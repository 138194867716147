<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">
      <div class="px-4 lookup-content">
        <div class="page-hdr-outer">
          <div class="sub-hdr">
            <h2>You are going to send email to the given Carriers and their associated contacts. </h2>
            <a href="javascript:void(0)" title="" (click)="closeModal()" class="close" data-bs-dismiss="modal"
              aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
          </div>
          <div id="tbl1">

            <ng-container *ngIf="submissionData.userList.length != 0">
              <div class="submission-header">Selected Users</div>

              <div class="submission-row">
                <div class="multi-select-tags">
                  <span *ngFor="let item of submissionData?.userList" class="selected-item">{{item?.display_name}}
                  </span>
                </div>
              </div>
            </ng-container>

            <div class="submission-confirm-outer">
              <div class="submission-header">Selected Carriers</div>
              <div class="submission-body-block">
                <div class="submission-row" *ngFor="let item of finalWbaSubmissionCarriers">
                  <div class="sub-detail-row">
                    <p class="carrName">{{item?.CarrierName}}</p>
                    <div class="mail-blk" *ngIf="item?.sentStatus"><img
                        src="../../../../../../assets/images/sub-email.svg" alt="" />
                      {{item?.submissionMail}}</div>
                  </div>
                  <!-- <div class="error-msg-submission" *ngIf="!item?.submissionMail && item?.sentStatus"> -->
                  <div class="error-msg-submission" *ngIf="item?.isError">
                    <p><img src="../../../../../../assets/images/alert.svg" alt="" /> This carrier has no email address.
                    </p>
                  </div>
                  <div class="error-msg-submission"
                    *ngIf="(!item?.contactList || item?.contactList.length == 0) && !item?.sentStatus && item?.isChecked">
                    <p><img src="../../../../../../assets/images/alert.svg" alt="" /> This carrier has no contact.
                    </p>
                  </div>
                  <div class="multi-select-tags">

                    <span class="selected-item" *ngFor="let i of item?.contactList">{{i?.contactName}} </span>
                  </div>
                  <div class="error-msg-submission" *ngIf="item?.contactEmailNotFound">
                    <p><img src="../../../../../../assets/images/alert.svg" alt="" /> This contact has no email address.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-blk1 pb-4 text-end">
              <button class="btn btn-outline-primary me-4" (click)="closeModal()">Cancel</button>
              <button class="btn btn-primary" (click)="emitSelectedData()" *ngIf="showBtn">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>