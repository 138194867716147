<div class="mid-con  px-4">
    <div class="pro-detail no-round-border" id="add-wba-view">

        <div class="wh-view">
            <div class="pro-right-header">
                <div class="pro-name-city">
                    <h2 *ngIf="transType == 'New Business' && (epicPolicyResponse.length <= 1)">Policy already created
                        in EPIC please review. <br>
                        You can select policy and update Whiteboard.</h2>
                    <h2 *ngIf="transType == 'New Business' && (epicPolicyResponse.length > 1)">Policy already created in
                        EPIC please review. <br>
                        You can select appropriate policy and update Whiteboard.</h2>
                    <h2 *ngIf="transType == 'Renew'">Policy renewal already initiated in EPIC. You can create renewal
                        WBA.</h2>
                </div>
                <div class="pro-action">
                    <div class="text-end flex-div">
                        <a href="javascript:void(0)" (click)="closeModal()" title="close" class="closebtn"><img
                                src="assets/images/close.svg" alt="" /></a>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="wh-view-detail" *ngFor="let epicPolicy of epicPolicyResponse; let i = index"
                    (click)="currentPolicyClicked(epicPolicy?.policyID)" [ngClass]="{'red-border': isClicked[i]}">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                    <h3 class="pe-3 compn"> Policy ID - {{epicPolicy?.policyID}} (Policy Number -
                                        {{epicPolicy?.policyNumber ? epicPolicy?.policyNumber : 'N/A'}})
                                    </h3>
                                </div>
                                <div class="row row-cols-5 ">
                                    <div class="col">
                                        <span>Line Type Code</span>
                                        <p>{{epicPolicy?.policyTypeCode ? epicPolicy?.policyTypeCode : 'N/A'}}</p>
                                    </div>

                                    <div class="col ">
                                        <span>Effective Date</span>
                                        <p>{{epicPolicy?.effectiveDate | date:'MM/dd/yyyy'}}</p>
                                    </div>

                                    <div class="col">
                                        <span>Expiration Date</span>
                                        <p>{{epicPolicy?.expirationDate | date:'MM/dd/yyyy'}}</p>
                                    </div>
                                    <div class="col">
                                        <span>Created On</span>
                                        <p>{{epicPolicy?.timestamp | date:'MM/dd/yyyy'}}</p>
                                    </div>
                                    <div class="col">
                                        <span>Policy Description</span>
                                        <p>{{epicPolicy?.description ? epicPolicy?.description : 'N/A'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 ">
                            <div class="whContactView ps-5 pe-3 descr">
                                <h3 class="pe-3 compn">Line Type Description</h3>

                                <p class="pt-2" *ngFor="let lines of epicPolicy?.lines">{{lines?.lineTypeDescription ?
                                    lines?.lineTypeDescription : 'N/A'}}<span
                                        *ngIf="epicPolicy?.lines.length > 1">,</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="d-flex justify-content-between align-items-center mb-3 mt-2">
            <div>
                <input type="checkbox" class="checkbox1 me-1" (change)="isPolicyUpdateChecked()" /><label
                    class="checkbox-label">This is not your policy?</label>
            </div>
            <div>
                <button *ngIf="isChecked" class="btn btn-primary pdbtn me-2" (click)="createPolicyInEPIC()">Create
                    Policy in EPIC</button>
                <button class="btn btn-primary" [disabled]="isChecked" *ngIf="policyId"
                    (click)="updatePolicyInEpic()">Update</button>
            </div>
        </div>
    </div>

    <p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
        [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
        [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
        <p>{{ _warningBox.message }}</p>
        <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
            <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-template>
    </p-dialog>
</div>