<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <!-- <div class="heading"
                    *ngIf="(this.fromCloneData?.transType == 'NB' && (((this.fromCloneData?.isBorDefined=='No' || this.fromCloneData?.isBorDefined=='' || this.fromCloneData?.isBorDefined=='null')
               && ((this.fromCloneData?.wbaStatusDescription== 'OPEN' || this.fromCloneData?.wbaStatusDescription== 'Lost' ) && (this.fromCloneData?.outcome=='' || this.fromCloneData?.outcome != 'Bound')))))">
                    Create a Clone
                </div>
                <div class="heading"
                    *ngIf="(this.fromCloneData?.transType == 'NB' && (this.fromCloneData?.isBorDefined=='Yes' && this.fromCloneData?.outcome == 'Bound'))">
                    BOR Renewal
                </div> -->
                <div class="heading">
                    Create a Clone
                </div>

                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
            <div class="add-content add-form">
                <div class="pdg-con">
                    <form class="row g-3" [formGroup]="addCloneForm">
                        <div class="add-form ">
                            <div class="add-fld">
                                <div class="row">
                                    <div class="row frmrow">
                                        <div class="col-12 col-sm-12 col-md-6 mb-2 date-control has-validation">
                                            <label class="form-label">Effective Date <span
                                                    class='star-color'>*</span></label>
                                            <p-calendar [readonlyInput]="true" class="form-control"
                                                [ngClass]="{ 'isDisabled': this.fromCloneData.isBorDefined == 'Yes'}"
                                                placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                inputId="effectiveDate" formControlName="effectiveDate"></p-calendar>
                                            <div class="display-error" *ngIf="f.effectiveDate.invalid && isSubmitted">
                                                <span *ngIf="f.effectiveDate.errors?.required">
                                                    {{_addWbaScreen.effectiveDate.required}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="blk-bt">
                <button class="btn btn-outline-primary ms-2 me-3" (click)="cancel()">Cancel</button>
                <button
                    *ngIf="(this.fromCloneData?.transType == 'NB' && (((this.fromCloneData?.isBorDefined=='No' || this.fromCloneData?.isBorDefined=='' || this.fromCloneData?.isBorDefined=='null')
                && ((this.fromCloneData?.wbaStatusDescription== 'OPEN' || this.fromCloneData?.wbaStatusDescription== 'Lost' ) && (this.fromCloneData?.outcome=='' || this.fromCloneData?.outcome != 'Bound')))))"
                    class="btn btn-primary" (click)="CreateClone()">Create a Clone</button>
                <button *ngIf="((this.fromCloneData?.transType == 'NB' &&
                this.fromCloneData?.isBorDefined=='Yes' && this.fromCloneData?.outcome == 'Bound'))"
                    class="btn btn-primary" (click)="CreateClone()">BOR Renewal</button>
            </div>
        </div>
    </div>
</div>
<p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
    <p>{{ _confirmBox.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
        <p-button (click)="confirmBoxCancelDiscard()" label="Yes" styleClass="btn btn-primary"></p-button>
    </ng-template>
</p-dialog>
<p-dialog header="Alert" [modal]="true" [(visible)]="_confirmBoxForClone.isVisible" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [styleClass]="_confirmBoxForClone.borderColor"
    *ngIf="_confirmBoxForClone">
    <p>{{ _confirmBoxForClone.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBoxForClone.hasFooter">
        <!-- <p-button (click)="_confirmBoxForClone.isVisible = false" label="No"
            styleClass="btn btn-outline-primary"></p-button>
        <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button> -->
        <p-button (click)="confirmBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
</p-dialog>
