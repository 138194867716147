import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { CustomeRemoveNumberPipe } from 'src/app/shared/pipes/remove-number.pipe';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { AddWBAScreen } from '../../error-message/addprospect-screen';
import { WhiteBoardActivityService } from '../../services/white-board-activity.service';

@Component({
  selector: 'app-edit-wbactivity',
  templateUrl: './edit-wbactivity.component.html',
  styleUrls: ['./edit-wbactivity.component.css']
})
export class EditWBActivityComponent implements OnInit {

  @Input() fromWBAList: any;
  editWhiteBoardForm: any;
  isDisabled: boolean = false;
  isSubmitted: boolean = false;
  isCompany: boolean = false;
  ein = Number;
  _AddWhiteBoardScreen: AddWBAScreen;
  value: any = "";
  selectedAccount: any;
  keyword = 'name';
  accountList: any;
  _confirmBox: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  userList: any = []
  businessLine: any = []
  businessStatus: any = []

  keydownInDropdown(e: KeyboardEvent): any {

    if (e.keyCode === 37 || e.keyCode === 39 ||
      e.keyCode === 16) { // ARROW LEFT or ARROW RIGHT or SHIFT key
      return;
    } else if (e.keyCode === 8) {
      return
    }
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _activeModal: NgbActiveModal,
    private _loaderService: LoaderService,
    private _addWBAService: WhiteBoardActivityService,
    private _numberPipe: CustomeNumberPipe,
    private _removeNumberPipe: CustomeRemoveNumberPipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe
    // private cdref: ChangeDetectorRef
  ) {
    this._AddWhiteBoardScreen = new AddWBAScreen();
    this._confirmBox = new ConfirmBox()
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this.getFormControl()
  }

  ngOnInit(): void {
    this.getAllLists()
  }


  getAllLists() {
    this.getCommonList();
    this.setFormValue();
  }
  get f() {
    return this.editWhiteBoardForm.controls;
  }
  // Form control fields
  getFormControl() {
    this.editWhiteBoardForm = this._formBuilder.group({
      id: [{ value: '' }, Validators.required],
      companyName: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      prospectId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      ein: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(9)]],
      businessStatusId: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      businessLineId: [{ value: '', disabled: this.isDisabled }, Validators.required],
      userId: [{ value: '', disabled: this.isDisabled }, Validators.required],
      effectiveDate: [{ value: '', disabled: this.isDisabled }, Validators.required],
      boundRevenue: [{ value: '' }, Validators.pattern("^[0-9]*$")],
      businessLine: [{ value: '' }, Validators.required],
      businessStatus: [{ value: '', }, Validators.required],
      userName: [{ value: '' }, Validators.required],
      whiteBoardActivityName: [{ value: '', disabled: this.isDisabled }, Validators.required],
    })
  }
  // form value set using patch value
  setFormValue() {
    this.editWhiteBoardForm.patchValue({
      id: this.fromWBAList.id,
      companyName: this.fromWBAList.companyName,
      prospectId: this.fromWBAList.prospectId,
      ein: this.fromWBAList.ein,
      businessStatusId: this.fromWBAList.businessStatusId,
      businessLineId: this.fromWBAList.businessLineId,
      userId: this.fromWBAList.userId,
      effectiveDate: new Date(this.fromWBAList.effectiveDate),
      boundRevenue: this.fromWBAList?.boundRevenue,
      businessLine: this.fromWBAList?.businessLine,
      businessStatus: this.fromWBAList?.businessStatus,
      userName: this.fromWBAList?.userName,
      whiteBoardActivityName: this.fromWBAList.whiteBoardActivityName
    });
  }
  closeModal() {
    this._activeModal.close();
  }
  editWBActivity() {
    this.isSubmitted = true
    let data = {
      id: this.fromWBAList.id,
      item: this.editWhiteBoardForm.value
    }
    if (this.editWhiteBoardForm.valid) {
      if (this.fromWBAList.companyName != this.editWhiteBoardForm.get('companyName').value) {
        this.isCompany = true
        return
      }

      this._loaderService.show()
      this._addWBAService.updateWBAById(data).subscribe(resp => {
        this._loaderService.hide()
        this.closeModal()
      },
        error => {
          this._loaderService.hide()
        })
    }
  }

  //to cancel the process and back to prospect list screen
  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.closeModal();
  }

  // to get user, business line & business status list
  getCommonList() {
    var businessLine = this._addWBAService.getBusinessLineList() //to get business line list
    var businessStatus = this._addWBAService.getBusinessStatusList() //to get business status list
    var user = this._addWBAService.getUserList()   //to get user list

    forkJoin([businessLine, businessStatus, user])
      .subscribe(resp => {
        this.businessLine = resp[0];
        this.businessStatus = resp[1];
        this.userList = resp[2]
        this._loaderService.hide();
      })
  }
  getCompanyList(val: any) {
    if (this.f.prospectId.value) this.value = '';
    if (val.keyCode === 37 || val.keyCode === 39 || val.keyCode === 16) { // ARROW LEFT or ARROW RIGHT or SHIFT key
      return;
    }
    else if (val.keyCode >= 112 && val.keyCode <= 123) { //CTRL+ALT+F4
      return
    }
    else if (val.key && val.key != undefined || val.key == 'Backspace') {
      if ((this.value != '' || this.value != 'Backspace') && val.key == 'Backspace') this.value = this.value.slice(0, -1)
      else this.value += val.key;
      if (this.value.includes('Backspace')) this.value.replace('Backspace', '');
      this._addWBAService.getAccountList(this.value).subscribe(resp => {
        this.accountList = resp.prospectList;
      })
    }
  }
  selectItem(item: any) {
    this.editWhiteBoardForm.get('ein').setValue(item?.prospectInformation?.ein);
    this.editWhiteBoardForm.get('prospectId').setValue(item?.id);
  }

  clearValue() {
    this, this.value = '';
  }

  addNumberFormat(element: any, control?: AbstractControl) {
    var formattedNumber;
    formattedNumber = this._numberPipe.transform(element.target.value, 'USD', '1.0-0');
    element.target.value = formattedNumber;
    control?.setValue(element.target.value);
  }

  removeNumberFormat(element: any, val?: any) {
    var formattedNumber;
    formattedNumber = this._removeNumberPipe.transform(element.target.value);
    element.target.value = formattedNumber;
  }

  // Remove white spaces from every input fileds
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
}
