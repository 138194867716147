<div class="mid-con">

  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <div class="col-12 wbaHeadding">
            <div class="hd-width"><h2>Select Coverage</h2></div>
          </div>
            <div class="col-12 col-sm-12 col-md-12">

              <div class="min-ht-comp">
                <!-- <div class="card-text row addw"> -->
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                    <p *ngIf="MasterCoverageListNew?.length==0"><b>Audit wizard not found.</b></p>
                    <ul class="chk-list wb-chklist">
                      <li [ngClass]="{'active-c' : CoverageCheckList.CurrentCoverage?.displayName === line.displayName,
                          'select-c' : CoverageCheckList.CurrentCoverage?.displayName != line.displayName
                        }" *ngFor="let line of MasterCoverageListNew; let i = index">
                        <a title="">
                          <div class="form-check">
                            <input type="checkbox" name="{{ line.displayName }}{{ i }}"
                              [disabled]="line.coverageParameters.length==0" id="{{ line.displayName }}{{ i }}"
                              class="form-check-input pointer" (change)="handleCoverage(line, 'checkbox')"
                              [checked]="line.isChecked && line.coverageParameters.length" />
                            <label (click)="handleCoverage(line, 'details')" class="m-t-2 pointer">
                              {{line.displayName }}</label>
                          </div>
                        </a>

                      </li>
                    </ul>
                  </div>

                  <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 cov-con">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h3 class="mb-3 DetailsHeading">
                          {{ CoverageCheckList.CurrentCoverage.displayName }}
                        </h3>
                      </div>
                      <ng-container *ngIf="CoverageCheckList.CurrentCoverage.coverageParameters.length;">
                        <ng-container *ngFor=" let group of groups">
                          <div class="col-12 px-0  grp-heading">
                            <h3>
                              {{group}}
                            </h3>
                          </div>

                          <ng-container
                            *ngFor="let param of CoverageCheckList.CurrentCoverage.coverageParameters; let index=index;">
                            <span [ngClass]="{'hidden2' : group.toLowerCase()!=param.parameterCategory.toLowerCase()}"
                              class="col-6 form-check mb-2">
                              <ng-container>
                                <input class="form-check-input pointer" type="checkbox"
                                  id={{param.id}}{{param.displayName}} [checked]="param.isChecked"
                                  (click)="handleCoverageParameter(param, CoverageCheckList.CurrentCoverage)"
                                  [disabled]="param.disabled" />
                                <label class="m-t-2 pointer" for={{param.id}}{{param.displayName}}>
                                  {{ param.displayName }}
                                </label>
                              </ng-container>
                            </span>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <ng-template #NoData> No data found </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialog for confirmation starts -->
<p-dialog [header]="messageKey==='uncheckCoverage'? _confirmBox.header:_confirmBox.header2" [modal]="true"
  [(visible)]="_confirmBox.isVisible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }"
  [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
  <p>{{ _confirmBox.message }}</p>
  <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
    <ng-container *ngIf="messageKey==='uncheckCoverage'; else showOK">
      <p-button (click)="confirmBoxCancel('no')" label="No" styleClass="btn btn-outline-primary"></p-button>
      <p-button (click)="confirmBoxCancel('yes')" label="Yes" styleClass="btn btn-primary"></p-button>
    </ng-container>

    <ng-template #showOK>
      <p-button (click)="confirmBoxCancel('ok')" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </ng-template>
</p-dialog>
<!-- Dialog for confirmation ends -->
