import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { WhiteBoardActivityService, coverageModel, coverageParameterModel } from '../../../services/white-board-activity.service';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-coverage-assessment',
  templateUrl: './coverage-assessment.component.html',
  styleUrls: ['./coverage-assessment.component.css']
})
export class CoverageAssessmentComponent implements OnInit {

  @Input() public auditDone: boolean = false;
  @Input() public auditRun: any;
  @Input() ApplicationData: any;
  @Input() tierId: any;
  @Input() epicUniquePolicyId: any;
  @Input() isRenewalStatusVisible: any;
  @Input() CoverageAssessmentData: any;
  @Input() tabName: any;
  @Input() masterCoverageListByLineTierAndIndustry: any;
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() assessmentPayload: any = new EventEmitter<any>();
  @Output() assessmentResponse: any = new EventEmitter<string>();
  @Output() isAssesmentParamsHasMandatoryValues: any = new EventEmitter<boolean>();
  @Output() handleCovAssessmentIconStatus: any = new EventEmitter<any>();

  public defaultExpiringKeys = {
    'Carrier (Issuing Company)': 'expiringCarrierId',
    'Named Insured': 'companyName',
  }

  services: any

  ServicesKeys: any
  serviceTab: any = { displayName: 'Services' };
  defaultRating: any = { exposure: '', value: '', description: '' };
  rating: any = { ...this.defaultRating };
  _confirmBox: ConfirmBox;
  selectedCoverages: string = '';
  tabs: any = ['Coverages', ''];
  currentParentTab: any;
  currentTab: string = this.tabs[0];
  ratingBasis: any = [];
  CoverageCheckList: any = [];
  wbaWorkerCompensationClassCodes: any = [];
  expiringCarrier: string = ''
  expiringPremium: string = ''
  isRatingAddSubmitted: boolean = false;
  public _navigationPath: NavigationPath;

  _userMessages: UserMessages;
  private request: any = [];
  public MasterCoverageListNew: any;
  showHideParametersValidationPopUp: boolean = false;
  missingCoverage: any[] = [];
  showServices: boolean = false;
  wbaDetails: any;


  isSubmitted: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  unAthorizesd: any = '';
  public editIndex: number = -1;
  defaultWCRatingData: any = {
    "id": '',
    "wbaId": '',
    "industryId": '',
    "coverageId": '',
    "location": '',
    "classCode": '',
    "subCode": '',
    "fullTimeEmployees": '',
    "partTimeEmployees": '',
    "payroll": '',
    "stateOfExposure": ''
  }
  public addWCRatingForm: any = { ...this.defaultWCRatingData };
  public editWCRatingForm: any = { ...this.defaultWCRatingData };


  defaultGLRatingBasicData: any = {
    "id": '',
    "wbaId": '',
    "coverageId": '',
    "exposureId": '',
    "exposureType": '',
    "value": '',
    "description": ''
  }

  private defaultLossRun: any = {
    policyTerm: '',
    totalIncured: '',
    carrierName: '',
    valuationDate: '',
  };
  public addGLRatingBasisForm: any = { ...this.defaultGLRatingBasicData };
  public editGLRatingBasisForm: any = { ...this.defaultGLRatingBasicData };


  isAddWCRatingSubmitted: boolean = false;
  isEditWCRatingSubmitted: boolean = false;
  isAddGLRatingBasisSubmitted: boolean = false;
  isEditGLRatingBasisSubmitted: boolean = false;
  deleteIndex: any;
  masterCoverageServiceList: any;
  arrayForMasterServiceList: any[] = [];
  // checkedServices: any[] = [];
  stateList: any;
  showHideExistingValidationPopUp: boolean = false;
  showRequiredExposureTypePopUp: boolean = false;
  arrayForRequiredExpsoureType: any;
  convertArrayToStringDisplayName: any;
  epicuniqePolicyid: number = 0;
  transTypeStatus: any;
  CoverageAssessmentDetail: any;
  deleteGLRatingBasisData: boolean = false;

  isDirty: boolean = false;
  isDollarVisible: boolean = false;
  recommendedValue: string = ''
  coverageAssessmentIDList: any;
  groupResult: any[] = [];
  finalGroupResult: any[] = [];
  activeWbaId: any;
  expiringValueForAutosymbol: any[] = [];
  recommandedValueForAutoSymbol: any[] = [];
  requiredAssessmentCheck: boolean = true
  requiredRatingBasis: boolean = true;
  tabRefresh: any;
  takingDataFromApi: any;
  renewPolicyDetails: any;
  lines: any = {};
  constructor(
    public activeModal: NgbActiveModal,
    public _amountPipe: CustomeAmountPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public ValidateKey: KeyboardValidation,
    public _addWBAService: WhiteBoardActivityService,
    private _router: Router,
    public datepipe: DatePipe,
    private _notifierService: WBANotifierService,
    private _AddprospectService: AddprospectService,
    private activeRouter: ActivatedRoute,
    private _dataCacheService: DatacacheService,
  ) {
    this._confirmBox = new ConfirmBox();
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();
    this.CoverageCheckList = JSON.parse(sessionStorage.getItem(environment.storageVariables.CoverageCheckListStorage)!);

    //sorting CoverageCheckList by group
    // this.CoverageCheckList.forEach((element: any) => {
    //   element?.coverageParameters?.sort((a: { parameterCategory: any; }, b: { parameterCategory: any; }) =>
    //     a.parameterCategory.localeCompare(b.parameterCategory));
    // })
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    // this.getProspectStates();
    this._notifierService._wbaIsSubmittedOnTabChange.subscribe((res: any) => {
      if (res == 'true') {
        this.isSubmitted = res;
      }
    });
    this._notifierService._wbaIsSubmittedOnTabChange.next('false');
    let names = this.CoverageCheckList.map((obj: any) => obj.displayName);
    if (names && names.length) this.selectedCoverages = names.join(', ');

    this.setCoverageData();
    //this api is called on application page now -- will try to remove this
    // this.getCoveragedata();

    if (!this.checkRequiredExpiringOrRecommendedCoverageHasValues()) {
      this.isAssesmentParamsHasMandatoryValues.emit(true);
    } else {
      this.isAssesmentParamsHasMandatoryValues.emit(false);
    }
    // this.checkRequiredRecommendedCoverageHasValuesErrorIcon();
    this.checkRequiredRecommendedCoverageHasValuesErrorIconRN();
    this.intializeData();
    // this.checkGLRatingBasisHasRequiredValuesErrorIcon();
  }

  checkRequiredExpiringOrRecommendedCoverageHasValues() {
    let result: boolean = true
    for (const element of this.CoverageCheckList) {
      if (element?.coverageParameters) {
        for (const param of element?.coverageParameters) {
          if (param.isRequired && param.displayName != "Named Insured" && ((this.expiringCarrier && this.expiringPremium && (param.expiringValue == "" || param.expiringValue == "undefined")) || param.recommendedValue == "" || param.recommendedValue == "undefined")) {
            result = false
            break;
          }
        }
      }
    }
    return result;
  }

  checkRequiredRecommendedCoverageHasValuesErrorIconRN() {

    for (const element of this.CoverageCheckList) {
      if (element.coverageParameters) {
        console.log("coverageParameters", element.coverageParameters)
        for (const param of element.coverageParameters) {
          if (param.displayName != "Named Insured") {
            this.handleDetailsTabErrorIcon(element.displayName, false);
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ApplicationData) {
      let { expiringPremium, expiringCarrierId } = changes.ApplicationData.currentValue;
      if (expiringPremium) {
        this.expiringCarrier = expiringCarrierId?.value;
        this.expiringPremium = expiringPremium;
        this.setDefaultCoveragesExpiring();
      }
    }
  }

  //method to get prospect states
  getProspectStates() {
    this._AddprospectService.getProspectStates().subscribe(resp => {
      this.stateList = resp.statesList;
    })
  }


  intializeData() {
    this.renewPolicyDetails = this._dataCacheService.retrieveData('renewPolicyDetails');
    this.lines = this.renewPolicyDetails?.lines;
    this.stateList = this._dataCacheService.retrieveData('wba_statesList');
    this.request = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == "Coverage Assessment");
    if (!this.request.length) this.allMasterData();
    else this.getCoveragedata()
  }

  allMasterData() {
    // var stateList = this._AddprospectService.getProspectStates();
    var assesmentlistData = this._addWBAService.getCoverageAssessmentDetails(parseInt(this.activeWbaId));
    forkJoin([assesmentlistData]).subscribe(resp => {
      // this.stateList = resp[0].statesList;
      this.request = resp[0];
      // this._dataCacheService.compressAndCacheData('wba_statesList', this.stateList);
      this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.request);
      // this.tabRefresh[3].refresh = false;
      // this._dataCacheService.compressAndCacheData('tabRefresh', this.tabRefresh);
      this.getCoveragedata();
    })
  }

  showServiceTab() {
    this.showServices = true;
  }

  showRenewTab() {
    let data = {

      "policyId": this.epicUniquePolicyId,
      "wbaId": this.activeWbaId
    }
    this._addWBAService.GetRenew(data).subscribe((response: any) => {
      this.request = response
      this.handleCovergeData();
      this.getRenewDetail()
    });
  }

  getRenewDetail() {

    // if (this.request?.wbaService?.length)
    //   this.checkedServices = this.request.wbaService;

    if (this.request.coverageAssessmentDetail[0].coverageName == "Workers Compensation") {
      this.CoverageCheckList[1].workercompRating = this.request.coverageAssessmentDetail[0].workercompRating;

    }
    let isGLAvailable = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageName == "General Liability" || cov.coverageName == "Cyber")
    if (isGLAvailable) {
      this.CoverageCheckList[1].ratingBasis = this.request?.coverageAssessmentDetail[0]?.ratingBasis;
    }


    let tempLabiliabiltyCoverage = this.request.coverageAssessmentDetail[0].coverages.find((element: any) =>
      element.parameterName == "Liability - Auto Symbol"
    )
    if (tempLabiliabiltyCoverage) {
      let expiringValueForAutosymbol = tempLabiliabiltyCoverage.expiringValue.split(',');
      let recommandedValueForAutoSymbol = tempLabiliabiltyCoverage.recommendedValue.split(',');
      this.expiringValueForAutosymbol = expiringValueForAutosymbol
      this.recommandedValueForAutoSymbol = recommandedValueForAutoSymbol
    }
    this.setDefaultCoveragesExpiring()
    //making array for master service list
    // this.request.coverageAssessmentDetail.forEach((element: any) => {
    //   this.arrayForMasterServiceList.push(element.coverageId)
    // })
    //this.GetMasterCoverageServiceListByTierAndCoverage();
    this._notifierService.setWBASaveAssessmentReq(this.request);
    this.assessmentResponse.emit(this.request);
  }

  handleCovergeData() {
    this.CoverageCheckList = [];
    this.request.coverageAssessmentDetail.forEach((element: coverageModel) => {
      let coverageParamemter: any[] = [];

      // finding the coverages and checking them
      let slectedCoverage = this.masterCoverageListByLineTierAndIndustry.find((cov: any) => cov.id === element.coverageId);
      if (slectedCoverage) {
        // checking only selected coverage
        slectedCoverage.isChecked = true;

        // finding the parameters of the coverages and checking them
        slectedCoverage.coverageParameters.filter((param: any) => element.coverages.some((obj: coverageParameterModel) => obj.coverageParameterId === param.id)).map((param: any) => {
          param.isChecked = true;

          let existingAssessmentParam = element.coverages.find((item: any) => param.id === item.coverageParameterId);
          if (existingAssessmentParam) {
            param.expiringValue = existingAssessmentParam.expiringValue;
            param.recommendedValue = existingAssessmentParam.recommendedValue;
            param.offeringGuide = existingAssessmentParam.offeringGuide;
            param.expiringConditionDescription = existingAssessmentParam.expiringConditionDescription;
            param.recommendedConditionDescription = existingAssessmentParam.recommendedConditionDescription;
            param.expiringAssessmentId = existingAssessmentParam.expiringAssessmentId;
            param.recommendedAssessmentId = existingAssessmentParam.recommendedAssessmentId;
          }

          coverageParamemter.push(param);
        });


        let CoverageToSend = { ...slectedCoverage };
        // CoverageToSend.coverageParameters.sort((a: { sequence: number; }, b: { sequence: number; }) => a.sequence - b.sequence);
        // CoverageToSend.coverageParameters = coverageParamemter;
        this.CoverageCheckList.push(CoverageToSend);
      }

    });
    sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList));
    this.CoverageCheckList.forEach((element: any) => {
      element?.coverageParameters?.sort((a: { parameterCategory: any; }, b: { parameterCategory: any; }) =>
        a.parameterCategory.localeCompare(b.parameterCategory));
    })

    this.setCoverageData();
  }

  setCoverageData() {
    this.CoverageCheckList.forEach((cov: any) => {
      if (cov.coverageParameters) {
        cov.coverageParameters.forEach((element: any) => {
          if (element.valueType == 'input-currency') {
            let removedCommasFromExpiringValue = this._removeAmountPipe.transform(element.expiringValue)
            element.expiringValue = this._amountPipe.transform((removedCommasFromExpiringValue), 'USD', '', '1.0-0')
            let removedCommasFromrecommendedValue = this._removeAmountPipe.transform(element.recommendedValue)
            element.recommendedValue = this._amountPipe.transform((removedCommasFromrecommendedValue), 'USD', '', '1.0-0')
          }
          if (element.displayName == 'Liability - Auto Symbol' && (element.expiringValue != '' || element.recommendedValue != '')) {
            let expiringValueForAuto = element.expiringValue.toString();
            let recommendedValueForAuto = element.recommendedValue.toString();
            element.expiringValue = expiringValueForAuto?.split(',');
            element.recommendedValue = recommendedValueForAuto?.split(',');
            this.expiringValueForAutosymbol = element.expiringValue
            this.recommandedValueForAutoSymbol = element.recommendedValue
          }
        });
      }
    })

    //setting GL on zero index because it will help me in wc rating tab
    let isGLAvailable = this.CoverageCheckList.find((cov: any) => cov.displayName == "General Liability")
    if (isGLAvailable) {
      let indexForGL = this.CoverageCheckList.findIndex((cov: any) => cov.displayName == "General Liability")
      this.CoverageCheckList.splice(indexForGL, 1)
      this.CoverageCheckList.splice(0, 0, isGLAvailable);
      this.currentParentTab = this.CoverageCheckList.length ? isGLAvailable : [];
      setTimeout(() => {
        this.hideShowControlOnCondition();
      });

      //Adding GL Rating Basis as Coverage
      let objGLRatingBasis = {
        id: isGLAvailable.id,
        displayName: "GL Rating Basis",
        coverageExposures: isGLAvailable.coverageExposures
      }
      let indexOneItem = this.CoverageCheckList[1];
      if (indexOneItem) {
        //getting one index
        if (!this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis')) {
          this.CoverageCheckList[1] = objGLRatingBasis;
          this.CoverageCheckList.push(indexOneItem);
        }
      }
      else {
        if (!this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis'))
          this.CoverageCheckList.push(objGLRatingBasis);
      }

    }
    else
      this.currentParentTab = this.CoverageCheckList.length ? this.CoverageCheckList[0] : [];
    setTimeout(() => {
      this.hideShowControlOnCondition();
    });

    //Adding WC Rating as Coverage
    if (this.CoverageCheckList.find((x: any) => x.displayName == 'Workers Compensation')) {
      let objWCRatingBasis = {
        id: this.CoverageCheckList[0].id,
        displayName: "WC Rating",
      }
      if (!this.CoverageCheckList.find((x: any) => x.displayName == 'WC Rating'))
        this.CoverageCheckList.push(objWCRatingBasis);
    }

    //Adding Cyber Rating Basis as Coverage
    if (this.CoverageCheckList.find((x: any) => x.displayName == 'Cyber')) {
      let data = this.CoverageCheckList.find((x: any) => x.displayName == 'Cyber');
      let objWCRatingBasis = {
        id: this.CoverageCheckList[0].id,
        displayName: "Cyber Rating Basis",
        coverageExposures: data.coverageExposures
      }
      if (!this.CoverageCheckList.find((x: any) => x.displayName == 'Cyber Rating Basis'))
        this.CoverageCheckList.push(objWCRatingBasis);
    }

    //setting Umbrella on last index
    let isUmbrellaAvailable = this.CoverageCheckList.find((cov: any) => cov.displayName == "Umbrella")
    let isUmbrellaIndex = this.CoverageCheckList.findIndex((cov: any) => cov.displayName == "Umbrella")
    if (isUmbrellaIndex > -1) {
      this.CoverageCheckList.splice(isUmbrellaIndex, 1)
      this.CoverageCheckList.push(isUmbrellaAvailable)
    }
  }


  parentTabChange(covg: any) {
    this.showServices = false;
    this.currentParentTab = covg;

    this.hideShowControlOnCondition();

  }

  TabChange(label: string) {
    this.currentTab = label;
  }

  getGroupName(coverage: any, index: number) {
    let currentIndexName = coverage.coverageParameters[index].parameterCategory;

    if (index === 0) return currentIndexName;
    else {
      let lastIndexName = coverage.coverageParameters[index - 1].parameterCategory;

      if (lastIndexName === currentIndexName) return '';
      else return currentIndexName;
    }
  }

  setDefaultCoveragesExpiring() {
    this.CoverageCheckList.forEach((covg: any) => {
      let records = covg?.coverageParameters?.filter((param: any) => Object.keys(this.defaultExpiringKeys).indexOf(param.displayName) > -1);
      if (records) {
        records.forEach((obj: any) => {
          if (obj.displayName === 'Carrier (Issuing Company)') {
            obj.expiringValue = this.ApplicationData[this.defaultExpiringKeys[obj.displayName as keyof defaultExpiringKeysModel]];
            let key = obj.displayName === 'Carrier (Issuing Company)' ? 'value' : 'prospectName';
            obj.expiringValue = obj.expiringValue[key];
          }
          // else {

          //   var tarPremium = this._removeAmountPipe.transform(obj.expiringValue);

          //   obj.expiringValue = this._amountPipe.transform(parseInt(obj.expiringValue), 'USD', '', '1.0-0')
          // }
        });
      }
    });

    // local storage set again for above keys get updated
    sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList));

  }

  getCoveragedata() {
    // this._addWBAService.getCoverageAssessmentDetails(parseInt(this.activeWbaId)).subscribe((response: any) => {
    //   this.request = response;
    //   this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.request);
    //  this.request = this.CoverageAssessmentData// was taking data from parent to child but it was creating issue
    // if (this.request.wbaService.length)
    //   this.checkedServices = this.request.wbaService;

    if (this.request?.coverageAssessmentDetail[0]?.coverageName == "Workers Compensation") {
      this.CoverageCheckList[1].workercompRating = this.request.coverageAssessmentDetail[0]?.workercompRating;
      if (this.request.coverageAssessmentDetail[0]?.workercompRating?.length == 0) {
        this.handleDetailsTabErrorIcon('WC Rating', true);
        this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
      }
      else {
        this.handleDetailsTabErrorIcon('WC Rating', false);
      }

    }
    let isGLAvailable = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageName == "General Liability" || cov.coverageName == "Cyber")
    if (isGLAvailable) {
      this.CoverageCheckList[1].ratingBasis = this.request?.coverageAssessmentDetail[0]?.ratingBasis;
    }

    if (this.request?.coverageAssessmentDetail[0]?.coverageName == "General Liability") {
      if (this.request.coverageAssessmentDetail[0]?.ratingBasis?.length == 0) {
        this.handleDetailsTabErrorIcon('GL Rating Basis', true);
        this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
      }
      else {
        this.handleDetailsTabErrorIcon('GL Rating Basis', false);
      }
    }
    if (this.request.coverageAssessmentDetail[0].coverageName == "Cyber") {
      if (this.request.coverageAssessmentDetail[0]?.ratingBasis?.length == 0) {
        this.handleDetailsTabErrorIcon('Cyber Rating Basis', true);
      }
      else {
        this.handleDetailsTabErrorIcon('Cyber Rating Basis', false);
      }
    }


    let tempLabiliabiltyCoverage = this.request.coverageAssessmentDetail[0].coverages.find((element: any) =>
      element.parameterName == "Liability - Auto Symbol"
    )
    if (tempLabiliabiltyCoverage) {
      let expiringValueForAutosymbol = tempLabiliabiltyCoverage.expiringValue.split(',');
      let recommandedValueForAutoSymbol = tempLabiliabiltyCoverage.recommendedValue.split(',');
      this.expiringValueForAutosymbol = expiringValueForAutosymbol
      this.recommandedValueForAutoSymbol = recommandedValueForAutoSymbol
    }
    this.setDefaultCoveragesExpiring()
    //making array for master service list
    // this.request.coverageAssessmentDetail.forEach((element: any) => {
    //   this.arrayForMasterServiceList.push(element.coverageId)
    // })
    //this.GetMasterCoverageServiceListByTierAndCoverage();
    this._notifierService.setWBASaveAssessmentReq(this.request);
    this.assessmentResponse.emit(this.request);
    // });
  }

  // GetMasterCoverageServiceListByTierAndCoverage() {
  //   let data = {
  //     "coverageIdList": this.arrayForMasterServiceList,
  //     "tierId": this.tierId
  //   }
  //   this._addWBAService.GetMasterCoverageServiceListByTierAndCoverage(data).subscribe((response: any) => {
  //     this.masterCoverageServiceList = response;
  //     this.ServicesKeys = Object.keys(this.masterCoverageServiceList);
  //     this.services = this.masterCoverageServiceList;

  //     //check/uncheck services based on wba
  //     this.request.wbaService.forEach((element: any) => {
  //       this.services.claimManagmentServiceList.forEach((serv: any) => {
  //         if (element.serviceId == serv.serviceId) {
  //           serv.isChecked = true
  //         }
  //       });

  //       this.services.riskManagmentServiceList.forEach((serv: any) => {
  //         if (element.serviceId == serv.serviceId) {
  //           serv.isChecked = true
  //         }
  //       });
  //     });
  //   });
  // }

  // handleService(service: any) {
  //   service.isChecked = !service.isChecked;
  //   let index = this.checkedServices.findIndex((obj: any) => obj.serviceName.toLowerCase() === service.serviceName.toLowerCase());
  //   let record = { ...service };
  //   if (index === -1) {
  //     let item = {
  //       serviceId: record.serviceId,
  //       serviceName: record.serviceName,
  //     }
  //     this.checkedServices.push(item);
  //     this.request.wbaService = this.checkedServices
  //   }
  //   else {
  //     this.checkedServices.splice(index, 1);
  //     this.request.wbaService = this.checkedServices
  //   }
  // }

  handleDetailsTabErrorIcon(label: string, value: boolean) {
    this.CoverageCheckList.forEach((x: any) => {
      if (x.displayName == label) x.showError = value;
    });
  }

  saveWBA(action: string) {
    this.isSubmitted = true;
    //checking required exposure type has values or not
    if (action == 'next') {
      if (this.wbaDetails?.transType === 'NB') {
        if (this.checkRequiredExpiringCoverageHasValues()) {
          if (this.checkRequiredRecommendedCoverageHasValues()) {
            if (this.checkGLRatingBasisHasRequiredValues()) {
              //validations for required services
              //this.request.wbaService = this.checkedServices
              this.showHideParametersValidationPopUp = false;
              //updating assessment id in existing storage
              this.coverageAssessmentIDList.forEach((cov: any) => {
                this.request.coverageAssessmentDetail.forEach((element: any) => {
                  element.coverages.forEach((param: any) => {
                    if (param.coverageParameterId === cov.parameterId) {

                      param.expiringAssessmentId = cov.expiringAssessmentId;
                      param.expiringConditionDescription = cov.expiringDescription;
                      param.recommendedAssessmentId = cov.recommendedAssessmentId;
                      param.recommendedConditionDescription = cov.recommendedDescription;
                      param.expiringValue = cov.expiringValue.toString();
                      param.recommendedValue = cov.recommendedValue.toString();
                      if (param.parameterName == 'Liability - Auto Symbol') {
                        let paramsFromLocalCopy = JSON.parse(JSON.stringify(this.expiringValueForAutosymbol));
                        let indexof10Object = paramsFromLocalCopy.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                        let indexof10Index = paramsFromLocalCopy.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                        if (indexof10Index > -1) {
                          paramsFromLocalCopy.splice(indexof10Index, 1)
                        }
                        paramsFromLocalCopy = paramsFromLocalCopy.sort().join(',');

                        if (indexof10Index > -1) {
                          paramsFromLocalCopy = paramsFromLocalCopy + ',' + indexof10Object
                        }

                        param.expiringValue = paramsFromLocalCopy
                        this.expiringValueForAutosymbol = paramsFromLocalCopy.split(',');

                        let paramsFromLocalRecommended = JSON.parse(JSON.stringify(this.recommandedValueForAutoSymbol));
                        let indexof10ObjectRecommended = paramsFromLocalRecommended.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                        let indexof10IndexRecommended = paramsFromLocalRecommended.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                        if (indexof10IndexRecommended > -1) {
                          paramsFromLocalRecommended.splice(indexof10IndexRecommended, 1)
                        }

                        paramsFromLocalRecommended = paramsFromLocalRecommended.sort().join(',');
                        if (indexof10IndexRecommended > -1) {
                          paramsFromLocalRecommended = paramsFromLocalRecommended + ',' + indexof10ObjectRecommended
                        }

                        param.recommendedValue = paramsFromLocalRecommended
                        this.recommandedValueForAutoSymbol = paramsFromLocalRecommended.split(',');

                        if (param.recommendedValue.charAt(0) == ',') {
                          param.recommendedValue = param.recommendedValue.substring(1)
                        }
                        if (param.expiringValue.charAt(0) == ',') {
                          param.expiringValue = param.expiringValue.substring(1)
                        }
                      }
                    }
                  });
                });
              })
              if (this.request.coverageAssessmentDetail[0]?.coverageName == "Workers Compensation") {
                if (this.request.coverageAssessmentDetail[0]?.workercompRating?.length == 0) {
                  this.handleDetailsTabErrorIcon('WC Rating', true);
                  this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
                }
                else {
                  this.handleDetailsTabErrorIcon('WC Rating', false);
                }

              }
              console.log("request123", this.request);
              this._addWBAService.saveCoverageAssessmentForWBA(this.request).subscribe((response: any) => {
                this.assessmentPayload.emit(this.request);
                this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: false });
                // this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.request);
                this.getAssessmentDetails();

                if (action === 'next') {
                  this.nextTab.emit(this.tabName)
                  this.auditRun = false;
                  this._notifierService._isAuditRun.next(true);
                  this.isAssesmentParamsHasMandatoryValues.emit(false);
                }
                else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
              });
            }
          } else {
            this.showHideParametersValidationPopUp = true;
          }
        }
        else {
          this.showHideParametersValidationPopUp = true;
        }
      }
      else {
        // this._addWBAService.saveCoverageAssessmentForWBA(this.request).subscribe((response: any) => {
        this.assessmentPayload.emit(this.request)
        if (action === 'next') {
          this.nextTab.emit(this.tabName)
          this.auditRun = false;
          this._notifierService._isAuditRun.next(true);
          this.isAssesmentParamsHasMandatoryValues.emit(false);
          this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: false });
          this.getAssessmentDetails();
        }
        else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
        // });
      }
    }
    else {
      this.requiredAssessmentCheck = this.checkRequiredRecommendedCoverageHasValuesErrorIcon();
      this.requiredRatingBasis = this.checkGLRatingBasisHasRequiredValuesErrorIcon();
      //making array blank
      let savedRatingBasis: any[] = []
      let isGLAvailable = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageName == "General Liability",)
      if (isGLAvailable) {
        savedRatingBasis = isGLAvailable.ratingBasis;//getting rating basis array
        isGLAvailable.ratingBasis = [];
      }
      // if (this.request.coverageAssessmentDetail[0]?.ratingBasis?.length == 0) {
      //   this.handleDetailsTabErrorIcon('GL Rating Basis', true);
      // }
      // else {
      //   this.handleDetailsTabErrorIcon('GL Rating Basis', false);
      // }

      if (this.request.coverageAssessmentDetail[0]?.coverageName == "Workers Compensation") {
        if (this.request.coverageAssessmentDetail[0]?.workercompRating?.length == 0) {
          this.handleDetailsTabErrorIcon('WC Rating', true);
          this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
        }
        else {
          this.handleDetailsTabErrorIcon('WC Rating', false);
        }

      }
      //updating assessment id in create assessment api request
      this.coverageAssessmentIDList.forEach((cov: any) => {
        this.request.coverageAssessmentDetail.forEach((element: any) => {
          element.coverages.forEach((param: any) => {
            if (param.coverageParameterId === cov.parameterId) {
              param.expiringAssessmentId = cov.expiringAssessmentId;
              param.expiringConditionDescription = cov.expiringDescription;
              param.recommendedAssessmentId = cov.recommendedAssessmentId;
              param.recommendedConditionDescription = cov.recommendedDescription;
              param.expiringValue = cov.expiringValue;
              param.recommendedValue = cov.recommendedValue;
              if (param.parameterName == 'Liability - Auto Symbol') {
                let paramsFromLocalCopy = JSON.parse(JSON.stringify(this.expiringValueForAutosymbol));
                let indexof10Object = paramsFromLocalCopy.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                let indexof10Index = paramsFromLocalCopy.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                if (indexof10Index > -1) {
                  paramsFromLocalCopy.splice(indexof10Index, 1)
                }
                paramsFromLocalCopy = paramsFromLocalCopy.sort().join(',');
                if (indexof10Index > -1) {
                  paramsFromLocalCopy = paramsFromLocalCopy + ',' + indexof10Object
                }

                param.expiringValue = paramsFromLocalCopy
                this.expiringValueForAutosymbol = paramsFromLocalCopy.split(',');

                let paramsFromLocalRecommended = JSON.parse(JSON.stringify(this.recommandedValueForAutoSymbol));
                let indexof10ObjectRecommended = paramsFromLocalRecommended.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                let indexof10IndexRecommended = paramsFromLocalRecommended.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                if (indexof10IndexRecommended > -1) {
                  paramsFromLocalRecommended.splice(indexof10IndexRecommended, 1)
                }
                paramsFromLocalRecommended = paramsFromLocalRecommended.sort().join(',');
                if (indexof10IndexRecommended > -1) {
                  paramsFromLocalRecommended = paramsFromLocalRecommended + ',' + indexof10ObjectRecommended
                }

                param.recommendedValue = paramsFromLocalRecommended
                this.recommandedValueForAutoSymbol = paramsFromLocalRecommended.split(',');
                if (param.recommendedValue.charAt(0) == ',') {
                  param.recommendedValue = param.recommendedValue.substring(1)
                }
                if (param.expiringValue.charAt(0) == ',') {
                  param.expiringValue = param.expiringValue.substring(1)
                }
              }
            }
          });
        });
      })
      this._addWBAService.saveCoverageAssessmentForWBA(this.request).subscribe((response: any) => {
        this.assessmentPayload.emit(this.request);
        if (this.requiredAssessmentCheck && this.requiredRatingBasis) this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: false });
        else this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
        // this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.request);
        this.getAssessmentDetails();

        let isGLAvailable = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageName == "General Liability",)
        if (isGLAvailable) {
          isGLAvailable.ratingBasis = savedRatingBasis;//setting rating basis array
        }
      });
    }

    //updating expiringValue and recommendedValue in local storage to display on page
    this.request.coverageAssessmentDetail.forEach((apiResponse: any) => {
      let covFromLocal = this.CoverageCheckList.find((x: any) => x.displayName == apiResponse.coverageName)
      apiResponse.coverages.forEach((apiCovParam: any) => {
        let paramsFromLocal = covFromLocal.coverageParameters.find((x: any) => x.displayName == apiCovParam.parameterName)
        paramsFromLocal.expiringValue = apiCovParam.expiringValue
        paramsFromLocal.recommendedValue = apiCovParam.recommendedValue
      })
    })
    this.setDefaultCoveragesExpiring();//updating Named Insured, Carrier (Issuing Company), Premium
    //updating local storage
    sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList));
    if (!this.checkRequiredExpiringOrRecommendedCoverageHasValues()) { // Checking mandatory recomended and expiring fields
      this.isAssesmentParamsHasMandatoryValues.emit(true);
    } else {
      this.isAssesmentParamsHasMandatoryValues.emit(false);
    }
    this.checkGLRatingBasisHasRequiredValuesErrorIcon();
  }

  getAssessmentDetails() {
    this._addWBAService.getCoverageAssessmentDetails(parseInt(this.activeWbaId)).subscribe((response: any) => {
      this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', response);
    })
  }


  //getting assessmentId by parameterId and value
  GetAssessmentIdByCoverageParameterIdAndValue(action: any) {
    let request: any[] = []
    //making api request
    this.CoverageCheckList.forEach((cov: any) => {
      cov.showLessButton = false;
      if (cov.coverageParameters) {
        cov.coverageParameters.forEach((element: any) => {
          let data = {
            parameterId: element.id,
            expiringValue: element?.expiringValue?.toString(),
            recommendedValue: element?.recommendedValue?.toString(),
          }
          request.push(data);
        });
      }
    })

    //setting first coverage as opened

    this._addWBAService.GetAssessmentIdByCoverageParameterIdAndValue(request).subscribe((response: any) => {
      if (response) {
        this.coverageAssessmentIDList = response.coverageParameterAssessmentList;
        let buttonAction = action;
        //updating assessment id in existing storage
        this.coverageAssessmentIDList.forEach((cov: any) => {
          this.CoverageCheckList.forEach((element: any) => {
            if (element.coverageParameters) {
              element.coverageParameters.forEach((param: any) => {
                if (param.id === cov.parameterId) {
                  param.expiringAssessmentId = cov.expiringAssessmentId;
                  param.expiringConditionDescription = cov.expiringDescription;
                  param.recommendedAssessmentId = cov.recommendedAssessmentId;
                  param.recommendedConditionDescription = cov.recommendedDescription;
                }
              });
            }
          });

        })
        if (buttonAction == 'SaveOnly') this.isDirty = false;
        this.saveWBA(buttonAction);
        sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList))
      }
    })
  }

  cancel(index: any) {
    this.deleteIndex = index;
    this._confirmBox.message = this._userMessages.deleteButtonWbaMessage;
    this._confirmBox.isVisible = true;
  }

  cancelGLRating(index: any) {
    this.deleteGLRatingBasisData = true;
    this.deleteIndex = index;
    this._confirmBox.message = this._userMessages.deleteButtonRatingBasisMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  handleDropdownExpiring(parameter: any) {
    this.isDirty = true
    if (parameter.expiringValue != "") {
      parameter.disableTextbox = true;
    }
    if (parameter.expiringValue == "undefined") {
      parameter.disableTextbox = false;

    }
    if (parameter.expiringValue == "") {
      parameter.disableTextbox = false;

    }
    //setting expiring value to recommended
    if (parameter.expiringValue != "") {
      parameter.recommendedValue = parameter.expiringValue
    }

    else
      parameter.recommendedValue = ""

    //converting array to string for this param - Liability - Auto Symbol
    if (parameter.displayName == 'Liability - Auto Symbol') {
      parameter.expiringValue = this.expiringValueForAutosymbol;
      parameter.recommendedValue = this.expiringValueForAutosymbol;
      this.recommandedValueForAutoSymbol = this.expiringValueForAutosymbol;
    }

    if (parameter.displayName == 'Claims Made or Occurrence' || parameter.displayName == 'Occurrence or Claims Made?' || parameter.displayName == 'Claims-Made or Occurrence?' || parameter.displayName == 'Occurrence or Claims-Made?') {
      this.currentParentTab.coverageParameters.forEach((x: any) => {
        if (x.displayName == 'Retro Date') {
          if (parameter.expiringValue == 'Claimsmade' || parameter.recommendedValue == 'Claimsmade') {
            x.isRequired = true;
            x.disableTextbox = false;
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.isRequired = false;
            x.disableTextbox = true;
            x.hideControl = true;
          }
        }

        if (x.displayName == '1 Year Extended Reporting Period Cost (% of policy premium)') {
          if (parameter.expiringValue == 'Claimsmade' || parameter.recommendedValue == 'Claimsmade') {
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.hideControl = true;
          }
        }

        if (x.displayName == '2 Year Extended Reporting Period Cost (% of policy premium)') {
          if (parameter.expiringValue == 'Claimsmade' || parameter.recommendedValue == 'Claimsmade') {
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.hideControl = true;
          }
        }

        if (x.displayName == '3 Year Extended Reporting Period Cost (% of policy premium)') {
          if (parameter.expiringValue == 'Claimsmade' || parameter.recommendedValue == 'Claimsmade') {
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.hideControl = true;
          }
        }
      })
    }

    this.eBLHideShowControlOnCondition(parameter);
    this.crimeDeductiblesUpdateValueOnCondition(parameter);

    // if (parameter.displayName == 'Claims Made') {
    //   this.currentParentTab.coverageParameters.forEach((x: any) => {
    //     if (x.displayName == 'Retro Date') {
    //       if (parameter.expiringValue == 'Claimsmade') {
    //         x.isRequired = true;
    //         x.disableTextbox = false;
    //       }
    //       else {
    //         x.expiringValue = "";
    //         x.recommendedValue = "";
    //         x.isRequired = false;
    //         x.disableTextbox = true;
    //       }
    //     }
    //   })
    // }

    let currentCoverage = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageId === this.currentParentTab.id)

    let currentParameter;
    if (currentCoverage)
      currentParameter = currentCoverage.coverages.find((item: any) => item.coverageParameterId === parameter.id)

    currentParameter.expiringValue = parameter.expiringValue;
    currentParameter.recommendedValue = parameter.recommendedValue;
    this._notifierService.setWBASaveAssessmentReq(this.request);

  }

  handleDropdownRecommended(parameter: any) {
    this.isDirty = true
    if (parameter.recommendedValue != "") {
      parameter.disableRecommendedTextbox = true;
    }
    if (parameter.recommendedValue == "undefined") {
      parameter.disableRecommendedTextbox = false;

    }
    if (parameter.recommendedValue == "") {
      parameter.disableRecommendedTextbox = false;

    }
    //For retro date required
    // if (parameter.displayName == 'Claims Made') {
    //   this.currentParentTab.coverageParameters.forEach((x: any) => {
    //     if (x.displayName == 'Retro Date') {
    //       if (parameter.recommendedValue == 'Claimsmade') {
    //         x.isRequired = true;
    //         x.disableTextbox = false;
    //       }
    //       else {
    //         x.recommendedValue = "";
    //         x.isRequired = false;
    //         x.disableTextbox = true;
    //       }
    //     }
    //   })
    // }

    //For retro date required
    if (parameter.displayName == 'Claims Made or Occurrence' || parameter.displayName == 'Occurrence or Claims Made?' || parameter.displayName == 'Claims-Made or Occurrence?' || parameter.displayName == 'Occurrence or Claims-Made?') {
      this.currentParentTab.coverageParameters.forEach((x: any) => {
        if (x.displayName == 'Retro Date') {
          if (parameter.recommendedValue == 'Claimsmade' || parameter.expiringValue == 'Claimsmade') {
            x.isRequired = true;
            x.disableTextbox = false;
            x.hideControl = false;
          }
          else {
            x.recommendedValue = "";
            x.isRequired = false;
            x.disableTextbox = true;
            x.hideControl = true;
          }
        }

        if (x.displayName == '1 Year Extended Reporting Period Cost (% of policy premium)') {
          if (parameter.recommendedValue == 'Claimsmade' || parameter.expiringValue == 'Claimsmade') {
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.hideControl = true;
          }
        }

        if (x.displayName == '2 Year Extended Reporting Period Cost (% of policy premium)') {
          if (parameter.recommendedValue == 'Claimsmade' || parameter.expiringValue == 'Claimsmade') {
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.hideControl = true;
          }
        }

        if (x.displayName == '3 Year Extended Reporting Period Cost (% of policy premium)') {
          if (parameter.recommendedValue == 'Claimsmade' || parameter.expiringValue == 'Claimsmade') {
            x.hideControl = false;
          }
          else {
            x.recommendedValue = "";
            x.hideControl = true;
          }
        }
      })
    }

    this.eBLHideShowRecommendedControlOnCondition(parameter);
    this.crimeDeductiblesUpdateValueOnCondition(parameter);

    let currentCoverage = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageId === this.currentParentTab.id)

    let currentParameter;
    if (currentCoverage) currentParameter = currentCoverage.coverages.find((item: any) => parameter.id === item.coverageParameterId)

    currentParameter.recommendedValue = parameter.recommendedValue;
    this._notifierService.setWBASaveAssessmentReq(this.request);

  }

  handleInputExpiring(parameter: any) {
    this.isDirty = true;
    if (parameter.valueType == "input-number" || parameter.valueType == "dropdown,input-number") {
      let formattedAmount = this._amountPipe.transform(parameter.expiringValue, 'USD', '', '1.0-0');
      parameter.recommendedValue = formattedAmount;
    }
    else {
      parameter.recommendedValue = parameter.expiringValue;
    }
    //formatting exp mode
    if (parameter.valueType == "input-expmod") {
      parameter.expiringValue = this.ValidateKey.formatDecimalNumber(parameter.expiringValue);
      parameter.recommendedValue = this.ValidateKey.formatDecimalNumber(parameter.expiringValue);
    }
    let currentCoverage = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageId === this.currentParentTab.id)

    let currentParameter;
    if (currentCoverage) currentParameter = currentCoverage.coverages.find((item: any) => parameter.id === item.coverageParameterId)

    if (currentParameter.valueType == 'input-currency') {
      let removedCommasFromExpiringValue = this._removeAmountPipe.transform(parameter.expiringValue)
      parameter.expiringValue = this._amountPipe.transform((removedCommasFromExpiringValue), 'USD', '', '1.0-0')
      let removedCommasFromrecommendedValue = this._removeAmountPipe.transform(parameter.recommendedValue)
      parameter.recommendedValue = this._amountPipe.transform((removedCommasFromrecommendedValue), 'USD', '', '1.0-0')
    }

    currentParameter.expiringValue = parameter.expiringValue;
    currentParameter.recommendedValue = parameter.recommendedValue;
    this._notifierService.setWBASaveAssessmentReq(this.request);
    this.eBLHideShowControlOnCondition(parameter);
    this.crimeDeductiblesUpdateValueOnCondition(parameter);
  }

  handleInputRecommended(parameter: any) {
    this.isDirty = true
    //formatting exp mode
    if (parameter.valueType == "input-expmod") {
      parameter.recommendedValue = this.ValidateKey.formatDecimalNumber(parameter.recommendedValue);
    }
    let currentCoverage = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageId === this.currentParentTab.id)
    let currentParameter;
    if (currentCoverage) currentParameter = currentCoverage.coverages.find((item: any) => parameter.id === item.coverageParameterId)

    currentParameter.recommendedValue = parameter.recommendedValue;
    this._notifierService.setWBASaveAssessmentReq(this.request);
    this.eBLHideShowRecommendedControlOnCondition(parameter);
    this.crimeDeductiblesUpdateValueOnCondition(parameter);
  }

  onDateChange(parameter: any): void {
    this.isDirty = true
    parameter.recommendedValue = parameter.expiringValue;//setting expiring date
    let currentCoverage = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageId === this.currentParentTab.id)
    let currentParameter;
    if (currentCoverage) currentParameter = currentCoverage.coverages.find((item: any) => parameter.id === item.coverageParameterId)

    currentParameter.expiringValue = this.datepipe.transform(parameter.expiringValue, 'MM/dd/yyyy');
    currentParameter.recommendedValue = this.datepipe.transform(parameter.recommendedValue, 'MM/dd/yyyy');


    //updating date format in storage
    let storageCurruntCoverage = this.CoverageCheckList.find((cov: any) => cov.id === this.currentParentTab.id)
    let currentStorageParameter;
    if (storageCurruntCoverage) currentStorageParameter = storageCurruntCoverage.coverageParameters.find((item: any) => item.id === parameter.id)
    currentStorageParameter.expiringValue = this.datepipe.transform(parameter.expiringValue, 'MM/dd/yyyy');
    currentStorageParameter.recommendedValue = this.datepipe.transform(parameter.recommendedValue, 'MM/dd/yyyy');
    this._notifierService.setWBASaveAssessmentReq(this.request);

  }

  onRecommendedDateChange(parameter: any): void {
    this.isDirty = true
    let currentCoverage = this.request.coverageAssessmentDetail.find((cov: any) => cov.coverageId === this.currentParentTab.id)
    let currentParameter;
    if (currentCoverage) currentParameter = currentCoverage.coverages.find((item: any) => parameter.id === item.coverageParameterId)

    currentParameter.recommendedValue = this.datepipe.transform(parameter.recommendedValue, 'MM/dd/yyyy');

    //updating date format in storage
    let storageCurruntCoverage = this.CoverageCheckList.find((cov: any) => cov.id === this.currentParentTab.id)
    let currentStorageParameter;
    if (storageCurruntCoverage) currentStorageParameter = storageCurruntCoverage.coverageParameters.find((item: any) => item.id === parameter.id)
    currentStorageParameter.recommendedValue = this.datepipe.transform(parameter.recommendedValue, 'MM/dd/yyyy');
    this._notifierService.setWBASaveAssessmentReq(this.request);
  }

  checkRequiredExpiringCoverageHasValues() {
    let result: boolean = true
    this.missingCoverage = []
    if (!this.expiringCarrier || !this.expiringPremium) {
      return result;
    }
    for (const element of this.CoverageCheckList) {
      if (element.coverageParameters) {
        let data = element.coverageParameters.find((cov: any) => cov.displayName === "Carrier (Issuing Company)");

        if (!data || data.expiringValue == "")
          return result;
        for (const param of element.coverageParameters) {
          if (param.displayName == "Carrier (Issuing Company)") {
            if (param.expiringValue == '')
              break;
          }
          if (param.isRequired && (param.expiringValue == "" || param.expiringValue == "undefined")) {
            this.missingCoverage.push(element.displayName)
            result = false
            break;
          }
        }
      }
    }
    return result;
  }

  checkGLRatingBasisHasRequiredValuesErrorIcon() {
    let result: boolean = true
    let finding_GL = this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis' || x.displayName == 'Cyber Rating Basis' || x.displayName == 'WC Rating');
    if (finding_GL) {
      if (finding_GL?.ratingBasis?.length == 0 || finding_GL?.workercompRating?.length == 0) {
        this.handleDetailsTabErrorIcon(finding_GL?.displayName, true);
        this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
        result = false
      }
      else {
        this.handleDetailsTabErrorIcon(finding_GL?.displayName, false);
      }
    }
    return result;
  }

  checkRequiredRecommendedCoverageHasValuesErrorIcon() {
    let result: boolean = true
    // this.missingCoverage = []

    for (const element of this.CoverageCheckList) {
      if (element.coverageParameters) {
        console.log("coverageParameters", element.coverageParameters)
        for (const param of element.coverageParameters) {
          console.log("", param)
          if (param.displayName != "Named Insured") {
            if (param.isRequired && (param.recommendedValue == "" || param.recommendedValue == "undefined")) {
              // this.missingCoverage.push(element.displayName);
              this.handleDetailsTabErrorIcon(element.displayName, true);
              this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
              result = false
              break;
            }
            else if (param.isRequired && (param.recommendedValue != "" || param.recommendedValue != "undefined")) {
              this.handleDetailsTabErrorIcon(element.displayName, false);
            }
          }
        }
      }
    }
    return result;
  }

  checkRequiredRecommendedCoverageHasValues() {
    let result: boolean = true
    this.missingCoverage = []

    for (const element of this.CoverageCheckList) {
      if (element.coverageParameters) {
        for (const param of element.coverageParameters) {
          if (param.displayName != "Named Insured") {
            if (param.isRequired && (param.recommendedValue == "" || param.recommendedValue == "undefined")) {
              this.missingCoverage.push(element.displayName);
              this.handleDetailsTabErrorIcon(element.displayName, true);
              this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
              result = false
              break;
            }
            else if (param.isRequired && (param.recommendedValue != "" || param.recommendedValue != "undefined")) {
              this.handleDetailsTabErrorIcon(element.displayName, false);
            }
          }
        }
      }
    }
    return result;
  }

  checkGLRatingBasisHasRequiredValues() {
    let result: boolean = true
    let finding_GL = this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis' || x.displayName == 'Cyber Rating Basis' || x.displayName == 'WC Rating');
    if (finding_GL) {
      if (finding_GL?.ratingBasis?.length == 0 || finding_GL?.workercompRating?.length == 0) {
        this.showRequiredExposureTypePopUp = true;
        this.handleDetailsTabErrorIcon(finding_GL?.displayName, true);
        this.handleCovAssessmentIconStatus.emit({ label: "Coverage Assessment", value: true });
        result = false
      }
      else {
        this.handleDetailsTabErrorIcon(finding_GL?.displayName, false);
      }
    }
    return result;
  }

  handleWCRating(action: string, WCRatingDetails: any, index: number) {
    let { location, classCode } = WCRatingDetails;
    if (action === 'add') {
      this.isAddWCRatingSubmitted = true;
      if (location && classCode) this.saveWCRating()
    } else if (action === "trash") {

      let record = this.currentParentTab.workercompRating.find((obj: any, index1: number) => index === index1);
      this.deleteWCRating(record.id)
      this.currentParentTab.workercompRating.splice(index, 1)
    } else if (action === "edit") {
      let record = this.currentParentTab.workercompRating.find((obj: any, index1: number) => index === index1);
      this.editWCRatingForm = { ...record };
      this.addWCRatingForm = { ...this.defaultWCRatingData };
      this.editIndex = index;
      this.isAddWCRatingSubmitted = false;
      this.isEditWCRatingSubmitted = false;

    }
    else if (action === "cross") {
      this.editIndex = -1;
      this.isAddWCRatingSubmitted = false;
      this.isEditWCRatingSubmitted = false;
      this.editWCRatingForm = { ...this.defaultWCRatingData };
      this.addWCRatingForm = { ...this.defaultWCRatingData };
    } else if (action === "save") {
      this.isEditWCRatingSubmitted = true;
      if (location && classCode) {
        this.updateWCRating();
        this.currentParentTab.workercompRating[this.editIndex] = { ...WCRatingDetails };
        this.editWCRatingForm = { ...this.defaultWCRatingData };
        this.addWCRatingForm = { ...this.defaultWCRatingData };
        this.editIndex = -1;
      }
    }
  }

  handleGLRatingBasis(action: string, GLRatingBasisDetails: any, index: number) {
    let { exposureType, value } = GLRatingBasisDetails;
    if (action === 'add') {
      this.isAddGLRatingBasisSubmitted = true;
      if (exposureType && value) {
        //finding GL Rating Basis tab
        let check = this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis' || x.displayName == 'Cyber Rating Basis')
        let findDuplicateExposure = check.ratingBasis.find((y: any) => y.exposureId == exposureType.id)
        if (findDuplicateExposure && this.currentParentTab.displayName !== 'GL Rating Basis') {
          this.showHideExistingValidationPopUp = true;
          return
        } else {
          this.saveGLRatingBasis()
          this.isDollarVisible = false;
        }
      }
    }
    else if (action === "edit") {
      let record = this.currentParentTab.ratingBasis.find((obj: any, index1: number) => index === index1);
      this.editGLRatingBasisForm = { ...record };
      let formattedValueData
      if (this.editGLRatingBasisForm.exposureType == 'Payroll' || this.editGLRatingBasisForm.exposureType == 'Gross Sales'
        || this.editGLRatingBasisForm.exposureType == 'Revenue' || this.editGLRatingBasisForm.exposureType == 'Total Cost') {
        this.isDollarVisible = true;
        let removeComma = this.editGLRatingBasisForm.value.replace(/,/g, '')
        let removeSpaces = removeComma.substring(2)
        formattedValueData = removeSpaces;
      }
      else {
        formattedValueData = this.editGLRatingBasisForm.value;
        this.isDollarVisible = false;
      }
      this.editGLRatingBasisForm.value = formattedValueData
      this.addGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
      this.editIndex = index;
      this.isAddGLRatingBasisSubmitted = false;
      this.isEditGLRatingBasisSubmitted = false;

    }
    else if (action === "trash") {

      let record = this.currentParentTab.ratingBasis.find((obj: any, index1: number) => index === index1);
      this.deleteGLRatingBasis(record.id);
      this.currentParentTab.ratingBasis.splice(index, 1)
    }
    else if (action === "cross") {
      this.editIndex = -1;
      this.isDollarVisible = false;
      this.isAddGLRatingBasisSubmitted = false;
      this.isEditGLRatingBasisSubmitted = false;
      this.editGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
      this.addGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
    } else if (action === "save") {
      if (exposureType && value) {

        let check = this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis' || x.displayName == 'Cyber Rating Basis')
        let findDuplicateExposureIndex = check.ratingBasis.findIndex((y: any) => y.exposureId == this.editGLRatingBasisForm.exposureId)
        let findDuplicateExposure = check.ratingBasis.find((y: any) => y.exposureId == this.editGLRatingBasisForm.exposureId)
        if (findDuplicateExposure && (this.editIndex !== findDuplicateExposureIndex) && this.currentParentTab.displayName !== 'GL Rating Basis') {
          this.showHideExistingValidationPopUp = true;
          return
        } else {
          this.isEditGLRatingBasisSubmitted = true;
          this.updateGLRatingBasis()
          this.isDollarVisible = false;
          if (GLRatingBasisDetails.exposureType == 'Payroll' || GLRatingBasisDetails.exposureType == 'Gross Sales'
            || GLRatingBasisDetails.exposureType == 'Revenue' || GLRatingBasisDetails.exposureType == 'Total Cost') {
            let formattedValueData = this._amountPipe.transform(GLRatingBasisDetails.value, 'USD', '$', '1.0-0');
            GLRatingBasisDetails.value = formattedValueData
          }

          this.currentParentTab.ratingBasis[this.editIndex] = { ...GLRatingBasisDetails };
          this.editGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
          this.addGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
          this.editIndex = -1;
        }
      }
    }
  }

  saveWCRating() {
    let saveData = {
      "wbaWorkerCompensationClassCodes": [
        {
          "id": 0,
          "wbaId": this.activeWbaId,
          "industryId": 0,
          "coverageId": this.currentParentTab.id,
          "location": this.addWCRatingForm.location,
          "classCode": this.addWCRatingForm.classCode,
          "subCode": this.addWCRatingForm.subCode,
          "fullTimeEmployees": this.addWCRatingForm.fullTimeEmployees,
          "partTimeEmployees": this.addWCRatingForm.partTimeEmployees,
          "payroll": this.addWCRatingForm.payroll,
          "stateOfExposure": this.addWCRatingForm.stateOfExposure.stateCode

        }
      ]
    }

    this._addWBAService.saveWCRating(saveData).subscribe(resp => {
      if (resp) {
        //   this.getCoveragedata() //commented now
        saveData.wbaWorkerCompensationClassCodes[0].id = resp;
        this.currentParentTab.workercompRating.push(saveData.wbaWorkerCompensationClassCodes[0]);
        //clearing controls
        this.addWCRatingForm = { ...this.defaultWCRatingData };
        this.isAddWCRatingSubmitted = false;
        this.handleDetailsTabErrorIcon('WC Rating', false);
      }
    },
      (err: any) => {
        this.errorList = []
        this.showError = true;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        this.errorMessage = "Error : " + err.message;
        this.errorList.push(this.errorMessage);
      });

  }

  handleRating(type: string, index: number) {
    if (type === 'plus') {
      this.isRatingAddSubmitted = true;
      let ratingBasisValue = this._removeAmountPipe.transform(this.rating.value)
      if (this.rating.exposure && (ratingBasisValue) > 0) {
        if (!this.currentParentTab.rating) this.currentParentTab.rating = [];
        this.currentParentTab.rating.push(this.rating);
        this.rating = { ...this.defaultRating };
      }
    } else {
      this.currentParentTab.rating.splice(index, 1);
    }
  }

  onGLRatingExposureTypeChange() {
    //get exposure type name
    if (this.editGLRatingBasisForm.value) {
      let expName = this.currentParentTab.coverageExposures.find((x: any) => x.id == this.editGLRatingBasisForm.exposureId)
      this.editGLRatingBasisForm.exposureType = expName.displayName
    }
    if (this.addGLRatingBasisForm.exposureType.displayName == 'Payroll' || this.addGLRatingBasisForm.exposureType.displayName == 'Gross Sales'
      || this.addGLRatingBasisForm.exposureType.displayName == 'Revenue' || this.addGLRatingBasisForm.exposureType.displayName == 'Total Cost' ||
      this.editGLRatingBasisForm.exposureType == 'Payroll' || this.editGLRatingBasisForm.exposureType == 'Gross Sales'
      || this.editGLRatingBasisForm.exposureType == 'Revenue' || this.editGLRatingBasisForm.exposureType == 'Total Cost') {
      this.isDollarVisible = true;
    }
    else { this.isDollarVisible = false; }


  }

  saveGLRatingBasis() {
    this.isAddGLRatingBasisSubmitted = true;
    let formattedValueData
    if (this.addGLRatingBasisForm.exposureType.displayName == 'Payroll' || this.addGLRatingBasisForm.exposureType.displayName == 'Gross Sales'
      || this.addGLRatingBasisForm.exposureType.displayName == 'Revenue' || this.addGLRatingBasisForm.exposureType.displayName == 'Total Cost') {
      formattedValueData = this._amountPipe.transform(this.addGLRatingBasisForm.value, 'USD', '$', '1.0-0');
    }
    else {
      formattedValueData = this._amountPipe.transform(this.addGLRatingBasisForm.value, 'USD', '', '1.0-0');
    }
    let saveData = {
      "ratingBasis": [
        {
          "id": 0,
          "wbaId": this.activeWbaId,
          "coverageId": this.currentParentTab.id,
          "exposureId": this.addGLRatingBasisForm.exposureType.id,
          "exposureType": this.addGLRatingBasisForm.exposureType.displayName,
          "value": formattedValueData,
          "description": this.addGLRatingBasisForm.description,
        }
      ]
    }

    this._addWBAService.saveGLRatingBasis(saveData).subscribe(resp => {
      if (resp) {
        this.isDollarVisible = false;
        saveData.ratingBasis[0].id = resp;
        this.currentParentTab.ratingBasis.push(saveData.ratingBasis[0]);
        //clearing controls
        this.addGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
        this.isAddGLRatingBasisSubmitted = false;
        if (this.request.coverageAssessmentDetail[0].coverageName == "General Liability") {
          this.handleDetailsTabErrorIcon('GL Rating Basis', false);
        }
        if (this.request.coverageAssessmentDetail[0].coverageName == "Cyber") {
          this.handleDetailsTabErrorIcon('Cyber Rating Basis', false);
        }
      }
    },
      (err: any) => {
        this.errorList = []
        this.showError = true;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        this.errorMessage = "Error : " + err.message;
        this.errorList.push(this.errorMessage);
      });

  }

  updateGLRatingBasis() {
    this.isDollarVisible = false;
    //get exposure type name
    let expName = this.currentParentTab.coverageExposures.find((x: any) => x.id == this.editGLRatingBasisForm.exposureId)
    this.editGLRatingBasisForm.exposureType = expName.displayName
    this.isEditGLRatingBasisSubmitted = true;
    let formattedValueData
    if (this.editGLRatingBasisForm.exposureType == 'Payroll' || this.editGLRatingBasisForm.exposureType == 'Gross Sales'
      || this.editGLRatingBasisForm.exposureType == 'Revenue' || this.editGLRatingBasisForm.exposureType == 'Total Cost') {
      formattedValueData = this._amountPipe.transform(this.editGLRatingBasisForm.value, 'USD', '$', '1.0-0');
    }
    else {
      formattedValueData = this._amountPipe.transform(this.editGLRatingBasisForm.value, 'USD', '', '1.0-0');
    }
    let saveData =
    {
      "id": this.editGLRatingBasisForm.id,
      "wbaId": this.activeWbaId,
      "coverageId": this.editGLRatingBasisForm.coverageId,
      "exposureId": this.editGLRatingBasisForm.exposureId,
      "exposureType": this.editGLRatingBasisForm.exposureType,
      "value": formattedValueData,
      "description": this.editGLRatingBasisForm.description,
    }

    this._addWBAService.updateGLRatingBasis(saveData).subscribe(resp => {
      if (resp) {
        let editValue = this.currentParentTab.ratingBasis.find((x: any) => x.id == saveData.id)
        if (editValue) {
          editValue.value = saveData.value
        }
        //clearing controls
        this.editGLRatingBasisForm = { ...this.defaultGLRatingBasicData };
        this.isEditGLRatingBasisSubmitted = false;
      }
    }
      ,
      (err: any) => {
        this.errorList = []
        this.showError = true;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        this.errorMessage = "Error : " + err.message;
        this.errorList.push(this.errorMessage);
      });

  }

  updateWCRating() {
    this.isEditWCRatingSubmitted = true;
    let saveData = {
      "wbaWorkerCompensationClassCodes": [
        {
          "id": this.editWCRatingForm.id,
          "wbaId": this.activeWbaId,
          "industryId": 1,
          "coverageId": this.currentParentTab.id,
          "location": this.editWCRatingForm.location,
          "classCode": this.editWCRatingForm.classCode,
          "subCode": this.editWCRatingForm.subCode,
          "fullTimeEmployees": this.editWCRatingForm.fullTimeEmployees,
          "partTimeEmployees": this.editWCRatingForm.partTimeEmployees,
          "payroll": this.editWCRatingForm.payroll,
          "stateOfExposure": this.editWCRatingForm.stateOfExposure
        }
      ]
    }

    this._addWBAService.updateWCRating(saveData).subscribe(resp => {
      if (resp) {
        this.editWCRatingForm = { ...this.defaultWCRatingData };
        this.isEditWCRatingSubmitted = false;
      }
    },
      (err: any) => {
        this.errorList = []
        this.showError = true;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        this.errorMessage = "Error : " + err.message;
        this.errorList.push(this.errorMessage);
      });

  }

  deleteWCRating(id: number) {
    this._addWBAService.deleteWCRating(this.activeWbaId, id).subscribe((resp) => {
      if (resp) {
        this.confirmBoxCancel();
        if (this.currentParentTab.workercompRating.length == 0) this.handleDetailsTabErrorIcon('WC Rating', true);
      }
    },
      (error) => {
        let obj = error.error.errors;
        if (obj) {
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
        }
      }
    );
  }

  deleteGLRatingBasis(id: number) {

    this._addWBAService.deleteGLRatingBasis(this.activeWbaId, id).subscribe((resp) => {
      if (resp) {
        this.confirmBoxCancel();
      }
    },
      (error) => {
        let obj = error.error.errors;
        if (obj) {
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
        }
      }
    );
  }

  // To Add currency format
  addCurrencyFormat(element: any, service: any, key: string) {
    var formattedAmount;
    formattedAmount = this._amountPipe.transform(element.target.value, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    service[key] = element.target.value;
  }

  // To remove amount
  removeCurrencyFormat(element: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  //To remove white spaces
  removeSpaces(element: any, service: any, key: string) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    service[key] = element.target.value;
  }

  hideShowControlOnCondition() {
    this.currentParentTab.coverageParameters = this.currentParentTab?.coverageParameters?.map((obj: any) => ({ ...obj, "hideControl": false }));

    let retroDateData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == 'Retro Date');
    let firstYearExtendedCostData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == '1 Year Extended Reporting Period Cost (% of policy premium)');
    let secondYearExtendedCostData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == '2 Year Extended Reporting Period Cost (% of policy premium)');
    let thirdYearExtendedCostData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == '3 Year Extended Reporting Period Cost (% of policy premium)');

    let occurrenceOrClaimMadeData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => (x.displayName == 'Claims Made or Occurrence' || x.displayName == 'Occurrence or Claims Made?' || x.displayName == 'Claims-Made or Occurrence?' || x.displayName == 'Occurrence or Claims-Made?'));
    if (occurrenceOrClaimMadeData && (occurrenceOrClaimMadeData.expiringValue == 'Claimsmade' || occurrenceOrClaimMadeData.recommendedValue == 'Claimsmade')) {
      if (retroDateData) {
        retroDateData.isRequired = true;
        retroDateData.hideControl = false;
      }

      if (firstYearExtendedCostData) {
        firstYearExtendedCostData.hideControl = false;
      }

      if (secondYearExtendedCostData) {
        secondYearExtendedCostData.hideControl = false;
      }

      if (thirdYearExtendedCostData) {
        thirdYearExtendedCostData.hideControl = false;
      }
    }
    else {
      if (retroDateData) {
        retroDateData.hideControl = true;
        retroDateData.isRequired = false;
      }

      if (firstYearExtendedCostData) {
        firstYearExtendedCostData.hideControl = true;
      }

      if (secondYearExtendedCostData) {
        secondYearExtendedCostData.hideControl = true;
      }

      if (thirdYearExtendedCostData) {
        thirdYearExtendedCostData.hideControl = true;
      }
    }

    let EBLClaimsMadeData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == 'EBL Claims Made');
    let eBLOccurrenceLimitData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == 'EBL Occurrence Limit');
    let eBLAggregateLimitData = this.currentParentTab?.coverageParameters?.find((x: { displayName: any; }) => x.displayName == 'EBL Aggregate Limit');

    if ((eBLOccurrenceLimitData && (eBLOccurrenceLimitData.expiringValue || eBLOccurrenceLimitData.recommendedValue)) || (eBLAggregateLimitData && (eBLAggregateLimitData.expiringValue || eBLAggregateLimitData.recommendedValue))) {
      if (EBLClaimsMadeData) {
        EBLClaimsMadeData.isRequired = true;
        EBLClaimsMadeData.hideControl = false;
      }
    }
    else {
      if (EBLClaimsMadeData) {
        retroDateData.isRequired = false;
        EBLClaimsMadeData.hideControl = true;
      }
    }

    let EblRetroDateData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Retro Date');

    if (EBLClaimsMadeData && (EBLClaimsMadeData.expiringValue == 'Yes' || EBLClaimsMadeData.recommendedValue == 'Yes')) {
      if (EblRetroDateData) {
        EblRetroDateData.isRequired = true;
        EblRetroDateData.hideControl = false;
      }
    }
    else {
      if (EblRetroDateData) {
        EblRetroDateData.isRequired = false;
        EblRetroDateData.hideControl = true;
      }
    }
  }

  eBLHideShowControlOnCondition(parameter: any) {
    let eblRetroDateData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Retro Date');

    if (parameter.displayName == 'EBL Occurrence Limit' || parameter.displayName == 'EBL Aggregate Limit') {
      this.currentParentTab.coverageParameters.forEach((x: any) => {
        var expiringValueData = '';
        var recommendedValueData = '';
        if (x.displayName == 'EBL Claims Made') {
          if (parameter.displayName == 'EBL Occurrence Limit') {
            expiringValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Aggregate Limit').expiringValue;
            recommendedValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Aggregate Limit').recommendedValue;
          }
          else {
            expiringValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Occurrence Limit').expiringValue;
            recommendedValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Occurrence Limit').recommendedValue;
          }

          if (parameter.expiringValue || expiringValueData || parameter.recommendedValue || recommendedValueData) {
            x.isRequired = true;
            x.hideControl = false;
          }
          else {
            x.expiringValue = "";
            x.recommendedValue = "";
            x.isRequired = false;
            x.hideControl = true;

            // If EBL Claims Made hide then EBL Retro Date also hide because EBL Retro Date dependent on EBL Claims Made
            eblRetroDateData.expiringValue = "";
            eblRetroDateData.recommendedValue = "";
            eblRetroDateData.isRequired = false;
            eblRetroDateData.hideControl = true;
          }
        }
      })
    }

    if (parameter.displayName == 'EBL Claims Made') {
      if (eblRetroDateData) {
        if (parameter.expiringValue == 'Yes' || parameter.recommendedValue == 'Yes') {
          eblRetroDateData.isRequired = true;
          eblRetroDateData.hideControl = false;
        }
        else {
          eblRetroDateData.expiringValue = "";
          eblRetroDateData.recommendedValue = "";
          eblRetroDateData.isRequired = false;
          eblRetroDateData.hideControl = true;
        }
      }
    }
  }

  eBLHideShowRecommendedControlOnCondition(parameter: any) {
    let eblRetroDateData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Retro Date');
    if (parameter.displayName == 'EBL Occurrence Limit' || parameter.displayName == 'EBL Aggregate Limit') {
      this.currentParentTab.coverageParameters.forEach((x: any) => {
        var recommendedValueData = '';
        var expiringValueData = '';
        if (x.displayName == 'EBL Claims Made') {
          if (parameter.displayName == 'EBL Occurrence Limit') {
            recommendedValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Aggregate Limit').recommendedValue;
            expiringValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Aggregate Limit').expiringValue;
          }
          else if (parameter.displayName == 'EBL Aggregate Limit') {
            recommendedValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Occurrence Limit').recommendedValue;
            expiringValueData = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'EBL Occurrence Limit').expiringValue;
          }
          if (parameter.recommendedValue || recommendedValueData || parameter.expiringValue || expiringValueData) {
            x.isRequired = true;
            x.hideControl = false;
          }
          else {
            x.recommendedValue = "";
            x.isRequired = false;
            x.hideControl = true;

            // If EBL Claims Made hide then EBL Retro Date also hide because EBL Retro Date dependent on EBL Claims Made
            eblRetroDateData.recommendedValue = "";
            eblRetroDateData.isRequired = false;
            eblRetroDateData.hideControl = true;
          }
        }
      })
    }

    if (parameter.displayName == 'EBL Claims Made') {
      if (eblRetroDateData) {
        if (parameter.recommendedValue == 'Yes' || parameter.expiringValue == 'Yes') {
          eblRetroDateData.isRequired = true;
          eblRetroDateData.hideControl = false;
        }
        else {
          eblRetroDateData.recommendedValue = "";
          eblRetroDateData.isRequired = false;
          eblRetroDateData.hideControl = true;
        }
      }
    }
  }

  crimeDeductiblesUpdateValueOnCondition(parameter: any) {
    let formattedValueData = this._amountPipe.transform(500, 'USD', '$', '1.0-0');

    if (parameter.displayName == 'Employee Theft / Dishonesty') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Employee Theft / Dishonesty Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Forgery or Alteration') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Forgery or Alteration Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Computer Fraud') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Computer Fraud Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Funds Transfer Fraud') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Funds Transfer Fraud Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Inside The Premises (Theft)') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Inside The Premises (Theft) Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Outside The Premises (Theft)') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Outside The Premises (Theft) Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Money Orders and Counterfeit Currency') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Money Orders and Counterfeit Currency Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Credit Card Fraud') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Credit Card Fraud Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }

    if (parameter.displayName == 'Theft of Client Property') {
      let employeeTheftDeductible = this.currentParentTab.coverageParameters.find((x: { displayName: any; }) => x.displayName == 'Theft of Client Property Deductible');
      if (parameter.expiringValue || parameter.recommendedValue) {

        if (employeeTheftDeductible) {
          employeeTheftDeductible.expiringValue = employeeTheftDeductible.expiringValue == "" ? formattedValueData : employeeTheftDeductible.expiringValue;
          employeeTheftDeductible.recommendedValue = employeeTheftDeductible.recommendedValue == "" ? formattedValueData : employeeTheftDeductible.recommendedValue;
        }
      }
    }
  }
}

export interface defaultExpiringKeysModel {
  'Carrier (Issuing Company)': string;
  'Named Insured': string;
}
