<div class="main-body-con">
  <div class="page-container">
    <div class="mid-con pb-0 px-4">
      <div class="no-round-border" id="add-wba">
        <div class="add-wba-left" style="display: none" [class.collapsedivleft]="classAppliedLeft">
          <div class="togglebtn-con-left">
            <button (click)="toggleClassLeft()" class="togglebtn" [class.btnpos]="btnclass2"></button>
          </div>
          <ul class="nav nav-pills pro-left" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <li class="curr">
              <a href="#" title="Application"><img src="assets/images/application.svg" width="12px"
                  alt="" /><span>Application</span></a>
            </li>
            <li>
              <a href="#" title="Policy Detail & Loss Run"><img src="assets/images/details.svg" width="14px" alt="" />
                <span>Policy Detail & Loss Run</span></a>
            </li>
            <li>
              <a href="#" title="Additional Information"><img src="assets/images/add-info.svg" width="14px" alt="" />
                <span>Additional Information</span></a>
            </li>
            <li>
              <a href="#" title="Coverage Checklist">
                <img src="assets/images/check-list.svg" width="14px" alt="" /><span>Coverage Checklist</span></a>
            </li>
            <li>
              <a href="#" title="ACORD"><img src="assets/images/acord.svg" width="14px" alt="" />
                <span>ACORD</span></a>
            </li>
            <li>
              <a href="#" title="RSM Approval"><img src="assets/images/right.svg" width="14px" alt="" />
                <span>RSM Approval</span></a>
            </li>
            <li>
              <a href="#" title="Submit to Marketing">
                <img src="assets/images/submit.svg" width="14px" alt="" /><span>Submit to Marketing</span></a>
            </li>
            <li>
              <a href="#" title="Marketing">
                <img src="assets/images/marketing.svg" width="18px" alt="" /><span>Marketing</span></a>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-12 ">
            <div class="pro-right-header hdp">
              <div class="page-hdr">
                <h2>Add Whiteboard Activity</h2>
              </div>
              <div class="pro-name-city">
                <h2 *ngIf="
                    selectedWhiteboardName &&
                    selectedWhiteboardName.length > 0
                  ">
                  {{ selectedWhiteboardName }}
                </h2>
              </div>

              <div class="pro-action ">
                <div class="act-link"></div>
                <div class="text-end flex-div">
                  <div class="es-link">
                    <a _ngcontent-nop-c184="" (click)="cancel()" href="javascript:void(0)" title="close"
                      class="btn btn-outline-primary"><span><img src="assets/images/back.svg" alt=""
                          class="plus-icon" /></span>Back to
                      List</a>
                  </div>
                  <!-- <button class="btn btn-primary " (click)="cancel()">
                    Back to List
                  </button> -->
                </div>
              </div>
            </div>
            <div class="mt-0">
              <div class="row" *ngIf="showError">
                <div class="col-12">
                  <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                    <ul class="view-error">
                      <li *ngFor="let err of errorList">{{ err }}</li>
                    </ul>
                    <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                      aria-label="Close"></button>
                  </div>
                </div>
              </div>
              <form [formGroup]="whiteBoardForm">
                <div class="pro-content pt-1 pb-0">
                  <div class="row" id="viewinfo">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="card">
                        <div class="card-header cl-hdr">
                          <h5>Consumer & Policy Details</h5>
                          <div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="card-text row addw">
                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-6 mb-1 has-validation">
                                <label class="form-label">Company Name <span>*</span></label>

                                <div class="flex-div">
                                  <p-autoComplete placeholder="Company Name" class="form-control auto-comp"
                                    [showEmptyMessage]="true" [suggestions]="accountList"
                                    (completeMethod)="getCompanyList('companyName', $event)" field="prospectName"
                                    [minLength]="1" (onSelect)="selectItem($event.value, 'companyName')"
                                    (onClear)="clearValue()" (onDropdownClick)="getCompanyList('companyName', $event)"
                                    (onClear)="lookUpDataFilterChanges('companyName')" formControlName="companyName"
                                    [ngClass]="{error:f.companyName.invalid && isSubmitted}">
                                    <ng-template let-company pTemplate="item">
                                      <div class="company-item">
                                        <div>{{ company.prospectName }}</div>
                                      </div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <img (click)="openLookupModal('companyName')" src="assets/images/search.svg" alt=""
                                    class="ms-2" />
                                </div>

                                <div class="display-error">
                                  <div *ngIf="f.companyName.invalid && isSubmitted">
                                    <span *ngIf="f.companyName.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.companyName.required
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div class="display-error">
                                  <span *ngIf="invalidCompanyName && isSubmitted">
                                    {{ _AddWhiteBoardScreen.companyName.companyNameInvalid }}
                                  </span>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1 date-control has-validation">
                                <label class="form-label" for="effectiveDate">Effective Date <span>*</span></label>
                                <p-calendar [readonlyInput]="true" class="form-control" placeholder="MM/DD/YYYY"
                                  [showIcon]="true" [iconDisplay]="'input'" (onSelect)="changeDate($event)" dateFormat="mm/dd/yy"
                                  inputId="effectiveDate" [ngClass]="{error:f.effectiveDate.invalid && isSubmitted}"
                                  formControlName="effectiveDate"></p-calendar>
                                <div class="display-error">
                                  <div *ngIf="f.effectiveDate.invalid && isSubmitted">
                                    <span *ngIf="f.effectiveDate.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.effDate.required
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1 has-validation">
                                <label class="form-label">Target Premium <span>*</span></label>
                                <div class="pos-re">
                                  <span class="dollor">$</span>
                                  <input class="form-control error dollor-input"
                                    [ngClass]="{error:f.targetPremium.invalid && isSubmitted}"
                                    (keypress)="ValidateKey.integer($event, 15)" formControlName="targetPremium"
                                    (focusout)="addCurrencyFormat($event, f.targetPremium)" maxlength="21"
                                    (focus)="removeCurrencyFormat($event,f.targetPremium)"
                                    (focusout)="removeSpaces($event, f.targetPremium)"
                                    (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value)" />
                                </div>
                                <div class="display-error">
                                  <div *ngIf="f.targetPremium.invalid && isSubmitted">
                                    <span *ngIf="f.targetPremium.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.targetPremium.required
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Line of Business <span>*</span></label>
                                <select class="select-cs form-select" [(ngModel)]="selectedValue"
                                  [ngClass]="{error:f.businessLineId.invalid && isSubmitted}"
                                  formControlName="businessLineId"
                                  (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value)">
                                  <option disabled value="">
                                    Select Business Line
                                  </option>
                                  <option *ngFor="let line of businessLine.businessLineList" [ngValue]="line"
                                    (click)="selectItem($event, 'businssLine')">
                                    {{ line.businessLineName }}
                                  </option>
                                </select>
                                <div class="display-error">
                                  <div *ngIf="f.businessLineId.invalid && isSubmitted">
                                    <span *ngIf="f.businessLineId.errors?.required">
                                      {{ _AddWhiteBoardScreen.line.required }}
                                    </span>
                                  </div>
                                </div>
                              </div>


                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Policy Type <span *ngIf="showPolicyTypeStar"
                                    class="star-color">*</span></label>
                                <select class="select-cs form-select"
                                  [ngClass]="{error:f.policyTypeId.invalid && isSubmitted}"
                                  formControlName="policyTypeId">
                                  <option disabled value="">
                                    Select Policy Type
                                  </option>
                                  <option *ngFor="let policyType of policyTypeList"
                                    [ngValue]="policyType.businessLineId" (click)="selectItem($event, 'policyType')">
                                    {{ policyType.businessLineName }}
                                  </option>
                                </select>
                                <div class="display-error">
                                  <div *ngIf="f.policyTypeId.invalid && isSubmitted">
                                    <span *ngIf="f.policyTypeId.errors?.required">
                                      {{ _AddWhiteBoardScreen.policyType.required }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1 priority">
                                <label class="form-label">Priority <span>*</span></label>
                                <div class="dropdown">
                                  <button class="form-control dropdown-toggle priorityButton" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{SelectedWbaPriorityName ? SelectedWbaPriorityName : 'Select Priority'}}
                                  </button>
                                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                      <a class="dropdown-item" *ngFor="let bs of WBAPriority.wbaPriorityList"
                                        (click)="selectItem(bs,'wbaPriorityList')">{{ bs.value }}</a>
                                    </li>
                                  </ul>
                                </div>
                                <div class="display-error">
                                  <div *ngIf="f.priorityId.invalid && isSubmitted">
                                    <span *ngIf="f.priorityId.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.priority.required
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Lead Generator <span>*</span></label>
                                <div class="flex-div">
                                  <p-autoComplete class="form-control auto-comp" placeholder="Lead Generator"
                                    formControlName="leadGenerator"
                                    optionLabel="name"
                                    [ngClass]="{error: f.leadGenerator.invalid && isSubmitted}"
                                    [showEmptyMessage]="true" [suggestions]="leadGenratorlist"
                                    [minLength]="1"
                                    (onSelect)="selectItem($event.value, 'leadGenerator')"
                                    (onClear)="lookUpDataFilterChanges('leadGenerator')"
                                    (completeMethod)="getCompanyList('leadGenerator', $event)"
                                    (onDropdownClick)="getCompanyList('leadGenerator', $event)">
                                    <ng-template let-generator pTemplate="item">
                                      <div class="company-item">
                                        <div>{{ generator.name }}</div>
                                      </div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <img (click)="openLookupModal('leadGenerator')" src="assets/images/search.svg" alt=""
                                    class="ms-2" />
                                </div>
                                <div class="display-error">
                                  <div *ngIf="
                                    f.leadGenerator.invalid && isSubmitted
                                  ">
                                    <span *ngIf="f.leadGenerator.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.leadGenerator
                                      .required
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div class="display-error">
                                  <span *ngIf="invalidLeadGerator?.id == '' && !f.leadGenerator.errors?.required">
                                    {{ _AddWhiteBoardScreen.leadGenerator.leadgenratorInvalid }}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!--end row frmrow -->

                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Expiring Carrier
                                  <span *ngIf="f.expiringPremium.value !='0' && f.expiringPremium.value !=''"
                                    class="star-color">*</span>
                                  <img tooltipposition="bottom" width="14px" tooltipstyleclass="tooltipstyle"
                                    src="assets/images/info.png" alt="" pTooltip="{{_userMessages.lookUpInfo}}"
                                    class="p-element infoImage ms-2 ng-star-inserted ts-info">
                                </label>
                                <div class="flex-div">
                                  <p-autoComplete class="form-control auto-comp" placeholder="Expiring Carrier "
                                    formControlName="expiringCarrier"
                                    [ngClass]="{error:f.expiringCarrier.invalid && isSubmitted}"
                                    [showEmptyMessage]="true" [suggestions]="expiringCarrierlist" [minLength]="1"
                                    (onSelect)="selectItem($event.value, 'expiringCarrier')"
                                    (completeMethod)="getCompanyList('Carrier', $event)"
                                    (onClear)="lookUpDataFilterChanges('Carrier')"
                                    (onDropdownClick)="getCompanyList('Carrier', $event)" field="value">
                                    <ng-template let-carrier pTemplate="item">
                                      <div class="company-item">
                                        <div>{{ carrier.value }}</div>
                                      </div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <img (click)="openLookupModal('Carrier')" src="assets/images/search.svg" alt=""
                                    class="ms-2" />
                                </div>
                                <div class="display-error">
                                  <div
                                    *ngIf="f.expiringCarrier.invalid && f.expiringPremium.value !='0' && f.expiringPremium.value !='' && isSubmitted">
                                    <span *ngIf="f.expiringCarrier.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.expiringCarrier.required
                                      }}
                                    </span>
                                  </div>
                                </div>
                                <div class="display-error">
                                  <span *ngIf="invalidExpringCarrier">
                                    {{ _AddWhiteBoardScreen.expiringCarrier.expringInvalid }}
                                  </span>
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Expiring Premium</label>
                                <div class="pos-re">
                                  <span class="dollor">$</span>
                                  <input class="form-control error dollor-input" formControlName="expiringPremium"
                                    [ngClass]="{error:f.expiringPremium.invalid && isSubmitted }"
                                    (keypress)="ValidateKey.integer($event, 15)" (focusout)="
                                    addCurrencyFormat($event,f.expiringPremium)" maxlength="21"
                                    (focus)="removeCurrencyFormat($event,f.expiringPremium)"
                                    (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value)" />
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Expiring Revenue</label>
                                <div class="pos-re">
                                  <span class="dollor">$</span>
                                  <input class="form-control dollor-input" formControlName="expiringRevenue"
                                    [ngClass]="{error:f.expiringRevenue.invalid && isSubmitted}"
                                    (keypress)="ValidateKey.integer($event, 15)"
                                    (focusout)="addCurrencyFormat($event,f.expiringRevenue)" maxlength="21"
                                    (focus)="removeCurrencyFormat($event,f.expiringRevenue)" [attr.disabled]="true" />
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Projected Revenue <span>*</span></label>
                                <div class="pos-re">
                                  <span class="dollor">$</span>
                                  <input class="form-control dollor-input" formControlName="targetRevenue"
                                    [ngClass]="{error:f.expiringRevenue.invalid && isSubmitted}"
                                    (keypress)="ValidateKey.integer($event, 15)"
                                    (focusout)="addCurrencyFormat($event,f.expiringRevenue)" maxlength="21"
                                    (focus)="removeCurrencyFormat($event,f.expiringRevenue)" />
                                </div>
                                <div class="display-error">
                                  <div *ngIf="f.targetRevenue.invalid && isSubmitted">
                                    <span *ngIf="f.targetRevenue.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.targetRevenue.required
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>


                            </div>
                            <!--end row frmrow -->

                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Industry <span>*</span></label>
                                <select class="select-cs form-select" [(ngModel)]="selectedIndustryValue"
                                  [ngClass]="{error:f.industryId.invalid && isSubmitted}" formControlName="industryId">
                                  <option disabled value="">
                                    Select Industry
                                  </option>
                                  <option *ngFor="let industry of prospectIndustryList" value="{{ industry.id }}"
                                    (click)="selectItem($event, 'industry')">
                                    {{ industry.prospectIndustryName }}
                                  </option>
                                </select>
                                <div class="display-error">
                                  <div *ngIf="f.industryId.invalid && isSubmitted">
                                    <span *ngIf="f.industryId.errors?.required">
                                      {{ _AddWhiteBoardScreen.industry.required }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Tier <span>*</span></label>
                                <select class="select-cs form-select" [(ngModel)]="selectedTierValue"
                                  formControlName="tierId" [ngClass]="{error:f.tierId.invalid && isSubmitted}">
                                  <option disabled value="">
                                    Select Tier
                                  </option>
                                  <option *ngFor="let tier of prospectTierList" value="{{ tier.id }}"
                                    (click)="selectItem($event, 'tier')">
                                    {{ tier.tierValue }}
                                  </option>
                                </select>
                                <div class="display-error">
                                  <div *ngIf="f.tierId.invalid && isSubmitted">
                                    <span *ngIf="f.tierId.errors?.required">
                                      {{ _AddWhiteBoardScreen.size.required }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Owner <span>*</span></label>
                                <div class="flex-div">
                                  <p-autoComplete class="form-control auto-comp" placeholder="Owner"
                                    formControlName="ownerName" [showEmptyMessage]="true" [minLength]="1"
                                    (onSelect)="selectItem($event.value,'ownerName')" [suggestions]="ownerlist"
                                    (onClear)="lookUpDataFilterChanges('Owner')" (completeMethod)="getOwnerlist($event)"
                                    field="value">
                                    <ng-template let-owner pTemplate="item">
                                      <div class="company-item">
                                        <div>{{owner.userName}}</div>
                                      </div>
                                    </ng-template>
                                  </p-autoComplete>
                                  <img (click)="openLookupModal('Owner')" src="assets/images/search.svg" alt=""
                                    class="ms-2">
                                </div>
                                <div class="display-error">
                                  <div *ngIf="f.ownerName.invalid && isSubmitted">
                                    <span *ngIf="f.ownerName.errors?.required">
                                      {{_AddWhiteBoardScreen.owner.required}}
                                    </span>
                                  </div>
                                </div>
                                <div class="display-error">
                                  <span *ngIf="invalidowner?.id == '' && !f.ownerName.errors?.required">
                                    {{ _AddWhiteBoardScreen.owner.ownerInvalid }}
                                  </span>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <label class="form-label">Profit Center</label>
                                <div class="flex-div">
                                  <select class="select-cs form-select" name="event" formControlName="profitCenter">
                                    <option value="Lamb">Lamb
                                    </option>
                                    <option value="CREIS">
                                      CREIS
                                    </option>
                                    <option value="TruePartners">TruePartners
                                    </option>
                                    <option value="TruePartners - Wheels">TruePartners - Wheels
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1" style="display: none;">
                                <label class="form-label">Business Status <span>*</span></label>
                                <select class="select-cs form-select" formControlName="businessStatusId"
                                  [ngClass]="{error:f.businessStatusId.invalid && isSubmitted}">
                                  <option disabled value="">
                                    Select Business Status
                                  </option>
                                  <option *ngFor="let bs of businessStatus.businessStatusList"
                                    value="{{ bs.businessStatusId }}" (click)="selectItem($event, 'businessStatus')">
                                    {{ bs.businessStatusName }}
                                  </option>
                                </select>
                                <div class="display-error">
                                  <div *ngIf="f.businessStatusId.invalid && isSubmitted">
                                    <span *ngIf="f.businessStatusId.errors?.required">
                                      {{ _AddWhiteBoardScreen.status.required }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-3 mb-1">
                                <ng-container
                                  *ngIf="selectedValue && selectedValue.businessLineId &&  selectedValue.businessLineId == 1">
                                  <label class="form-label">EIN <span>*</span></label>
                                  <div class="flex-div">
                                    <input mask="00-0000000" class="form-control" placeholder="##-#######"
                                      formControlName="ein" [attr.disabled]="true" />
                                  </div>
                                  <div class="display-error">
                                    <div *ngIf="f.ein.invalid && isSubmitted">
                                      <span *ngIf="f.ein.errors?.required">
                                        {{_AddWhiteBoardScreen.ein.required}}
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                            <!--end row frmrow -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 mt-3 mb-5" *ngIf="!showaddressChoice">
                      <div class="card">
                        <div class="card-header cl-hdr justify-content-start">
                          <h5>Primary Contact Details</h5>
                          <div class="rd-con ms-5">
                            <div class="form-check me-4">
                              <input class="form-check-input" type="radio" id="inlineRadio1" value="true"
                                (click)="checkaddressChoice($event.target)" formControlName="isExisting"
                                name="isExisting" />
                              <label class="form-check-label" for="inlineRadio1">Existing Contact</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="radio" id="inlineRadio2" value="false"
                                (click)="checkaddressChoice($event.target)" formControlName="isExisting"
                                name="isExisting" />
                              <label class="form-check-label" for="inlineRadio2">New Contact</label>
                            </div>
                          </div>
                        </div>
                        <div class="card-body" formGroupName="contact">
                          <div class="card-text row addw">
                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-4 mb-1 has-validation">

                                <label class="form-label">First Name <span>*</span></label>
                                <input [ngClass]="{
                                  error: FirstName.invalid && isSubmitted
                                }" class="form-control error" placeholder="First Name" formControlName="firstName" />
                                <div class="display-error" *ngIf="FirstName.invalid && isSubmitted">
                                  <div *ngIf="FirstName.invalid && isSubmitted">
                                    <span *ngIf="FirstName.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.firstName.required
                                      }}
                                    </span>
                                    <span *ngIf="FirstName.errors?.maxlength">
                                      {{
                                      _AddWhiteBoardScreen.firstName.maxlength
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <!-- last name -->
                              <div class="col-12 col-sm-12 col-md-4 mb-1 has-validation">
                                <label class="form-label">Last Name<span></span></label>
                                <input [ngClass]="{
                                  '': LastName.invalid && isSubmitted
                                }" class="form-control" placeholder="Last Name" formControlName="lastName" />
                              </div>

                              <div class="col-12 col-sm-12 col-md-2 mb-1 has-validation">
                                <label class="form-label">Job Title</label>
                                <input class="form-control" placeholder="Job Title" formControlName="jobTitle" />
                              </div>
                            </div>
                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-3 mb-2 phone-div">
                                <div class="phone">
                                  <label class="form-label">Phone Number <span>*</span></label>
                                  <input mask="(000) 000-0000" class="form-control" placeholder="(###) ###-####"
                                    formControlName="phoneNumber" [ngClass]="{
                                    error: PhoneNumber.invalid && isSubmitted
                                  }" />
                                  <div class="display-error" *ngIf="PhoneNumber.invalid && isSubmitted">
                                    <div *ngIf="PhoneNumber.invalid && isSubmitted">
                                      <span *ngIf="PhoneNumber.errors?.mask">
                                        {{
                                        _AddWhiteBoardScreen.phoneNumber.mask
                                        }}
                                      </span>
                                      <span *ngIf="PhoneNumber.errors?.required">
                                        {{
                                        _AddWhiteBoardScreen.phoneNumber
                                        .required
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="ext ms-4">
                                  <label class="form-label">Extension <span></span></label>
                                  <input type="text" value="" placeholder="#####" class="form-control"
                                    formControlName="extension" (keypress)="ValidateKey.integer($event,5)" />
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-4 mb-2">
                                <label class="form-label">Email Address <span></span></label>
                                <input class="form-control" formControlName="emailAddress"
                                  placeholder="example@email.com" />
                                <div class="display-error" *ngIf="EmailAddress.invalid && isSubmitted">
                                  <div *ngIf="EmailAddress.invalid && isSubmitted">
                                    <span *ngIf="EmailAddress.errors?.pattern">
                                      {{ _AddWhiteBoardScreen.email.pattern }}
                                    </span>
                                    <span *ngIf="EmailAddress.errors?.required">
                                      {{ _AddWhiteBoardScreen.email.required }}
                                    </span>
                                    <span *ngIf="EmailAddress.errors?.maxlength">
                                      {{ _AddWhiteBoardScreen.email.maxlength }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 mt-3" *ngIf="showaddressChoice">
                      <div class="card">
                        <div class="card-header cl-hdr justify-content-start">
                          <h5>Primary Contact Details</h5>
                          <div class="rd-con ms-5">
                            <div class="form-check me-4">
                              <input class="form-check-input" type="radio" id="inlineRadio1" value="true"
                                (click)="checkaddressChoice($event.target)" formControlName="isExisting"
                                name="isExisting" />
                              <label class="form-check-label" for="inlineRadio1">Existing Contact</label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="radio" id="inlineRadio2" value="false"
                                (click)="checkaddressChoice($event.target)" formControlName="isExisting"
                                name="isExisting" />
                              <label class="form-check-label" for="inlineRadio2">New Contact</label>
                            </div>
                          </div>
                        </div>
                        <div class="card-body" formGroupName="contact">
                          <div class="card-text row addw">
                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-4 mb-1 has-validation" *ngIf="!contactExisted">
                                <label class="form-label">First Name <span class="star-color">*</span></label>
                                <p-autoComplete placeholder="First Name" class="form-control auto-comp" [ngClass]="{
                                  error: FirstName.invalid && isSubmitted
                                }" [suggestions]="contactlist" [showEmptyMessage]="true"
                                  (completeMethod)="getContactByName($event)"
                                  (onDropdownClick)="getContactByName($event)" formControlName="firstName"
                                  [minLength]="1" (onSelect)="selectItem($event.value, 'firstName')" fieldName="fullName">
                                  <ng-template let-contactName pTemplate="item">
                                    <div class="company-item">
                                      <div>
                                        {{
                                        contactName.fullName
                                        ? contactName.fullName
                                        : contactName.firstName
                                        }}
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-autoComplete>
                                <div class="display-error" *ngIf="FirstName.invalid && isSubmitted">
                                  <div *ngIf="FirstName.invalid && isSubmitted">
                                    <span *ngIf="FirstName.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.firstName.required
                                      }}
                                    </span>
                                    <span *ngIf="FirstName.errors?.maxlength">
                                      {{
                                      _AddWhiteBoardScreen.firstName.maxlength
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-4 mb-1 has-validation" *ngIf="contactExisted">
                                <label class="form-label">First Name <span class="star-color">*</span></label>
                                <input [ngClass]="{
                                  error: FirstName.invalid && isSubmitted
                                }" class="form-control error" placeholder="First Name" formControlName="firstName" />
                                <div class="display-error" *ngIf="FirstName.invalid && isSubmitted">
                                  <div *ngIf="FirstName.invalid && isSubmitted">
                                    <span *ngIf="FirstName.errors?.required">
                                      {{
                                      _AddWhiteBoardScreen.firstName.required
                                      }}
                                    </span>
                                    <span *ngIf="FirstName.errors?.maxlength">
                                      {{
                                      _AddWhiteBoardScreen.firstName.maxlength
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 col-sm-12 col-md-4 mb-1 has-validation">
                                <label class="form-label">Last Name <span></span></label>
                                <input [ngClass]="{
                                  error: LastName.invalid && isSubmitted
                                }" class="form-control" placeholder="Last Name" formControlName="lastName" />
                                <div class="display-error" *ngIf="LastName.invalid && isSubmitted">
                                  <div *ngIf="LastName.invalid && isSubmitted">
                                    <span *ngIf="LastName.errors?.maxlength">
                                      {{
                                      _AddWhiteBoardScreen.lastName.maxlength
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-2 mb-1 has-validation">
                                <label class="form-label">Job Title</label>
                                <input class="form-control" placeholder="Job Title" formControlName="jobTitle"
                                  [attr.disabled]="true" />
                                <div class="display-error" *ngIf="JobTitle.invalid && isSubmitted">
                                  <div *ngIf="JobTitle.invalid && isSubmitted">
                                    <span *ngIf="JobTitle.errors?.maxlength">
                                      {{_AddWhiteBoardScreen.jobTitle.maxlength}}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row frmrow add-wba-form">
                              <div class="col-12 col-sm-12 col-md-3 mb-2 phone-div">
                                <div class="phone">
                                  <label class="form-label">Phone Number <span class="star-color">*</span></label>
                                  <input mask="(000) 000-0000" class="form-control" placeholder="(###) ###-####"
                                    formControlName="phoneNumber" [ngClass]="{
                                    error: PhoneNumber.invalid && isSubmitted
                                  }" [attr.disabled]="true" />
                                  <div class="display-error" *ngIf="PhoneNumber.invalid && isSubmitted">
                                    <div *ngIf="PhoneNumber.invalid && isSubmitted">
                                      <span *ngIf="PhoneNumber.errors?.mask">
                                        {{
                                        _AddWhiteBoardScreen.phoneNumber.mask
                                        }}
                                      </span>
                                      <span *ngIf="PhoneNumber.errors?.required">
                                        {{
                                        _AddWhiteBoardScreen.phoneNumber.required
                                        }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="ext ms-4">
                                  <label class="form-label">Extension <span></span></label>
                                  <input type="text" value="" placeholder="#####" class="form-control"
                                    formControlName="extension" (keypress)="ValidateKey.integer($event,5)"
                                    [attr.disabled]="true" />
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-4 mb-2">
                                <label class="form-label">Email Address <span class="star-color"></span></label>
                                <input class="form-control" formControlName="emailAddress"
                                  placeholder="example@email.com" [attr.disabled]="true" />
                                <div class="display-error" *ngIf="EmailAddress.invalid && isSubmitted">
                                  <div *ngIf="EmailAddress.invalid && isSubmitted">
                                    <span *ngIf="EmailAddress.errors?.pattern">
                                      {{ _AddWhiteBoardScreen.email.pattern }}
                                    </span>
                                    <span *ngIf="EmailAddress.errors?.maxlength">
                                      {{ _AddWhiteBoardScreen.email.maxlength }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="btn-blk1">
              <button class="btn btn-outline-primary me-4" (click)="cancel()">
                Cancel
              </button>
              <button class="btn btn-primary me-4" (click)="saveWBA('close')">
                Create WBA & Close
              </button>
              <button class="btn btn-primary" (click)="saveWBA('next')">
                Create WBA & Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Dialog for confirmation -->
  <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
    <p>{{ _confirmBox.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
      <p-button (click)="confirmBoxClose()" label="No" styleClass="btn btn-outline-primary"></p-button>
      <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </p-dialog>
  <p-dialog header="Alert" [modal]="true" [(visible)]="_warningBox1.isVisible" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [styleClass]="_warningBox1.borderColor"
    *ngIf="_warningBox1">
    <p>{{ _warningBox1.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_warningBox1.hasFooter">
      <p-button (click)="this._warningBox1.isVisible = false;" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </p-dialog>
</div>
