<div class="ldg">
  <div class="modal-content">
    <div class="modal-body">
      <div class="add-pro-outer">
        <div class="add-hdr mb-2">
          <div class="heading">
            <h2 *ngIf="visd">New Lead Generator</h2>
            <h2 *ngIf="!tbsd">Lookup Record</h2>
          </div>
          <div class="add-act">
            <a href="javascript:void(0)" (click)="addwb()" *ngIf="!tbsd" class="me-4 btn btn-outline-primary">
              <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Add</label>
            </a>
            <a href="javascript:void(0)" title="" (click)="closeModal()" class="close" data-bs-dismiss="modal"
              aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
          </div>
        </div>
        <ng-container *ngIf="visd">
          <div class="row" *ngIf="showError">
            <div class="col-12">
              <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                <ul class="view-error">
                  <li>{{unAthorizesd}}</li>
                </ul>
                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="px-4 lookup-content">
          <div class="page-hdr-outer">
            <div class="add-wb" id="addwb1" *ngIf="visd" [formGroup]="leadGenertorForm">
              <div class="row">
                <div class="card-text row addw">
                  <div class="row  frmrow add-wba-form ">
                    <div class="col-12 col-sm-12 col-md-6 mb-1 has-validation">
                      <label class="form-label">Name<span>*</span></label>
                      <div class="flex-div">
                        <input formControlName="name" dataTextFocus [class.errorInput]="f.name.invalid && submitted"
                          class="form-control" placeholder="Name" (focusout)="removeSpaces($event, f.name)" />
                        <div class="display-error" *ngIf="f.name.invalid && submitted">
                          <div *ngIf="f.name.invalid && submitted">
                            <span *ngIf="f.name.errors?.required">
                              {{leadGeneratorScreen.name.required}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-1">
                      <label class="form-label">Owner <span>*</span></label>
                      <div class="flex-div">
                        <input formControlName="ownerId" dataTextFocus
                          [class.errorInput]="f.ownerId.invalid && submitted" class="form-control rd-con" readonly
                          placeholder="Owner" />
                        <div class="display-error">
                          <div *ngIf="f.ownerId.invalid && submitted">
                            <span *ngIf="f.ownerId.errors?.required">
                              {{leadGeneratorScreen.ownerId.required}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-blk1 py-4 text-end">
                    <button class="btn btn-outline-primary me-4" (click)="close()">Cancel</button>
                    <button class="btn btn-primary " (click)="addLeadGenerator()">Save</button>

                  </div>
                </div>
              </div>
              <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
                [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
                [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                <p>{{_confirmBox.message}}</p>
                <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                  <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                  </p-button>
                  <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
                </ng-template>
              </p-dialog>
            </div>
            <div class="lookup-table" id="tbl1" *ngIf="!tbsd">
              <div class="filterdiv text-end my-2">

                <div class="nyc-search1">
                  <input type="search" (input)="searchValue(searchTextForLeadgenerator.value)" [(ngModel)]="searchGrid"
                    #searchTextForLeadgenerator class="srch-input" placeholder="Search for lead generator">
                  <input type="submit" (input)="searchValue(searchTextForLeadgenerator.value)"
                    (click)="getLeadgeneratorList(searchTextForLeadgenerator.value)" class="btn btn-sbt" value="">
                </div>

                <p-paginator [rows]="_lookUpPaginator.rowsPerPageOptions[0]"
                  [totalRecords]="_lookUpPaginator.totalRecords" (input)="searchValue(searchTextForLeadgenerator.value)"
                  [rowsPerPageOptions]="_lookUpPaginator.rowsPerPageOptions" (onPageChange)="prospectPaginate($event)">
                </p-paginator>
              </div>


              <div class="table-responsive table-responsive-md  list-prospect table-bdr">
                <table class="table">
                  <thead class="table-dark">
                    <tr class="table-hd">
                      <th width="30%">Name</th>
                      <th>Owner</th>
                      <th>Owner's Email Address</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of lookupData;let i = index" [class.active]="i == HighlightRow"
                      (click)="clickFunction(data,i)">
                      <td>{{ data.name }}</td>
                      <td>{{ data.owner }}</td>
                      <td>{{ data.ownerEmail }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="btn-blk1 pb-4 text-end">
                <button class="btn btn-outline-primary me-4" (click)="closeModal()">Cancel</button>
                <button class="btn btn-primary " (click)="emitSelectedData()">OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>