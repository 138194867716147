<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>WBA Outcome</h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
            <div class="add-content add-form">
                <div class="pdg-con">
                    <form class="row g-3">
                        <div class="add-form">
                            <div class="add-fld ">

                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12">

                                        <div class="add-title">
                                            <h3>BOR</h3>
                                        </div>
                                        <form class="add-noti-form row" [formGroup]="borForm">
                                            <div class="row">
                                                <div class="row frmrow">
                                                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                        <label class="form-label">Outcome</label>
                                                        <input class="form-control" formControlName="outcome" />
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-3 customSelect">
                                                        <label class="form-label">Why was the BOR
                                                            initiated
                                                            <span class='star-color'>*</span></label>

                                                        <kendo-dropdownlist formControlName="initializeBOR"
                                                            [(ngModel)]="selectedItem" [valuePrimitive]="true"
                                                            (selectionChange)="selectionChange($event)"
                                                            [data]="initiateBorResponse" textField="reason"
                                                            valueField="id" [defaultItem]="defaultItem">
                                                        </kendo-dropdownlist>
                                                        <div class="display-error"
                                                            *ngIf="f.initializeBOR.invalid && submitted">
                                                            <div *ngIf="f.initializeBOR.invalid && submitted">
                                                                <span *ngIf="f.initializeBOR.errors?.required">
                                                                    {{addWbaScreen.initializeBOR.required}}
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                        <label class="form-label">What is the execution
                                                            strategy
                                                            <span class='star-color'>*</span></label>
                                                        <select class="form-select l-3"
                                                            [class.errorInput]="f.executionStrategy.invalid && submitted"
                                                            #strategy (change)="setExecutionStrategy(strategy.value)"
                                                            formControlName="executionStrategy">
                                                            <option value="none" selected disabled hidden>Select
                                                            </option>
                                                            <option *ngFor="let item of borStrategiesResponse"
                                                                [value]="item.strategy">{{item.strategy}}</option>
                                                        </select>

                                                        <div class="display-error"
                                                            *ngIf="f.executionStrategy.invalid && submitted">
                                                            <div *ngIf="f.executionStrategy.invalid && submitted">
                                                                <span *ngIf="f.executionStrategy.errors?.required">
                                                                    {{addWbaScreen.executionStrategy.required}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                        <label class="form-label mb-3">List out any business
                                                            groups that need to be notified of the BOR and service
                                                            promised</label>
                                                        <table class="w-100 mb-3">
                                                            <thead class="mb-2">
                                                                <tr>
                                                                    <th width="50%">
                                                                        <h5 class="bor-hd">Claims</h5>
                                                                    </th>
                                                                    <th id="loss">
                                                                        <h5 class="bor-hd">Loss
                                                                            Control/Risk
                                                                            Management</h5>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="inputHeight">
                                                                <tr scope="row">
                                                                    <td>
                                                                        <div *ngFor="let b of claimBusinessGroups, let i = index"
                                                                            id="claims" class="form-check">
                                                                            <input #claimChekbox type="checkbox"
                                                                                [checked]="b?.businessGroup"
                                                                                (change)="setClaims(claimChekbox, b)"
                                                                                formControlName="claimBusinessGroups" /><label>{{b.name}}</label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div *ngFor="let b of lossBusinessGroups, let i = index"
                                                                            class="form-check">
                                                                            <input type="checkbox" #lossChekbox
                                                                                [checked]="b?.businessGroup"
                                                                                (change)="setLoss(lossChekbox,b)"
                                                                                formControlName="lossBusinessGroups" /><label>{{b.name}}</label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-3" *ngIf="isActive">

                                                        <label class="form-label">Special Service Notes <span
                                                                class='star-color'>*</span></label>
                                                        <textarea rows="4" class="form-control"
                                                            (focusout)="removeSpaces($event,f.specialServiceNotes)"
                                                            name="SpecialServiceNotes" #SpecialServiceNotes
                                                            formControlName="specialServiceNotes"></textarea>

                                                        <div class="display-error"
                                                            *ngIf="f.specialServiceNotes.value == '' && submitted">
                                                            <div *ngIf="f.specialServiceNotes.value == '' && submitted">
                                                                <span *ngIf="f.specialServiceNotes.value == ''">
                                                                    {{addWbaScreen.specialServiceNotes.required}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-3">
                                                        <label class="form-label">What is the renewal
                                                            plan of this
                                                            policy? Renew with existing/market to other
                                                            carriers? <span class='star-color'>*</span></label>
                                                        <select class="form-select l-3" #renewal
                                                            [class.errorInput]="f.renewalPlan.invalid && submitted"
                                                            (change)="setRenewalPolicy(renewal.value)"
                                                            formControlName="renewalPlan">
                                                            <option value="none" selected disabled hidden>Select
                                                            </option>
                                                            <option *ngFor="let item of borRenewalResponse"
                                                                [value]="item.renewal">{{item.renewal}}</option>
                                                        </select>
                                                        <div class="display-error"
                                                            *ngIf="f.renewalPlan.invalid && submitted">
                                                            <div *ngIf="f.renewalPlan.invalid && submitted">
                                                                <span *ngIf="f.renewalPlan.errors?.required">
                                                                    {{addWbaScreen.renewalPlan.required}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                        <label class="form-label">Additional
                                                            Comments</label>
                                                        <textarea rows="4" class="form-control" name="comments"
                                                            (focusout)="removeSpaces($event,f.comments)" #comments
                                                            formControlName="comments"></textarea>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>

                </div>
                <div class="blk-bt">

                    <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
                    <button class="btn btn-primary ms-2 me-4"
                        [disabled]="borResponse?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || wbaDetails?.outcome == 'Bound' || wbaDetails?.outcome == 'Lost' "
                        (click)="createWbaBor(comments.value)">Save</button>

                </div>
                <!-- Dialog for confirmation -->
                <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
                    [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
                    [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                    <p>{{_confirmBox.message}}</p>
                    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                        <p-button (click)="_confirmBox.isVisible = false" label="No"
                            styleClass="btn btn-outline-primary">
                        </p-button>
                        <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
                    </ng-template>
                </p-dialog>

            </div>

        </div>
    </div>
</div>