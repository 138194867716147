<div class="main-body-con">
    <div class="page-container">
        <div class="mid-con">
            <div class="wba-outer-block">
                <div class="wba-left-block">
                    <div class="wba-contact-detail">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12">


                                <div class="flex-item wbc wba-contact">
                                    <img src="assets/images/user.svg" alt="">

                                    <p class="uName">{{wbaDetails?.firstName}}&nbsp;{{ wbaDetails?.lastName}}
                                        <ng-container *ngIf="wbaDetails?.jobTitle">
                                            <span>| {{wbaDetails?.jobTitle }}</span>
                                        </ng-container>
                                    </p>

                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12">
                                <div class="flex-item wbc">

                                    <img src="assets/images/phone-pr.svg" alt="">
                                    <label>
                                        <p class="wba-phone"
                                            (*kendoAutoCompleteGroupTemplate)="openAddDialModal(wbaDetails?.office1PhoneNumber)">
                                            <span>{{wbaDetails?.office1PhoneNumber ? (wbaDetails?.office1PhoneNumber |
                                                mask: "(000) 000-0000") :
                                                "NA" }}</span>
                                            | <span class="ext"> Ext. <ng-container
                                                    *ngIf="wbaDetails?.office1Extension">{{wbaDetails?.office1Extension}}</ng-container></span>
                                        </p>
                                    </label>
                                </div>

                            </div>
                            <div class="col-12 col-sm-12 col-md-12">
                                <ng-container *ngIf="wbaDetails?.office1Email">
                                    <div class="flex-item wbc">
                                        <img src="assets/images/email-pr.svg" alt="">
                                        <label>
                                            <a href="mailto:{{ wbaDetails?.office1Email}}">
                                                <p class="wba-email" pTooltip="{{ wbaDetails?.office1Email }}"
                                                    tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">

                                                    {{wbaDetails?.office1Email}}
                                                </p>
                                            </a>
                                        </label>
                                    </div>

                                </ng-container>




                            </div>

                        </div>



                    </div>
                    <div class="scrolltab">
                        <div *ngIf="showProposalSlide" id="propslide">
                            <form novalidate [formGroup]='proposalForm' id="proposalForm"
                                onkeydown="return event.key != 'Enter';">
                                <div class="grp-heading-left flex-div">
                                    <img src="assets/images/include.svg" alt="Include slides for proposal" />
                                    <h3>

                                        Include slides for proposal</h3>
                                </div>
                                <div class="slide-size">
                                    <ul class="chk-list wb-chklist">
                                        <li [ngClass]="{'active-c' : currentSlideNumber === line.slideNumber,'select-c' : currentSlideNumber != line.slideNumber}"
                                            *ngFor="let line of MasterCoverageListNew; let i = index">
                                            <a title="">
                                                <div class="flex-div space-between">
                                                    <div class="flex-div slide-lbl">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                name="contactInclude"
                                                                (click)="onIncludeChange(line.displayName, line.isInclude)"
                                                                [checked]="line.isInclude" />
                                                        </div>

                                                        <label (click)="openSlide(line)"
                                                            class="form-check-label m-t-2 pointer chk-label"
                                                            pTooltip="{{line.displayName }}" tooltipPosition="bottom"
                                                            tooltipStyleClass="tooltipstyle">
                                                            {{line.displayName }}</label>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="createdWBA datecol">
                        <div>

                            <p class="createdOn">Created On {{wbaDetails?.createdDate | date:'MM/dd/YYYY'}} </p>
                            <p class="createdBy">By {{wbaDetails?.createdByName ? wbaDetails.createdByName:'N/A'}}</p>
                        </div>
                        <div class="dt-icon">
                            <img src="assets/images/dt.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="wba-right-block">
                    <div class="pro-detail no-round-border" id="add-wba-view">
                        <div class="wh-view">
                            <div class="pro-right-header">
                                <div class="pro-name-city flex-div space-between">

                                    <h2>
                                        {{wbaDetails?.companyName}} <span><img src="assets/images/content-copy.png"
                                                alt="" (click)="copyText(wbaDetails?.companyName)" /></span>
                                    </h2>
                                    <h2 class="wbaID">WBA-{{wbaDetails?.id}}<span><img
                                                src="assets/images/content-copy.png" alt=""
                                                (click)="copyText(wbaDetails?.id)" /> </span></h2>





                                </div>
                                <ng-template #template>
                                    <div class="eMsg">
                                        The Proposal request generated successfully.
                                    </div>
                                </ng-template>
                                <div class="flex-div">
                                    <ng-container *ngIf="showProposalSlide">
                                        <a _ngcontent-nop-c184="" (click)="navigateBackToList()"
                                            href="javascript:void(0)" title="close"
                                            class="btn btn-outline-primary backBtn"><span><img
                                                    src="assets/images/back.svg" alt=""
                                                    class="plus-icon" /></span>Back</a>
                                    </ng-container>
                                    <a (click)="closeModal()" href="javascript:void(0)" title="Close"
                                        class="closeWBA"><img src="assets/images/close-wba.png"
                                            alt="Back to List" /></a>
                                </div>
                            </div>
                            <div class="wh-view-detail">
                                <div class="row row-cols-7">

                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"><img src="assets/images/wb-icon1.png" alt="" />
                                            </div>
                                            <div class="icon-des"><span>Effective Date</span>
                                                <p *ngIf="wbaDetails?.effectiveDate">{{ wbaDetails?.effectiveDate |
                                                    date:'MM/dd/YYYY'}} </p>
                                                <p *ngIf="!wbaDetails?.effectiveDate">NA </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"><img src="assets/images/wb-icon2.png" alt="" />
                                            </div>
                                            <div class="icon-des"><span>Line of Business</span>
                                                <div class="cr-wd textover"
                                                    [ngClass]="{'txth':!isExpand ? '':isExpand}">
                                                    <p pTooltip="{{ wbaDetails?.masterBusinessLine }}"
                                                        tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                                                        {{wbaDetails?.masterBusinessLine ?
                                                        wbaDetails?.masterBusinessLine:'N/A'}} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"><img src="assets/images/wb-icon3.png" alt="" />
                                            </div>
                                            <div class="icon-des"> <span>Business Status</span>
                                                <div class="cr-wd textover"
                                                    [ngClass]="{'txth':!isExpand ? '':isExpand}">
                                                    <p pTooltip="{{ wbaDetails?.masterBusinessStatus }}"
                                                        tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                                        wbaDetails?.masterBusinessStatus ?
                                                        wbaDetails?.masterBusinessStatus
                                                        :
                                                        'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"><img src="assets/images/wb-icon4.png" alt="" />
                                            </div>
                                            <div class="icon-des"><span>Progress</span>
                                                <div class="cr-wd" [ngClass]="{'txth':!isExpand ? '':isExpand}">
                                                    <p pTooltip="{{ wbaDetails?.progressName }}"
                                                        tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                                                        {{wbaDetails?.progressName ?
                                                        wbaDetails.progressName:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"> <img src="assets/images/Client-Status (3).png"
                                                    alt="" /></div>
                                            <div class="icon-des"><span>Client Status</span>
                                                <div class="cr-wd">
                                                    <p pTooltip="{{this.prospectDetail?.prospectInformation.status}}"
                                                        tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                                                        {{prospectDetail?.prospectInformation.status ?
                                                        prospectDetail?.prospectInformation.status
                                                        :'NA'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"> <img src="assets/images/wb-icon6.png" alt="" />
                                            </div>
                                            <div class="icon-des"><span>Tier</span>
                                                <div class="cr-wd">
                                                    <p pTooltip="{{tierName?.tierValue}}" tooltipPosition="bottom"
                                                        tooltipStyleClass="tooltipstyle">
                                                        {{tierName?.tierValue ? tierName?.tierValue:'NA'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="icon-block">
                                            <div class="icon-image"> <img src="assets/images/wb-icon7.png" alt="" />
                                            </div>
                                            <div class="icon-des"><span>Projected Revenue</span>
                                                <div class="cr-wd">
                                                    <p pTooltip="{{wbaDetails?.expectedBoundRevenue | number:'1.0-0' }}"
                                                        tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">$
                                                        {{wbaDetails?.expectedBoundRevenue ?
                                                        (wbaDetails?.expectedBoundRevenue | number:'1.0-0'):'0'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div class="coreTeam">
                                <div class="core-title">
                                    <img src="assets/images/coreteam.png" alt="Core Team" />
                                </div>
                                <div class="core-des">
                                    <div class="team-row">
                                        <div class="row align-items-center">
                                            <div class="col-12 col-sm-12 col-md-4  coreMr">
                                                <div class="flex-div">

                                                    <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                                                    <p>Owner:</p>
                                                    <div class="teamInput auto-comp-wd">
                                                        <div class="company-item">
                                                            <p>{{wbaDetails?.ownerUserName}}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-12 col-sm-12 col-md-4 coreMr">
                                                <div class="flex-div">

                                                    <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                                                    <p>Marketing Manager:</p>
                                                    <div class="teamInput auto-comp-wd">
                                                        <div class="company-item">
                                                            <p>{{wbaDetails?.marketingManager}}</p>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-12 col-sm-12 col-md-4 coreMr">
                                                <div class="flex-div">

                                                    <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                                                    <p>Account Manager:</p>
                                                    <div class="teamInput auto-comp-wd">
                                                        <div class="company-item">
                                                            <p pTooltip="{{ wbaDetails?.accountManagerUserName }}"
                                                                tooltipPosition="bottom"
                                                                tooltipStyleClass="tooltipstyle">{{
                                                                wbaDetails?.accountManagerUserName ?
                                                                wbaDetails?.accountManagerUserName:'N/A'}}</p>
                                                        </div>




                                                    </div>
                                                </div>


                                            </div>



                                        </div>



                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="white-box">
                            <div class="wrp-pros">
                                <div>
                                    <!-- <div class="wh-view-detail">
                            <div class=" iconsection">


                              <div class="row row-cols-5 ">

                                  <div class="col datecol">
                                      <div class="icon-block">
                                          <div class="icon-image"><img src="assets/images/wb-icon1.png" alt="" /></div>
                                          <div class="icon-des"><span>Effective Date</span>
                                              <p *ngIf="wbaDetails?.effectiveDate">{{ wbaDetails?.effectiveDate |
                                                  date:'MM/dd/YYYY'}} </p>
                                              <p *ngIf="!wbaDetails?.effectiveDate">N/A </p>
                                          </div>
                                      </div>


                                  </div>
                                  <div class="col">
                                      <div class="icon-block">
                                          <div class="icon-image"><img src="assets/images/wb-icon3.png" alt="" /></div>
                                          <div class="icon-des"><span>Business Status</span>
                                              <div>
                                                  <p pTooltip="{{ wbaDetails?.masterBusinessStatus }}"
                                                      tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                                      wbaDetails?.masterBusinessStatus ? wbaDetails?.masterBusinessStatus
                                                      :
                                                      'N/A'}}</p>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="col">
                                      <div class="icon-block">
                                          <div class="icon-image"><img src="assets/images/wb-icon2.png" alt="" /></div>
                                          <div class="icon-des"> <span>Line of Business</span>
                                              <div>
                                                  <p pTooltip="{{ wbaDetails?.masterBusinessLine }}"
                                                      tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                                                      {{wbaDetails?.masterBusinessLine ?
                                                      wbaDetails?.masterBusinessLine:'N/A'}} </p>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="col">
                                      <div class="icon-block">
                                          <div class="icon-image"><img src="assets/images/wb-icon4.png" alt="" /></div>
                                          <div class="icon-des"> <span>Progress</span>
                                              <div>
                                                  <p pTooltip="{{ wbaDetails?.progressName }}" tooltipPosition="bottom"
                                                      tooltipStyleClass="tooltipstyle">{{wbaDetails?.progressName ?
                                                      wbaDetails.progressName:'N/A'}}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="col">
                                      <div class="icon-block">
                                          <div class="icon-image"><img src="assets/images/wb-icon8.png" alt="" /></div>
                                          <div class="icon-des"> <span>Account Manager</span>
                                              <div>
                                                  <p pTooltip="{{ wbaDetails?.accountManagerUserName }}"
                                                      tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{
                                                      wbaDetails?.accountManagerUserName ?
                                                      wbaDetails?.accountManagerUserName:'N/A'}}</p>
                                              </div>
                                          </div>

                                      </div>
                                  </div>
                              </div>
                          </div>
                          </div> -->
                                    <div *ngIf="!showProposalSlide">
                                        <div>
                                            <h2 class="subgen">Select additional whiteboard to generate proposal</h2>
                                        </div>
                                        <div class="grid-con-in">
                                            <kendo-grid [data]="finalWBAList" kendoGridSelectBy="id" [virtualColumns]="true"
                                                [(selectedKeys)]="mySelection" [pageSize]="5" [resizable]="false"
                                                (cellClick)="onCellClick($event)">

                                                <kendo-grid-checkbox-column [resizable]="false" [width]="4"
                                                    class="pro-col1 innerP">
                                                    <ng-template kendoGridCellTemplate let-idx="rowIndex" let-rowData>
                                                        <input [checked]="rowData?.defaultCheckedRow"
                                                            (click)="changeSelection(rowData)"
                                                            [kendoGridSelectionCheckbox]="idx"
                                                            class="form-check-input" />
                                                    </ng-template>
                                                    <ng-template kendoGridHeaderTemplate>
                                                        <input type="checkbox" kendoGridSelectAllCheckbox
                                                            class="allchk form-check-input"
                                                            (click)="changeAllSelection()" />
                                                    </ng-template>
                                                </kendo-grid-checkbox-column>
                                                <kendo-grid-column [width]="10" field="id" title="WBAID">
                                                </kendo-grid-column>
                                                <kendo-grid-column [width]="15" field="masterBusinessLine" title="Line">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <span class="customsp1">
                                                            {{dataItem.policyType ? dataItem.policyType :
                                                            dataItem.masterBusinessLine}}
                                                        </span>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column [width]="15" field="effectiveDate"
                                                    title="Effective Date" format="{0:d}">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <span class="customsp1">
                                                            {{dataItem.effectiveDate
                                                            | date:'MM/dd/yyyy'}}</span>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column [width]="15" field="transType"
                                                    title="Transaction Type">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <span class="customsp">
                                                            {{dataItem.transType == 'NB' ? 'New Business' :
                                                            'Renewal'}}</span>
                                                    </ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column [width]="20" field="masterBusinessStatus"
                                                    title="Business Status">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <span
                                                            class="customsp">{{dataItem.masterBusinessStatus}}</span></ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column [width]="15" field="targetPremium"
                                                    title="Target Premium" format="{0:c0}" class="text-left">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <span class="customsp">{{dataItem.targetPremium |
                                                            currency:'USD':true:'1.0-0'}}</span></ng-template>
                                                </kendo-grid-column>
                                                <kendo-grid-column [width]="20" field="ownerUserName" title="Owner">
                                                    <ng-template kendoGridCellTemplate let-dataItem>
                                                        <span
                                                            class="customsp">{{dataItem.ownerUserName}}</span></ng-template>
                                                </kendo-grid-column>
                                            </kendo-grid>
                                            <div class="flex-div justify-content-between mt-3 rd-btn">
                                                <div class="flex-div">
                                                    <input type="radio" id="proposal" name="radio1" class="me-1"
                                                        (click)="selectProposalOption('Proposal')"
                                                        [checked]="isProposalChecked">
                                                    <label class="modal-basic-title" for="proposal">Proposal</label>

                                                    <input type="radio" id="capabilitiesDoc" name="radio1"
                                                        class="ms-4 me-1"
                                                        (click)="selectProposalOption('Capabilities Document')"
                                                        [checked]="isCapabilitiesChecked">
                                                    <label class="modal-basic-title" for="capabilitiesDoc">Capabilities
                                                        Document</label>
                                                </div>
                                                <button class="btn btn-primary"
                                                    (click)="navigateToProposalSlide()">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showProposalSlide" id="propslide">

                                        <div class="audit-table exeSum">


                                            <div class="prop-container">

                                                <form novalidate [formGroup]='proposalForm' id="proposalForm"
                                                    onkeydown="return event.key != 'Enter';">
                                                    <div class="contentRow">

                                                        <div class="content-right cov-con pt-0">
                                                            <div class="row">
                                                                <div class="col-12 ">
                                                                    <div
                                                                        class="flex-div space-between hd-p actionbutton">
                                                                        <div class="flex-div">
                                                                            <button class="btn btn-prev me-3"
                                                                                pTooltip="Previous Slide"
                                                                                tooltipPosition="bottom"
                                                                                tooltipStyleClass="tooltipstyle"
                                                                                *ngIf="currentSlideNumber !== 100 || currentSlideNumber !== 101"
                                                                                [ngClass]="currentSlideNumber == 100 || currentSlideNumber == 101  ? 'disabled' : '' "
                                                                                (click)="openPreviousSlide()">
                                                                                <img src="assets/images/arrow-back-g.svg"
                                                                                    alt="Previous">
                                                                            </button>
                                                                            <h3 class="DetailsHeading">{{slideName}}
                                                                            </h3>
                                                                        </div>




                                                                        <div class="flex-div">

                                                                            <button
                                                                                *ngIf="currentSlideNumber === 100 || currentSlideNumber === 101"
                                                                                [ngClass]="prospectLogoId == 0 ? 'btn btn-primary ms-3' : 'btn btn-success ms-3'"
                                                                                (click)="uploadLogo()">{{prospectLogoId
                                                                                == 0 ? 'Upload
                                                                                Prospect
                                                                                Logo' : 'Change Prospect Logo'}}
                                                                            </button>

                                                                            <a href="javascript:void(0)"
                                                                                class="dropdown-toggle btn btn-secondary gBtn mx-3"
                                                                                id="dropdownMenuButton1"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false">
                                                                                <label class="ps-2">Generate
                                                                                    Proposal</label>
                                                                            </a>
                                                                            <ul class="dropdown-menu"
                                                                                aria-labelledby="dropdownMenuButton1">
                                                                                <li><a href="javascript:void(0)"
                                                                                        *ngIf="wbaDetails?.transType == 'NB'"
                                                                                        style="color: #122B74 !important;cursor:pointer  !important;"
                                                                                        class="dropdown-item"
                                                                                        (click)="generateProposal('Portrait')">Portrait</a>
                                                                                </li>
                                                                                <li><a href="javascript:void(0)"
                                                                                        style="color: #122B74 !important;cursor:pointer  !important;"
                                                                                        title="" class="dropdown-item"
                                                                                        (click)="generateProposal('Landscape')">Landscape</a>
                                                                                </li>
                                                                            </ul>
                                                                            <div class="button-group">
                                                                                <!-- <button
                                                                                    class="btn btn-secondary btn-cancel me-3"
                                                                                    (click)="cancel()">Discard</button> -->
                                                                                <button class="btn btn-primary"
                                                                                    (click)="saveProposal('save')">Save</button>
                                                                            </div>
                                                                            <button class="btn btn-next ms-3"
                                                                                pTooltip="Next Slide"
                                                                                tooltipPosition="bottom"
                                                                                tooltipStyleClass="tooltipstyle"
                                                                                *ngIf="currentSlideNumber !== lastSlide?.slideNumber"
                                                                                [ngClass]="currentSlideNumber == lastSlide?.slideNumber ? 'disabled' : '' "
                                                                                (click)="openNextSlide()">
                                                                                <img src="assets/images/arrow-next-g.svg"
                                                                                    alt="Next">
                                                                            </button>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mid-text">

                                                                    <div class="row">


                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 100 || currentSlideNumber === 101">
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">Title</label>
                                                                                <input dataTextFocus
                                                                                    class="form-control"
                                                                                    placeholder="Enter title"
                                                                                    formControlName="coverPageTitle"
                                                                                    (change)="onCoverPageTitleChange()" />
                                                                                <div class="display-error"
                                                                                    *ngIf="f.coverPageTitle.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.coverPageTitle.errors?.required">{{_quoteDetailsMessage.coverPageTitle.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">Prospect
                                                                                    <span>*</span></label>
                                                                                <input class="form-control"
                                                                                    placeholder="Prospect Name"
                                                                                    formControlName="coverPageProspectName"
                                                                                    (change)="onCoverPageProposalChange()" />
                                                                                <div class="display-error"
                                                                                    *ngIf="f.coverPageProspectName.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.coverPageProspectName.errors?.required">{{_quoteDetailsMessage.prospect.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">Presented By
                                                                                </label>
                                                                                <div class="row">
                                                                                    <div
                                                                                        class="col-12 col-sm-6 col-md-12">
                                                                                        <div class="flex-div">
                                                                                            <p-autoComplete
                                                                                                class="form-control auto-comp-prp"
                                                                                                placeholder="Presented By"
                                                                                                formControlName="coverPagepresentedBy"
                                                                                                [showEmptyMessage]="true"
                                                                                                [minLength]="1"
                                                                                                (onSelect)="selectItem($event.value,'ownerNameTitleSlide')"
                                                                                                [suggestions]="ownerlist"
                                                                                                (completeMethod)="getOwnerlist($event)"
                                                                                                field="value">
                                                                                                <ng-template let-title
                                                                                                    pTemplate="item">
                                                                                                    <div
                                                                                                        class="company-item">
                                                                                                        <div>
                                                                                                            {{title.userName}}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </ng-template>
                                                                                            </p-autoComplete>
                                                                                            <img (click)="openLookupModal('Owner', 'Title', '')"
                                                                                                src="assets/images/search.svg"
                                                                                                alt="" class="ms-2">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Human Service Expertise'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <label class="form-label">Description
                                                                                    <span>*</span></label>
                                                                                <!-- <p-editor [style]="{'height':'170px'}"
                                                                                                                          formControlName="hseDescription">
                                                                                                                      </p-editor> -->

                                                                                <kendo-editor
                                                                                    formControlName="hseDescription"
                                                                                    style="height: 300px;">
                                                                                    <kendo-toolbar>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorBoldButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorItalicButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorUnderlineButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorStrikethroughButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorSubscriptButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorSuperscriptButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAlignCenterButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAlignRightButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-dropdownlist
                                                                                            kendoEditorFormat></kendo-toolbar-dropdownlist>
                                                                                        <kendo-toolbar-dropdownlist
                                                                                            kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                                                                        <kendo-toolbar-dropdownlist
                                                                                            kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                                                                        <kendo-toolbar-colorpicker
                                                                                            kendoEditorForeColor></kendo-toolbar-colorpicker>
                                                                                        <kendo-toolbar-colorpicker
                                                                                            kendoEditorBackColor
                                                                                            view="gradient"></kendo-toolbar-colorpicker>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorIndentButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorOutdentButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorBlockquoteButton></kendo-toolbar-button>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorSelectAllButton></kendo-toolbar-button>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorUndoButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorRedoButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorCreateLinkButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorUnlinkButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorInsertFileButton></kendo-toolbar-button>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorInsertImageButton></kendo-toolbar-button>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorViewSourceButton></kendo-toolbar-button>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorCleanFormattingButton></kendo-toolbar-button>
                                                                                        <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorAddRowAfterButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorDeleteRowButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorDeleteTableButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-buttongroup>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorMergeCellsButton></kendo-toolbar-button>
                                                                                            <kendo-toolbar-button
                                                                                                kendoEditorSplitCellButton></kendo-toolbar-button>
                                                                                        </kendo-toolbar-buttongroup>
                                                                                        <kendo-toolbar-button
                                                                                            kendoEditorPrintButton></kendo-toolbar-button>
                                                                                    </kendo-toolbar>
                                                                                </kendo-editor>

                                                                                <div class="display-error"
                                                                                    *ngIf="f.hseDescription.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.hseDescription.errors?.required">{{_quoteDetailsMessage.hseDescription.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container *ngIf="isStaticSlide">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a Static Slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Proposal Introduction'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <label class="form-label">Description
                                                                                    <span>*</span></label>
                                                                                <p-editor [style]="{'height':'170px'}"
                                                                                    formControlName="piDescription">
                                                                                </p-editor>
                                                                                <div class="display-error"
                                                                                    *ngIf="f.piDescription.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.piDescription.errors?.required">{{_quoteDetailsMessage.piDescription.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Map of Lamb Client'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a Static Slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 600 || currentSlideNumber === 7400">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="tps-table">
                                                                                    <div class="audit-table premSum">
                                                                                        <table>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <!-- <th width="10%">Select</th> -->
                                                                                                    <th>WBAID</th>
                                                                                                    <th>Effective Date
                                                                                                    </th>
                                                                                                    <th>Line</th>
                                                                                                    <th>Premium
                                                                                                        Comparisions
                                                                                                    </th>
                                                                                                    <th>Expiring</th>
                                                                                                    <th>Proposed</th>
                                                                                                    <th>Difference</th>
                                                                                                    <th width="80px">
                                                                                                        Edit/Save</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    *ngFor="let data of totalPremiumSummaryCoverage; let i = index;">
                                                                                                    <!-- <td><input type="checkbox"
                                                                    class="form-check-input pointer checkBox-margin"
                                                                    [checked]="data.isChecked"
                                                                    (click)="tpsCheckboxEvent(data)">
                                                            </td> -->
                                                                                                    <td>{{data.currentWbaId}}
                                                                                                    </td>
                                                                                                    <td>{{data.effectiveDate}}
                                                                                                    </td>
                                                                                                    <td>{{data.line}}
                                                                                                    </td>
                                                                                                    <td>{{data.coverageName}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        *ngIf="data.showEditIcon">
                                                                                                        <!-- {{data.expiringValue }} -->
                                                                                                        <input
                                                                                                            class="edit-control error dollor-input-view isDisabled"
                                                                                                            [formControlName]="data.expformControlName"
                                                                                                            (change)="premiumSummaryInput($event, data, 'exp')" />
                                                                                                    </td>
                                                                                                    <td
                                                                                                        *ngIf="!data.showEditIcon">
                                                                                                        <input
                                                                                                            (keypress)="ValidateKey.integer($event, 15)"
                                                                                                            class="edit-control error dollor-input-view"
                                                                                                            [formControlName]="data.expformControlName"
                                                                                                            (change)="premiumSummaryInput($event, data, 'exp')" />
                                                                                                    </td>
                                                                                                    <td
                                                                                                        *ngIf="data.showEditIcon">
                                                                                                        <!-- {{data.recommendedValue}} -->
                                                                                                        <input
                                                                                                            class="edit-control error dollor-input-view isDisabled"
                                                                                                            [formControlName]="data.recformControlName"
                                                                                                            (change)="premiumSummaryInput($event, data, 'rec')" />
                                                                                                    </td>
                                                                                                    <td
                                                                                                        *ngIf="!data.showEditIcon">
                                                                                                        <input
                                                                                                            (keypress)="ValidateKey.integer($event, 15)"
                                                                                                            class="edit-control error dollor-input-view"
                                                                                                            [formControlName]="data.recformControlName"
                                                                                                            (change)="premiumSummaryInput($event, data, 'rec')" />
                                                                                                    </td>
                                                                                                    <td>{{data.difference}}

                                                                                                    </td>
                                                                                                    <td *ngIf="data.showEditIcon"
                                                                                                        class="actionIcon">
                                                                                                        <div
                                                                                                            class="flex-div">

                                                                                                            <span
                                                                                                                class="edit">
                                                                                                                <img (click)="data.showEditIcon = false"
                                                                                                                    class="pointer"
                                                                                                                    src="assets/images/edit.svg"
                                                                                                                    width="17px"
                                                                                                                    alt="" /></span>



                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td *ngIf="!data.showEditIcon"
                                                                                                        class="actionIcon">
                                                                                                        <div
                                                                                                            class="flex-div justify-content-end">
                                                                                                            <span
                                                                                                                class="trash  me-2"><img
                                                                                                                    (click)="cancelTpsPremium(data)"
                                                                                                                    class="pointer"
                                                                                                                    src="assets/images/cross.svg"
                                                                                                                    width="17px"
                                                                                                                    alt="" /></span>
                                                                                                            <span
                                                                                                                class="edit"><img
                                                                                                                    (click)="saveTpsPremium(data)"
                                                                                                                    class="pointer"
                                                                                                                    src="assets/images/save.svg"
                                                                                                                    width="17px"
                                                                                                                    alt="" /></span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr class="font-weight-bold"
                                                                                                    *ngIf="hasCovgAssessment">
                                                                                                    <td>Total Premium
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td>{{totalPremiumExpiring}}
                                                                                                    </td>
                                                                                                    <td>{{totalPremiumRecommended}}
                                                                                                    </td>
                                                                                                    <td>{{totalPremiumDifferences}}
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 700">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="audit-right">
                                                                                    <ng-container
                                                                                        *ngFor="let data of request">
                                                                                        <ng-container
                                                                                            *ngFor="let item of data.coverageAssessmentDetail; let index = index;">
                                                                                            <div
                                                                                                class="au-active-detail">
                                                                                                <div
                                                                                                    class="audit-right-header">
                                                                                                    <div
                                                                                                        class="coverage-n flex-div">
                                                                                                        <img src="assets/images/coverage.png"
                                                                                                            title="{{item.coverageName}}">
                                                                                                        <h2>{{item.coverageName}}
                                                                                                        </h2>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="audit-table">
                                                                                                    <table>
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                                <th
                                                                                                                    width="14%">
                                                                                                                    <div
                                                                                                                        class="form-check-inline">
                                                                                                                        <input
                                                                                                                            class="form-check-input pointer checkBox-margin"
                                                                                                                            type="checkbox"
                                                                                                                            name="coverageAllCheckbox"
                                                                                                                            id="{{ item.coverageName }}SelectRow{{ index }}"
                                                                                                                            (click)="onCoverageSelectAllSelect($event, item, index)">
                                                                                                                        <label
                                                                                                                            class="form-check-label"
                                                                                                                            for="role1">Select
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    width="16%">
                                                                                                                    <div
                                                                                                                        class="form-check-inline">
                                                                                                                        <input
                                                                                                                            class="form-check-input pointer checkBox-margin"
                                                                                                                            type="checkbox"
                                                                                                                            name="coverageAllCheckbox"
                                                                                                                            id="{{ item.coverageName }}{{ index }}"
                                                                                                                            (click)="onCoverageHighlightAllSelect($event, item, index)">
                                                                                                                        <label
                                                                                                                            class="form-check-label"
                                                                                                                            for="role1">Highlights
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    width="38%">
                                                                                                                    Coverage
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    width="16%">
                                                                                                                    Expiring
                                                                                                                    Value
                                                                                                                </th>
                                                                                                                <th
                                                                                                                    width="16%">
                                                                                                                    Recommended
                                                                                                                    Value
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr
                                                                                                                *ngFor="let param of item.coverageParameters; let i = index;">
                                                                                                                <td
                                                                                                                    class="">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="{{ param.displayName }}SelectRow{{ i }}"
                                                                                                                        id="{{ param.displayName }}SelectRow{{ i }}"
                                                                                                                        class="form-check-input pointer checkBox-margin"
                                                                                                                        (change)="handleCoverageSelectRow(param)"
                                                                                                                        [checked]="param.selectRow" />
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        name="{{ param.displayName }}{{ i }}"
                                                                                                                        id="{{ param.displayName }}{{ i }}"
                                                                                                                        class="form-check-input pointer checkBox-margin"
                                                                                                                        (change)="handleCoverageHighlight(param)"
                                                                                                                        [checked]="param.highlights"
                                                                                                                        [disabled]="!param.selectRow" />
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="coverage-grp">
                                                                                                                    <div>
                                                                                                                        {{param.displayName}}
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td><span
                                                                                                                        [ngClass]="'assess_' + param.expiringAssessmentId">
                                                                                                                        {{param.expiringValue}}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td><span
                                                                                                                        [ngClass]="'assess_' + param.recommendedAssessmentId">
                                                                                                                        {{!param.recommendedValue
                                                                                                                        ||
                                                                                                                        param.recommendedValue
                                                                                                                        ===
                                                                                                                        "undefined"
                                                                                                                        ?
                                                                                                                        ""
                                                                                                                        :
                                                                                                                        param.recommendedValue
                                                                                                                        }}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 2300">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="audit-right">
                                                                                    <div class="audit-table">
                                                                                        <table>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <!-- (click)="onCoverageSelectAllSelect1($event)" -->
                                                                                                    <!-- <th width="5%"></th> -->
                                                                                                    <th width="10%">
                                                                                                        <input
                                                                                                            class="form-check-input pointer checkBox-margin"
                                                                                                            type="checkbox"
                                                                                                            id="isAllCarriersSelected"
                                                                                                            [checked]="isAllCarriersSelected"
                                                                                                            name="coverageAllCheckbox"
                                                                                                            (click)="onCoverageSelectAllSelect1($event)">
                                                                                                    </th>
                                                                                                    <!-- <th width="15%">Effective Date </th> -->
                                                                                                    <th>WBAID</th>
                                                                                                    <th>Coverage</th>
                                                                                                    <th>Carrier </th>
                                                                                                    <th>Wholesaler </th>
                                                                                                    <th>Status </th>
                                                                                                    <th>Premium</th>
                                                                                                    <th>Notes</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    *ngFor="let item of marketSummaryList; let i = index;">
                                                                                                    <td><input
                                                                                                            class="form-check-input pointer checkBox-margin"
                                                                                                            type="checkbox"
                                                                                                            name="name1"
                                                                                                            (change)="handleMarketingSelectRow(item)"
                                                                                                            [checked]="item.selectRow" />
                                                                                                    </td>
                                                                                                    <!-- <td> {{item.effectiveDate |
                                                                                          date:'MM/dd/yyyy'}}
                                                                                      </td> -->
                                                                                                    <td> {{item.wbaId}}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="cr-wd">
                                                                                                            <span
                                                                                                                pTooltip="{{item.coverageName}}"
                                                                                                                tooltipPosition="bottom"
                                                                                                                tooltipStyleClass="tooltipstyle">{{item.coverageName}}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="cr-wd">
                                                                                                            <span
                                                                                                                pTooltip="{{item.carrierName}}"
                                                                                                                tooltipPosition="bottom"
                                                                                                                tooltipStyleClass="tooltipstyle">{{item.carrierName}}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div
                                                                                                            class="cr-wd">
                                                                                                            <span
                                                                                                                pTooltip="{{item.wholesalerName}}"
                                                                                                                tooltipPosition="bottom"
                                                                                                                tooltipStyleClass="tooltipstyle">
                                                                                                                {{item.wholesalerName}}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> {{item.quotedStatus}}
                                                                                                    </td>
                                                                                                    <td> {{item.quotedPremium
                                                                                                        }} </td>
                                                                                                    <td class="actionIcon"
                                                                                                        align="center">
                                                                                                        <span
                                                                                                            class="edit">
                                                                                                            <img class="pointer"
                                                                                                                src="assets/images/notes.svg"
                                                                                                                width="17px"
                                                                                                                alt=""
                                                                                                                (click)="openNotesModal(item)" />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Am Best Rating'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a Static Slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Service and Risk Management'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <label class="form-label">About Risk
                                                                                    <span>*</span></label>
                                                                                <p-editor [style]="{'height':'170px'}"
                                                                                    formControlName="rmgAboutRisk">
                                                                                </p-editor>
                                                                                <div class="display-error"
                                                                                    *ngIf="f.rmgAboutRisk.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.rmgAboutRisk.errors?.required">{{_quoteDetailsMessage.rmgAboutRisk.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <label class="form-label">Description
                                                                                    <span>*</span></label>
                                                                                <textarea class="form-control "
                                                                                    id="rmgDescription"
                                                                                    formControlName="rmgDescription"
                                                                                    rows="3"
                                                                                    placeholder="Description"></textarea>
                                                                                <div class="display-error"
                                                                                    *ngIf="f.rmgDescription.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.rmgDescription.errors?.required">{{_quoteDetailsMessage.rmgDescription.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-3">
                                                                                <label
                                                                                    class="form-label">{{riskServiceLabel}}
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">
                                                                                    <h3 class="DetailsHeading">
                                                                                        Claim Managment Service
                                                                                    </h3>
                                                                                </label>
                                                                                <ul class="wb-chklist">
                                                                                    <li
                                                                                        *ngFor="let line of claimServiceList; let i = index">
                                                                                        {{line.serviceName }}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">
                                                                                    <h3 class="DetailsHeading">
                                                                                        Risk Control Service
                                                                                    </h3>
                                                                                </label>
                                                                                <ul class="wb-chklist">
                                                                                    <li
                                                                                        *ngFor="let line of riskServiceList; let i = index">
                                                                                        {{line.serviceName }}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 2400">
                                                                            <div class="col-12 col-sm-12 col-md-12">
                                                                                <div class="flex-div space-between">
                                                                                    <h3>
                                                                                        <p>Selected Service
                                                                                            Team<span> * </span> </p>
                                                                                    </h3>
                                                                                    <div class="flex-div">
                                                                                        <a href="javascript:void(0)"
                                                                                            (click)="openLookupModal('Owner', 'yst', '')">
                                                                                            <span><img
                                                                                                    src="assets/images/plus.svg"
                                                                                                    alt="" />
                                                                                            </span>
                                                                                            <label class="ps-2"> Add
                                                                                                Resource </label>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="audit-right-serviceTeam">
                                                                                    <div class="audit-table">
                                                                                        <table>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th width="18%">
                                                                                                        Resource Name
                                                                                                    </th>
                                                                                                    <th width="18%">
                                                                                                        Resource Title
                                                                                                    </th>
                                                                                                    <th width="40%">
                                                                                                        Service
                                                                                                        Description</th>
                                                                                                    <th width="6%">
                                                                                                        Actions </th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    *ngFor="let item of yourServiceTeamList; let i = index;">
                                                                                                    <td
                                                                                                        class="td-vertical-align">
                                                                                                        <div
                                                                                                            class="flex-div">
                                                                                                            <img src="{{item.userPic ? item.userPic : 'assets/images/user.png'}}"
                                                                                                                alt=""
                                                                                                                class="proimg-pd" />
                                                                                                            {{item.userDisplayName}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="td-vertical-align">
                                                                                                        {{item.position}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="td-vertical-align">
                                                                                                        <textarea
                                                                                                            class="form-control servicedes"
                                                                                                            id="rmgDescription"
                                                                                                            [value]="item.serviceDescription"
                                                                                                            rows="3"
                                                                                                            placeholder="Service Description"
                                                                                                            (change)="onServiceDescriptionChange($event,item)"></textarea>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="td-vertical-align actionIcon">
                                                                                                        <div class="flex-div justify-content-end"
                                                                                                            style="float: left;">
                                                                                                            <a href="javascript:void(0)"
                                                                                                                class="pe-2"
                                                                                                                (click)="openLookupModal('Owner','EDIT', item)">
                                                                                                                <span
                                                                                                                    class="edit"><img
                                                                                                                        width="13px"
                                                                                                                        src="../../assets/images/edit.svg"
                                                                                                                        alt="Edit"></span></a>

                                                                                                            <a href="javascript:void(0)"
                                                                                                                (click)="removeResourceServiceTeam(item.userId)">
                                                                                                                <span
                                                                                                                    class="trash"><img
                                                                                                                        src="../../assets/images/trash.svg"
                                                                                                                        alt="Delete"></span></a>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 3000">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-3">
                                                                                <label
                                                                                    class="form-label">{{riskServiceLabel}}
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">
                                                                                    <h3 class="DetailsHeading">
                                                                                        Claim Managment Service
                                                                                    </h3>
                                                                                </label>
                                                                                <ul class="wb-chklist">
                                                                                    <li
                                                                                        *ngFor="let line of claimServiceList; let i = index">
                                                                                        {{line.serviceName }}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                <label class="form-label">
                                                                                    <h3 class="DetailsHeading">
                                                                                        Risk Control Service
                                                                                    </h3>
                                                                                </label>
                                                                                <ul class="wb-chklist">
                                                                                    <li
                                                                                        *ngFor="let line of riskServiceList; let i = index">
                                                                                        {{line.serviceName }}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 4900 || currentSlideNumber === 7200">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="audit-table">
                                                                                    <table>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th width="40%">Coverage
                                                                                                    Comparisions</th>
                                                                                                <th width="30%">Expiring
                                                                                                </th>
                                                                                                <th width="30%">Proposed
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <ng-container
                                                                                            *ngFor="let el of pricingPkgData">
                                                                                            <tbody
                                                                                                *ngFor="let item of el">
                                                                                                <tr>
                                                                                                    <td widtd="40%"
                                                                                                        class="grp-heading">
                                                                                                        <h3>{{item.coverageName}}
                                                                                                        </h3>
                                                                                                    </td>
                                                                                                    <td widtd="30%">
                                                                                                    </td>
                                                                                                    <td widtd="30%">
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr
                                                                                                    *ngFor="let param of item.coverages">
                                                                                                    <td>{{param.parameterName}}
                                                                                                    </td>
                                                                                                    <td>{{param.expiringValue}}
                                                                                                    </td>
                                                                                                    <td>{{param.recommendedValue}}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </ng-container>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 5000">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="tps-table">
                                                                                    <div class="audit-table">
                                                                                        <table>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th width="20%">
                                                                                                        Coverage Type
                                                                                                    </th>
                                                                                                    <th width="20%">
                                                                                                        Renewal Policy
                                                                                                        Term</th>
                                                                                                    <th width="20%">
                                                                                                        Expiring Premium
                                                                                                    </th>
                                                                                                    <th width="20%">
                                                                                                        Annualized
                                                                                                        Premium</th>
                                                                                                    <th width="10%">
                                                                                                        Renewal Premium
                                                                                                    </th>
                                                                                                    <th width="10%">%
                                                                                                        Change</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>{{pricingWCRatingPremium.coverageName}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPremium.renewalPolicy}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPremium.expiringValue}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPremium.annualPremium}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPremium.recommendedValue}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPremium.difference}}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr
                                                                                                    class="font-weight-bold">
                                                                                                    <td>Total Payroll
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                    <td>{{pricingWCRatingPayroll.expiringValue}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPayroll.annualPremium}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPayroll.recommendedValue}}
                                                                                                    </td>
                                                                                                    <td>{{pricingWCRatingPayroll.difference}}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Condition of Market Place'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a Static Slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Subjectivities and Billing'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <label class="form-label">Description
                                                                                    <span>*</span></label>
                                                                                <p-editor [style]="{'height':'170px'}"
                                                                                    formControlName="sbDescription">
                                                                                </p-editor>
                                                                                <div class="display-error"
                                                                                    *ngIf="f.sbDescription.invalid && isSubmitted">
                                                                                    <span
                                                                                        *ngIf="f.sbDescription.errors?.required">{{_quoteDetailsMessage.sbDescription.required}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <!-- <ng-container *ngIf="currentSlideName === 'Summary'">
                                                              <div class="col-12 col-sm-12 col-md-12 mt-2">
                                                                  <label class="form-label">Differences <span>*</span></label>
                                                                  <p-editor [style]="{'height':'170px'}"
                                                                      formControlName="summaryDifferences">
                                                                  </p-editor>
                                                                  <div class="display-error"
                                                                      *ngIf="f.summaryDifferences.invalid && isSubmitted">
                                                                      <span
                                                                          *ngIf="f.summaryDifferences.errors?.required">{{_quoteDetailsMessage.summaryDifferences.required}}</span>
                                                                  </div>
                                                              </div>
                                                              <div class="col-12 col-sm-12 col-md-12 mt-2">
                                                                  <label class="form-label">Services <span>*</span></label>
                                                                  <p-editor [style]="{'height':'170px'}"
                                                                      formControlName="summaryServices">
                                                                  </p-editor>
                                                                  <div class="display-error"
                                                                      *ngIf="f.summaryServices.invalid && isSubmitted">
                                                                      <span
                                                                          *ngIf="f.summaryServices.errors?.required">{{_quoteDetailsMessage.summaryServices.required}}</span>
                                                                  </div>
                                                              </div>
                                                          </ng-container> -->

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Disclaimer'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Philadelphia Insurance Companies'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Amtrust'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Church Mutual'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Guide One'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Non-Profits Insurance Alliance'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Berkley'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Hanover'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 'Key Risk'">
                                                                            <div
                                                                                class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                <div class="static-Slide">
                                                                                    <h2>This is a static slide.</h2>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 4200">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-12 col-sm-12 col-md-2 mt-5">

                                                                                    <a title=""
                                                                                        class="pro-img-box me-5"><img
                                                                                            alt="" class="proimg"
                                                                                            src="assets/images/user.png"></a>
                                                                                </div>

                                                                                <div
                                                                                    class="col-12 col-sm-12 col-md-10 mt-2">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Name
                                                                                            </label>
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-12 col-sm-6 col-md-12">
                                                                                                    <div
                                                                                                        class="flex-div">
                                                                                                        <p-autoComplete
                                                                                                            class="form-control auto-comp-prp"
                                                                                                            placeholder="Name"
                                                                                                            formControlName="contactName"
                                                                                                            [showEmptyMessage]="true"
                                                                                                            [minLength]="1"
                                                                                                            (onSelect)="selectItem($event.value,'ownerNameContact')"
                                                                                                            [suggestions]="ownerlist"
                                                                                                            (completeMethod)="getOwnerlist($event)"
                                                                                                            field="value">
                                                                                                            <ng-template
                                                                                                                let-contact
                                                                                                                pTemplate="item">
                                                                                                                <div
                                                                                                                    class="company-item">
                                                                                                                    <div>
                                                                                                                        {{contact.userName}}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </ng-template>
                                                                                                        </p-autoComplete>
                                                                                                        <img (click)="openLookupModal('Owner', 'Contact', '')"
                                                                                                            src="assets/images/search.svg"
                                                                                                            alt=""
                                                                                                            class="ms-2">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Title
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Title"
                                                                                                formControlName="contactTitle"
                                                                                                (change)="onCoverPageTitleChange()" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-4 mt-2">
                                                                                            <label
                                                                                                class="form-label">Phone
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Phone"
                                                                                                mask="(000) 000-0000"
                                                                                                formControlName="contactPhone"
                                                                                                (change)="onCoverPageProposalChange()" />
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-2 mt-2">
                                                                                            <label
                                                                                                class="form-label">Extension
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Extension"
                                                                                                formControlName="contactExt"
                                                                                                (keypress)="ValidateKey.integer($event,5)"
                                                                                                (change)="onCoverPageProposalChange()" />
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Email
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Email"
                                                                                                formControlName="contactEmail"
                                                                                                (change)="onCoverPageProposalChange()" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 4300">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-12 col-sm-12 col-md-12 mt-2">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Org.
                                                                                                Name</label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Org. Name"
                                                                                                formControlName="thankyouOrgName" />
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label class="form-label">CE
                                                                                                Name </label>
                                                                                            <!-- <div class="flex-div space-between">
                                                                                  <input class="form-control"
                                                                                      placeholder="CE Name"
                                                                                      formControlName="thankyouCEName" />
                                                                                  <img src="assets/images/search.svg"
                                                                                      (click)="openLookupModal('Owner', 'ThankYou', '')"
                                                                                      alt="" class="ms-2">
                                                                              </div> -->
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-12 col-sm-6 col-md-12">
                                                                                                    <div
                                                                                                        class="flex-div">
                                                                                                        <p-autoComplete
                                                                                                            class="form-control auto-comp-prp"
                                                                                                            placeholder="CE Name"
                                                                                                            formControlName="thankyouCEName"
                                                                                                            [showEmptyMessage]="true"
                                                                                                            [minLength]="1"
                                                                                                            (onSelect)="selectItem($event.value,'ownerName')"
                                                                                                            [suggestions]="ownerlist"
                                                                                                            (completeMethod)="getOwnerlist($event)"
                                                                                                            field="value">
                                                                                                            <ng-template
                                                                                                                let-thankyouCEName
                                                                                                                pTemplate="item">
                                                                                                                <div
                                                                                                                    class="company-item">
                                                                                                                    <div>
                                                                                                                        {{thankyouCEName.userName}}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </ng-template>
                                                                                                        </p-autoComplete>
                                                                                                        <img (click)="openLookupModal('Owner', 'ThankYou', '')"
                                                                                                            src="assets/images/search.svg"
                                                                                                            alt=""
                                                                                                            class="ms-2">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Name
                                                                                            </label>
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-12 col-sm-6 col-md-12">
                                                                                                    <div
                                                                                                        class="flex-div">
                                                                                                        <p-autoComplete
                                                                                                            class="form-control auto-comp-prp"
                                                                                                            placeholder="Name"
                                                                                                            formControlName="contactName"
                                                                                                            [showEmptyMessage]="true"
                                                                                                            [minLength]="1"
                                                                                                            (onSelect)="selectItem($event.value,'ownerNameContact')"
                                                                                                            [suggestions]="ownerlist"
                                                                                                            (completeMethod)="getOwnerlist($event)"
                                                                                                            field="value">
                                                                                                            <ng-template
                                                                                                                let-contact
                                                                                                                pTemplate="item">
                                                                                                                <div
                                                                                                                    class="company-item">
                                                                                                                    <div>
                                                                                                                        {{contact.userName}}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </ng-template>
                                                                                                        </p-autoComplete>
                                                                                                        <img (click)="openLookupModal('Owner', 'Contact', '')"
                                                                                                            src="assets/images/search.svg"
                                                                                                            alt=""
                                                                                                            class="ms-2">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Title
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Title"
                                                                                                formControlName="contactTitle"
                                                                                                (change)="onCoverPageTitleChange()" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-4 mt-2">
                                                                                            <label
                                                                                                class="form-label">Phone
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Phone"
                                                                                                mask="(000) 000-0000"
                                                                                                formControlName="contactPhone"
                                                                                                (change)="onCoverPageProposalChange()" />
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-2 mt-2">
                                                                                            <label
                                                                                                class="form-label">Extension
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Extension"
                                                                                                formControlName="contactExt"
                                                                                                (keypress)="ValidateKey.integer($event,5)"
                                                                                                (change)="onCoverPageProposalChange()" />
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-12 col-sm-12 col-md-6 mt-2">
                                                                                            <label
                                                                                                class="form-label">Email
                                                                                            </label>
                                                                                            <input class="form-control"
                                                                                                placeholder="Email"
                                                                                                formControlName="contactEmail"
                                                                                                (change)="onCoverPageProposalChange()" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>

                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 4400">
                                                                            <div>
                                                                                <kendo-grid [data]="renewalList" [virtualColumns]="true"
                                                                                    class="sampleGrid">
                                                                                    <kendo-grid-column [width]="10"
                                                                                        field="wbaId" title="WBAID">
                                                                                    </kendo-grid-column>
                                                                                    <kendo-grid-column [width]="30"
                                                                                        field="lob" title="Line">
                                                                                    </kendo-grid-column>
                                                                                    <kendo-grid-column [width]="30"
                                                                                        field="effectiveDate"
                                                                                        title="Effective Date">
                                                                                    </kendo-grid-column>
                                                                                    <kendo-grid-column [width]="30"
                                                                                        field="policyExpirationDate"
                                                                                        title="Policy Expiration Date">
                                                                                    </kendo-grid-column>
                                                                                </kendo-grid>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 6000">
                                                                            <p><b>Exp. Mode : </b>{{expMode}}</p>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="currentSlideNumber === 4600">
                                                                            <p><b>Primary WBA's Line :
                                                                                </b>{{currentLineName}}</p>
                                                                        </ng-container>
                                                                        <ng-template #NoData> No data found
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <!-- Dialog for error -->
                <p-dialog [header]="_errorBox.header" [modal]="true" [(visible)]="_errorBox.isVisible"
                    [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
                    [styleClass]="_errorBox.borderColor" *ngIf="_errorBox">
                    <p>{{_errorBox.message}}</p>
                    <ng-template pTemplate="footer">
                        <p-button (click)="errorBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                    </ng-template>
                </p-dialog>
                <!-- Dialog for confirmation -->
                <p-dialog header="Alert" [modal]="true" [(visible)]="_warningBox.isVisible"
                    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                    [resizable]="false" [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
                    <p>{{ _warningBox.message }}</p>
                    <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                        <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                    </ng-template>
                </p-dialog>

                <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
                    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                    [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                    <p>{{ _confirmBox.message }}</p>
                    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                        <p-button (click)="_confirmBox.isVisible = false" label="No"
                            styleClass="btn btn-outline-primary"></p-button>
                        <p-button (click)="saveProposal('cancel')" label="Yes" styleClass="btn btn-primary"></p-button>
                    </ng-template>
                </p-dialog>


                <ng-template #content let-modal>
                    <div id="view-pdf">
                        <div class="modal-header">
                            <h2 class="modal-title" id="modal-basic-title">{{slideName}}</h2>
                            <div class="flex-div">
                                <button class="btn btn-primary ml-4" (click)="downloadPreviewProposalReport()">
                                    Download</button>
                                <a href="javascript:void(0)" title="" (click)="modal.dismiss('Cross click')"
                                    class="close" data-bs-dismiss="modal" aria-label="Close"><img
                                        src="assets/images/cross.svg" alt="" /></a>
                            </div>
                        </div>
                        <div class="modal-body">
                            <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="true" [rotation]="0"
                                [fit-to-page]="true" [show-all]="true" style="width: 100%; height: 610px" [zoom]="1"
                                [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
                                [external-link-target]="'blank'" [autoresize]="true"
                                [show-borders]="false"></pdf-viewer>
                        </div>
                    </div>
                </ng-template>


            </div>
        </div>
