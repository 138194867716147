import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialsComponent } from 'src/app/core/components/dials/dials.component';
import { RingCentralLoginComponent } from 'src/app/core/components/ring-central-login/ring-central-login.component';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../services/white-board-activity.service';
@Component({
  selector: 'app-executive-summary',
  templateUrl: './executive-summary.component.html',
  styleUrls: ['./executive-summary.component.css']
})
export class ExecutiveSummaryComponent implements OnInit {
  routeFrom: any;
  _navigationPath: NavigationPath;
  wbaId: any = sessionStorage.getItem(environment.storageVariables.wbaId) || "0";

  detailedSummary: any[] = [];
  coverageCheckList: any = [];
  detailedSummaryAssessment: any[] = [];
  wbaView: any;
  _confirmBox: ConfirmBox;
  wbaListId: any;
  initiated: any;

  constructor(
    public route: Router,
    private _wbaService: WhiteBoardActivityService,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox()
    this.routeFrom = this.route.getCurrentNavigation()?.extras?.state?.routeValue;
  }

  ngOnInit(): void {
    this.activeRouter.queryParams.subscribe((params: any) => {
      if (params) {
        this.wbaListId = params.listId
        this.initiated = params.initiated
        console.log("params", params);
      }
    });
    this.activeRouter.queryParams.subscribe((params: any) => {
      if (params) {
        this.wbaListId = params.listId
        this.initiated = params.initiated
        console.log("params", params);
      }
    });
    this.getCoveragedata();
    this.getWhiteBoardDetails(this.wbaId);
  }

  getWhiteBoardDetails(wbaId: any) {
    this._wbaService.getWBAById(wbaId).subscribe(resp => {
      this.wbaView = resp.whiteBoardActivityInfo;
    })
  }

  getCoveragedata() {
    this._wbaService.getCoverageAssessmentDetails(parseInt(this.wbaId)).subscribe((response: any) => {
      this.coverageCheckList = response.coverageAssessmentDetail;
      this.coverageCheckList.forEach((cov: any) => {
        cov.coverages.forEach((parameters: any) => {
          if (parameters.expiringAssessmentId > 0) {
            this.detailedSummary.push({
              "coverageName": cov.coverageName,
              "coverageId": cov.coverageId,
              "coverageParameterId": parameters.coverageParameterId,
              "parameterName": parameters.parameterName,
              "expiringValue": parameters.expiringValue,
              "valueType": parameters.valueType,
              "expiringAssessmentId": parameters.expiringAssessmentId,
              "expiringConditionDescription":
                parameters.expiringAssessmentId === 1 ? "Inadequate" :
                  parameters.expiringAssessmentId === 2 ? "Better Option Available" :
                    parameters.expiringAssessmentId === 3 ? "Adequate" :
                      parameters.expiringAssessmentId === 4 ? "Superior" :
                        parameters.expiringAssessmentId === 5 ? "Do not Assess" :
                          "",
              "offeringGuide": parameters.offeringGuide,
              "offeringGuideOrder":
                parameters.offeringGuide === "High Priority" ? 1 :
                  parameters.offeringGuide === "Medium Priority" ? 2 :
                    parameters.offeringGuide === "Low Priority" ? 3 :
                      4
            })
          }
        });
      })

      this.detailedSummary = this.detailedSummary.filter(x => x.expiringAssessmentId > 0);
      this.detailedSummary = this.detailedSummary.sort((a, b) => a.offeringGuideOrder - b.offeringGuideOrder);
      this.detailedSummary = this.detailedSummary.sort((a, b) => a.expiringAssessmentId - b.expiringAssessmentId);
      this.detailedSummaryAssessment = this.detailedSummary.reduce((r: { expiringConditionDescription: any; detailedSummaryGroup: any; }[], { expiringConditionDescription }: any) => {
        if (!r.some(o => o.expiringConditionDescription == expiringConditionDescription)) {
          r.push({ expiringConditionDescription, detailedSummaryGroup: this.detailedSummary.filter((v: { expiringConditionDescription: any; }) => v.expiringConditionDescription == expiringConditionDescription) });
        }
        return r;
      }, []);
    });
  }

  closeModal() {
    if (this.initiated == 'WhiteboardList') {
      this.route.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl())
    } else {
      this.route.navigate([
        '/' +
        this._navigationPath.getWhiteboardsUrl() +
        '/' +
        this._navigationPath.getwhiteboardsApplicationUrl(),
      ], { queryParams: { wbaId: this.wbaView.id, prospectId: this.wbaView.prospectId, listId: this.wbaListId } });
    }
  }

  //to open 'Add Dial' Modal
  openAddDialModal(item: any) {
    this._confirmBox.isVisible = false;
    var rcTokenExp = localStorage.getItem('RC_tokenExp')
    if (localStorage.getItem('RC_token') != 'null' && parseInt(rcTokenExp!) > new Date().getTime()) {
      this.dialScreen(item)
    }
    else {
      this.rcLogin(item)
    }
  }

  dialScreen(item: any) {
    const modalRef = this.modalService.open(DialsComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog-d',
    });
    var data = {
      number: item,
      prospectId: this.wbaView.prospectId,
    };
    modalRef.componentInstance.dialNumber = data;
    modalRef.closed.subscribe((resp) => {
    });
  }

  rcLogin(item: any) {
    sessionStorage.setItem('modalStatus', '')
    localStorage.setItem('RC_tokenExp', '')
    const modalRef = this.modalService.open(RingCentralLoginComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'ringDialog1',
    });
    const timer = setInterval(() => {
      if (sessionStorage.getItem('modalStatus') == 'close') {
        modalRef.close()
        clearInterval(timer)
        this.dialScreen(item)
      } else if (sessionStorage.getItem('modalStatus') == 'open') {
        modalRef.close()
        clearInterval(timer)
      }
    }, 500)
  }
}
