<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr my-2">
                <div class="heading">
                    <h2> {{this.prospectLogoObject?.prospectLogoId == 0 ? 'Upload Prospect Logo' : 'Change Prospect
                        Logo'}} </h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>

        </div>


        <div class="add-content add-form">
            <div class="pdg-con">
                <div class="con1 tbl-pro-detail">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <div class="row-detail">
                                <div class="lbl-title edt">Company Name</div>
                                <div class="des ml-2">{{prospectDeatils?.prospectName}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">Phone</div>
                                <div class="des ml-2"> {{ prospectDeatils?.phoneNumber ? (prospectDeatils?.phoneNumber |
                                    mask: "(000) 000-0000") : " "}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">Industry</div>
                                <div class="des ml-2">{{prospectDeatils?.prospectInformation?.industryName}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">Website</div>
                                <div class="des ml-2">{{prospectDeatils?.website}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">Address Line 1</div>
                                <div class="des ml-2">{{prospectDeatils?.addressLine1}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">Address Line 2</div>
                                <div class="des ml-2">{{prospectDeatils?.addressLine2}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">City</div>
                                <div class="des ml-2">{{prospectDeatils?.city}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">State</div>
                                <div class="des ml-2">{{prospectDeatils?.state}}</div>
                            </div>
                            <div class="row-detail">
                                <div class="lbl-title edt">ZIP Code</div>
                                <div class="des ml-2">{{prospectDeatils?.zipCode}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 my-3" style="text-align: center;">
                            <img [src]="imageSrc" *ngIf="imageSrc" style="height: 200px; width:200px">
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 my-3">
                            <div class="content-block ">
                                <div class="upload-block file-upload-wrapper">
                                    <div class="file-drop-area">
                                        <span class="flex-div justify-center upl"><img
                                                src="assets/images/upload-file.svg" width="30px" alt="" /></span>
                                        <span class="file-message small">Click or drag & drop your data file.</span>
                                        <input id="file" class="file-input" #fileUpload type="file"
                                            (change)="onFileChanges($event)" accept=".png, .jpg, .jpeg" />
                                    </div>
                                </div>
                                <div class="mt-2" style="text-align: left">
                                    <span *ngIf="filesToUpload">
                                        Import file name : {{ filesToUpload.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blk-bt">
                <button class="btn btn-outline-primary me-4" (click)="cancel()">Cancel</button>
                <button class="btn btn-primary " (click)="uploadLogo()">Save</button>
            </div>
        </div>
        <!-- Dialog for confirmation -->
        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>{{_confirmBox.message}}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                </p-button>
                <p-button (click)="closeModal()" label="Yes" styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>

        <!-- Dialog for error -->
        <p-dialog [header]="_errorBox.header" [modal]="true" [(visible)]="_errorBox.isVisible"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_errorBox.borderColor" *ngIf="_errorBox">
            <p>{{_errorBox.message}}</p>
            <ng-template pTemplate="footer">
                <p-button (click)="errorBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>