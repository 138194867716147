import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WhiteBoardActivityService } from '../features/white-board/services/white-board-activity.service';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-view-proposal',
  templateUrl: './view-proposal.component.html',
  styleUrls: ['./view-proposal.component.css']
})
export class ViewProposalComponent implements OnInit {
  wbaId: any;
  fileDatafetch: any;
  private selectedRowIndex: number | null = null;
  selectedFile: any;
  showError: boolean = false;
  errorList: any[] = [];
  errorMessage: any;
  showModifiedProposalColumn: boolean = true; // Flag to control column visibility
  subscription !: Subscription;

  constructor(public activeModal: NgbActiveModal,
    private activeRouter: ActivatedRoute,
    private _wbaService: WhiteBoardActivityService,
  ) {


  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.wbaId = params.wbaId;
      });

    this.getWbaViewProposal(this.wbaId)
    this.subscription = timer(0, 5000).pipe(
      switchMap(() => this._wbaService.getViewProposal(this.wbaId, false))
    ).subscribe((result: any) => {
      this.fileDatafetch = result;
      if (!this.fileDatafetch[0]?.proposalStatus) this.fileDatafetch = [];
      this.checkModifiedProposalDoc();
      if (this.fileDatafetch.length > 0 && this.fileDatafetch[0].proposalStatus === 'Completed') {
        this.subscription.unsubscribe();
      }
    });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //get view proposal data
  getWbaViewProposal(wbaId: any) {
    this._wbaService.getViewProposal(wbaId, true).subscribe((resp: any) => {
      if (resp) {
        this.fileDatafetch = resp;
        if (!this.fileDatafetch[0]?.proposalStatus) this.fileDatafetch = [];
        this.checkModifiedProposalDoc();
      }
    })
  }

  cancel() {
    this.activeModal.close();
  }

  onCellClick(event: any) {

    // this.finalWBAList.data.forEach((res: any) => {
    //   res.isSelected = false
    //   let index = this.wbaListArr?.wbalists.findIndex((e: any) => e.wbaId == res?.id);
    //   if (index > -1) this.wbaListArr?.wbalists.splice(index, 1);
    // });
    // event.dataItem.isSelected = true;
    // let obj = {
    //   wbaId: event.dataItem?.id,
    //   lob: event.dataItem?.masterBusinessLine,
    //   lobId: event.dataItem?.masterBusinessLineId,
    //   transType: event.dataItem?.transType
    // }
    // let index = this.wbaListArr?.wbalists.findIndex((res: any) => res.wbaId == event.dataItem?.id);
    // if (index == -1) this.wbaListArr?.wbalists.push(obj);
    // console.log(event.dataItem);

    // // Uncheck the previously selected row if it exists
    // if (this.selectedRowIndex !== null && this.selectedRowIndex !== rowIndex) {
    //   this.fileDatafetch[this.selectedRowIndex].isChecked = false;
    // }

    // // Toggle the checked state of the current row
    // this.fileDatafetch[rowIndex].isChecked = !this.fileDatafetch[rowIndex].isChecked;

    // // Update the selectedRowIndex to the current row if it is checked, otherwise set it to null
    // this.selectedRowIndex = this.fileDatafetch[rowIndex].isChecked ? rowIndex : null;



  }

  onUploadProposalChange(event: any) {
    // const files = event.target.files[0];
    // this.selectedFile = files;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }

  }

  onUploadProposal() {
    const formData = new FormData();
    // formData.append('Id', "1");
    // formData.append('wbaId', "72043");
    formData.append('uploadProposalFile', this.selectedFile ? this.selectedFile : null);

    this._wbaService.uploadProposal(this.fileDatafetch[0].id, this.fileDatafetch[0].wbaId, formData).subscribe((resp: any) => {
      if (resp) {
        this.getWbaViewProposal(this.wbaId)
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else if (err.status === 412) {
            this.showError = true;
            this.errorMessage = err.error.detail;
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = err.message;
            this.errorList.push(this.errorMessage);
          }
        }
      })
  }

  downloadFile(filePath: string) {
    // Construct the full URL for downloading the file
    const downloadUrl = `${filePath}`;

    // Trigger the file download by creating an anchor element
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = filePath; // Optional: specify the filename to download
    link.click();
  }

  checkModifiedProposalDoc() {
    // Check if any item has a non-null, non-empty modifiedProposalDoc field
    this.showModifiedProposalColumn = this.fileDatafetch.some((item: any) => item.modifiedPath);
  }

}


