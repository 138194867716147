import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { environment } from 'src/environments/environment';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { ValidatePolicyComponent } from '../validate-policy/validate-policy.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { DatacacheService } from '../../../services/datacache.service';

@Component({
  selector: 'app-submit-to-marketing',
  templateUrl: './submit-to-marketing.component.html',
  styleUrls: ['./submit-to-marketing.component.css']
})
export class SubmitToMarketingComponent implements OnInit {
  @Input() public tabName: any;
  @Input() public PrevtabName: any;
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() policyInEPIC: any = new EventEmitter<boolean>(true);
  @Input() public userDetailsOutput: any;
  @Input() public acordId: any;
  @Input() public acordStatus: any;
  @Input() public selectedValue: any;
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  @Input() isAssesmentParamsHasMandatoryValues: any;
  @Output() handleMarketingIconStatus: any = new EventEmitter<any>();



  public _navigationPath: NavigationPath = new NavigationPath();
  MarketingDropdown: boolean = false;
  reviewStatus: boolean = true;
  acordWBA: boolean = false;
  SDRSMapproval: boolean = false;
  marketingReview: boolean = true;
  accessReviewList: Array<any> = [];
  // selectedReview: any;
  selectedReviewResponse: any;
  AcordResponse: Array<any> = [];
  finalResponse: Array<any> = [];
  rolesList: Array<any> = [];
  roleId: any;
  roleName: any;
  selectedAcord: any;
  wbaId: any
  hideReviewDropdown: boolean = false
  marketingReviewStatus: any;
  errorMessage: SafeHtml = ''; // Initialize to an empty string


  acords: any = [
    { label: 'Select', value: 'select' },
    { label: 'Requested', value: 'Requested' },
    { label: 'Not Needed', value: 'Not Needed' },
    { label: 'Incomplete Information', value: 'Incomplete Information' },
    { label: 'Incomplete Contact', value: 'Incomplete Contact' },
  ]

  acord: any = [
    { label: 'Completed', value: 'Completed' },
    { label: 'Incomplete', value: 'Incomplete' },
  ]

  mod: any = [
    { label: 'Attached', value: 'Attached' },
    { label: 'Not Needed', value: 'Not Needed' },
  ]

  application: any = [
    { label: 'Attached', value: 'Attached' },
    { label: 'Not Needed', value: 'Not Needed' },
  ]

  WbaReviewStatus: any = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Complete', value: 'Complete' },
    { label: 'Incomplete', value: 'Incomplete' }
  ]

  reminder: any = [
    { label: '3', value: '3' },
    { label: '5', value: '5' },
    { label: '7', value: '7' }
  ]

  userId: any;
  userDetails: any;
  finalApiResponse: any;
  permissionsDeniedLocal: any;
  permissionId: any;
  permissionList: any;
  roles: any;
  behaviourResponse: Array<any> = [];
  AcordArray: any;
  requestStatus: any;
  marketingReviewArray: any;
  selectedComment: any;
  isDisabled: boolean = false;
  reasonResponse: any;
  RsmArray: any;
  sdRsmStatus: any;
  createdByUserName: any;
  marketingroleName: any;
  rsmRoleName: any;
  userRoleId: any;
  loggedInUserName: any;
  selectedWbaReviewStatus: any;
  selectedReasonForWbaReview: any;
  selectedReminderForWbaReview: any;
  wbaResponse: any = [];
  wbaReviewTable: boolean = false;
  wbaReviewStatus: any;
  epicUniquePolicyId: any;
  _warningBox: ConfirmBox;
  _warningBox1: ConfirmBox;
  epicPolicyResponse: any;
  wbaReminderDropdown: boolean = true;
  assessmentWCRating: any;
  assessmentGLRating: any;
  workercompRating: any;
  glRating: any;
  assessmentName: any;
  cvgName: any;
  businessLineIdResp: Array<any> = [];
  BusinessLineId: any;
  BusinessLine: any;
  wbaFileResponse: Array<any> = [];
  selectedRsmAcord: string = ''
  selectedRsmModDocument: string = ''
  selectedRsmFinancials: string = ''
  selectedRsmSOV: string = ''
  selectedRsmApplication: string = ''
  selectedRsmLossRuns: string = ''
  selectedBOR: string = '';
  wbaDropdown: boolean = false;
  borResponse: any;
  _isBORFormSaved: boolean = false;
  wbaProgressChange: any;
  isDirty: boolean = false;
  loggedInUserId: any;
  activeWbaId: any;
  marketingReviewResponse: any;
  prospectId: any;
  wbaDetails: any
  wbaTransType: any;
  validatePolicyResponse: any;
  validationMessage: boolean = false;
  checkValidationPopup: boolean = true;
  coverageAssessmentDetailData: any
  tabRefresh: any;
  takingDataFromApi: any;

  constructor(
    private wbaService: WhiteBoardActivityService,
    private _AcmeProductService: AcmeProductService,
    private _loaderService: LoaderService,
    private _notifierService: WBANotifierService,
    private _addWBAService: WhiteBoardActivityService,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private _dataCacheService: DatacacheService
  ) {
    this._warningBox = new ConfirmBox();
    this._warningBox1 = new ConfirmBox()
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    // this.getBusinessLineList()
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.loggedInUserName = userDetails.user.display_name;
    this.loggedInUserId = userDetails.user.userId;
    this.selectedReminderForWbaReview = sessionStorage.getItem('reminderDays');
    // this.getAccessReviewList();
    // this.getAccordReasonList();
    // this.getWhiteBoardDetails(this.activeWbaId);
    // this.getCoverageAssessmentDetails();
    this.intializeData();
    window.scroll(0, 0);
  }

  getBusinessLineList() {
    this.wbaService.getBusinessLineList().subscribe(resp => {
      this.businessLineIdResp = resp.businessLineList
      this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
      this.BusinessLineId = this.BusinessLine?.businessLineId;
    })
  }

  intializeData() {
    this.tabRefresh = this._dataCacheService.retrieveData('tabRefresh');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    // this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLineIdResp');
    this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLine');
    this.AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse');
    this.wbaResponse = this._dataCacheService.retrieveData('wba_wbaResponse');
    this.reasonResponse = this._dataCacheService.retrieveData('wba_marketingResponse');
    this.coverageAssessmentDetailData = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == "Marketing Review");
    if (!this.wbaDetails || this.AcordResponse.length == 0) this.getAllMaster();
    else this.maprsubmitMrkt();
  }

  getAllMaster() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };
    let data = {
      acordReasonType: "marketing"
    }
    // var businessLineList = this.wbaService.getBusinessLineList();
    var accessReviewList = this.wbaService.getAccessReviewList(requestBody);
    var AccordReasonList = this.wbaService.getAccordReasonList(data);
    var wbaActivityList = this._addWBAService.getWBAById(this.activeWbaId);
    var CoverageAssessmentDetails = this.wbaService.getCoverageAssessmentDetails(this.activeWbaId);
    forkJoin([accessReviewList, AccordReasonList, wbaActivityList, CoverageAssessmentDetails
    ]).subscribe((resp) => {
      if (resp) {
        // this.businessLineIdResp = resp[0].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[0]);
        this.AcordResponse = resp[0].acordResponse;
        this.wbaResponse = resp[0].wbaReviews;
        this.reasonResponse = resp[1].acordReasonList;
        this.wbaDetails = resp[2].whiteBoardActivityInfo;
        this.coverageAssessmentDetailData = resp[3];
        // this.tabRefresh[7].refresh = false;
        // this._dataCacheService.compressAndCacheData('tabRefresh', this.tabRefresh);
        this.maprsubmitMrkt();
        this.setDataInCache();
      }
    })
  }
  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    // this._dataCacheService.compressAndCacheData('wba_businessLineIdResp', this.businessLineIdResp);
    this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
    this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
    this._dataCacheService.compressAndCacheData('wba_marketingResponse', this.reasonResponse);
    this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.coverageAssessmentDetailData);
  }

  maprsubmitMrkt() {
    if (this.businessLineIdResp) {
      this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
      this.BusinessLineId = this.BusinessLine?.businessLineId;
    }
    if (this.coverageAssessmentDetailData) {
      let response = this.coverageAssessmentDetailData?.coverageAssessmentDetail?.find((el: any) => el?.coverageName == "General Liability" || el?.coverageName == "Workers Compensation");
      if (response?.coverageName == "General Liability") {
        this.assessmentGLRating = response?.ratingBasis;
      } else if (response?.coverageName == "Workers Compensation") {
        this.assessmentWCRating = response?.workercompRating;
      }
    }
    // else this.getCoverageAssessmentDetails();
    this.mapAccessReviewList();
    this.mapwbaActivityList();
  }

  mapAccessReviewList() {
    this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
    this.acordStatus = this.AcordArray?.requestStatus
    this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
    this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
    this.createdByUserName = this.marketingReviewArray?.createdByUserName;
    this.RsmArray = this.AcordResponse?.find(e => e?.requestType == 'SD Approval' || e?.requestType == 'AM Status');
    this.sdRsmStatus = this.RsmArray?.requestStatus;
    this.acordId = this.AcordArray?.id
    this.showVisibleFields();
    this.setRsmApprovalDropdowns();
    this.setwbaDropdown();
    this.setwbaReviewTable();
    this.acordWBA = true;
    this.marketingReview = false;
  }
  mapwbaActivityList() {
    this.wbaProgressChange = this.wbaDetails.progressName;
    this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    this.epicUniquePolicyId = this.wbaDetails?.epicUniquePolicyId;
    this.setUpdatedProgressId.emit(this.wbaDetails.progressId);
    this.prospectId = this.wbaDetails?.prospectId
    this.wbaTransType = this.wbaDetails?.transType;
    // if (!this.epicUniquePolicyId) this.ValidatePolicyInEpic();
    if (!this.epicUniquePolicyId) {
      this.validatePolicyResponse = this._dataCacheService.retrieveData('wba_validatePolicyResponse');
      if (this.validatePolicyResponse?.length) this.validatePolicyResponse?.message ? this.validationMessage = true : this.validationMessage = false;
      else this.ValidatePolicyInEpic();
    }
  }

  setAcord(item: any) {
    this.isDirty = true;
    this.selectedAcord = item;
  }
  setComments(item: any) {
    this.isDirty = true;
    this.selectedComment = item;
    this.isDisabled = false;
  }

  setWbaReviewStatus(item: any) {
    this.isDirty = true;
    this.selectedWbaReviewStatus = item
    this.setwbaReminderDropdown()
  }

  setwbaReminderDropdown() {
    this.wbaReminderDropdown = this.selectedWbaReviewStatus == 'Complete' ||
      this.selectedWbaReviewStatus == 'Incomplete' ? false : true;
  }

  setReminderForWbaReview(item: any) {
    this.isDirty = true;
    this.selectedReminderForWbaReview = item
    sessionStorage.setItem('reminderDays', this.selectedReminderForWbaReview);
  }

  getAccessReviewList() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };

    this.wbaService.getAccessReviewList(requestBody).subscribe(res => {
      this.wbaResponse = res?.wbaReviews;
      this.AcordResponse = res?.acordResponse;
      this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', res);
      this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
      this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
      this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
      this.acordStatus = this.AcordArray?.requestStatus
      this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
      this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
      this.createdByUserName = this.marketingReviewArray?.createdByUserName;
      this.RsmArray = this.AcordResponse?.find(e => e?.requestType == 'SD Approval' || e?.requestType == 'AM Status');
      this.sdRsmStatus = this.RsmArray?.requestStatus;
      this.acordId = this.AcordArray?.id
      this.showVisibleFields();
      this.setRsmApprovalDropdowns();
      this.setwbaDropdown();
      this.setwbaReviewTable();
      this.rnCaseErrorIcon()
    });
    this.acordWBA = true;
    this.marketingReview = false;
  }
  showVisibleFields() {
    this.MarketingDropdown = this.marketingReviewStatus == "Pending" ? true : false;

  }
  rnCaseErrorIcon() {
    if (this.wbaDetails?.transType == 'RN') {
      if (this.marketingReviewStatus == "Completed") {
        this.handleMarketingIconStatus.emit({ label: 'Marketing Review', value: false });
      }
      else {
        this.handleMarketingIconStatus.emit({ label: 'Marketing Review', value: true });
      }

    }
  }
  setVisibleFields() {
    this.hideReviewDropdown = this.AcordResponse &&
      this.AcordResponse.length &&
      this.AcordResponse?.find(e => e?.requestStatus == 'Incompleted' || 'Incomplete Contact') ? false : true;
  }

  setwbaReviewTable() {
    if (this.wbaResponse[0]?.wbaReviewStatus != null) {
      this.wbaReviewTable = true;
      this.MarketingDropdown = false;
      this.SDRSMapproval = false;
      this.wbaDropdown = false;
      this.handleMarketingIconStatus.emit({ label: 'Marketing Review', value: false });
    } else {
      if (this.wbaDetails?.transType == 'NB') this.handleMarketingIconStatus.emit({ label: 'Marketing Review', value: true });
    }
  }
  setRsmApprovalDropdowns() {
    if (this.acordStatus == "Not Needed" && this.sdRsmStatus == "Approved") {
      this.SDRSMapproval = true
    }
  }

  setwbaDropdown() {
    if (((this.acordStatus == "Requested" || this.acordStatus == "Completed") && this.sdRsmStatus == "Approved") && this.wbaResponse[0]?.wbaReviewStatus == null) {
      this.wbaDropdown = true;
    }
  }
  //   //marketing rewiew api 
  updateAccessReview() {
    if (this.selectedAcord == "Completed" && !this.epicUniquePolicyId && (this.acordStatus == "Requested" || this.acordStatus == "Completed")) {
      this.showWarningBox3();
    } else if (!this.selectedAcord) {
      this.showWarningBox7();
    } else {
      let requestBody = {
        id: this.acordId,
        wbaId: this.activeWbaId,
        acordStatus: this.acordStatus,
        marketingStatus: this.selectedAcord,
        marketingUser: this.loggedInUserName,
        marketingUserId: this.loggedInUserId,
        marketingComments: this.selectedAcord == "Incomplete" ? this.selectedComment : "",
        rsmStatus: this.sdRsmStatus === "Approved" ? this.sdRsmStatus : "",
        rsmUser: "",
        rsmUserId: "",
        rsmComments: "",
        aeUserId: "",
        amUserId: "",
        amUser: "",
        amStatus: "",
        amComments: ""
      }
      this.wbaService.updateMarketingReview(requestBody).subscribe(res => {
        this.marketingReviewResponse = res
        if (res?.id === 0) {
          this.showWarningBox8();
        } else {
          this.isDirty = false;
          this.getAccessReviewList();
          this.getWBAFileById();
          this.getWbaEmailTemplateByWbaId(this.activeWbaId)
        }
        // if (res) this._notifierService._acordSubmitted.next(true);
        if (res) {
          let data = {
            value: 'accord',
            wbaId: this.activeWbaId
          }
          this._dataCacheService.emitrefreshaccord(data);
        }
      })
      if (this.selectedAcord == "Completed") {
        this.SDRSMapproval = false;
      }
    }
  }

  CreatePolicyInEPIC() {
    if (this.assessmentWCRating?.length === 0) {
      this.showWarningBox1();
    } else if (this.assessmentGLRating?.length === 0) {
      this.showWarningBox5();
    } else if (this.epicUniquePolicyId) {
      this.showWarningBox2();
    } else if (this.validationMessage == true) {
      this.showWarningBox9();
    } else if (this.validatePolicyResponse && this.validatePolicyResponse.length && this.checkValidationPopup) {
      this.openValidatePolicyModal();
    } else {
      let requestBody = {
        wbaId: this.activeWbaId,
      };
      this.wbaService.createEpicInPolicy(requestBody).subscribe(res => {
        this.epicPolicyResponse = res;
        this.policyInEPIC.emit(true);
        this.getWhiteBoardDetails(this.activeWbaId);
        if (res.epicPolicyId) {
          this.policyCreatedMsg();
        }
        else {
          this.createPolicyErrMsg();
        }
      })
    }
  }
  showWarningBox1() {
    let message = `WC Rating Basis is not added on Coverage Assessment`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox2() {
    let message = `Policy "${this.epicUniquePolicyId}" already created in EPIC`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox3() {
    let message = `Policy is not created in EPIC`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox4() {
    let message = `Please select WBA review status as Complete.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox5() {
    let message = `GL Rating Basis is not added on Coverage Assessment.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  policyCreatedMsg() {
    let message = `Policy "${this.epicPolicyResponse.epicPolicyId}" created in EPIC`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  createPolicyErrMsg() {
    let message = this.epicPolicyResponse.errorMessage
    //this._warningBox.message = message;
    this.errorMessage = this.sanitizer.bypassSecurityTrustHtml(message);
    this._warningBox1.isVisible = true;
  }
  showWarningBox6() {
    let message = `Please complete the BOR Form`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  showWarningBox7() {
    let message = `Please select review status`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  showWarningBox8() {
    let message = this.marketingReviewResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  showWarningBox9() {
    let message = this.validatePolicyResponse?.message
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  getAccordReasonList() {
    let data = {
      acordReasonType: "marketing"
    }
    this.wbaService.getAccordReasonList(data).subscribe(res => {
      this.reasonResponse = res.acordReasonList;

    })
    this._loaderService.hide();
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaDetails = resp.whiteBoardActivityInfo;
      this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
      this.epicUniquePolicyId = resp?.whiteBoardActivityInfo?.epicUniquePolicyId;
      this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
      this.prospectId = resp.whiteBoardActivityInfo.prospectId
      this.wbaTransType = resp.whiteBoardActivityInfo?.transType;
      if (!this.epicUniquePolicyId) this.ValidatePolicyInEpic();
    });
  }

  createWbaReviewStatus() {
    let requestBody = {
      "wbaId": this.activeWbaId,
      "wbaReviewStatus": this.selectedWbaReviewStatus == "Pending" ? null : this.selectedWbaReviewStatus,
      "reminderdays": this.selectedWbaReviewStatus == "Pending" ? this.selectedReminderForWbaReview : 0
    }
    this.wbaService.createWbaReviewStatus(requestBody).subscribe(res => {
      if (res) {
        this.isDirty = false;
        this.getAccessReviewList();
        this.getWBAFileById();
        this.getWbaEmailTemplateByWbaId(this.activeWbaId)
        this.getWhiteBoardDetails(this.activeWbaId);
      }
    });
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.prospectId,
      epicUniquePolicyId: this.epicUniquePolicyId,
      wbaTransType: this.wbaTransType,
      priorPolicyId: this.wbaDetails.priorPolicyId
    }
    modalRef.componentInstance.wbaIdData = data
  }

  getCoverageAssessmentDetails() {
    this.wbaService.getCoverageAssessmentDetails(this.activeWbaId).subscribe(res => {
      let response = this.coverageAssessmentDetailData?.coverageAssessmentDetail?.find((el: any) => el?.coverageName == "General Liability" || el?.coverageName == "Workers Compensation");
      if (response?.coverageName == "General Liability") {
        this.assessmentGLRating = response?.ratingBasis;
      } else if (response?.coverageName == "Workers Compensation") {
        this.assessmentWCRating = response?.workercompRating;
      }
    })
  }

  saveWBA(action: string) {
    if (action === 'next') {
      if (this.marketingReviewStatus == "Completed" && this.sdRsmStatus == "Approved") {
        this.nextTab.emit(this.tabName);
      }
      else if (this.marketingReviewStatus == "Completed" || this.marketingReviewStatus == "Incomplete") {
        this.nextTab.emit(this.PrevtabName);
      }
      else {
        this.nextTab.emit(this.tabName);
      }
    }
  }

  ValidatePolicyInEpic() {
    this._addWBAService.validateEpicInPolicy(this.activeWbaId).subscribe(res => {
      this.validatePolicyResponse = res;
      this._dataCacheService.compressAndCacheData('wba_validatePolicyResponse', this.validatePolicyResponse);
      res?.message ? this.validationMessage = true : this.validationMessage = false;
    });
  }

  openValidatePolicyModal() {
    const modalRef = this.modalService.open(ValidatePolicyComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });
    modalRef.closed.subscribe(resp => {
    })
    modalRef.componentInstance.epicPolicyResponse = this.validatePolicyResponse;
    modalRef.componentInstance.transType = 'New Business';
    modalRef.componentInstance.passEntry.subscribe((res: any) => {
      this.checkValidationPopup = false;
      if (res == 'Create Policy') {
        this.CreatePolicyInEPIC();
      } else {
        this.getWhiteBoardDetails(this.activeWbaId);
      }
    })
  }

  getWBAFileById() {
    let requestBody = {
      wbaId: this.activeWbaId,
      lineId: this.BusinessLineId
    }
    this.wbaService.getWBAFileById(requestBody).subscribe(res => {
      this.wbaFileResponse = res.wbaFiles;
      this._dataCacheService.compressAndCacheData('wba_wbaFileResponse', this.wbaFileResponse);
    })
  }

  //to get wba email template by wbaId
  getWbaEmailTemplateByWbaId(wbaId: any) {
    this.wbaService.getWbaEmailTemplateByWbaId(wbaId).subscribe(resp => {
      if (resp && resp.wbaSubmissionTemplateList.length > 0) {
        this._dataCacheService.compressAndCacheData('wba_wbaEmailSubmission', resp.wbaSubmissionTemplateList[0]);
      }
    })
  }
}
