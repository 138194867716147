import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';

@Component({
  selector: 'app-caffeine-email-detail',
  templateUrl: './caffeine-email-detail.component.html',
  styleUrls: ['./caffeine-email-detail.component.css']
})
export class CaffeineEmailDetailComponent implements OnInit {
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  @Input() fromEmailList: any;
  massageId: any;
  acmeEmailDetail: any;
  emailBody: any;
  errorMessage: any;
  showError: boolean = false;
  errorList: any[] = [];
  attachment: any[] = [];
  attachmentUrl: any[] = [];
  uniqueId: any;
  constructor(
    public _activeModal: NgbActiveModal,
    private _wbaService: WhiteBoardActivityService,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();

  }

  ngOnInit(): void {
    this.uniqueId = this.fromEmailList.itemId;
    this.getAcmeEmailDetail()
  }

  //to show acme Product  detail
  getAcmeEmailDetail() {
    this._wbaService.getCaffeineEmailDetail(this.uniqueId).subscribe(
      (resp: any) => {
        var data = JSON.parse(JSON.stringify(resp));
        if (data) {
          this.acmeEmailDetail = data;
          this.emailBody = this.acmeEmailDetail.body;
        }
      },
      (err: any) => {
        this.showError = true;
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  };

  //cancel method
  cancel() {
    this._activeModal.close();
  }
}
