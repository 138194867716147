<div class="mid-con">

  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <!-- Additional Exposures section starts -->
          <div *ngIf="this.businessLineIdfromApp!=1 && this.businessLineIdfromApp!=5"
            class="col-12 col-sm-12 col-md-12 mb-2">
            <div class="card">
              <div class="card-header cl-hdr">
                <h5>Additional Exposures</h5>
              </div>
              <div class="card-body">
                <div class="card-text row addw">
                  <div class="row frmrow add-wba-form">

                    <div *ngFor="let exposure of masterExtraExposureList; let index=index;" class="col-4  mb-2">
                      <div class="form-check">
                        <input class="form-check-input" (change)="handleCheckbox(exposure.id, 'wbaExtraExposureDetail')"
                          [checked]="isChecked(exposure.id, 'wbaExtraExposureDetail', 'extraExposureId')"
                          [value]="exposure.id" type="checkbox" id={{exposure.id}}{{exposure.displayName}} />
                        <label class="form-check-label m-t-2" for={{exposure.id}}{{exposure.displayName}}>
                          {{exposure.displayName}}
                        </label>
                      </div>
                    </div>

                    <div *ngIf="otherExposureRecord[0].id" class="col-12 mb-2 mt-0 flex-div flex-basis cov-con">
                      <div class="form-check col-2 mb-2">
                        <input class="form-check-input" type="checkbox"
                          [checked]="isChecked(otherExposureRecord[0].id, 'wbaExtraExposureDetail', 'extraExposureId')"
                          (change)="handleCheckbox(otherExposureRecord[0].id, 'wbaExtraExposureDetail')"
                          [value]="otherExposureRecord[0].id"
                          id={{otherExposureRecord[0].id}}{{otherExposureRecord[0].displayName}} />
                        <label class="form-check-label m-t-2"
                          for={{otherExposureRecord[0].id}}{{otherExposureRecord[0].displayName}}>
                          {{otherExposureRecord[0].displayName}}
                        </label>
                      </div>
                      <div class="form-check col-9 mb-2">
                        <input [(ngModel)]="OtherExposureName"
                          [disabled]="!isChecked(otherExposureRecord[0].id, 'wbaExtraExposureDetail', 'extraExposureId')"
                          (change)="handleOtherInput(otherExposureRecord[0].id, 'wbaExtraExposureDetail', OtherExposureName)"
                          class="form-control py-0" value="" type="text" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Additional Exposures section ends -->

          <!--Special Circumstances section starts -->
          <div class="col-12 col-sm-12 col-md-12 mt-3 mb-2">
            <div class="card">
              <div class="card-header cl-hdr">
                <h5>Special Circumstances</h5>
              </div>
              <div class="card-body">
                <div class="card-text row addw">
                  <div class="row frmrow add-wba-form ">
                    <div class="col-12 col-sm-12 col-md-4 servicebox" *ngFor="let grpKeys of keysOfGroupName">
                      <h3 class="pb-2 mb-2">
                        {{grpKeys}}
                      </h3>
                      <div class="mb-2 serviceBlock"
                        *ngFor="let circumstances of groupByGroupName[grpKeys]; let i = index">
                        <div class="flex-div space-between align-items-center">
                          <div class="form-check flex-div align-items-center">
                            <input type="checkbox" (change)="handleSpecialCircumstancesCheckbox(circumstances)"
                              [checked]="circumstances.isChecked" name="{{ circumstances.displayName }}{{ i }}"
                              id="{{ circumstances.displayName }}{{ i }}" class="form-check-input" />
                            <label class="form-check-label m-t-2" for="{{ circumstances.displayName }}{{ i }}">{{
                              circumstances.displayName }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Special Circumstances section ends -->
        </div>
      </div>
    </div>
  </div>
</div>