import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';

@Component({
  selector: 'app-marketing-notes',
  templateUrl: './marketing-notes.component.html',
  styleUrls: ['./marketing-notes.component.css']
})
export class MarketingNotesComponent implements OnInit {
  @Input() currentCarrier: any;
  @Input() marketingSummaryList: any;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  notesForm: any;
  wbaId: any;
  selectedCarrier: any;
  selectedCoverage: any;
  loggedInUserName: any;
  loggedInUserId: any;
  selectedCarrierValue: any;
  coverageAssessmentResponse: any;
  marketingNotesResponse: any;
  clickOnRequestNotes: boolean = false;
  emptyComments: boolean = false;
  selectedCoverageValue: any;
  currentNote: any;
  currentNoteId: any;
  isMaximized = false;
  hideInputBox1: boolean = true;
  hideInputBox2: boolean = false;
  filteredCarrierArray: any;
  filteredCoverageArray: any = [];
  requestBodyArray: any = [];
  allComments: any;
  marketingSummaryId: any;
  currentNoteIdArray: any = [];
  isValidUser: boolean = false;
  userDetails: any;

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private activeRouter: ActivatedRoute,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _wbaService: WhiteBoardActivityService, private ref: ChangeDetectorRef) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {

    // wbaId
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.wbaId = params.wbaId
      });

    // Login userId
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.loggedInUserName = userDetails.user.display_name;
    this.loggedInUserId = userDetails.user.userId;
    this.userInformation();
    // Form Control
    this.getFormControls();

    // set dropdown value
    this.setDropdownValue();

    // Get Marketing Notes
    this.getMarketingNotes();
  }

  getFormControls() {
    this.notesForm = this.formBuilder.group({
      carrier: [''],
      coverage: [''],
      comments: ['']
    })
  }

  setDropdownValue() {
    this.notesForm.get('carrier')?.setValue(this.currentCarrier?.carrierId ? this.currentCarrier?.carrierId : this.currentCarrier?.wholesalerId ? this.currentCarrier?.wholesalerId : 0);
    this.notesForm.get('coverage')?.setValue(this.currentCarrier?.coverageId == undefined ? 0 : this.currentCarrier?.coverageId);

    const seenNames: any = {};
    this.filteredCarrierArray = this.marketingSummaryList.reduce((result: any, item: any) => {
      if (item.carrierName) {
        const name = item.carrierName;
        const id = item.carrierId;
        if (!seenNames[name]) {
          seenNames[name] = true;
          result.push({ carrierName: name, carrierId: id });
        }
      }
      if (item.wholesalerName) {
        const name = item.wholesalerName;
        const id = item.wholesalerId;
        if (!seenNames[name]) {
          seenNames[name] = true;
          result.push({ wholesalerName: name, wholersalerId: id });
        }
      }
      return result;
    }, []);

    this.currentCarrier ? this.filteredCoverageArray.push(this.currentCarrier) : this.filteredCoverageArray = this.marketingSummaryList;
    this.filteredCoverageArray = this.getUniqueCoverages(this.filteredCoverageArray);
  }

  get f() {
    return this.notesForm.controls;
  }

  // modal close
  cancel() {
    if (!this.notesForm.dirty) {
      this.activeModal.close();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  // Popup close on yes/no
  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  // Popup close on ok
  warningBoxCancel() {
    this._confirmBox.isVisible = false;
    this.emptyComments = false;
  }

  // Dropdown Change Events
  getCarrierValue(item: any) {
    this.selectedCarrierValue = item;

    if (item == 0) {
      this.filteredCoverageArray = this.getUniqueCoverages(this.marketingSummaryList);
      this.notesForm.get('coverage').setValue(item);
    } else {
      this.filteredCoverageArray = this.marketingSummaryList.filter((res: any) => res?.carrierId == item || res?.wholesalerId == item);
      this.filteredCoverageArray = this.getUniqueCoverages(this.filteredCoverageArray);
      const selectedCoverage = this.filteredCoverageArray.find((res: any) => res?.coverageId);
      // this.ref.detectChanges();
      this.notesForm.get('coverage').setValue(0);
    }

    this.getMarketingNotes();
  }

  getUniqueCoverages(inputArray: any[]) {
    const seenCoverageNames: any = {};
    return inputArray.filter(item => {
      if (item.coverageName) {
        if (!seenCoverageNames[item.coverageName]) {
          seenCoverageNames[item.coverageName] = true;
          return true;
        }
      }
      return false;
    });
  }


  getCoverageValue(item: any) {
    this.selectedCoverageValue = item;
    if (item == 0) {
      if (this.notesForm.get('carrier').value != 0) this.filteredCoverageArray = this.marketingSummaryList.filter((res: any) => res?.carrierId == this.notesForm.get('carrier').value || res?.wholesalerId == this.notesForm.get('carrier').value);
      this.filteredCoverageArray = this.getUniqueCoverages(this.filteredCoverageArray);
    }
    this.getMarketingNotes();
  }

  // Removing extra space
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  // Get Marketing Notes
  getMarketingNotes() {
    let requestBody = {
      wbaId: this.wbaId,
      carrierId: this.notesForm.get('carrier')?.value,
      coverageId: this.notesForm.get('coverage')?.value,
      userId: this.loggedInUserId
    }

    this._wbaService.getMarketingNotes(requestBody).subscribe(res => {
      this.notesForm.markAsPristine();
      this.clickOnRequestNotes = true;
      this.marketingNotesResponse = res;
      this.hideInputBox(res);
      this.createFormControl(res)
    })
  }

  hideInputBox(res: any) {
    let data = this.notesForm.value;
    this.hideInputBox1 = res.carriercoveragenotes.length <= 1 ? true : false;
    this.hideInputBox2 = res.carriercoveragenotes.length > 1 ? true : false;
    res.carriercoveragenotes.forEach((el: any) => {
      if (el.coverageNote.length > 1) {
        this.hideInputBox2 = true;
        this.hideInputBox1 = false;
      }
    })
  }

  createFormControl(res: any) {
    let data = this.notesForm.value;
    if (res != null && this.hideInputBox2) {
      let formControl: UntypedFormControl;
      res.carriercoveragenotes.forEach((elem: any) => {
        elem.coverageNote.forEach((el: any) => {
          const controlName = "textArea" + el.marketingSummaryId + el.coverageId;
          if (this.notesForm.contains(controlName)) {
            this.notesForm.removeControl(controlName);
          }
          formControl = new UntypedFormControl();
          this.notesForm.addControl(controlName, formControl)
        })
      })
    }
  }

  // Coverage Assessment
  getCoverageAssessmentDetails() {
    this._wbaService.getCoverageAssessmentDetails(parseInt(this.wbaId)).subscribe((response: any) => {
      this.coverageAssessmentResponse = response.coverageAssessmentDetail;
      this.selectedCoverage = this.coverageAssessmentResponse.find((res: any) => res.coverageId);
      this.notesForm.get('coverage')?.setValue(this.selectedCoverage.coverageId);
    })
  }

  // Add Notes
  addMarketingNotes() {
    let data = this.notesForm.value;
    if (this.hideInputBox2) {
      if (!this.requestBodyArray.length) {
        this.emptyComments = true;
        this.showWarningBox();
      } else {
        this.requestBodyArray.forEach((res: any) => {
          let requestBody = res;
          this._wbaService.createMarketingNotes(requestBody).subscribe(res => {
            this.notesForm.get('comments').setValue('');
            this.currentNoteId = 0;
            this.currentNoteIdArray = [];
            this.requestBodyArray = [];
            this.getMarketingNotes();
          })
        })
      }
    } else {
      if (!data.comments) {
        this.emptyComments = true;
        this.showWarningBox();
      } else {
        let selectedCarrier: any;
        this.marketingNotesResponse.carriercoveragenotes.forEach((resp: any) => {
          selectedCarrier = resp?.coverageNote.find((res: any) => res.marketingSummaryId);
        })
        let requestBody = {
          id: this.currentNoteId ? this.currentNoteId : 0,
          wbaId: this.wbaId,
          coverageId: selectedCarrier.coverageId,
          carrierId: selectedCarrier.carrierId ? selectedCarrier.carrierId : 0,
          wholesalerId: selectedCarrier.wholeSalerId ? selectedCarrier.wholeSalerId : 0,
          marketingSummaryId: selectedCarrier.marketingSummaryId,
          note: data.comments
        }
        this._wbaService.createMarketingNotes(requestBody).subscribe(res => {
          this.notesForm.get('comments').setValue('');
          this.currentNoteId = 0;
          this.currentNoteIdArray = [];
          this.requestBodyArray = [];
          this.getMarketingNotes();
        })
      }
    }
  }

  payload(coverageNote: any, comments: any) {
    this.allComments = comments;
    const trimmedContent = comments.trim();
    if (comments && trimmedContent) {
      const existingIndex = this.requestBodyArray.findIndex((item: any) =>
        item.marketingSummaryId == coverageNote?.marketingSummaryId && item.coverageId == coverageNote?.coverageId);
      let currentNote: any;
      let currentNoteId: any;
      coverageNote?.note.forEach((res: any) => {
        currentNote = this.currentNoteIdArray.find((el: any) => el?.id == res?.id);
        if (currentNote) currentNoteId = currentNote?.id;
      })
      if (existingIndex === -1) {
        const requestBody = {
          id: currentNoteId ? currentNoteId : 0,
          wbaId: this.wbaId,
          coverageId: coverageNote?.coverageId,
          carrierId: coverageNote?.carrierId ? coverageNote?.carrierId : 0,
          wholesalerId: coverageNote?.wholeSalerId ? coverageNote?.wholeSalerId : 0,
          marketingSummaryId: coverageNote?.marketingSummaryId,
          note: comments
        };

        this.requestBodyArray.push(requestBody);
      } else {
        this.requestBodyArray[existingIndex].note = comments;
      }
    } else {
      const existingIndex = this.requestBodyArray.findIndex((item: any) =>
        item.marketingSummaryId == coverageNote?.marketingSummaryId && item.coverageId == coverageNote?.coverageId);
      this.requestBodyArray.splice(existingIndex, 1);
    }
  }

  // Edit Notes
  editNotes(note: any, coverageNote: any) {
    this.currentNoteId = note?.id;

    let index = this.currentNoteIdArray.findIndex((item: any) => item?.marketingSummaryId == coverageNote?.marketingSummaryId);
    if (index == -1) {
      this.currentNoteIdArray.push({
        id: note?.id,
        marketingSummaryId: coverageNote?.marketingSummaryId,
      });
    } else {
      this.currentNoteIdArray[index].id = note?.id;
    }

    if (this.hideInputBox2) {
      const controlName = "textArea" + coverageNote.marketingSummaryId + coverageNote.coverageId;
      this.notesForm.get(controlName).setValue(note?.note);
    } else if (this.hideInputBox1) {
      this.notesForm.get('comments').setValue(note?.note);
    }
  }

  // Add Notes on all Carriers and Coverages
  addANotesOnAllCarriers(carrierId: any, coverageId: any) {
    let data = this.notesForm.value;
    if (!data.comments2) {
      this.emptyComments = true;
      this.showWarningBox();
    } else {
      let requestBody = {
        id: this.currentNoteId ? this.currentNoteId : 0,
        wbaId: this.wbaId,
        coverageId: coverageId,
        carrierId: carrierId,
        note: data.comments2
      }

      this._wbaService.createMarketingNotes(requestBody).subscribe(res => {
        this.notesForm.get('comments2').setValue('');
        this.currentNoteId = 0;
        this.getMarketingNotes();
      })
    }
  }

  showWarningBox() {
    let message = `Please Enter Notes`
    this._confirmBox.message = message;
    this._confirmBox.isVisible = true;
  }

  toggleMaximize() {
    this.isMaximized = !this.isMaximized;
  }

  userInformation(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

    if (this.userDetails) {
      let roleExist = this.userDetails.user.roles.find((role: any) => role.roleName != 'Client Executive');
      if (roleExist) {
        this.isValidUser = true;
      }
      else {
        this.isValidUser = false;
      }
    }
  }
}
