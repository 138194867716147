import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteBoardRoutingModule } from './white-board-routing.module';
import { WhiteBoardActivityDetailComponent } from './components/view-whiteboardactivity/view-whiteboardactivity.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpInterceptors } from 'src/app/shared/interceptors/http.interceptor';
import { WhiteBoardActivityService } from './services/white-board-activity.service';
import { WhiteboardListComponent } from './components/whiteboard-list/whiteboard-list.component';
import { AddWhiteboardComponent } from './components/add-whiteboard/add-whiteboard.component';
import { EditWBActivityComponent } from './components/edit-wbactivity/edit-wbactivity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
// import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
// import { SharedModule } from 'primeng/api';
// import { DeleteWhiteboardActivityComponent } from './components/delete-whiteboardActivity/delete-whiteboardactivity.component';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ApplicationComponent } from './components/add-whiteboard/application/application.component';
import { PolicydetailComponent } from './components/add-whiteboard/policydetail/policydetail.component';
import { CoverageChecklistComponent } from './components/add-whiteboard/coverage-checklist/coverage-checklist.component'; './components/add-whiteboard/coverage-checklist/coverage-checklist.component';
import { LookupProspectComponent } from './components/add-whiteboard/lookup-prospect/lookup-prospect.component';
import { AdditionalInformationComponent } from './components/add-whiteboard/additional-information/additional-information.component';
import { SubmissionComponent } from './components/add-whiteboard/submission/submission.component';
import { LookupUsersComponent } from './components/add-whiteboard/lookup-users/lookup-users.component';
import { LookupLeadGeneratorComponent } from './components/add-whiteboard/lookup-lead-generator/lookup-lead-generator.component';
import { LookupCarrierComponent } from './components/add-whiteboard/lookup-carrier/lookup-carrier.component';
import { LookupManagerComponent } from './components/add-whiteboard/lookup-manager/lookup-manager.component';
// import { SharedModule } from 'src/app/shared/shared.module';
import { AcordComponent } from './components/add-whiteboard/acord/acord.component';
import { RsmApprovalComponent } from './components/add-whiteboard/rsm-approval/rsm-approval.component';
import { EFileCabinateComponent } from './components/e-file-cabinate/e-file-cabinate.component';
import { WbaNoteComponent } from './components/add-whiteboard/wba-note/wba-note.component';
import { WbaEditNoteComponent } from './components/add-whiteboard/wba-edit-note/wba-edit-note.component';
import { CoverageAssessmentComponent } from './components/add-whiteboard/coverage-assessment/coverage-assessment.component';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { NotificationModule } from '@progress/kendo-angular-notification';
import { AuditReportComponent } from './components/add-whiteboard/audit-report/audit-report.component';
import { SubmissionTemplateComponent } from './components/add-whiteboard/submissionTemplate/submissionTemplate.component';
import { LookupMarketingmanagerComponent } from './components/add-whiteboard/lookup-marketingmanager/lookup-marketingmanager.component';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import { QuoteComponent } from './components/add-whiteboard/quote/quote.component';
import { ProposalComponent } from './components/add-whiteboard/proposal/proposal.component';
import { SubmitToMarketingComponent } from './components/add-whiteboard/submit-to-marketing/submit-to-marketing.component';
import { QuoteDetailsComponent } from './components/add-whiteboard/quoteDetails/quoteDetails.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { MultiSelectModule } from 'primeng/multiselect';
import { LostDetailComponent } from './components/add-whiteboard/lost-detail/lost-detail.component';
import { WbaBoundComponent } from './components/add-whiteboard/wba-bound/wba-bound.component';
import { WbaBindRequestComponent } from './components/add-whiteboard/wba-bind-request/wba-bind-request.component';
import { BindingComponent } from './components/add-whiteboard/binding/binding.component';
import { EpicpolicydDetailsComponent } from './components/add-whiteboard/epicpolicyd-details/epicpolicyd-details.component';
import { BorComponent } from './components/add-whiteboard/bor/bor.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AccountManagementServicesComponent } from './components/add-whiteboard/account-management-services/account-management-services.component';
import { ExecutiveSummaryComponent } from './components/executive-summary/executive-summary.component';
import { RenewComponent } from './components/renew/renew.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccountExecutiveComponent } from './components/add-whiteboard/account-executive/account-executive.component';
import { AccountManagerComponent } from './components/add-whiteboard/account-manager/account-manager.component';
import { LookupSubmissionCarrierComponent } from './components/add-whiteboard/lookup-submissionCarrier/lookup-submissionCarrier.component';
import { CaffeineEmailDetailComponent } from './components/add-whiteboard/caffeine-email-detail/caffeine-email-detail.component';
import { ProposalDetailsComponent } from './components/add-whiteboard/proposalDetails/proposalDetails.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SafeHtmlPipe } from 'src/app/features/white-board/components/add-whiteboard/pipes/safeHtml.pipe';
import { EditorModule as KendoEditorModule } from "@progress/kendo-angular-editor";
import { UploadProspectLogoComponent } from './components/add-whiteboard/upload-prospect-logo/upload-prospect-logo.component';
import { MarketingNotesComponent } from './components/add-whiteboard/marketing-notes/marketing-notes.component';
import { ValidatePolicyComponent } from './components/add-whiteboard/validate-policy/validate-policy.component';
import { CoverageAssessmentNBComponent } from './components/add-whiteboard/coverage-assessment-nb/coverage-assessment-nb.component';
import { SendCarrierConfirmationComponent } from './components/add-whiteboard/sendCarrierConfirmation/sendCarrierConfirmation.component';
import { SubmissionCarrierComponent } from './components/add-whiteboard/submissionCarrier/submissionCarrier.component';
import { CloneBorComponent } from './components/add-whiteboard/clone-bor/clone-bor.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TruncatePipe } from './components/add-whiteboard/pipes/truncate.pipe';
import { ProposalSummaryNotesComponent } from './components/add-whiteboard/proposal-summary-notes/proposal-summary-notes.component';
import {NgxMaskDirective, NgxMaskPipe} from "ngx-mask";
import { SubmissionViewComponent } from './components/add-whiteboard/submission-view/submission-view.component';


@NgModule({
    declarations: [
        WhiteboardListComponent,
        AddWhiteboardComponent,
        EditWBActivityComponent,
        // DeleteWhiteboardActivityComponent,
        WhiteBoardActivityDetailComponent,
        ApplicationComponent,
        PolicydetailComponent,
        LookupProspectComponent,
        CoverageChecklistComponent,
        AdditionalInformationComponent,
        SubmissionComponent,
        LookupUsersComponent,
        LookupLeadGeneratorComponent,
        LookupCarrierComponent,
        LookupManagerComponent,
        AcordComponent,
        RsmApprovalComponent,
        SubmitToMarketingComponent,
        EFileCabinateComponent,
        WbaNoteComponent,
        WbaEditNoteComponent,
        CoverageAssessmentComponent,
        AuditReportComponent,
        SubmissionTemplateComponent,
        LookupMarketingmanagerComponent,
        QuoteComponent,
        ProposalComponent,
        LostDetailComponent,
        QuoteDetailsComponent,
        WbaBoundComponent,
        WbaBindRequestComponent,
        BindingComponent,
        EpicpolicydDetailsComponent,
        BorComponent,
        AccountManagementServicesComponent,
        ExecutiveSummaryComponent,
        RenewComponent,
        AccountExecutiveComponent,
        AccountManagerComponent,
        LookupSubmissionCarrierComponent,
        CaffeineEmailDetailComponent,
        ProposalDetailsComponent,
        SafeHtmlPipe,
        UploadProspectLogoComponent,
        MarketingNotesComponent,
        ValidatePolicyComponent,
        CoverageAssessmentNBComponent,
        SendCarrierConfirmationComponent,
        SubmissionCarrierComponent,
        CloneBorComponent,
        TruncatePipe,
        ProposalSummaryNotesComponent,
        SubmissionViewComponent
    ],
    imports: [
        CommonModule,
        WhiteBoardRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        CalendarModule,
        AutocompleteLibModule,
        NgSelectModule,
        // TypeaheadModule,
        DialogModule,
        ButtonModule,
        SharedModule,
        PaginatorModule,
        TooltipModule,
        AutoCompleteModule,
        DialogsModule,
        NotificationModule,
        EditorModule,
        InputMaskModule,
        MultiSelectModule,
        DropDownsModule,
        LabelModule,
        ScrollPanelModule,
        PdfViewerModule,
        OverlayPanelModule,
        KendoEditorModule,
        ClipboardModule,
      NgxMaskDirective,
      NgxMaskPipe,
    ],
    providers: [
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: HttpInterceptors,
        //   multi: true
        // },
        WhiteBoardActivityService
    ]
})
export class WhiteBoardModule { }
