import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { EmailTemplateMessage } from 'src/app/features/white-board/error-message/emailTemplate-mesages'
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { LookupSubmissionCarrierComponent } from 'src/app/features/white-board/components/add-whiteboard/lookup-submissionCarrier/lookup-submissionCarrier.component';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { ActivatedRoute } from '@angular/router';
import { SendCarrierConfirmationComponent } from '../sendCarrierConfirmation/sendCarrierConfirmation.component';
import { AddContactComponent } from 'src/app/features/contact/components/add-contact/add-contact.component';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';

interface carrierName {
  name: string,
  code: string
}
@Component({
  selector: 'app-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.css']
})
export class SubmissionComponent implements OnInit {
  @Input() wbaOutcome: any;
  @Input() wbaAcordProgress: any;
  @Input() wbaReviewStatus: any;
  @Input() isAssesmentParamsHasMandatoryValues: any;
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  @Output() handleSubmIconStatus: any = new EventEmitter<any>();
  @Input() AcordPermissionResponse: any;
  @Output() emitSendToSelectedFlag: any = new EventEmitter<any>();

  _navigationPath: NavigationPath;
  _confirmBox: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  wbaCarriers: any = [];
  wbaCarriersFromAPi: any = [];
  sendMailToCarrierList: any = [];
  _EmailTemplateMessage: EmailTemplateMessage;
  submitted: boolean = false;
  isDisable: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  wbaId: number = 0;
  isAllContactSelected: boolean = true;
  isCarrierSelected: boolean = true;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterCarrierList: any[] = [];
  lookupData: any;
  isPageDirty: boolean = false;
  activeWbaId: any;
  wbaProgressChange: any;
  prospectId: any;
  epicUniquePolicyId: any;
  wbaTransType: any = '';
  templateId: any;
  data: any;
  wbaDetails: any;

  // CName: any[] = [];

  userIdList: any[] = [];
  userList: any[] = [];
  selectedUserList: any[] = [];
  selectedContactList: any[] = [];
  finalCarrierAndUserJson: any;

  selectedTier1ContactId: any[] = [];
  selectedTier2ContactId: any[] = [];
  selectedTier3ContactId: any[] = [];
  selectedTier4ContactId: any[] = [];
  selectedTierOtherContactId: any[] = [];
  loginUserId: string | null = '';
  userDetails: any;
  selectedContacts: any[] = [];
  submissionForm: any;
  // isMasterTemplate: boolean = true;
  isSubmissionsButtonsDisable: boolean = true;
  UserIdLogged: any;
  selectedListId: any;
  selectedDefaultList: any;
  isInvalidUser: boolean = false;
  isSubmissionValidUser: any;
  // isAddUserAndContactPermission: any;
  wbaEmailSubmission: any
  tabRefresh: any;
  takingDataFromApi: any;
  issubmissionRequest: boolean = false;

  constructor(private _wbaService: WhiteBoardActivityService,
    private modalService: NgbModal,
    private _notifierService: WBANotifierService,
    private _carriersService: CarriersService,
    private activeRouter: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private _dataCacheService: DatacacheService
  ) {
    this._confirmBox = new ConfirmBox()
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._EmailTemplateMessage = new EmailTemplateMessage();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    console.log("msg", this.isAssesmentParamsHasMandatoryValues)
    // this.getWhiteBoardDetails(this.activeWbaId)
    // this.getWbaCarrierListById(this.activeWbaId);
    // this.getWbaEmailTemplateByWbaId(this.activeWbaId);
    // this.getUserList()
    // Load user information asynchronously;
    this.submissionForm = this.fb.group({
      selectedUserLabel: ['']
    })
    this.intializeData()
    this.userInformation();

    window.scroll(0, 0);
  }
  createDynamicFormControls() {
    if (this.wbaCarriers?.length) {
      this.wbaCarriers?.forEach((res: any) => {
        res.wbaCarrierListByTierGroup?.forEach((el: any) => {
          const controlName = this.getFormControlName(el.id);
          let formControl: UntypedFormControl = new UntypedFormControl();
          let val = this.submissionForm?.controls[controlName]?.value;
          if (this.submissionForm.contains(controlName)) {
            this.submissionForm.removeControl(controlName);
          }
          this.submissionForm.addControl(controlName, formControl)
          if (val) this.submissionForm.controls[controlName].patchValue(val);
          this.submissionForm.controls[controlName]?.updateValueAndValidity()
        })
      })
    }
  }


  getFormControlName(id: number) {
    return ['selectedPrefered', id, 'label'].join('_');
  }

  getWbaCarrierListByIdApi(wbaid: any) {
    this._wbaService.getWbaCarrierListById(this.activeWbaId).subscribe(resp => {
      this.wbaCarriersFromAPi = resp.wbaCarrierList;
      this._dataCacheService.compressAndCacheData('wba_wbaCarrierList', this.wbaCarriersFromAPi);
      this._dataCacheService.compressAndCacheData('userSubmissionInfo', resp.userSubmissionInfo);
      if (resp.userSubmissionInfo) this.handleSubmIconStatus.emit({ label: 'Submissions', value: false });
      this.getWbaCarrierListById(this.activeWbaId)
    })
  }
  //to get wba carriers list
  getWbaCarrierListById(wbaId: any) {
    let sequence = '0';
    // this._wbaService.getWbaCarrierListById(wbaId).subscribe(resp => {
    //   this.wbaCarriersFromAPi = resp.wbaCarrierList;
    this.wbaCarriers = this.wbaCarriersFromAPi.reduce((r: { tierName: any; wbaCarrierListByTierGroup: any; sequence: any; }[], { tierName }: any) => {
      if (!r.some(o => o.tierName == tierName)) {
        switch (tierName) {
          case 'Preferred-1':
            sequence = '1';
            break;

          case 'Preferred-2':
            sequence = '2';
            break;

          case 'Preferred-3':
            sequence = '3';
            break;

          case 'Preferred-4':
            sequence = '4';
            break;

          case 'Others':
            sequence = '5'
            break;

          default:
            return '6';
        }
        r.push({ tierName, sequence: sequence, wbaCarrierListByTierGroup: this.wbaCarriersFromAPi.filter((v: { tierName: any; }) => v.tierName == tierName) });
      }
      return r;
    }, []);

    // this.wbaCarriers.sort((a: { tierName: string; }, b: { tierName: any; }) => a.tierName.localeCompare(b.tierName));
    this.wbaCarriers.sort((a: { sequence: string; }, b: { sequence: any; }) => a.sequence.localeCompare(b.sequence));
    this.createDynamicFormControls();
    // setTimeout(() => {
    this.wbaCarriers.forEach((data: any) => {
      data.wbaCarrierListByTierGroup.forEach((groupData: any, carrierIndex: any) => {
        groupData.contact = groupData.contact.map((obj: any) => ({
          ...obj, "isCarrierEmail": false, "isChecked": groupData.isMailSent
        }));

        groupData.contact.sort((a: { contactNameWithInfo: string; }, b: { contactNameWithInfo: any; }) => a.contactNameWithInfo.localeCompare(b.contactNameWithInfo));

        if (groupData.submissionEmail) {
          groupData.contact.unshift({
            "id": groupData.id,
            "contactNameWithInfo": groupData.submissionEmail,
            "prospectId": 0,
            "carrierId": groupData.id,
            "isCarrierEmail": true,
            "isMailSent": groupData.sentStatus,
            "isChecked": groupData.sentStatus,
            "isCarrierMailSend": groupData.sentStatus
          })
        }

        // if (groupData.tierName == "Preferred-1") {
        let contactArray: any[] = []
        // let contactIndex = 0
        groupData.contact.forEach((contactData: any, index: any) => {
          if (contactData.isMailSent == true) {
            contactData.isChecked = true;
            groupData.isDisplaySentButton = true
            groupData.isChecked = true
            contactArray.push(contactData.id)
            this.handleSubmIconStatus.emit({ label: 'Submissions', value: false });
          }
        })
        if (contactArray.length > 0) {
          this.selectedTier1ContactId[carrierIndex] = contactArray;
          this.submissionForm.controls[this.getFormControlName(groupData.id)].patchValue(contactArray);
        }

      })
    })
    // })
  }

  intializeData() {
    this.tabRefresh = this._dataCacheService.retrieveData('tabRefresh');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    this.wbaCarriersFromAPi = this._dataCacheService.retrieveData('wba_wbaCarrierList');
    this.wbaEmailSubmission = this._dataCacheService.retrieveData('wba_wbaEmailSubmission');
    this.userList = this._dataCacheService.retrieveData('wba_userListLookup');
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == "Submissions");
    if (!this.wbaDetails || this.wbaCarriersFromAPi.length == 0 || !this.wbaEmailSubmission || this.userList.length == 0) this.getAllMasters();
    else this.mapSubmission();
  }

  getAllMasters() {
    let data = {
      "lookUpType": 'WBASubmisiionUser',
      "rowCount": 10
    }
    var wbaActivityList = this._wbaService.getWBAById(this.activeWbaId);
    var carrierList = this._wbaService.getWbaCarrierListById(this.activeWbaId);
    var emailTemplateList = this._wbaService.getWbaEmailTemplateByWbaId(this.activeWbaId);
    var wbalookup = this._wbaService.getWhiteBoardActivityLookUp(data)
    forkJoin([wbaActivityList, carrierList, emailTemplateList, wbalookup
    ]).subscribe((resp) => {
      if (resp) {
        this.wbaDetails = resp[0].whiteBoardActivityInfo;
        this.wbaCarriersFromAPi = resp[1].wbaCarrierList;
        console.log("carrierList", this.wbaCarriersFromAPi)
        this.wbaEmailSubmission = resp[2].wbaSubmissionTemplateList[0];
        this.userList = resp[3].userList;
        this.userList = this.userList.map((obj: any) => ({
          ...obj, "isMailSent": false
        }));
        this._dataCacheService.compressAndCacheData('userSubmissionInfo', resp[1].userSubmissionInfo);
        // this.tabRefresh[10].refresh = false;
        // this._dataCacheService.compressAndCacheData('tabRefresh', this.tabRefresh);
        this.mapSubmission();
        this.setDataInCache();
      }
    })
  }

  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    this._dataCacheService.compressAndCacheData('wba_wbaCarrierList', this.wbaCarriersFromAPi);
    this._dataCacheService.compressAndCacheData('wba_wbaEmailSubmission', this.wbaEmailSubmission);
    this._dataCacheService.compressAndCacheData('wba_userListLookup', this.userList);
  }

  mapSubmission() {
    if (this.wbaDetails) {
      console.log("details", this.wbaDetails)
      this.checkAccessPermissionByWbaId();
      this.epicUniquePolicyId = this.wbaDetails?.epicUniquePolicyId;
      this.wbaTransType = this.wbaDetails?.transType;
      this.wbaProgressChange = this.wbaDetails?.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
      this.setUpdatedProgressId.emit(this.wbaDetails?.progressId);
      this.prospectId = this.wbaDetails?.prospectId
    }
    this.getWbaCarrierListById(this.activeWbaId)
    if (this.wbaEmailSubmission) {
      let data = this.wbaEmailSubmission;
      this.templateId = this.wbaEmailSubmission.id;
      if (data.isMasterTemplate) {
        this.isSubmissionsButtonsDisable = true;
        this._notifierService._isSubmissionsButtonsDisable.next(true);
      }
      else {
        this.isSubmissionsButtonsDisable = false;
        this._notifierService._isSubmissionsButtonsDisable.next(false);
      }
    }
    this.getUserList();
  }

  //to get wba email template by wbaId
  getWbaEmailTemplateByWbaId(wbaId: any) {
    this._wbaService.getWbaEmailTemplateByWbaId(wbaId).subscribe(resp => {
      if (resp && resp.wbaSubmissionTemplateList.length > 0) {
        let data = resp.wbaSubmissionTemplateList[0];
        this._dataCacheService.compressAndCacheData('wba_wbaEmailSubmission', resp.wbaSubmissionTemplateList[0]);
        this.templateId = resp.wbaSubmissionTemplateList[0].id;
        if (data.isMasterTemplate) {
          this.isSubmissionsButtonsDisable = true;
          this._notifierService._isSubmissionsButtonsDisable.next(true);
        }
        else {
          this.isSubmissionsButtonsDisable = false;
          this._notifierService._isSubmissionsButtonsDisable.next(false);
        }
      }
    })
  }

  //create userSubscription request on selection/removal
  createSendMailToCarrierList(carrier: any, operation: string) {

    let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == carrier.id);
    if (operation === "add") {
      if (carrierObject) {
        carrierObject.isChecked = true;
      }
      else {
        this.data = {
          "wbaId": carrier.wbaId || this.activeWbaId,
          "submissionMail": carrier.submissionEmail,
          "carrierId": carrier.id,
          "sentStatus": carrier.isCarrierMailSend ? true : false,
          "mailTemplateId": this.templateId,
          "isChecked": true,
          "CarrierName": carrier.carrierName,
          "isCarrierMailSend": carrier.isCarrierMailSend ? true : false,
          "contactList": null,
        }

        carrier.contact.forEach((contactData: any, index: any) => {

          if (contactData.isChecked == true) {
            if (carrier.id == contactData.id && contactData.isCarrierEmail) {
              // send data list             
              this.data.isCarrierMailSend = true;
              this.data.sentStatus = true;

              // get data list
              carrier.isChecked = true;
              carrier.isCarrierMailSend = true;
              carrier.sentStatus = true;

            }
            else {

              let contactObject = {
                "id": contactData.id,
                "email": contactData.office1Email,
                "contactName": contactData.firstName ? contactData.firstName + ' ' + contactData.lastName : contactData.contactNameWithInfo,
                "isCarrierEmail": contactData.isCarrierEmail
              }

              if (this.data.contactList == null) {
                this.data.contactList = []
              }

              this.data.contactList.push(contactObject);
            }

          }
        })

        this.sendMailToCarrierList.push(this.data)
      }

    } else {
      if (carrierObject) {
        // send list
        carrierObject.isChecked = false;
        carrierObject.isCarrierMailSend = false;
        carrierObject.sentStatus = false;
        this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: { carrierId: any; }) => x.carrierId != carrier.id);
      }
    }
  }

  //this function is used to remove  value from array
  removeValueFromJson(array: any, _key: string, value: string) {
    let _array = array.filter(function (_ele: any) {
      return _ele[_key] != value;
    })
    return _array;
  }

  onParentCarrierClick(eventType: any, tierList: any) {
    this.isPageDirty = true;
    if (eventType.checked) {
      tierList.isChecked = true;


      tierList.wbaCarrierListByTierGroup.forEach((carrierContact: any) => {
        this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: { carrierId: any; }) => x.carrierId != carrierContact.id);

        carrierContact.isChecked = true;
        this.data = {
          "wbaId": carrierContact.wbaId || this.activeWbaId,
          "submissionMail": carrierContact.submissionEmail,
          "carrierId": carrierContact.id,
          "sentStatus": false,
          "mailTemplateId": this.templateId,
          "isChecked": true,
          "CarrierName": carrierContact.carrierName,
          "isCarrierMailSend": carrierContact.isCarrierMailSend ? true : false,
          "contactList": null,
        }

        let contactListData = carrierContact.contact;
        contactListData.forEach((contactData: any, index: any) => {
          if (contactData.isChecked == true && contactData.isMailSent == false) {
            if (carrierContact.id == contactData.id && contactData.isCarrierEmail) {
              // send data list             
              this.data.isCarrierMailSend = true;
              this.data.sentStatus = true;

              // get data list
              carrierContact.isChecked = true;
              carrierContact.isCarrierMailSend = true;
              carrierContact.sentStatus = true;
            }
            else {
              let contactObject = {
                "id": contactData.id,
                "email": contactData.office1Email,
                "contactName": contactData.firstName ? contactData.firstName + ' ' + contactData.lastName : contactData.contactNameWithInfo,
                "isCarrierEmail": contactData.isCarrierEmail
              }

              if (this.data.contactList == null) {
                this.data.contactList = []
              }

              this.data.contactList.push(contactObject);
            }
          }
        })

        if (this.data) {
          this.sendMailToCarrierList.push(this.data);
          this.data = null;
        }

      })





    }
    else {
      tierList.isChecked = false;
      tierList.wbaCarrierListByTierGroup.forEach((carrierContact: any) => {

        // this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: { carrierId: any; }) => x.carrierId != carrierContact.id);
        // unchecked in multiselect dropdown
        // let contactIsCheckedObject = carrierContact.contact.find((x: { isMailSent: boolean; }) => x.isMailSent == true);

        let carrierAllContact = JSON.parse(JSON.stringify(carrierContact.contact));
        carrierAllContact = carrierAllContact.filter((x: { isMailSent: boolean; }) => x.isMailSent == true);

        let isCheckedContact = JSON.parse(JSON.stringify(carrierContact.contact));
        isCheckedContact = isCheckedContact.filter((x: {
          isMailSent: boolean; isChecked: boolean;
        }) => x.isChecked == true && x.isMailSent == false);

        // if (!contactIsCheckedObject) {
        if (carrierAllContact.length == 0) {
          this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: { carrierId: any; }) => x.carrierId != carrierContact.id);

          // remove from get list
          carrierContact.isChecked = false;
          carrierContact.isCarrierMailSend = false;
          carrierContact.sentStatus = false;
          // carrierContact.contact.forEach((res: any) => res.isChecked = false);
        }
        else {
          carrierContact.isChecked = true;
          if (isCheckedContact.length == 0) {
            this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: { carrierId: any; }) => x.carrierId != carrierContact.id);
          }
          else {
            carrierContact.contact.forEach((element: any) => {
              if (!element.isMailSent) {
                if (carrierContact.id == element.carrierId && element.isCarrierMailSend) {
                  if (element.isChecked == false) {
                    carrierContact.isCarrierMailSend = false;
                    carrierContact.sentStatus = false;
                  }

                }
              }
            })
          }



        }
      })
    }
  }

  //global checkbox event
  onCarrierClick(eventType: any, selectedRow: any, tierList: any, carrierIndex: any) {

    this.isPageDirty = true;
    if (eventType.checked) {
      this.createSendMailToCarrierList(selectedRow, "add")
      selectedRow.isChecked = true;
    }
    else {
      this.createSendMailToCarrierList(selectedRow, "remove")
      selectedRow.isChecked = false;
    }
  }

  onContactChange(eventType: any, selectedRow: any) {
    this.isPageDirty = true;
    let carrierId = selectedRow.id;
    let contactValue = eventType.target.value;
    let contactObject = selectedRow.contact.find((x: { id: string; }) => x.id == contactValue || 0);

    this.sendMailToCarrierList.forEach((data: any) => {
      if (data.carrierId === carrierId) {
        data.contactId = contactValue,
          data.contactEmail = contactObject?.office1Email || ""
      }
    })

    this.wbaCarriers.forEach((data: any) => {
      data.wbaCarrierListByTierGroup.forEach((groupData: any) => {
        if (groupData.id === carrierId) {
          groupData.contactId = contactValue
        }
      })
    })
  }

  sendMailToCarriers(action: string) {

    if (this.templateId == 0) {
      this._confirmBox.message = this._userMessages.wbaSumissionCarrierTemplateMessage;
      this._confirmBox.isVisible = true;
      return;
    }
    this.submitted = true;
    this.isAllContactSelected = true;
    this.isCarrierSelected = true;

    this.finalCarrierAndUserJson.wbaSubmissionCarriers

    this.finalCarrierAndUserJson.wbaSubmissionCarriers =
      this.finalCarrierAndUserJson.wbaSubmissionCarriers.map((item: any) => {
        item.mailTemplateId = this.templateId
        return item;
      });


    if (this.finalCarrierAndUserJson.wbaSubmissionCarriers.length == 0) {
      this.finalCarrierAndUserJson.wbaSubmissionCarriers = null
    }

    this._wbaService.addWbaEmailTemplateCarriers(this.finalCarrierAndUserJson).subscribe(resp => {
      if (parseInt(resp) > 0) {
        this.getWhiteBoardDetails(this.activeWbaId);
        this.getWbaCarrierListByIdApi(this.activeWbaId);
        this.getMarketingSummaryList(this.activeWbaId)
        this.sendMailToCarrierList = [];
        let data = {
          'wbaId': this.activeWbaId,
          'value': 'refresh'
        }
        this._notifierService._submissionEmailRefresh.next(data);
      } else {
        this.isDisable = false;
      }
    }, (err: any) => {

      this.showError = true;
      this.isDisable = false; //enabling save button for after changes
      if (err.status !== 200) {
        if (err.status === 400) {
          this.showError = true;
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = [];
          this.errorMessage = arr[0].value[0];
          this.errorList.push(this.errorMessage);
        } else {
          this.showError = true;
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
          this.errorList.push(this.errorMessage);
        }
      }
    });
    // }
  }

  getMarketingSummaryList(wbaId: any) {
    if (wbaId) {
      this._wbaService.getMarketingSummaryList(wbaId).subscribe((res: any) => {
        if (res) {
          this._dataCacheService.compressAndCacheData('wba_Mrktsmry_marketingSummary', res.marketingSummary);
        }
      })
    }
  }

  warningBoxCancel() {
    //this._warningBox.isVisible = false;
    this._confirmBox.isVisible = false;
  }
  //updating progress on  wba details title bar
  getWhiteBoardDetails(wbaId: any) {
    this._wbaService.getWBAById(wbaId).subscribe(resp => {
      if (resp) {
        this.wbaDetails = resp.whiteBoardActivityInfo;
        this.checkAccessPermissionByWbaId();
        this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
        this.epicUniquePolicyId = resp.whiteBoardActivityInfo?.epicUniquePolicyId;
        this.wbaTransType = resp.whiteBoardActivityInfo?.transType;
        this.wbaProgressChange = resp.whiteBoardActivityInfo?.progressName;
        this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
        this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
        this.prospectId = resp.whiteBoardActivityInfo?.prospectId
        //this.epicUniquePolicyId = resp?.whiteBoardActivityInfo?.priorPolicyId;
      }
    });
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.prospectId,
      epicUniquePolicyId: this.epicUniquePolicyId,
      wbaTransType: this.wbaTransType,
      priorPolicyId: this.wbaDetails.priorPolicyId
    }
    modalRef.componentInstance.wbaIdData = data;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'Template-Saved') {
        this.getWbaEmailTemplateByWbaId(this.activeWbaId)
      }
    })
    modalRef.closed.subscribe((resp) => {
    });
  }

  showErrorBox() {
    this._errorBox.message = !this.isAllContactSelected ? this._userMessages.wbaSumissionContactErrorMessage : this._userMessages.wbaSumissionCarrierErrorMessage;
    this._errorBox.isVisible = true;
  }

  showCarrierConactUserLengthZeroErrorBox() {
    this._errorBox.message = this._userMessages.carrierConactUserLengthZero;
    this._errorBox.isVisible = true;
  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
  }

  //Loading prospect List.
  getCarriersListInfo() {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "wbaId": this.activeWbaId
    }

    this._carriersService.getSubmissionCarriersList(data).subscribe(
      (resp: any) => {
        if (resp) {
          this.masterCarrierList = resp.items[0].carrierList;

          this.lookupData = {
            data: this.masterCarrierList,
            total: resp.totalRecord
          };

          const modalRef = this.modalService.open(LookupSubmissionCarrierComponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });

          modalRef.closed.subscribe(resp => {
          })


          modalRef.componentInstance.lookupData = this.lookupData;
          modalRef.componentInstance.messageEvent.subscribe((response: any) => {
            if (response) {
              if (response.selectedData.contact.length > 0) {
                response.selectedData.contact = response.selectedData.contact.map((obj: any) => ({
                  ...obj, "isCarrierEmail": false
                }));

                response.selectedData.contact.sort((a: { contactNameWithInfo: string; }, b: { contactNameWithInfo: any; }) => a.contactNameWithInfo.localeCompare(b.contactNameWithInfo));
              }



              if (response.selectedData.submissionEmail) {
                response.selectedData.contact.unshift({
                  "id": response.selectedData.id,
                  "contactNameWithInfo": response.selectedData.submissionEmail, // label should be contactinfo what we grt from api
                  "prospectId": 0,
                  "carrierId": response.selectedData.id,
                  "isCarrierEmail": true,
                  "isChecked": false,
                  "isMailSent": false,
                  "isCarrierMailSend": false
                })
              }

              let lookupTierCarrier = [];
              lookupTierCarrier = this.wbaCarriers.filter((x: { tierName: string; }) => x.tierName === response.selectedData.tierName)

              if (lookupTierCarrier.length > 0) {
                let carrierExists = []
                carrierExists = lookupTierCarrier[0].wbaCarrierListByTierGroup.filter((x: { id: any; }) => x.id === response?.selectedData?.id || 0)
                if (carrierExists.length > 0) {
                  this.showCarrierExistsErrorBox();
                }
                else {
                  lookupTierCarrier[0].wbaCarrierListByTierGroup.push(response.selectedData);
                }
              }
              else {

                let sequence = this.getSequenceFromPreferredName(response.selectedData.tierName);
                let othersArray = [];
                othersArray.push(response.selectedData);
                this.wbaCarriers.push({ tierName: response.selectedData.tierName, sequence: sequence, wbaCarrierListByTierGroup: othersArray })
              }
              ;
              this.wbaCarriers.sort((a: { sequence: string; }, b: { sequence: any; }) => a.sequence.localeCompare(b.sequence));
              this.createDynamicFormControls();
            }
          });

        }
      },
      (error: any) => {
      }
    );
  }

  getSequenceFromPreferredName(tierName: string) {

    let sequence = '0';
    switch (tierName) {
      case 'Preferred-1':
        sequence = '1';
        break;

      case 'Preferred-2':
        sequence = '2';
        break;

      case 'Preferred-3':
        sequence = '3';
        break;

      case 'Preferred-4':
        sequence = '4';
        break;

      case 'Others':
        sequence = '5'
        break;

      default:
        return '6';
    }
    return sequence;
  }

  openLookupModal(value: any) {
    if (value === "expiringCarrier") {
      this.getCarriersListInfo();
    }
  }

  showCarrierExistsErrorBox() {
    this._errorBox.message = "This Carrier already exists in the list.";
    this._errorBox.isVisible = true;
  }

  uploadEpicFilesOnEfileCabinet() {
    let data = {
      policyId: this.epicUniquePolicyId,
      wbaId: this.activeWbaId
    }

    this._wbaService.uploadEpicFilesOnEfileCabinet(data).subscribe(resp => {
      if (resp) {
      }
    },
      ((errorResponse: any) => {
      }));
  }

  openSendCarrierConfirmationModal(value: any) {
    console.log(this.submissionForm);

    if (this.sendMailToCarrierList.length == 0 && this.selectedUserList.length == 0) {
      this.showCarrierConactUserLengthZeroErrorBox();
    }
    else {
      let json = {
        "wbaSubmissionCarriers": this.sendMailToCarrierList,
        "userList": this.selectedUserList,
        "templateId": this.templateId
      }

      const modalRef = this.modalService.open(SendCarrierConfirmationComponent, {
        keyboard: false,
        backdrop: 'static',
        modalDialogClass: 'customeDialog1'
      });

      modalRef.closed.subscribe(resp => {
      })
      modalRef.componentInstance.submissionData = json;
      modalRef.componentInstance.messageEvent.subscribe((response: any) => {

        if (response) {
          let finalWbaSubmissionCarriers = JSON.parse(JSON.stringify(response.wbaSubmissionCarriers));
          finalWbaSubmissionCarriers = finalWbaSubmissionCarriers.filter((x: { isError: boolean; isContactError: boolean; }) => x.isError == false && x.isContactError == false)
          this.finalCarrierAndUserJson = {
            "wbaSubmissionCarriers": finalWbaSubmissionCarriers,
            "userList": response.userList,
            "wbaId": this.activeWbaId
          }

          this.sendMailToCarriers('send');
        }

      });



    }
  }

  openContactModal(carrier: any) {
    const modalRef = this.modalService.open(AddContactComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      "contactType": "Carrier",
      "carrierId": carrier.id,
      "carrierName": carrier.carrierName
    }

    modalRef.componentInstance.fromContactList = data;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp.response == 'Add Contact from submission') {
        if (resp.contactInfo) {
          let value = resp.contactInfo;
          let contactNameWithInfo = ''

          if (value.isPkgContact && value.isWcContact) {
            contactNameWithInfo = value.firstName + ' ' + value.lastName + ' (PKG Contact & WC Contact)'
          }
          else if (value.isPkgContact) {
            contactNameWithInfo = value.firstName + ' ' + value.lastName + ' (PKG Contact)'
          }
          else if (value.isWcContact) {
            contactNameWithInfo = value.firstName + ' ' + value.lastName + '  (WC Contact)'
          }
          else {
            contactNameWithInfo = value.firstName + ' ' + value.lastName
          }

          resp.contactInfo.contactNameWithInfo = contactNameWithInfo;
          resp.contactInfo.isCarrierEmail = false
          resp.contactInfo.isMailSent = false
          carrier.contact.push(resp.contactInfo)
          // carrier.contact.sort((a: { contactNameWithInfo: string; }, b: { contactNameWithInfo: any; }) => a.contactNameWithInfo.localeCompare(b.contactNameWithInfo));
        }

      }
    })
    modalRef.closed.subscribe((resp) => {

    });
  }

  getUserList() {
    // let data = {
    //   "lookUpType": 'WBASubmisiionUser',
    //   "rowCount": 10
    // }

    // this._wbaService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
    //   if (response) {
    //     this.userList = response.userList;
    //     this.userList = this.userList.map((obj: any) => ({
    //       ...obj, "isMailSent": false
    //     }));

    // add login user in the lop the list
    this.userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    if (this.userDetails) {
      this.loginUserId = this.userDetails.user.userId;
    }

    if (this.loginUserId) {
      var loginUserRow = this.userList.find((x: any) => (x.userId == this.loginUserId));

      var removeUserIndex = this.userList.findIndex((x: any) => (x.userId == this.loginUserId));
      if (removeUserIndex > -1 && this.userList.length > 0) {
        this.userList.splice(removeUserIndex, 1)
      }

      if (loginUserRow) {
        this.userList.unshift(loginUserRow);

        this.selectedUserList.push({
          id: loginUserRow.userId,
          display_name: loginUserRow.display_name,
          isMailSent: true,
          "email": loginUserRow.email
        })
        setTimeout(() => {
          this.userIdList.push(loginUserRow.userId)
          this.submissionForm.get('selectedUserLabel').patchValue(loginUserRow.userId.split(','));
        }, 100);
      }
    }
    //     }
    //   }, (err: any) => {
    //     if (err) {
    //       this.showError = true;
    //       this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
    //     }
    //   }
    // );
  }

  onUserListChange(data: any) {
    this.selectedUserList = [];
    let selectedUserIDList = data.value
    this.userIdList = [];
    if (selectedUserIDList.length > 0) {
      selectedUserIDList.forEach((element: any) => {
        // let displayName = this.userList.find(x => x.userId == element).display_name;
        let checkedUser = this.userList.find(x => x.userId == element);
        if (checkedUser) {
          checkedUser.isMailSent = true;

          this.userIdList.push(element)
          this.selectedUserList.push({
            id: element,
            display_name: checkedUser.display_name,
            isMailSent: true,
            "email": checkedUser.email
          })
        }
      });
    }
  }

  onContactClick(data: any, selectedContact: any, index: any) {
    const formControl = this.submissionForm.controls[this.getFormControlName(selectedContact.id)];
    if (data.itemValue) { // single contact click
      let selectedContactIDList = data.value
      let currentContactIndex = selectedContact.contact.findIndex((x: { id: any; }) => x.id == data.itemValue);
      let currentContact = selectedContact.contact.find((x: { id: any; }) => x.id == data.itemValue);

      // Add contact of carrier by clicking on contact checkbox     
      if (data.value.includes(data.itemValue)) {
        let currentContactIdRow = selectedContactIDList.find((x: any) => x == data.itemValue);
        let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == selectedContact.id);

        if (currentContactIdRow) { // add contact
          // make carrier checkbox true
          selectedContact.isChecked = true;

          // index = 0 means carrier email so no need to add in contact array. just set sentStatus = true for carrier
          if (currentContactIndex == 0 && currentContact.isCarrierEmail == true) {
            selectedContact.sentStatus = true;
            selectedContact.isCarrierMailSend = true;
          }
          // else {

          if (carrierObject) {
            if (!currentContact.isCarrierEmail) {
              let contactObject = {
                "id": currentContact.id,
                "email": currentContact.office1Email,
                "contactName": currentContact.firstName ? currentContact.firstName + ' ' + currentContact.lastName : currentContact.contactNameWithInfo,
                "isCarrierEmail": currentContact.isCarrierEmail
              }

              if (carrierObject.contactList == null) {
                carrierObject.contactList = []
              }

              carrierObject.contactList.push(contactObject);
            } else {
              // this.sendMailToCarrierList.forEach((res: any) => res.sentStatus = true);
              let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == selectedContact.id);
              carrierObject.sentStatus = true
              carrierObject.isCarrierMailSend = true;
            }
          }
          else {
            if (currentContactIndex == 0 && currentContact.isCarrierEmail == true) {
              selectedContact.sentStatus = true;
              selectedContact.isCarrierMailSend = true;

              this.data = {
                "wbaId": selectedContact.wbaId || this.activeWbaId,
                "submissionMail": selectedContact.submissionEmail,
                "carrierId": selectedContact.id,
                "sentStatus": currentContactIndex == 0 && currentContact.isCarrierEmail == true ? true : false,
                "mailTemplateId": this.templateId,
                "isChecked": selectedContact.isChecked ? true : false,
                "CarrierName": selectedContact.carrierName,
                "isCarrierMailSend": true,
                "contactList": null,
              }
              this.sendMailToCarrierList.push(this.data)
            }
            else {
              this.data = {
                "wbaId": selectedContact.wbaId || this.activeWbaId,
                "submissionMail": selectedContact.submissionEmail,
                "carrierId": selectedContact.id,
                "sentStatus": currentContactIndex == 0 && currentContact.isCarrierEmail == true ? true : false,
                "mailTemplateId": this.templateId,
                "isChecked": selectedContact.isChecked ? true : false,
                "CarrierName": selectedContact.carrierName,
                "isCarrierMailSend": false,
                "contactList": [{
                  "id": currentContact.id,
                  "email": currentContact.office1Email,
                  "contactName": currentContact.firstName ? currentContact.firstName + ' ' + currentContact.lastName : currentContact.contactNameWithInfo,
                  "isCarrierEmail": currentContact.isCarrierEmail
                }]
              }
              this.sendMailToCarrierList.push(this.data)
            }
          }

          if (currentContact) {
            currentContact.isChecked = true;
          }
        }
        else { // remove contact       
          if (carrierObject.contactList) {
            carrierObject.contactList = carrierObject.contactList.filter((x: { id: any; }) => x.id != data.itemValue)
          }

          if (currentContact) {
            currentContact.isMailSent = false;
            currentContact.isChecked = false;
          }
        }
      }
      // // remove last selected contact by clicking on contact checkbox so remove contact with carrier
      else
      //if ((selectedContactIDList.length == 0 && data.itemValue))
      {


        this.selectedTier1ContactId.forEach((el: any[]) => {

          // var removeContactIndex = el.findIndex((x: any) => (x == selectedContact.id));
          var removeContactIndex = el.findIndex((x: any) => (x == data.itemValue));

          if (removeContactIndex > -1 && el.length > 0) {
            el.splice(removeContactIndex, 1)
          }
        })
        // const index = formControl?.value.findIndex((res: any) => res == selectedContact.id);
        const index = formControl?.value.findIndex((res: any) => res == data.itemValue);
        if (index > -1) formControl?.value.splice(index, 1);
        formControl.patchValue(formControl?.value);
        formControl.updateValueAndValidity();

        selectedContact.contact.forEach((e: any) => {
          if (e.id == data.itemValue) {
            e.isChecked = false
          }
          // if (e.id == selectedContact.id && formControl?.value.length == 0) {
          if (e.id == data.itemValue && formControl?.value.length == 0) {
            selectedContact.isChecked = false;
            selectedContact.sentStatus = false;
            selectedContact.isCarrierMailSend = false;
          }
        })


        // Remove contact from the selected carriers with contact List to whom we will send mail
        // this code is common for all Tier or preferred
        let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == selectedContact.id);

        if (carrierObject.contactList) {
          // if carrier id and contact id are equal means carrier
          // so need to set sentStatus, isChecked, isCarrierMailSend to false
          if (selectedContact.id == currentContact.id) {
            // get carrier with contact list
            // selectedContact.isChecked = false;
            selectedContact.sentStatus = false;
            // selectedContact.isCarrierMailSend = false;

            // send carrier with contact list
            carrierObject.isChecked = false;
            carrierObject.sentStatus = false;
            carrierObject.isCarrierMailSend = false;
          }
          else {
            // remove unchecked contact
            carrierObject.contactList = carrierObject.contactList.filter((x: { id: any; }) => x.id != data.itemValue)
          }
        }


        let mailSenCarrierContact = JSON.parse(JSON.stringify(selectedContact.contact));
        mailSenCarrierContact = mailSenCarrierContact.filter((x: { isMailSent: boolean; }) => x.isMailSent == true);

        let checkedCarrierContact = JSON.parse(JSON.stringify(selectedContact.contact));
        checkedCarrierContact = checkedCarrierContact.filter((x: { isChecked: boolean; }) => x.isChecked == true);

        if (checkedCarrierContact.length == mailSenCarrierContact.length) {
          this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: any) => x.carrierId != selectedContact.id);
        }
      }
    }
    else { // All contacts click
      let selectedContactIDList = data.value
      if (selectedContactIDList.length > 0) {
        let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == selectedContact.id);
        // carrierObject.contactList = null;

        if (carrierObject) {
          this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: { carrierId: any; }) => x.carrierId != selectedContact.id)
        }

        let isSelectAll = true;

        if (selectedContact.tierName == "Preferred-1") {

          if (formControl?.value.length == 0) {
            isSelectAll = false
          }
          else {
            let currentContactList = JSON.parse(JSON.stringify(selectedContact.contact));
            const result = currentContactList.filter((item: { id: any; isMailSent: boolean; }) => data.value.includes(item.id) && item.isMailSent == true);
            if (result.length == data.value.length) {
              isSelectAll = false
            }
          }
        }
        else if (selectedContact.tierName == "Preferred-2") {
          // isSelectAll = this.selectedTier2ContactId[index].length == 0 ? false : true
          if (this.selectedTier2ContactId[index].length == 0) {
            isSelectAll = false
          }
          else {
            let currentContactList = JSON.parse(JSON.stringify(selectedContact.contact));
            const result = currentContactList.filter((item: { id: any; isMailSent: boolean; }) => data.value.includes(item.id) && item.isMailSent == true);
            if (result.length == data.value.length) {
              isSelectAll = false
            }
          }

        }
        else if (selectedContact.tierName == "Preferred-3") {
          // isSelectAll = this.selectedTier3ContactId[index].length == 0 ? false : true

          if (this.selectedTier3ContactId[index].length == 0) {
            isSelectAll = false
          }
          else {
            let currentContactList = JSON.parse(JSON.stringify(selectedContact.contact));
            const result = currentContactList.filter((item: { id: any; isMailSent: boolean; }) => data.value.includes(item.id) && item.isMailSent == true);
            if (result.length == data.value.length) {
              isSelectAll = false
            }
          }

        }
        else if (selectedContact.tierName == "Preferred-4") {
          // isSelectAll = this.selectedTier4ContactId[index].length == 0 ? false : true

          if (this.selectedTier4ContactId[index].length == 0) {
            isSelectAll = false
          }
          else {
            let currentContactList = JSON.parse(JSON.stringify(selectedContact.contact));
            const result = currentContactList.filter((item: { id: any; isMailSent: boolean; }) => data.value.includes(item.id) && item.isMailSent == true);
            if (result.length == data.value.length) {
              isSelectAll = false
            }
          }

        }
        else if (selectedContact.tierName == "Others") {
          // isSelectAll = this.selectedTierOtherContactId[index].length == 0 ? false : true
          if (this.selectedTierOtherContactId[index].length == 0) {
            isSelectAll = false
          }
          else {
            let currentContactList = JSON.parse(JSON.stringify(selectedContact.contact));
            const result = currentContactList.filter((item: { id: any; isMailSent: boolean; }) => data.value.includes(item.id) && item.isMailSent == true);
            if (result.length == data.value.length) {
              isSelectAll = false
            }
          }
        }

        if (selectedContact.contact && selectedContact.contact.length > 0) {
          selectedContact.contact.forEach((currentContact: any, currentContactIndex: any) => {

            if (currentContact.isMailSent == false) {

              if (isSelectAll) // multiselect all checkbox checked
              {

                if (currentContact) {
                  currentContact.isChecked = true;
                }
                if (currentContactIndex == 0 && currentContact.isCarrierEmail == true) {
                  selectedContact.sentStatus = true;
                  selectedContact.isCarrierMailSend = true;
                }

                let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == currentContact.carrierId);
                // checking carriers already exists or not
                if (carrierObject) {
                  let contactObject = {
                    "id": currentContact.id,
                    "email": currentContact.office1Email,
                    "contactName": currentContact.firstName ? currentContact.firstName + ' ' + currentContact.lastName : currentContact.contactNameWithInfo,
                    "isCarrierEmail": currentContact.isCarrierEmail,
                    "isChecked": true
                  }

                  if (carrierObject.contactList == null) {
                    carrierObject.contactList = []
                  }

                  carrierObject.contactList.push(contactObject);
                }
                else {
                  // Need to check first item(carrier) of the multi select.
                  if (currentContactIndex == 0 && currentContact.isCarrierEmail == true) {
                    // mail already go to carrier to no need to add this carrier in sendMailToCarrierList
                    if (!currentContact.sentStatus) {
                      selectedContact.sentStatus = true;
                      selectedContact.isCarrierMailSend = true;

                      this.data = {
                        "wbaId": selectedContact.wbaId || this.activeWbaId,
                        "submissionMail": selectedContact.submissionEmail,
                        "carrierId": selectedContact.id,
                        "sentStatus": currentContactIndex == 0 && currentContact.isCarrierEmail == true ? true : false,
                        "mailTemplateId": this.templateId,
                        "isChecked": true,//selectedContact.isChecked ? true : false,
                        "CarrierName": selectedContact.carrierName,
                        "isCarrierMailSend": true,
                        "contactList": null,
                      }
                      this.sendMailToCarrierList.push(this.data)
                    }

                  }
                  // Need to check all items(contact) other than first Item of the multi select. 
                  else {
                    this.data = {
                      "wbaId": selectedContact.wbaId || this.activeWbaId,
                      "submissionMail": selectedContact.submissionEmail,
                      "carrierId": selectedContact.id,
                      "sentStatus": currentContactIndex == 0 && currentContact.isCarrierEmail == true ? true : false,
                      "mailTemplateId": this.templateId,
                      // "isChecked": selectedContact.isChecked ? true : false,
                      "isChecked": true,
                      "isCarrierMailSend": false,
                      "CarrierName": selectedContact.carrierName,
                      "contactList": [{
                        "id": currentContact.id,
                        "email": currentContact.office1Email,
                        "contactName": currentContact.firstName ? currentContact.firstName + ' ' + currentContact.lastName : currentContact.contactNameWithInfo,
                        "isCarrierEmail": currentContact.isCarrierEmail,
                        "isChecked": true,
                      }]
                    }
                    this.sendMailToCarrierList.push(this.data)
                  }
                }
              }
              else {
                if (currentContact) {
                  currentContact.isChecked = false;
                }
                if (currentContactIndex == 0 && currentContact.isCarrierEmail == true) {
                  if (!currentContact.sentStatus) {
                    selectedContact.sentStatus = false;
                    selectedContact.isCarrierMailSend = false;
                    selectedContact.isChecked = false;
                  }
                }
              }
            }



          });
        }
      }

    }
  }

  removeContact(selectedContact: any, tierName: any, carrier: any, carrierIndex: any) {

    selectedContact.isChecked = false;
    const formControl = this.submissionForm.controls[this.getFormControlName(selectedContact.carrierId)];
    const index = formControl?.value.findIndex((res: any) => res == selectedContact.id);
    if (index > -1) formControl?.value.splice(index, 1);
    formControl.patchValue(formControl?.value);
    formControl.updateValueAndValidity();

    carrier.contact.forEach((e: any) => {
      if (e.id == selectedContact.id && formControl?.value?.length == 0) {
        carrier.isChecked = false;
        carrier.sentStatus = false;
        carrier.isCarrierMailSend = false;
      }
    })


    // Remove contact from the selected carriers with contact List to whom we will send mail
    // this code is common for all Tier or preferred
    let carrierObject = this.sendMailToCarrierList.find((x: any) => x.carrierId == carrier.id);
    if (carrierObject.contactList) {
      // if carrier id and contact id are equal means carrier
      // so need to set sentStatus, isChecked, isCarrierMailSend to false
      if (carrier.id == selectedContact.id) {
        // get carrier with contact list     
        carrier.sentStatus = false;

        // send carrier with contact list
        carrierObject.isChecked = false;
        carrierObject.sentStatus = false;
        carrierObject.isCarrierMailSend = false;
      }
      else {
        // remove unchecked contact
        carrierObject.contactList = carrierObject.contactList.filter((x: { id: any; }) => x.id != selectedContact.id)

      }
    }

    let mailSenCarrierContact = JSON.parse(JSON.stringify(carrier.contact));
    mailSenCarrierContact = mailSenCarrierContact.filter((x: { isMailSent: boolean; }) => x.isMailSent == true);

    let checkedCarrierContact = JSON.parse(JSON.stringify(carrier.contact));
    checkedCarrierContact = checkedCarrierContact.filter((x: { isChecked: boolean; }) => x.isChecked == true);

    if (checkedCarrierContact.length == mailSenCarrierContact.length) {
      this.sendMailToCarrierList = this.sendMailToCarrierList.filter((x: any) => x.carrierId != carrier.id);
    }

  }

  removeUser(selectedUser: any, Index: any) {
    var removeUserIndex = this.userIdList.findIndex((x: any) => (x == selectedUser.id));
    if (removeUserIndex > -1 && this.userIdList.length > 0) {

      this.userIdList.splice(removeUserIndex, 1)
      this.submissionForm.get('selectedUserLabel').patchValue(this.userIdList);
    }

    var removeSelectedUserIndex = this.selectedUserList.findIndex((x: any) => (x.id == selectedUser.id));
    if (removeSelectedUserIndex > -1 && this.selectedUserList.length > 0) {

      this.selectedUserList.splice(removeSelectedUserIndex, 1)
    }
  }

  userInformation(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

    if (this.userDetails) {
      this.UserIdLogged = this.userDetails.user.userId;
      this.selectedListId = sessionStorage.getItem('selectWBAID') || this.userDetails.user.defaultWBAListId.toString();
      this.selectedDefaultList = this.selectedListId;

      // const requiredAddContactAndUserRoles = ['Admin', 'Sales Director', 'Marketing Manager', 'Account Manager'];
      // // const requiredAddContactAndUserRoles = ['Client Executive'];
      // this.isAddUserAndContactPermission = this.userDetails.user.roles.some((role: any) => requiredAddContactAndUserRoles.includes(role.roleName));

      // if (this.isAddUserAndContactPermission) {
      //   this._notifierService._isDisplaySubmissionSendButton.next(true);
      // }
      // else {
      //   this._notifierService._isDisplaySubmissionSendButton.next(false);
      // }

      const requiredSubmissionsRoles = ['Admin', 'Sales Director', 'Marketing Manager', 'Client Service Level 2', 'Production Specialist', 'Client Service Level 4', 'Client Service Level 3', 'Client Service Level 1'];
      // const requiredSubmissionsRoles = ['Client Executive'];
      this.isSubmissionValidUser = this.userDetails.user.roles.some((role: any) => requiredSubmissionsRoles.includes(role.roleName));

      if (this.isSubmissionValidUser) {
        this._notifierService._isDisplaySubmissionSendButton.next(true);
      }
      else {
        this._notifierService._isDisplaySubmissionSendButton.next(false);
      }

      const invalidUserRoles = ['Client Executive'];
      this.isInvalidUser = this.userDetails.user.roles.some((role: any) => invalidUserRoles.includes(role.roleName));
    }
  }

  checkAccessPermissionByWbaId() {
    this.AcordPermissionResponse = this.AcordPermissionResponse;
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);

    for (let index = 0; index < this.AcordPermissionResponse.length; index++) {
      let allRolePerms = this.AcordPermissionResponse[index];
      let actionAERequest = allRolePerms?.actionName

      let findRoleName = userDetails?.user?.roles.find((y: any) => y.id === allRolePerms?.approverRoleId);

      if (this.wbaDetails?.transType == 'RN' && (actionAERequest == 'CarrierSubmission' && (allRolePerms.approverUserId == userDetails?.user?.userId || findRoleName)) &&
        (this.wbaDetails?.progressName == 'Marketed to Carrier' || this.wbaDetails?.progressName == 'AM Marketed to Carrier'
          || this.wbaDetails?.progressName == 'Quoted' || this.wbaDetails?.progressName == 'BOR' || this.wbaDetails?.progressName == 'Submitted to Marketing' || this.wbaDetails?.progressName == 'AM Remarketing'
          || this.wbaDetails?.progressName == 'Request to Bind' || this.wbaDetails?.outcome == 'Bound' || this.wbaDetails?.progressName == 'Client' || this.wbaDetails?.outcome == 'Lost'
        )) {
        this.issubmissionRequest = true;
        this.emitSendToSelectedFlag.emit(this.issubmissionRequest)
        return;
      }
    }
  }

  closeError() {
    this.issubmissionRequest = false;
  }
}
