import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialsComponent } from 'src/app/core/components/dials/dials.component';
import { RingCentralLoginComponent } from 'src/app/core/components/ring-central-login/ring-central-login.component';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { environment } from 'src/environments/environment';
import { proposalMessage } from '../../../error-message/quoteDetails-mesages';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { LookupManagerComponent } from 'src/app/features/white-board/components/add-whiteboard/lookup-manager/lookup-manager.component';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { UploadProspectLogoComponent } from '../upload-prospect-logo/upload-prospect-logo.component';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { ProposalSummaryNotesComponent } from '../proposal-summary-notes/proposal-summary-notes.component';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { NotificationService } from '@progress/kendo-angular-notification';
import { forkJoin } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { NgxMaskPipe } from 'ngx-mask';
@Component({
  selector: 'app-proposalDetails',
  templateUrl: './proposalDetails.component.html',
  styleUrls: ['./proposalDetails.component.css']
})
export class ProposalDetailsComponent implements OnInit {
  isExpand: boolean = false;
  businessStatusChange: any;
  policyTypeName: any;
  progressStatusChange: any;
  prospectDetail: any;

  routeFrom: any;
  _navigationPath: NavigationPath;
  wbaView: any;
  _confirmBox: ConfirmBox;
  tierId: any;
  public isSubmitted: boolean = false;
  public CoverageCheckList: any = [];
  public MasterCoverageListNew: any;
  public CoverageCheckListForApi: any = [];

  slideType: string = "Dynamic";
  slideName: string = "";

  currentSlideNumber: any;
  wbaDetails: any;
  marketSummaryList: any = [];
  proposalForm: any;
  isDisable: boolean = false;
  _quoteDetailsMessage: proposalMessage;
  userId: string | null = "0";
  userDetails: any = [];
  presentedBy: any = "";
  pi_description: string = '<p><span style="color: black;">Lamb Insurance Services is pleased to provide this proposal to @ProspectName</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">This proposal employs Lamb’s unique skills as a broker specialized in commercial insurance for human service organizations and represents our recommendations based upon your specific needs.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">I look forward to reviewing this proposal with you.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">@UserName</span></p>';
  pi_descriptionOriginal: string = '<p><span style="color: black;">Lamb Insurance Services is pleased to provide this proposal to @ProspectName</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">This proposal employs Lamb’s unique skills as a broker specialized in commercial insurance for human service organizations and represents our recommendations based upon your specific needs.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">I look forward to reviewing this proposal with you.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">@UserName</span></p>';
  request: any = [];
  arrayForMasterServiceList: any[] = [];
  masterCoverageServiceList: any = [];
  services: any;
  claimServiceList: any[] = [];
  riskServiceList: any[] = [];
  riskServiceLabelOriginal = "Following are a few of the services we are offering @coverPageTitle";
  riskServiceLabel = "Following are a few of the services we are offering ";
  apiPayload: any;
  proposalId: number = 0;
  proposalPath: string = "";
  proposalDoc: any;
  prospectName: any;
  expiringCarrier: any;
  expiringPremium: any;
  pdfSrc: any;
  userDetailes: any;
  totalHighlightLength: number = 0;
  totalParameterHighlightLength: number = 0;
  totalSelectLength: number = 0;
  totalParameterSelectLength: number = 0;
  coveragesFromDB: any = [];
  lookupData: any;
  yourServiceTeamList: any = [];
  _errorBox: ErrorBox;
  activatedWbaId: any;
  totalPremiumSummaryCoverage: any = [];
  currentDisplayName: string = "";
  totalPremiumExpiring: any;
  totalPremiumRecommended: any;
  prospectLogoObject: any;
  prospectLogoId: number = 0;
  proposalDetails: any;
  isAllCarriersSelected: boolean = false;
  _userMessages: UserMessages;
  _warningBox: ConfirmBox;
  prospectId: any;
  finalWBAList: any = { total: 0, data: [] };
  public mySelection: number[] = [];
  pageSize = 5;
  pageNumber = 1;
  skip = 0;
  proposalOption = 'Proposal';
  showProposalSlide: boolean = false;
  public wbaListArr: any = { wbalists: [], prospectTier: 0, proposalOption: '', primaryWbaId: 0 };
  isAllWbaSelected: boolean = false;
  selectedArr: any;
  currentLineName: any;
  isProposalChecked: boolean = true;
  isCapabilitiesChecked: boolean = false;
  isStaticSlide: boolean = false;
  totalPremiumDifferences: any;
  totalPremiumSummaryGroups: any = { slideNumber: 600, tableData: [] };
  marketingSummaryJson: any;
  staticSlideJson: any = [];
  dynamicSlideJson: any = [];
  pg: any;
  renewalList: any;
  ownerlist: any;
  pricingWCRating: any = [];
  pricingWCRatingPremium: any;
  pricingWCRatingPayroll: any;
  pricingPkgData: any;
  riskMgmtPersonilzed: any[] = [];
  pricingPKGRating: any = [];
  pricingPackageGroups: any = [];
  contactPicPath: any;
  listId: any;
  @ViewChild("template", { read: TemplateRef, static: false })
  public notificationTemplate!: TemplateRef<unknown>;
  public hideAfter = 3000;
  uploadLogoUrl: string = '';
  totalWCRatingExpValue: any;
  totalWCRatingRecValue: any;
  totalWCRatingDifference: any;
  proposalSlidesResponse: any;
  lastSlide: any;
  expMode: any;
  expExpMode: any;
  recExpMode: any;
  hasCovgAssessment: any;
  prospectTierList: any = [];
  tierName: any;
  hasOnlyPackageLob: boolean = false;
  pricingPkgResponse: any;

  constructor(
    private _notifierService: WBANotifierService,
    public route: Router,
    public ValidateKey: KeyboardValidation,
    private fb: UntypedFormBuilder,
    private _addWBAService: WhiteBoardActivityService,
    private _preventBack: PreventBackButtonService,
    public _amountPipe: CustomeAmountPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    private el: ElementRef,
    private _AcmeProductService: AcmeProductService,
    public _numberPipe: CustomeNumberPipe,
    public datepipe: DatePipe,
    public maskPipe: NgxMaskPipe,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private notificationService: NotificationService,
    public clipboard: Clipboard

  ) {
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();
    this._confirmBox = new ConfirmBox()
    this._warningBox = new ConfirmBox()
    this.routeFrom = this.route.getCurrentNavigation()?.extras?.state?.routeValue;
    this._quoteDetailsMessage = new proposalMessage();
    this.getFormControls();
    this._errorBox = new ErrorBox();
  }

  closeModal() {
    if (this.routeFrom == 'WhiteboardList') {
      this.route.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl())
    } else {
      this.route.navigate([
        '/' +
        this._navigationPath.getWhiteboardsUrl() +
        '/' +
        this._navigationPath.getwhiteboardsApplicationUrl(),
      ], { queryParams: { wbaId: this.activatedWbaId, listId: this.listId, prospectId: this.prospectId } });
    }
  }

  //to open 'Add Dial' Modal
  openAddDialModal(item: any) {
    this._confirmBox.isVisible = false;
    var rcTokenExp = localStorage.getItem('RC_tokenExp')
    if (localStorage.getItem('RC_token') != 'null' && parseInt(rcTokenExp!) > new Date().getTime()) {
      this.dialScreen(item)
    }
    else {
      this.rcLogin(item)
    }
  }

  dialScreen(item: any) {
    const modalRef = this.modalService.open(DialsComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog-d',
    });
    var data = {
      number: item,
      prospectId: this.wbaView.prospectId,
    };
    modalRef.componentInstance.dialNumber = data;
    modalRef.closed.subscribe((resp) => {
    });
  }

  rcLogin(item: any) {
    sessionStorage.setItem('modalStatus', '')
    localStorage.setItem('RC_tokenExp', '')
    const modalRef = this.modalService.open(RingCentralLoginComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'ringDialog1',
    });
    const timer = setInterval(() => {
      if (sessionStorage.getItem('modalStatus') == 'close') {
        modalRef.close()
        clearInterval(timer)
        this.dialScreen(item)
      } else if (sessionStorage.getItem('modalStatus') == 'open') {
        modalRef.close()
        clearInterval(timer)
      }
    }, 500)
  }

  getFormControls() {
    this.proposalForm = this.fb.group({
      coverPageTitle: ['', Validators.required],
      coverPageProspectName: ['', Validators.required],
      coverPagepresentedBy: [{ value: '' }, [Validators.required]],
      coverPageInclude: [''],
      contactName: [{ value: '' }],
      contactTitle: [''],
      contactPhone: [''],
      contactEmail: [''],
      contactExt: [''],
      //contactBody: [''],
      contactPicPath: [''],
      thankyouOrgName: [''],
      thankyouCEName: [{ value: '' }],
      hseDescription: [''],
      hseInclude: [''],
      lspInclude: [''],
      piDescription: [''],
      piInclude: [''],
      mlcInclude: [''],
      tpsBusinessLine: [''],
      tpsExpiringPremium: [''],
      tpsTargetPremium: [''],
      tpsInclude: [''],
      coveragesInclude: [''],
      coveragesAllChecked: [''],
      msInclude: [''],
      abrInclude: [''],
      rmgAboutRisk: [''],
      rmgDescription: [''],
      rmgServices: [''],
      rmgInclude: [''],
      ystResouceList: this.fb.array([]),
      ystInclude: [''],
      cmpInclude: [''],
      sbDescription: [''],
      sbInclude: [''],
      summaryDifferences: [''],
      summaryServices: [''],
      summaryInclude: [''],
      disclaimerInclude: [''],
      picInclude: [''],
      amTrustInclude: [''],
      cmInclude: [''],
      goInclude: [''],
      niaInclude: [''],
      berkleyInclude: [''],
      hanoverInclude: [''],
      krInclude: [''],
      contactInclude: [''],
      thankyouInclude: ['']
    })
  }

  ngOnInit(): void {



    window.scroll(0, 0);

    this.activeRouter.queryParams.subscribe(params => {
      if (params)
        this.activatedWbaId = params.wbaId,
          this.prospectId = params.prospectId,
          this.listId = params.listId

    });

    this.getProspectDetailTemp(this.prospectId);
    this.getProspectTierList()

    this.userDetailes = JSON.parse(localStorage.getItem('userDetails')!);
    if (this.userDetailes) {
      this.userId = this.userDetailes.user.userId
    }
    // this.getPaginatedList(0);
    // this.getWhiteBoardDetails(this.activatedWbaId);
    this.proposalForm.reset(); //method to reset Proposa form
    this._preventBack.preventBackButton(); //method to prevent browser back button
    this.proposalForm.patchValue({
      coverPageTitle: "Commercial Insurance Solutions for",
      coverPageInclude: true,
      coverPagepresentedBy: {
        id: this.userId,
        value: this.userDetailes.user.display_name
      },
      // hseDescription: '<h2><strong>Lamb Insurance Services is an insurance broker positioned uniquely in the marketplace to serve the needs of companies like yours.</strong></h2><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dedicated exclusively to non-profit and human services organizations</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experts in understanding the specific insurance needs of organizations in this space</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;More expertise in non-profits and human services than any other broker in the industry</p>',
      hseDescription: '<p><strong><span style="font-family: Arial,&quot;Helvetica Neue&quot;,Helvetica,sans-serif;">Lamb Insurance Services is an insurance broker positioned uniquely in the marketplace to serve the needs of companies like yours.</span></strong></p><ul><li><p><span style="font-family: Arial,&quot;Helvetica Neue&quot;,Helvetica,sans-serif;">Dedicated exclusively to non-profit and human services organizations</span></p></li><li><p><span style="font-family: Arial,&quot;Helvetica Neue&quot;,Helvetica,sans-serif;">Experts in understanding the specific insurance needs of organizations in this space</span></p></li><li><p><span style="font-family: Arial,&quot;Helvetica Neue&quot;,Helvetica,sans-serif;">More expertise in non-profits and human services than any other broker in the industry</span></p></li></ul>',
      hseInclude: true,
      lspInclude: true,
      piInclude: true,
      mlcInclude: true,
      tpsInclude: true,
      coveragesInclude: true,
      coveragesAllChecked: false,
      msInclude: true,
      abrInclude: false,
      rmgAboutRisk: '<p>Insurance is the transfer of uncertain risks to another party (the insurer) for a certain sum of money (the premium).&nbsp;However, Risk Management is far broader.&nbsp;While it does encompass insurance, it also deals with avoiding, preventing, minimizing and >recovering from loss.&nbsp;In addition, Risk Management addresses methods other than insurance for transferring the financial consequences of losses. </p>',
      rmgDescription: 'Lamb Insurance Services has certified Insurance Code Rule 59 and 60 consultants, developing customized, comprehensive risk management packages.',
      rmgInclude: true,
      ystResouceName: '<h2><strong>Additional Lamb Resources:</strong></h2><p>Josh Lamberg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Founder &amp; Chief Executive Officer</p><p>Sal Abano&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; President</p>',
      ystInclude: true,
      cmpInclude: true,
      sbInclude: true,
      summaryDifferences: '<h2><strong>The Lamb Difference:</strong></h2><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Call out significant differentiators in this proposal.</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example: This proposal represents an annual savings of $xx, while offering incremental protection in the areas of x and y.</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etc.</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etc.</p>',
      summaryServices: '<h2><strong>Lamb Insurance Services:</strong></h2><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dedicated exclusively to non-profit and human services organizations</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Uniquely qualified to understand and protect your business</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;An advocate for organizations like yours</p>',
      //contactBody: '<h2><strong>Please contact us with any questions you may have.<strong></h2><p>For more information about Lamb Insurance Services, visit our website at www.LambIS.com and follow us on LinkedIn.</p>',
      contactName: {
        id: this.userId,
        value: this.userDetailes.user.display_name
      },
      contactTitle: this.userDetailes.user.position,
      contactPhone: this.userDetailes.user.mobile,
      contactEmail: this.userDetailes.user.email,
      contactExt: this.userDetailes.user.extension,
      contactPicPath: this.userDetailes.user.userPicPath,
      summaryInclude: true,
      disclaimerInclude: true,
      picInclude: true,
      amTrustInclude: true,
      cmInclude: true,
      goInclude: true,
      niaInclude: true,
      berkleyInclude: true,
      hanoverInclude: true,
      krInclude: true,
      contactInclude: true,
      thankyouInclude: true,
      thankyouCEName: {
        id: this.userId,
        value: this.userDetailes.user.display_name
      }
    })
    // if (this.userDetailes.user.userPicPath) this.convertImage(this.userDetailes.user.userPicPath, 'contact');

    this.currentSlideNumber = 100;
    this._notifierService._isProgressStatusChange.subscribe(resp => {
      if (resp) {
        this.progressStatusChange = resp;
      }
    });

    // Get Proposal data By wba ID
    this.getWbaProposalById();
    this.getProposalSlidesByPrimaryWbaId();
  }

  //to get wba carriers list for market summary slide
  getWbaProposalById() {
    this._addWBAService.getWbaProposalById(this.activatedWbaId).subscribe(resp => {
      if (resp) {
        this.prospectLogoId = resp[0]?.prospectLogoId ? resp[0]?.prospectLogoId : 0
        this.proposalDetails = resp[0];
        this.uploadLogoUrl = this.proposalDetails?.prospectLogoPath;
        // if (this.proposalDetails?.prospectLogoPath) this.convertImage(this.proposalDetails?.prospectLogoPath, 'uploadLogo')
      }
    })
  }

  // Get wba details for PREMIUM SUMMARY slide
  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaDetails = resp.whiteBoardActivityInfo;
      this.businessStatusChange = this.wbaDetails.masterBusinessStatus,
        this.tierName = this.prospectTierList.find((tierItem: any) => (tierItem.id == resp.whiteBoardActivityInfo.prospectSizeId));
      this.getPaginatedList(0);
      this.expiringCarrier = this.wbaDetails.expiringName;
      this.expiringPremium = this.wbaDetails.expiringPremium ? "$" + this._numberPipe.transform(this.wbaDetails.expiringPremium, 'USD', '1.0-0') : "$0";
      this.tierId = this.wbaDetails.prospectSizeId;
      // Default Service team list for the Your Service Team Slide
      this.getDefaultServiceTeam(this.activatedWbaId, this.tierId);

      // this.wbaDetails.totalCoverageExpiringPremium = this.wbaDetails.totalCoverageExpiringPremium ? "$" + this._numberPipe.transform(this.wbaDetails.totalCoverageExpiringPremium, 'USD', '1.0-0') : "$0";
      // this.wbaDetails.totalCoverageRecommendedPremium = this.wbaDetails.totalCoverageRecommendedPremium ? "$" + this._numberPipe.transform(this.wbaDetails.totalCoverageRecommendedPremium, 'USD', '1.0-0') : "$0";
      this.prospectName = this.wbaDetails.companyName;
      this.pi_description = this.pi_description.replace("@ProspectName", this.wbaDetails.companyName);
      this.proposalForm.patchValue({
        coverPageProspectName: this.wbaDetails.companyName,
        thankyouOrgName: this.wbaDetails.companyName,
        piDescription: this.pi_description,
        tpsBusinessLine: this.wbaDetails.masterBusinessLine,
        tpsExpiringPremium: this.wbaDetails.expiringPremium,
        tpsTargetPremium: this.wbaDetails.targetPremium
      });
      let obj = {
        wbaId: this.wbaDetails?.id,
        lob: this.wbaDetails?.shortCode,
        lobId: this.wbaDetails?.masterBusinessLineId,
        transType: this.wbaDetails?.transType
      }
      let index = this.wbaListArr?.wbalists?.findIndex((e: any) => e.wbaId == this.wbaDetails?.id);
      if (index == -1) this.wbaListArr?.wbalists.push(obj);
      this.riskServiceLabel = this.riskServiceLabel + ' ' + this.wbaDetails.companyName;
      console.log(this.wbaListArr);
      if (this.wbaDetails.transType == 'RN') this.currentSlideNumber = 101;
    });
  }

  setApiPayload(slideType: string) {
    let formValue = this.proposalForm.value;
    this.dynamicSlideJson = [];
    this.staticSlideJson = [];
    // Update values for coverages slide
    let includeCoverage = this.getIncludedSlides('Coverages');
    let checkList = {
      slideName: 'Coverages',
      data: JSON.parse(JSON.stringify(this.request))
    };
    checkList.data.forEach((item: any, index: any) => {
      item.coverageAssessmentDetail.forEach((cov: any, index: any) => {
        cov.coverageParameters = cov.coverageParameters.filter((x: { selectRow: boolean; }) => x.selectRow === true);
      })
    })
    checkList.data = includeCoverage ? checkList.data : [];
    this.handleCoverageCheckbox('both');
    // Update values for your service team slide
    let serviceTeamList = formValue.ystResouceList;
    serviceTeamList = serviceTeamList?.map((obj: any) => ({
      ...obj, "sequence": 11,
      "included": formValue.ystInclude
    }));

    this.marketingSummaryJson = {
      slideNumber: 2300,
      tableData: this.marketSummaryList?.map((obj: any, row: 1) => {
        return {
          rowNo: ++row, rowType: '', coverageName: obj.coverageName, carrierName: obj.carrierName,
          wholesalerName: obj.wholesalerName, quotedStatus: obj.quotedStatus, comments: obj.notes?.length > 0 ? obj.notes : ""
        }
      })
    }

    let renewalSlideData = this.renewalList?.map((x: any) => x.effectiveDate);
    if (renewalSlideData?.length > 1) {
      let lastItem = renewalSlideData.pop();
      renewalSlideData = renewalSlideData?.join(', ') + ' and ' + lastItem;
    } else {
      renewalSlideData = renewalSlideData?.join(', ');
    }

    let proposalSlides = this.MasterCoverageListNew.filter((x: any) => x.displayName != 'Coverages');
    proposalSlides.forEach((obj: any, sequence: 1) => {
      let data = {
        // sequence: ++sequence,
        slideName: obj.displayName,
        slideNumber: obj.slideNumber,
        caffeinePageNumber: obj.caffeinePageNumber,
        excelPageNumber: obj.excelPageNumber,
        included: this.getIncludedSlides(obj.displayName)
      }
      if (obj.isStaticSlide) {
        this.staticSlideJson.push(data);
      } else {
        this.dynamicSlideJson.push(data);
      }
    })

    this.dynamicSlideJson.forEach((x: any) => {
      if (x.slideNumber == 100 || x.slideNumber == 101) {
        x.title = formValue.coverPageTitle;
        x.prospectName = formValue.coverPageProspectName;
        x.presentedBy = formValue.coverPagepresentedBy.value;
        x.clientlogopath = this.uploadLogoUrl;
      }
      if (x.slideNumber == this.totalPremiumSummaryGroups.slideNumber) x.tableData = this.totalPremiumSummaryGroups.tableData;
      if (x.slideNumber == 7400) x.tableData = this.totalPremiumSummaryGroups.tableData;
      if (x.slideNumber == this.marketingSummaryJson.slideNumber) {
        x.primaryWBALine = this.currentLineName;
        x.tableData = this.marketingSummaryJson.tableData;
      }
      // if (x.slideNumber == checkList.slideNumber) x.tableData = checkList.data;
      if (x.slideNumber == 2400) {
        // x.additionalTeam = serviceTeamList;
        x.tableData = this.yourServiceTeamList;
      }
      if (x.slideNumber == 4300) {
        x.thankyouCEName = formValue.thankyouCEName.value;
        x.thankyouOrgName = formValue.thankyouOrgName;
        x.contactName = formValue.contactName.value;
        x.contactTitle = formValue.contactTitle;
        x.contactPhone = formValue.contactPhone ? this.formatPhoneNumber(formValue.contactPhone) : '';
        x.contactExtension = formValue.contactExt;
        x.contactEmail = formValue.contactEmail;
        //   x.userPic = this.contactPicPath ? this.contactPicPath : '';
        //   x.userPicPath = formValue.contactPicPath;
      }
      if (x.slideNumber == 4200) {
        x.contactName = formValue.contactName.value;
        x.contactTitle = formValue.contactTitle;
        x.contactPhone = formValue.contactPhone ? this.formatPhoneNumber(formValue.contactPhone) : '';
        x.contactExtension = formValue.contactExt;
        x.contactEmail = formValue.contactEmail;
        x.userPic = this.contactPicPath ? this.contactPicPath : '';
        x.userPicPath = formValue.contactPicPath;
        //  x.contactBody = formValue.contactbody;
      }
      if (x.slideNumber == 3000) {
        x.prospectName = formValue.coverPageProspectName;
        x.tableData = this.riskMgmtPersonilzed;
        //x.claimManagement = this.claimServiceList;
        //x.riskControlServices = this.riskServiceList;
      }
      if (x.slideNumber == 4400) {
        x.effectiveDate = renewalSlideData;
      }
      if (x.slideNumber == 5000) {
        x.expiringValue = this.totalWCRatingExpValue;
        x.recommendedValue = this.totalWCRatingRecValue;
        x.difference = this.totalWCRatingDifference;
        x.expiringExpMode = this.expExpMode.toString();
        x.recommendedExpMode = this.recExpMode.toString();
        x.expModeDifference = this.getDifferenceOfExpMode();
        x.tableData = this.pricingWCRating;
      }
      if (x.slideNumber == 4900 || x.slideNumber == 7200) {
        x.tableData = this.pricingPackageGroups.tableData;
      }
      if (x.slideNumber == 6000) {
        x.experienceMode = this.expMode;
      }
      if (x.slideNumber == 4600) {
        x.primaryWBALine = this.currentLineName;
      }
    })


    this.apiPayload = {
      wBAProposal: {
        //   id: this.proposalId,
        wbaId: this.activatedWbaId,
        PreviewSlideName: this.slideName,
        Slidetype: this.slideType,
        StaticSlide: this.staticSlideJson,
        DynamicSlide: this.dynamicSlideJson,
        coverages: checkList.data
      },
      layout: slideType
    }
    console.log('this.apiPayload', this.apiPayload);

  }

  formatPhoneNumber(phoneNumberString: any) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  getIncludedSlides(slideName: string) {
    return this.MasterCoverageListNew.find((x: any) => x.displayName == slideName)?.isInclude;
  }

  downloadPreviewProposalReport() {
    this._addWBAService.previewProposalReport(this.apiPayload).subscribe(resp => {
      if (resp) {
        let fileURL: any = URL.createObjectURL(resp);
        let a = document.createElement("a");
        a.href = fileURL;
        a.target = '_blank';
        a.download = `${this.slideName}.pdf`;
        a.click();
      }
    })
  }

  copyText(value: string): void {
    this.clipboard.copy(value);
    // alert('Text copied to clipboard');
  }


  openSlide(slideData: any) {
    this.currentSlideNumber = slideData.slideNumber;
    this.isStaticSlide = slideData.isStaticSlide;
    this.slideType = "Dynamic";
    this.slideName = slideData.displayName;
  }

  openNextSlide() {
    let index = this.MasterCoverageListNew.findIndex((x: any) => x.slideNumber == this.currentSlideNumber);
    if (index > -1) this.openSlide(this.MasterCoverageListNew[index + 1]);
  }

  openPreviousSlide() {
    let index = this.MasterCoverageListNew.findIndex((x: any) => x.slideNumber == this.currentSlideNumber);
    if (index > -1) this.openSlide(this.MasterCoverageListNew[index - 1]);
  }

  handleCoverageHighlight(param: any) {
    param.highlights = !param.highlights;
    this.handleCoverageCheckbox('highlights');
  }

  // saveProposal(action: string) {
  //   if (action === 'download') {
  //     if (this.yourServiceTeamList.length === 0) {
  //       this.showNoUserErrorBox();
  //     }
  //     else {
  //       this.downloadProposalReport('');
  //     }
  //   }
  // }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    if (firstInvalidControl)
      firstInvalidControl.focus();
  }

  //to get all form controls
  get f() {
    return this.proposalForm.controls;
  }

  onIncludeChange(slideName: string, controlValue: any) {
    let object = this.MasterCoverageListNew.find((x: { displayName: string; }) => x.displayName === slideName);
    object.isInclude = !object.isInclude;
  }

  onCoverPageTitleChange() {
    if (this.proposalId === 0) {
      this.riskServiceLabel = this.riskServiceLabelOriginal.replace("@coverPageTitle", this.proposalForm.value.coverPageTitle);
    }
  }

  onCoverPageProposalChange() {
    if (this.proposalId === 0) {
      this.pi_description = this.pi_descriptionOriginal.replace("@UserName", this.userDetails.user.given_name);
      this.pi_description = this.pi_descriptionOriginal.replace("@ProspectName", this.proposalForm.value.coverPageProspectName);
      this.proposalForm.patchValue({ piDescription: this.pi_description });
    }
  }

  addServiceTeamFormGroup() {
    (this.proposalForm.get(['ystResouceList']) as UntypedFormArray).push(
      this.fb.group({
        resourceName: [''],
        resourceTitle: [''],
      })
    );
  }

  onCoverageHighlightAllSelect(event: any, cov: any, index: any) {
    let value = event.target.checked;
    if (value) {
      let _selectEleContact = (document.getElementById(cov.coverageName + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = true
      }

      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "highlights": true }));
    } else {
      let _selectEleContact = (document.getElementById(cov.coverageName + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = false
      }
      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "highlights": false }));
    }
  }

  onCoverageSelectAllSelect(event: any, cov: any, index: any) {
    let value = event.target.checked;
    if (value) {
      let _selectEleContact = (document.getElementById(cov.coverageName + 'SelectRow' + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = true
      }

      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "selectRow": true }));
    } else {
      let _selectEleContact = (document.getElementById(cov.coverageName + 'SelectRow' + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = false
      }
      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "selectRow": false }));
    }
  }

  onCoverageSelectAllSelect1(event: any) {
    let value = event.target.checked;
    if (value) {
      let _selectEleContact = (document.getElementById(this.marketSummaryList.coverageName + 'SelectRow') as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = true
      }

      this.marketSummaryList = this.marketSummaryList.map((obj: any) => ({ ...obj, "selectRow": true }));
    } else {
      let _selectEleContact = (document.getElementById(this.marketSummaryList.coverageName + 'SelectRow') as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = false
      }
      this.marketSummaryList = this.marketSummaryList.map((obj: any) => ({ ...obj, "selectRow": false }));
    }
  }

  handleCoverageSelectRow(param: any) {
    param.selectRow = !param.selectRow;
    this.handleCoverageCheckbox('selectRow');
  }

  handleMarketingSelectRow(item: any) {
    item.selectRow = !item.selectRow;
    this.handleCoverageCheckbox('bothMarketingSummary');
    let abc = this.marketSummaryList.filter((x: any) => x.coverageName == item.coverageName)
    abc.isChecked = item.selectRow;

  }

  handleCoverageCheckbox(value: any) {
    this.totalSelectLength = 0;
    this.totalParameterSelectLength = 0;
    this.totalHighlightLength = 0;
    this.totalParameterHighlightLength = 0;
    setTimeout(() => {
      this.request.forEach((item: any) => {
        item.coverageAssessmentDetail.forEach((cov: any, index: any) => {
          if (value === 'selectRow') {
            this.totalSelectLength = cov.coverageParameters.filter((x: { selectRow: boolean; }) => x.selectRow === true).length;
            this.totalParameterSelectLength = cov.coverageParameters.length;
            if (this.totalParameterSelectLength > 0 && this.totalSelectLength == this.totalParameterSelectLength) {
              let covName = cov.coverageName + 'SelectRow' + index.toString();
              let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleSelectRow) {
                _selectEleSelectRow.checked = true
              }
            }
            else {
              let covName = cov.coverageName + 'SelectRow' + index.toString();
              let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleSelectRow) {
                _selectEleSelectRow.checked = false
              }
            }
          } else if (value === 'highlights') {
            this.totalHighlightLength = cov.coverageParameters.filter((x: { highlights: boolean; }) => x.highlights === true).length;
            this.totalParameterHighlightLength = cov.coverageParameters.length;

            if (this.totalParameterHighlightLength > 0 && this.totalHighlightLength == this.totalParameterHighlightLength) {
              let covName = cov.coverageName + index.toString()
              let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleHighlight) {
                _selectEleHighlight.checked = true
              }
            }
            else {
              let covName = cov.coverageName + index.toString()
              let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleHighlight) {
                _selectEleHighlight.checked = false
              }
            }
          } else if (value === 'both') {
            // SelectRow
            this.totalSelectLength = cov.coverageParameters.filter((x: { selectRow: boolean; }) => x.selectRow === true).length;
            this.totalParameterSelectLength = cov.coverageParameters.length;
            if (this.totalParameterSelectLength > 0 && this.totalSelectLength == this.totalParameterSelectLength) {
              let covName = cov.coverageName + 'SelectRow' + index.toString();
              let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleSelectRow) {
                _selectEleSelectRow.checked = true
              }
            }
            else {
              let covName = cov.coverageName + 'SelectRow' + index.toString();
              let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleSelectRow) {
                _selectEleSelectRow.checked = false
              }
            }

            // highlights
            this.totalHighlightLength = cov.coverageParameters.filter((x: { highlights: boolean; }) => x.highlights === true).length;
            this.totalParameterHighlightLength = cov.coverageParameters.length;

            if (this.totalParameterHighlightLength > 0 && this.totalHighlightLength == this.totalParameterHighlightLength) {
              let covName = cov.coverageName + index.toString()
              let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleHighlight) {
                _selectEleHighlight.checked = true
              }
            }
            else {
              let covName = cov.coverageName + index.toString()
              let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
              if (_selectEleHighlight) {
                _selectEleHighlight.checked = false
              }
            }
          }
          else if (value === 'bothMarketingSummary') {
            // SelectRow
            this.totalSelectLength = this.marketSummaryList.filter((x: { selectRow: boolean; }) => x.selectRow === true).length;
            this.totalParameterSelectLength = this.marketSummaryList.length;
            if (this.totalParameterSelectLength > 0 && this.totalSelectLength == this.totalParameterSelectLength) {
              // this.isAllCarriersSelected = true;
              let _selectEleSelectRow = (document.getElementById("isAllCarriersSelected") as HTMLInputElement);
              if (_selectEleSelectRow) {
                _selectEleSelectRow.checked = true;
              }
            }
            else {
              // this.isAllCarriersSelected = false;
              let _selectEleSelectRow = (document.getElementById("isAllCarriersSelected") as HTMLInputElement);
              if (_selectEleSelectRow) {
                _selectEleSelectRow.checked = false;
              }
            }
          }
          // else if (value === 'bothMarketingSummary') {
          //   // SelectRow
          //   this.totalSelectLength = this.marketSummaryList.filter((x: { selectRow: boolean; }) => x.selectRow === true).length;
          //   this.totalParameterSelectLength = this.marketSummaryList.length;
          //   if (this.totalParameterSelectLength > 0 && this.totalSelectLength == this.totalParameterSelectLength) {
          //     this.isAllCarriersSelected = true;
          //   }
          //   else {
          //     this.isAllCarriersSelected = false;
          //   }
          // }
        })
      })
    })
  }

  openLookupModal(value: any, callFrom: string, selectedItem: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 10
    }
    let lookupcomponent: any;
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
      if (response) {
        this.lookupData = response;
        lookupcomponent = LookupManagerComponent;

        const modalRef = this.modalService.open(lookupcomponent, {
          keyboard: false,
          backdrop: 'static',
          modalDialogClass: 'customeDialog1'
        });
        modalRef.closed.subscribe(resp => {
        })

        modalRef.componentInstance.lookupData = this.lookupData;
        // if (callFrom == 'thankyou') modalRef.componentInstance.ownerlookupFilterData = this.f.thankyouCEName.value;
        // if (callFrom == 'contact') modalRef.componentInstance.ownerlookupFilterData = this.f.contactName.value;
        modalRef.componentInstance.messageEvent.subscribe((data: any) => {
          if (callFrom == 'yst') {
            if (this.yourServiceTeamList.length > 0) {
              let userExists = []
              userExists = this.yourServiceTeamList.filter((x: { userId: any; }) => x.userId === data.userId || 0)
              if (userExists.length > 0) {
                this.showUserExistsErrorBox();
              }
              else {
                this.yourServiceTeamList.push({
                  userId: data.userId,
                  userDisplayName: data.userName,
                  userPic: data.userPicPath || "",
                  userPicPath: data.userPicPath || "",
                  serviceDescription: data.serviceDescription || "",
                  position: data.position || "",
                  phone: data.mobile || "",
                  extension: data.extension || "",
                  email: data.email || ""
                })
              }
            }
            else {
              this.yourServiceTeamList.push({
                userId: data.userId,
                userDisplayName: data.userName,
                userPic: data.userPicPath || "",
                userPicPath: data.userPicPath || "",
                serviceDescription: data.serviceDescription || "",
                position: data.position || "",
                phone: data.mobile || "",
                extension: data.extension || "",
                email: data.email || ""
              })
            }
          }
          else {
            // if (callFrom == 'ThankYou') this.proposalForm.get('thankyouCEName').patchValue(data.userName);
            if (callFrom == 'ThankYou') this.proposalForm.get('thankyouCEName').patchValue({
              id: data.userId,
              value: data.userName
            });
            //if (callFrom == 'Contact') this.proposalForm.get('contactName').patchValue(data.userName);
            if (callFrom == 'Contact') {
              this.proposalForm.patchValue({
                contactName: {
                  id: data.userId,
                  value: data.userName
                },
                contactTitle: data.position,
                contactPhone: data.mobile,
                contactEmail: data.email,
                contactExt: data.extension,
                contactPicPath: data.userPicPath
              });
              // if (data.userPicPath) this.convertImage(data.userPicPath, 'contact');
            }
            //if (callFrom == 'TitleSlide') this.proposalForm.get('coverPagepresentedBy').patchValue(data.userName);
            if (callFrom == 'Title' || callFrom == 'TITLE') this.proposalForm.get('coverPagepresentedBy').patchValue({
              id: data.userId,
              value: data.userName
            });
            if (callFrom == 'EDIT') {
              let editingItem = this.yourServiceTeamList.find((x: any) => x.userId == selectedItem.userId)
              if (editingItem) {
                editingItem.userId = data.userId,
                  editingItem.userDisplayName = data.userName,
                  editingItem.userPic = data.userPicPath || "",
                  editingItem.userPicPath = data.userPicPath || "",
                  editingItem.serviceDescription = data.serviceDescription || "",
                  editingItem.position = data.position || "",
                  editingItem.phone = data.mobile || "",
                  editingItem.extension = data.extension || "",
                  editingItem.email = data.email || ""
              }
            }
          }
        });
      }
    });
    // }
  }

  showUserExistsErrorBox() {
    this._errorBox.message = "This resource already exists in the service team.";
    this._errorBox.isVisible = true;
  }

  showUserLengthErrorBox() {
    this._errorBox.message = "There are already 4 resources in the Service team.";
    this._errorBox.isVisible = true;
  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
    // unchecking the coverage and removing it from managed array
  }

  showNoUserErrorBox() {
    this._errorBox.message = "Please select atleast one service team member.";
    this._errorBox.isVisible = true;
  }

  onServiceDescriptionChange(event: any, item: any) {
    let value = event.target.value
    item.serviceDescription = value;
  }

  removeResourceServiceTeam(userId: any) {
    this.yourServiceTeamList = this.yourServiceTeamList.filter((x: { userId: any; }) => x.userId != userId);
  }

  // Get get Default Service Team for Your Service team slide
  getDefaultServiceTeam(wbaId: any, tierId: any) {
    this._addWBAService.getProposalDefaultServiceTeam(wbaId, tierId).subscribe(resp => {
      if (resp.userList && resp.userList.length > 0) {
        this.yourServiceTeamList = [];
        resp.userList.forEach((data: any) => {
          this.yourServiceTeamList.push({
            userId: data.userId,
            userDisplayName: data.userName,
            userPic: data.userPicPath || "",
            userPicPath: data.userPicPath || "",
            serviceDescription: data.serviceDescription || "",
            position: data.position || "",
            phone: data.mobile ? this.formatPhoneNumber(data.mobile) : '',
            extension: data.extension || "",
            email: data.email || ""
          })
        })
      }
    });
  }

  uploadLogo() {
    const modalRef = this.modalService.open(UploadProspectLogoComponent, {
      keyboard: true,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    this.prospectLogoObject = {
      prospectId: this.wbaDetails?.prospectId,
      prospectLogoId: this.proposalDetails?.prospectLogoId ? this.proposalDetails.prospectLogoId : 0,
      prospectLogoPath: this.proposalDetails?.prospectLogoPath ? this.proposalDetails.prospectLogoPath : "",
      wbaId: this.activatedWbaId
    }
    modalRef.componentInstance.prospectLogoObject = this.prospectLogoObject;
    modalRef.closed.subscribe((resp) => {
    });
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp) {
        this.uploadLogoUrl = resp;
        this.getWbaProposalById();
      }
    })
  }

  onInputChange(value: any) {
    var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
    return value
  }

  getPaginatedList(totalCount: any) {

    let request = {
      listId: "0",
      sortDir: "desc",
      sortCol: "effectiveDate",
      searchText: "",
      pageNumber: 1,
      pageSize: 10000000,
      filterJson: `{\"logic\":\"and\",\"filters\":[{\"operator\":\"eq\",\"value\":\"${this.prospectId}\",\"field\":\"WBA.ProspectId\"},{\"operator\":\"neq\",\"value\":\"${this.activatedWbaId}\",\"field\":\"WBA.WBAId\"},{\"operator\":\"contains\",\"value\":\"Open\",\"field\":\"WBAStatusId\"},{\"operator\":\"eq\",\"value\":\"${this.wbaDetails.transType}\",\"field\":\"transType\"}]}`,
      gridFilterJson: "",
      totalCount: totalCount,
      UserId: this.userId
    }
    this._addWBAService.wbaPagination(request).subscribe(res => {
      if (totalCount == 0) this.finalWBAList.data = res.items[0].whiteBoardActivityList;
      this.finalWBAList.data.forEach((x: any) => {
        let ele = this.wbaListArr.wbalists.find((e: any) => e.wbaId == x.id);
        if (ele) x.isSelected = true;
      })
    })
  }

  paginationChange(event: PageChangeEvent) {
    this.pageNumber = (event.take + event.skip) / event.take;
    this.skip = event.skip;
    this.getPaginatedList(0);
  }

  selectProposalOption(option: any) {
    this.proposalOption = option;
  }


  changeSelection(item: any) {
    item.isSelected = !item.isSelected;
    if ((this.mySelection.length > this.selectedArr?.length) && !item.isSelected) item.isSelected = !item.isSelected;
    else if ((this.mySelection.length < this.selectedArr?.length) && item.isSelected) item.isSelected = !item.isSelected;
    let index = this.wbaListArr?.wbalists.findIndex((res: any) => res.wbaId == item?.id);
    if (item.isSelected) {
      let obj = {
        wbaId: item?.id,
        lob: item?.shortCode,
        lobId: item?.masterBusinessLineId,
        transType: item?.transType
      }
      if (index == -1) this.wbaListArr?.wbalists.push(obj);
    } else {
      if (index > -1) this.wbaListArr?.wbalists.splice(index, 1);
    }
    if (this.wbaListArr?.wbalists.length == this.finalWBAList.data.length) this.isAllWbaSelected = true;
    if (this.wbaListArr?.wbalists.length == 0) this.isAllWbaSelected = false;
    this.selectedArr = this.mySelection
    console.log(item);
  }

  changeAllSelection() {
    let checkbox: any = document.querySelector('input[kendoGridSelectAllCheckbox]');
    let data = checkbox?.checked;
    this.isAllWbaSelected = data;
    this.selectedArr = this.mySelection
    this.finalWBAList.data.forEach((res: any) => {
      let index = this.wbaListArr?.wbalists.findIndex((e: any) => e.wbaId == res?.id);
      if (this.isAllWbaSelected) {
        res.isSelected = true;
        let obj = {
          wbaId: res?.id,
          lob: res?.shortCode,
          lobId: res?.masterBusinessLineId,
          transType: res?.transType
        }
        if (index == -1) this.wbaListArr?.wbalists.push(obj);
      } else {
        if (index > -1) this.wbaListArr?.wbalists.splice(index, 1);
      }
    })
    console.log('item');
  }

  onCellClick(event: any) {
    this.finalWBAList.data.forEach((res: any) => {
      res.isSelected = false
      let index = this.wbaListArr?.wbalists.findIndex((e: any) => e.wbaId == res?.id);
      if (index > -1) this.wbaListArr?.wbalists.splice(index, 1);
    });
    event.dataItem.isSelected = true;
    let obj = {
      wbaId: event.dataItem?.id,
      lob: event.dataItem?.shortCode,
      lobId: event.dataItem?.masterBusinessLineId,
      transType: event.dataItem?.transType
    }
    let index = this.wbaListArr?.wbalists.findIndex((res: any) => res.wbaId == event.dataItem?.id);
    if (index == -1) this.wbaListArr?.wbalists.push(obj);
    console.log(event.dataItem);
  }

  navigateToProposalSlide() {

    this.wbaListArr.prospectTier = this.wbaDetails.prospectSizeId;
    this.wbaListArr.proposalOption = this.proposalOption;
    this.wbaListArr.primaryWbaId = this.activatedWbaId;
    this.renewalList = this.wbaListArr.wbalists;
    this.renewalList?.forEach((x: any) => {
      let ele = this.finalWBAList.data.find((e: any) => e.id == x.wbaId);
      if (this.wbaDetails.id == x.wbaId) x.effectiveDate = this.datepipe.transform(this.wbaDetails.effectiveDate, 'MM/dd/yyyy');
      if (ele) x.effectiveDate = this.datepipe.transform(ele.effectiveDate, 'MM/dd/yyyy');
      if (ele) x.policyExpirationDate = this.datepipe.transform(ele.policyExpirationDate, 'MM/dd/yyyy');
    })
    if (!this.wbaDetails.prospectSizeId || this.wbaDetails.prospectSizeId == 0) {
      this._errorBox.message = "Prospect Tier is mandatory.";
      this._errorBox.isVisible = true;
      return
    }
    this._addWBAService.getProposalSlides(this.wbaListArr).subscribe(res => {
      this.showProposalSlide = true;
      res.sort((a: any, b: any) => (a.caffeinePageNumber - b.caffeinePageNumber));
      let noPkg = this.wbaListArr.wbalists.find((x: any) => x.lob != 'PKG');
      if (noPkg) {
        this.hasOnlyPackageLob = false;
      } else {
        this.hasOnlyPackageLob = true;
      }
      this.MasterCoverageListNew = res;
      let i = this.MasterCoverageListNew.findIndex((x: any) => x.displayName.toLowerCase().startsWith('coverage') && x.slideNumber !== 7200);
      this.MasterCoverageListNew = this.MasterCoverageListNew.filter((x: any) => !x.displayName.toLowerCase().startsWith('coverage') || x.slideNumber === 7200);
      if (i > -1) this.MasterCoverageListNew.splice(i, 0, {
        slideNumber: res[i].slideNumber,
        slideName: "Coverages",
        displayName: "Coverages",
        isInclude: this.hasOnlyPackageLob ? false : true,
        isStaticSlide: false
      })
      if (this.MasterCoverageListNew.length) this.openSlide(this.MasterCoverageListNew[0]);
      let slide = JSON.parse(JSON.stringify(this.MasterCoverageListNew));
      this.lastSlide = slide.pop();
      this.getProposalSlidesData();
    })
  }

  getProposalSlidesData() {
    let coverageAssessmentDetails = this._addWBAService.getCoverageAssessmentDetailsByPrimaryId(this.activatedWbaId);
    let proposalCoverageAssessments = this._addWBAService.getWbaProposalCoverageAssessmentsForPackageRN(this.activatedWbaId);
    let marketingSummaryList = this._addWBAService.getMarketingSummaryListByPrimaryId(this.activatedWbaId);

    forkJoin([coverageAssessmentDetails, proposalCoverageAssessments, marketingSummaryList]).subscribe(res => {
      this.getCoverageAssessmentDetailsByPrimaryId(res[0]);
      this.getWbaProposalCoverageAssessmentsForPackageRN(res[1]);
      this.getMarketingSummaryListByPrimaryId(res[2]);
      this.pricingPkgResponse = res[1];
      let hasSelectedWba = true;
      this.wbaListArr.wbalists.forEach((x: any) => {
        let selectedWba = this.proposalSlidesResponse?.wbalists.find((e: any) => e.wbaId == x.wbaId);
        if (!selectedWba) hasSelectedWba = false;
      })
      if (hasSelectedWba) {
        if (this.proposalSlidesResponse?.wbaProposals?.dynamicSlide?.length && this.proposalSlidesResponse?.wbaProposals?.staticSlide?.length) {
          this.setProposalData();
        }
      }
    })
  }

  getProposalSlidesByPrimaryWbaId() {
    this._addWBAService.getProposalSlidesByPrimaryWbaId(this.activatedWbaId).subscribe(res => {
      if (res?.wbalists) {
        this.proposalSlidesResponse = res;
        this.mySelection = res?.wbalists?.map((item: any) => item.wbaId)
        let data = JSON.parse(JSON.stringify(res));
        delete data.wbaProposals;
        this.wbaListArr = data;
        this.finalWBAList.data.forEach((x: any) => {
          let ele = this.wbaListArr.wbalists.find((e: any) => e.wbaId == x.id);
          if (ele) x.isSelected = true;
        })
        this.proposalOption = res.proposalOption;
        if (this.proposalOption == 'Proposal') {
          this.isProposalChecked = true;
          this.isCapabilitiesChecked = false;
        }
        else {
          this.isCapabilitiesChecked = true;
          this.isProposalChecked = false;
        }
      }
    })
  }

  getWbaProposalCoverageAssessmentsForPackageRN(res: any) {
    if (res?.length) {
      this.pricingPkgData = res;
      this.pricingPkgData = this.pricingPkgData.map((x: any) => x.coverageAssessmentDetail);

      // let pkgCoverageData = res.find((X: any) => X?.businessLineName == 'Package');
      this.setPricingPKGData(res);
    }
  }

  creatingRiskMgmtPersonalizedJSON() {
    let getPrimaryWBAServiceList = this.request.find((x: any) => x.wbaId == this.activatedWbaId)?.wbaService;
    this.claimServiceList = getPrimaryWBAServiceList?.filter((x: { groupName: any; }) => x.groupName == "CLAIMS REPORTING & MANAGEMENT")
    this.riskServiceList = getPrimaryWBAServiceList?.filter((x: { groupName: any; }) => x.groupName == "RISK CONTROL")

    let claimManagementArrLength = this.claimServiceList?.length;
    let riskServiceArrLength = this.riskServiceList?.length;
    let finalCount;
    if (claimManagementArrLength > riskServiceArrLength) finalCount = claimManagementArrLength; else finalCount = riskServiceArrLength
    for (let i = 0; i < finalCount; i++) {

      this.riskMgmtPersonilzed.push({
        rowNo: i + 1,
        rowType: '',
        'claimManagement': i + 1 <= claimManagementArrLength ? this.claimServiceList[i].serviceName : "",
        'riskControlServices': i + 1 <= riskServiceArrLength ? this.riskServiceList[i].serviceName : "",
      })
    }
  }

  checkStringValue(value: string): boolean {
    const containsAlpha = /[a-zA-Z]/.test(value);
    // Return true if the value contains at least one letter, otherwise false
    return containsAlpha;
  }

  getCoverageAssessmentDetailsByPrimaryId(res: any) {
    this.request = res;
    this.creatingRiskMgmtPersonalizedJSON();
    this.totalPremiumSummaryCoverage = [];
    this.totalPremiumSummaryGroups = { slideNumber: 600, tableData: [] };
    this.pricingWCRating = [];
    let row = 0;
    let wcCoverageData = this.request.find((X: any) => X.businessLineName == 'Workers Compensation');
    if (wcCoverageData) this.setPricingWcData(wcCoverageData);
    this.request.forEach((item: any) => {

      this.currentLineName = item?.policyType ? item?.policyType : item?.businessLineName
      let totalLineExpValue: any;
      let totalLineRecValue: any;
      let totalLineDiffValue: any;
      let hasRecord = false;
      if (this.currentLineName == 'Management Package' || this.currentLineName == 'Package') hasRecord = true;
      if (hasRecord) row += 1;
      item.coverageAssessmentDetail.forEach((cov: any) => {
        row += 1;
        this.currentDisplayName = cov.coverageName;
        cov.coverageParameters = cov.coverages.map((obj: any) => ({ ...obj, "highlights": false, "displayName": obj.parameterName, "selectRow": false }));
        cov.coverageParameters = cov.coverageParameters.filter((x: { valueType: string; }) => x.valueType !== "")
        // cov?.coverageParameters?.sort((a: { groupName: any; }, b: { groupName: any; }) =>
        //   a.groupName.localeCompare(b.groupName));
        // saved coverage of proposal
        cov.coverageParameters.forEach((param: any) => {
          if (!this.hasOnlyPackageLob && this.currentLineName != 'Package') {
            if (param.recommendedValue) param.selectRow = true;
          }
          if (param.valueType === "input-currency") {
            param.expiringValue = this._removeAmountPipe.transform(param.expiringValue);
            param.recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
            param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '$', '1.0-0');
            param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '$', '1.0-0');
          }
          else if (param.valueType === "input-number") {
            param.expiringValue = this._removeAmountPipe.transform(param.expiringValue);
            param.recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
            param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '', '1.0-0');
            param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '', '1.0-0');
          }
          else if (param.valueType === "dropdown,input-currency") {
            // if (!param.expiringValue.includes('$')) {
            //   // param.expiringValue = param.expiringValue.replaceAll('$', '').replaceAll(',', '');
            //   if (param.expiringValue && !isNaN(param.expiringValue)) {
            //     param.expiringValue = this._removeAmountPipe.transform(param.expiringValue);
            //     param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '$', '1.0-0');
            //   }
            // }
            // if (!param.recommendedValue.includes('$')) {
            //   // param.recommendedValue = param.recommendedValue.replaceAll('$', '').replaceAll(',', '');
            //   if (param.recommendedValue && !isNaN(param.recommendedValue)) {
            //     param.recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
            //     param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '$', '1.0-0');
            //   }
            // }
            if (!this.checkStringValue(param.expiringValue)) {
              param.expiringValue = this._removeAmountPipe.transform(param.expiringValue);
              param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '$', '1.0-0');
            }
            if (!this.checkStringValue(param.recommendedValue)) {
              param.recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
              param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '$', '1.0-0');
            }
          }
          //  else if (param.valueType === "dropdown,input-number") {
          //   param.expiringValue = this._removeAmountPipe.transform(param.expiringValue);
          //   param.recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
          //   param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '', '1.0-0');
          //   param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '', '1.0-0');
          // }
          else if (param.valueType === "input-date") {
            param.expiringValue = param.expiringValue == "N/A" ? null : this.datepipe.transform(param.expiringValue, 'MM/dd/yyyy');
            param.recommendedValue = param.recommendedValue == "N/A" ? null : this.datepipe.transform(param.recommendedValue, 'MM/dd/yyyy');
          }
          else if (param.valueType === "input-zip") {
            param.expiringValue = this.maskPipe.transform(param.expiringValue, '00000-0000||00000');
            param.recommendedValue = this.maskPipe.transform(param.recommendedValue, '00000-0000||00000');
          }
          else if (param.valueType === "input-ein") {
            param.expiringValue = this.maskPipe.transform(param.expiringValue, '00-0000000');
            param.recommendedValue = this.maskPipe.transform(param.recommendedValue, '00-0000000');
          }
          else if (param.valueType === "dropdown,input-percent" || param.valueType === "input-percent") {
            if (param.expiringValue && !isNaN(+param.expiringValue)) {
              if (+param.expiringValue > 100) {
                param.expiringValue = "100";
              }
              param.expiringValue = param.expiringValue + '%';
            }

            if (param.recommendedValue && !isNaN(+param.recommendedValue)) {
              if (+param.recommendedValue > 100) {
                param.recommendedValue = "100";
              }
              param.recommendedValue = param.recommendedValue + '%';
            }
          }
          else if (param.valueType === '' && param.groupName !== 'Price' && param.groupName
            === 'Carrier') {
            param.expiringValue = this.expiringCarrier;
          }
          else if (param.valueType === '' && param.groupName !== 'Price' && param.groupName
            === 'Additional Insureds') {
            param.expiringValue = this.prospectName;
          }
          else if (param.valueType === '' && param.groupName === 'Price') {
            param.expiringValue = this.expiringPremium;
          }
          if (param.displayName == 'Premium') {
            let covgName = this.currentDisplayName.split(' ').join('');
            if (item?.policyType) covgName = this.currentLineName.split(' ').join('');
            const controlName1 = 'exp' + item.wbaId + covgName;
            const controlName2 = 'rec' + item.wbaId + covgName;
            this.createTspFormControl(controlName1, controlName2)
            let exp = this._removeAmountPipe.transform(param.expiringValue);
            let rec = this._removeAmountPipe.transform(param.recommendedValue);
            let differenceValue: any = exp - rec;
            let effecDate = this.datepipe.transform(item.effectiveDate, 'MM/dd/yyyy');
            if (differenceValue) {
              differenceValue = this._amountPipe.transform(differenceValue, 'USD', '$', '1.0-0');
            }
            if (hasRecord) {
              if (param.expiringValue && isNaN(param.expiringValue)) {
                let expiringValue = this._removeAmountPipe.transform(param.expiringValue);
                totalLineExpValue = (+totalLineExpValue || 0) + +expiringValue;
              }

              if (param.recommendedValue && isNaN(param.recommendedValue)) {
                let recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
                totalLineRecValue = (+totalLineRecValue || 0) + +recommendedValue;
              }
            }

            if (differenceValue) {
              if (+exp > +rec) differenceValue = '-' + differenceValue;
            }


            this.totalPremiumSummaryCoverage.push({
              rowNo: row,
              rowType: hasRecord ? 'record' : 'group',
              line: this.currentLineName,
              coverageName: item?.policyType ? this.currentLineName : this.currentDisplayName,
              expiringValue: param.expiringValue,
              recommendedValue: param.recommendedValue,
              difference: differenceValue ? differenceValue : '$0',
              currentWbaId: item.wbaId,
              effectiveDate: effecDate,
              // currentCov: cov,
              isChecked: true,
              showEditIcon: true,
              expformControlName: controlName1,
              recformControlName: controlName2,
              id: param.id,
              coverageParameterId: param.coverageParameterId
            })
            this.totalPremiumSummaryGroups.tableData.push({
              rowNo: row,
              rowType: hasRecord ? 'record' : 'group',
              coverageName: item?.policyType ? this.currentLineName : this.currentDisplayName,
              expiringValue: param.expiringValue,
              recommendedValue: param.recommendedValue,
              difference: differenceValue ? differenceValue : '$0',
              line: this.currentLineName,
              currentWbaId: item.wbaId,
              effectiveDate: effecDate,
              // currentCov: cov,
              isChecked: true
            })
            this.proposalForm.get(controlName1)?.patchValue(param.expiringValue);
            this.proposalForm.get(controlName2)?.patchValue(param.recommendedValue);
          }
          if (param.displayName == 'Experience Mod') {
            this.expMode = param.expiringValue;
            this.expExpMode = param.expiringValue ? Number(param.expiringValue) : 0;
            this.recExpMode = param.recommendedValue ? Number(param.recommendedValue) : 0;
          }
        });
      });
      this.handleTotalPremiumSummary();

      if (this.request.coverageAssessmentDetail) {
        this.request.coverageAssessmentDetail.forEach((element: any) => {
          this.arrayForMasterServiceList.push(element.coverageId)
        })
      }
      this.handleCoverageCheckbox('both');

      if (item.coverageAssessmentDetail.length && hasRecord) {
        let index = this.totalPremiumSummaryGroups.tableData.findIndex((x: any) => x.line == this.currentLineName && x.currentWbaId == item.wbaId);
        let totalExpValue = totalLineExpValue;
        let totalRecValue = totalLineRecValue;
        let totalDiffValue = totalRecValue - totalExpValue;
        totalLineExpValue = totalLineExpValue ? "$" + this._numberPipe.transform(totalLineExpValue, 'USD', '1.0-0') : "$0";
        totalLineRecValue = totalLineRecValue ? "$" + this._numberPipe.transform(totalLineRecValue, 'USD', '1.0-0') : "$0";
        totalLineDiffValue = totalDiffValue ? "$" + this._numberPipe.transform(totalDiffValue, 'USD', '1.0-0') : "$0";
        if ((totalExpValue > totalRecValue) && totalLineDiffValue !== '$0') totalLineDiffValue = '-' + totalLineDiffValue;
        let effecDate = this.datepipe.transform(item.effectiveDate, 'MM/dd/yyyy');
        this.totalPremiumSummaryGroups.tableData.splice(index, 0, {
          rowNo: this.totalPremiumSummaryGroups.tableData[index]?.rowNo - 1,
          rowType: 'group',
          coverageName: this.currentLineName,
          expiringValue: totalLineExpValue,
          recommendedValue: totalLineRecValue,
          difference: totalLineDiffValue ? totalLineDiffValue : '$0',
          line: this.currentLineName,
          currentWbaId: item.wbaId,
          effectiveDate: effecDate,
          // currentCov: cov,
          isChecked: true
        })
      }
    });
    this.hasCovgAssessment = this.request.find((x: any) => x.coverageAssessmentDetail.length);
    if (this.hasCovgAssessment) {
      row += 1;
      this.totalPremiumSummaryGroups.tableData.push({
        rowNo: row,
        rowType: 'group',
        coverageName: 'Total Premium',
        expiringValue: this.totalPremiumExpiring,
        recommendedValue: this.totalPremiumRecommended,
        difference: this.totalPremiumDifferences,
        line: 'Total Premium',
        currentWbaId: this.activatedWbaId,
        // currentCov: cov,
        isChecked: true
      });
    }
  }

  handleTotalPremiumSummary() {
    this.totalPremiumSummaryCoverage?.forEach((param: any) => {
      if (param.expiringValue && isNaN(param.expiringValue)) {
        let expiringValue = this._removeAmountPipe.transform(param.expiringValue);
        this.totalPremiumExpiring = (+this.totalPremiumExpiring || 0) + +expiringValue;
      }

      if (param.recommendedValue && isNaN(param.recommendedValue)) {
        let recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
        this.totalPremiumRecommended = (+this.totalPremiumRecommended || 0) + +recommendedValue;
      }
    });

    // if (this.totalPremiumExpiring && +this.totalPremiumExpiring > 0) {
    // }

    // if (this.totalPremiumRecommended && +this.totalPremiumRecommended > 0) {
    // }

    let val1 = this.totalPremiumRecommended;
    let val2 = this.totalPremiumExpiring;
    this.totalPremiumExpiring = this.totalPremiumExpiring && +this.totalPremiumExpiring > 0 ? "$" + this._numberPipe.transform(this.totalPremiumExpiring, 'USD', '1.0-0') : "$0";
    this.totalPremiumRecommended = this.totalPremiumRecommended && +this.totalPremiumRecommended > 0 ? "$" + this._numberPipe.transform(this.totalPremiumRecommended, 'USD', '1.0-0') : "$0";
    let diff = val1 - val2;
    this.totalPremiumDifferences = diff ? "$" + this._numberPipe.transform(diff, 'USD', '1.0-0') : "$0";
    if ((val2 > val1) && this.totalPremiumDifferences !== '$0') this.totalPremiumDifferences = '-' + this.totalPremiumDifferences;
  }

  getMarketingSummaryListByPrimaryId(res: any) {
    let marketsummaryData = res;
    marketsummaryData.forEach((x: any) => {
      this.marketSummaryList.push(...x);
    })
    this.marketSummaryList = this.marketSummaryList.map((obj: any) => ({ ...obj, "selectRow": true }));
    this.marketSummaryList.forEach((element: any) => {
      element.quotedPremium = element.quotedPremium ? "$" + this._numberPipe.transform(element.quotedPremium, 'USD', '1.0-0') : "$0";
      if (element.notes?.length > 0) element.notes = element.notes.join('. ');
      element.notes = '';
    })
  }

  tpsCheckboxEvent(data: any) {
    data.isChecked = !data.isChecked;
    this.request.forEach((x: any) => {
      if (data.currentWbaId == x.wbaId) {
        let i = x.coverageAssessmentDetail.findIndex((z: any) => z.coverageId == data.currentCov.coverageId);
        if (!data.isChecked) {
          if (i > -1) x.coverageAssessmentDetail.splice(i, 1);
        } else {
          if (i == -1) x.coverageAssessmentDetail.push(data.currentCov);
        }
      }
    })
  }

  openNotesModal(data: any) {
    const modalRef = this.modalService.open(ProposalSummaryNotesComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    modalRef.componentInstance.summaryData = data;
    modalRef.closed.subscribe(resp => {
      if (resp) data.notes = resp;
    })
    console.log(data.notes);
  }

  getOwnerlist(result: any) {
    let data = {
      searchType: "Owner",
      value: result.query
    };
    // this.ownerDatalookupFilterData = result.query;
    this._addWBAService.getOwnerList(data).subscribe(resp => {
      this.ownerlist = resp.userList;
    })
  }

  selectItem(item: any, type: any) {
    if (type == 'ownerName') {
      this.proposalForm.get('thankyouCEName').patchValue({
        id: item.userId,
        value: item.userName
      });
    }
    if (type == 'ownerNameContact') {
      this.proposalForm.patchValue({
        contactName: {
          id: item.userId,
          value: item.userName
        },
        contactTitle: item.position,
        contactPhone: item.mobile,
        contactEmail: item.email,
        contactExt: item.extension,
        contactPicPath: item.userPicPath
      });
      // if (item.userPicPath) this.convertImage(item.userPicPath, 'contact');
    }
    if (type == 'ownerNameTitleSlide') {
      this.proposalForm.get('coverPagepresentedBy').patchValue({
        id: item.userId,
        value: item.userName
      });
    }
  }


  navigateBackToList() {
    if (this.showProposalSlide) {
      this.showProposalSlide = false;
      this.marketSummaryList = [];
      this.getProposalSlidesByPrimaryWbaId();
    } else {
      this.closeModal();
    }
  }

  setPricingPKGData(res: any) {
    this.pricingPackageGroups = { slideNumber: 4900, tableData: [] };
    this.pricingPKGRating = [];
    let row = 0;
    res.forEach((data: any) => {
      if (data.coverageAssessmentDetail?.length) {
        data.coverageAssessmentDetail.forEach((x: any) => {
          this.pricingPackageGroups.tableData.push({
            rowNo: ++row,
            rowType: 'group',
            coverageName: x.coverageName,
            parameterName: x.coverageName,
            expiringValue: '',
            recommendedValue: '',
          })
          x.coverages.forEach((y: any) => {
            this.pricingPackageGroups.tableData.push({
              rowNo: ++row,
              rowType: 'record',
              coverageName: y.parameterName,
              parameterName: y.parameterName,
              expiringValue: y.expiringValue,
              recommendedValue: y.recommendedValue,
              coverageParameterId: y.coverageParameterId,
              currentWbaId: data.wbaId
            })
          });
        });
      }
    })
  }

  setPricingWcData(data: any) {
    let payroll = data.coverageAssessmentDetail[0]?.workercompRating.reduce((total: any, x: any) => total + +x.payroll, 0);
    if (!payroll) payroll = 0;
    let expDate = this.datepipe.transform(data.policyExpirationDate, 'MM/dd/yyyy');
    let effecDate = this.datepipe.transform(data.effectiveDate, 'MM/dd/yyyy');
    if (this.wbaDetails.priorWbaId) this.getCoverageAssessmentDetailsByPriorWbaId(payroll);

    this.pricingWCRating.push({
      rowNo: 1,
      rowType: '',
      coverageName: data.businessLineName,
      renewalPolicy: `${effecDate} - ${expDate ? expDate : ""}`,
      expiringValue: data.expiringPremium ? this._amountPipe.transform(data.expiringPremium, 'USD', '$', '1.0-0') : '$0',
      annualPremium: '$ <>',
      recommendedValue: '$ <>',
      difference: '$ <>',
    });
    // this.pricingWCRating.push({
    //   rowNo: 2,
    //   rowType: 'record',
    //   coverageName: '',
    //   renewalPolicy: '',
    //   expiringValue: 'Payroll',
    //   annualPremium: 'Payroll',
    //   recommendedValue: 'Payroll',
    //   difference: '% change',
    // })
    this.pricingWCRating.push({
      rowNo: 2,
      rowType: '',
      coverageName: '',
      renewalPolicy: 'Total Payroll:',
      expiringValue: "0",
      annualPremium: payroll ? this._amountPipe.transform(payroll, 'USD', '$', '1.0-0') : '$0',
      recommendedValue: payroll ? this._amountPipe.transform(payroll, 'USD', '$', '1.0-0') : '$0',
      difference: payroll > 0 ? '100%' : '0%',
    })

    this.pricingWCRatingPremium = this.pricingWCRating[0];
    this.pricingWCRatingPayroll = this.pricingWCRating[1];
    this.totalWCRatingExpValue = this.pricingWCRating[1].expiringValue;
    this.totalWCRatingRecValue = this.pricingWCRating[1].recommendedValue;
    this.totalWCRatingDifference = this.pricingWCRating[1].difference;
  }

  getCoverageAssessmentDetailsByPriorWbaId(recPayroll: any) {
    this._addWBAService.getCoverageAssessmentDetails(parseInt(this.wbaDetails.priorWbaId)).subscribe((response: any) => {
      let expiringPayroll = response.coverageAssessmentDetail[0]?.workercompRating.reduce((total: any, x: any) => total + +x.payroll, 0);
      if (!expiringPayroll) expiringPayroll = 0;
      let difference = '0%';
      if ((recPayroll == 0 && expiringPayroll > 0) || (recPayroll > 0 && expiringPayroll == 0)) difference = '100%';
      if (recPayroll > 0 && expiringPayroll > 0) {
        //let value = Math.abs(expPayroll - recPayroll);
        let percentageDifference = ((recPayroll / expiringPayroll) - 1) * 100;
        difference = percentageDifference.toFixed(2) + "%";
      }
      this.pricingWCRating[1].expiringValue = expiringPayroll ? this._amountPipe.transform(expiringPayroll, 'USD', '$', '1.0-0') : '$0';
      this.pricingWCRating[1].difference = difference;
      this.pricingWCRatingPayroll = this.pricingWCRating[1];
      this.totalWCRatingExpValue = this.pricingWCRating[1].expiringValue;
      this.totalWCRatingDifference = this.pricingWCRating[1].difference;
    });
  }

  getDifferenceOfExpMode() {
    let difference = '0%';
    if ((this.expExpMode == 0 && this.recExpMode > 0) || (this.expExpMode > 0 && this.recExpMode == 0)) difference = '100%';
    if (this.expExpMode > 0 && this.recExpMode > 0) {
      //  let value = Math.abs(this.expExpMode - this.recExpMode);
      let percentageDifference = ((this.recExpMode / this.expExpMode) - 1) * 100;
      difference = percentageDifference.toFixed(2) + "%";
    }
    return difference;
  }

  generateProposal(slideType: string) {
    if (this.proposalForm.invalid) {
      this.proposalForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      this.isDisable = false;
      return;
    }
    else {
      this.isDisable = true;
      this.setApiPayload(slideType)
      this._addWBAService.generateProposalPPT(this.apiPayload).subscribe(res => {
        if (res) {
          if (res == 1) {
            this.show();
            this.route.navigateByUrl(`/whiteboard-activities/application?wbaId=${this.activatedWbaId}&listId=${this.listId}&prospectId=${this.prospectId}`);
          } else {
            this.showError();
          }
        } else {
          this.showError();
        }
      })
    }
  }

  public showError(): void {
    this.notificationService.show({
      content: "The Proposal request not generated successfully.",
      hideAfter: this.hideAfter,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 1000 },
      type: { style: "error", icon: true },
    });
  }

  public show(): void {
    this.notificationService.show({
      content: this.notificationTemplate,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 1000 },
      type: { style: "success", icon: true },
      hideAfter: this.hideAfter,
    });
  }

  createTspFormControl(controlName1: any, controlName2: any) {
    let formControl1: UntypedFormControl;
    let formControl2: UntypedFormControl;
    if (this.proposalForm.contains(controlName1)) {
      this.proposalForm.removeControl(controlName1);
    }
    if (this.proposalForm.contains(controlName2)) {
      this.proposalForm.removeControl(controlName2);
    }
    formControl1 = new UntypedFormControl();
    formControl2 = new UntypedFormControl();
    this.proposalForm.addControl(controlName1, formControl1);
    this.proposalForm.addControl(controlName2, formControl2);
  }

  saveTpsPremium(item: any) {
    item.showEditIcon = true;
    item.expiringValue = this.proposalForm.get(item.expformControlName)?.value;
    item.recommendedValue = this.proposalForm.get(item.recformControlName)?.value;
    if (!item.expiringValue) item.expiringValue = '$0';
    if (!item.recommendedValue) item.recommendedValue = '$0';
    let exp = parseInt(this._removeAmountPipe.transform(item.expiringValue));
    let rec = parseInt(this._removeAmountPipe.transform(item.recommendedValue));
    let differenceValue: any = exp - rec;
    differenceValue = differenceValue ? this._amountPipe.transform(differenceValue, 'USD', '$', '1.0-0') : '$0';
    if (differenceValue != '$0') {
      if (exp > rec) differenceValue = '-' + differenceValue;
    }
    item.difference = differenceValue;
    this.handleTotalPremiumSummary();
    this.editTotalPremiumSummaryPayload(item, differenceValue);
  }

  editTotalPremiumSummaryPayload(item: any, differenceValue: any) {
    let totalGroupExpiringValue: any;
    let totalGroupRecommendedValue: any;
    let totalGroupDifference: any;
    this.totalPremiumSummaryCoverage?.forEach((param: any) => {
      if (param.currentWbaId == item.currentWbaId) {
        if (param.expiringValue && isNaN(param.expiringValue)) {
          let expiringValue = this._removeAmountPipe.transform(param.expiringValue);
          totalGroupExpiringValue = (+totalGroupExpiringValue || 0) + +expiringValue;
        }

        if (param.recommendedValue && isNaN(param.recommendedValue)) {
          let recommendedValue = this._removeAmountPipe.transform(param.recommendedValue);
          totalGroupRecommendedValue = (+totalGroupRecommendedValue || 0) + +recommendedValue;
        }
      }
    });

    totalGroupDifference = totalGroupRecommendedValue - totalGroupExpiringValue;
    let totalExpValue = totalGroupExpiringValue;
    let totalRecValue = totalGroupRecommendedValue;
    if (totalGroupExpiringValue) totalGroupExpiringValue = totalGroupExpiringValue ? "$" + this._numberPipe.transform(totalGroupExpiringValue, 'USD', '1.0-0') : "$0";
    if (totalGroupRecommendedValue) totalGroupRecommendedValue = totalGroupRecommendedValue ? "$" + this._numberPipe.transform(totalGroupRecommendedValue, 'USD', '1.0-0') : "$0";
    totalGroupDifference = totalGroupDifference ? "$" + this._numberPipe.transform(totalGroupDifference, 'USD', '1.0-0') : "$0";
    if (totalGroupDifference != '$0') {
      if (totalExpValue > totalRecValue) totalGroupDifference = '-' + totalGroupDifference;
    }

    this.totalPremiumSummaryGroups.tableData?.forEach((x: any) => {
      if (x.currentWbaId == item.currentWbaId && x.coverageName == item.coverageName && x.rowType == 'record') {
        x.expiringValue = this.proposalForm.get(item.expformControlName)?.value;
        x.recommendedValue = this.proposalForm.get(item.recformControlName)?.value;
        x.difference = differenceValue;
      }
      if (x.currentWbaId == item.currentWbaId && x.coverageName == item.line && x.rowType == 'group') {
        x.expiringValue = totalGroupExpiringValue;
        x.recommendedValue = totalGroupRecommendedValue;
        x.difference = totalGroupDifference;
      }
      if (x.coverageName == 'Total Premium' && x.rowType == 'group') {
        x.expiringValue = this.totalPremiumExpiring;
        x.recommendedValue = this.totalPremiumRecommended;
        x.difference = this.totalPremiumDifferences;
      }
    });

    // update Coverage Screen Premium
    this.handleCoverageData(item);
    this.handleCoverageSummaryData(item);
  }

  handleCoverageData(item: any) {
    this.request?.forEach((x: any) => {
      if (item.currentWbaId == x.wbaId) {
        let selectedCoverage = x.coverageAssessmentDetail.find((z: any) => z.coverageId == item.id);
        if (selectedCoverage) {
          let selectedCoverageParameter = selectedCoverage.coverageParameters.find((z: any) => z.coverageParameterId == item.coverageParameterId);
          if (selectedCoverageParameter) {
            selectedCoverageParameter.expiringValue = item.expiringValue;
            selectedCoverageParameter.recommendedValue = item.recommendedValue;
          }
        }
      }
    })
  }

  handleCoverageSummaryData(item: any) {
    this.pricingPkgResponse?.forEach((x: any) => {
      if (item.currentWbaId == x.wbaId) {
        let selectedCoverage = x.coverageAssessmentDetail.find((z: any) => z.coverageId == item.id);
        if (selectedCoverage) {
          let selectedCoverageParameter = selectedCoverage.coverages.find((z: any) => z.coverageParameterId == item.coverageParameterId);
          if (selectedCoverageParameter) {
            selectedCoverageParameter.expiringValue = item.expiringValue;
            selectedCoverageParameter.recommendedValue = item.recommendedValue;
          }
        }
      }
    })
    this.getWbaProposalCoverageAssessmentsForPackageRN(this.pricingPkgResponse);
  }

  cancelTpsPremium(item: any) {
    item.showEditIcon = true;
    this.proposalForm.get(item.expformControlName)?.patchValue(item.expiringValue);
    this.proposalForm.get(item.recformControlName)?.patchValue(item.recommendedValue);
  }

  saveProposal(callFrom: any) {
    this.setApiPayload('Dynamic');
    let payload = {
      wbaProposal: {
        wbaId: this.activatedWbaId,
        slideType: null,
        proposalOption: null,
        staticSlide: null,
        dynamicSlide: null,
        coverages: null
      },
      layout: null
    }
    this._addWBAService.saveProposal(callFrom == 'save' ? this.apiPayload : payload).subscribe(res => {
      if (res) {
        if (callFrom == 'cancel') {
          this._confirmBox.isVisible = false;
          this.route.navigateByUrl(`/whiteboard-activities/application?wbaId=${this.activatedWbaId}&listId=${this.listId}&prospectId=${this.prospectId}`);
        }
      }
    })
  }

  setProposalData() {
    let formValue = this.proposalForm;
    this.MasterCoverageListNew.forEach((item: any) => {
      let data: any;
      if (item.isStaticSlide) data = this.proposalSlidesResponse.wbaProposals?.staticSlide.find((e: any) => e.slideName == item.displayName);
      if (!item.isStaticSlide) data = this.proposalSlidesResponse.wbaProposals?.dynamicSlide.find((e: any) => e.slideName == item.displayName);
      if (data) item.isInclude = data?.included;
      if (item?.slideName == 'Coverages') {
        if (item?.slideName == 'Coverages' && !this.proposalSlidesResponse.wbaProposals?.coverages?.length) item.isInclude = false;
        else item.isInclude = true;
      }
      if ((data?.slideNumber == 100 || data?.slideNumber == 101) && data?.included) {
        formValue.get('coverPageTitle').patchValue(data?.title);
        formValue.get('coverPageProspectName').patchValue(data?.prospectName);
        formValue.get('coverPagepresentedBy').patchValue({ value: data?.presentedBy });
      }
      if (item?.slideName == 'Coverages' && this.proposalSlidesResponse.wbaProposals?.coverages?.length) {
        this.proposalSlidesResponse?.wbaProposals.coverages.forEach((x: any) => {
          let index = this.request.findIndex((y: any) => y.wbaId == x.wbaId);
          if (index > -1) {
            this.request[index].coverageAssessmentDetail.forEach((e: any) => {
              let elem = x.coverageAssessmentDetail.find((z: any) => z.coverageName == e.coverageName);
              if (elem) {
                e.coverageParameters.forEach((y: any) => {
                  let param = elem.coverageParameters.find((z: any) => z.coverageParameterId == y.coverageParameterId);
                  if (param) {
                    y.selectRow = param.selectRow;
                    y.highlights = param.highLights;
                    // y.expiringValue = param.expiringValue;
                    // y.recommendedValue = param.recommendedValue;
                  } else {
                    y.selectRow = false;
                  }
                })
              }
            })
          }
        })
      }
      if (data?.slideNumber == 2300 && data?.included && data?.tableData?.length) {
        this.marketSummaryList.forEach((x: any) => {
          let elem = data?.tableData.find((y: any) => y.coverageName == x.coverageName && y.wholeSalerName == x.wholesalerName && y.carrierName == x.carrierName);
          if (elem) {
            x.notes = elem.comments;
          }
        })
      }
      if (data?.slideNumber == 4300 && data?.included) {
        formValue.get('thankyouOrgName').patchValue(data?.thankYouOrgName);
        formValue.get('thankyouCEName').patchValue({ value: data?.thankYouCEName });
      }
      if (data?.slideNumber == 4200) {
        formValue.get('contactName').patchValue({ value: data?.contactName });
        formValue.get('contactTitle').patchValue(data?.contactTitle);
        formValue.get('contactPhone').patchValue(data?.contactPhone);
        formValue.get('contactExt').patchValue(data?.contactExtension);
        formValue.get('contactEmail').patchValue(data?.contactEmail);
        formValue.get('contactPicPath').patchValue(data?.userPicPath);
      }
      if (data?.slideNumber == 2400 && data?.included && data?.tableData?.length) {
        this.yourServiceTeamList = data?.tableData;
      }
    })
  }

  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonProposalMessage;
    this._confirmBox.isVisible = true;
  }

  premiumSummaryInput(event: any, item: any, callFrom: any) {
    var numOnly = this._removeAmountPipe.transform(event.target.value);
    let numInCurrency = this._amountPipe.transform(numOnly, 'USD', '$', '1.0-0');
    if (callFrom == 'exp') this.proposalForm.get(item.expformControlName)?.patchValue(numInCurrency);
    if (callFrom == 'rec') this.proposalForm.get(item.recformControlName)?.patchValue(numInCurrency);
  }

  getProspectDetailTemp(pId: any, enableLoader: boolean = true) {
    if (pId) {
      this._addWBAService.getProspectById(pId, enableLoader).subscribe(resp => {
        if (resp && resp.prospectInfo) {
          this.prospectDetail = resp.prospectInfo;
        }
      });
    }
  }

  //to get Prospect Size  list
  getProspectTierList() {
    this._addWBAService.getProspectTierList().subscribe(resp => {
      this.prospectTierList = resp.prospectTierList;
      this.getWhiteBoardDetails(this.activatedWbaId);

    })
  }

}

