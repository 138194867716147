<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">


      <div class="add-hdr">
        <div class="heading">
          <h2>WBA Outcome</h2>
        </div>
        <div class="add-act">
          <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
            aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
      </div>
      <div class="row" *ngIf="showError">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
            <ul class="view-error">
              <li *ngFor="let err of errorList">{{err}}</li>
            </ul>
            <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
      <div class="add-content add-form">
        <div class="pdg-con">
          <form class="row g-3" [formGroup]="addBindRequestForm">
            <div class="add-form ">
              <div class="add-fld">
                <div class="add-title ">
                  <h3>Bind Request</h3>
                </div>
                <div class="row">
                  <div class="row frmrow">

                    <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                      <label class="form-label">Outcome <span class='star-color'></span></label>
                      <input formControlName="outcome" dataTextFocus [class.errorInput]="f.outcome.invalid && submitted"
                        class="form-control" placeholder="Outcome" type="text"
                        (focusout)="removeSpaces($event, f.outcome)" />

                    </div>



                    <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                      <label class="form-label">Reason <span class='star-color'>*</span></label>

                      <p-dropdown filterBy="label" [options]="reasonLostWbaList" [group]="true" [filter]="true"
                        formControlName="reason">
                        <ng-template let-group pTemplate="group">
                          <div class="flex align-items-center">
                            <span>{{ group.label }}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                      <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                          <span>{{ group.label }}</span>
                        </div>
                      </ng-template>
                      <!-- </select> -->
                      <div class="display-error" *ngIf="f.reason.invalid && submitted">
                        <div *ngIf="f.reason.invalid && submitted">
                          <span *ngIf="f.reason.errors?.required">
                            {{_addWbaScreen.reason.required}}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 mb-1" *ngIf="addBindRequestForm.value.reason == '21'">
                      <label class="form-label">Description <span class='star-color'>*</span></label>
                      <textarea rows="4" class="form-control" name="description" #description
                        formControlName="description"></textarea>

                      <div class="display-error" *ngIf="f.description.invalid && submitted">
                        <div *ngIf="f.description.invalid && submitted">
                          <span *ngIf="f.description.errors?.required">
                            {{_addWbaScreen.description.required}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                      <label class="form-label">Sub Producer<span class='star-color'></span></label>
                      <div class="flex-div">
                        <p-autoComplete placeholder="Sub Producer" class="form-control auto-comp"
                          [showEmptyMessage]="true" [suggestions]="accountManagerlist" field="value" [minLength]="1"
                          (onSelect)="selectItem($event.value,'accountManager')"
                          (onDropdownClick)="getCompanyList('accountManager',$event)"
                          (completeMethod)="getCompanyList('accountManager',$event)" formControlName="subProcedureName">
                          <ng-template let-account pTemplate="item">
                            <div class="company-item">
                              <div>{{ account.userName }}</div>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                        <img src="assets/images/search.svg" (click)="openLookupModal('AccountManager')" alt=""
                          class="ms-2" />

                      </div>

                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                      <label class="form-label">Sub Producer % <span class='star-color'></span></label>
                      <div>
                        <input (keypress)="decimalFilter($event)" formControlName="subProducer" class="form-control"
                          (change)="onChange($event)" placeholder="Sub Producer %" />
                      </div>

                      <div class="display-error" *ngIf="showPercentageValidation">
                        <span>
                          {{ _addWbaScreen.subProducer.range }}
                        </span>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                      <label class="form-label">Cross Sale<span class='star-color'></span></label>
                      <select class="select-cs form-select" placeholder="Cross Sale"
                        [class.errorInput]="f.crossSale.invalid && submitted" formControlName="crossSale">
                        <option [value]="true">Yes</option>
                        <option [value]="false" [selected]="isSelected">No</option>
                      </select>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6  mb-2  has-validation">
                      <label class="form-label">Was this an RFP? <span class='star-color'>*</span></label>
                      <select class="select-cs form-select" placeholder="Was this an RFP?"
                        [class.errorInput]="f.rfp.invalid && submitted" formControlName="rfp">
                        <option [value]="true">Yes</option>
                        <option [value]="false" [selected]="isSelected">No</option>
                      </select>
                    </div>

                  </div>
                </div>

                <div class="add-title ">
                  <h3>Competitor</h3>
                </div>
                <div class="row">
                  <div class="row frmrow">

                    <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 mb-2">
                          <label class="form-label">Broker Agent<span class='star-color'></span></label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 mb-2 text-right">
                          <input class="form-check-input" type="checkbox" formControlName="competitorAgentKnown">
                          <label class="form-check-label lb-non" for="flexCheckDefault">
                            Unknown
                          </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12 ">
                          <input class="form-control" placeholder="Broker Agent Name" type="email"
                            formControlName="brokerAgent" />
                        </div>
                      </div>


                    </div>

                    <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                      <div class="row align-items-center">
                        <div class="col-12 col-sm-12 col-md-6  mb-2">
                          <label class="form-label">Carrier<span class='star-color'></span></label>


                        </div>
                        <div class="col-12 col-sm-12 col-md-6 mb-2 text-right ">
                          <input class="form-check-input" type="checkbox" formControlName="competitorCarrierKnown">
                          <label class="form-check-label lb-non" for="flexCheckDefault">
                            Unknown
                          </label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                          <div class="flex-div">
                            <p-autoComplete class="form-control auto-comp" placeholder="Carrier "
                              formControlName="carrier" [minLength]="1" [showEmptyMessage]="true"
                              [suggestions]="expiringCarrierlist" (onSelect)="selectItem($event.value, 'expiringCarrier')"
                              (completeMethod)="getCompanyList('Carrier', $event)"
                              (onDropdownClick)="getCompanyList('Carrier', $event)" field="value">
                              <ng-template let-carrier pTemplate="item">
                                <div class="company-item">
                                  <div>{{ carrier.value }}</div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                            <img (click)="openLookupModal('Carrier')" src="assets/images/search.svg" alt=""
                              class="ms-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="blk-bt">
        <button class="btn btn-outline-primary ms-2 me-3" (click)="cancel()">Cancel</button>
        <button class="btn btn-primary"
          [disabled]="isSaveBtnDisable || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost'"
          (click)="saveWbaBindRequest()">Request to
          Bind</button>
      </div>
    </div>
    <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showHideBoundCarrierValidationPopUp"
      [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p>There is no QUOTED status of any carrier in the Marketing Summary.</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button (click)="showHideBoundCarrierValidationPopUp = false" label="OK"
          styleClass="btn btn-primary"></p-button>
      </ng-template>
    </p-dialog>
    <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
      [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p>{{_confirmBox.message}}</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
        <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
