<div class="modal-content" [ngClass]="{'maxWindow': isMaximized}">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Marketing Notes</h2>
                </div>
                <div class="add-act1">

                    <button class="mx-btn custom-btn me-2" (click)="toggleMaximize()">
                        <img *ngIf="!isMaximized" src="assets/images/maximize.svg" alt="Minimize">
                        <img *ngIf="isMaximized" src="assets/images/minimize.svg" alt="Maximize">
                    </button>

                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
            <div class="add-noti-form" [formGroup]="notesForm">
                <div class="note-dropdown d-flex card-header cl-hdr p-4 gap-2 align-items-end">
                    <div class="flex-grow-1">
                        <label class="form-label fw-bold">Carrier/Wholesaler</label>
                        <select class="form-select w-100" formControlName="carrier" #carrier
                            (change)="getCarrierValue(carrier.value)" placeholder="select carrier">
                            <option [value]="0">All Carriers</option>
                            <option *ngFor="let item of filteredCarrierArray"
                                [value]="item?.carrierId ? item?.carrierId : item?.wholersalerId">
                                {{item?.carrierName ? item?.carrierName : item?.wholesalerName}}</option>
                        </select>
                    </div>
                    <div class="flex-grow-1">
                        <label class="form-label fw-bold">Coverages</label>
                        <select class="form-select w-100" formControlName="coverage" #coverage
                            (change)="getCoverageValue(coverage.value)" placeholder="select coverage">
                            <option [value]="0">All Coverages</option>
                            <option *ngFor="let item of filteredCoverageArray" [value]="item?.coverageId">
                                {{item?.coverageName}}</option>
                        </select>
                    </div>
                    <!-- <div>
                        <button class="btn btn-primary" (click)="getMarketingNotes()"><img class="white-image"
                                src="assets/images/arrow.png" alt="arrow"></button>
                    </div> -->
                </div>

                <div class="add-content padding-bottom">
                    <div class="pdg-con" *ngFor="let item of marketingNotesResponse?.carriercoveragenotes">

                        <div class="add-title">
                            <h3 class="fw-bold">{{item?.carrierName}}</h3>
                        </div>

                        <div class="row" *ngFor="let coverageNote of item?.coverageNote">
                            <h4 class="fw-bold mb-2">{{coverageNote?.coverageName}}</h4>
                            <div class="add-fld" *ngFor="let notes of coverageNote?.note">
                                <blockquote class="blockquote">
                                    <p class="mb-2">{{notes?.note}} <img class="pointer d-flex"
                                            src="assets/images/edit.svg" (click)="editNotes(notes,coverageNote)"
                                            width="13px" alt="" class="me-2" /></p>
                                    <p class="text-muted notes-style">Created by -- {{notes?.createdBy}} -
                                        {{notes.createdDateTime+'Z' | date:'MM/dd/yyy hh:mm:ss a' }}</p>
                                    <p class="text-muted notes-style" *ngIf="notes?.modifiedBy">Modified by --
                                        {{notes?.modifiedBy}} -
                                        {{notes?.modifiedDateTime+'Z' | date:'MM/dd/yyy hh:mm:ss a' }}</p>
                                </blockquote>
                            </div>
                            <div class="mb-2" *ngIf="hideInputBox2">
                                <textarea rows="3" class="form-control mb-2" name="comments2"
                                    placeholder="Add/Edit Notes" #comments
                                    [formControlName]="'textArea'+coverageNote?.marketingSummaryId+coverageNote?.coverageId"
                                    (change)="payload(coverageNote,comments.value)"></textarea>
                                <!-- <button class="btn btn-primary"
                                    (click)="addANotesOnAllCarriers(coverageNote?.carrierId,coverageNote?.coverageId)">Save</button> -->
                            </div>
                        </div>

                    </div>
                </div>
                <div class="blk-bt">
                    <textarea rows="3" class="form-control mb-2 textarea-with-space" name="comments"
                        placeholder="Add/Edit Notes" formControlName="comments" *ngIf="hideInputBox1"
                        (focusout)="removeSpaces($event,f.comments)"></textarea>
                    <button [disabled]="!isValidUser" class="btn btn-primary"
                        (click)="addMarketingNotes()">Save</button>
                </div>
            </div>
            <!-- Dialog for confirmation -->
            <p-dialog [header]="!emptyComments ? _confirmBox.header :  _confirmBox.header2" [modal]="true"
                [(visible)]="_confirmBox.isVisible" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}"
                [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                <p>{{_confirmBox.message}}</p>
                <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                    <p-button *ngIf="!emptyComments" (click)="_confirmBox.isVisible = false" label="No"
                        styleClass="btn btn-outline-primary">
                    </p-button>
                    <p-button *ngIf="!emptyComments" (click)="confirmBoxCancel()" label="Yes"
                        styleClass="btn btn-primary"></p-button>
                    <p-button *ngIf="emptyComments" (click)="warningBoxCancel()" label="OK"
                        styleClass="btn btn-primary"></p-button>
                </ng-template>
            </p-dialog>
        </div>
    </div>
</div>