<div class="main-body-con">
    <div class="page-container">
        <div class="mid-con  px-4">
            <div class="pro-detail no-round-border" id="add-wba-view">

                <div class="wh-view">
                    <div class="pro-right-header">
                        <div class="pro-name-city">
                            <h2>Policy Information</h2>
                        </div>
                        <div class="pro-action">
                            <div class="text-end flex-div">
                                <a href="javascript:void(0)" (click)="closeModal()" title="close" class="closebtn"><img
                                        src="assets/images/close.svg" alt="" /></a>
                            </div>
                        </div>
                    </div>

                    <div class="wh-view-detail">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                                <div class="row align-items-center" *ngIf="policy">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-2">
                                        <h3 class="pe-3 compn"> Policy Number - {{this.wbaPolicyId}}
                                        </h3>
                                    </div>
                                    <div class="row row-cols-7 ">
                                        <div class="col-auto datecol">
                                            <span>Line Type Code</span>
                                            <p>{{policy.policyTypeCode}}</p>
                                        </div>
                                        <div class="col-auto datecol">
                                            <span>Line of Business</span>
                                            <p pTooltip="Line of Busieness" tooltipPosition="bottom"
                                                tooltipStyleClass="tooltipstyle">
                                                {{businessLineName}}</p>
                                        </div>
                                        <div class="col ">
                                            <span>Effective Date</span>
                                            <p>{{policy.effectiveDate | date:'MM/dd/yyyy'}}</p>
                                        </div>

                                        <div class="col">
                                            <span>Expiration Date</span>
                                            <p>{{policy.expirationDate | date:'MM/dd/yyyy'}}</p>
                                        </div>


                                        <div class="col">
                                            <span>Account Manager</span>
                                            <div class="cr-wd">
                                                <p pTooltip="Account Manager" tooltipPosition="bottom"
                                                    tooltipStyleClass="tooltipstyle">
                                                    {{accountManagerName ? accountManagerName: " N/A"}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <span>Producer</span>
                                            <div class="cr-wd">
                                                <p pTooltip="Account Manager" tooltipPosition="bottom"
                                                    tooltipStyleClass="tooltipstyle">
                                                    {{producerName ? producerName: "N/A"}}</p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <span>Premium</span>
                                            <div class="cr-wd">
                                                <p> {{policy.estimatedPremium ? (policy.estimatedPremium | currency :
                                                    'USD' : '$' :'1.0-0'): ""}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 ">
                                <div class="whContactView ps-5 pe-3 descr">
                                    <h3 class="pe-3 compn">Policy Description</h3>

                                    <p class="pt-2">
                                        {{policy?.description}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card acDetail">
                    <div class="card-header">
                        <h2>Account Details</h2>
                    </div>
                    <div class="card-body py-3">
                        <div class="row ">
                            <div class="col-12 col-sm-12 col-md-4 ">
                                <div class="bdr-right">
                                    <h3>{{account}}</h3>

                                    <h3 class="mt-3">Address</h3>
                                    <p *ngIf="accountAddress">{{accountAddress.street1}}, {{accountAddress.street2 ?
                                        accountAddress.street2 + ", "
                                        :""}}{{accountAddress.street3?accountAddress.street3 + ",
                                        ":""}}{{accountAddress.city}}, {{accountAddress.stateOrProvinceCode}},
                                        {{accountAddress.zipOrPostalCode | mask: "00000-0000||00000"}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-8"
                                *ngIf="contact?.firstName !== '' && contact?.lastName !== '' && contactinfo?.phone1Number !== '' && contactinfo?.email1 !== ''">
                                <h3>Primary Contact Details</h3>
                                <div class="row row-cols-4 mt-3 pcd">
                                    <div class="col ">
                                        <div class="d-flex align-items-center">
                                            <img src=" assets/images/r1.png" alt="">
                                            <span>{{contact?.firstName}}
                                                {{contact?.lastName}}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/r3.png" alt="">
                                            <span>{{contactinfo?.phone1Number ?
                                                (contactinfo?.phone1Number | mask : '(000)
                                                000-0000'):''}}</span>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center">
                                            <img src=" assets/images/r4.png" alt="">
                                            <span>{{contactinfo?.email1}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card acDetail mt-3">
                    <div class="card-header">
                        <h2>Lines</h2>
                    </div>
                    <div class="card-body py-3">
                        <div class="row row-cols-5 liens-des">
                            <ng-container *ngFor="let line of lineArray">
                                <div class="col ">
                                    <span>{{line.lineTypeCode}}</span>
                                    <p>{{line.lineTypeDescription}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="btn-blk1">
                    <button class="btn btn-outline-primary me-3" (click)="closeModal()">Cancel </button>
                    <button class="btn btn-primary" [disabled]="isRenewButtonDisable"
                        (click)="createwbaRenewpolicy()">{{checkValidationPopup ? 'Renew' :
                        'Create Renewal Whiteboard'}}</button>
                </div>
            </div>
            <!-- Dialog for error -->
            <p-dialog [header]="_errorBox.header" [modal]="true" [(visible)]="_errorBox.isVisible"
                [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
                [styleClass]="_errorBox.borderColor" *ngIf="_errorBox">
                <p>{{_errorBox.message}}</p>
                <ng-template pTemplate="footer">
                    <p-button (click)="errorBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                </ng-template>
            </p-dialog>

            <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
                [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
                [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                <p>{{ _confirmBox.message }}</p>
                <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                    <p-button (click)="confirmBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                </ng-template>
            </p-dialog>

            <p-dialog [header]="_confirmBoxForRenew.header2" [modal]="true" [(visible)]="_confirmBoxForRenew.isVisible"
                [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
                [styleClass]="_confirmBoxForRenew.borderColor" *ngIf="_confirmBoxForRenew">
                <p>{{ _confirmBoxForRenew.message }}</p>
                <ng-template pTemplate="footer" *ngIf="_confirmBoxForRenew.hasFooter">
                    <p-button (click)="this._confirmBoxForRenew.isVisible = false" label="OK"
                        styleClass="btn btn-outline-primary">
                    </p-button>
                </ng-template>
            </p-dialog>
        </div>
    </div>