export class EmailTemplateMessage {
    mailSubject = {
        required: 'Email Subject is required.',
        invalid: 'Invalid Email Subject.'
    }

    mailTemplate = {
        required: 'Email Body is required.'
    }
    
    mailAttachmentDocType = {
        required: 'Document Type is required.'
    }  
}


