export class AddWBAScreen {
    companyName = {
        required: 'Company Name is required.',
        maxlength: 'Company Name should not exceed more than 200 characters.',
        invalid: 'Invalid Company name.',
        companyNameInvalid: 'Company Name is Invalid.'
    }

    status = {
        required: 'Business Status is required.'
    }

    jobTitle = {
        maxlength: 'Job Title should not exceed more than 200 characters.',
    }


    industry = {
        required: 'Industry is required.'
    }

    size = {
        required: 'Tier is required.'
    }
    renewalStatus = {
        required: 'Renewal Status is required.'
    }
    ein = {
        length: 'EIN should be of 9 digit.',
        invalid: 'Please enter a valid employee identification number for this prospect.',
        required: 'EIN is required.'
    }

    line = {
        required: 'Business Line is required.'
    }

    policyType = {
        required: 'Policy Type is required.'
    }

    marketing = {
        required: 'User Marketing is required.'
    }

    effDate = {
        required: 'Effective Date is required.',
        formate: 'Effective Date is invalid.'
    }

    boundRevenue = {
        formate: 'Fill only number formate.',
        required: 'Bound Revenue is required.',
        length: 'Bound Revenue should be greater than zero.'
    }

    firstName = {
        required: 'First Name is required',
        maxlength: 'First Name should not exceed more than 200 characters.'
    }

    lastName = {
        required: 'Last Name is required.',
        maxlength: 'Last Name should not exceed more than 200 characters.'
    }

    phoneNumber = {
        mask: 'Invalid Phone number.',
        required: 'Phone Number is required.'
    }

    targetPremium = {
        required: 'Target Premium is required.',
        nonZero: 'It should be greater than 0'
    }

    email = {
        pattern: 'Invalid email.',
        required: 'Email is required.',
        maxlength: '“Email Address should not exceed more than 60 characters.'
    }

    extension = {
        required: 'Please enter a phone number.',
        maxlength: 'Extension should not exceed more than 5 characters.',
        minlength: 'Extension should not be less than 5 characters.'
    }

    priority = {
        required: 'Priority is required.',
    }

    leadGenerator = {
        required: 'Lead Generator is required.',
        leadgenratorInvalid: 'Lead Generator is Invalid. '
    }

    accountManager = {
        accountManagerInvalid: 'Account Manager is Invalid. '
    }

    marketingManager = {
        marketingManagerInvalid: 'Marketing Manager is Invalid. '
    }

    expiringCarrier = {
        required: 'Expiring Carrier is required.',
        expringInvalid: 'Expiring Carrier is Invalid'
    }

    expiringPremium = {
        required: 'Expiring Premium is required.',
        nonZero: 'It should be greater than 0'
    }

    expiringRevenue = {
        required: 'Expiring Revenue is required.',
    }
    targetRevenue = {
        required: 'Expected Bound Revenue is required.',
        nonZero: 'It should be greater than 0'
    }

    owner = {
        required: 'Owner is required.',
        ownerInvalid: 'Owner Name is Invalid '
    }
    reason = {
        required: 'Reason is required.',
    }
    description = {
        required: 'Description is required.',
    }

    result = {
        required: 'Result is required.',
    }

    rfp = {
        required: 'RFP is required.'
    }

    subProducer = {
        required: 'Sub Producer % is required.',
        range: 'Sub Producer % should not be more than 100 %'
    }
    boundCarrier = {
        required: 'Bound Carrier is required.',
    }

    boundPremium = {
        required: 'Bound Premium is required.',
        length: 'Bound Premium should be greater than zero.'
    }

    carrierCommission = {
        required: 'Carrier Commission is required.',
        range: 'Carrier Commission % should not be more than 100 %'
    }

    boundPolicyNumber = {
        required: 'Bound Policy Number is required.',
    }
    policyExpirationDate = {
        required: 'Policy Expiration Date is required.',
    }



    initializeBOR = {
        required: 'This field is required.',
    }
    executionStrategy = {
        required: 'This field is required.',
    }
    renewalPlan = {
        required: 'This field is required.',
    }
    sdRsmDropdowns = {
        required: 'This field is required.',
    }
    progressId = {
        required: 'Progress is required.',
    }
    specialServiceNotes = {
        required: 'Special Service Notes is required.'
    }

    effectiveDate = {
        required: 'Effective Date is required.',
        formate: 'Effective Date is invalid.'
    }
}