import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddprospectScreen } from 'src/app/features/prospect/error-message/addprospect-screen';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { NoteService } from 'src/app/features/prospect/services/note.service';

@Component({
  selector: 'app-wba-edit-note',
  templateUrl: './wba-edit-note.component.html',
  styleUrls: ['./wba-edit-note.component.css']
})
export class WbaEditNoteComponent implements OnInit {

  prospectId: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() wbaNoteData: any;
  editNoteForm: any;
  isSubmitted: boolean = false;
  isDisabled: boolean = true;
  _AddNoteScreen: AddprospectScreen;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  showError: boolean = false;
  errorList: any[] = [];
  errorMessage: any;
  apiResponse: any;
  finalApiResponse: any;
  topicListValue: any;

  constructor(
    public _formBuilder: UntypedFormBuilder,
    public _activeModal: NgbActiveModal,
    private _viewProspectNotifireService: CustomNotifireService,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public datepipe: DatePipe,
    public _whiteBoardActivityService: WhiteBoardActivityService,
    public _noteService: NoteService,
  ) {
    this._AddNoteScreen = new AddprospectScreen();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this.getFormControl();
  }

  ngOnInit(): void {
    this.setFormValue(this.wbaNoteData);
    this.getTopicValue();
  }

  //topic dropdown
  getTopicValue() {
    this._noteService.getTopicValue().subscribe(resp => {
      this.topicListValue = resp.wbaTopic;
    })
  }
  get f() {
    return this.editNoteForm.controls;
  }

  getFormControl() {
    this.editNoteForm = this._formBuilder.group({
      id: [{ value: '' }, Validators.required],
      wbaId: [{ value: '' }, Validators.required],
      description: [
        { value: '' },
        [Validators.required, Validators.maxLength(6000)],
      ],
      topic: [''],
    });

  }

  // form value set using patch value
  setFormValue(resp: any) {
    this.editNoteForm.patchValue({
      id: resp.id,
      wbaId: resp.wbaId,
      description: resp.description,
      topic: resp.topic,
    });
  }

  // Edit Note method
  editNote() {
    this.isSubmitted = true;
    let data = this.editNoteForm.value;
    let id = this.wbaNoteData.id;
    if (this.editNoteForm.valid) {
      var saveData = {
        id: data.id,
        wbaId: data.wbaId,
        description: data.description,
        topic: data.topic,
      };
      this._whiteBoardActivityService.editWbaNotes(saveData, id).subscribe(
        (resp: any) => {
          this.passEntry.emit('Edit WBA Note');
          this.closeModal();
        },
        (err: any) => {
          if (err.status !== 200) {
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorList = [];
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            } else {
              this.showError = true;
              this.errorMessage = err.message;
              this.errorList.push(this.errorMessage);
            }
            err.message;
          }
        }
      );
    }
  }

  //to cancel the process and back to view prospect screen
  cancel() {
    if (this.editNoteForm.dirty) {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    } else {
      this.closeModal();
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.closeModal();
  }

  closeModal() {
    this._activeModal.close();
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(
      element.target.value,
      ''
    );
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  closeError() {
    this.showError = false;
  }

}
