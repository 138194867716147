import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';

@Component({
  selector: 'app-account-management-services',
  templateUrl: './account-management-services.component.html',
  styleUrls: ['./account-management-services.component.css']
})
export class AccountManagementServicesComponent implements OnInit {
  @Input() public tabName: any;
  @Output() public nextTab: any = new EventEmitter<string>();
  constructor(private _router: Router) {
    this._navigationPath = new NavigationPath();
  }

  _navigationPath: NavigationPath;
  ngOnInit(): void {
    window.scroll(0, 0);
  }
  saveWBA(action: string) {
    if (action === 'next') this.nextTab.emit(this.tabName)
    if (action === 'exit') this._router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl());
  }
}
