<div class="mid-con">
    <div class="tab-content py-0">
        <div class="tab-pane fade show active">
            <div class="pro-right-con">
                <div class="row">
                  <div class="col-12 wbaHeadding">
                    <div class="hd-width">
                      <h2>Account Manager</h2>
                      <a class="email-btn me-4" (click)="openeEmailTemplateModal()"
                                    [ngClass]="{'isDisabled': isAssesmentParamsHasMandatoryValues}">
                                    <img src="assets/images/email-s.png" alt="Submissions Email" />Submissions Email
                                </a>
                      </div>
                      </div>
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="card-text row addw" id="tb-h">
                        <div class="row frmrow add-wba-form my-2">
                            <form>
                                <!-- <div>
                                    <h6><label for="Review Status" style="color: #122B74;"
                                            class="fw-bold mb-3">Review
                                            Status</label>
                                    </h6>
                                </div> -->
                                <table class="table wba-tbl" id="response" *ngIf="acordWBA">
                                    <thead class="table wba-tbl">
                                        <tr>
                                            <th>Account/WBA</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of AcordResponse ">
                                            <td>{{item.requestType}}</td>
                                            <td>
                                                <div class="flex-div">
                                                    <img *ngIf="item.requestStatus=='Send To Marketing'"
                                                        src="assets/images/requested.svg" alt=""
                                                        class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Pending'"
                                                        src="assets/images/pending.svg" alt=""
                                                        class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Incomplete Information'"
                                                        src="assets/images/incomplete-info.svg"
                                                        alt="Incomplete Information" class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Incomplete Contact'"
                                                        src="assets/images/incomplete-contact.svg"
                                                        alt="Incomplete Contact" class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Send To Account Manager'"
                                                        src="assets/images/not-needed.svg" alt="Not Needed"
                                                        class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Completed'"
                                                        src="assets/images/completed.svg" alt=""
                                                        class="me-2" />

                                                    <img *ngIf="item.requestStatus=='Approved'"
                                                        src="assets/images/approved.svg" alt="Approved"
                                                        class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Denied'"
                                                        src="assets/images/denied.svg" alt="denied"
                                                        class="me-2 " />
                                                    <img *ngIf="item.requestStatus=='Incomplete'"
                                                        src="assets/images/incomplete.svg" alt="Incomplete"
                                                        class="me-2 " />
                                                    {{item.requestStatus}}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="flex-div"><img
                                                        *ngIf="item.requestStatus!='Pending'"
                                                        src="assets/images/calendar.svg"
                                                        class="me-2" />{{item.requestDate | date}}</div>
                                            </td>
                                            <td>
                                                <div class="flex-div"><img
                                                        *ngIf="item.requestStatus!='Pending'"
                                                        src="assets/images/user.svg" alt=""
                                                        class="me-2" />{{item.createdByUserName}}</div>
                                            </td>
                                        </tr>
                                        <tr class="pending" *ngIf="marketingReview">
                                            <td>Marketing Review</td>
                                            <td>
                                                <div class="flex-div"><img src="assets/images/pending.svg"
                                                        alt="" class="me-2" /> Pending
                                                </div>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr *ngIf="SDRSMapproval">
                                            <td colspan="4" class="expandRow">
                                                <div class="expandRow-con ">
                                                    <div class="row" *ngIf="rsmApprovalDropdowns">
                                                        <div class="col-12 col-sm-12 col-md-6"
                                                            *ngFor="let item of notBOR">
                                                            <div class="form-group row align-items-center">
                                                                <label class="form-label me-4 col-2"
                                                                    [for]="item?.fileType">{{item?.fileType}}</label>
                                                                <div class="select-div col-4">
                                                                    <select class="form-select l-3"
                                                                        disabled>
                                                                        <option [value]="item?.fileStatus">
                                                                            {{ item?.fileStatus }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row wba-sec">
                                                        <div class="col-12 col-sm-12 col-md-6 ">
                                                            <div class="form-group row ">
                                                                <label class="form-label me-4 col-2"
                                                                    for="WBAapproval">WBA Approval</label>
                                                                <div class="select-div col-4">
                                                                    <select class="form-select l-3" #wba
                                                                        id="wba_approval"
                                                                        (change)="setWbaApproval(wba.value)">
                                                                        <option value="none" selected
                                                                            disabled hidden>Select</option>
                                                                        <option
                                                                            *ngFor="let option of wba_approval"
                                                                            [value]="option.label">
                                                                            {{ option.label }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-sm-12 col-md-6 ">
                                                            <div class="form-group row ">
                                                                <label class="form-label me-4 col-2"
                                                                    for="ProfitCenter">Profit
                                                                    Center</label>
                                                                <div class="select-div col-4">
                                                                    <select class="form-select l-3"
                                                                        [ngClass]="{'isDisabled': showwbaprofitCenter}"
                                                                        #ProfitCenter id="wba_profitCenter">
                                                                        <option
                                                                            *ngFor="let option of wba_profitCenter"
                                                                            [value]="option.label"
                                                                            [selected]="SelectedvalueforProfitCenter == option.label">
                                                                            {{ option.label }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div *ngIf="selectWbaApproval=='Denied'"
                                                            class="col-12 col-sm-12 col-md-6">
                                                            <div class="form-group row align-items-center">
                                                                <label class="form-label me-4 col-2"
                                                                    for="Review">Comment</label>
                                                                <div class="select-div col-4">
                                                                    <select class="form-select l-3" #review
                                                                        id="Review"
                                                                        (change)="setComments(review.value)">
                                                                        <option value="none" selected
                                                                            disabled hidden>Select</option>
                                                                        <option
                                                                            *ngFor="let item of reasonResponse"
                                                                            [value]="item.reason">
                                                                            {{ item.reason }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div
                                                            class="mt-2 col-12 col-sm-12 col-md-12 text-right">
                                                            <button class="btn btn-primary"
                                                                (click)="updateAccessReview()"
                                                                [disabled]="isDisabled || isAssesmentParamsHasMandatoryValues">Submit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- WBA Table -->
                                <table class="table wba-tbl" id="response" *ngIf="wbaReviewTable">
                                    <thead class="table wba-tbl">
                                        <tr>
                                            <th>WBA Review Status</th>
                                            <th>Reminder Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of wbaResponse ">
                                            <td>{{item.wbaReviewStatus}}</td>
                                            <td>{{item.reminderDays == 0 ? 'N/A' : item.reminderDays}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                            <p-dialog [header]="_warningBox.header2" [modal]="true"
                                [(visible)]="_warningBox.isVisible" [breakpoints]="{ '960px': '75vw' }"
                                [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
                                [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
                                <p>{{ _warningBox.message }}</p>
                                <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                                    <p-button (click)="warningBoxCancel()" label="OK"
                                        styleClass="btn btn-primary"></p-button>
                                </ng-template>
                            </p-dialog>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
