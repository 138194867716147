
import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegxConstant } from 'src/app/shared/class/regx';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { environment } from 'src/environments/environment';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { reasonLostWbaList } from 'src/app/features/prospect/interfaces/criteria.model';
import { LookupCarrierComponent } from '../lookup-carrier/lookup-carrier.component';
import { DatePipe } from '@angular/common';
import { LookupManagerComponent } from '../lookup-manager/lookup-manager.component';
import { ActivatedRoute } from '@angular/router';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { DatacacheService } from '../../../services/datacache.service';
import {stringEmpty} from "../../../../../shared/utils";

@Component({
  selector: 'app-wba-bound',
  templateUrl: './wba-bound.component.html',
  styleUrls: ['./wba-bound.component.css']
})
export class WbaBoundComponent implements OnInit {
  @Input() progresStatus: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  addBoundForm: any;
  _regxConstant: RegxConstant;
  _addWbaScreen: AddWBAScreen;
  selectedPKGTier: any;
  selectedWCTier: any;
  selectedType: any;
  submitted: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  wcTierList: any[] = [];
  wcTierList$!: Observable<any>;
  pkgTierList$!: Observable<any>;
  typeList$!: Observable<any>;
  saveCarrier$!: Observable<any>;
  pkgTierList: any[] = [];
  typeList: any[] = [];
  wbaSubmissionCarriers: any = [];
  showError: boolean = false;
  _navigationPath: NavigationPath;
  unsubscribe$: Subject<boolean> = new Subject();
  @Output() messageEvent = new EventEmitter<string>();
  unAthorizesd: any = '';
  WBAId: any;
  epicStatus: any = [];
  reasonDetailofLost: any = [];
  reasonLostWbaList: reasonLostWbaList[] = [];
  resultofWba: any;
  wbaId: number = 0;
  defaultValue = 'No';
  isSelected: boolean = true;
  resultSelectedBoundvalue: boolean = false;
  resultSelectedNotBoundvalue: boolean = false;
  lookupData: any;
  lookupcomponent: any;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterCarrierList: any[] = [];
  expiringCarrierlist: any;
  outComeDetailFinish: any;
  expectBound: any;
  quotedFind: any;
  boundPremium: any;
  isSaveBtnDisable: boolean = false;
  showHideBoundCarrierValidationPopUp: boolean = false;
  accountManagerlist: any;
  showPercentageValidation: boolean = false;
  showCarrierPercentageValidation: boolean = false;
  selectedReasonId: any;
  reason: any;
  showDialogBoxFOrBindRequestOrNot: boolean = false;
  showDialogBoxForNoAccountManager: boolean = false;
  wbaProgressChange: any;
  invalidBoundCarrier: boolean = false;
  wbaDetails: any;
  isValidUser: boolean = true;
  wbaEpicPolicyDetailList: any;
  isBoundRequest: boolean = false;
  AcordPermissionResponse: any;

  constructor(
    public activeModal: NgbActiveModal,
    private _carriersService: CarriersService,
    private _loaderService: LoaderService,
    public ValidateKey: KeyboardValidation,
    private fb: UntypedFormBuilder,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public profileInfoService: ProfileInfoService,
    private _addWBAService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private _wbaService: WhiteBoardActivityService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private activeRouter: ActivatedRoute,
    public _amountPipe: CustomeAmountPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    private _dataCacheService: DatacacheService,
    private _getWbaActivityService: WhiteBoardActivityService,

  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._regxConstant = new RegxConstant();
    this._addWbaScreen = new AddWBAScreen();
  }
  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.wbaId = params.wbaId
      });

    this.resultofWba = sessionStorage.getItem("wbaforResult");
    this.getFormControl();
    this.getReasonList('Bound')
    //this.setFormValue();
    this.removeDescriptionValidator();
    this.getWhiteBoardDetailsFromApi(this.wbaId);
    setTimeout(() => {
      this.getAccordPermissionByWbaId();
    }, 1000);

  }

  getWhiteBoardDetailsFromApi(Id: any) {
    this._wbaService.getWBAById(Id).subscribe(resp => {
      if (resp && resp.whiteBoardActivityInfo) {
        this.wbaDetails = resp.whiteBoardActivityInfo;
        if (!this.wbaDetails.accountManagerUserId) {
          this.showDialogBoxForNoAccountManager = true;
        }
        this.getOutComeDetail(this.wbaId)
      }
    })
  }

  removeDescriptionValidator() {
    this.addBoundForm.get("reason").valueChanges.subscribe((x: any) => {
      this.selectedReasonId = x
      let description = this.addBoundForm.get('description');
      this.reason = this.reasonDetailofLost?.find((res: any) => res?.reason == "Other")
      if (this.reason.id == x) {
        if (!this.outComeDetailFinish?.description) {
          description.setValue('');
        } else {
          description.setValue(this.outComeDetailFinish.description);
        }
        description.setValidators([Validators.required]);
      } else {
        description.clearValidators();
      }
      description.updateValueAndValidity();

    })
  }

  getFormControl() {
    this.addBoundForm = this.fb.group({
      outcome: ['Bound'],
      reason: ['', [Validators.required]],
      boundCarrier: ['', [Validators.required]],
      boundPremium: ['', [Validators.required]],
      boundRevenue: ['', [Validators.required]],
      carrierCommission: ['', [Validators.required]],
      boundPolicyNumber: ['', [Validators.required]],
      policyExpirationDate: ['', [Validators.required]],
      crossSale: [''],
      subProcedureName: [''],
      subProducer: [''],
      rfp: [''],
      brokerAgent: [''],
      carrier: [''],
      competitorAgentKnown: [''],
      competitorCarrierKnown: [''],
      description: [''],

    })
    this.addBoundForm.get('outcome').disable();
  }

  //to get Bind Request detail
  getOutComeDetail(wbaId: any) {
    this._wbaService.getOutComeDetail(wbaId).subscribe(resp => {
      if (resp) {
        this.outComeDetailFinish = resp.wbaOutcomeDetails;
        this._dataCacheService.compressAndCacheData('wba_bindingResponse', this.outComeDetailFinish);
        if (this.outComeDetailFinish) {
          this.setFormData(this.outComeDetailFinish)
        } else {
          this.setFormValue()
        }
      }
    })
  }

  setFormValue() {
    if (this.wbaDetails?.transType == 'NB') {
      let userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
      if (userDetailsData) {

        userDetailsData?.user?.roles.forEach((x: any) => {
          if (x.roleName == 'Admin' || x.roleName == 'System Manager' || x.roleName == 'Client Service Level 1' || x.roleName == 'Client Service Level 2' || x.roleName == 'Client Service Level 3' || x.roleName == 'Client Service Level 4' || x.roleName == 'Marketing Manager' || x.roleName == 'Production Specialist' || x.roleName == 'Power' || x.roleName == 'User' || x.roleName == 'System Manager' || x.roleName == 'System Client Manager') {
            this.isValidUser = false;
          }
        })
      }
      if (this.isValidUser == true) {
        if (this.outComeDetailFinish == null) {
          this.showDialogBoxFOrBindRequestOrNot = true;
          this.isSaveBtnDisable = true;
          return;
        }
        else {
          this.showDialogBoxFOrBindRequestOrNot = false;
          this.isSaveBtnDisable = false;
        }
      } else {
        this.showDialogBoxFOrBindRequestOrNot = false;
        this.isSaveBtnDisable = false;
      }
    }
    this.addBoundForm.patchValue({
      outcome: 'Bound',
      // boundRevenue: this.resultofWba,
      boundRevenue: this._amountPipe.transform((this.resultofWba), 'USD', '', '1.0-0')
    })
    this.getWbaSubmissionCarrierListById(this.wbaId);
  }

  //patch value
  setFormData(result: any) {
    let outcome = 'Bound'
    if (outcome == "Bound") {
      this.addBoundForm.get('outcome')?.setValue('Bound');
    }
    this.addBoundForm.get('brokerAgent')?.setValue(result.competitorAgent);
    this.addBoundForm.get('competitorAgentKnown')?.setValue(result.competitorAgentKnown);
    this.addBoundForm.get('subProducer')?.setValue(result.subProducerPercentage);
    this.addBoundForm.patchValue({
      subProcedureName: {
        id: result.subProducerId,
        value: result.subProducer ?? stringEmpty
      },
    })
    this.addBoundForm.get('crossSale').setValue(result.crossSale);
    this.addBoundForm.get('rfp').setValue(result.rfp);
    //this.addBoundForm.get('boundCarrier')?.setValue(result.boundCarrierId);
    this.addBoundForm.patchValue({
      boundCarrier: {
        id: result.boundCarrierId,
        value: result.carrier ?? stringEmpty
      },
    })
    this.addBoundForm.get('boundPremium')?.setValue(this._amountPipe.transform((result.boundPremium), 'USD', '', '1.0-0'));
    this.addBoundForm.get('boundRevenue')?.setValue(this._amountPipe.transform((result.boundRevenue), 'USD', '', '1.0-0'));
    this.addBoundForm.get('carrierCommission')?.setValue(result.carrierCommission);
    this.addBoundForm.get('boundPolicyNumber')?.setValue(result.boundPolicyNumber);
    this.addBoundForm.get('description').setValue(result.description);
    this.addBoundForm.get('policyExpirationDate')?.setValue(this.datepipe.transform(result.policyExpirationDate, 'MM/dd/yyyy'));

    this.addBoundForm.get('competitorCarrierKnown').setValue(result.competitorCarrierKnown);
    this.addBoundForm.patchValue({
      carrier: {
        value: result.competitorCarrier ?? stringEmpty
      },
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }



  getReasonList(outcome: 'Bound') {
    this._addWBAService.getReasonLost(outcome).subscribe(resp => {
      this.reasonDetailofLost = resp.wbaReasonList;
      this.reasonLostWbaList = [];

      if (this.reasonDetailofLost) {
        this.reasonDetailofLost.forEach((item: any) => {
          let groupIndex = this.reasonLostWbaList.findIndex((ob: any) => ob.label === item.reasonDescription);
          let listObj = { label: item.reason, value: item.id.toString() };
          if (groupIndex === -1) {
            // group has not found
            let Obj = {
              label: item.reasonDescription,
              value: item.reasonDescription,
              items: [listObj],
            };
            this.reasonLostWbaList.push(Obj);
          } else {
            // group has found
            this.reasonLostWbaList[groupIndex].items.push(listObj);
          }
        })
        setTimeout(() => {
          this.addBoundForm.get('reason')?.setValue(this.outComeDetailFinish?.reasonId?.toString());
        }, 500);
      }
    })
  }


  //Loading prospect List.
  getCarriersListInfo(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType
    }

    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })

    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.searchType = searchType;
    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.addBoundForm.controls['carrier'].patchValue({
          id: data.id,
          value: data.value
        });
      }
    });
  }

  //Loading prospect List.
  getCarriersListInfoforBound(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType
    }
    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })

    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.searchType = searchType;
    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.addBoundForm.controls['boundCarrier'].patchValue({
          id: data.id,
          value: data.value
        });
      }
    });
  }



  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    if (value === "Carrier") {
      this.getCarriersListInfo(value);
    }
    else {
      this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
        if (response) {
          this.lookupData = response;
          if (value === "AccountManager") {
            this.lookupcomponent = LookupManagerComponent;
          }
          const modalRef = this.modalService.open(this.lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })

          modalRef.componentInstance.lookupData = this.lookupData;

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              if (value === "expiringCarrier") {
                this.addBoundForm.controls['expiringCarrier'].patchValue({
                  id: data.id,
                  value: data.value
                });
                this.selectItem(data, value);

              } else if (value === "expiringCarrierforBound") {
                this.addBoundForm.controls['expiringCarrierforBound'].patchValue({
                  id: data.id,
                  value: data.value
                });
                this.selectItem(data, value);
              } else (value === "AccountManager")

              this.addBoundForm.controls['subProcedureName'].patchValue({
                id: data.userId,
                value: data.userName
              });

            }
          });

        }
      }, (err: any) => {
        if (err) {
          this.showError = true;
          this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
        }
      });
    }
  }

  openLookupModalforBoundCarrier(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    if (value === "Carrier") {
      this.getCarriersListInfoforBound(value);
    }
    else {
      this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
        if (response) {
          this.lookupData = response;
          if (value === "AccountManager") {
            this.lookupcomponent = LookupManagerComponent;
          }
          const modalRef = this.modalService.open(this.lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })

          modalRef.componentInstance.lookupData = this.lookupData;

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              if (value === "expiringCarrier") {
                this.addBoundForm.controls['expiringCarrier'].patchValue({
                  id: data.id,
                  value: data.value
                });
                this.selectItem(data, value);

              } else if (value === "expiringCarrierforBound") {
                this.addBoundForm.controls['expiringCarrierforBound'].patchValue({
                  id: data.id,
                  value: data.value
                });
                this.selectItem(data, value);
              } else (value === "AccountManager")

              this.addBoundForm.controls['subProcedureName'].patchValue({
                id: data.userId,
                value: data.userName
              });

            }
          });
        }
      }, (err: any) => {
        if (err) {
          this.showError = true;
          this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
        }
      });
    }
  }


  getCompanyList(fieldName: any, val: any) {
    if (fieldName && val.query) {
      let encode = val.query.replaceAll('&', '%26')
      this._addWBAService.getTypeheadList(fieldName, encode).subscribe(resp => {
        if (fieldName == 'Carrier') {
          this.expiringCarrierlist = resp.expiringCarrierList;
        }
        else (fieldName == 'accountManager')
        this.accountManagerlist = resp.userList;
      })
    }
  }



  selectItem(item: any, type: any) {
    if (type == 'expiringCarrier') {
      this.addBoundForm.controls['expiringCarrier'].setValue({
        id: item.id,
        value: item.value
      });
    }
    else if (type == 'expiringCarrierforBound') {
      this.addBoundForm.controls['expiringCarrierforBound'].setValue({
        id: item.id,
        value: item.value
      });
    }
    else (type == 'accountManager')
    this.addBoundForm.controls['subProcedureName'].setValue({
      id: item.userId,
      value: item.userName
    });

  }

  //to get wba carriers list
  getWbaSubmissionCarrierListById(wbaId: any) {
    this._wbaService.getMarketingSummaryList(wbaId).subscribe(resp => {
      this.wbaSubmissionCarriers = resp.marketingSummary;
      // this.quotedFind = this.wbaSubmissionCarriers.filter((x: any) => x.quotedStatus == "Quoted");
      if (this.progresStatus == "Quoted" || this.progresStatus == "Request to Bind") {
        this.showHideBoundCarrierValidationPopUp = false;
        this.isSaveBtnDisable = false;
      } else {
        this.showHideBoundCarrierValidationPopUp = true;
        this.isSaveBtnDisable = true;
        return
      }
    })
  }






  //to get result list
  getResultList() {
    this._addWBAService.getResultList().subscribe(resp => {
      this.epicStatus = resp;
    })
  }


  changeBoundPremium(e: any) {
    let boundPremiumForQuoted = this.quotedFind.find((x: any) => x.id == e.target.value);
    // this.addBoundForm.get('boundPremium')?.setValue(boundPremiumForQuoted.quotedPremium);
    this.addBoundForm.get('boundPremium')?.setValue(this._amountPipe.transform((boundPremiumForQuoted.quotedPremium), 'USD', '', '1.0-0'))
  }


  closeError() {
    this.showError = false;
  }


  onChange(event: any) {
    if (event.target.value == '')
      this.showPercentageValidation = false;
  }

  saveWbaBound() {
    this.submitted = true;
    if (this.addBoundForm.get('subProducer').value == '' || this.addBoundForm.get('subProducer').value == null)
      this.showPercentageValidation = false;
    else if (this.addBoundForm.get('subProducer').value < 0 || this.addBoundForm.get('subProducer').value > 100
    ) {
      this.showPercentageValidation = true;
      return;
    } else {
      this.showPercentageValidation = false;
    }

    if (this.addBoundForm.get('carrierCommission').value == '' || this.addBoundForm.get('carrierCommission').value == null)
      this.showCarrierPercentageValidation = false;
    else if (this.addBoundForm.get('carrierCommission').value < 0 || this.addBoundForm.get('carrierCommission').value > 100
    ) {
      this.showCarrierPercentageValidation = true;
      return;
    } else {
      this.showCarrierPercentageValidation = false;
    }
    if (this.addBoundForm.controls.boundCarrier.value.id == 0) {
      this.invalidBoundCarrier = true;
      return;
    }
    if (!this.addBoundForm.valid) {
      return;
    } else {
      let data = this.addBoundForm.value;
      let date = new Date()
      var dateValue = this.datepipe.transform(date, 'yyyy-MM-dd')
      var time;
      time = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: "2-digit", second: "2-digit", hour12: false })
      let completeDate = dateValue + 'T' + time + 'Z';
      let savedDta = {
        wbaId: this.wbaId,
        outcome: "Bound",
        reasonId: parseInt(data.reason),
        boundCarrierId: data.boundCarrier.id,
        boundPremium: data.boundPremium ? parseInt(this._removeAmountPipe.transform(data.boundPremium)) : 0,
        boundRevenue: data.boundRevenue ? parseInt(this._removeAmountPipe.transform(data.boundRevenue)) : 0,
        carrierCommission: data.carrierCommission.toString(),
        boundPolicyNumber: data.boundPolicyNumber.toString(),
        policyExpirationDate: this.datepipe.transform(data.policyExpirationDate, 'yyyy-MM-dd'),
        subProducer: data.subProcedureName.id,
        subProducerPercentage: data.subProducer ? data.subProducer.toString() : null,
        rfp: data.rfp === 'true' || data.rfp === true ? true : false,
        crossSale: data.crossSale === 'true' || data.crossSale === true ? true : false,
        competitorAgent: data.brokerAgent,
        competitorCarrier: data.carrier.value,
        competitorAgentKnown: data.competitorAgentKnown == '' ? false : true,
        competitorCarrierKnown: data.competitorCarrierKnown == '' ? false : true,
        resultId: 1,
        boundDate: completeDate,
        description: this.selectedReasonId == this.reason.id ? data.description : null,
        id: this.outComeDetailFinish?.id,
        broker: this.outComeDetailFinish?.broker,
        brokerFees: this.outComeDetailFinish?.brokerFees,
        brokerFeeAmount: this.outComeDetailFinish?.brokerFeeAmount,
        brokerFeeTerms: this.outComeDetailFinish?.brokerFeeTerms,
        premiumPayableCarrierId: this.outComeDetailFinish?.premiumPayableCarrierId,
        premiumPayableCarrier: this.outComeDetailFinish?.premiumPayableCarrier,
        mainProducerPercentage: this.outComeDetailFinish?.mainProducerPercentage,
        startDate: this.outComeDetailFinish?.createdDate,
        wbaCloseDate: this.outComeDetailFinish?.wbaCloseDate,
        overAge: this.outComeDetailFinish?.overAge ? this.outComeDetailFinish?.overAge : false
      };

      this._addWBAService.addWbaOutComeBound(savedDta).subscribe((resp: any) => {

        if (resp) {
          this._notifierService.setExpectBoundValue(resp.boundRevenue);
          this._notifierService._boundData.next(savedDta);
          // this.moveEfileWbaToClientFolderFolder();
          this.getOutComeDetail(this.wbaId);
          this.closeModal();
          this.messageEvent.emit(resp);
          this.passEntry.emit('Bound');
          this.errorList = [];
          this.getWhiteBoardDetails(this.wbaId);
          this.getEpicPolicyDetailsApi();
        }
      },
        (err: any) => {
          this.errorList = [];
          if (err.status !== 200) {
            if (this.unAthorizesd) {
              this.showError = true;
              this.errorList.push(this.unAthorizesd);
              return
            }
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            }
            if (err.status == 403) {
              this.showError = true;
              let errormessage = "Access denied, your permissions do not allow you to proceed."
              this.errorList.push(errormessage)
            }
            else {
              this.showError = true;
              this.errorMessage = err.message;
              this.errorList.push(this.errorMessage);
            }
          }
        }
      );
    }
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    });
  }
  get f() {
    return this.addBoundForm.controls;
  }
  cancel() {
    if (!this.addBoundForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }
  showDialogBox() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(
      element.target.value,
      ''
    );
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,3})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  moveEfileWbaToClientFolderFolder() {
    this._wbaService.moveEfileWbaToClientFolderByWbaId(this.wbaId).subscribe(resp => {
      if (resp) {
      }
      else {
      }
    })
  }

  addCurrencyFormat(element: any, control?: AbstractControl) {
    var formattedAmount;
    formattedAmount = element.target.value.replace(/,/g, '');
    formattedAmount = this._amountPipe.transform(formattedAmount, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    control?.setValue(element.target.value);
  }
  // To remove amount
  removeCurrencyFormat(element: any, val?: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  getEpicPolicyDetailsApi() {
    if (this.wbaId) {
      this._getWbaActivityService.GetEpicPolicyDetails(this.wbaId).subscribe((resp: any) => {
        this.wbaEpicPolicyDetailList = resp.wbaEpicPolicyDetailList[0];
        this._dataCacheService.compressAndCacheData('wba_epicPolicyDetails', this.wbaEpicPolicyDetailList);
        this._dataCacheService.setproducer(this.wbaEpicPolicyDetailList)
      })
    }
  }

  getAccordPermissionByWbaId() {

    let requestBody = {
      wbaId: this.wbaId
    };

    this._wbaService.getAccordPermissionByWbaId(requestBody).subscribe((res: any) => {
      this.AcordPermissionResponse = res.acordPermissions;
      let userDetails = JSON.parse(localStorage.getItem('userDetails')!);

      for (let index = 0; index < this.AcordPermissionResponse.length; index++) {
        let allRolePerms = this.AcordPermissionResponse[index];
        let actionAERequest = allRolePerms.actionName

        let findRoleName = userDetails.user.roles.find((y: any) => y.id === allRolePerms.approverRoleId);
        // if(findRoleName)
        // this.disabledSubmit = true
        if (actionAERequest == 'Lost' && (allRolePerms.approverUserId == userDetails?.user?.userId || findRoleName)) {
          console.log("AAM");
          this.isBoundRequest = true;
        }
      }
      //let actionAERequest = this.AcordPermissionResponse.filter((x: any) => x.actionName == 'AccountExecutiveRequest')
      //this.isAERequestSubmit = actionAERequest.some((item: any) => item.approverUserId === userDetails?.user?.userId);
    });
  }

  closeErrorBound() {
    this.isBoundRequest = false;
  }
}
