import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';

@Component({
  selector: 'app-e-file-cabinate',
  templateUrl: './e-file-cabinate.component.html',
  styleUrls: ['./e-file-cabinate.component.css']
})
export class EFileCabinateComponent implements OnInit {

  @Input() url: string = "";

  private _userMessages: any;
  private _confirmBox: any;
  private interval: any;
  public urlSafes!: SafeResourceUrl;

  constructor(
    public activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
    private _loaderService: LoaderService,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this._loaderService.show();

    this.urlSafes = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.interval = setInterval(() => { this.checkIframeLoaded() }, 2000);
  }

  checkIframeLoaded() {
    // Get a handle to the iframe element
    let iframe = document.getElementById('i_frame') as HTMLIFrameElement

    var iframeDoc = iframe.ownerDocument || iframe?.contentWindow?.document;

    // Check if loading is complete for iFrame
    if (iframeDoc.readyState == 'complete') {
      this._loaderService.hide();
      clearInterval(this.interval);
    }
  }

  //to cancel the process and back to prospect list screen
  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }
}