<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Edit Whiteboard Activity</h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>

        </div>

        <div class="add-content add-form">
            <form class="row g-3" novalidate [formGroup]='editWhiteBoardForm'>
                <div class="add-form my-4">
                    <div class="add-fld">
                        <div class="add-title">
                            <h3>Basic info</h3>
                        </div>
                        <div class="row frmrow">
                            <div class="col-12 col-sm-12 col-md-12 mb-1 has-validation">
                                <label class="form-label">White Board Activity Name<span></span></label>
                                <input formControlName="whiteBoardActivityName" class="form-control"
                                    placeholder="White Board Activity Name" [readonly]="true" />
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 my-2 has-validation">
                                <label class="form-label">Marketing</label>
                                <select class="select-cs form-select " formControlName="userName"
                                    placeholder="Select Marketing">
                                    <option disabled value="">Select Marketing</option>
                                    <option *ngFor="let marketing of userList.userList" value="{{marketing.userName}}">
                                        {{marketing.userName}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="add-title">
                            <h3>Company Info</h3>
                        </div>
                        <div class="row frmrow">
                            <div class="col-12 col-sm-12 col-md-12 mb-1 has-validation">
                                <label class="form-label">Company Name<span></span></label>
                                <input formControlName="companyName" class="form-control" placeholder="Company Name"
                                    [ngClass]="{ 'error':  f.companyName.invalid && isSubmitted }"
                                    (focusout)="removeSpaces($event,f.companyName)" />
                                <div class="display-error">
                                    <div *ngIf="f.companyName.invalid && isSubmitted">
                                        <span *ngIf="f.companyName.errors?.required">
                                            {{_AddWhiteBoardScreen.companyName.required}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row frmrow">
                            <div class="col-12 col-sm-12 col-md-3 mb-1 has-validation">
                                <label class="form-label">EIN <span></span></label>
                                <input formControlName="ein" [readonly]="true" class="form-control" placeholder="EIN"
                                    [ngClass]="{ 'error':  f.ein.invalid && isSubmitted }" />
                                <div class="display-error">
                                    <div *ngIf="f.ein.invalid && isSubmitted">
                                        <span *ngIf="f.ein.errors?.required">
                                            {{_AddWhiteBoardScreen.ein.required}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-4 mb-1 has-validation">
                                <label class="form-label">Business Status<span></span></label>
                                <select class="select-cs form-select" formControlName="businessStatus">
                                    <option disabled value="">Select Business Status</option>
                                    <option *ngFor="let line of businessStatus.businessStatusList"
                                        value="{{line.businessStatusName}}">
                                        {{line.businessStatusName}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="add-title">
                            <h3>Coverage Requirement Info</h3>
                        </div>
                        <div class="row frmrow">
                            <div class="col-12 col-sm-12 col-md-6 mb-1 has-validation">
                                <label class="form-label">Line <span></span></label>
                                <select class="select-cs form-select" formControlName="businessLine">
                                    <option disabled value="">Select Line</option>
                                    <option *ngFor="let line of businessLine.businessLineList"
                                        value="{{line.businessLineName}}">
                                        {{line.businessLineName}}</option>
                                </select>
                                <div class="display-error">
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-3 mb-1 has-validation date-control">
                                <label class="form-label">Effective Date<span></span></label>
                                <p-calendar class="form-control" placeholder="mm/dd/yy" [showIcon]="true" [iconDisplay]="'input'"
                                    formControlName="effectiveDate" dateFormat="mm/dd/yy"
                                    inputId="effectiveDate"></p-calendar>
                                <div class="display-error">
                                </div>
                            </div>
                        </div>
                        <div class="add-title">
                            <h3>Policy Sales Info</h3>
                        </div>
                        <div class="row frmrow">
                            <div class="col-12 col-sm-12 col-md-3 mb-1 has-validation">
                                <label class="form-label">Bound Revenue (to Lamb)<span></span></label>
                                <input formControlName="boundRevenue" class="form-control" placeholder="Bound Revenue"
                                    [ngClass]="{ 'error':  f.boundRevenue.invalid && isSubmitted }"
                                    (focusout)="removeSpaces($event,f.boundRevenue)" />
                                <div class="display-error">
                                    <div *ngIf="f.boundRevenue.invalid && isSubmitted">
                                        <span *ngIf="f.boundRevenue.errors?.pattern">
                                            {{_AddWhiteBoardScreen.boundRevenue.formate}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 pt-3">
                                <button class="btn btn-primary me-4" (click)="editWBActivity()">Save</button>
                                <button class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- Dialog for confirmation -->
        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>{{_confirmBox.message}}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                </p-button>
                <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
