<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header cl-hdr">
                <h5>Proposals</h5>
              </div>
              <div class="card-body">
                <div class="grp-heading">
                  <h3>Include slides for proposal</h3>
                </div>
                <form class="row g-3" novalidate [formGroup]='proposalForm' id="proposalForm"
                  onkeydown="return event.key != 'Enter';">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                      <div class="slide-size">
                        <ul class="chk-list wb-chklist">
                          <li [ngClass]="{'active-c' : currentSlideName === line.displayName,
                          'select-c' : currentSlideName != line.displayName
                        }" *ngFor="let line of MasterCoverageListNew; let i = index">
                            <a title="">
                              <div class="flex-div">
                                <img *ngIf="line.isInclude" src="assets/images/completed.svg" alt="Completed"
                                  class="me-2" />
                                <span *ngIf="!line.isInclude" class="include-ml"></span>

                                <label (click)="openSlide(line)" class="form-check-label m-t-2 pointer">
                                  {{line.displayName }}</label>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 cov-con">
                      <div class="row">
                        <div class="col-12 px-0">
                          <h3 class="DetailsHeading">
                            {{currentSlideName}}
                          </h3>
                        </div>
                        <ng-container *ngIf="currentSlideName === 'Cover Page'">
                          <div class="col-12 col-sm-12 col-md-6 mt-2">
                            <label class="form-label">Title</label>
                            <input dataTextFocus class="form-control" placeholder="Enter title of the cover page"
                              formControlName="coverPageTitle" (change)="onCoverPageTitleChange()" />
                            <div class="display-error" *ngIf="f.coverPageTitle.invalid && isSubmitted">
                              <span
                                *ngIf="f.coverPageTitle.errors?.required">{{_quoteDetailsMessage.coverPageTitle.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 mt-2">
                            <label class="form-label">Prospect <span>*</span></label>
                            <input class="form-control" placeholder="Prospect Name"
                              formControlName="coverPageProspectName" (change)="onCoverPageProposalChange()" />
                            <div class="display-error" *ngIf="f.coverPageProspectName.invalid && isSubmitted">
                              <span
                                *ngIf="f.coverPageProspectName.errors?.required">{{_quoteDetailsMessage.prospect.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Presented By <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="coverPagepresentedBy">
                            </p-editor>
                            <div class="display-error" *ngIf="f.coverPagepresentedBy.invalid && isSubmitted">
                              <span
                                *ngIf="f.coverPagepresentedBy.errors?.required">{{_quoteDetailsMessage.presentedBy.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="coverPageInclude"
                                formControlName="coverPageInclude" name="coverPageInclude"
                                (click)="onIncludeChange('Cover Page', f.coverPageInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Human Service Expertise'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Description <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="hseDescription">
                            </p-editor>
                            <div class="display-error" *ngIf="f.hseDescription.invalid && isSubmitted">
                              <span
                                *ngIf="f.hseDescription.errors?.required">{{_quoteDetailsMessage.hseDescription.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="hseInclude"
                                formControlName="hseInclude" name="hseInclude"
                                (click)="onIncludeChange('Human Service Expertise', f.hseInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Lamb Safeguard Protect'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="lspInclude"
                                formControlName="lspInclude" name="lspInclude"
                                (click)="onIncludeChange('Lamb Safeguard Protect', f.lspInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Proposal Introduction'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Description <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="piDescription">
                            </p-editor>
                            <div class="display-error" *ngIf="f.piDescription.invalid && isSubmitted">
                              <span
                                *ngIf="f.piDescription.errors?.required">{{_quoteDetailsMessage.piDescription.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="piInclude" formControlName="piInclude"
                                name="piInclude"
                                (click)="onIncludeChange('Proposal Introduction', f.piInclude.value)" />
                              <span class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Map of Lamb Client'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="mlcInclude"
                                formControlName="mlcInclude" name="mlcInclude"
                                (click)="onIncludeChange('Map of Lamb Client', f.mlcInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Total Premium Summary'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="tps-table">
                              <div class="audit-table">
                                <table>
                                  <thead>
                                    <tr>
                                      <th width="50%">Premium Comparisions</th>
                                      <th width="20%">Expiring</th>
                                      <th width="20%">Proposed</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{{wbaDetails.masterBusinessLine}}</td>
                                      <td>{{wbaDetails.expiringPremium }}</td>
                                      <td>{{wbaDetails.targetPremium}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="tpsInclude"
                                formControlName="tpsInclude" name="tpsInclude"
                                (click)="onIncludeChange('Total Premium Summary', f.tpsInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Coverages'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="audit-right">
                              <ng-container *ngFor="let item of CoverageCheckList; let index = index;">
                                <div class="au-active-detail">
                                  <div class="audit-right-header">
                                    <div class="coverage-n flex-div">
                                      <img src="assets/images/{{item.coverageName}}.svg" title="{{item.coverageName}}">
                                      <h2>{{item.coverageName}}</h2>
                                    </div>
                                  </div>
                                  <div class="audit-table">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th width="14%">
                                            <div class="form-check-inline">
                                              <label class="form-check-label" for="role1">Select
                                              </label>
                                            </div>
                                          </th>
                                          <th width="16%">
                                            <div class="form-check-inline">
                                              <input class="form-check-input pointer checkBox-margin" type="checkbox"
                                                name="coverageAllCheckbox" id="{{ item.coverageName }}{{ index }}"
                                                (click)="onCoverageHighlightAllSelect($event, item, index)">
                                              <label class="form-check-label" for="role1">Highlights
                                              </label>
                                            </div>
                                          </th>
                                          <th width="38%">Coverage</th>
                                          <th width="16%">Expiring Value</th>
                                          <th width="16%">Recommended Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let param of item.coverageParameters; let i = index;">
                                          <td class="">
                                            <input type="checkbox" name="{{ param.displayName }}SelectRow{{ i }}"
                                              id="{{ param.displayName }}SelectRow{{ i }}"
                                              class="form-check-input pointer checkBox-margin"
                                              (change)="handleCoverageSelectRow(param)" [checked]="param.selectRow" />
                                          </td>
                                          <td class="">
                                            <input type="checkbox" name="{{ param.displayName }}{{ i }}"
                                              id="{{ param.displayName }}{{ i }}"
                                              class="form-check-input pointer checkBox-margin"
                                              (change)="handleCoverageHighlight(param)" [checked]="param.highlights"
                                              [disabled]="!param.selectRow" />
                                          </td>
                                          <td class="coverage-grp">
                                            <div>{{param.displayName}}
                                            </div>
                                          </td>
                                          <td><span [ngClass]="'assess_' + param.expiringAssessmentId">
                                              {{param.expiringValue}}
                                            </span></td>
                                          <td><span [ngClass]="'assess_' + param.recommendedAssessmentId">
                                              {{!param.recommendedValue || param.recommendedValue === "undefined" ? "" :
                                              param.recommendedValue }}
                                            </span> </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="coveragesInclude"
                                formControlName="coveragesInclude" name="coveragesInclude"
                                (click)="onIncludeChange('Coverages', f.coveragesInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Marketing Summary'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="audit-right">
                              <div class="audit-table">
                                <table>
                                  <thead>
                                    <tr>
                                      <th width="15%"> Effective Date </th>
                                      <th width="20%"> Coverage</th>
                                      <th width="30%">Carrier/Wholesaler </th>
                                      <th width="15%">Status </th>
                                      <th width="20%">Quoted Premium </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of marketSummaryList; let i = index;">
                                      <td> {{item.effectiveDate | date:'MM/dd/yyyy'}} </td>
                                      <td> {{item.coverageName}} </td>
                                      <td> {{item.carrierShortName}} </td>
                                      <td> {{item.quotedStatus}} </td>
                                      <td> {{item.quotedPremium }} </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="msInclude" formControlName="msInclude"
                                name="msInclude" (click)="onIncludeChange('Marketing Summary', f.msInclude.value)" />
                              <span class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Am Best Rating'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="abrInclude"
                                formControlName="abrInclude" name="abrInclude"
                                (click)="onIncludeChange('Am Best Rating', f.abrInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Risk Management Glossary'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">About Risk <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="rmgAboutRisk">
                            </p-editor>
                            <div class="display-error" *ngIf="f.rmgAboutRisk.invalid && isSubmitted">
                              <span
                                *ngIf="f.rmgAboutRisk.errors?.required">{{_quoteDetailsMessage.rmgAboutRisk.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Description <span>*</span></label>
                            <textarea class="form-control" id="rmgDescription" formControlName="rmgDescription" rows="3"
                              placeholder="Description"></textarea>
                            <div class="display-error" *ngIf="f.rmgDescription.invalid && isSubmitted">
                              <span
                                *ngIf="f.rmgDescription.errors?.required">{{_quoteDetailsMessage.rmgDescription.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-3">
                            <label class="form-label">{{riskServiceLabel}} </label>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 mt-2">
                            <label class="form-label">
                              <h3 class="DetailsHeading">
                                Claim Managment Service
                              </h3>
                            </label>
                            <ul class="wb-chklist">
                              <li *ngFor="let line of claimServiceList; let i = index">
                                {{line.serviceName }}
                              </li>
                            </ul>
                          </div>
                          <div class="col-12 col-sm-12 col-md-6 mt-2">
                            <label class="form-label">
                              <h3 class="DetailsHeading">
                                Risk Control Service
                              </h3>
                            </label>
                            <ul class="wb-chklist">
                              <li *ngFor="let line of riskServiceList; let i = index">
                                {{line.serviceName }}
                              </li>
                            </ul>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-3">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="rmgInclude"
                                formControlName="rmgInclude" name="rmgInclude"
                                (click)="onIncludeChange('Risk Management Glossary', f.rmgInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Your Service Team'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Addtional Resources<span>*</span></label>
                            <div class="flex-div justify-content-between">
                            </div>
                            <p-scrollPanel [style]="{width: '100%', height: '170px'}" styleClass="custombar">
                              <div class="frmrow  mb-2 mt-2">

                                <div class="table-responsive table-responsive-md  dial-table">
                                  <table class="table tbl-dial">
                                    <thead class="table-dark">
                                      <tr>
                                        <th width="45%">Resource Name</th>
                                        <th width="45%">
                                          Resource Title <span class="star-color"></span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody formArrayName="ystResouceList">
                                      <ng-container
                                        *ngFor="let other of proposalForm.controls.ystResouceList['controls']; let i = index">
                                        <tr [formGroupName]="i">
                                          <td>
                                            <input type="text" placeholder="Resource Name" class="form-control"
                                              formControlName="resourceName" />
                                          </td>
                                          <td>
                                            <div>
                                              <input type="text" placeholder="Resource Title" class="form-control"
                                                formControlName="resourceTitle" />
                                            </div>
                                          </td>
                                        </tr>
                                      </ng-container>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </p-scrollPanel>

                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="ystInclude"
                                formControlName="ystInclude" name="ystInclude"
                                (click)="onIncludeChange('Your Service Team', f.ystInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Condition of Market Place'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="cmpInclude"
                                formControlName="cmpInclude" name="cmpInclude"
                                (click)="onIncludeChange('Condition of Market Place', f.cmpInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Subjectivities and Billing'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Description <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="sbDescription">
                            </p-editor>
                            <div class="display-error" *ngIf="f.sbDescription.invalid && isSubmitted">
                              <span
                                *ngIf="f.sbDescription.errors?.required">{{_quoteDetailsMessage.sbDescription.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="sbInclude" formControlName="sbInclude"
                                name="sbInclude"
                                (click)="onIncludeChange('Subjectivities and Billing', f.sbInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Summary'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Differences <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="summaryDifferences">
                            </p-editor>
                            <div class="display-error" *ngIf="f.summaryDifferences.invalid && isSubmitted">
                              <span
                                *ngIf="f.summaryDifferences.errors?.required">{{_quoteDetailsMessage.summaryDifferences.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <label class="form-label">Services <span>*</span></label>
                            <p-editor [style]="{'height':'170px'}" formControlName="summaryServices">
                            </p-editor>
                            <div class="display-error" *ngIf="f.summaryServices.invalid && isSubmitted">
                              <span
                                *ngIf="f.summaryServices.errors?.required">{{_quoteDetailsMessage.summaryServices.required}}</span>
                            </div>
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="summaryInclude"
                                formControlName="summaryInclude" name="summaryInclude"
                                (click)="onIncludeChange('Summary', f.summaryInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Disclaimer'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="disclaimerInclude"
                                formControlName="disclaimerInclude" name="disclaimerInclude"
                                (click)="onIncludeChange('Disclaimer', f.disclaimerInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Philadelphia Insurance Companies'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="picInclude"
                                formControlName="picInclude" name="picInclude"
                                (click)="onIncludeChange('Philadelphia Insurance Companies', f.picInclude.value)" />
                              <span class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Amtrust'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="amTrustInclude"
                                formControlName="amTrustInclude" name="amTrustInclude"
                                (click)="onIncludeChange('Amtrust', f.amTrustInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Church Mutual'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="cmInclude" formControlName="cmInclude"
                                name="cmInclude" (click)="onIncludeChange('Church Mutual', f.cmInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Guide One'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="goInclude" formControlName="goInclude"
                                name="goInclude" (click)="onIncludeChange('Guide One', f.goInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Non-Profits Insurance Alliance'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="niaInclude"
                                formControlName="niaInclude" name="niaInclude"
                                (click)="onIncludeChange('Non-Profits Insurance Alliance', f.niaInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Berkley'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="berkleyInclude"
                                formControlName="berkleyInclude" name="berkleyInclude"
                                (click)="onIncludeChange('Berkley', f.berkleyInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Hanover'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="hanoverInclude"
                                formControlName="hanoverInclude" name="hanoverInclude"
                                (click)="onIncludeChange('Hanover', f.hanoverInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="currentSlideName === 'Key Risk'">
                          <div class="col-12 col-sm-12 col-md-12 mt-2">
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="checkbox" id="krInclude" formControlName="krInclude"
                                name="krInclude" (click)="onIncludeChange('Key Risk', f.krInclude.value)" /> <span
                                class="pt-2">Include</span>
                            </div>
                          </div>
                        </ng-container>

                        <div class="col-12 col-sm-12 col-md-12 mt-2 mb-4">
                          <button class="btn btn-primary ms-2 me-3" (click)="previewCurrentPage(content)">
                            Preview Slide</button>
                          <button class="btn btn-outline-primary" *ngIf="currentSlideName !== 'Key Risk'"
                            (click)="openNextSlide()">Next</button>
                        </div>
                        <ng-template #NoData> No data found </ng-template>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div id="view-pdf">
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">{{currentSlideName}}</h2>
      <div class="flex-div">
        <button class="btn btn-primary ml-4" (click)="downloadPreviewProposalReport()">
          Download</button>
        <a href="javascript:void(0)" title="" (click)="modal.dismiss('Cross click')" class="close"
          data-bs-dismiss="modal" aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
      </div>
    </div>
    <div class="modal-body">
      <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="true" [rotation]="0" [fit-to-page]="true"
        [show-all]="true" style="width: 100%; height: 610px" [zoom]="1" [zoom-scale]="'page-width'"
        [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
        [show-borders]="false"></pdf-viewer>
    </div>
  </div>
</ng-template>