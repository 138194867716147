<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <div class="col-12 wbaHeadding">
            <div class="hd-width">

              <h2>Binding</h2>
              <div>

              </div>



            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12">
            <div class="addw" id="tb-h">
              <div class="frmrow add-wba-form mb-2">
                <div class="row ">
                  <form [formGroup]="bindingForm">

                    <div class="col mb-2 has-validation">
                      <input class="form-check-input" type="checkbox" formControlName="overage">
                      <label class="form-check-label lb-non mt-1" for="overage">
                        Overage
                      </label>
                    </div>

                    <div class="row row-cols-4">
                      <div class="col mb-2  has-validation">
                        <label class="form-label">Broker<span class='star-color'></span></label>
                        <input formControlName="broker" class="form-control" placeholder="Broker" type="text" />
                      </div>

                      <div class="col mb-2  has-validation">
                        <label class="form-label">Broker Fee <span class="star-color"></span></label>
                        <div class="pos-re">
                          <select class="form-control" formControlName="brokerFee">
                            <option disabled value="">
                              Select Broker Fee
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>

                          </select>
                        </div>
                        <!-- <div class="pos-re">
                                    <span class="dollor">$</span>
                                    <input formControlName="brokerFee" class="form-control"
                                        placeholder="Broker Fee" type="number" />
                                </div> -->
                      </div>

                      <div class="col mb-2  has-validation">
                        <label class="form-label">Broker Fee Amount<span class='star-color'></span></label>
                        <div class="pos-re">
                          <span class="dollor">$</span>
                          <input formControlName="brokerFeeAmount" class="form-control" placeholder="Broker Fee Amount"
                            type="number" />
                        </div>
                      </div>

                      <div class="col mb-2  has-validation">
                        <label class="form-label">Broker Fee Terms<span class='star-color'></span></label>
                        <input formControlName="brokerFeeTerms" class="form-control" placeholder="Broker Fee Terms"
                          type="text" />
                      </div>

                      <div class="col mb-2  has-validation">
                        <label class="form-label">Bound Premium<span class='star-color'></span></label>
                        <div class="pos-re">
                          <span class="dollor">$</span>
                          <input formControlName="boundPremium" class="form-control" placeholder="" type="number"
                            [attr.disabled]="disable" />
                        </div>
                      </div>
                      <div class="col mb-2  has-validation">
                        <label class="form-label">Carrier<span class='star-color'></span></label>
                        <input formControlName="carrier" class="form-control" placeholder=""
                          [attr.disabled]="disable" />
                        <!-- <div id="carrier" class="flex-div wd-auto-comp">
                                    <select class="select-cs form-select" placeholder="Carrier Name"
                                        formControlName="carrier" [attr.disabled]="disable">
                                        <option *ngFor="let boundCarrier of quotedFind"
                                            [value]="boundCarrier.id">
                                            {{boundCarrier.carrierShortName}}
                                        </option>
                                    </select>
                                </div> -->
                      </div>

                      <div class="col mb-2  has-validation">
                        <label class="form-label">Premium Payable<span class='star-color'></span></label>
                        <div class="flex-div wd-auto-comp">
                          <p-autoComplete class="form-control auto-comp " placeholder="Premium Payable"
                            formControlName="premiumPayable" [minLength]="1" [showEmptyMessage]="true"
                            [suggestions]="expiringCarrierlist" (onSelect)="selectItem($event.value, 'expiringCarrier')"
                            (onClear)="lookUpDataFilterChanges()"
                            (completeMethod)="getCompanyList('Wholesaler', $event)"
                            (onDropdownClick)="getCompanyList('Wholesaler', $event)" field="value">
                            <ng-template let-carrier pTemplate="item">
                              <div class="company-item">
                                <div>{{ carrier.value }}</div>
                              </div>
                            </ng-template>
                          </p-autoComplete>
                          <img src="assets/images/search.svg" (click)="openLookupModal('Wholesaler')" alt=""
                            class="ms-2" />
                        </div>
                      </div>
                      <div class="col mb-2  has-validation">
                        <label class="form-label">Main Producer %<span class='star-color'></span></label>
                        <input formControlName="mainProducer" class="form-control" placeholder="0" type="text"
                          [attr.disabled]="disable" />
                      </div>
                      <div class="col mb-2  has-validation">
                        <label class="form-label">Sub Producer %<span class='star-color'></span></label>
                        <input formControlName="subProducer" class="form-control" placeholder="" type="text"
                          (change)="getSubProducerCalculation(f.subProducer.value)" [attr.disabled]="disable" />
                      </div>

                      <div class="col mb-2  date-control epic  has-validation">
                        <label class="form-label">Start Date<span class='star-color'></span></label>
                        <p-calendar placeholder="MM/DD/YYYY" class="form-control" id="startDate" [showIcon]="true" [iconDisplay]="'input'"
                          dateFormat="mm/dd/yy" inputId="effectiveDate" formControlName="startDate"></p-calendar>
                      </div>

                      <div class="col mb-2  date-control epic  has-validation">
                        <label class="form-label">Bound Date<span class='star-color'></span></label>
                        <p-calendar [readonlyInput]="true" placeholder="MM/DD/YYYY" class="form-control"
                          [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy" inputId="effectiveDate" formControlName="boundDate"
                          id="boundDate"></p-calendar>
                      </div>

                      <div class="col mb-2  date-control epic  has-validation">
                        <label class="form-label">WBA Close Date<span class='star-color'></span></label>
                        <p-calendar [readonlyInput]="true" placeholder="MM/DD/YYYY" class="form-control"
                          [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy" inputId="effectiveDate" formControlName="closeDate"
                          dataType="string"></p-calendar>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-12 text-right mt-2">
                        <button class="btn btn-primary" (click)="saveWBA('binding')"
                          [disabled]="wbaView?.outcome != 'Bound'">Save</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <p-dialog header="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{ _confirmBox.message }}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="_confirmBox.isVisible = false" label="OK" styleClass="btn btn-primary">
          </p-button>
        </ng-template>
      </p-dialog>
    </div>
