import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LookupPaginatorModel } from '../../../shared/lookup-paginator';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { LeadGeneratorScreen } from '../../../error-message/leadGenerator-messages';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
@Component({
  selector: 'app-lookup-lead-generator',
  templateUrl: './lookup-lead-generator.component.html',
  styleUrls: ['./lookup-lead-generator.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LookupLeadGeneratorComponent implements OnInit, OnDestroy {
  _navigationPath: NavigationPath;
  _confirmBox: ConfirmBox;
  _lookUpPaginator: LookupPaginatorModel;
  leadGeneratorScreen: LeadGeneratorScreen;
  leadGenertorForm: any;
  _userMessages: UserMessages;
  visd: boolean = false;
  isSelected: boolean = false;
  submitted: boolean = false;
  tbsd: boolean = false;
  ownerList: any;
  ClickedRow: any;
  HighlightRow!: Number;
  selectedData: any;
  justClicked: boolean = false;
  doubleClicked: boolean = false;
  @Input() lookupData: any;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  unsubscribe$: Subject<boolean> = new Subject();
  serviceResponse$!: Observable<any>;
  userDetails: any = {};
  unAthorizesd: any = '';
  showError: boolean = false;
  searchGrid: string = '';
  FilterBlankcheck: boolean = false;
  @Input() LeadlookupFilterData: string = "";

  constructor(public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private _addWBAService: WhiteBoardActivityService,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _loaderService: LoaderService,
    public profileInfoService: ProfileInfoService) {
    this._confirmBox = new ConfirmBox();
    this._lookUpPaginator = new LookupPaginatorModel();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this.leadGeneratorScreen = new LeadGeneratorScreen();
    this.leadGenertorForm = this.fb.group({
      name: ['', [Validators.required]],
      ownerId: ['', [Validators.required]]
    })
  }

  @HostListener('document:keypress', ['$event'])
  startSearch(event: KeyboardEvent) {
    if (event.code === "Enter") {
      this.getLeadgeneratorList(this.searchGrid)
    }
  }

  get f() {
    return this.leadGenertorForm.controls;
  }

  close() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  clickFunction(data: any, index: any) {
    this.HighlightRow = index;
    if (this.justClicked === true) {
      this.doubleClicked = true;
      this.doubleClick(data, index);
    } else {
      this.justClicked = true;
      setTimeout(() => {
        this.justClicked = false;
        if (this.doubleClicked === false) {
          this.selctedValue(data, index);
        }
        this.doubleClicked = false;
      }, 300);
    }
  }

  doubleClick(data: any, index: any) {
    this.selectedData = data;
    this.messageEvent.emit(data);
    this.passEntry.emit(this.selectedData.name);
    this.closeModal();
  }

  clearSearch() {
    let data = {
      "lookUpType": "leadGenerator",
      "rowCount": 50
    };
    this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
      if (response) {
        this.lookupData = response.leadGeneratorList;
        this._lookUpPaginator.totalRecords = response.totalRecords;
      }
    });

  }
  addLeadGenerator() {
    this.submitted = true;
    let data = this.leadGenertorForm?.value;
    if (!this.leadGenertorForm.valid) {
      return
    } else {
      data = {
        "name": data.name,
        "userId": data.ownerId.id
      }
      this.serviceResponse$ = this._addWBAService.saveLeadGenerator(data);
      this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        if (resp) {
          this.selectedData = {
            id: resp,
            name: data.name
          };
          this.closeModal();
          this.messageEvent.emit(this.selectedData);
        }
      },
        ((error: any) => {
          if (error.status == 403 && this.unAthorizesd) {
            this.showError = true;
          }
        }));
    }
  }

  ngOnInit(): void {
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    var data: any = this.userDetails = sessionStorage.getItem('profileDetails');
    this.userDetails = JSON.parse(data);
    if (this.userDetails) {
      this.leadGenertorForm.get('ownerId').setValue(this.userDetails.displayName)
    }
    this._lookUpPaginator.totalRecords = this.lookupData.totalRecords;
    this.lookupData = this.lookupData.leadGeneratorList;
    if (this.LeadlookupFilterData != "" && this.LeadlookupFilterData != null && this.LeadlookupFilterData != undefined) {
      this.searchGrid = this.LeadlookupFilterData
      this.getLeadgeneratorList(this.searchGrid)
    }
    this.getOwnerList();
  }

  closeError() {
    this.showError = false;
    this.unAthorizesd = '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //To remove white spaces
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(
      element.target.value,
      ''
    );
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  getOwners(result: any) {
    let data = {
      searchType: "Owner",
      value: result.query
    };
    this.serviceResponse$ = this._addWBAService.getOwnerList(data);
    this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      this.ownerList = resp.userList;
    });
  }
  selectItem(item: any) {
    this.leadGenertorForm.controls['ownerId'].setValue({
      id: item.id,
      value: item.displayName
    });
  }

  getOwnerList() {
    let data = {
      lookUpType: "Owner",
      rowCount: 50,
      "searchText": this.searchGrid
    };
    this.serviceResponse$ = this._addWBAService.getWhiteBoardActivityLookUp(data);
    this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
      if (response) {
        this.ownerList = response.userList;
      }
    });
  }

  selctedValue(data: any, index: any) {
    this.HighlightRow = index;
    this.selectedData = data;
  }

  getLeadgeneratorList(result: any) {

    let data = {
      "lookUpType": "leadGenerator",
      "rowCount": 50
    };
    if (result === "" && !this.FilterBlankcheck) {
      return
    }
    else if (result === "" && this.FilterBlankcheck) {
      this.FilterBlankcheck = false;
      this.serviceResponse$ = this._addWBAService.getWhiteBoardActivityLookUp(data);
      this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
        if (response) {
          this.lookupData = response.leadGeneratorList;
          this._lookUpPaginator.totalRecords = response.totalRecords;
        }
      });
    }
    else {
      this.FilterBlankcheck = true;
      this.serviceResponse$ = this._addWBAService.getTypeheadList("leadGenerator", result);
      this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        if (resp) {
          this.lookupData = resp.leadGeneratorList;
          this._lookUpPaginator.totalRecords = resp.totalRecords;
        }
      })
    }
  }
  emitSelectedData() {
    this.messageEvent.emit(this.selectedData);
    this.passEntry.emit(this.selectedData.name);
    this.closeModal();
  }
  addwb() {
    this.visd = !this.visd;
    this.tbsd = !this.tbsd;
  }


  searchValue(inputValue: any) {
    this.searchGrid = inputValue;
    this.LeadlookupFilterData = "";
  }


  prospectPaginate(event: any) {
    var data = {
      pageNo: event.page + 1,
      pageSize: event.rows,
      lookUpType: "LeadGenerator",
      "searchText": this.searchGrid,
    }
    if (data) {
      this._loaderService.show();
      this.serviceResponse$ = this._addWBAService.lookupPagination(data);
      this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        if (resp) {
          this.lookupData = [...resp.items[0].leadGeneratorList];
          this._loaderService.hide();
        }
        else {
          this._loaderService.hide();
        }
      },
        (error: any) => {
          this._loaderService.hide();
        })
    }
  }

  //to calcel the process and back to prospect list screen
  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }
}
