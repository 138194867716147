export class QuoteDetailsMessage {
    quotedStatus = {
        required: 'Quote status is required.'
    }

    quotedPremium = {
        required: 'Quote Premium is required.'
    }

    comment = {
        required: 'Comment is required.'
    }

    quoteDocument = {
        required: 'Quote Document is required.'
    }

    clearanceDate = {
        required: 'Clearance Date is required.'
    }

    quoteDate = {
        required: 'Quote Date is required.'
    }
}

export class proposalMessage {
    coverPageTitle = {
        required: 'Title is required.'
    }

    prospect = {
        required: 'Prospect is required.'
    }

    presentedBy = {
        required: 'Presented By is required.'
    }

    hseDescription = {
        required: 'Description is required.'
    }

    piDescription = {
        required: 'Description is required.'
    }

    rmgAboutRisk = {
        required: 'About Risk is required.'
    }

    rmgDescription = {
        required: 'Description is required.'
    }

    rmgServices = {
        required: 'Services is required.'
    }

    ystResouceTitle = {
        required: 'Resource Name is required.'
    }

    ystResouceName = {
        required: 'Resource Title is required.'
    }

    summaryDifferences = {
        required: 'Differences is required.'
    }

    summaryServices = {
        required: 'Services Title is required.'
    }

    sbDescription = {
        required: 'Description is required.'
    }
}


