import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { WhiteBoardActivityService } from '../../services/white-board-activity.service';
import { EditWBActivityComponent } from '../edit-wbactivity/edit-wbactivity.component';

@Component({
  selector: 'app-getbyid-whiteboardactivity',
  templateUrl: './view-whiteboardactivity.component.html',
  styleUrls: ['./view-whiteboardactivity.component.css']
})
export class WhiteBoardActivityDetailComponent implements OnInit {
  WBA:any;
  id :any;
  _navigationPath:NavigationPath

  constructor(
    private _whiteBoardActivityService : WhiteBoardActivityService,
    private router : Router,
    private modalService: NgbModal
  ) {
    this._navigationPath = new NavigationPath();
    this.id = this.router.getCurrentNavigation()?.extras?.state?.id;
   }

  ngOnInit(): void {
    this.getViewWhiteBoardActivity()
  }
   getViewWhiteBoardActivity(){
     this._whiteBoardActivityService.getWBAById(this.id).subscribe(resp=>{
       this.WBA=resp.whiteBoardActivityInfo;
     })
   }

  //for navigation
  navigate() {
    this.router.navigateByUrl(this._navigationPath.getWhiteboardsUrl())
  }

  //to open 'Edit WBA' Modal
  openEditWBAModal() {
    
    const modalRef = this.modalService.open(EditWBActivityComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });
    let data = this.WBA
    modalRef.componentInstance.fromWBAList = data;
    modalRef.closed.subscribe(resp => {
      this.getViewWhiteBoardActivity();
    })
  }
}
