import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sendCarrierConfirmation',
  templateUrl: './sendCarrierConfirmation.component.html',
  styleUrls: ['./sendCarrierConfirmation.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SendCarrierConfirmationComponent implements OnInit, OnDestroy {
  @Input() submissionData: any;
  @Output() messageEvent = new EventEmitter<string>();
  unsubscribe$: Subject<boolean> = new Subject();
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  wbaId: number = 0;
  finalWbaSubmissionCarriers: any;
  showBtn: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._confirmBox = new ConfirmBox()

    // this._errorBox = new ErrorBox();
    // this._userMessages = new UserMessages();
    // this._navigationPath = new NavigationPath();
    // this._EmailTemplateMessage = new EmailTemplateMessage();
  }

  ngOnInit(): void {
    this.wbaId = parseInt(sessionStorage.getItem(environment.storageVariables.wbaId) || "0");

    this.finalWbaSubmissionCarriers = this.submissionData.wbaSubmissionCarriers;
    this.finalWbaSubmissionCarriers.forEach((res: any) => {
      res.isError = false;
      res.isContactError = false;
      res.contactEmailNotFound = false;

      if (!res.submissionMail && res.sentStatus) {
        res.isError = true;
      }

      if ((!res?.contactList || res?.contactList.length == 0) && !res.sentStatus && res.isChecked) {
        res.isContactError = true;
      }

      if (res?.contactList) {
        res?.contactList.forEach((e: any) => {
          if (!e?.email) {
            res.isContactError = true;
            res.contactEmailNotFound = true;
          }
        })
      }
    })

    let validCarrier = this.finalWbaSubmissionCarriers.find((x: { isError: boolean; isContactError: boolean; }) => x.isError == false && x.isContactError == false)
    if (validCarrier || this.submissionData.userList.length > 0) {
      this.showBtn = true;
    }
    else {
      this.showBtn = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //to calcel the process and back to prospect list screen
  cancel() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  emitSelectedData() {
    this.messageEvent.emit(this.submissionData);
    this.closeModal();
  }

}
