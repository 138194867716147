import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { CustomeRemoveNumberPipe } from 'src/app/shared/pipes/remove-number.pipe';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService, coverageRequestModel, coverageModel, coverageParameterModel } from '../../../services/white-board-activity.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { forkJoin, Subject } from 'rxjs';
import { DatacacheService } from '../../../services/datacache.service';

@Component({
  selector: 'app-coverage-checklist',
  templateUrl: './coverage-checklist.component.html',
  styleUrls: ['./coverage-checklist.component.css']
})
export class CoverageChecklistComponent implements OnInit {

  @Input() ApplicationData: any;
  @Input() masterCoverageListByLineTierAndIndustry: any;
  @Input() CoverageAssessmentData: any;
  @Input() tabName: any;
  @Input() businessLineIdfromApp: any;
  @Input() industryIdfromApp: any;
  @Input() tierIdfromApp: any;
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() isAssesmentPopUp: any = new EventEmitter<string>();
  @Output() coverageCheckListPayload: any = new EventEmitter<string>();
  @Output() noMasterCovData: any = new EventEmitter<boolean>();
  @Output() handleCheckListIconStatus: any = new EventEmitter<any>();

  public isSubmitted: boolean = false;
  public _navigationPath: NavigationPath;
  public CoverageCheckList: any = [];
  public businessStatus: any = [];
  public coverageList: any = [];
  public businessLineId: any;
  public MasterCoverageListNew: any;
  public _confirmBox: ConfirmBox;
  public _userMessages: UserMessages;
  public messageKey: string = '';
  public selectedCoverageDelName: string = '';
  public groups: any = [];

  private request: coverageRequestModel = {
    wbaId: 0,
    coverageAssessmentDetail: [],
    wbaService: []
  }
  public industryId: any;
  isPageDirty: boolean = false;
  @Input() tierId: any;
  activeWbaId: any;
  noMasterCov: boolean = false;
  tabRefresh: any;
  takingDataFromApi: any;



  constructor(
    private _router: Router,
    private _numberPipe: CustomeNumberPipe,
    private _removeNumberPipe: CustomeRemoveNumberPipe,
    private _addWBAService: WhiteBoardActivityService,
    private _loderService: LoaderService,
    private activeRouter: ActivatedRoute,
    private _dataCacheService: DatacacheService
  ) {
    this._confirmBox = new ConfirmBox();
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();

    let list = JSON.parse(sessionStorage.getItem(environment.storageVariables.CoverageCheckListStorage) || '[]');


    this.CoverageCheckList = {
      Coverages: list,
      CurrentCoverage: { displayName: '', coverageParameters: [] }
    }
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    this.request = {
      wbaId: parseInt(this.activeWbaId),
      coverageAssessmentDetail: [],
      wbaService: []
    }
    this.intializeData()
    // this.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId()
    window.scroll(0, 0);
  }

  getAssessmentDetail() {
    if (this.CoverageAssessmentData.coverageAssessmentDetail.length) {

      this.CoverageCheckList.Coverages = [];
      this.MasterCoverageListNew.forEach((element: any) => {
        element.isChecked = false;
        element.coverageParameters.forEach((param: any) => {
          param.isChecked = false
          if (param.isSystemDisabled) {
            param.isChecked = true;
            param.disabled = true;
          }
        })
      })
      this.CoverageAssessmentData.coverageAssessmentDetail.forEach((element: coverageModel) => {
        let coverageParamemter: any[] = [];
        // finding the coverages and checking them
        let slectedCoverage = this.MasterCoverageListNew.find((cov: any) => cov.id === element.coverageId);
        if (slectedCoverage) {
          // checking only selected coverage 
          slectedCoverage.isChecked = true;

          // finding the parameters of the coverages and checking them
          slectedCoverage.coverageParameters.filter((param: any) => element.coverages.some((obj: coverageParameterModel) => obj.coverageParameterId === param.id)).map((param: any) => {
            param.isChecked = true;

            let existingAssessmentParam = element.coverages.find((item: any) => param.id === item.coverageParameterId);
            if (existingAssessmentParam) {

              param.expiringValue = existingAssessmentParam.expiringValue;
              param.recommendedValue = existingAssessmentParam.recommendedValue;
              param.offeringGuide = existingAssessmentParam.offeringGuide;
              param.expiringConditionDescription = existingAssessmentParam.expiringConditionDescription;
              param.recommendedConditionDescription = existingAssessmentParam.recommendedConditionDescription;
              param.expiringAssessmentId = existingAssessmentParam.expiringAssessmentId;
              param.recommendedAssessmentId = existingAssessmentParam.recommendedAssessmentId;
            }

            coverageParamemter.push(param);
          });


          let CoverageToSend = { ...slectedCoverage };
          // CoverageToSend.coverageParameters.sort((a: { sequence: number; }, b: { sequence: number; }) => a.sequence - b.sequence);
          // CoverageToSend.coverageParameters = coverageParamemter;
          this.CoverageCheckList.Coverages.push(CoverageToSend);
        }

      });
      if (this.CoverageCheckList.Coverages.length) {
        sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList.Coverages));
      }
    }
    this.checkNoMasterCovData();
    if (this.noMasterCov) {
      this._confirmBox.isVisible = true;
      this._confirmBox.message = this._userMessages.noMasterCoverages;
      this.noMasterCovData.emit(true);
    } else {
      this.noMasterCovData.emit(false);
    }
  }

  checkNoMasterCovData() {
    if (this.MasterCoverageListNew) {
      for (const param of this.MasterCoverageListNew) {
        if (param.coverageParameters.length == 0) {
          this.noMasterCov = true;
        }
        else {
          this.noMasterCov = false;
          break;
        }
      }
    }
  }

  handleCoverage(Covg: any, type: string) {
    if (type === 'checkbox') {
      this.isPageDirty = true;
      Covg.isChecked = !Covg.isChecked;
      let selected = this.CoverageCheckList.Coverages;

      let index = selected.findIndex((obj: any) => obj.displayName.toLowerCase() === Covg.displayName.toLowerCase());
      let record = { ...Covg };
      if (index === -1) {

        record.coverageParameters.map((param: any) => {
          param.isChecked = true;
        });

        // sorting the coverageParameters
        selected.push(record);
      }
      else {

        record.coverageParameters.map((param: any) => {

          if (!param.disabled) param.isChecked = false;
        });

        selected.splice(index, 1);
      }

      this.handleCoverage(Covg, 'details');

      // sorting the coverages
      // selected.forEach((element: any) => {
      //   element.coverageParameters.sort((a: { parameterCategory: any; }, b: { parameterCategory: any; }) =>
      //     a.parameterCategory.localeCompare(b.parameterCategory));
      // })

    } else if (type === 'details') {

      if (!Covg) {
        let lines = this.MasterCoverageListNew;
        let DisplayName = lines[0].displayName;
        if (lines && lines.length) {
          this.CoverageCheckList.CurrentCoverage = lines.find((obj: any) => obj.displayName === DisplayName);
        }

        let categories: any[] = [];
        categories = this.CoverageCheckList.CurrentCoverage.coverageParameters.map((x: any) => x.parameterCategory);
        this.groups = new Set(categories);
      }
      else {
        this.CoverageCheckList.CurrentCoverage = { ...Covg }
        let categories: any[] = [];
        categories = this.CoverageCheckList.CurrentCoverage.coverageParameters.map((x: any) => x.parameterCategory);
        this.groups = new Set(categories);
      }
    }

  }

  handleCoverageParameter(param: any, coverage: any) {
    this.isPageDirty = true;
    param.isChecked = !param.isChecked;
    let record = this.CoverageCheckList.Coverages.find((obj: any) => obj.displayName.toLowerCase() === coverage.displayName.toLowerCase());
    if (record) {
      let coverageSpliceCheckedIndex = this.CoverageCheckList.Coverages.findIndex((obj: any) => obj.displayName.toLowerCase() === coverage.displayName.toLowerCase());
      let coverageCheckedIndex = coverage.coverageParameters.findIndex((obj: any) => obj.isChecked === true && !obj.disabled);
      if (coverageCheckedIndex === -1) {
        record.isChecked = false;
        let slectedCoverage = this.MasterCoverageListNew.find((cov: any) => cov.displayName.toLowerCase() === coverage.displayName.toLowerCase());
        if (slectedCoverage) {
          slectedCoverage.isChecked = false;
        }

        this.CoverageCheckList.Coverages.splice(coverageSpliceCheckedIndex, 1);
      }
      else {
        let slectedCoverage = this.MasterCoverageListNew.find((cov: any) => cov.displayName.toLowerCase() === coverage.displayName.toLowerCase());
        record.isChecked = true;
        if (slectedCoverage) {
          slectedCoverage.isChecked = true;
        }
      }

      let index = record.coverageParameters.findIndex((obj: any) => obj.displayName.toLowerCase() === param.displayName.toLowerCase());

      if (index === -1) {
        record.coverageParameters.push(param);
      } else {
        record.coverageParameters[index].isChecked = param.isChecked;

        // checking if coverage has to be unchecked in case only default parameters are slected now
        let toBeRemoved = !record.coverageParameters.some((obj: any) => !obj.disabled);

        // showing the confirm box to ask if the user wants to uncheck the coverage also
        if (toBeRemoved) {
          this.messageKey = 'uncheckCoverage';
          this.showAlert();

          this.selectedCoverageDelName = coverage.displayName;
        }
        let item = { ...coverage }
      }
    } else {
      coverage.isChecked = true;

      // checked-in the coverage as it is not checked yet but parameter is checked 
      let record1 = this.MasterCoverageListNew.find((obj: any) => obj.displayName.toLowerCase() === coverage.displayName.toLowerCase());
      record1.isChecked = true;

      let item = { ...coverage }
      let checkedParams = coverage.coverageParameters.filter((param: any) => param.isChecked);
      item.coverageParameters = checkedParams;

      // pushing the coverage parameter in selected coverage paramater array 
      this.CoverageCheckList.Coverages.push(item);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.businessLineId = changes.ApplicationData.currentValue.businessLineId;
    this.industryId = parseInt(changes.ApplicationData.currentValue.industryId);
    this.tierId = parseInt(changes.ApplicationData.currentValue.tierId);
  }

  DisplayData() {
    this.MasterCoverageListNew.forEach((element: any) => {
      element.isChecked = true;
      element.coverageParameters.forEach((param: any, index: number) => {
        if ((param.valueType == "" && param.isRequired == true) || (param.valueType != "" && param.isRequired == true)) {// param.valueType == "" || param.isRequired == true
          param.disabled = true;
        }
        param.isChecked = true;
      });

      //sorting coverage parameters by group name
      // element.coverageParameters.sort((a: { parameterCategory: any; }, b: { parameterCategory: any; }) =>
      //   a.parameterCategory.localeCompare(b.parameterCategory));

      this.CoverageCheckList.Coverages.push(element);
    });

    if (this.MasterCoverageListNew.length) this.handleCoverage(false, 'details');
    this.getAssessmentDetail();
  }

  intializeData() {
    // this.tabRefresh = this._dataCacheService.retrieveData('tabRefresh');
    this.masterCoverageListByLineTierAndIndustry = this._dataCacheService.retrieveData('wba_checkList_coverageList');
    this.CoverageAssessmentData = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    this.MasterCoverageListNew = this.masterCoverageListByLineTierAndIndustry;
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == "Coverage Checklist")
    if (this.masterCoverageListByLineTierAndIndustry.length == 0 || !this.CoverageAssessmentData) this.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId();
    else this.DisplayData();
  }

  GetMasterCoverageListByBusinessLineTierAndIndustryWbaId() {
    var masterCoverageListByBusinessLineTierAndIndustryWbaId = this._addWBAService.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId(this.businessLineIdfromApp, parseInt(this.industryIdfromApp), this.tierIdfromApp, (this.activeWbaId)); //to get masters coverages
    var coverageAssessmentDetails = this._addWBAService.getCoverageAssessmentDetails(this.activeWbaId); //to get saved coverages with assessment
    forkJoin([masterCoverageListByBusinessLineTierAndIndustryWbaId, coverageAssessmentDetails]).subscribe(resp => {
      this.masterCoverageListByLineTierAndIndustry = resp[0].coverageList;
      this.CoverageAssessmentData = resp[1];
      this.MasterCoverageListNew = this.masterCoverageListByLineTierAndIndustry;
      this._dataCacheService.compressAndCacheData('wba_checkList_coverageList', this.masterCoverageListByLineTierAndIndustry);
      // this._dataCacheService.compressAndCacheData('wba_checkList_CoverageAssessmentData', this.CoverageAssessmentData);
      this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.CoverageAssessmentData);
      if (this.MasterCoverageListNew?.length == 0) this.handleCheckListIconStatus.emit({ label: "Coverage Checklist", value: false });
      this.DisplayData();
    })

  }

  saveWBA(action: string) {
    if (this.CoverageCheckList.Coverages.length) {
      //checking for minimum one coverage has coverageparameters
      let checkParameterArrayLength: boolean = true;
      if (this.CoverageCheckList.Coverages.length > 0) {
        for (const element of this.CoverageCheckList.Coverages) {
          if (element.coverageParameters.length > 0) {
            checkParameterArrayLength = false
            break;
          }
        };
        if (checkParameterArrayLength) {
          this.messageKey = 'selectAtleastOneCoverage';
          this.showAlert();
          return;
        }
      }
      this.CoverageCheckList.Coverages.map((cov: any) => {
        let coverage: coverageModel = {
          coverageId: cov.id,
          coverageName: cov.displayName,
          coverages: [],
          ratingBasis: []
        }

        let existingAssessment = this.CoverageAssessmentData.coverageAssessmentDetail.find((savedCov: any) => savedCov.coverageId === cov.id)
        if (cov.coverageParameters.length > 0) {
          cov.coverageParameters.forEach((param: any) => {
            let existingAssessmentParam;
            if (existingAssessment) existingAssessmentParam = existingAssessment.coverages.find((item: any) => param.id === item.coverageParameterId)
            let coverageParameter: coverageParameterModel = {
              id: 0,
              groupName: param.parameterCategory,
              coverageParameterId: param.id,
              parameterName: param.displayName,
              expiringValue: (existingAssessmentParam ? existingAssessmentParam.expiringValue : ""),
              recommendedValue: (existingAssessmentParam ? existingAssessmentParam.recommendedValue : ""),
              offeringGuide: (existingAssessmentParam ? existingAssessmentParam.offeringGuide : ""),
              expiringConditionDescription: (existingAssessmentParam ? existingAssessmentParam.expiringConditionDescription : ""),
              recommendedConditionDescription: (existingAssessmentParam ? existingAssessmentParam.recommendedConditionDescription : ""),
              expiringAssessmentId: (existingAssessmentParam ? existingAssessmentParam.expiringAssessmentId : 0),
              recommendedAssessmentId: (existingAssessmentParam ? existingAssessmentParam.recommendedAssessmentId : 0),
            }

            if (!param.isSystemDisabled && param.valueType != "dropdown") {
              coverageParameter.expiringValue = (existingAssessmentParam ? existingAssessmentParam.expiringValue : "");
              coverageParameter.recommendedValue = (existingAssessmentParam ? existingAssessmentParam.recommendedValue : "")
            }

            if (param.isChecked)
              coverage.coverages.push(coverageParameter);
          });
        }
        else {
          //removing coverages with no parameters 
          // let covIndex = this.CoverageCheckList.Coverages.findIndex((obj: any) => obj.displayName.toLowerCase() === cov.displayName.toLowerCase());
          // if (covIndex > -1) {
          //   this.CoverageCheckList.Coverages.splice(covIndex, 1);
          // }
        }
        if (coverage.coverages.length)
          this.request.coverageAssessmentDetail.push(coverage);
      });

      //commenting for testing
      this.CoverageCheckList.Coverages.forEach((data: any) => {
        data.coverageParameters.forEach((param: any) => {
          if (!param.hasOwnProperty('expiringValue')) {
            param.expiringValue = ""
          }
          if (!param.hasOwnProperty('recommendedValue')) {
            param.recommendedValue = ""
          }
          if (param.displayName == 'EIN' && this.CoverageCheckList.CurrentCoverage.displayName == 'Workers Compensation') {
            param.expiringValue = this.ApplicationData.ein;
          }
        })
      });
      //removing coverages with no parameters 
      this.CoverageCheckList.Coverages.map((cov: any, index: any) => {
        if (cov.coverageParameters == 0) {
          this.CoverageCheckList.Coverages.splice(index, 1);
        }
      })

      sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList.Coverages));
      this.coverageCheckListPayload.emit(this.request);
      this._addWBAService.saveCoverageAssessmentForWBA(this.request).subscribe((response: any) => {
        this.getAssessmentDetails(action);

        // if (action === 'next') {
        //   this.nextTab.emit(this.tabName)
        //   this.isAssesmentPopUp.emit(true);
        // }
        // else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);


      });
    } else {
      this.messageKey = 'selectAtleastOneCoverage';
      this.showAlert();
    }
  }

  getAssessmentDetails(action: any) {
    this._addWBAService.getCoverageAssessmentDetails(parseInt(this.activeWbaId)).subscribe((resp: any) => {
      if (resp) {
        let coverageAssessData = resp;
        this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', coverageAssessData);
        if (action === 'next') {
          this.nextTab.emit(this.tabName)
          this.isAssesmentPopUp.emit(true);
        }
        else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
      }
    })
  }



  addNumberFormat(element: any, control?: AbstractControl) {
    var formattedNumber;
    formattedNumber = this._numberPipe.transform(element.target.value, 'USD', '1.0-0');
    element.target.value = formattedNumber;
    control?.setValue(element.target.value);
  }

  removeNumberFormat(element: any, val?: any) {
    var formattedNumber;
    formattedNumber = this._removeNumberPipe.transform(element.target.value);
    element.target.value = formattedNumber;
  }



  showAlert() {
    this._confirmBox.message = this._userMessages[this.messageKey as keyof messageModel];
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel(value: string) {
    this._confirmBox.isVisible = false;

    // unchecking the coverage and removing it from managed array
    if (value === 'yes') {
      let RecordIndex = this.CoverageCheckList.Coverages.findIndex((obj: any) => obj.displayName.toLowerCase() === this.selectedCoverageDelName.toLowerCase());

      if (RecordIndex > -1) {
        this.CoverageCheckList.Coverages.splice(RecordIndex, 1);
        let record1 = this.MasterCoverageListNew.find((obj: any) => obj.displayName.toLowerCase() === this.selectedCoverageDelName.toLowerCase());
        record1.isChecked = false;
      }
    } else if (value === 'no') {
      this.selectedCoverageDelName = '';
    }
  }

  setIsAssesmentPopup($event: any) {
    this.isAssesmentPopUp = $event;
  }

}

export interface messageModel {
  selectAtleastOneCoverage: string;
  uncheckCoverage: string;
}