import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-validate-policy',
  templateUrl: './validate-policy.component.html',
  styleUrls: ['./validate-policy.component.css']
})
export class ValidatePolicyComponent implements OnInit {
  @Input() public epicPolicyResponse: any;
  @Input() public transType: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  wbaId: any;
  updatePolicyInEpicResponse: any;
  _warningBox: ConfirmBox;
  policyId: any;
  isChecked: boolean = false;
  isClicked: boolean[] = [];
  prevIndex: any;

  constructor(private _wbaService: WhiteBoardActivityService,
    private activeRouter: ActivatedRoute,
    private modalService: NgbActiveModal
  ) { this._warningBox = new ConfirmBox() }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.wbaId = params.wbaId
      });
  }

  closeModal() {
    this.modalService.close();
  }

  updatePolicyInEpic() {
    let requestBody = {
      wbaId: this.wbaId,
      policyId: this.policyId,
      transType: this.transType == 'New Business' ? "NB" : 'RN'
    }

    this._wbaService.updateEpicInPolicy(requestBody).subscribe(res => {
      this.updatePolicyInEpicResponse = res;
      this.showWarningBox();
      this.passEntry.emit('Update Policy');
    });
  }

  showWarningBox() {
    let message = `Policy "${this.policyId}" Updated In Epic`;
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  currentPolicyClicked(policyId: any) {
    this.policyId = policyId;
    const index = this.epicPolicyResponse.findIndex((epicPolicy: any) => epicPolicy.policyID === policyId);
    if (index >= 0) {
      if (this.prevIndex >= 0) this.isClicked[this.prevIndex] = false;
      this.isClicked[index] = !this.isClicked[index];
    }
    this.prevIndex = index;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  isPolicyUpdateChecked() {
    this.isChecked = !this.isChecked
  }

  createPolicyInEPIC() {
    this.passEntry.emit('Create Policy');
  }

}
