import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { WhiteBoardActivityService } from '../../services/white-board-activity.service';

@Component({
  selector: 'app-delete-whiteboardactivity',
  templateUrl: './delete-whiteboardactivity.component.html',
  styleUrls: ['./delete-whiteboardactivity.component.css']
})
export class DeleteWhiteboardActivityComponent implements OnInit {
  @Input() fromWBAList: any;
  errorList: any = [];
  _confirmBox: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  _navigationPath: NavigationPath;

  constructor(
    private _whiteboardActivityService: WhiteBoardActivityService,
    private _router: Router,
    public _activeModal: NgbActiveModal,
  ) {
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox()
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this.openDeleteModel()
  }

  deleteWhiteboardActivity() {
    this._whiteboardActivityService.deleteWhiteboardActivityById(this.fromWBAList.id).subscribe(resp => {
      if (resp) {
        this._router.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl())
      }
    }, error => {
      let obj = error.error.errors;
      if (obj) {
        var arr = Object.keys(obj).map(key => (
          { type: key, value: obj[key] }
        ));
        this.errorList = arr;
      }
    })
  }
  closeModal() {
    this._activeModal.close();
  }
  confirmBoxCancel() {
    this.deleteWhiteboardActivity()
    setTimeout(() => {
      this._confirmBox.isVisible = false;
      this.closeModal();
    }, 1000)
  }
  openDeleteModel() {
    this._confirmBox.message = this._userMessages.deleteButtonMessage;
    this._confirmBox.isVisible = true;
  }
}
