import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { environment } from 'src/environments/environment';
import { WBANotifierService } from '../../services/wba-notifier.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatePolicyComponent } from '../add-whiteboard/validate-policy/validate-policy.component';

@Component({
  selector: 'app-renew',
  templateUrl: './renew.component.html',
  styleUrls: ['./renew.component.css']
})
export class RenewComponent implements OnInit {
  renewPolicyDetails: any = [];
  policy: any = {};
  account: any = '';
  lines: any = {};
  contact: any = {};
  _navigationPath: NavigationPath;
  accountClient: any;
  accountAddress: any;
  contactinfo: any;
  lineArray: any = [];
  businessLine: any = []
  policyTypeCode: any;
  accountservicingContacts: any = [];
  accountManager: any;
  producer: any;
  wbaId: any;
  wbaPolicyId: any;
  wbaData: any;
  businessLineName: any
  _errorBox: ErrorBox;
  _confirmBox: ConfirmBox;
  accountManagerName: any;
  producerName: any;
  validatePolicyResponse: any;
  checkValidationPopup: boolean = true;
  validationMessage: boolean = false;
  modalRef: any;
  wbaListId: any = 0;
  prospectId: any;
  _confirmBoxForRenew: ConfirmBox;
  isRenewButtonDisable: boolean = false;

  constructor(private _wbaService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    public route: Router,
    private activeRouter: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this._errorBox = new ErrorBox();
    this._navigationPath = new NavigationPath();
    this._confirmBox = new ConfirmBox();
    this._confirmBoxForRenew = new ConfirmBox();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.wbaId = params.wbaId
        this.wbaPolicyId = params.policyId,
          this.wbaListId = params.listId;
        this.prospectId = params.prospectId
      });
    let payload = {
      policyId: this.wbaPolicyId,
      wbaId: this.wbaId
    }
    this.getWbaPolicyRenewDetails(payload);
    this.ValidatePolicyInEpic();
  }

  //to get wba carriers list
  getWbaPolicyRenewDetails(data: any) {
    this._wbaService.getWbaPolicyRenewDetails(data).subscribe(resp => {
      if (resp) {
        this.renewPolicyDetails = resp?.policyDetails;
        this.policy = this.renewPolicyDetails?.policy;
        if (!this.renewPolicyDetails?.policy) {
          this._confirmBoxForRenew.message = 'Policy detail or contact detail not found in EPIC. Please submit a ticket to the IT along with this WBA-ID ' + "[" + this.wbaId + "]";
          this._confirmBoxForRenew.isVisible = true;
          this.isRenewButtonDisable = true;
        }
        this.policyTypeCode = this.policy?.policyTypeCode;
        this.account = this.renewPolicyDetails?.account?.accountName;
        //this.accountClient = this.renewPolicyDetails?.account?.clients;
        this.accountservicingContacts = this.renewPolicyDetails?.account?.servicingContacts;
        this.accountManager = this.accountservicingContacts?.find((x: { role: string; }) => x.role === 'Account Manager');
        this.producer = this.accountservicingContacts?.find((x: { role: string; }) => x.role === 'Producer');
        this.accountAddress = this.renewPolicyDetails?.account?.accountValue?.address;
        this.lines = this.renewPolicyDetails?.lines;
        this.lineArray = this.renewPolicyDetails?.lines;
        this.contact = this.renewPolicyDetails?.contact;
        this.contactinfo = this.renewPolicyDetails?.contact?.contactInfo;
        this.getBusinessLineList();
        this.getUserbyLookupCode();
      }
    }, ((errorResponse: any) => {
      if (errorResponse.status == 500) {
        this._confirmBoxForRenew.message = 'Policy detail or contact detail not found in EPIC. Please submit a ticket to the IT along with this WBA-ID ' + "[" + this.wbaId + "]";
        this._confirmBoxForRenew.isVisible = true;
        this.isRenewButtonDisable = true;
      }
    }));
  }

  getUserbyLookupCode() {
    if (this.accountManager?.employeeLookupCode) {
      let data = this.accountManager?.employeeLookupCode;
      this._wbaService.getUserbyLookupCode(data).subscribe(res => {
        this.accountManagerName = res?.user?.display_name;
      })
    }
    if (this.producer?.employeeLookupCode) {
      let data = this.producer?.employeeLookupCode;
      this._wbaService.getUserbyLookupCode(data).subscribe(res => {
        this.producerName = res?.user?.display_name;
      })
    }
  }

  //to get Business Line list
  getBusinessLineList() {
    this._wbaService.getBusinessLineList().subscribe(resp => {
      if (resp) {
        this.businessLine = resp.businessLineList;
        this.businessLineName = this.businessLine.find((x: { policyTypeCode: any; }) => x.policyTypeCode === this.policyTypeCode)?.businessLineName;
      }
    })
  }

  closeModal() {
    this.route.navigateByUrl('/' + this._navigationPath.getWhiteboardsUrl())
  }

  createwbaRenewpolicy() {
    if (this.validationMessage) {
      this.showWarningBox();
    } else if (this.validatePolicyResponse && this.validatePolicyResponse.length && this.checkValidationPopup) {
      this.openValidatePolicyModal();
    } else {
      let data = {
        policyId: this.wbaPolicyId,
        wbaId: this.wbaId
      }
      this._wbaService.createWbaRenewalPolicy(data).subscribe(resp => {
        if (resp) {
          if (resp.wbaId > 0) {
            sessionStorage.setItem(environment.storageVariables.wbaId, resp.wbaId);
            this._notifierService.setValue(resp.wbaId);
            this.wbaId = resp.wbaId;
            // this.uploadEpicFilesOnEfileCabinet();
            // this._wbaService.getWBAById(resp.wbaId).subscribe(resp => {
            if (this.modalRef) this.modalRef.close();
            sessionStorage.setItem('paylpadForMasterCoveragesAPI', JSON.stringify(resp.whiteBoardActivityInfo));
            this.route.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl(),], { queryParams: { wbaId: this.wbaId, listId: this.wbaListId, prospectId: this.prospectId } });
            // });
          }
          else {
            this.showErrorBoxForPolicyID(resp.message)
          }
        }
      },
        ((errorResponse: any) => {
          if (errorResponse.status == 400) {
            if (errorResponse.error.errors.WbaId[0]) {
              this.showErrorBox(errorResponse.error.errors.WbaId[0])
            } if (errorResponse.error.errors?.policyId) {
              this.showErrorBoxForPolicyID(errorResponse.error.errors?.policyId)
            }
          }
        }));
    }
  }

  showErrorBox(message: string) {
    this._confirmBox.message = message;
    this._confirmBox.isVisible = true;
  }
  showErrorBoxForPolicyID(message: string) {
    this._errorBox.message = message;
    this._errorBox.isVisible = true;
  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
    this._confirmBox.isVisible = false;
  }
  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this._errorBox.isVisible = false;
  }
  showWarningBox() {
    let message = this.validatePolicyResponse?.message
    this._confirmBox.message = message;
    this._confirmBox.isVisible = true;
  }

  uploadEpicFilesOnEfileCabinet() {
    let data = {
      policyId: this.wbaPolicyId,
      wbaId: this.wbaId
    }
    this._wbaService.uploadEpicFilesOnEfileCabinet(data).subscribe(resp => {
      if (resp) {
        console.log(resp);
      }
    },
      ((errorResponse: any) => {
        console.log(errorResponse);
      }));
  }

  ValidatePolicyInEpic() {
    this._wbaService.validateRenewedEpicInPolicy(this.wbaId).subscribe(res => {
      this.validatePolicyResponse = res;
      res?.message ? this.validationMessage = true : this.validationMessage = false;
    });
  }

  openValidatePolicyModal() {
    this.modalRef = this.modalService.open(ValidatePolicyComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });
    this.modalRef.closed.subscribe((resp: any) => {
    })
    this.modalRef.componentInstance.epicPolicyResponse = this.validatePolicyResponse;
    this.modalRef.componentInstance.transType = 'Renew';
    this.modalRef.componentInstance.passEntry.subscribe((res: any) => {
      this.checkValidationPopup = false;
      if (res) this.createwbaRenewpolicy();
    });
  }
}
