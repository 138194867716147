<!-- Telrik start  -->
<div class="main-body-con">
    <div class="page-container pt-4">
        <div class="page-hdr-outer ps-4 pe-4">
            <div class="p-2 pt-1">

                <!-- <h2>Whiteboard Activity List</h2> -->
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-7">
                                <div class="flex-div align-items-center" id="pros-dp">

                                    <a [ngClass]="{'green' : !pinUnpinList, 'red': pinUnpinList}"
                                        (click)="updateUserForProspect()" title="" class="pe-4">
                                        <span><img src="assets/images/pin.png" alt="" width="16px" class="pin" /></span>
                                    </a>
                                    <p-dropdown *ngIf="prospectCreateList" filterBy="label"
                                        (onChange)="onCreateListChange($event,'onchange')" optionGroupChildren="items"
                                        [options]="prospectCreateList" optionLabel="label" optionValue="value"
                                        placeholder="{{selectedDefaultListName}}" [(ngModel)]="selectedDefaultList"
                                        [group]="true" [filter]="true">
                                        <ng-template let-group pTemplate="group">
                                            <div class="flex align-items-center">
                                                <span>{{ group.label }}</span>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <button *ngIf="selectedListId == '0'" class="filter-button ms-3 mx-0"
                                        [ngClass]="{ actbtn: (isFilter1||filterValueCopy.filters.length>0) }"
                                        data-bs-toggle="modal" data-bs-target="#exampleModal"> <img
                                            src="assets/images/filter.svg" alt="" class="flt-icon" />
                                    </button>


                                </div>
                            </div>


                            <div class="col-12 col-sm-12 col-md-5">
                                <div class="flex-div justify-content-end ht-col">


                                    <div class="dropdown reset me-3">

                                        <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton3"
                                            data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                            style="color: #122B74 !important;cursor:pointer  !important;"><label
                                                class="ps-2"><img src="assets/images/reset.png" class="me-2 import-img"
                                                    width="13px" alt="" />Reset</label></a>

                                        <div class="dropdown-menu reset-menu-div" aria-labelledby="dropdownMenuButton3">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="resetAll"
                                                    (change)="onChangeResetAll($event)">
                                                <label class="form-check-label" for="resetAll">
                                                    Reset All
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="filter"
                                                    [(ngModel)]="selectResetArr['filter']">
                                                <label class="form-check-label" for="filter">
                                                    Filter
                                                </label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="column"
                                                    [(ngModel)]="selectResetArr['column']">
                                                <label class="form-check-label" for="column">
                                                    Column
                                                </label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="sorting"
                                                    [(ngModel)]="selectResetArr['sorting']">
                                                <label class="form-check-label" for="sorting">
                                                    Sorting
                                                </label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="columnFilter" [(ngModel)]="selectResetArr['columnFilter']">
                                                <label class="form-check-label" for="columnFilter">
                                                    Column Filter
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="width"
                                                    [(ngModel)]="selectResetArr['width']">
                                                <label class="form-check-label" for="width">
                                                    Column Width
                                                </label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="columnSequence" [(ngModel)]="selectResetArr['columnSequence']">
                                                <label class="form-check-label" for="columnSequence">
                                                    Column Sequence
                                                </label>
                                            </div>

                                            <div class="flex-div"><button class="btn btn-outline-primary me-3"
                                                    (click)="selectResetArr={};">Clear</button> <button
                                                    class="btn btn-primary"
                                                    (click)="resetAllfunctionality()">Apply</button></div>
                                        </div>
                                    </div>

                                    <div class="dropdown export-dropdown">
                                        <a href="javascript:void(0)" class="dropdown-toggle" id="dropdownMenuButton1"
                                            style="color: #122B74 !important;cursor:pointer  !important;"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <label class="ps-2"><img src="assets/images/import.svg"
                                                    class="me-2 import-img" width="13px" alt="" />Export</label>
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li (click)="DownloadExportFile('Selected')"><a href="javascript:void(0)"
                                                    [style.pointer-events]="mySelection.length==0 ? 'none' : 'auto'"
                                                    [style.color]="mySelection.length==0 ? 'grey !important' : '#122B74 !important'"
                                                    style="color: #122B74 !important;cursor:pointer  !important;"
                                                    class="dropdown-item">Selected
                                                    WBA</a></li>
                                            <li *ngIf="isExportVisible" (click)="DownloadExportFile('All')"><a
                                                    href="javascript:void(0)"
                                                    style="color: #122B74 !important;cursor:pointer  !important;"
                                                    title="" class="dropdown-item">All WBA</a></li>
                                        </ul>
                                    </div>

                                    <a class="ps-4" href="javascript:void(0)"
                                        style="color: #122B74 !important;cursor:pointer  !important;"
                                        [routerLink]="[ _navigationPath.getwhiteboardsAddWhiteboardUrl()]">
                                        <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Add
                                            Whiteboard
                                            Activity</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showError">
                <div class="col-12">
                    <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                        <ul class="view-error">
                            <li *ngIf="unAthorizesd != ''">{{unAthorizesd}}</li>
                            <ng-container *ngFor="let err of errorList">
                                <li>{{err}}</li>
                            </ng-container>
                        </ul>
                        <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>

            <!-- <div class="search-panel mb-3">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-7">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-7">
                                <div class="row">
                                    <div class="flex-div align-items-center" id="pros-dp">

                                        <a [ngClass]="{'green' : !pinUnpinList, 'red': pinUnpinList}"
                                            (click)="updateUserForProspect()" title="" class="pe-4">
                                            <span><img src="assets/images/pin.png" alt="" width="16px"
                                                    class="pin" /></span>
                                        </a>
                                        <p-dropdown *ngIf="prospectCreateList" filterBy="label"
                                            (onChange)="onCreateListChange($event,'onchange')"
                                            [options]="prospectCreateList" [(ngModel)]="selectedDefaultList"
                                            ngDefaultControl [group]="true" [filter]="true">
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                    <span>{{ group.label }}</span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <button class="filter-button ms-3 mx-0" [ngClass]="{ actbtn: isFilter1 }"
                                            data-bs-toggle="modal" data-bs-target="#exampleModal"> <img
                                                src="assets/images/filter.svg" alt="" class="flt-icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="filterdiv">
                <div class="tbl-top-actions">

                    <div class="quick-ac flex-div">
                        <ng-container *ngIf="mySelection && mySelection.length > 0">

                            <!-- <a (click)="exportSelectedwba('multi')" href="javascript:void(0)" title=""
                                class="flex-div me-3"><img src="../../assets/images/excel.svg" alt="Export" class="me-2"
                                    width="10px" />Export</a> -->

                            <a (click)="openRemoveWbaFromListPopUp('')" href="javascript:void(0)" title=""
                                style="color: #122B74 !important;cursor:pointer  !important;" class="flex-div me-3"><img
                                    src="../../assets/images/remove.svg" alt="Should Not be on List" class="me-2" />Hide
                                Unselected
                            </a>
                            <a (click)="unHidewbaListData()" [ngClass]="{ isDisabled: unHideFlagForCheckBox }"
                                style="color: #122B74 !important;cursor:pointer  !important;" href="javascript:void(0)"
                                title="" class="flex-div me-3"><img src="../../assets/images/excel.svg" alt="Export"
                                    class="me-2" width="10px" />Unhide</a>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-con">
            <div class="grid__wrapper wrp-pros">
                <!-- <div *ngIf="hideShowFilter" class="accordion mb-3" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="filter-button me-3 mx-0" [ngClass]="{ actbtn: isFilter1 }"> <img
                                        src="assets/images/filter.svg" alt="" class="flt-icon" /></span> Filter
                                Whiteboard Activities
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse"
                            [ngClass]="this.filterValue.filters.length ? 'show' : '' " aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body" *ngIf="filterBlock">
                                <kendo-filter #filter [filters]="filters" [value]="filterValue"
                                    (valueChange)="onFilterChange($event)">
                                </kendo-filter>
                                <div class="apply-filter-btn">
                                    <button kendoButton (click)="applyFilter(filter.value,isFilter1)">
                                        Apply filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <ng-template let-currentItem #template1>
                    <kendo-multiselect [checkboxes]="true" [data]="statesList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [ngModel]="getMultiselectValue(currentItem)" [valuePrimitive]="true" textField="stateCode"
                        valueField="stateCode">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #template2>
                    <kendo-multiselect [checkboxes]="true" [data]="businessLine"
                        [ngModel]="getMultiselectValue(currentItem)"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" textField="businessLineName" valueField="businessLineName">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #template3>
                    <kendo-multiselect [checkboxes]="true" [data]="statesList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                        [ngModel]="getMultiselectValue(currentItem)">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForPolicy>
                    <kendo-multiselect [checkboxes]="true" [data]="policyTypeList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                        [ngModel]="getMultiselectValue(currentItem)" textField="businessLineName"
                        valueField="businessLineName">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForProgress>
                    <kendo-multiselect [checkboxes]="true" [data]="progress"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)" textField="progressName"
                        valueField="progressName">
                    </kendo-multiselect>
                </ng-template>

                <ng-template let-currentItem #templateForResult>
                    <kendo-multiselect [checkboxes]="true" [ngModel]="getMultiselectValue(currentItem)"
                        [autoClose]="false" [data]="resultListForGrid" [valuePrimitive]="true"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" textField="epicStatusName"
                        valueField="epicStatusName">
                    </kendo-multiselect>
                </ng-template>

                <ng-template let-currentItem #templateForClientStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="clientStatus"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" textField="stateCode" valueField="stateCode"
                        [ngModel]="getMultiselectValue(currentItem)" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForRecallStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="recallForStatusList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)"
                        [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForWbaStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="wbaForStatusList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)"
                        [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForWbaBussinessStatus>
                    <kendo-multiselect [checkboxes]="true" [data]="businessStatusList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)"
                        textField="businessStatusName" valueField="businessStatusName"
                        [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForPrimaryIndustry>
                    <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValueByid(currentItem)"
                        textField="prospectIndustryName" valueField="id">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateForSecondaryIndustry>
                    <kendo-multiselect [checkboxes]="true" [data]="prospectIndustryList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValueByid(currentItem)"
                        textField="prospectIndustryName" valueField="id">
                    </kendo-multiselect>
                </ng-template>
                <ng-template let-currentItem #templateprofitCenter>
                    <kendo-multiselect [checkboxes]="true" [data]="profitCenterData"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [ngModel]="getMultiselectValue(currentItem)" [valuePrimitive]="true" textField="stateCode"
                        valueField="stateCode" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template>
                <!-- <ng-template let-currentItem #templatewbaOwner>
                    <kendo-multiselect [checkboxes]="true" [data]="userList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [ngModel]="getMultiselectValue(currentItem)" [valuePrimitive]="true" textField="display_name"
                        valueField="display_name" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template> -->
                <ng-template let-currentItem #templateForNumber>
                    <kendo-numerictextbox [style.width.px]="170" format="n0"
                        (valueChange)="onChangeForNumber($event,currentItem)"
                        [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                </ng-template>
                <ng-template let-currentItem #templateForNumberEXP>
                    <kendo-numerictextbox [style.width.px]="170" format="n2"
                        (valueChange)="onChangeForMultiselect($event,currentItem)"
                        [value]="getNumericValueEXP(currentItem)"></kendo-numerictextbox>
                </ng-template>
                <ng-template let-currentItem #templateForNumberWithoutComma>
                    <kendo-numerictextbox [style.width.px]="170" format="00"
                        (valueChange)="onChangeForNumber($event,currentItem)"
                        [value]="getNumericValue(currentItem)"></kendo-numerictextbox>
                </ng-template>
                <ng-template let-currentItem #templateForUserName>
                    <kendo-multiselect [checkboxes]="true" [data]="userList"
                        (valueChange)="onChangeForMultiselect($event,currentItem)" [autoClose]="false"
                        [valuePrimitive]="true" [ngModel]="getMultiselectValue(currentItem)" textField="display_name"
                        valueField="display_name" [ngModelOptions]="{standalone: true}">
                    </kendo-multiselect>
                </ng-template>
                <kendo-grid [reorderable]="ColumnReorderable" filterable="menu" kendoGridSelectBy="id"
                    [(selectedKeys)]="mySelection" [selectable]="selectableSettings"
                    (filterChange)="gridFilterChange($event)" [data]="finalWBAList" [sortable]="true" [sort]="sort"
                    [resizable]="true" (sortChange)="sortChange($event)" [skip]="skip" [filter]="gridFilters"
                    [pageable]="{ pageSizes: true, position: 'top' }" [pageSize]="pageSize"
                    (pageChange)="paginationChange($event)"
                    [sortable]="{allowUnsort: false,mode: false ? 'multiple' : 'single'}"
                    (columnReorder)="onColumnReorder($event)" (columnVisibilityChange)="onColumnMenuInit($event)"
                    (columnResize)="columnResize($event)" (cellClick)="onCellClick($event)" [virtualColumns]="true"
                    (dblclick)="onCellDoubleClick($event)" #grid>
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <div class="flex-div justify-content-between grid-hd">
                            <!-- <div></div> -->
                            <div class="flex-div">
                                <kendo-textbox placeholder="Search Company Name" #searchText
                                    (input)="searchValue(searchText.value)"
                                    [ngModel]="filterData ? filterData.searchText: filterDataEmpty" (ngModelChange)="filterData ? filterData.searchText : filterDataEmpty = $event"
                                    [style.width.px]="205"></kendo-textbox><img src="assets/images/search.svg"
                                    (click)="onSearch()" alt="" class="ms-2" />
                            </div>

                            <div class="flex-div">
                                <div class="flex-div">
                                    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="buttonCount">
                                    </kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons></kendo-pager-next-buttons>
                                    <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
                                    <kendo-grid-messages pagerItems="Items" [pagerItemsPerPage]="'Items per page'">
                                    </kendo-grid-messages>
                                  <kendo-pager-info  size="medium" class="bd-l"></kendo-pager-info>
                                </div>

                              <button [ngClass]="{ actbtn: isFilter }" class="filter-button-choose"
                                      (click)="setStyleOfPopup()">
                                <kendo-grid-column-chooser [allowHideAll]="false"></kendo-grid-column-chooser>
                              </button>

                            </div>
                        </div>

                        <!-- <button [ngClass]="{ actbtn: isFilter }" (click)="columnFilter(isFilter)" class="filter-button">
                            <img src="assets/images/filter.svg" alt="" class="flt-icon" />
                        </button> -->
                    </ng-template>
                    <kendo-grid-column [reorderable]="false" [resizable]="false" title="Actions" [width]="62"
                        field="Actions" [filterable]="false" [sortable]="false" [includeInChooser]="false" class="overflow-visible">
                        <ng-template kendoGridCellTemplate let-rowData>
                            <div class="flex-div">
                                <div class="ren-pos" style="cursor: pointer;;"
                                    *ngIf="(rowData?.outcome =='Bound' && rowData?.epicUniquePolicyId  > 0 && rowData?.policyExpirationDate >= currentDate )  && (rowData.renewed==false) else blnk">
                                    <a href="javascript:void(0)" (click)="renewById(rowData)" pTooltip="Renew"
                                        tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">
                                        <img src="../../assets/images/renew.png" alt="" />
                                    </a>
                                </div>
                                <ng-template #blnk>
                                    <div class="ren-pos"><img src="../../assets/images/blnk.png" alt="" /></div>
                                </ng-template>
                                <a href="javascript:void(0)" title="" class="tbl-act pointer list-action"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img
                                        src="assets/images/action.svg" alt="" /></a>

                                <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                                    <!-- <li><a class="dropdown-item" href="javascript:void(0)"
                                            (click)="executiveSummaryById(rowData,rowData.prospectId)">
                                            <img src="../../assets/images/user1.png" alt="Executive Summary"
                                                class="exesum"><span>Executive
                                                Summary</span></a></li> -->
                                    <li><a class="dropdown-item" [routerLink]="'/whiteboard-activities/application'"
                                            [queryParams]="{wbaId: rowData.id,listId:selectedListId,prospectId:rowData.prospectId}">
                                            <img src="../../assets/images/edit2.svg" alt="Edit Whiteboard"><span>Edit
                                                Whiteboard</span></a></li>
                                    <li *ngIf="isValidUser"><a class="dropdown-item" href="javascript:void(0)"
                                            (click)="openDeleteModel(rowData.id)"><img
                                                src="../../assets/images/trash.svg" alt="Delete Whiteboard"><span>Delete
                                                WBA</span></a></li>
                                    <li
                                        *ngIf="((rowData.outcome == 'Lost' || rowData.outcome == 'Bound') && (rowData.id != id)) && isValidUserwbaOpen">
                                        <a class="dropdown-item" href="javascript:void(0)"
                                            (click)="reOpenModel(rowData.id)"><img src="../../assets/images/reas.png"
                                                alt="Reopen Whiteboard"><span>Reopen
                                            </span></a>
                                    </li>
                                    <li
                                        *ngIf="(rowData?.outcome =='Bound' && rowData?.epicUniquePolicyId  > 0 && rowData?.policyExpirationDate >= currentDate )  && (rowData.renewed==false)">
                                        <a class="dropdown-item" href="javascript:void(0)"
                                            (click)="renewById(rowData)"><img src="../../assets/images/reassign.svg"
                                                alt="Renew"><span>Renew</span>
                                        </a>
                                    </li>
                                    <li
                                        *ngIf="(rowData?.transType == 'NB' && (rowData?.isBorDefined=='No' || rowData?.isBorDefined=='' || rowData?.isBorDefined=='null'))">
                                        <a class="dropdown-item" href="javascript:void(0)"
                                            (click)="openCloneBorModal(rowData)"><img
                                                src="../../assets/images/clone.svg" alt="Clone Whiteboard"><span>Create
                                                a Clone</span>
                                        </a>
                                    </li>
                                    <li *ngIf="(isBorRenuewshow && (rowData?.transType == 'NB' &&
                               (rowData?.isBorDefined=='Yes' && rowData?.outcome == 'Bound')))">
                                        <a class="dropdown-item" href="javascript:void(0)"
                                            (click)="openCloneBorModal(rowData)"><img
                                                src="../../assets/images/clone.svg" alt="Clone Whiteboard"><span>BOR
                                                Renewal</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-checkbox-column [reorderable]="false" [resizable]="false" [width]="35"
                        [headerClass]="'pro-col'">

                        <ng-template kendoGridCellTemplate let-idx="rowIndex" let-rowData>
                            <div class="pro-checkbox">
                                <input [checked]="rowData.checkedRowForExport" (click)="changeSelection(rowData)"
                                    [kendoGridSelectionCheckbox]="idx" class="form-check-input" />
                            </div>
                        </ng-template>
                        <ng-template kendoGridHeaderTemplate>
                            <input type="checkbox" class="form-check-input" kendoGridSelectAllCheckbox
                                (click)="changeAllSelection()" />
                        </ng-template>

                    </kendo-grid-checkbox-column>
                    <!-- <kendo-grid-column [minResizableWidth]="100" [width]="100" [reorderable]="false"
                        [maxResizableWidth]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div>
                                <ng-container
                                    *ngIf="(dataItem?.outcome =='Bound' && dataItem?.epicUniquePolicyId  > 0 && dataItem?.policyExpirationDate >= currentDate )  && (dataItem.renewed==false)">
                                    <div class="es-link">
                                        <a href="javascript:void(0)" class="btn btn-outline-primary"
                                            (click)="renewById(dataItem)"><img src="../../assets/images/reassign.svg"
                                                alt="Renew">&nbsp;Renew
                                        </a>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </kendo-grid-column> -->
                    <kendo-grid-column [hidden]="isGridColumnVisible('Priority')" [width]="GridColumnWidth('Priority')"
                        field="masterWBAPriorityId" title="Priority">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                            </kendo-grid-string-filter-menu>
                        </ng-template> -->
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <number-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService" [format]="'n0'">
                            </number-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="GridColumnWidth('Prospect Name')" field="companyName"
                        title="Prospect Name" [hidden]="isGridColumnVisible('Prospect Name')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd ">
                                <a href="javascript:void(0);" id="{{dataItem.id}}_name"
                                    (click)="onCellClickProspect(dataItem,$event,true)" title=""
                                    (contextmenu)="onContextMenu($event, dataItem)" title=""
                                    pTooltip="{{dataItem.companyName}}"
                                    style="color: #122B74 !important;cursor:pointer  !important;"
                                    tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.companyName}}</a>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-string-filter-menu [column]="column" [filter]="filter"
                                [filterService]="filterService" operator="startswith">
                            </kendo-grid-string-filter-menu>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="GridColumnWidth('Line')" field="masterBusinessLine" title="Line"
                        [hidden]="isGridColumnVisible('Line')">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                            <kendo-multiselect [checkboxes]="true" #multiselect [(ngModel)]="gridFilterArrayLob"
                                [autoClose]="true" [data]="businessLine" [valuePrimitive]="true"
                                (valueChange)="onLineOfBusinessChange($event)" textField="businessLineName"
                                valueField="businessLineName">
                            </kendo-multiselect>
                        </ng-template> -->
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.masterBusinessLine}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.masterBusinessLine}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="businessLine" textField="businessLineName"
                                valueField="businessLineName" [currentFilter]="filter" [filterService]="filterService"
                                [isPrimitive]="false" [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="policyType" title="Policy Type" [width]="GridColumnWidth('Policy Type')"
                        [hidden]="isGridColumnVisible('Policy Type')">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter>
                        <kendo-multiselect [checkboxes]="true" [(ngModel)]="gridFilterArrayPolicyType"
                            [autoClose]="true" [data]="policyTypeList" [valuePrimitive]="true"
                            (valueChange)="onPolicyTypeChange($event)" textField="businessLineName"
                            valueField="businessLineName">
                        </kendo-multiselect>
                    </ng-template> -->
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.policyType}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.policyType}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="policyTypeList" textField="businessLineName"
                                valueField="businessLineName" [currentFilter]="filter" [filterService]="filterService"
                                [isPrimitive]="false" [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column field="effectiveDate" title="Eff Date" [width]="GridColumnWidth('Eff Date')"
                        [hidden]="isGridColumnVisible('Eff Date')" filter="date" format="{0:d}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                {{dataItem.effectiveDate
                                | date:'MM/dd/yyyy'}}</div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <date-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService">
                            </date-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column class="text-right" field="targetPremium" title="Tgt Prem"
                        [width]="GridColumnWidth('Tgt Prem')" [hidden]="isGridColumnVisible('Tgt Prem')"
                        format="{0:c0}">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-numeric-filter-menu class="numeric-fld" [column]="column" [filter]="filter"
                                [filterService]="filterService">
                            </kendo-grid-numeric-filter-menu>
                        </ng-template> -->
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <number-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService" [format]="'n0'">
                            </number-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="ownerUserName" title="Owner" [hidden]="isGridColumnVisible('Owner')"
                        [width]="GridColumnWidth('Owner')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.ownerUserName}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.ownerUserName}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="userList" textField="display_name" valueField="display_name"
                                [currentFilter]="filter" [filterService]="filterService" [isPrimitive]="false"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="marketingManager" title="Mktg Mgr"
                        [hidden]="isGridColumnVisible('Mktg Mgr')" [width]="GridColumnWidth('Mktg Mgr')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.marketingManager}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.marketingManager}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="userList" textField="display_name" valueField="display_name"
                                [currentFilter]="filter" [filterService]="filterService" [isPrimitive]="false"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="progressName" title="Progress" [width]="GridColumnWidth('Progress')"
                        [hidden]="isGridColumnVisible('Progress')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.progressName}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.progressName}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="progress" textField="progressName" valueField="progressName"
                                [currentFilter]="filter" [filterService]="filterService" [isPrimitive]="false"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="epicStatusName" title="Result" [hidden]="isGridColumnVisible('Result')"
                        [width]="GridColumnWidth('Result')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.epicStatusName}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.epicStatusName}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="resultListForGrid" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="masterBusinessStatus" title="Business Status"
                        [hidden]="isGridColumnVisible('Business Status')" [width]="GridColumnWidth('Business Status')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.masterBusinessStatus}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.masterBusinessStatus}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="businessStatusList" textField="businessStatusName"
                                valueField="businessStatusName" [currentFilter]="filter" [filterService]="filterService"
                                [isPrimitive]="false" [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="state" title="St" [hidden]="isGridColumnVisible('St')"
                        [width]="GridColumnWidth('St')">

                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="statesList" textField="stateCode" valueField="stateCode"
                                [currentFilter]="filter" [filterService]="filterService" [isPrimitive]="false"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="accountManagerUserName" title="Acct Mgr"
                        [hidden]="isGridColumnVisible('Acct Mgr')" [width]="GridColumnWidth('Acct Mgr')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.accountManagerUserName}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.accountManagerUserName}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="userList" textField="display_name" valueField="display_name"
                                [currentFilter]="filter" [filterService]="filterService" [isPrimitive]="false"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column class="text-right" field="expectedBoundRevenue" title="Proj Rev"
                        [width]="GridColumnWidth('Proj Rev')" [hidden]="isGridColumnVisible('Proj Rev')"
                        format="{0:c0}">
                        <!-- <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <kendo-grid-numeric-filter-menu class="numeric-fld" [column]="column" [filter]="filter"
                                [filterService]="filterService">
                            </kendo-grid-numeric-filter-menu>
                        </ng-template> -->
                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <number-range-filter [field]="column.field" [filter]="filter"
                                [filterService]="filterService" [format]="'n0'">
                            </number-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('ACORD Status')" field="acordStatus"
                        title="ACORD Status" [width]="GridColumnWidth('ACORD Status')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                <p title="" pTooltip="{{dataItem.acordStatus}}" tooltipPosition="bottom"
                                    tooltipStyleClass="tooltipstyle">{{dataItem.acordStatus}}</p>
                            </div>
                        </ng-template>
                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="filterForAcordStatus" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Loss Runs Status')" field="lossRun"
                        title="Loss Runs Status" [width]="GridColumnWidth('Loss Runs Status')">

                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="filterForLossRunStatus" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Application')" field="application"
                        title="Application" [width]="GridColumnWidth('application')">

                        <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter"
                            let-filterService="filterService">
                            <multicheck-filter [data]="filterForApplicationStatus" [currentFilter]="filter"
                                [filterService]="filterService" [isPrimitive]="true"
                                [field]="column.field"></multicheck-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [hidden]="isGridColumnVisible('Proposal Date')" field="proposalDate"
                        title="Proposal Date" filter="date" format="{0:d}" [width]="GridColumnWidth('Proposal Date')">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="cr-wd rm-style">
                                {{
                                dataItem.proposalDate
                                | date: "MM/dd/yyyy"
                                }}
                            </div>
                        </ng-template>

                        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                            let-filterService="filterService">
                            <date-range-filter [field]="column.field" [filter]="filter" [filterService]="filterService">
                            </date-range-filter>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal">
    <div class="modal-dialog modal-dialog-centered filter-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">Filter</h2>
                <a hrf="javascript:void()" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></a>
            </div>
            <div class="modal-body">
                <div class="filter-cont">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-8">
                            <kendo-filter #filter [filters]="filters" [value]="filterValue"
                                (valueChange)="onFilterChange($event)">
                            </kendo-filter>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary me-2" data-bs-dismiss="modal"
                    #closebutton>Cancel</button>

                <div class="apply-filter-btn">
                    <button kendoButton (click)="applyFilter(filter.value,isFilter1)">
                        Apply filter
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog [header]="callFrom != 'Reopen' ? _confirmBox.header : 'Reason'" [modal]="true"
    [(visible)]="_confirmBox.isVisible" [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false"
    [resizable]="false" [ngClass]="{'reopen' : callFrom == 'Reopen'}"
    [styleClass]="callFrom != 'Reopen' ? _confirmBox.borderColor : 'reopen'" *ngIf="_confirmBox">
    <p *ngIf="callFrom != 'Reopen'">{{_confirmBox.message}}</p>
    <div *ngIf="callFrom == 'Reopen'">
        <textarea class="form-control" [(ngModel)]="reasonForReopenWBA" placeholder="Reason"></textarea>
        <div class="display-error">
            <span *ngIf="invalidReasonForReopenWBA">
                Reason is required
            </span>
        </div>
    </div>
    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <ng-container *ngIf="callFrom != 'Reopen'">
            <p-button (click)="confirmBoxCancel()" label="No" styleClass="btn btn-outline-primary"></p-button>
            <p-button (click)="deleteWhiteboardActivity()" label="Yes" styleClass="btn btn-primary"></p-button>
        </ng-container>
        <ng-container *ngIf="callFrom == 'Reopen'">
            <p-button (click)="confirmBoxCancel()" label="Cancel" styleClass="btn btn-outline-primary"></p-button>
            <p-button (click)="saveReason()" label="Save" styleClass="btn btn-primary"></p-button>
        </ng-container>
    </ng-template>
</p-dialog>

<p-dialog [header]="isInValidNumber  ? _exportConfirmBox.header2 : _exportConfirmBox.header" [modal]="true"
    [(visible)]="_exportConfirmBox.isVisible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }"
    [draggable]="false" [resizable]="false" [styleClass]="_exportConfirmBox.borderColor" (onHide)=" confirmBoxOk()"
    *ngIf="_exportConfirmBox">
    <p>{{ _exportConfirmBox.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_exportConfirmBox.hasFooter">
        <ng-container *ngIf="isInValidNumber">
            <p-button (click)="confirmBoxOk()" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-container>
    </ng-template>
</p-dialog>

<p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
    <p>{{ _warningBox.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
        <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
</p-dialog>

<p-dialog [header]="_confirmBoxForClone.header" [modal]="true" [(visible)]="_confirmBoxForClone.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_confirmBoxForClone.borderColor" *ngIf="_confirmBoxForClone">
    <p>{{ _confirmBoxForClone.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBoxForClone.hasFooter">
        <p-button (click)="this._confirmBoxForClone.isVisible = false" label="OK" styleClass="btn btn-outline-primary">
        </p-button>
    </ng-template>
</p-dialog>
