<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Edit Note</h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>

        </div>
        <div class="row" *ngIf="showError">
            <div class="col-12">
                <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                    <ul class="view-error">
                        <li *ngFor="let err of errorList">{{err}}</li>
                    </ul>
                    <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                        aria-label="Close"></button>
                </div>
            </div>
        </div>
        <div class="add-content add-form">
            <div class="pdg-con">
                <form class="row g-3" novalidate [formGroup]='editNoteForm'>
                    <div class="add-form">
                        <div class="row">
                            <div class="add-fld">
                                <div class="row">
                                    <div class="add-title">
                                        <h3>Notes Details<span class='star-color'>*</span></h3>
                                    </div>
                                    <div class="row ">
                                        <div class="row frmrow">
                                            <div class="col-12 col-sm-12 col-md-12 mb-3 ">
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 mt-2 has-validation">
                                                        <label class="form-label">Topic <span>*</span></label>
                                                        <select class="select-cs form-select" name="topic"
                                                            formControlName="topic">
                                                            <option value="">Select Topic</option>
                                                            <option *ngFor="let topic of topicListValue"
                                                                value="{{topic.topicName}}">
                                                                {{topic.topicName}}</option>
                                                        </select>
                                                        <div class="display-error"
                                                            *ngIf="f.topic.invalid && isSubmitted">
                                                            <div>
                                                                <span *ngIf="f.topic.errors?.required">
                                                                    {{_AddNoteScreen.topic.required}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-12 mt-2 has-validation">
                                                <label class="form-label">Note <span class="star-color">*</span></label>
                                                <textarea class="form-control" name="addNotes"
                                                    formControlName="description" rows="5" placeholder="Add a Notes"
                                                    (focusout)="removeSpaces($event,f.description)"
                                                    [class.errorInput]="f.description.invalid && isSubmitted"></textarea>
                                                <div class="display-error" *ngIf="f.description.invalid && isSubmitted">
                                                    <div>
                                                        <span *ngIf="f.description.errors?.required">
                                                            {{_AddNoteScreen.note.required}}
                                                        </span>
                                                        <span *ngIf="f.description.errors?.maxlength">
                                                            {{_AddNoteScreen.note.maxlength}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="blk-bt">
            <button class="btn btn-primary me-4" (click)="editNote()">Save</button>
            <button class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
        </div>
        <!-- Dialog for confirmation -->
        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>{{_confirmBox.message}}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary">
                </p-button>
                <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>