import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { environment } from 'src/environments/environment';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { LookupCarrierComponent } from '../lookup-carrier/lookup-carrier.component';
import { ActivatedRoute } from '@angular/router';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';
import {stringEmpty} from "../../../../../shared/utils";

@Component({
  selector: 'app-binding',
  templateUrl: './binding.component.html',
  styleUrls: ['./binding.component.css']
})
export class BindingComponent implements OnInit {

  @Input() wbaView: any;
  bindingForm: any;
  addWbaScreen: AddWBAScreen;
  expiringCarrierlist: any;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterCarrierList: any[] = [];
  lookupData: any;
  lookupcomponent: any;
  showError: boolean = false;
  errorMessage: any;
  wbaSubmissionCarriers: any = [];
  quotedFind: any;
  mainprod: any;
  bindingResponse: any;
  disable: boolean = false;
  result: any;
  activeWbaId: any;
  subProducerId: any;
  carrierLookupFilterData: string = "";
  _confirmBox: ConfirmBox;


  constructor(private formbuilder: UntypedFormBuilder,
    private _carriersService: CarriersService,
    private modalService: NgbModal,
    private _addWBAService: WhiteBoardActivityService,
    private activeRouter: ActivatedRoute,
    private _notifierService: WBANotifierService,
    private _dataCacheService: DatacacheService,
    public datepipe: DatePipe) {
    this.addWbaScreen = new AddWBAScreen();
    this._confirmBox = new ConfirmBox();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    this.getFormControls();
    this._notifierService._boundData.subscribe(res => {
      this.result = res;
      this.setFormValues();
    });
    this._notifierService._subProducerId.subscribe(res => {
      this.subProducerId = res;
    });
    this.initializeData();
    window.scroll(0, 0);
  }

  initializeData() {
    this.wbaSubmissionCarriers = this._dataCacheService.retrieveData('WbaSubmissionCarrierList');
    this.bindingResponse = this._dataCacheService.retrieveData('wba_bindingResponse');
    if (!this.wbaSubmissionCarriers) this.getAllMasters();
    else this.getWbaBindingListDetails();
  }

  getAllMasters() {
    let requestBody = {
      wbaId: this.activeWbaId
    }
    var wbaAcarrierList = this._addWBAService.getWbaSubmissionCarrierListById(this.activeWbaId);
    var BindingList = this._addWBAService.getWbaBindingList(requestBody);
    forkJoin([wbaAcarrierList, BindingList
    ]).subscribe((resp) => {
      if (resp) {
        this.wbaSubmissionCarriers = resp[0].wbaSubmissionCarrierList;
        this.bindingResponse = resp[1].wbaOutcomeDetails;
        this.getWbaBindingListDetails();
        this.setDataInCache();
      }
    })
  }

  getWbaBindingListDetails() {
    if (this.bindingResponse != null) {
      this.getFormValues();
    }
    else {
      this.setFormValues();
    }
  }
  setDataInCache() {
    this._dataCacheService.compressAndCacheData('WbaSubmissionCarrierList', this.wbaSubmissionCarriers);
    this._dataCacheService.compressAndCacheData('wba_bindingResponse', this.bindingResponse);
  }

  getFormControls() {
    this.bindingForm = this.formbuilder.group({
      broker: [''],
      brokerFee: [''],
      brokerFeeAmount: [''],
      brokerFeeTerms: [''],
      boundPremium: [''],
      carrier: [''],
      premiumPayable: [''],
      mainProducer: [''],
      subProducer: [''],
      startDate: [''],
      boundDate: [''],
      closeDate: [''],
      overage: ['']
    })
  }

  setFormValues() {
    if (this.wbaView.outcome == 'Bound') {
      let createdDate = this.wbaView?.createdDate
      createdDate = this.datepipe.transform(createdDate, 'MM/dd/yyyy')

      this.bindingForm.get('boundPremium').patchValue(Number(this.result.boundPremium));
      this.bindingForm.get('carrier').patchValue(this.result.carrier);
      this.bindingForm.get('mainProducer').patchValue(100);
      this.bindingForm.get('subProducer').patchValue(0);
      this.bindingForm.get('startDate').setValue(createdDate);
      this.bindingForm.get('startDate').disable();
      this.bindingForm.get('boundDate').disable();
      this.setProducerValue();
    }
  }

  getFormValues() {
    this.bindingForm.get('overage').setValue(this.bindingResponse.overAge);
    this.bindingForm.get('broker').patchValue(this.bindingResponse.broker);
    this.bindingForm.get('brokerFee').patchValue(this.bindingResponse.brokerFees);
    this.bindingForm.get('brokerFeeAmount').patchValue(this.bindingResponse.brokerFeeAmount);
    this.bindingForm.get('brokerFeeTerms').patchValue(this.bindingResponse.brokerFeeTerms);
    this.bindingForm.get('boundPremium').patchValue(this.bindingResponse.boundPremium);
    this.bindingForm.get('carrier').patchValue(this.bindingResponse.carrier);
    this.bindingForm.patchValue({
      premiumPayable: {
        value: this.bindingResponse.premiumPayableCarrier ?? stringEmpty
      }
    });
    this.carrierLookupFilterData = this.bindingResponse.premiumPayableCarrier;
    this.bindingForm.get('subProducer').patchValue(this.bindingResponse.subProducerPercentage);
    this.bindingForm.get('boundDate').patchValue(this.datepipe.transform(this.bindingResponse.boundDate, 'MM/dd/yyyy'));
    this.bindingForm.get('boundDate').disable();
    this.bindingForm.get('startDate').patchValue(this.datepipe.transform(this.wbaView?.createdDate, 'MM/dd/yyyy'));
    this.bindingForm.get('startDate').disable();
    this.bindingForm.get('closeDate').patchValue(this.datepipe.transform(this.bindingResponse.wbaCloseDate, 'MM/dd/yyyy'));
    this.getSubProducerCalculation(this.bindingForm.value.subProducer);
  }

  get f() {
    return this.bindingForm.controls;
  }

  setProducerValue() {
    let producer = this.result.subProducerPercentage
    if (producer !== "0") {
      this.bindingForm.get('subProducer').patchValue(producer);
    }

    let data = this.bindingForm.value;
    if (data.subProducer != "0") {
      let value = 100 - Number(data.subProducer);
      this.bindingForm.get('mainProducer').patchValue(value);
    }
  }
  getSubProducerCalculation(subProducer: any) {
    var eValue = 100 - subProducer;
    this.mainprod = this.bindingForm.get('mainProducer').patchValue(eValue);
  }


  getWbaSubmissionCarrierListById(wbaId: any) {
    this._addWBAService.getWbaSubmissionCarrierListById(wbaId).subscribe(resp => {
      this.wbaSubmissionCarriers = resp.wbaSubmissionCarrierList;
      //this.quotedFind = this.wbaSubmissionCarriers.filter((x: any) => x.quotedStatus == "Quoted");
    })
  }

  getCompanyList(fieldName: any, val: any) {
    if (fieldName && val.query) {
      this.carrierLookupFilterData = val.query;
      let encode = val.query.replaceAll('&', '%26')
      this._addWBAService.getTypeheadList(fieldName, encode).subscribe(resp => {
        if (fieldName == 'Wholesaler') {
          this.expiringCarrierlist = resp.expiringCarrierList;
        }

      })
    }
  }

  selectItem(item: any, type: any) {
    if (type == 'expiringCarrier') {
      this.bindingForm.controls['premiumPayable'].setValue({
        id: item.id,
        value: item.value
      });
      this.carrierLookupFilterData = item.value;
    }
  }

  getCarriersListInfo(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType
    }
    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })
    modalRef.componentInstance.carrierLookupFilterData = this.carrierLookupFilterData;
    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.searchType = searchType;
    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.bindingForm.controls['premiumPayable'].patchValue({
          id: data.id,
          value: data.value
        });
        this.carrierLookupFilterData = data.value;
      }
    });
  }


  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    if (value === "expiringCarrier" || value === "Wholesaler") {
      this.getCarriersListInfo(value)


    } else {
      this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
        if (response) {
          this.lookupData = response;


          const modalRef = this.modalService.open(this.lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })

          modalRef.componentInstance.lookupData = this.lookupData;
          modalRef.componentInstance.searchType = value;
          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              if (value === "expiringCarrier") {
                this.bindingForm.controls['expiringCarrier'].patchValue({
                  id: data.id,
                  value: data.value
                });
                this.selectItem(data, value);
              }
            }
          });

        }
      }, (err: any) => {
        if (err) {
          this.showError = true;
          this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
        }
      });
    }
  }

  saveWBA(action: string) {
    if (action == 'binding') {
      let data = this.bindingForm.value
      let requestBody = {
        id: this.bindingResponse?.id,
        wbaId: Number(this.activeWbaId),
        subProducerPercentage: data.subProducer,
        boundCarrierId: this.bindingResponse?.boundCarrierId ? this.bindingResponse.boundCarrierId : 0,
        carrier: this.bindingResponse?.carrier,
        boundPremium: data.boundPremium ? data.boundPremium : 0,
        broker: data.broker,
        brokerFees: data.brokerFee.toString(),
        brokerFeeAmount: data.brokerFeeAmount.toString(),
        brokerFeeTerms: data.brokerFeeTerms,
        premiumPayableCarrierId: data.premiumPayable.id ? data.premiumPayable.id : this.bindingResponse?.premiumPayableCarrierId,
        premiumPayableCarrier: data.premiumPayable.value,
        mainProducerPercentage: String(data.mainProducer),
        startDate: this.wbaView?.createdDate,
        wbaCloseDate: this.bindingResponse?.outcome !== 'Lost' ? this.localToUtc(new Date(data.closeDate)) : this.bindingResponse?.wbaCloseDate,
        outcome: this.bindingResponse?.outcome,
        resultId: this.bindingResponse?.resultId,
        reasonId: this.bindingResponse?.reasonId ? this.bindingResponse?.reasonId : 1,
        reason: this.bindingResponse?.reason,
        rfp: this.bindingResponse?.rfp,
        competitorAgent: this.bindingResponse?.competitorAgent,
        competitorAgentKnown: this.bindingResponse?.competitorAgentKnown,
        competitorCarrier: this.bindingResponse?.competitorCarrier,
        competitorCarrierKnown: this.bindingResponse?.competitorCarrierKnown,
        subProducer: this.result?.subProducer ? this.result?.subProducer : this.subProducerId,
        crossSale: this.bindingResponse?.crossSale,
        boundRevenue: this.bindingResponse?.boundRevenue,
        carrierCommission: this.bindingResponse?.carrierCommission,
        boundPolicyNumber: this.bindingResponse?.boundPolicyNumber,
        policyExpirationDate: this.bindingResponse?.policyExpirationDate,
        boundDate: this.bindingResponse?.boundDate,
        boundUser: this.bindingResponse?.boundUser,
        bindRequestUser: this.bindingResponse?.bindRequestUser,
        createdBy: this.bindingResponse?.createdBy,
        boundRequestDate: this.bindingResponse?.boundRequestDate,
        bindRequestDate: this.bindingResponse?.bindRequestDate,
        description: this.bindingResponse?.description ? this.bindingResponse?.description : null,
        overAge: data.overage == '' ? false : true
      }
      this._addWBAService.createWbaBinding(requestBody).subscribe(res => {
        this.getWbaBindingList();
        this.bindingForm.markAsPristine();
      },
        (err: any) => {
          this.showError = true;
          if (err.status !== 200) {
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorMessage = arr[0].value[0];
              this._confirmBox.message = this.errorMessage;
              this._confirmBox.isVisible = true;
            }
          }
        });
    }
  }

  deleteKey(x: any) {
    delete x.wbaCloseDate;
    return x
  }

  public localToUtc(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  public utcToLocal(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }



  getWbaBindingList() {
    let requestBody = {
      wbaId: this.activeWbaId
    }
    this._addWBAService.getWbaBindingList(requestBody).subscribe(res => {
      this.bindingResponse = res.wbaOutcomeDetails;
      this._dataCacheService.compressAndCacheData('wba_bindingResponse', this.bindingResponse);
      if (this.bindingResponse != null) {
        this.getFormValues();
      }
      else {
        this.setFormValues();
      }
    })
  }

  lookUpDataFilterChanges() {
    this.carrierLookupFilterData = "";
  }


}
