import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddprospectScreen } from 'src/app/features/prospect/error-message/addprospect-screen';
import { NoteService } from 'src/app/features/prospect/services/note.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';

@Component({
  selector: 'app-wba-note',
  templateUrl: './wba-note.component.html',
  styleUrls: ['./wba-note.component.css']
})
export class WbaNoteComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  _confirmBox: any;
  _userMessages: any;
  prospectId: any;
  @Input() wbaId: any;
  addNoteForm: any;
  isSubmitted: boolean = false;
  _AddNoteScreen: AddprospectScreen;
  isDisabled: boolean = true;
  currentCreatedDate: any;
  topicListValue: any;

  constructor(
    public _activeModal: NgbActiveModal,
    public _formBuilder: UntypedFormBuilder,
    public _noteService: NoteService,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public datepipe: DatePipe,
    public _whiteBoardActivityService: WhiteBoardActivityService
  ) {
    this._AddNoteScreen = new AddprospectScreen();
    this._confirmBox = new ConfirmBox()
    this._userMessages = new UserMessages();
    this.getFormControl()
  }

  //to get all form controls
  get f() {
    return this.addNoteForm.controls;
  }
  // Form control fields
  getFormControl() {
    this.addNoteForm = this._formBuilder.group({
      description: ['', [Validators.required, Validators.maxLength(6000)]],
      topic: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.getTopicValue();
  }

  //topic dropdown
  getTopicValue() {
    this._noteService.getTopicValue().subscribe(resp => {
      this.topicListValue = resp.wbaTopic;
    })
  }

  // Create Note method
  saveNote() {
    this.isSubmitted = true;
    let data = this.addNoteForm.value;
    if (this.addNoteForm.valid) {
      let saveData = {
        wbaId: this.wbaId,
        description: data.description,
        topic: data.topic
      }
      this._whiteBoardActivityService.addWbaNotes(saveData).subscribe(resp => {
        if (resp) {
          this.passEntry.emit('Add WBA Note');
          this.closeModal()
        }
      },
        error => {
        })
    }
  }

  //to cancel the process and back to view prospect screen
  cancel() {
    if (this.addNoteForm.dirty) {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    } else {
      this.closeModal();
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.closeModal();
  }

  closeModal() {
    this._activeModal.close();
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
}
