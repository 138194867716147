<div class="main-body-con1">
  <div class="page-container  px-4">
    <div class="page-hdr-outer hdr-assess ">
      <div class="page-hdr ">
        <h3>Audit Report for {{WBADetailedData.whiteBoardActivityName}}</h3>
        <div class="flex-div">
          <div class="date-display me-4">Expiring Carrier: <span class="me-4">{{WBADetailedData.expiringName ?
              WBADetailedData.expiringName : 'N/A'}}</span>
            Expiring Premium: <span>{{WBADetailedData.expiringPremium !='0' ? '$' + WBADetailedData.expiringPremium :
              'N/A'}}</span></div>
          <a _ngcontent-nop-c184="" (click)="closeModal()" title="close" class="closebtn"><img _ngcontent-nop-c184=""
              src="assets/images/close.svg" alt="" /></a>
        </div>
      </div>
    </div>

    <div class="audit_content">
      <div class="audit-left">
        <ng-container *ngFor="let item of CoverageCheckList; index as indx">
          <div
            *ngIf="item.displayName!=='GL Rating Basis' && item.displayName!=='Cyber Rating Basis' && item.displayName!=='WC Rating'"
            (click)="gotoSelectedCoverage(item.displayName, indx)" class="au-blk1"
            [ngClass]="item.isChecked ? 'au-active' : ''">
            <div class="flex-div au-blk-hd space-between">
              <div class="flex-div">
                <img
                  src="assets/images/{{WBADetailedData.masterBusinessLine!='Specialty' ? item.displayName : 'Specialty'}}.svg"
                  title="{{item.displayName}}" class="au-blk-icon">
                <h2>{{item.displayName}}</h2>
              </div>
              <div class="text-right">
                <button class="more-btn" (click)="showM(isAssess, item)" *ngIf="!item.showLessButton">More</button>
                <button class="more-btn" (click)="showM(isAssess, item)" *ngIf="item.showLessButton">Less</button>
              </div>
            </div>
            <ng-container *ngIf="item.showLessButton && item.displayName===clickedMoreCoverageName">
              <table class="table tbl-audit ">
                <thead>
                  <tr>
                    <th width="40%"> </th>
                    <th width="30%">Expiring</th>
                    <th width="30%" class="bd-left">Recommended</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="assess-value asr">Inadequate</span></td>
                    <td class="exp-gr">{{item.expringAssess1}}</td>
                    <td class="rec-or"> {{item.recommendedAssess1}}</td>
                  </tr>
                  <tr>
                    <td><span class="assess-value asy">Better Option Available</span></td>
                    <td class="exp-gr">{{item.expringAssess2}}</td>
                    <td class="rec-or">{{item.recommendedAssess2}}</td>
                  </tr>
                  <tr>
                    <td><span class="assess-value asb">Adequate</span></td>
                    <td class="exp-gr">{{item.expringAssess3}}</td>
                    <td class="rec-or">{{item.recommendedAssess3}}</td>
                  </tr>
                  <tr>
                    <td><span class="assess-value asg">Superior</span></td>
                    <td class="exp-gr">{{item.expringAssess4}}</td>
                    <td class="rec-or">{{item.recommendedAssess4}}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="audit-right">
        <ng-container *ngFor="let item of CoverageCheckList">
          <div *ngIf="item.displayName!=='GL Rating Basis' && item.displayName!=='WC Rating'">
            <div id="{{item.displayName}}"></div>
            <div [ngClass]="isCoverage  ? 'au-active-detail' : 'au-detail'">
              <div class="audit-right-header">
                <div class="coverage-n flex-div">
                  <img
                    src="assets/images/{{WBADetailedData.masterBusinessLine!='Specialty' ? item.displayName : 'Specialty'}}.svg"
                    title="{{item.displayName}}">
                  <h2>{{item.displayName}}</h2>
                </div>
              </div>
              <div class="audit-table">
                <table>
                  <thead>
                    <tr>
                      <th width="20%">Group Name</th>
                      <th width="45%">Coverage</th>
                      <th width="17%">Expiring Value</th>
                      <th width="18%">Recommended Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let param of item.coverageParameters; let i = index;">
                      <td>
                        <h3 class="grpnameHd">{{ getGroupName(item, i)}}</h3>
                      </td>
                      <td class="coverage-grp">
                        <div>{{param.displayName}} <label *ngIf="param.isRequired" class="form-label">
                            <span>*</span></label></div>
                      </td>
                      <td>
                        <div [ngClass]="'assess_' + param.expiringAssessmentId"
                          [ngClass]="'verticle_line' + param.expiringAssessmentId">
                          <span *ngIf="param.valueType === 'input-currency' && param.expiringValue!=''">$&nbsp;</span>
                          <img *ngIf=" param.valueType=='input-date' && param.expiringValue"
                            src="assets/images/calendar.svg" alt="">

                          <span [innerHTML]="param.expiringValue"> </span>
                        </div>
                      </td>
                      <td>
                        <div [ngClass]="'assess_' + param.recommendedAssessmentId"
                          [ngClass]="'verticle_line' + param.recommendedAssessmentId">
                          <span
                            *ngIf="param.valueType === 'input-currency' && param.recommendedValue!=''">$&nbsp;</span>
                          <img *ngIf="param.valueType=='input-date' && param.recommendedValue"
                            src="assets/images/calendar.svg" alt="">
                          <span [innerHTML]="param.recommendedValue"> </span>
                        </div>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>


        <div class="btn-blk">
          <button class="btn btn-outline-primary me-3" (click)="closeModal()">Modify</button>
          <button class="btn btn-primary" (click)="saveCoverageAssessmentOnAccept('Accept')">Accept</button>
        </div>
      </div>

    </div>
    <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showHideParametersValidationPopUp"
      [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p>You have not entered the expiring or recommended value for mandatory coverage(s).</p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button (click)="showHideParametersValidationPopUp = false" label="OK"
          styleClass="btn btn-primary"></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showRequiredExposureTypePopUp"
      [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
      [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
      <p> {{ratingBasisMessage}} </p>
      <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
        <p-button (click)="showRequiredExposureTypePopUp = false" label="OK" styleClass="btn btn-primary"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>