<div class="add-hdr">
  <div class="heading">
    <h2>Proposal</h2>
  </div>
  <div class="add-act">
    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
      aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
  </div>
</div>


<!-- <table class="table mb-5 table-responsive mt-4 filter-table">
    <thead>
        <tr>
            <th width="40%">File Name</th>
            <th width="30%">Created On </th>
            <th width="20%">Created By</th>
            <th width="10%"> Download </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of fileDatafetch; let i = index;">
            <td class="td-vertical-align">
                {{item.proposalDoc}}
            </td>
            <td class="td-vertical-align">
                {{item.createdDate | date:'MM/dd/yyyy'}}
            </td>
            <td class="td-vertical-align">
                {{item.createdBy}}
            </td>
            <td class="td-vertical-align">
                <a href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">
                    <label class="ps-2"><img src="assets/images/download.svg" class="me-2" width="13px"
                            alt=" " /></label></a>
            </td>
        </tr>
    </tbody>
</table> -->

<div class="grid-con-in" id="noaction">
  <kendo-grid [data]="fileDatafetch" [resizable]="false" (cellClick)="onCellClick($event)" [virtualColumns]="true">
    <kendo-grid-column [width]="15" field="createdDate" title="Created On" format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.createdDate | date:'MM/dd/yyyy'}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="15" field="createdBy" title="Created By">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.createdBy}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="headerCustomStatusClass" [width]="15" title="Generated Proposal"
      field="generatedPath">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="customsp1">
          <a href="javascript:void(0)"
            [ngClass]="{'disabled-link': dataItem.proposalStatus === 'Pending' || dataItem.proposalStatus === null }"
            (click)="(dataItem.proposalStatus !== 'Pending' || dataItem.proposalStatus !== null) && downloadFile(dataItem.generatedPath)"
            data-bs-toggle="dropdown" aria-expanded="false">
            <ng-container
              *ngIf=" (dataItem.proposalStatus == 'Pending' || dataItem.proposalStatus == null); else ServiceBody">
              <label class="ps-2">
                Processing
              </label>
            </ng-container>
            <ng-template #ServiceBody>
              <label class="ps-2">
                Available
                <!-- <img src="assets/images/download.svg" class="me-2" width="13px" alt="" /> -->
              </label>
            </ng-template>
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>
    <ng-container *ngIf="showModifiedProposalColumn">
      <kendo-grid-column headerClass="headerCustomStatusClass" [width]="15" field="modifiedPath"
        title="Modified Proposal">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="customsp1">
            <a href="javascript:void(0)" *ngIf="dataItem.modifiedProposalDoc"
              (click)="downloadFile(dataItem.modifiedPath)" data-bs-toggle="dropdown" aria-expanded="false">
              <label class="ps-2">
                <img src="assets/images/download.svg" class="me-2" width="13px" alt="" />
              </label>
            </a>
          </div>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
  </kendo-grid>
</div>
<div class="uploadBlock">
  <div class="uploadTitle">
    <h3>Upload Your Proposal File</h3>
  </div>

  <div class="text-center">
    <!-- <input class="form-control" type="file" (change)="onUploadProposalChange($event)" />
    <br />
    <span class="file-message small">Click or drag and drop your proposal file in pptx format.</span> -->
    <div class="upload-block file-upload-wrapper">
      <div class="file-drop-area">
        <span class="flex-div justify-center"><img src="assets/images/upload-file-g.svg" width="90px" alt="" /></span>

        <span class="file-message small">Click or Drag and Drop your proposal file <br>
          in the PPTX Format.
        </span>

        <input id="file" class="file-input" type="file" (change)="onUploadProposalChange($event)" accept=".ppt" />

      </div>
    </div>
    <div style="text-align: center">
      <span *ngIf="selectedFile">
        File name : {{ selectedFile.name }}</span>
    </div>
  </div>
</div>
<div class="blk-bt">
  <button class="btn btn-outline-primary ms-2 me-3" (click)="cancel()">Cancel</button>
  <button [disabled]="this.fileDatafetch?.length==0" (click)=" onUploadProposal()"
    class="btn btn-primary">Upload</button>
</div>
