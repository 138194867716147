import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';

@Component({
  selector: 'app-clone-bor',
  templateUrl: './clone-bor.component.html',
  styleUrls: ['./clone-bor.component.css']
})
export class CloneBorComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();
  @Input() fromCloneData: any;
  @Input() fromCloneDataListId: any;

  addCloneForm: any;
  wbaId: number = 0;
  submitted: boolean = false;
  errorList: any[] = [];
  unAthorizesd: any = '';
  showError: boolean = false;
  errorMessage: any;
  _addWbaScreen: AddWBAScreen;
  _confirmBoxForClone: ConfirmBox;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  _navigationPath: NavigationPath;
  isSubmitted: boolean = false;
  resIdForCloneWba: any;

  constructor(private activeRouter: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private _addWBAService: WhiteBoardActivityService,
    public activeModal: NgbActiveModal,
    private _router: Router) {
    this._addWbaScreen = new AddWBAScreen();
    this._confirmBoxForClone = new ConfirmBox();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
  }

  ngOnInit(): void {
    this.getFormControl();
    //create new variable for not affected the other form value
    this.fromCloneData = JSON.parse(JSON.stringify(this.fromCloneData));
    this.patchDateWithOneYearAdded();
  }

  //patch for effective date 
  patchDateWithOneYearAdded(): void {
    if (this.fromCloneData.isBorDefined == 'Yes') {
      if (this.fromCloneData.effectiveDate) {
        //add 1 year in case of bor case
        const currentDate = new Date(this.fromCloneData.effectiveDate);
        this.fromCloneData.effectiveDate = currentDate.setFullYear(currentDate.getFullYear() + 1);
      }
    }
    //for null effective date set blank
    this.addCloneForm.patchValue({
      effectiveDate: this.fromCloneData.effectiveDate == 'Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)' || this.fromCloneData.effectiveDate == '0001-01-01T00:00:00' ? '' : new Date(this.fromCloneData.effectiveDate)
    })
  }

  getFormControl() {
    this.addCloneForm = this.fb.group({
      effectiveDate: ['', [Validators.required]],
    })
  }

  //save clone wba with effective date
  CreateClone() {
    this.isSubmitted = true;
    if (this.addCloneForm && this.addCloneForm.valid) {
      let data = this.addCloneForm.value;
      let saveData = {
        wbaId: this.fromCloneData.id,
        effectiveDate: this.datepipe.transform(data.effectiveDate, 'yyyy-MM-dd'),
      }
      this._addWBAService.saveCloneWBA(saveData).subscribe((res: any) => {
        if (res) {
          if (res.message) {
            this._confirmBoxForClone.message = res.message;
            this._confirmBoxForClone.isVisible = true;
          } else {
            this._confirmBoxForClone.message = 'WBA successfully cloned and the new WBA is ' + "[" + "WBA ID" + "-" + res.wbaId + "]";
            this._confirmBoxForClone.isVisible = true;
            this.resIdForCloneWba = res.wbaId
          }
          //this._router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl(),], { queryParams: { wbaId: res.wbaId } });
          //this.closeModal();
          // this.messageEvent.emit(res);
        }
      },
        (err: any) => {
          if (err.status !== 200) {
            if (this.unAthorizesd) {
              this.errorList.push(this.unAthorizesd);
              return;
            }
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorList = [];
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            } else {
              this.showError = true;
              this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
              this.errorList.push(this.errorMessage);
            }
          }
        });
    }
  }


  closeModal() {
    this.activeModal.close();
  }

  get f() {
    return this.addCloneForm.controls;
  }

  //to cancel the process 
  cancel() {
    if (!this.addCloneForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  confirmBoxCancel() {
    this._confirmBoxForClone.isVisible = false;
    this._router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl(),], { queryParams: { wbaId: this.resIdForCloneWba, listId: this.fromCloneDataListId } });
    this.closeModal();
    this.messageEvent.emit(this.resIdForCloneWba);
  }

  confirmBoxCancelDiscard() {
    this._confirmBoxForClone.isVisible = false;
    this.closeModal();
  }

}
