import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegxConstant } from 'src/app/shared/class/regx';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { AddWBAScreen } from '../../../error-message/addprospect-screen';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { environment } from 'src/environments/environment';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { reasonLostWbaList } from 'src/app/features/prospect/interfaces/criteria.model';
import { LookupCarrierComponent } from '../lookup-carrier/lookup-carrier.component';
import { LookupManagerComponent } from '../lookup-manager/lookup-manager.component';
import { ActivatedRoute } from '@angular/router';
import {stringEmpty} from "../../../../../shared/utils";

@Component({
  selector: 'app-wba-bind-request',
  templateUrl: './wba-bind-request.component.html',
  styleUrls: ['./wba-bind-request.component.css']
})
export class WbaBindRequestComponent implements OnInit {
  @Input() progresStatus: any
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  addBindRequestForm: any;
  _regxConstant: RegxConstant;
  _addWbaScreen: AddWBAScreen;
  selectedPKGTier: any;
  selectedWCTier: any;
  selectedType: any;
  submitted: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  wcTierList: any[] = [];
  wcTierList$!: Observable<any>;
  pkgTierList$!: Observable<any>;
  typeList$!: Observable<any>;
  saveCarrier$!: Observable<any>;
  pkgTierList: any[] = [];
  typeList: any[] = [];
  wbaSubmissionCarriers: any = [];
  showError: boolean = false;
  _navigationPath: NavigationPath;
  unsubscribe$: Subject<boolean> = new Subject();
  @Output() messageEvent = new EventEmitter<string>();
  unAthorizesd: any = '';
  WBAId: any;
  epicStatus: any = [];
  reasonDetailofLost: any = [];
  reasonLostWbaList: reasonLostWbaList[] = [];
  wbaId: number = 0;
  defaultValue = 'No';
  isSelected: boolean = true;
  resultSelectedBoundvalue: boolean = false;
  resultSelectedNotBoundvalue: boolean = false;
  lookupData: any;
  lookupcomponent: any;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterCarrierList: any[] = [];
  expiringCarrierlist: any;
  outComeDetailFinish: any;
  quotedFind: any;
  showHideBoundCarrierValidationPopUp: boolean = false;
  isSaveBtnDisable: boolean = false;
  accountManagerlist: any;
  showPercentageValidation: boolean = false;
  reason: any;
  selectedReasonId: any;
  wbaDetails: any;
  constructor(
    public activeModal: NgbActiveModal,
    private _carriersService: CarriersService,
    public ValidateKey: KeyboardValidation,
    private fb: UntypedFormBuilder,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public profileInfoService: ProfileInfoService,
    private _addWBAService: WhiteBoardActivityService,
    private _wbaService: WhiteBoardActivityService,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private _notifierService: WBANotifierService
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._regxConstant = new RegxConstant();
    this._addWbaScreen = new AddWBAScreen();



  }
  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        console.log("params  ", params)
        if (params)
          this.wbaId = params.wbaId
      });
    this.getOutComeDetail(this.wbaId);
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this.getFormControl();
    this.getReasonList('Bind Request')
    //this.setFormValue();
    this.removeDescriptionValidator();
    this.getWhiteBoardDetailsFromApi(this.wbaId)
  }

  removeDescriptionValidator() {
    this.addBindRequestForm.get("reason").valueChanges.subscribe((x: any) => {
      this.selectedReasonId = x;
      let description = this.addBindRequestForm.get('description');
      this.reason = this.reasonDetailofLost?.find((res: any) => res?.reason == "Other")
      if (this.reason.id == x) {
        if (!this.outComeDetailFinish?.description) {
          description.setValue('');
        } else {
          description.setValue(this.outComeDetailFinish.description);
        }
        description.setValidators([Validators.required]);
      } else {
        description.clearValidators();
      }
      description.updateValueAndValidity();

    })
  }





  getFormControl() {
    this.addBindRequestForm = this.fb.group({
      outcome: [''],
      reason: ['', [Validators.required]],
      subProcedureName: [''],
      subProducer: [''],
      crossSale: [''],
      rfp: [''],
      brokerAgent: [''],
      carrier: [''],
      competitorAgentKnown: [''],
      competitorCarrierKnown: [''],
      description: ['']

    })
    this.addBindRequestForm.get('outcome').disable();
  }

  getWhiteBoardDetailsFromApi(Id: any) {
    this._wbaService.getWBAById(Id).subscribe(resp => {
      if (resp && resp.whiteBoardActivityInfo) {
        this.wbaDetails = resp.whiteBoardActivityInfo;
      }
    })
  }

  //to get Bind Request detail
  getOutComeDetail(wbaId: any) {
    this._wbaService.getOutComeDetail(wbaId).subscribe(resp => {
      if (resp) {
        this.outComeDetailFinish = resp.wbaOutcomeDetails;
        if (this.outComeDetailFinish) {
          this.setFormData(this.outComeDetailFinish)
        } else {
          this.setFormValue()
        }
      }
    })
  }

  setFormValue() {
    this.addBindRequestForm.patchValue({
      outcome: 'Bind Request',
    })
    this.getWbaSubmissionCarrierListById(this.wbaId);
  }

  //patch value
  setFormData(result: any) {
    sessionStorage.setItem("dataForBound", result)
    console.log(result)
    let outcome = 'Bind Request'
    if (outcome == "Bind Request") {
      this.addBindRequestForm.get('outcome')?.setValue('Bind Request');
    }
    this.addBindRequestForm.get('brokerAgent')?.setValue(result.competitorAgent);
    this.addBindRequestForm.get('competitorAgentKnown')?.setValue(result.competitorAgentKnown);
    this.addBindRequestForm.get('subProducer')?.setValue(result.subProducerPercentage);
    this.addBindRequestForm.patchValue({
      subProcedureName: {
        id: result.subProducerId,
        value: result.subProducer ?? stringEmpty
      },
    })
    this.addBindRequestForm.get('crossSale').setValue(result.crossSale);
    this.addBindRequestForm.get('rfp').setValue(result.rfp);
    this.addBindRequestForm.get('description').setValue(result.description);
    this.addBindRequestForm.get('competitorCarrierKnown').setValue(result.competitorCarrierKnown);
    this.addBindRequestForm.patchValue({
      carrier: {
        value: result.competitorCarrier ?? stringEmpty
      },
    })
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }




  getReasonList(outcome: 'Bind Request') {
    this._addWBAService.getReasonLost(outcome).subscribe(resp => {
      this.reasonDetailofLost = resp.wbaReasonList;
      this.reasonLostWbaList = [];

      if (this.reasonDetailofLost) {
        this.reasonDetailofLost.forEach((item: any) => {
          let groupIndex = this.reasonLostWbaList.findIndex((ob: any) => ob.label === item.reasonDescription);
          let listObj = { label: item.reason, value: item.id.toString() };
          if (groupIndex === -1) {
            // group has not found
            let Obj = {
              label: item.reasonDescription,
              value: item.reasonDescription,
              items: [listObj],
            };
            this.reasonLostWbaList.push(Obj);
          } else {
            // group has found
            this.reasonLostWbaList[groupIndex].items.push(listObj);
          }
        })
        setTimeout(() => {
          this.addBindRequestForm.get('reason')?.setValue(this.outComeDetailFinish?.reasonId.toString());
        }, 500);
      }
    })
  }

  //to get wba carriers list
  getWbaSubmissionCarrierListById(wbaId: any) {
    this._wbaService.getMarketingSummaryList(wbaId).subscribe(resp => {
      this.wbaSubmissionCarriers = resp.marketingSummary;
      this.quotedFind = this.wbaSubmissionCarriers.find((x: any) => x.quotedStatus == "Quoted");
      if (this.progresStatus == "Quoted" || this.progresStatus == "Request to Bind" || this.quotedFind?.quotedStatus == "Quoted") {
        this.showHideBoundCarrierValidationPopUp = false;
        this.isSaveBtnDisable = false;
      } else {
        this.showHideBoundCarrierValidationPopUp = true;
        this.isSaveBtnDisable = true;
      }
    })
  }


  //Loading prospect List.
  getCarriersListInfo(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType
    }

    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })

    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.searchType = searchType;
    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.addBindRequestForm.controls['carrier'].patchValue({
          id: data.id,
          value: data.value
        });
      }
    });
  }


  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    if (value === "Carrier") {
      this.getCarriersListInfo(value)


    } else {
      this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
        if (response) {
          this.lookupData = response;

          if (value === "AccountManager") {
            this.lookupcomponent = LookupManagerComponent;
          }
          const modalRef = this.modalService.open(this.lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })

          modalRef.componentInstance.lookupData = this.lookupData;

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              if (value === "expiringCarrier") {
                this.addBindRequestForm.controls['expiringCarrier'].patchValue({
                  id: data.id,
                  value: data.value
                });
                this.selectItem(data, value);
              } else (value === "AccountManager")

              this.addBindRequestForm.controls['subProcedureName'].patchValue({
                id: data.userId,
                value: data.userName
              });
            }
          });

        }
      }, (err: any) => {
        if (err) {
          this.showError = true;
          this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
        }
      });
    }
  }

  getCompanyList(fieldName: any, val: any) {
    if (fieldName && val.query) {
      let encode = val.query.replaceAll('&', '%26')
      this._addWBAService.getTypeheadList(fieldName, encode).subscribe(resp => {
        if (fieldName == 'Carrier') {
          this.expiringCarrierlist = resp.expiringCarrierList;
        }
        else (fieldName == 'accountManager')
        this.accountManagerlist = resp.userList;

      })
    }
  }

  selectItem(item: any, type: any) {
    if (type == 'expiringCarrier') {
      this.addBindRequestForm.controls['expiringCarrier'].setValue({
        id: item.id,
        value: item.value
      });
    }
    else (type == 'accountManager')
    this.addBindRequestForm.controls['subProcedureName'].setValue({
      id: item.userId,
      value: item.userName
    });
  }



  closeError() {
    this.showError = false;
  }

  onChange(event: any) {
    if (event.target.value == '')
      this.showPercentageValidation = false;
  }

  saveWbaBindRequest() {
    this.submitted = true;
    if (this.addBindRequestForm.get('subProducer').value == '' || this.addBindRequestForm.get('subProducer').value == null)
      this.showPercentageValidation = false;
    else if (this.addBindRequestForm.get('subProducer').value < 0 || this.addBindRequestForm.get('subProducer').value > 100
    ) {
      this.showPercentageValidation = true;
      return;
    } else {
      this.showPercentageValidation = false;
    }
    if (!this.addBindRequestForm.valid) {
      return;
    } else {
      let data = this.addBindRequestForm.value;
      let savedDta = {
        wbaId: this.wbaId,
        outcome: "Bind Request",
        resultId: 0,
        reasonId: parseInt(data.reason),
        subProducer: data.subProcedureName.id,
        subProducerPercentage: data.subProducer ? data.subProducer.toString() : null,
        crossSale: data.crossSale === 'true' ? true : false,
        rfp: data.rfp === 'true' ? true : false,
        competitorAgent: data.brokerAgent,
        description: this.selectedReasonId == this.reason.id ? data.description : null,
        competitorCarrier: data.carrier.value,
        competitorAgentKnown: data.competitorAgentKnown == '' ? false : true,
        competitorCarrierKnown: data.competitorCarrierKnown == '' ? false : true,
        id: this.outComeDetailFinish?.id,
        boundCarrierId: this.outComeDetailFinish?.boundCarrierId,
        boundPremium: this.outComeDetailFinish?.boundPremium,
        broker: this.outComeDetailFinish?.broker,
        brokerFees: this.outComeDetailFinish?.brokerFees,
        brokerFeeAmount: this.outComeDetailFinish?.brokerFeeAmount,
        brokerFeeTerms: this.outComeDetailFinish?.brokerFeeTerms,
        premiumPayableCarrierId: this.outComeDetailFinish?.premiumPayableCarrierId,
        premiumPayableCarrier: this.outComeDetailFinish?.premiumPayableCarrier,
        mainProducerPercentage: this.outComeDetailFinish?.mainProducerPercentage,
        startDate: this.outComeDetailFinish?.startDate,
        wbaCloseDate: this.outComeDetailFinish?.wbaCloseDate,
        overAge: this.outComeDetailFinish?.overAge ? this.outComeDetailFinish?.overAge : false
      };

      this._addWBAService.addWbaOutComeBind(savedDta).subscribe((resp: any) => {

        if (resp) {
          this.getOutComeDetail(this.wbaId);
          this._notifierService._subProducerId.next(savedDta.subProducer);
          this.closeModal();
          this.passEntry.emit('Add BindRequest');
          this.messageEvent.emit(resp);
          this.errorList = [];
        }
      },
        (err: any) => {
          this.errorList = [];
          if (err.status !== 200) {
            if (this.unAthorizesd) {
              this.showError = true;
              this.errorList.push(this.unAthorizesd);
              return
            }
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            }
            if (err.status == 403) {
              this.showError = true;
              let errorMess = "Access denied, your permissions do not allow you to proceed."
              this.errorList.push(errorMess)
            }
            else {
              this.showError = true;
              this.errorMessage = err.message;
              this.errorList.push(this.errorMessage);
            }
          }
        }
      );
    }
  }
  get f() {
    return this.addBindRequestForm.controls;
  }
  cancel() {
    if (!this.addBindRequestForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }
  showDialogBox() {
    this._confirmBox.message = this._userMessages.cancelButtonMessage;
    this._confirmBox.isVisible = true;
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(
      element.target.value,
      ''
    );
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,3})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

}

