import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { CustomeRemoveNumberPipe } from 'src/app/shared/pipes/remove-number.pipe';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.css']
})
export class AdditionalInformationComponent implements OnInit {

  @Input() tabName: any;
  @Output() nextTab: any = new EventEmitter<string>();

  isSubmitted: boolean = false;
  _navigationPath: NavigationPath;
  masterIndustryList: any;
  otherIndustryRecord: any[] = [{ displayName: '', id: 0 }];
  masterExtraExposureList: any;
  masterSpecialCircumstancesList: any;
  otherExposureRecord: any[] = [{ displayName: '', id: 0 }];
  OtherExposureName: string = '';
  OtherIndustryName: string = '';
  AdditionalData: any = {
    revenue: '',
    payroll: ''
  };
  specialCircumstancesIdList: any[] = [];
  specialCircumstancesListByWBAId: any[] = [];
  keysOfGroupName: any;
  groupByGroupName: any;
  @Input() businessLineIdfromApp: any;
  isPageDirty: boolean = false;
  activeWbaId: any;

  constructor(
    private _numberPipe: CustomeNumberPipe,
    private _removeNumberPipe: CustomeRemoveNumberPipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public ValidateKey: KeyboardValidation,
    private _addWBAService: WhiteBoardActivityService,
    private _router: Router,
    private activeRouter: ActivatedRoute,
  ) {
    this._navigationPath = new NavigationPath();
  }

  ngOnInit() {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    window.scroll(0, 0);
    this.getMasterData();
  }

  getAdditionalInformation() {
    this._addWBAService.getAdditionalInformation(this.activeWbaId).subscribe((response: any) => {
      this.AdditionalData = response;

      if (!this.AdditionalData.revenue) this.AdditionalData.revenue = '';
      if (!this.AdditionalData.payroll) this.AdditionalData.payroll = '';
      if (!this.AdditionalData.clientBeds) this.AdditionalData.clientBeds = '';
      if (!this.AdditionalData.squareFootage) this.AdditionalData.squareFootage = '';

      // if other industry record found, binding the name of the record
      let otherIndustryRecord = this.AdditionalData.wbaIndustryDetail.find((industry: industryModel) => industry.industryId === this.otherIndustryRecord[0].id);

      if (otherIndustryRecord) this.OtherIndustryName = otherIndustryRecord.industryName;

      // if other exposure record found, binding the name of the record
      let otherExposureRecord = this.AdditionalData.wbaExtraExposureDetail.find((exposure: exposureModel) => exposure.extraExposureId === this.otherExposureRecord[0].id);
      if (otherExposureRecord) this.OtherExposureName = otherExposureRecord.extraExposureName;

      // formatting of currency controls
      let currencyControls = ['revenue', 'payroll'];
      currencyControls.forEach((controls: any) => this.addNumberFormat(controls));
    })
  }

  isChecked(id: number, type: string, idKey: string): boolean {
    let source = this.AdditionalData[type as keyof AdditionalModel];

    if (source) {
      let index = this.AdditionalData[type as keyof AdditionalModel].findIndex((obj: any) => obj[idKey] === id);

      return index > -1 ? true : false;
    } else {
      return false;
    }
  }

  handleOtherInput(id: number, type: string, value: string) {
    this.isPageDirty = true;
    if (type === 'wbaIndustryDetail') {
      let record = this.AdditionalData.wbaIndustryDetail.find((obj: any) => obj.industryId === id)
      record.industryName = value;
    } else {
      let record = this.AdditionalData.wbaExtraExposureDetail.find((obj: any) => obj.extraExposureId === id)
      record.extraExposureName = value;
    }
  }

  handleCheckbox(id: number, type: string) {
    this.isPageDirty = true;
    if (type === 'wbaIndustryDetail') {
      let obj = { industryId: id, industryName: '' }

      let index = this.AdditionalData.wbaIndustryDetail.findIndex((obj: any) => obj.industryId === id)

      if (index === -1) this.AdditionalData.wbaIndustryDetail.push(obj);
      else {
        if (this.AdditionalData.wbaIndustryDetail[index].industryId == this.otherIndustryRecord[0].id) this.OtherIndustryName = '';
        this.AdditionalData.wbaIndustryDetail.splice(index, 1)
      }

    } else if (type === 'wbaExtraExposureDetail') {
      let obj = { extraExposureId: id, extraExposureName: '' }

      let index = this.AdditionalData.wbaExtraExposureDetail.findIndex((obj: any) => obj.extraExposureId === id)

      if (index === -1) this.AdditionalData.wbaExtraExposureDetail.push(obj);
      else {
        if (this.AdditionalData.wbaExtraExposureDetail[index].extraExposureId == this.otherExposureRecord[0].id) this.OtherExposureName = '';
        this.AdditionalData.wbaExtraExposureDetail.splice(index, 1)
      }
    }
  }

  handleSpecialCircumstancesCheckbox(specialCircumstances: any) {
    this.isPageDirty = true;
    specialCircumstances.isChecked = !specialCircumstances.isChecked;
    if (specialCircumstances.id == 11 || specialCircumstances.id == 12) {
      this.specialCircumstancesIdList = [];
      if (specialCircumstances.id == 11 && specialCircumstances.isChecked) {
        this.specialCircumstancesIdList.push(specialCircumstances.id);
        this.masterSpecialCircumstancesList.forEach((element: any) => {
          if (specialCircumstances.isChecked && element.id != specialCircumstances.id) {
            element.isChecked = false;
          }
        });
        return;
      }
      if (specialCircumstances.id == 12 && specialCircumstances.isChecked) {
        this.specialCircumstancesIdList.push(specialCircumstances.id);
        this.masterSpecialCircumstancesList.forEach((element: any) => {
          if (specialCircumstances.isChecked && element.id != specialCircumstances.id) {
            element.isChecked = false;
          }
        });
        return;
      }
    }
    else {
      //remove 11 and 12
      var accordOrCyberIsChecked = this.specialCircumstancesIdList.find((x: any) => x == 11 || x == 12);
      if (accordOrCyberIsChecked) {
        this.specialCircumstancesIdList = []
        this.masterSpecialCircumstancesList.forEach((element: any) => {
          if (specialCircumstances.isChecked && element.id != specialCircumstances.id) {
            element.isChecked = false;
          }
        });
      }
      let index = this.specialCircumstancesIdList.findIndex((obj: any) => obj === specialCircumstances.id);
      let record = { ...specialCircumstances };
      if (index === -1) {
        this.specialCircumstancesIdList.push(specialCircumstances.id);
      }
      else {
        this.specialCircumstancesIdList.splice(index, 1);
      }
    }

  }
  saveAdditionalInformation(action: string) {
    if (!this.AdditionalData.wbaId) this.AdditionalData.wbaId = this.activeWbaId;

    let request = { ...this.AdditionalData }

    request.payroll = !request.payroll ? 0 : parseInt(this._removeNumberPipe.transform(request.payroll));
    request.revenue = !request.revenue ? 0 : parseInt(this._removeNumberPipe.transform(request.revenue));

    if (!request.clientBeds) request.clientBeds = 0;
    if (!request.squareFootage) request.squareFootage = 0;

    this._addWBAService.saveAdditionalInformation(request).subscribe((response: any) => {
      if (action === 'next') {
        this.saveSpecialCircumstancesInfo()


      }
      else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()])
    })
  }
  saveSpecialCircumstancesInfo() {
    if (this.specialCircumstancesIdList.length == 0) {
      this.specialCircumstancesIdList.push(0)
    }
    let request = {
      "wbaId": this.activeWbaId,
      "specialCircumstencesId": this.specialCircumstancesIdList
    }
    this._addWBAService.saveSpecialCircumstancesInfo(request).subscribe((response: any) => {
      this.nextTab.emit(this.tabName)
    });

  }

  getMasterData() {
    this.getAdditionalInformation();
    var MasterExtraExposures = this._addWBAService.getMasterExtraExposures().pipe(map((res) => res), catchError(e => of('Additional Exposures fails')));; //to get Additional exposures
    var MasterSpecialCircumstances = this._addWBAService.getMasterSpecialCircumstances(this.businessLineIdfromApp).pipe(map((res) => res), catchError(e => of('Special Circumstances fails')));; //to get Special Circumstances
    var specialCircumstancesListByWBAId = this._addWBAService.getspecialCircumstancesListByWBAId(this.activeWbaId).pipe(map((res) => res), catchError(e => of('Special Circumstances List By WBAId fails')));; //to get Special Circumstances List By WBAId

    forkJoin([MasterExtraExposures, MasterSpecialCircumstances, specialCircumstancesListByWBAId]).subscribe((resp: any) => {
      this.masterExtraExposureList = resp[0].masterExtraExposuresList;
      this.masterSpecialCircumstancesList = resp[1].wbaCoverageSpecialCircumstances;
      this.specialCircumstancesListByWBAId = resp[2];
      this.masterSpecialCircumstancesList.forEach((masterObj: any) => {
        masterObj.isChecked = false;
        //checking existing checked values
        this.specialCircumstancesListByWBAId.forEach((checkedObj: any) => {
          if (masterObj.id == checkedObj.specialCircumstencesId) {
            masterObj.isChecked = true;
            //pushing existing checked/unchecked 
            this.specialCircumstancesIdList.push(checkedObj.specialCircumstencesId);
          }
        });
      });
      // deleting record whose name includes 'other' keyword from master variable and saving it in another variable 
      let exposureOtherIndex = this.masterExtraExposureList.findIndex((obj: any) => obj.displayName.toLowerCase().indexOf('other') !== -1)
      if (exposureOtherIndex !== -1) this.otherExposureRecord = this.masterExtraExposureList.splice(exposureOtherIndex, 1);

      this.groupByGroupName = this.masterSpecialCircumstancesList.reduce((group: { [x: string]: any[]; }, product: { groupName: any; }) => {
        const { groupName } = product;
        group[groupName] = group[groupName] ?? [];
        group[groupName].push(product);
        return group;
      }, {});
      this.keysOfGroupName = Object.keys(this.groupByGroupName);
    },
    )


  }

  addNumberFormat(key: string) {
    this.AdditionalData[key] = this._numberPipe.transform(this.AdditionalData[key], 'USD', '1.0-0');
  }

  removeNumberFormat(key: string) {
    this.AdditionalData[key] = this._removeNumberPipe.transform(this.AdditionalData[key]);
  }

  removeSpaces(key: string) {
    this.AdditionalData[key] = this._removeWhiteSpacesPipe.transform(this.AdditionalData[key], '');
  }
  handleData() {
    this.isPageDirty = true;
  }
}

export interface AdditionalModel {
  wbaId: number;
  revenue: number;
  payroll: number;
  clientBeds: number;
  squareFootage: number;
  wbaExtraExposureDetail: exposureModel[];
  wbaIndustryDetail: industryModel[];
}
export interface industryModel {
  industryId: number;
  industryName: string;
}
export interface exposureModel {
  extraExposureId: number;
  extraExposureName: string;
}