<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">

          <div class="col-12 wbaHeadding">
            <div class="hd-width">
              <h2>AE Status</h2>
              <div class="alert alert-danger alert-dismissible fade show alert-wba" role="alert"
                *ngIf="!isAERequestSubmit && wbaDetails">
                <ul class="view-error">
                  <li>Access denied, your permissions do not allow you to proceed.</li>
                </ul>
                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>
              <a class="email-btn me-4" (click)="openeEmailTemplateModal()">
                <img src="assets/images/email-s.png" alt="Submissions Email" />Submissions Email
              </a>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12">
            <div class="addw">
              <div class=" frmrow add-wba-form">
                <!-- <div class="row" *ngIf="!isAERequestSubmit  && wbaDetails">
                  <div class="col-12">
                    <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                      <ul class="view-error">
                        <li>Access denied, your permissions do not allow you to proceed.</li>
                      </ul>
                      <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                        aria-label="Close"></button>
                    </div>
                  </div>
                </div> -->

                <div class="addw" id="tb-h">
                  <div class="frmrow add-wba-form mb-2 mt-0">
                    <form class="row">
                      <div class="col-12 col-sm-12 col-md-4" *ngIf="hideReviewDropdown">
                        <div class="form-group ">
                          <label class="form-label" for="Review">Review</label>
                          <!-- <div class="select-div ">
                            <select class="form-select l-3" #review id="Review" (change)="setReview(review.value)">
                              <option *ngFor="let item of account" [value]="item.value">
                                {{ item.label }}
                              </option>
                            </select>
                          </div> -->
                          <div class="select-div">
                            <!-- Display label instead of dropdown -->
                            <span class="form-select no-dropdown-icon">{{ account[0]?.label }}</span>
                          </div>
                          <button [disabled]="!isAERequestSubmit" class="btn btn-primary my-2"
                            (click)="createAccordReview()">Request Approval</button>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-4" *ngIf="marketingStatusDropdown">
                        <div class="form-group">
                          <label class="form-label" for="WBA Status">WBA Marketing
                            Status</label>
                          <div class="select-div">
                            <select class="form-select" #review id="Review"
                              (change)="setWbaMarketingStatus(review.value)">
                              <option value="none" selected disabled hidden>Select
                              </option>
                              <option *ngFor="let item of wba_marketingStatus" [value]="item.value">
                                {{ item.label }}
                              </option>
                            </select>
                          </div>
                          <button [disabled]="!isRemarketingRequestSubmit" class="btn btn-primary my-2"
                            (click)="createMarketingStatus()">Submit</button>

                        </div>
                      </div>


                      <div class="row align-items-center">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 review-hd ">
                              <h6>
                                Review Status
                              </h6>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 mt-2">
                              <div *ngIf="amDropdown">
                                <div class=" has-validation mb-2">
                                  <label class="form-label" for="ProfitCenter">Profit Center :</label>
                                  <select class="form-control" [ngClass]="{'isDisabled': showwbaprofitCenter}"
                                    #ProfitCenter id="wba_profitCenter">
                                    <option *ngFor="let option of wba_profitCenter" [value]="option.label"
                                      [selected]="SelectedvalueforProfitCenter == option.label">
                                      {{ option.label }}
                                    </option>
                                  </select>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>

                      <table class="table wba-tbl" id="response" *ngIf="acordWBA">
                        <thead class="table wba-tbl">
                          <tr>
                            <th>Approvals</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>User</th>
                            <th>Description</th>
                          </tr>

                        </thead>
                        <tbody>
                          <tr *ngFor="let item of AcordResponse ">
                            <td>{{item.requestType}}</td>
                            <td>
                              <div class="flex-div">

                                <img *ngIf="item.requestStatus=='Request AE Approval'" src="assets/images/requested.svg"
                                  alt="Request AE Approval" class="me-2 " />
                                <img *ngIf="item.requestStatus=='AM Requested'" src="assets/images/requested.svg"
                                  alt="AM Requested" class="me-2 " />
                                <img *ngIf="item.requestStatus=='Incomplete Information'"
                                  src="assets/images/incomplete-info.svg" alt="Incomplete Information" class="me-2 " />
                                <img *ngIf="item.requestStatus=='Incomplete Contact'"
                                  src="assets/images/incomplete-contact.svg" alt="Incomplete Contact" class="me-2 " />
                                <img *ngIf="item.requestStatus=='AE Approved'" src="assets/images/approved.svg"
                                  alt="AE Approved" class="me-2 " />
                                <img *ngIf="item.requestStatus=='AE Declined'" src="assets/images/denied.svg"
                                  alt="AE Declined" class="me-2 " />

                                {{item.requestStatus}}
                              </div>
                            </td>
                            <td>
                              <div class="flex-div" *ngIf="item.requestDate"><img
                                  *ngIf="item.requestStatus!='AM Requested'" src="assets/images/calendar.svg"
                                  class="me-2" />{{item.requestDate+'Z' |
                                date:'MM/dd/yyy hh:mm:ss a' }}
                              </div>
                            </td>
                            <td>
                              <div class="flex-div"><img *ngIf="item.requestStatus!='AM Requested'"
                                  src="assets/images/user.svg" alt="" class="me-2" />{{item.createdByUserName}}</div>
                            </td>
                            <td class="des-table-data">
                              <div class="flex-div des-text-div" *ngIf="item.requestStatus!='AM Requested'">
                                {{item?.description && item?.requestType ==
                                'AM Approval Status' ? item?.description : 'N/A'}}
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="amDropdown">
                            <td colspan="5" class="expandRow">
                              <div class="row">
                                <div class="col-4">
                                  <!-- <div class="mb-2">
                                                            <label class="form-label me-4"
                                                                for="ProfitCenter">Profit
                                                                Center</label>
                                                            <div class="select-div">
                                                                <select class="form-select l-3"
                                                                    [ngClass]="{'isDisabled': showwbaprofitCenter}"
                                                                    #ProfitCenter id="wba_profitCenter">
                                                                    <option
                                                                        *ngFor="let option of wba_profitCenter"
                                                                        [value]="option.label"
                                                                        [selected]="SelectedvalueforProfitCenter == option.label">
                                                                        {{ option.label }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div> -->

                                  <div class="mb-2">
                                    <label class="form-label me-4" for="AmApproval">AE Approval</label>
                                    <div class="select-div">
                                      <select class="form-select l-3" #wba id="wba_approval"
                                        (change)="setAMApproval(wba.value)">
                                        <option value="none" selected disabled hidden>Select</option>
                                        <option *ngFor="let option of acc_manager" [value]="option.label">
                                          {{ option.label }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                  <div *ngIf="selectedRsmApproval=='Declined'">
                                    <label class="form-label me-4" for="Review">Comment</label>
                                    <div class="select-div">
                                      <select class="form-select l-3" #review id="Review"
                                        (change)="setComments(review.value)">
                                        <option value="none" selected disabled hidden>Select</option>
                                        <option *ngFor="let item of reasonResponse" [value]="item.reason">
                                          {{ item.reason }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>

                                </div>
                                <div class="col-8" *ngIf="selectedRsmApproval=='Declined'">
                                  <label class=" form-label me-4" for="Description">Description</label>
                                  <div class="select-div">
                                    <textarea class="form-control des-textarea" name="" id="" cols="50" rows="50"
                                      (blur)="setDespcription($event)"></textarea>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="mt-2 col-12 col-sm-12 col-md-12 text-right">
                                  <button [disabled]="!isAERequestforRequestApproval" class="btn btn-primary"
                                    (click)="updateAccessReview()">Submit</button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>


                      <!-- <ng-container *ngIf="sdRsmStatus == 'AE Declined'">
                                    <div>
                                        <h6><label for="Review Status" style="color: #122B74;"
                                                class="fw-bold mb-3 mt-3">AE Declined History</label>
                                        </h6>
                                    </div>

                                    <table class="table wba-tbl" id="response">
                                        <thead class="table wba-tbl">
                                            <tr>
                                                <th>Status</th>
                                                <th>Date</th>
                                                <th>User</th>
                                                <th>Comments</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let item of wbaAeHistoryResponse ">
                                                <td>
                                                    <div class="flex-div">
                                                        <img src="assets/images/denied.svg"
                                                            alt="AE Declined" class="me-2 " />
                                                        {{item.status}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="flex-div"><img
                                                            src="assets/images/calendar.svg"
                                                            class="me-2" />{{item.createdDateTime | date}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="flex-div"><img src="assets/images/user.svg"
                                                            alt="" class="me-2" />{{item.createdUserName}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="flex-div">{{item.comments}}</div>
                                                </td>
                                                <td class="des-table-data">
                                                    <div class="flex-div des-text-div">
                                                        {{item?.description ? item?.description : 'N/A'}}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-container> -->


                      <!-- <div *ngIf="marketingStatusDropdown">
                                    <div class="expandRow-con">
                                        <div class="col-6 col-sm-12 col-md-6">
                                            <div class="form-group flex-div">
                                                <label class="col-4 form-label" for="Review">WBA Marketing
                                                    Status</label>
                                                <div class="select-div col-12 col-md-6 col-md-3">
                                                    <select class="form-select l-3" #review id="Review"
                                                        (change)="setWbaMarketingStatus(review.value)">
                                                        <option value="none" selected disabled hidden>Select
                                                        </option>
                                                        <option *ngFor="let item of wba_marketingStatus"
                                                            [value]="item.value">
                                                            {{ item.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2 col-12 col-sm-12 col-md-12 text-right">
                                        <button class="btn btn-primary" (click)="createMarketingStatus()"
                                            [disabled]="isAssesmentParamsHasMandatoryValues">Submit</button>
                                    </div>
                                </div> -->

                      <p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
                        [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                        [resizable]="false" [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
                        <p>{{ _warningBox.message }}</p>
                        <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                          <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                        </ng-template>
                      </p-dialog>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
