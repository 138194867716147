<div class="modal-content">
     <div class="modal-body">
          <div class="add-pro-outer">
               <div class="add-hdr">
                    <div class="heading">
                         <div class="col-12 col-sm-12 col-md-12">
                              <div class="row align-items-center">
                                   <div class="col-10 col-sm-12 col-md-10">
                                        <h2>
                                             <label>Coverage :</label>
                                             <span class="ml-1">
                                                  {{quoteData.coverageName}}</span>
                                             <ng-container *ngIf="quoteData.carrierName != null">
                                                  <label class="ml-5">Carrier :</label>
                                                  <span class="ml-1">
                                                       {{quoteData.carrierName}}</span>
                                             </ng-container>
                                             <ng-container *ngIf="quoteData.wholesalerName != null">
                                                  <label class="ml-5">Wholesaler :</label>
                                                  <span class="ml-1">
                                                       {{quoteData.wholesalerName}}</span>
                                             </ng-container>


                                        </h2>
                                   </div>
                                   <div class="col-2 col-sm-12 col-md-2">
                                        <a href="javascript:void(0)" title="" (click)="cancel()" class="close"
                                             data-bs-dismiss="modal" aria-label="Close"><img
                                                  src="assets/images/cross.svg" alt="" /></a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div class="row" *ngIf="showError">
                    <div class="col-12">
                         <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                              <ul class="view-error">
                                   <li *ngFor="let err of errorList">{{err}}</li>
                              </ul>
                              <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                                   aria-label="Close"></button>
                         </div>
                    </div>
               </div>

               <div class="add-content add-form">
                    <div class="pdg-con">
                         <form class="row g-3" novalidate [formGroup]='quoteDetailsForm' id="quoteDetailsForm"
                              onkeydown="return event.key != 'Enter';">
                              <div class="add-form">
                                   <div class="add-fld">
                                        <div class="col-12 col-sm-12 col-md-12">
                                             <div class="row">
                                                  <div class="col-3 col-sm-12 col-md-2">
                                                       <div class="has-validation">
                                                            <label>Quote Status <span class='star-color'></span>
                                                            </label>
                                                            <select class="select-cs form-select mt-1" name="size"
                                                                 dataTextFocus formControlName="quotedStatus"
                                                                 [class.errorInput]="f.quotedStatus.invalid && submitted"
                                                                 (change)="onQuoteStatusChange()">
                                                                 <option value="" [selected]="">Select
                                                                      Status</option>
                                                                 <option value="In Process">In Process
                                                                 </option>
                                                                 <option value="Cleared">Cleared
                                                                 </option>
                                                                 <option value="Need Additional Info">Need
                                                                      Additional Info</option>
                                                                 <option value="Blocked">Blocked</option>
                                                                 <option value="Quoted">Quoted</option>
                                                                 <option value="Declined">Declined</option>
                                                                 <option value="Not Bound">Not Bound</option>
                                                                 <option value="Bound">Bound</option>
                                                                 <option value="Bound, Currently in Renewal">Bound,
                                                                      Currently in Renewal</option>
                                                                 <option value="Bound with Other">Bound with Other
                                                                 </option>
                                                                 <option value="Expired">Expired</option>
                                                                 <option value="Closing with Carrier">Closing with
                                                                      Carrier</option>
                                                            </select>
                                                            <!-- <div class="display-error"
                                                                 *ngIf="f.quotedStatus.invalid && submitted">
                                                                 <span
                                                                      *ngIf="f.quotedStatus.errors?.required">{{_quoteDetailsMessage.quotedStatus.required}}</span>
                                                            </div> -->
                                                       </div>
                                                  </div>
                                                  <div class="col-3 col-sm-12 col-md-2">
                                                       <div class="has-validation">
                                                            <label class="form-label">Clearance Date<span
                                                                      class='star-color'
                                                                      *ngIf="f.quotedStatus.value === 'Cleared'"> *
                                                                 </span></label>
                                                            <div class="date-control">
                                                                 <p-calendar class="form-control"
                                                                      placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'"
                                                                      dateFormat="mm/dd/yy" inputId="clearanceDate"
                                                                      [ngClass]="{error:f.clearanceDate.invalid}"
                                                                      formControlName="clearanceDate"></p-calendar>
                                                                 <div class="display-error"
                                                                      *ngIf="f.clearanceDate.invalid && submitted">
                                                                      <span
                                                                           *ngIf="f.clearanceDate.errors?.required">{{_quoteDetailsMessage.clearanceDate.required}}</span>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div class="col-3 col-sm-12 col-md-2">
                                                       <div class="has-validation">
                                                            <label class="form-label">Quote Date</label>
                                                            <div class="date-control">
                                                                 <p-calendar class="form-control"
                                                                      placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'"
                                                                      dateFormat="mm/dd/yy" inputId="quoteDate"
                                                                      [ngClass]="{error:f.quoteDate.invalid}"
                                                                      formControlName="quoteDate"></p-calendar>
                                                                 <div class="display-error"
                                                                      *ngIf="f.quoteDate.invalid && submitted">
                                                                      <span
                                                                           *ngIf="f.quoteDate.errors?.required">{{_quoteDetailsMessage.quoteDate.required}}</span>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <div class="col-3 col-sm-12 col-md-2">
                                                       <div class="has-validation">
                                                            <label class="form-label">Total Quoted Premium </label>
                                                            <div class="pos-re">
                                                                 <span class="dollor-view-quote mt-1px">$</span>
                                                                 <input formControlName="quotedPremium"
                                                                      [class.errorInput]="f.quotedPremium.invalid && submitted"
                                                                      class="form-control  error dollor-input"
                                                                      placeholder="Total Quoted Premium" />
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <!-- <div class="col-4 col-sm-12 col-md-2 has-validation">
                                                       <label class="form-label">Carriers Comments </label>
                                                       <textarea class="form-control" id="policyDescription" rows="1"
                                                            placeholder="Carriers Comments"
                                                            formControlName="comments"></textarea>
                                                       <div class="display-error"
                                                            *ngIf="f.comments.invalid && submitted">
                                                            <span
                                                                 *ngIf="f.comments.errors?.required">{{_quoteDetailsMessage.comment.required}}</span>
                                                       </div>
                                                  </div>

                                                  <div class="col-2 col-sm-12 col-md-2 has-validation my-4">
                                                       <div>
                                                            <label class="form-label mr-2">Quote document attached?
                                                            </label>
                                                            <div>
                                                                 <div class="form-check form-check-inline">
                                                                      <input class="form-check-input me-2" type="radio"
                                                                           formControlName="isDocumentChecked"
                                                                           name="isDocumentChecked"
                                                                           id="isDocumentChecked1" value="true">
                                                                      <label class="form-check-label"
                                                                           for="isDocumentChecked">Yes</label>
                                                                 </div>
                                                                 <div class="form-check form-check-inline">
                                                                      <input class="form-check-input me-2" type="radio"
                                                                           formControlName="isDocumentChecked"
                                                                           name="isDocumentChecked"
                                                                           id="isDocumentChecked2" value="false">
                                                                      <label class="form-check-label"
                                                                           for="isDocumentChecked">No</label>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div> -->

                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </form>
                         <div class="pro-content">
                              <div class="row me-2" id="viewinfo1">
                                   <div class="pt-0 pl-0 mx-3">
                                        <ul class="nav nav-tabs" id="myTab-bot" role="tablist">
                                             <ng-container *ngFor="let list of CoverageCheckList;let i = index">
                                                  <li *ngFor="let item of list.coverage;let ii = index" class="nav-item"
                                                       role="presentation">
                                                       <button class="nav-link" (click)="parentTabChange(item)"
                                                            [ngClass]="currentParentTab && currentParentTab.displayName === item.displayName
                                                           ? 'active': ''" id="{{ i + '-tab' }}" data-bs-toggle="tab"
                                                            type="button" role="tab" aria-selected="true">
                                                            {{ item.displayName }}
                                                       </button>
                                                  </li>
                                             </ng-container>
                                        </ul>

                                        <div class="sub-tab-content">
                                             <ul *ngIf="currentParentTab && currentParentTab.displayName !== 'Services'"
                                                  class="nav nav-tabs" id="myTab-bot-subtab" role="tablist">
                                                  <li *ngFor="let item of tabs; let i = index" class="nav-item"
                                                       role="presentation">
                                                       <button class="nav-link" (click)="tabChange(item)" [ngClass]="
                                                           currentTab === item ? 'active' : ''" id="{{ i + '-tab' }}"
                                                            data-bs-toggle="tab" type="button" role="tab"
                                                            aria-selected="true">
                                                            {{ item }}
                                                       </button>
                                                  </li>
                                             </ul>

                                             <div class="sub-tab-content-inner">
                                                  <ng-container
                                                       *ngIf="currentParentTab && currentParentTab.displayName !== 'Services'">
                                                       <div
                                                            class="table-responsive table-responsive-md dial-table tbld mt-3">
                                                            <ng-container *ngIf="currentTab === 'Coverages';">
                                                                 <table class="table tbl-dial" id="tbl-checkdetails">
                                                                      <thead class="table-dark">
                                                                           <tr>
                                                                                <th width="20%"> Group
                                                                                     Name
                                                                                     <span class="star-color"></span>
                                                                                </th>
                                                                                <th width="30%"> Coverage
                                                                                     <span class="star-color"></span>
                                                                                </th>
                                                                                <th> Requested
                                                                                     <span class="star-color"></span>
                                                                                </th>
                                                                                <th> Quoted
                                                                                     <span class="star-color"></span>
                                                                                </th>
                                                                           </tr>
                                                                      </thead>

                                                                      <tbody>
                                                                           <ng-container
                                                                                *ngIf="currentParentTab.parameters && currentParentTab.parameters.length else NoData">
                                                                                <tr
                                                                                     *ngFor="let parameter of currentParentTab.parameters;let i = index;">
                                                                                     <td class="cr-wd">
                                                                                          {{getGroupName(currentParentTab,
                                                                                          i)}}

                                                                                     </td>

                                                                                     <td class="cr-wd">
                                                                                          <span pTooltip="{{ parameter.displayName }}"
                                                                                               tooltipPosition="bottom"
                                                                                               tooltipStyleClass="tooltipstyle">
                                                                                               {{parameter.displayName}}
                                                                                          </span>
                                                                                     </td>

                                                                                     <td>
                                                                                          <ng-container
                                                                                               *ngIf="parameter.valueType!=''; else displayLabelShortNameStandard">
                                                                                               <div class="pos-re">
                                                                                                    <ng-container
                                                                                                         *ngIf="parameter.valueType === 'input-currency' || parameter.valueType === 'dropdown,input-currency';else inputNumberStandard">
                                                                                                         <input
                                                                                                              class="form-control"
                                                                                                              [ngClass]="'assess_input_' + parameter.expiringAssessmentId"
                                                                                                              [(ngModel)]="parameter.standardPremium"
                                                                                                              (focusout)="addCurrencyFormat( $event, parameter, 'standardPremium')"
                                                                                                              (keypress)=" ValidateKey.integer( $event, 16 )"
                                                                                                              (focus)="removeCurrencyFormat( $event )"
                                                                                                              (focusout)=" removeSpaces($event, parameter, 'standardPremium' )"
                                                                                                              (change)="handleData()"
                                                                                                              disabled />

                                                                                                         <img *ngIf="parameter.expiringAssessmentId>0"
                                                                                                              pTooltip="{{parameter.expiringConditionDescription}}"
                                                                                                              tooltipPosition="bottom"
                                                                                                              tooltipStyleClass="tooltipstyle"
                                                                                                              src="assets/images/info.png"
                                                                                                              alt
                                                                                                              class="infoImage ms-2" />
                                                                                                    </ng-container>
                                                                                                    <ng-template
                                                                                                         #inputNumberStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf=" parameter.valueType==='input-number' || parameter.valueType === 'dropdown,input-number'; else inputTextStandard">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.standardPremium"
                                                                                                                   (focusout)="addCurrencyFormat($event,parameter,'standardPremium')"
                                                                                                                   (keypress)="ValidateKey.integer($event,16)"
                                                                                                                   (focus)="removeCurrencyFormat($event)"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'standardPremium')"
                                                                                                                   (change)="handleData()"
                                                                                                                   disabled />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #inputTextStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-text' || parameter.valueType === 'dropdown' || parameter.valueType === 'dropdown,input-text'; else dateStandard">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.standardPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'standardPremium')"
                                                                                                                   (change)="handleData()"
                                                                                                                   disabled />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #dateStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-date';else input_zipStandard">
                                                                                                              <div
                                                                                                                   class="row-detail date-control has-validation">
                                                                                                                   <div
                                                                                                                        class="des">
                                                                                                                        <div
                                                                                                                             class="date-control-view wba-date">
                                                                                                                             <p-calendar
                                                                                                                                  class="form-control"
                                                                                                                                  placeholder="MM/DD/YYYY"
                                                                                                                                  [showIcon]="true" [iconDisplay]="'input'"
                                                                                                                                  dateFormat="mm/dd/yy"
                                                                                                                                  (onSelect)="onDateChange(parameter, 'standardPremium')"
                                                                                                                                  [(ngModel)]="parameter.standardPremium"
                                                                                                                                  (change)="handleData()"
                                                                                                                                  [disabled]="true"></p-calendar>
                                                                                                                        </div>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_zipStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-zip'; else input_einStandard">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   mask="00000-0000||00000"
                                                                                                                   [(ngModel)]="parameter.standardPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'standardPremium')"
                                                                                                                   (change)="handleData()"
                                                                                                                   disabled />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_einStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-ein'; else input_expmodStandard">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   mask="00-0000000"
                                                                                                                   [(ngModel)]="parameter.standardPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'standardPremium')"
                                                                                                                   (change)="handleData()"
                                                                                                                   disabled />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_expmodStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-expmod'; else input_PercentageStandard">
                                                                                                              <input
                                                                                                                   (keypress)="ValidateKey.WholeNumbers($event, 4)"
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.standardPremium"
                                                                                                                   appTwoDigitDecimaNumber
                                                                                                                   (change)="handleData()"
                                                                                                                   disabled />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_PercentageStandard>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'dropdown,input-percent' || parameter.valueType === 'input-percent'">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.standardPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'standardPremium')"
                                                                                                                   (change)="handleData()"
                                                                                                                   (keypress)="ValidateKey.integer($event,3)"
                                                                                                                   disabled />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                               </div>
                                                                                          </ng-container>

                                                                                          <ng-template
                                                                                               #displayLabelShortNameStandard>
                                                                                               <ng-container
                                                                                                    *ngIf="(parameter.valueType === '' && parameter.parameterCategory !== 'Price' && parameter.parameterCategory === 'Carrier'); else displayLabelNameInsuredStandard">
                                                                                                    {{quoteData.carrierShortName}}
                                                                                               </ng-container>
                                                                                          </ng-template>
                                                                                          <ng-template
                                                                                               #displayLabelNameInsuredStandard>
                                                                                               <ng-container
                                                                                                    *ngIf="(parameter.valueType === '' && parameter.parameterCategory !== 'Price' && parameter.parameterCategory === 'Additional Insureds' && parameter.displayName === 'Named Insured'); else showExpiringStandard">
                                                                                                    {{prospectName}}
                                                                                               </ng-container>
                                                                                          </ng-template>
                                                                                          <ng-template
                                                                                               #showExpiringStandard>
                                                                                               <div class="pos-re">
                                                                                                    <span
                                                                                                         class="dollor">$</span>
                                                                                                    <input
                                                                                                         class="form-control"
                                                                                                         [ngClass]="'assess_input_' + parameter.expiringAssessmentId"
                                                                                                         [(ngModel)]="parameter.standardPremium"
                                                                                                         (focusout)="addCurrencyFormat( $event, parameter, 'standardPremium')"
                                                                                                         (keypress)=" ValidateKey.integer( $event, 16 )"
                                                                                                         (focus)="removeCurrencyFormat( $event )"
                                                                                                         (focusout)=" removeSpaces($event, parameter, 'standardPremium' )"
                                                                                                         (change)="handleData()"
                                                                                                         disabled />

                                                                                                    <img *ngIf="parameter.expiringAssessmentId>0"
                                                                                                         pTooltip="{{parameter.expiringConditionDescription}}"
                                                                                                         tooltipPosition="bottom"
                                                                                                         tooltipStyleClass="tooltipstyle"
                                                                                                         src="assets/images/info.png"
                                                                                                         alt
                                                                                                         class="infoImage ms-2" />
                                                                                               </div>
                                                                                          </ng-template>
                                                                                     </td>
                                                                                     <td class="exp-col">
                                                                                          <ng-container
                                                                                               *ngIf="parameter.valueType!=''; else displayLabelShortName">

                                                                                               <div class="pos-re">
                                                                                                    <ng-container
                                                                                                         *ngIf="parameter.valueType === 'input-currency' || parameter.valueType === 'dropdown,input-currency';else inputNumber">

                                                                                                         <input
                                                                                                              class="form-control"
                                                                                                              [ngClass]="'assess_input_' + parameter.expiringAssessmentId"
                                                                                                              [(ngModel)]="parameter.carrierPremium"
                                                                                                              (focusout)="addCurrencyFormat( $event, parameter, 'carrierPremium')"
                                                                                                              (focus)="removeCurrencyFormat( $event )"
                                                                                                              (keypress)=" ValidateKey.integer( $event, 16 )"
                                                                                                              (focusout)=" removeSpaces($event, parameter, 'carrierPremium' )"
                                                                                                              (change)="handleData()" />

                                                                                                         <img *ngIf="parameter.expiringAssessmentId>0"
                                                                                                              pTooltip="{{parameter.expiringConditionDescription}}"
                                                                                                              tooltipPosition="bottom"
                                                                                                              tooltipStyleClass="tooltipstyle"
                                                                                                              src="assets/images/info.png"
                                                                                                              alt
                                                                                                              class="infoImage ms-2" />
                                                                                                    </ng-container>
                                                                                                    <ng-template
                                                                                                         #inputNumber>
                                                                                                         <ng-container
                                                                                                              *ngIf=" parameter.valueType==='input-number' || parameter.valueType === 'dropdown,input-number'; else inputText">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.carrierPremium"
                                                                                                                   (focusout)="addCurrencyFormat($event,parameter,'carrierPremium')"
                                                                                                                   (keypress)="ValidateKey.integer($event,16)"
                                                                                                                   (focus)="removeCurrencyFormat($event)"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'carrierPremium')"
                                                                                                                   (change)="handleData()" />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #inputText>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-text' || parameter.valueType === 'dropdown' || parameter.valueType === 'dropdown,input-text'; else date">
                                                                                                              <input
                                                                                                                   class=" form-control"
                                                                                                                   [(ngModel)]="parameter.carrierPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'carrierPremium')"
                                                                                                                   (change)="handleData()" />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template #date>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-date';else input_zip">
                                                                                                              <div
                                                                                                                   class="row-detail date-control has-validation">
                                                                                                                   <div
                                                                                                                        class="des">
                                                                                                                        <div
                                                                                                                             class="date-control-view wba-date">
                                                                                                                             <p-calendar
                                                                                                                                  class="form-control"
                                                                                                                                  placeholder="MM/DD/YYYY"
                                                                                                                                  [showIcon]="true" [iconDisplay]="'input'"
                                                                                                                                  dateFormat="mm/dd/yy"
                                                                                                                                  (onSelect)="onDateChange(parameter, 'carrierPremium')"
                                                                                                                                  [(ngModel)]="parameter.carrierPremium"
                                                                                                                                  (change)="handleData()"></p-calendar>
                                                                                                                        </div>
                                                                                                                   </div>
                                                                                                              </div>
                                                                                                         </ng-container>
                                                                                                    </ng-template>

                                                                                                    <ng-template
                                                                                                         #input_zip>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-zip'; else input_ein">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   mask="00000-0000||00000"
                                                                                                                   [(ngModel)]="parameter.carrierPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'carrierPremium')"
                                                                                                                   (change)="handleData()" />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_ein>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-ein'; else input_expmod">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   mask="00-0000000"
                                                                                                                   [(ngModel)]="parameter.carrierPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'carrierPremium')"
                                                                                                                   (change)="handleData()" />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_expmod>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'input-expmod'; else input_Percentage">
                                                                                                              <input
                                                                                                                   (keypress)="ValidateKey.WholeNumbers($event, 4)"
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.carrierPremium"
                                                                                                                   appTwoDigitDecimaNumber
                                                                                                                   (change)="handleData()"
                                                                                                                   (input)="handleInputQuoted(parameter)" />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                         #input_Percentage>
                                                                                                         <ng-container
                                                                                                              *ngIf="parameter.valueType === 'dropdown,input-percent' || parameter.valueType === 'input-percent'">
                                                                                                              <input
                                                                                                                   class="form-control"
                                                                                                                   [(ngModel)]="parameter.carrierPremium"
                                                                                                                   (focusout)="removeSpaces($event,parameter,'carrierPremium')"
                                                                                                                   (change)="handleData()"
                                                                                                                   (keypress)="ValidateKey.integer($event,3)"
                                                                                                                   (focus)="removePercentageFormat($event,parameter,'carrierPremium')" />
                                                                                                         </ng-container>
                                                                                                    </ng-template>
                                                                                               </div>
                                                                                          </ng-container>

                                                                                          <ng-template
                                                                                               #displayLabelShortName>
                                                                                               <ng-container
                                                                                                    *ngIf="(parameter.valueType === '' && parameter.parameterCategory !== 'Price' && parameter.parameterCategory === 'Carrier'); else displayLabelNameInsured">
                                                                                                    {{quoteData.carrierShortName}}
                                                                                               </ng-container>
                                                                                          </ng-template>
                                                                                          <ng-template
                                                                                               #displayLabelNameInsured>
                                                                                               <ng-container
                                                                                                    *ngIf="(parameter.valueType === '' && parameter.parameterCategory !== 'Price' && parameter.parameterCategory === 'Additional Insureds' && parameter.displayName === 'Named Insured'); else showExpiring">
                                                                                                    {{prospectName}}
                                                                                               </ng-container>
                                                                                          </ng-template>
                                                                                          <ng-template #showExpiring>

                                                                                               <div class="pos-re">
                                                                                                    <span
                                                                                                         class="dollor">$</span>
                                                                                                    <input
                                                                                                         class="form-control"
                                                                                                         [ngClass]="'assess_input_' + parameter.expiringAssessmentId"
                                                                                                         [(ngModel)]="parameter.carrierPremium"
                                                                                                         (focusout)="addCurrencyFormat( $event, parameter, 'carrierPremium')"
                                                                                                         (keypress)=" ValidateKey.integer( $event, 16 )"
                                                                                                         (focus)="removeCurrencyFormat( $event )"
                                                                                                         (focusout)=" removeSpaces($event, parameter, 'carrierPremium' )"
                                                                                                         (change)="handleData()" />

                                                                                                    <img *ngIf="parameter.expiringAssessmentId>0"
                                                                                                         pTooltip="{{parameter.expiringConditionDescription}}"
                                                                                                         tooltipPosition="bottom"
                                                                                                         tooltipStyleClass="tooltipstyle"
                                                                                                         src="assets/images/info.png"
                                                                                                         alt
                                                                                                         class="infoImage ms-2" />
                                                                                               </div>
                                                                                          </ng-template>
                                                                                     </td>
                                                                                </tr>
                                                                           </ng-container>
                                                                           <ng-template #NoData>
                                                                                <tr>
                                                                                     <td colSpan="4">
                                                                                          No data found
                                                                                     </td>
                                                                                </tr>
                                                                           </ng-template>
                                                                      </tbody>
                                                                 </table>
                                                            </ng-container>
                                                       </div>
                                                  </ng-container>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div class="btn-blk1" *ngIf="currentParentTab">
                              <!-- <button class="btn btn-primary me-3" (keydown.enter)="preventDefault()"
                                   (click)="updatepolicyInEPIC()" *ngIf="f.quotedStatus.value === 'Quoted'"
                                   [disabled]="wbaDetails?.outcome == 'Bound'">
                                   Update Policy in EPIC</button> -->
                              <button class="btn btn-primary me-3" (keydown.enter)="preventDefault()"
                                   (click)="updateWbaSubmissionCarriers()"
                                   [disabled]="isDisable || (wbaReviewStatus !== 'Complete' && marketingSummaryProgress) || wbaDetails?.outcome == 'Bound'">Save</button>
                              <button class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
                         </div>
                    </div>
               </div>
               <!-- Dialog for error -->
               <p-dialog [header]="_errorBox.header" [modal]="true" [(visible)]="_errorBox.isVisible"
                    [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
                    [styleClass]="_errorBox.borderColor" *ngIf="_errorBox">
                    <p>{{_errorBox.message}}</p>
                    <ng-template pTemplate="footer">
                         <p-button (click)="errorBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                    </ng-template>
               </p-dialog>
               <!-- Dialog for warning -->
               <p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
                    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                    [resizable]="false" [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
                    <p>{{ _warningBox.message }}</p>
                    <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                         <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                    </ng-template>
               </p-dialog>
               <!-- Dialog for Bound Form Fill -->
               <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
                    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                    [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
                    <p>{{ _confirmBox.message }}</p>
                    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                         <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                    </ng-template>
               </p-dialog>
          </div>
     </div>
</div>
