<div class="mid-con">
    <div class="tab-content py-0">
        <div class="tab-pane fade show active">
            <div class="pro-right-con">
                <div class="row">
                    <div class="col-12 wbaHeadding">
                        <div class="hd-width">
                            <h2>EPIC Policy Details</h2>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="card-text row addw" id="tb-h">
                            <div class="row frmrow add-wba-form my-2">
                                <form [formGroup]="epicPolicyForm">
                                    <div class="row row-cols-4">

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">EPIC Unique Policy ID<span
                                                    class='star-color'></span></label>
                                            <input formControlName="epicUniquePolicyId" class="form-control"
                                                placeholder="EPIC Unique Policy ID" type="text" />
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">Previous EPIC ID<span
                                                    class='star-color'></span></label>
                                            <input formControlName="previousEpicId" class="form-control"
                                                placeholder="Enter EPIC ID" type="text" />
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">Policy Status<span
                                                    class='star-color'></span></label>
                                            <select class="select-cs form-select" formControlName="policyStatus">
                                                <option disabled value="" class="form-control">
                                                    Select Policy Status
                                                </option>
                                                <option value="ASG">ASG</option>
                                                <option value="CAN">CAN</option>
                                                <option value="CIR">CIR</option>
                                                <option value="CNP">CNP</option>
                                                <option value="DEC">DEC</option>
                                                <option value="ERP">ERP</option>
                                                <option value="EXT">XT</option>
                                                <option value="LAP">LAP</option>
                                                <option value="MKP">MKP</option>
                                                <option value="NEW">NEW</option>
                                                <option value="NR">NR</option>
                                                <option value="NWQ">NWQ</option>
                                                <option value="REN">REN</option>
                                                <option value="REW">REW</option>
                                                <option value="BOR">BOR</option>
                                                <option value="BRE">BRE</option>
                                            </select>
                                        </div>

                                        <div class="col mb-2  date-control epic  has-validation">
                                            <label class="form-label">Bound Date<span class='star-color'></span></label>
                                            <p-calendar [readonlyInput]="true" placeholder="MM/DD/YYYY"
                                                class="form-control" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                inputId="boundDate" formControlName="boundDate"></p-calendar>
                                        </div>

                                        <div class="col mb-2   has-validation">
                                            <label class="form-label">Modified By<span
                                                    class='star-color'></span></label>
                                            <input formControlName="modifiedby" dataTextFocus class="form-control"
                                                placeholder="Modified By" type="text" />
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">Original Producer<span
                                                    class='star-color'></span></label>
                                            <div class="flex-div">
                                                <p-autoComplete placeholder="Original Producer"
                                                    class="form-control auto-comp" [showEmptyMessage]="true"
                                                    [suggestions]="accountManagerlist" field="value" [minLength]="1"
                                                    (onSelect)="selectItem($event.value,'accountManagerProducer')"
                                                    (onDropdownClick)="getCompanyList('accountManager',$event)"
                                                    (completeMethod)="getCompanyList('accountManager',$event)"
                                                    formControlName="originalProducer">
                                                    <ng-template let-account pTemplate="item">
                                                        <div class="company-item">
                                                            <div>{{ account.userName }}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-autoComplete>
                                                <img src="assets/images/search.svg"
                                                    (click)="openLookupModal('AccountManager','Producer')" alt=""
                                                    class="ms-2" />
                                            </div>
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">Original Sub Producer<span
                                                    class='star-color'></span></label>
                                            <div class="flex-div">
                                                <p-autoComplete placeholder="Original Sub Producer"
                                                    class="form-control auto-comp" [showEmptyMessage]="true"
                                                    [suggestions]="accountManagerlist" field="value" [minLength]="1"
                                                    (onSelect)="selectItem($event.value,'accountManagerSubProducer')"
                                                    (onDropdownClick)="getCompanyList('accountManager',$event)"
                                                    (completeMethod)="getCompanyList('accountManager',$event)"
                                                    formControlName="originalSubProducer">
                                                    <ng-template let-account pTemplate="item">
                                                        <div class="company-item">
                                                            <div>{{ account.userName }}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-autoComplete>
                                                <img src="assets/images/search.svg"
                                                    (click)="openLookupModal('AccountManager','SubProducer')" alt=""
                                                    class="ms-2" />
                                            </div>
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">eFile Parent Node Id<span
                                                    class='star-color'></span></label>
                                            <input formControlName="efileParentNodeId" class="form-control"
                                                placeholder="eFile Parent Node Id" type="text" />
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">BOR Timer<span class='star-color'></span></label>
                                            <input formControlName="borTimer" class="form-control"
                                                placeholder="BOR Timer" type="text" />
                                        </div>

                                        <div class="col mb-2  has-validation">
                                            <label class="form-label">eFile Node Id<span
                                                    class='star-color'></span></label>
                                            <input formControlName="eFileNodeId" class="form-control"
                                                placeholder="eFile Node Id" type="text" />
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-right mt-2">
                                            <button class="btn btn-primary"
                                                [disabled]="wbaView?.outcome != 'Bound' || !isValidUser "
                                                (click)="epicPolicyDetails()">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
