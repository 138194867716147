import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-account-manager',
  templateUrl: './account-manager.component.html',
  styleUrls: ['./account-manager.component.css']
})
export class AccountManagerComponent implements OnInit {
  wba_approval: any = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
  ];
  wba_profitCenter: any = [
    { label: 'Lamb', value: 'Lamb' },
    { label: 'CREIS', value: 'CREIS' },
    { label: 'TruePartners', value: 'TruePartners' },
    { label: 'TruePartners - Wheels', value: 'TruePartners - Wheels' },
  ];


  @Input() public tabName: any;
  @Input() public userDetailsOutput: any;
  @Input() public selectedValue: any;
  @Input() public acordTab: any;
  @Input() public PrevtabName: any;
  @Input() isAssesmentParamsHasMandatoryValues: any;
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() acordStatus: any = new EventEmitter<string>();
  @Output() setCreatedAcordId: any = new EventEmitter<string>();
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  @Output() handleAMIconStatus: any = new EventEmitter<any>();
  public _navigationPath: NavigationPath = new NavigationPath();

  hideReviewDropdown: boolean = true;
  acordWBA: boolean = false;
  SDRSMapproval: boolean = false;
  marketingReview: boolean = true;
  accessReviewList: Array<any> = [];
  selectedReview: any;
  selectedReviewResponse: any;
  AcordResponse: Array<any> = [];
  requestStatus: any;
  rolesList: Array<any> = [];
  wbaFileResponse: Array<any> = [];
  marketingReviewStatus: any;
  roleId: any;
  roleName: string = '';
  selectedAcord: any;
  wbaId: any
  userId: any;
  roles: any;
  userRoleId: any;
  AcordArray: any;
  marketingReviewArray: any;
  wbaFileDropdownAccord: any
  wbaFileDropdownApplication: any;
  wbaFileDropdownModDocument: any;
  wbaFileDropdownLOSS: any;
  businessLineIdResp: Array<any> = [];
  BusinessLineId: any;
  marketingroleName: string = "";
  sdRsmStatus: any;
  wbaLossFileId: number = 0;
  wbaAccordFileId: number = 0;
  wbaModDocumentFileId: number = 0;
  wbaApplicationFileId: number = 0;
  selectWbaApproval: string = "";
  RsmArray: any;
  selectedComment: any;
  isDisabled: boolean = false;
  reasonResponse: any;
  BusinessLine: any;
  rsmRoleName: string = "";
  marketingCreatedByUserName: any;
  rsmCreatedByUserName: any;
  acordCreatedByUserName: any;
  loggedInUserName: any;
  wbaResponse: any;
  rsmApprovalDropdowns: boolean = true;
  wbaProgressChange: any;
  wbaReviewTable: boolean = false;
  selectedWbaReviewStatus: any;
  selectedReasonForWbaReview: any;
  selectedReminderForWbaReview: any;
  wbaReminderDropdown: boolean = true;
  assessmentName: any;
  cvgName: any;
  workercompRating: any;
  assessmentGLRating: any;
  glRating: any;
  assessmentWCRating: any;
  epicUniquePolicyId: any;
  epicPolicyResponse: any;
  _warningBox: ConfirmBox;
  loggedInUserId: any;
  notBOR: any;
  rsmResponse: any;
  activeWbaId: any;
  prospectId: any
  wbaTransType: any;
  isDirty: boolean = false;
  SelectedvalueforProfitCenter: any;
  showwbaprofitCenter: boolean = true;
  wbaDetails: any;
  coverageAssessmentDetailData: any
  tabRefresh: any;
  takingDataFromApi: any;

  constructor(
    private wbaService: WhiteBoardActivityService,
    private _AcmeProductService: AcmeProductService,
    private _router: Router,
    private _loaderService: LoaderService,
    private _addWBAService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private _dataCacheService: DatacacheService
  ) {
    this._warningBox = new ConfirmBox()
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    // this.getBusinessLineList()
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.loggedInUserName = userDetails?.user?.display_name;
    this.loggedInUserId = userDetails?.user?.userId;
    // this.getAccessReviewList();
    // this.getAccordReasonList();
    // this.getWhiteBoardDetails(this.activeWbaId);
    // this.getCoverageAssessmentDetails();
    this.intializeData();
    window.scroll(0, 0);
  }

  getBusinessLineList() {
    // this.wbaService.getBusinessLineList().subscribe(resp => {
    // this.businessLineIdResp = resp?.businessLineList
    this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
    this.BusinessLineId = this.BusinessLine?.businessLineId;
    this.wbaFileResponse = this._dataCacheService.retrieveData('wba_wbaFileResponse');
    if (this.wbaFileResponse) {
      this.notBOR = this.wbaFileResponse.filter((res: any) => res.fileType !== "BOR");
    }
    else this.getWBAFileById();
    // })
  }

  intializeData() {
    this.tabRefresh = this._dataCacheService.retrieveData('tabRefresh');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    // this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLineIdResp');
    this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLine');
    this.AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse');
    this.wbaResponse = this._dataCacheService.retrieveData('wba_wbaResponse');
    this.reasonResponse = this._dataCacheService.retrieveData('wba_reasonResponse');
    this.coverageAssessmentDetailData = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == "Account Manager");
    if (!this.wbaDetails || this.AcordResponse.length == 0 || !this.coverageAssessmentDetailData) this.getAllMaster();
    else this.mapaccountMngr();
  }

  getAllMaster() {
    let requestBody = {
      wbaId: this.activeWbaId,
    };
    let data = {
      acordReasonType: "rsm"
    }
    // var businessLineList = this.wbaService.getBusinessLineList();
    var accessReviewList = this.wbaService.getAccessReviewList(requestBody);
    var AccordReasonList = this.wbaService.getAccordReasonList(data);
    var wbaActivityList = this._addWBAService.getWBAById(this.activeWbaId);
    var CoverageAssessmentDetails = this.wbaService.getCoverageAssessmentDetails(this.activeWbaId);
    forkJoin([accessReviewList, AccordReasonList, wbaActivityList, CoverageAssessmentDetails
    ]).subscribe((resp) => {
      if (resp) {
        // this.businessLineIdResp = resp[0].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[0]);
        this.AcordResponse = resp[0].acordResponse;
        this.wbaResponse = resp[0].wbaReviews;
        this.reasonResponse = resp[1].acordReasonList;
        this.wbaDetails = resp[2].whiteBoardActivityInfo;
        this.coverageAssessmentDetailData = resp[3];
        // this.tabRefresh[9].refresh = false;
        // this._dataCacheService.compressAndCacheData('tabRefresh', this.tabRefresh);
        this.mapaccountMngr();
        this.setDataInCache();
      }
    })
  }
  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    // this._dataCacheService.compressAndCacheData('wba_businessLineIdResp', this.businessLineIdResp);
    this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
    this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
    this._dataCacheService.compressAndCacheData('wba_reasonResponse', this.reasonResponse);
    this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.coverageAssessmentDetailData);
  }

  mapaccountMngr() {
    this.getBusinessLineList();
    this.mapAccessReviewList();
    this.mapwbaActivityList();
    this.getCoverageAssessmentDetails();
  }

  mapwbaActivityList() {
    this.wbaProgressChange = this.wbaDetails?.progressName;
    this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    this.epicUniquePolicyId = this.wbaDetails?.epicUniquePolicyId;
    this.prospectId = this.wbaDetails?.prospectId
    this.wbaTransType = this.wbaDetails?.transType;
    this.SelectedvalueforProfitCenter = this.wbaDetails?.profitCenter;
    this.setUpdatedProgressId.emit(this.wbaDetails?.progressId);
  }

  mapAccessReviewList() {
    this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
    this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
    this.marketingCreatedByUserName = this.marketingReviewArray?.createdByUserName;
    this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
    this.acordStatus = this.AcordArray?.requestStatus
    this.acordCreatedByUserName = this.AcordArray?.createdByUserName;
    this.RsmArray = this.AcordResponse?.find(e => e?.requestType == 'AM Status');
    this.sdRsmStatus = this.RsmArray?.requestStatus;
    this.rsmCreatedByUserName = this.RsmArray?.createdByUserName;
    this.hideSDrsmFields();
    this.setRsmApprovalDropdowns()
    this.setwbaReviewTable();
    this.acordWBA = true;
    this.marketingReview = false;
  }

  setWbaApproval(item: any) {
    this.isDirty = true;
    this.selectWbaApproval = item;
  }
  setComments(item: any) {
    this.isDirty = true;
    this.selectedComment = item;
    this.isDisabled = false;
  }

  createAccordReview() {

    let data = {
      "wbaId": this.activeWbaId,
      "acordStatus": this.selectedReview
    };
    this.wbaService.createAccordReview(data).subscribe(res => {

      this.selectedReviewResponse = res;
      this.setCreatedAcordId.emit(res);

      if (res) {
        this.getAccessReviewList();
      }
    });
  }

  getAccessReviewList() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };

    this.wbaService.getAccessReviewList(requestBody).subscribe(res => {
      this.AcordResponse = res?.acordResponse;
      this.wbaResponse = res?.wbaReviews;
      this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', res);
      this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
      this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
      this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
      this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
      this.marketingCreatedByUserName = this.marketingReviewArray?.createdByUserName;
      this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
      this.acordStatus = this.AcordArray?.requestStatus
      this.acordCreatedByUserName = this.AcordArray?.createdByUserName;
      this.RsmArray = this.AcordResponse?.find(e => e?.requestType == 'AM Status');
      this.sdRsmStatus = this.RsmArray?.requestStatus;
      this.rsmCreatedByUserName = this.RsmArray?.createdByUserName;
      this.hideSDrsmFields();
      this.setRsmApprovalDropdowns()
      this.setwbaReviewTable();

      if (this.wbaResponse[0]?.wbaReviewStatus) {
        this.handleAMIconStatus.emit({ label: 'Account Manager', value: false });
      }
      else {
        this.handleAMIconStatus.emit({ label: 'Account Manager', value: true });
      }
    });
    this.acordWBA = true;
    this.marketingReview = false;
  }

  hideSDrsmFields() {
    this.SDRSMapproval = this.sdRsmStatus == "Pending" ? true : false;
  }

  setRsmApprovalDropdowns() {
    if (this.acordStatus == "Send To Account Manager" && this.sdRsmStatus == "Pending") {
      this.rsmApprovalDropdowns = false;
    }
  }

  setwbaReviewTable() {
    if (this.wbaResponse[0]?.wbaReviewStatus != null && this.sdRsmStatus == 'Approved') {
      this.wbaReviewTable = true;
      this.SDRSMapproval = false;
    }
  }

  // acord review for SD/RSM Approval
  getWBAFileById() {
    let requestBody = {
      wbaId: this.activeWbaId,
      lineId: this.BusinessLineId
    }
    this.wbaService.getWBAFileById(requestBody).subscribe(res => {
      this.wbaFileResponse = res.wbaFiles;
      this._dataCacheService.compressAndCacheData('wba_wbaFileResponse', this.wbaFileResponse);
      this.notBOR = this.wbaFileResponse.filter((res: any) => res.fileType !== "BOR");
    })
    this._loaderService.hide();

  }

  updateAccessReview() {
    if (this.selectWbaApproval == "Approved") {
      let requestBody1 = {
        "wbaId": this.activeWbaId,
        "wbaReviewStatus": "Complete",
        "reminderdays": 0
      }
      this.wbaService.createWbaReviewStatus(requestBody1).subscribe(res => {
        if (res) {
          this.getAccessReviewList();
          this.getWBAFileById();
          this.getWhiteBoardDetails(this.activeWbaId);
        }
      });

      let requestBody2 = {
        id: this.selectedReviewResponse,
        wbaId: this.activeWbaId,
        acordStatus: this.acordStatus,
        marketingStatus: this.marketingReviewStatus,
        marketingUser: null,
        marketingUserId: null,
        marketingComments: "",
        rsmStatus: "",
        rsmUser: "",
        rsmUserId: "",
        rsmComments: "",
        aeUserId: "",
        amUserId: this.loggedInUserId,
        amUser: this.loggedInUserName,
        amStatus: this.selectWbaApproval,
        amComments: "",
        profitCenter: this.SelectedvalueforProfitCenter,
      }
      this.wbaService.updateAccountManagerReview(requestBody2).subscribe(res => {
        this.isDirty = false;
        this.getAccessReviewList();
        this.getWBAFileById();
        this.rsmResponse = res;
        if (res?.id === 0) {
          this.showWarningBox4();
        }
      })
    }
    else {
      let requestBody = {
        id: this.selectedReviewResponse,
        wbaId: this.activeWbaId,
        acordStatus: this.acordStatus,
        marketingStatus: this.marketingReviewStatus,
        marketingUser: null,
        marketingUserId: null,
        marketingComments: "",
        rsmStatus: "",
        rsmUser: "",
        rsmUserId: "",
        rsmComments: "",
        aeUserId: "",
        amUserId: this.loggedInUserId,
        amUser: this.loggedInUserName,
        amStatus: this.selectWbaApproval,
        amComments: "",
        profitCenter: this.SelectedvalueforProfitCenter,

      }
      this.wbaService.updateAccountManagerReview(requestBody).subscribe(res => {
        this.isDirty = false;
        this.getAccessReviewList();
        this.getWBAFileById();
        this.getWhiteBoardDetails(this.activeWbaId);
        this.rsmResponse = res;
        if (res?.id === 0) {
          this.showWarningBox4();
        }
      })
    }
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaDetails = resp.whiteBoardActivityInfo;
      this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
      this.epicUniquePolicyId = resp?.whiteBoardActivityInfo?.epicUniquePolicyId;
      this.prospectId = resp?.whiteBoardActivityInfo?.prospectId
      this.wbaTransType = resp.whiteBoardActivityInfo?.transType;
      this.SelectedvalueforProfitCenter = resp.whiteBoardActivityInfo?.profitCenter;
      this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
    });
  }


  // API for COMMENTS
  getAccordReasonList() {
    let data = {
      acordReasonType: "rsm"
    }
    this.wbaService.getAccordReasonList(data).subscribe(res => {
      this.reasonResponse = res.acordReasonList;

    })
  }

  showWarningBox1() {
    let message = `WC Rating Basis is not added on Coverage Assessment`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox3() {
    let message = `Policy is not created in EPIC`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox4() {
    let message = this.rsmResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox5() {
    let message = `GL Rating Basis is not added on Coverage Assessment.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  policyCreatedMsg() {
    let message = `Policy "${this.epicPolicyResponse.epicPolicyId}" created in EPIC`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  createPolicyErrMsg() {
    let message = this.epicPolicyResponse.errorMessage
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.prospectId,
      epicUniquePolicyId: this.epicUniquePolicyId,
      wbaTransType: this.wbaTransType,
      priorPolicyId: this.wbaDetails.priorPolicyId
    }
    modalRef.componentInstance.wbaIdData = data
  }

  getCoverageAssessmentDetails() {
    // this.wbaService.getCoverageAssessmentDetails(this.activeWbaId).subscribe(res => {
    let response = this.coverageAssessmentDetailData?.coverageAssessmentDetail?.find((el: any) => el?.coverageName == "General Liability" || el?.coverageName == "Workers Compensation");
    if (response?.coverageName == "General Liability") {
      this.assessmentGLRating = response?.ratingBasis;
    } else if (response?.coverageName == "Workers Compensation") {
      this.assessmentWCRating = response?.workercompRating;
    }
    // })
  }

  saveWBA(action: string) {
    if (action === 'next') {
      this.sdRsmStatus == "Approved" ? this.nextTab.emit(this.tabName) : this.nextTab.emit(this.acordTab)
    }
    else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
  }
}
