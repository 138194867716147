import { Component, OnInit, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { QuoteDetailsMessage } from 'src/app/features/white-board/error-message/quoteDetails-mesages'
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { environment } from 'src/environments/environment';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { DatePipe } from '@angular/common';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { CustomeRemoveNumberPipe } from 'src/app/shared/pipes/remove-number.pipe';

@Component({
  selector: 'app-quoteDetails',
  templateUrl: './quoteDetails.component.html',
  styleUrls: ['./quoteDetails.component.css']
})
export class QuoteDetailsComponent implements OnInit {
  @Input() quoteData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  _quoteDetailsMessage: QuoteDetailsMessage;
  submitted: boolean = false;
  quoteDetailsForm: any;
  isDisable: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  _errorBox: ErrorBox;
  _userMessages: UserMessages;

  CoverageCheckList: any = [];
  serviceTab: any = { displayName: 'Services' };
  tabs: any = ['Coverages'];
  currentParentTab: any;
  currentTab: string = this.tabs[0];
  public wbaId: any;
  coverageParameterCarrierPremiumList: any = [];
  isCarrierSubmitted: boolean = false
  wbaDetails: any;
  prospectName: any;
  expiringPremium: any = 0;
  totalQuotedPremium: number = 0;
  epicPolicyResponse: any;
  isQuotedPremiumExist: boolean = true;
  _confirmBox: ConfirmBox;
  isPageDirty: boolean = false;
  _warningBox: ConfirmBox;
  WBADetailedDataForOutcome: any;
  wbaProgressChange: any;
  marketingSummaryProgress: boolean = false;
  wbaReviewStatus: string = '';


  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    public ValidateKey: KeyboardValidation,
    private _preventBack: PreventBackButtonService,
    private el: ElementRef,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _whiteBoardActivityService: WhiteBoardActivityService,
    public _amountPipe: CustomeAmountPipe,
    private _numberPipe: CustomeNumberPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    public datepipe: DatePipe,
    private _wbaService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private _removeNumberPipe: CustomeRemoveNumberPipe,
  ) {
    this._quoteDetailsMessage = new QuoteDetailsMessage();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();

    //quote Details form creation
    this.quoteDetailsForm = this.fb.group({
      quotedStatus: [''],
      quotedPremium: [''],
      comments: [''],
      quoteDocument: [''],
      isDocumentChecked: ['false'],
      clearanceDate: [''],
      quoteDate: ['']
    })
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this._warningBox = new ConfirmBox()
  }

  ngOnInit(): void {
    this.wbaId = parseInt(sessionStorage.getItem(environment.storageVariables.wbaId) || "0");
    this.quoteDetailsForm.reset(); //method to reset quote Details form
    this._preventBack.preventBackButton(); //method to prevent browser back button
    if (this.quoteData) {
      this.quoteDetailsForm.patchValue({
        quotedStatus: this.quoteData.quotedStatus,
        quotedPremium: this.quoteData.quotedPremium ? this._amountPipe.transform(this.quoteData.quotedPremium, 'USD', '', '1.0-0') : "",
        comments: this.quoteData.comments,
        quoteDocument: this.quoteData.quoteDocument,
        isDocumentChecked: this.quoteData.isDocumentChecked.toString(),
        clearanceDate: this.quoteData.clearanceDate ? new Date(this.quoteData.clearanceDate) : null,
        quoteDate: this.quoteData.quoteDate ? new Date(this.quoteData.quoteDate) : null
      })

      this.quoteDetailsForm?.get('quotedStatus')?.disable();
      this.quoteDetailsForm?.get('quotedPremium')?.disable();
      this.quoteDetailsForm?.get('clearanceDate')?.disable();
      this.quoteDetailsForm?.get('quoteDate')?.disable();



      this.onQuoteStatusChange();

      const data = {
        "wbaId": this.quoteData.wbaId,
        "carrierId": this.quoteData.carrierId ? this.quoteData.carrierId : 0,
        "coverageId": this.quoteData.coverageId,
        "wholesalerId": this.quoteData.wholesalerId ? this.quoteData.wholesalerId : 0,
      }
      this.getWBACoverageParameterCarrierPremium(data);
      this.getWhiteBoardDetails(this.quoteData.wbaId);
    }
    this._notifierService._marketingSummaryProgress.subscribe(res => this.marketingSummaryProgress = res);
    this._notifierService._wbaReviewStatus.subscribe(res => this.wbaReviewStatus = res);
  }

  //to get Coverage Parameter Carrier Premium by wbaId and carrier Id
  getWBACoverageParameterCarrierPremium(data: any) {
    this._whiteBoardActivityService.getWBACoverageParameterCarrierPremium(data).subscribe(resp => {
      if (resp && resp.wbaCoverageParameterCarrierPremium.length > 0) {
        let currentTabData = resp.wbaCoverageParameterCarrierPremium[0];
        this.CoverageCheckList = resp.wbaCoverageParameterCarrierPremium;
        if (currentTabData) {
          this.currentParentTab = currentTabData.coverage[0];
          //sorting currentParentTab by group
          this.currentParentTab.parameters.sort((a: { parameterCategory: any; }, b: { parameterCategory: any; }) =>
            a.parameterCategory.localeCompare(b.parameterCategory));
        }
        this.getTotalQuotedPremium();
      }
    })
  }

  // to get Total Quoted Premium from carrierPremium
  getTotalQuotedPremium() {
    if (!this.quoteData.smsWbaId) {
      this.totalQuotedPremium = 0;
      this.CoverageCheckList.forEach((carrierList: any, index: any) => {
        carrierList.coverage.forEach((paramList: any) => {
          //sorting parameters by group
          //
          paramList.parameters.sort((a: { parameterCategory: any; }, b: { parameterCategory: any; }) =>
            a.parameterCategory.localeCompare(b.parameterCategory));
          paramList.parameters.forEach((preminumList: any) => {
            preminumList.carrierPremium = this._removeAmountPipe.transform(preminumList.carrierPremium);
            if (preminumList.carrierPremium && !isNaN(+preminumList.carrierPremium) && (preminumList.valueType === 'dropdown,input-percent' || preminumList.valueType === 'input-percent')) {
              if (+preminumList.carrierPremium > 100) {
                preminumList.carrierPremium = "100";
              }
              preminumList.carrierPremium = preminumList.carrierPremium + '%';
            }
            else if (preminumList.carrierPremium && !isNaN(+preminumList.carrierPremium) && (preminumList.parameterCategory === 'Underwriting Data') || ((preminumList.valueType === 'input-expmod'))) {
              // preminumList.carrierPremium = this.ValidateKey.formatDecimalNumber(preminumList.carrierPremium);
            }
            else if (preminumList.carrierPremium && !isNaN(+preminumList.carrierPremium) && (preminumList.valueType !== 'dropdown,input-percent' && preminumList.valueType !== 'input-percent')) {
              preminumList.carrierPremium = this._amountPipe.transform(preminumList.carrierPremium, 'USD', '$', '1.0-0');
            }

            preminumList.standardPremium = this._removeAmountPipe.transform(preminumList.standardPremium);
            if (preminumList.standardPremium && !isNaN(+preminumList.standardPremium) && (preminumList.valueType === 'dropdown,input-percent' || preminumList.valueType === 'input-percent')) {
              preminumList.standardPremium = preminumList.standardPremium + '%';
            }
            else if (preminumList.standardPremium && (preminumList.parameterCategory === 'Underwriting Data') || (!isNaN(+preminumList.standardPremium) && (preminumList.valueType === 'input-expmod'))) {
              // preminumList.standardPremium = this.ValidateKey.formatDecimalNumber(preminumList.standardPremium);
            }
            else if (preminumList.standardPremium && !isNaN(+preminumList.standardPremium) && (preminumList.valueType !== 'dropdown,input-percent' && preminumList.valueType !== 'input-percent')) {
              preminumList.standardPremium = this._amountPipe.transform(preminumList.standardPremium, 'USD', '$', '1.0-0');
            }

            // if (preminumList.parameterCategory === 'Price' && preminumList.displayName === 'Premium') {
            if (preminumList.displayName === 'Premium') {
              this.totalQuotedPremium = this.totalQuotedPremium + (+this._removeAmountPipe.transform(preminumList.carrierPremium));
            }

          })
        })
      });
      // this.quoteDetailsForm.patchValue({ quotedPremium: this.quoteData.quotedPremium });
      this.addNumberFormat(this.quoteData.quotedPremium);
    }
  }

  // on Premium Value Change
  onPremiumChange() {
    this.getTotalQuotedPremium()
  }

  updatepolicyInEPIC() {
    let requestBody = {
      wbaId: this.wbaId,
    };
    this._whiteBoardActivityService.createEpicInPolicy(requestBody).subscribe(res => {
      this.epicPolicyResponse = res;
      if (res.epicPolicyId) {
        this.policyUpdatedMsg();
      }
      else {
        this.updatePolicyErrMsg();
      }
    })
  }

  // Get wba details for prospectName
  getWhiteBoardDetails(wbaId: any) {
    this._whiteBoardActivityService.getWBAById(wbaId).subscribe(resp => {
      if (resp) {
        this.wbaDetails = resp.whiteBoardActivityInfo;
        this.prospectName = this.wbaDetails.companyName;
        this.expiringPremium = this.wbaDetails?.expiringPremium

        this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
        this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
        this._notifierService._setUpdatedProgressId.next(resp.whiteBoardActivityInfo.progressId);
      }
    });
  }

  //to save email template
  updateWbaSubmissionCarriers() {
    let checkReturnorNot: boolean = false
    this.submitted = true;
    let data = this.quoteDetailsForm?.value;
    if (data.quotedStatus == 'Bound') {
      this._notifierService.getWBADetailsValue().subscribe((res: any) => {

        this.WBADetailedDataForOutcome = res.outcome;
        if (this.WBADetailedDataForOutcome != 'Bound') {
          checkReturnorNot = true
          this._confirmBox.message = this._userMessages.overrideBoundButtonMessage;
          this._confirmBox.isVisible = true;
        }
      })

    }
    if (checkReturnorNot) {
      return;
    }
    if (this.quoteDetailsForm.invalid) {
      this.quoteDetailsForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      this.isDisable = false;
      return;
    }
    else {
      this.isQuotedPremiumExist = true
      this.CoverageCheckList.forEach((carrierList: any) => {
        carrierList.coverage.forEach((paramList: any) => {
          paramList.parameters.forEach((preminumList: any) => {
            // if (preminumList.parameterCategory === 'Price' && preminumList.displayName === 'Premium' && (!preminumList.carrierPremium)) {
            if (preminumList.displayName === 'Premium' && (!preminumList.carrierPremium)) {
              this.isQuotedPremiumExist = false;
            }
          })
        })
      });
      // }

      if (!this.quoteData.carrierId) {
        this.showCarrierIdErrorBox();
      }
      else if (!this.isQuotedPremiumExist) {
        this.showErrorBox();
      }
      else {
        this.isDisable = true;
        let updateData = {
          "wbaId": this.wbaId,
          "carrierId": this.quoteData.carrierId,
          "quotedStatus": data.quotedStatus,
          "quotedPremium": this.quoteDetailsForm.controls['quotedPremium'].value ? this._removeAmountPipe.transform(this.quoteDetailsForm.controls['quotedPremium'].value) : 0,
          "comments": data.comments,
          "isDocumentChecked": data.isDocumentChecked === 'true' ? true : false,
          "clearanceDate": this.datepipe.transform(this.quoteDetailsForm.controls['clearanceDate'].value, 'yyyy-MM-dd'),
          "quoteDate": this.datepipe.transform(this.quoteDetailsForm.controls['quoteDate'].value, 'yyyy-MM-dd')
        }

        this.coverageParameterCarrierPremiumList = [];
        this.CoverageCheckList.forEach((carrierList: any) => {
          carrierList.coverage.forEach((paramList: any) => {
            paramList.parameters.forEach((preminumList: any) => {
              let data = {
                "wbaId": carrierList.wbaId,
                "carrierid": carrierList.carrierId,
                "coverageId": paramList.id,
                "coverageParameterId": preminumList.coverageParameterId,
                "carrierPremimum": preminumList.carrierPremium,
                "standardPremium": preminumList.standardPremium,
                "MarketingSummarycoverageid": this.quoteData.coverageId,
                "WholesalerId": this.quoteData.wholesalerId,
                "QuotedPremium": this.removeNumberFormat(this.quoteDetailsForm.get('quotedPremium').value)
              }
              this.coverageParameterCarrierPremiumList.push(data);
            })
          })
        });

        this.isCarrierSubmitted = false;

        let payload = {
          "coverageParameterCarrierPremiumList": this.coverageParameterCarrierPremiumList
        }
        this._whiteBoardActivityService.addCoverageParameterCarrierAndStandardPremium(payload).subscribe(resp => {
          if (parseInt(resp) > 0) {
            let carrierSubmitted = this.isCarrierSubmitted;
            this.passEntry.emit('updateWbaSubmissionCarriers');
            this.cancel();
          }
          this.cancel();
          this.isDisable = false;
        }, (err: any) => {
          this.showError = true;
          this.isDisable = false; //enabling save button for after changes
          if (err.status !== 200) {
            if (err.status === 400) {
              this.showError = true;
              let obj = err.error.errors;
              var arr = Object.keys(obj).map((key) => ({
                type: key,
                value: obj[key],
              }));
              this.errorList = [];
              this.errorMessage = arr[0].value[0];
              this.errorList.push(this.errorMessage);
            } else {
              this.showError = true;
              this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
              this.errorList.push(this.errorMessage);
            }
          }
        });

        // this._whiteBoardActivityService.updateWbaSubmissionCarriers(updateData).subscribe(resp => {
        //   if (parseInt(resp) > 0) {
        //     this.isCarrierSubmitted = true;
        //     this.getWhiteBoardDetails(this.quoteData.wbaId);
        //   }
        // }, (err: any) => {
        //   this.showError = true;
        //   this.isDisable = false; //enabling save button for after changes
        //   if (err.status !== 200) {
        //     if (err.status === 400) {
        //       this.showError = true;
        //       let obj = err.error.errors;
        //       var arr = Object.keys(obj).map((key) => ({
        //         type: key,
        //         value: obj[key],
        //       }));
        //       this.errorList = [];
        //       this.errorMessage = arr[0].value[0];
        //       this.errorList.push(this.errorMessage);
        //     } else {
        //       this.showError = true;
        //       this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
        //       this.errorList.push(this.errorMessage);
        //     }
        //   }
        // });


      }
    }
  }

  preventDefault() {
    return false;
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    if (firstInvalidControl)
      firstInvalidControl.focus(); //without smooth behavior
  }

  //to calcel the process and back to email template screen
  cancel() {
    this.activeModal.close();
  }

  //to get all form controls
  get f() {
    return this.quoteDetailsForm.controls;
  }

  closeError() {
    this.showError = false;
  }

  parentTabChange(covg: any) {
    this.currentParentTab = covg;
  }

  tabChange(label: string) {
    this.currentTab = label;
  }

  getGroupName(coverage: any, index: number) {
    let currentIndexName = coverage.parameters[index].parameterCategory;

    if (index === 0) return currentIndexName;
    else {
      let lastIndexName = coverage.parameters[index - 1].parameterCategory;

      if (lastIndexName === currentIndexName) return '';
      else return currentIndexName;
    }
  }
  // To Add currency format
  addCurrencyFormat(element: any, parameter: any, key: string) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    if (element.target.value && !isNaN(+element.target.value)) {
      formattedAmount = this._amountPipe.transform(formattedAmount, 'USD', '$', '1.0-0');
      element.target.value = formattedAmount;
    }
    parameter[key] = element.target.value;
  }

  // To remove amount
  removeCurrencyFormat(element: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  // To remove Percentage
  removePercentageFormat(element: any, parameter: any, key: string) {
    if (parameter.valueType === 'dropdown,input-percent' || parameter.valueType === 'input-percent') {
      var removeSpaces;
      removeSpaces = element.target.value.replace('%', '');
      element.target.value = removeSpaces;
      parameter[key] = element.target.value;
    }
  }

  //To remove white spaces
  removeSpaces(element: any, parameter: any, key: string) {
    if ((parameter.valueType === 'dropdown,input-percent' || parameter.valueType === 'input-percent') && !isNaN(+element.target.value)) {
      var removeSpaces;
      removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
      if (removeSpaces && !removeSpaces.includes('%')) {
        removeSpaces = removeSpaces + '%';
      }

      element.target.value = removeSpaces;
      parameter[key] = element.target.value;
    }
    else {
      var removeSpaces;
      removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
      element.target.value = removeSpaces;
      parameter[key] = element.target.value;
    }

  }

  addCurrencyFormatQuote(element: any, control?: AbstractControl) {
    var formattedAmount;
    formattedAmount = this._amountPipe.transform(element.target.value, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    control?.setValue(element.target.value);
  }

  // To remove amount
  removeCurrencyFormatQuote(element: any, val?: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  //To remove white spaces
  removeSpacesQuote(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  onDateChange(parameter: any, type: string): void {
    if (type === "carrierPremium") {
      parameter.carrierPremium = this.datepipe.transform(parameter.carrierPremium, 'MM/dd/yyyy');
      parameter.standardPremium = this.datepipe.transform(parameter.carrierPremium, 'MM/dd/yyyy');
    }
    else {
      parameter.standardPremium = this.datepipe.transform(parameter.standardPremium, 'MM/dd/yyyy');
    }
  }

  onQuoteStatusChange() {
    if (this.f.quotedStatus.value === 'Cleared') {
      this.quoteDetailsForm.get('clearanceDate')?.setValidators([Validators.required]);
    }
    else {
      this.quoteDetailsForm.get('clearanceDate')?.setValidators(null);
    }
    this.quoteDetailsForm.get('clearanceDate')?.updateValueAndValidity({ emitEvent: false });
  }

  showErrorBox() {
    if (this.CoverageCheckList.length === 1) {
      this._errorBox.message = this._userMessages.wbaMarketSummaryWarningMessage;
    }
    else {
      this._errorBox.message = this._userMessages.wbaMarketSummaryMultipleCoveragesMessage;
    }

    this._errorBox.isVisible = true;
  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
  }

  handleData() {
    this.isPageDirty = true;
    this.onPremiumChange();
  }

  policyUpdatedMsg() {
    let message = `Policy "${this.epicPolicyResponse.epicPolicyId}" updated in EPIC`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
    this._confirmBox.isVisible = false;
  }

  updatePolicyErrMsg() {
    let message = this.epicPolicyResponse.errorMessage
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }

  showCarrierIdErrorBox() {
    this._errorBox.message = this._userMessages.wbaMarketSummaryCarrierIdErrorMessage;
    this._errorBox.isVisible = true;
  }

  handleInputQuoted(parameter: any) {
    //formatting exp mode
    if (parameter.valueType == "input-expmod") {
      parameter.carrierPremium = this.ValidateKey.formatDecimalNumber(parameter.carrierPremium);
    }
  }

  addNumberFormat(variable: any) {
    variable = this._numberPipe.transform(variable, 'USD', '1.0-0');
    this.quoteDetailsForm.patchValue({ quotedPremium: variable });
  }

  removeNumberFormat(variable: any) {
    variable = this._removeNumberPipe.transform(variable);
    return variable ? parseInt(variable) : 0
  }
}
