import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { proposalMessage } from '../../../error-message/quoteDetails-mesages';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { DatePipe } from '@angular/common';
import { NgxMaskPipe} from 'ngx-mask';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {
  @Input() ApplicationData: any;
  @Input() tierId: any;
  public isSubmitted: boolean = false;
  public CoverageCheckList: any = [];
  public MasterCoverageListNew: any;
  public wbaId: any = sessionStorage.getItem(environment.storageVariables.wbaId) || "0";

  public CoverageCheckListForApi: any = [];

  slideType: string = "";
  slideName: string = "";

  currentSlideName: any;
  wbaDetails: any;
  marketSummaryList: any = [];
  proposalForm: any;
  isDisable: boolean = false;
  _quoteDetailsMessage: proposalMessage;
  userId: string | null = "0";
  userDetails: any = [];
  presentedBy: any = "";
  pi_description: string = '<p><span style="color: black;">Lamb Insurance Services is pleased to provide this proposal to @ProspectName</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">This proposal employs Lamb’s unique skills as a broker specialized in commercial insurance for human service organizations and represents our recommendations based upon your specific needs.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">I look forward to reviewing this proposal with you.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">@UserName</span></p>';
  pi_descriptionOriginal: string = '<p><span style="color: black;">Lamb Insurance Services is pleased to provide this proposal to @ProspectName</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">This proposal employs Lamb’s unique skills as a broker specialized in commercial insurance for human service organizations and represents our recommendations based upon your specific needs.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">I look forward to reviewing this proposal with you.</span></p><p><span style="color: black;">&nbsp;</span></p><p><span style="color: black;">@UserName</span></p>';
  request: any = [];
  arrayForMasterServiceList: any[] = [];
  masterCoverageServiceList: any = [];
  services: any;
  claimServiceList: any;
  riskServiceList: any;
  riskServiceLabelOriginal = "Following are a few of the services we are offering @coverPageTitle";
  riskServiceLabel = "Following are a few of the services we are offering @coverPageTitle";
  apiPayload: any;
  proposalId: number = 0;
  proposalPath: string = "";
  proposalDoc: any;
  prospectName: any;
  expiringCarrier: any;
  expiringPremium: any;
  pdfSrc: any;
  userDetailes: any;
  totalHighlightLength: number = 0;
  totalParameterHighlightLength: number = 0;
  totalSelectLength: number = 0;
  totalParameterSelectLength: number = 0;
  coveragesFromDB: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private _addWBAService: WhiteBoardActivityService,
    private _preventBack: PreventBackButtonService,
    public _amountPipe: CustomeAmountPipe,
    private el: ElementRef,
    private _AcmeProductService: AcmeProductService,
    private _numberPipe: CustomeNumberPipe,
    public datepipe: DatePipe,
    public maskPipe: NgxMaskPipe,
    private modalService: NgbModal
  ) {
    this._quoteDetailsMessage = new proposalMessage();
    this.getFormControls();
  }

  getFormControls() {

    this.proposalForm = this.fb.group({
      coverPageTitle: ['', Validators.required],
      coverPageProspectName: ['', Validators.required],
      coverPagepresentedBy: ['', Validators.required],
      coverPageInclude: [''],
      hseDescription: [''],
      hseInclude: [''],
      lspInclude: [''],
      piDescription: [''],
      piInclude: [''],
      mlcInclude: [''],
      tpsBusinessLine: [''],
      tpsExpiringPremium: [''],
      tpsTargetPremium: [''],
      tpsInclude: [''],
      coveragesInclude: [''],
      coveragesAllChecked: [''],
      msInclude: [''],
      abrInclude: [''],
      rmgAboutRisk: [''],
      rmgDescription: [''],
      rmgServices: [''],
      rmgInclude: [''],
      ystResouceList: this.fb.array([]),
      ystInclude: [''],
      cmpInclude: [''],
      sbDescription: [''],
      sbInclude: [''],
      summaryDifferences: [''],
      summaryServices: [''],
      summaryInclude: [''],
      disclaimerInclude: [''],
      picInclude: [''],
      amTrustInclude: [''],
      cmInclude: [''],
      goInclude: [''],
      niaInclude: [''],
      berkleyInclude: [''],
      hanoverInclude: [''],
      krInclude: ['']
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    let { expiringPremium, expiringCarrierId } = changes.ApplicationData.currentValue;
    if (expiringPremium) {
      this.expiringCarrier = expiringCarrierId?.value;
      this.expiringPremium = expiringPremium ? "$" + expiringPremium : expiringPremium;
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.proposalForm.reset(); //method to reset Proposa form
    this._preventBack.preventBackButton(); //method to prevent browser back button

    this.proposalForm.patchValue({
      coverPageTitle: "Commercial Insurance Solutions for",
      coverPageInclude: true,
      hseDescription: '<h2><strong>Lamb Insurance Services is an insurance broker positioned uniquely in the marketplace to serve the needs of companies like yours.</strong></h2><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dedicated exclusively to non-profit and human services organizations</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experts in understanding the specific insurance needs of organizations in this space</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;More expertise in non-profits and human services than any other broker in the industry</p>',
      hseInclude: true,
      lspInclude: true,
      piInclude: true,
      mlcInclude: true,
      tpsInclude: true,
      coveragesInclude: true,
      coveragesAllChecked: false,
      msInclude: true,
      abrInclude: true,
      rmgAboutRisk: '<p>Insurance is the transfer of uncertain risks to another party (the insurer) for a certain sum of money (the premium).&nbsp;However, Risk Management is far broader.&nbsp;While it does encompass insurance, it also deals with avoiding, preventing, minimizing and >recovering from loss.&nbsp;In addition, Risk Management addresses methods other than insurance for transferring the financial consequences of losses. </p>',
      rmgDescription: 'Lamb Insurance Services has certified Insurance Code Rule 59 and 60 consultants, developing customized, comprehensive risk management packages.',
      rmgInclude: true,
      ystResouceName: '<h2><strong>Additional Lamb Resources:</strong></h2><p>Josh Lamberg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Founder &amp; Chief Executive Officer</p><p>Sal Abano&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; President</p>',
      ystInclude: true,
      cmpInclude: true,
      sbInclude: true,
      summaryDifferences: '<h2><strong>The Lamb Difference:</strong></h2><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Call out significant differentiators in this proposal.</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example: This proposal represents an annual savings of $xx, while offering incremental protection in the areas of x and y.</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etc.</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Etc.</p>',
      summaryServices: '<h2><strong>Lamb Insurance Services:</strong></h2><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dedicated exclusively to non-profit and human services organizations</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Uniquely qualified to understand and protect your business</p><p>•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;An advocate for organizations like yours</p>',
      summaryInclude: true,
      disclaimerInclude: true,
      picInclude: true,
      amTrustInclude: true,
      cmInclude: true,
      goInclude: true,
      niaInclude: true,
      berkleyInclude: true,
      hanoverInclude: true,
      krInclude: true
    })

    this.riskServiceLabel = this.riskServiceLabelOriginal.replace("@coverPageTitle", this.proposalForm.value.coverPageTitle);

    this.MasterCoverageListNew = [{ "id": "Cover Page", "displayName": "Cover Page", "isInclude": true },
    { "id": "Human Service Expertise", "displayName": "Human Service Expertise", "isInclude": true },
    { "id": "Lamb Safeguard Protect", "displayName": "Lamb Safeguard Protect", "isInclude": true },
    { "id": "Proposal Introduction", "displayName": "Proposal Introduction", "isInclude": true },
    { "id": "Map of Lamb Client", "displayName": "Map of Lamb Client", "isInclude": true },
    { "id": "Total Premium Summary", "displayName": "Total Premium Summary", "isInclude": true },
    { "id": "Coverages", "displayName": "Coverages", "isInclude": true },
    { "id": "Marketing Summary", "displayName": "Marketing Summary", "isInclude": true },
    { "id": "Am Best Rating", "displayName": "Am Best Rating", "isInclude": true },
    { "id": "Risk Management Glossary", "displayName": "Risk Management Glossary", "isInclude": true },
    { "id": "Your Service Team", "displayName": "Your Service Team", "isInclude": true },
    { "id": "Condition of Market Place", "displayName": "Condition of Market Place", "isInclude": true },
    { "id": "Subjectivities and Billing", "displayName": "Subjectivities and Billing", "isInclude": true },
    { "id": "Summary", "displayName": "Summary", "isInclude": true },
    { "id": "Disclaimer", "displayName": "Disclaimer", "isInclude": true },
    { "id": "Philadelphia Insurance Companies", "displayName": "Philadelphia Insurance Companies", "isInclude": true },
    { "id": "Amtrust", "displayName": "Amtrust", "isInclude": true },
    { "id": "Church Mutual", "displayName": "Church Mutual", "isInclude": true },
    { "id": "Guide One", "displayName": "Guide One", "isInclude": true },
    { "id": "Non-Profits Insurance Alliance", "displayName": "Non-Profits Insurance Alliance", "isInclude": true },
    { "id": "Berkley", "displayName": "Berkley", "isInclude": true },
    { "id": "Hanover", "displayName": "Hanover", "isInclude": true },
    { "id": "Key Risk", "displayName": "Key Risk", "isInclude": true }
    ]

    if (this.MasterCoverageListNew.length) this.openSlide({ "id": "Cover Page", "displayName": "Cover Page", "isInclude": true });

    this.currentSlideName = "Cover Page";
    this.wbaId = parseInt(sessionStorage.getItem(environment.storageVariables.wbaId) || "0");
    // For cover page slide
    this.userDetailes = JSON.parse(localStorage.getItem('userDetails')!);
    if (this.userDetailes) {
      this.userId = this.userDetailes.user.userId
    }


    this.getUserDetails(this.userId);

    //for Total Premium Summary slide
    this.getWhiteBoardDetails(this.wbaId);

    //for Marketing Summary slide
    this.getWbaProposalMarketSummaryListById(this.wbaId);


    // Get Proposal data By wba ID
    this.getWbaProposalById();
  }

  //to get wba carriers list for market summary slide
  getWbaProposalById() {
    this._addWBAService.getWbaProposalById(this.wbaId).subscribe(resp => {
      if (resp.id === 0 && resp.wbaId === 0) {
        // if proposal not saved then need to get data from api
        this.getCoveragedata();
        // Call it two times to display two records when there is not data return by getProposalById api
        this.addServiceTeamFormGroup();
        this.addServiceTeamFormGroup();
      }
      else {
        this.getCoveragedata();
        this.proposalId = resp.id;
        this.proposalPath = resp.path ? resp.path : "";
        this.proposalDoc = resp.proposalDoc ? resp.proposalDoc : "";
        if (resp.dynamicSlide.coverPage) {
          let coverPageSlide = resp.dynamicSlide.coverPage;

          this.proposalForm.patchValue({
            coverPageTitle: coverPageSlide.title,
            coverPageProspectName: coverPageSlide.prospectName,
            coverPagepresentedBy: coverPageSlide.presentedBy,
            coverPageInclude: coverPageSlide.included
          })
        }

        if (resp.dynamicSlide.hummanServiceExpertise) {
          let hseSlide = resp.dynamicSlide.hummanServiceExpertise;
          this.proposalForm.patchValue({
            hseDescription: hseSlide.description,
            hseInclude: hseSlide.included
          })
        }

        if (resp.dynamicSlide.proposalIntroduction) {
          let piSlide = resp.dynamicSlide.proposalIntroduction;
          this.proposalForm.patchValue({
            piDescription: piSlide.description,
            piInclude: piSlide.included
          })
        }

        if (resp.dynamicSlide.totalPremiumSummary) {
          let tpsSlide = resp.dynamicSlide.totalPremiumSummary;
          this.proposalForm.patchValue({
            tpsBusinessLine: tpsSlide.lob,
            tpsExpiringPremium: tpsSlide.expiring,
            tpsTargetPremium: tpsSlide.target,
            tpsInclude: tpsSlide.included
          })
        }

        if (resp.dynamicSlide.proposalCoverage) {
          this.coveragesFromDB = resp.dynamicSlide.proposalCoverage;
        }
        if (resp.dynamicSlide.marketingSummary) {
          let msSlide = resp.dynamicSlide.marketingSummary;

          if (msSlide[0]) {
            this.proposalForm.patchValue({
              msInclude: msSlide[0].included
            })
          }
        }

        if (resp.dynamicSlide.riskManagement) {
          let rmgSlide = resp.dynamicSlide.riskManagement;
          this.proposalForm.patchValue({
            rmgAboutRisk: rmgSlide.aboutRisk,
            rmgDescription: rmgSlide.description,
            rmgInclude: rmgSlide.included
          })
        }

        if (resp.dynamicSlide.serviceTeam) {
          let ystSlide = resp.dynamicSlide.serviceTeam;
          if (ystSlide.length > 0) {
            this.proposalForm.patchValue({
              ystInclude: ystSlide[0].included
            })
          }

          ystSlide.forEach((element: any, index: number) => {
            this.addServiceTeamControlWithData(this.proposalForm.controls['ystResouceList'], element);
          })
        }

        if (resp.dynamicSlide.subjectiveBilling) {
          let sbSlide = resp.dynamicSlide.subjectiveBilling;
          this.proposalForm.patchValue({
            sbDescription: sbSlide.description,
            sbInclude: sbSlide.included
          })
        }

        if (resp.dynamicSlide.summaryDifferences) {
          let summarySlide = resp.dynamicSlide.summaryDifferences;
          this.proposalForm.patchValue({
            summaryDifferences: summarySlide.differences,
            summaryServices: summarySlide.services,
            summaryInclude: summarySlide.included
          })
        }
        this.setApiPayload();
      }
    })
  }

  addServiceTeamControlWithData(controls: any, data: any) {
    if (data) {
      controls.push(
        this.createListForm(data)
      );
    }
  }

  createListForm(data: any) {
    return new UntypedFormGroup({
      resourceName: new UntypedFormControl(data.resourceName),
      resourceTitle: new UntypedFormControl(data.resourceTitle),
      sequence: new UntypedFormControl(data.sequence),
      included: new UntypedFormControl(data.included)
    });
  }

  getCoveragedata() {
    this._addWBAService.getCoverageAssessmentDetails(parseInt(this.wbaId)).subscribe((response: any) => {
      this.request = response || [];
      this.CoverageCheckList = this.request.coverageAssessmentDetail || [];
      this.CoverageCheckList.forEach((cov: any) => {
        cov.coverageParameters = cov.coverages.map((obj: any) => ({ ...obj, "highlights": false, "displayName": obj.parameterName, "selectRow": false }));
        cov.coverageParameters = cov.coverageParameters.filter((x: { valueType: string; }) => x.valueType !== "")

        // saved coverage of proposal
        let currentcoverageFromDB = this.coveragesFromDB.filter((x: { coverageName: any; }) => x.coverageName === cov.coverageName);
        if (currentcoverageFromDB && currentcoverageFromDB.length > 0) {
          let parametersDB = currentcoverageFromDB[0].coverageParameters;
          cov.coverageParameters.forEach((param: any) => {
            let currentParametersFromDB = parametersDB.find((x: { displayName: any; }) => x.displayName === param.displayName);
            if (currentParametersFromDB) {
              param.expiringValue = currentParametersFromDB.expiringValue;
              param.recommendedValue = currentParametersFromDB.recommendedValue;
              param.selectRow = currentParametersFromDB.selectRow ? currentParametersFromDB.selectRow : true;
              param.highlights = currentParametersFromDB.highlights;
            }
            else {
              if (param.valueType === "input-currency") {
                param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '$', '1.0-0');
                param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '$', '1.0-0');
              }
              else if (param.valueType === "input-number") {
                param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '', '1.0-0');
                param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '', '1.0-0');
              }
              else if (param.valueType === "input-date") {
                param.expiringValue = this.datepipe.transform(param.expiringValue, 'MM/dd/yyyy');
                param.recommendedValue = this.datepipe.transform(param.recommendedValue, 'MM/dd/yyyy');
              }
              else if (param.valueType === "input-zip") {
                param.expiringValue = this.maskPipe.transform(param.expiringValue, '00000-0000||00000');
                param.recommendedValue = this.maskPipe.transform(param.recommendedValue, '00000-0000||00000');
              }
              else if (param.valueType === "input-ein") {
                param.expiringValue = this.maskPipe.transform(param.expiringValue, '00-0000000');
                param.recommendedValue = this.maskPipe.transform(param.recommendedValue, '00-0000000');
              }
              else if (param.valueType === '' && param.groupName !== 'Price' && param.groupName
                === 'Carrier') {
                param.expiringValue = this.expiringCarrier;
              }
              else if (param.valueType === '' && param.groupName !== 'Price' && param.groupName
                === 'Additional Insureds') {
                param.expiringValue = this.prospectName;
              }
              else if (param.valueType === '' && param.groupName === 'Price') {
                param.expiringValue = this.expiringPremium;
              }
            }
          });
        }
        else {
          cov.coverageParameters.forEach((param: any) => {
            param.selectRow = true;
            if (param.valueType === "input-currency") {
              param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '$', '1.0-0');
              param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '$', '1.0-0');
            }
            else if (param.valueType === "input-number") {
              param.expiringValue = this._amountPipe.transform(param.expiringValue, 'USD', '', '1.0-0');
              param.recommendedValue = this._amountPipe.transform(param.recommendedValue, 'USD', '', '1.0-0');
            }
            else if (param.valueType === "input-date") {
              param.expiringValue = this.datepipe.transform(param.expiringValue, 'MM/dd/yyyy');
              param.recommendedValue = this.datepipe.transform(param.recommendedValue, 'MM/dd/yyyy');
            }
            else if (param.valueType === "input-zip") {
              param.expiringValue = this.maskPipe.transform(param.expiringValue, '00000-0000||00000');
              param.recommendedValue = this.maskPipe.transform(param.recommendedValue, '00000-0000||00000');
            }
            else if (param.valueType === "input-ein") {
              param.expiringValue = this.maskPipe.transform(param.expiringValue, '00-0000000');
              param.recommendedValue = this.maskPipe.transform(param.recommendedValue, '00-0000000');
            }
            else if (param.valueType === '' && param.groupName !== 'Price' && param.groupName
              === 'Carrier') {
              param.expiringValue = this.expiringCarrier;
            }
            else if (param.valueType === '' && param.groupName !== 'Price' && param.groupName
              === 'Additional Insureds') {
              param.expiringValue = this.prospectName;
            }
            else if (param.valueType === '' && param.groupName === 'Price') {
              param.expiringValue = this.expiringPremium;
            }
          });
        }
      });
      if (this.request.coverageAssessmentDetail) {
        this.request.coverageAssessmentDetail.forEach((element: any) => {
          this.arrayForMasterServiceList.push(element.coverageId)
        })
      }
    });
  }

  GetMasterCoverageServiceListByTierAndCoverage() {
    let data = {
      "coverageIdList": this.arrayForMasterServiceList,
      "tierId": this.tierId
    }
    this._addWBAService.GetMasterCoverageServiceListByTierAndCoverage(data).subscribe((response: any) => {
      this.masterCoverageServiceList = response;
      this.services = this.masterCoverageServiceList;

      this.request.wbaService.forEach((element: any) => {
        this.services.claimManagmentServiceList.forEach((serv: any) => {
          if (element.serviceId == serv.serviceId) {
            serv.isChecked = true
          }
        });
        this.services.riskManagmentServiceList.forEach((serv: any) => {
          if (element.serviceId == serv.serviceId) {
            serv.isChecked = true
          }
        });
      });
      this.claimServiceList = this.services.claimManagmentServiceList.filter((x: { isChecked: boolean; }) => x.isChecked == true)
      this.riskServiceList = this.services.riskManagmentServiceList.filter((x: { isChecked: boolean; }) => x.isChecked == true)
    });
  }

  //get user detail method
  getUserDetails(id: any) {
    if (id) {
      this._AcmeProductService.getUserById(id).subscribe((resp: any) => {
        this.userDetails = resp;
        this.presentedBy = `<p>${this.userDetails.user.given_name}</p><p>1100 Hector Street, Suite 470</p><p>Conshohocken, PA 19428</p><p>${this.userDetails.user.mobile}</p><p>${this.userDetails.user.email}</p>`;
        this.pi_description = this.pi_description.replace("@UserName", this.userDetails.user.given_name);
        this.proposalForm.patchValue({ coverPagepresentedBy: this.presentedBy, piDescription: this.pi_description });
      });
    }
  }

  // Get wba details for total premium summary slide
  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaDetails = resp.whiteBoardActivityInfo;
      this.wbaDetails.expiringPremium = this.wbaDetails.expiringPremium ? "$" + this._numberPipe.transform(this.wbaDetails.expiringPremium, 'USD', '1.0-0') : "$0";
      this.wbaDetails.targetPremium = this.wbaDetails.targetPremium ? "$" + this._numberPipe.transform(this.wbaDetails.targetPremium, 'USD', '1.0-0') : "$0";
      this.prospectName = this.wbaDetails.companyName;
      this.pi_description = this.pi_description.replace("@ProspectName", this.wbaDetails.companyName);
      this.proposalForm.patchValue({
        coverPageProspectName: this.wbaDetails.companyName,
        piDescription: this.pi_description,
        tpsBusinessLine: this.wbaDetails.masterBusinessLine,
        tpsExpiringPremium: this.wbaDetails.expiringPremium,
        tpsTargetPremium: this.wbaDetails.targetPremium
      });

    });
  }

  //to get wba carriers list for market summary slide
  getWbaProposalMarketSummaryListById(wbaId: any) {
    this._addWBAService.getWbaProposalMarketSummaryListById(wbaId).subscribe(resp => {
      this.marketSummaryList = resp.wbaProposalMarketSummaryList;
      this.marketSummaryList = this.marketSummaryList.map((obj: any) => ({ ...obj, "isChecked": false }));

      this.marketSummaryList.forEach((element: any) => {
        element.quotedPremium = element.quotedPremium ? "$" + this._numberPipe.transform(element.quotedPremium, 'USD', '1.0-0') : "$0";
      })
    })
  }

  setApiPayload() {
    let formValue = this.proposalForm.value;
    // Update values for coverages slide
    this.CoverageCheckList = this.CoverageCheckList.map((obj: any) => ({ ...obj, "sequence": 7, "included": formValue.coveragesInclude }));
    let checkList = JSON.parse(JSON.stringify(this.CoverageCheckList));
    checkList.forEach((cov: any, index: any) => {
      cov.coverageParameters = cov.coverageParameters.filter((x: { selectRow: boolean; }) => x.selectRow === true);
    })

    // Update values for market SummaryList slide
    this.marketSummaryList = this.marketSummaryList.map((obj: any) => ({
      ...obj, "sequence": 8,
      "included": formValue.msInclude,
      "carrier": obj.coverageName,
      "quotedPremium": obj.quotedPremium
    }));

    // Update values for your service team slide
    let serviceTeamList = formValue.ystResouceList;
    serviceTeamList = serviceTeamList.map((obj: any) => ({
      ...obj, "sequence": 11,
      "included": formValue.ystInclude
    }));

    this.apiPayload = {
      "wBAProposal": {
        "id": this.proposalId,
        "wbaId": this.wbaId,
        "PreviewSlideName": this.slideName,
        "Slidetype": this.slideType,
        "StaticSlide": [
          {
            "sequence": 3,
            "included": formValue.lspInclude,
            "SlideName": "Safeguard Protect.pdf"
          },
          {
            "sequence": 5,
            "included": formValue.mlcInclude,
            "SlideName": "MAP OF LAMB CLIENT.pdf"
          },
          {
            "sequence": 9,
            "included": formValue.abrInclude,
            "SlideName": "AM Best Rating.pdf"
          },
          {
            "sequence": 12,
            "included": formValue.cmpInclude,
            "SlideName": "Condition of Market Place.pdf"
          },
          {
            "sequence": 15,
            "included": formValue.disclaimerInclude,
            "SlideName": "Disclaimer.pdf"
          },
          {
            "sequence": 16,
            "included": formValue.picInclude,
            "SlideName": "Philadelphia Insurance Companies.pdf"
          },
          {
            "sequence": 17,
            "included": formValue.amTrustInclude,
            "SlideName": "Amtrust.pdf"
          },
          {
            "sequence": 18,
            "included": formValue.cmInclude,
            "SlideName": "Church Mutual.pdf"
          },
          {
            "sequence": 19,
            "included": formValue.goInclude,
            "SlideName": "GuideOne.pdf"
          },
          {
            "sequence": 20,
            "included": formValue.niaInclude,
            "SlideName": "Non Profits.pdf"
          },
          {
            "sequence": 21,
            "included": formValue.berkleyInclude,
            "SlideName": "Berkley.pdf"
          },
          {
            "sequence": 22,
            "included": formValue.hanoverInclude,
            "SlideName": "Hanover.pdf"
          },
          {
            "sequence": 23,
            "included": formValue.krInclude,
            "SlideName": "Key Risk.pdf"
          }
        ],
        "DynamicSlide": {
          "coverPage": {
            "sequence": 1,
            "included": formValue.coverPageInclude,
            "title": formValue.coverPageTitle,
            "prospectName": formValue.coverPageProspectName,
            "presentedBy": formValue.coverPagepresentedBy
          },
          "hummanServiceExpertise": {
            "sequence": 2,
            "included": formValue.hseInclude,
            "description": formValue.hseDescription
          },
          "proposalIntroduction": {
            "sequence": 4,
            "included": formValue.piInclude,
            "description": formValue.piDescription
          },
          "totalPremiumSummary": {
            "sequence": 6,
            "included": formValue.tpsInclude,
            "lob": this.wbaDetails ? this.wbaDetails.masterBusinessLine : "",
            "expiring": this.wbaDetails ? this.wbaDetails.expiringPremium : "",
            "target": this.wbaDetails ? this.wbaDetails.targetPremium.toString() : ""
          },
          "ProposalCoverage": checkList,//this.CoverageCheckList,
          "marketingSummary": this.marketSummaryList,
          "riskManagement": {
            "sequence": 10,
            "included": formValue.rmgInclude,
            "aboutRisk": formValue.rmgAboutRisk,
            "description": formValue.rmgDescription,
          },
          "serviceTeam": serviceTeamList,
          "SubjectiveBilling": {
            "sequence": 13,
            "included": formValue.sbInclude,
            "description": formValue.sbDescription
          },
          "summaryDifferences": {
            "sequence": 14,
            "included": formValue.summaryInclude,
            "differences": formValue.summaryDifferences,
            "services": formValue.summaryServices
          },
          "path": this.proposalPath,
          "proposalDoc": this.proposalDoc
        }
      }
    }
  }

  previewCurrentPage(content: any) {
    this.isSubmitted = true;
    if (this.proposalForm.invalid) {
      this.proposalForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      this.isDisable = false;
      return;
    }
    else {
      this.isDisable = true;
      this.setApiPayload();
      this.previewProposalReport(this.apiPayload, content);
    }
  }

  //to get Coverage Parameter Carrier Premium by wbaId and carrier Id
  previewProposalReport(data: any, content: any) {
    this._addWBAService.previewProposalReport(data).subscribe(resp => {
      if (resp) {
        let fileURL: any = URL.createObjectURL(resp);
        this.pdfSrc = fileURL;
        this.open(content);
      }
    })
  }

  open(content: any) {
    const modalRef = this.modalService.open(content, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialogPreview'
    });
  }

  downloadPreviewProposalReport() {
    this._addWBAService.previewProposalReport(this.apiPayload).subscribe(resp => {
      if (resp) {
        let fileURL: any = URL.createObjectURL(resp);
        let a = document.createElement("a");
        a.href = fileURL;
        a.target = '_blank';
        a.download = `${this.slideName}.pdf`;
        a.click();
      }
    })
  }

  downloadProposalReport() {
    this.isSubmitted = true;
    if (this.proposalForm.invalid) {
      this.proposalForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      this.isDisable = false;
      return;
    }
    else {
      this.isDisable = true;
      this.setApiPayload()
      this.generateProposalReport(this.apiPayload);
    }
  }

  //to get Coverage Parameter Carrier Premium by wbaId and carrier Id
  generateProposalReport(data: any) {
    this._addWBAService.generateProposalReport(data).subscribe(resp => {
      if (resp) {
        var fileURL: any = URL.createObjectURL(resp);
        var a = document.createElement("a");
        a.href = fileURL;
        a.target = '_blank';
        a.download = "Proposal Report.pdf";
        a.click();
        this.getWbaProposalById()
      }
    })
  }

  openSlide(slideData: any) {
    let formValue = this.proposalForm.value;
    this.currentSlideName = slideData.displayName

    this.proposalForm.get('coverPageTitle')?.setValidators(null);
    this.proposalForm.get('coverPageTitle')?.updateValueAndValidity();
    this.proposalForm.get('coverPageProspectName')?.setValidators(null);
    this.proposalForm.get('coverPageProspectName')?.updateValueAndValidity();
    this.proposalForm.get('coverPagepresentedBy')?.setValidators(null);
    this.proposalForm.get('coverPagepresentedBy')?.updateValueAndValidity();
    this.proposalForm.get('hseDescription')?.setValidators(null);
    this.proposalForm.get('hseDescription')?.updateValueAndValidity();
    this.proposalForm.get('piDescription')?.setValidators(null);
    this.proposalForm.get('piDescription')?.updateValueAndValidity();
    this.proposalForm.get('rmgAboutRisk')?.setValidators(null);
    this.proposalForm.get('rmgAboutRisk')?.updateValueAndValidity();
    this.proposalForm.get('rmgDescription')?.setValidators(null);
    this.proposalForm.get('rmgDescription')?.updateValueAndValidity();
    this.proposalForm.get('ystResouceName')?.setValidators(null);
    this.proposalForm.get('ystResouceName')?.updateValueAndValidity();
    this.proposalForm.get('sbDescription')?.setValidators(null);
    this.proposalForm.get('sbDescription')?.updateValueAndValidity();
    this.proposalForm.get('summaryDifferences')?.setValidators(null);
    this.proposalForm.get('summaryDifferences')?.updateValueAndValidity();
    this.proposalForm.get('summaryServices')?.setValidators(null);
    this.proposalForm.get('summaryServices')?.updateValueAndValidity();

    if (this.currentSlideName === 'Cover Page') {
      this.slideType = "Dynamic";
      this.slideName = "coverPage"

      let slectedslide = this.MasterCoverageListNew.find((x: any) => x.displayName === this.currentSlideName);
      if (slectedslide) {
        slectedslide.isInclude = formValue.coverPageInclude;
      }

      this.proposalForm.get('coverPageTitle')?.setValidators([Validators.required]);
      this.proposalForm.get('coverPageTitle')?.updateValueAndValidity();
      this.proposalForm.get('coverPageProspectName')?.setValidators([Validators.required]);
      this.proposalForm.get('coverPageProspectName')?.updateValueAndValidity();
      this.proposalForm.get('coverPagepresentedBy')?.setValidators([Validators.required]);
      this.proposalForm.get('coverPagepresentedBy')?.updateValueAndValidity();

    }
    else if (this.currentSlideName === 'Human Service Expertise') {
      this.slideType = "Dynamic";
      this.slideName = "hummanServiceExpertise"

      let slectedslide = this.MasterCoverageListNew.find((x: any) => x.displayName === this.currentSlideName);
      if (slectedslide) {
        slectedslide.isInclude = formValue.hseInclude;
      }

      this.proposalForm.get('hseDescription')?.setValidators([Validators.required]);
      this.proposalForm.get('hseDescription')?.updateValueAndValidity();
    }
    else if (this.currentSlideName === 'Lamb Safeguard Protect') {
      this.slideType = "Static";
      this.slideName = "Safeguard Protect"

      let slectedslide = this.MasterCoverageListNew.find((x: any) => x.displayName === this.currentSlideName);
      if (slectedslide) {
        slectedslide.isInclude = formValue.lspInclude;
      }
    }
    else if (this.currentSlideName === 'Proposal Introduction') {
      this.slideType = "Dynamic";
      this.slideName = "ProposalIntroduction"

      this.proposalForm.get('piDescription')?.setValidators([Validators.required]);
      this.proposalForm.get('piDescription')?.updateValueAndValidity();
    }
    else if (this.currentSlideName === 'Map of Lamb Client') {
      this.slideType = "Static";
      this.slideName = "MAP OF LAMB CLIENT"
    }
    else if (this.currentSlideName === 'Total Premium Summary') {
      this.slideType = "Dynamic";
      this.slideName = "TotalPremiumSummary"
    }
    else if (this.currentSlideName === 'Coverages') {
      this.slideType = "Dynamic";
      this.slideName = "ProposalCoverage"
      this.handleCoverageCheckbox('both');
    }
    else if (this.currentSlideName === 'Marketing Summary') {
      this.slideType = "Dynamic";
      this.slideName = "marketingSummary"
    }
    else if (this.currentSlideName === 'Am Best Rating') {
      this.slideType = "Static";
      this.slideName = "AM Best Rating"
    }
    else if (this.currentSlideName === 'Risk Management Glossary') {
      this.GetMasterCoverageServiceListByTierAndCoverage();
      this.slideType = "Dynamic";
      this.slideName = "riskManagement"

      this.proposalForm.get('rmgAboutRisk')?.setValidators([Validators.required]);
      this.proposalForm.get('rmgAboutRisk')?.updateValueAndValidity();
      this.proposalForm.get('rmgDescription')?.setValidators([Validators.required]);
      this.proposalForm.get('rmgDescription')?.updateValueAndValidity();
    }
    else if (this.currentSlideName === 'Your Service Team') {
      this.slideType = "Dynamic";
      this.slideName = "serviceTeam"

      this.proposalForm.get('ystResouceName')?.setValidators([Validators.required]);
      this.proposalForm.get('ystResouceName')?.updateValueAndValidity();
    }
    else if (this.currentSlideName === 'Condition of Market Place') {
      this.slideType = "Static";
      this.slideName = "Condition of Market Place"
    }
    else if (this.currentSlideName === 'Subjectivities and Billing') {
      this.slideType = "Dynamic";
      this.slideName = "SubjectiveBilling"

      this.proposalForm.get('sbDescription')?.setValidators([Validators.required]);
      this.proposalForm.get('sbDescription')?.updateValueAndValidity();
    }
    else if (this.currentSlideName === 'Summary') {
      this.slideType = "Dynamic";
      this.slideName = "summaryDifferences"

      this.proposalForm.get('summaryDifferences')?.setValidators([Validators.required]);
      this.proposalForm.get('summaryDifferences')?.updateValueAndValidity();
      this.proposalForm.get('summaryServices')?.setValidators([Validators.required]);
      this.proposalForm.get('summaryServices')?.updateValueAndValidity();
    }
    else if (this.currentSlideName === 'Disclaimer') {
      this.slideType = "Static";
      this.slideName = "Disclaimer"
    }
    else if (this.currentSlideName === 'Philadelphia Insurance Companies') {
      this.slideType = "Static";
      this.slideName = "Philadelphia Insurance Companies"
    }
    else if (this.currentSlideName === 'Amtrust') {
      this.slideType = "Static";
      this.slideName = "Amtrust"
    }
    else if (this.currentSlideName === 'Church Mutual') {
      this.slideType = "Static";
      this.slideName = "Church Mutual"
    }
    else if (this.currentSlideName === 'Guide One') {
      this.slideType = "Static";
      this.slideName = "Guideone"
    }
    else if (this.currentSlideName === 'Non-Profits Insurance Alliance') {
      this.slideType = "Static";
      this.slideName = "Non Profits"
    }
    else if (this.currentSlideName === 'Berkley') {
      this.slideType = "Static";
      this.slideName = "Berkley"
    }
    else if (this.currentSlideName === 'Hanover') {
      this.slideType = "Static";
      this.slideName = "Hanover"
    }
    else if (this.currentSlideName === 'Key Risk') {
      this.slideType = "Static";
      this.slideName = "Key Risk"
    }
  }

  openNextSlide() {
    if (this.currentSlideName === 'Cover Page') {
      this.openSlide({ "id": "Human Service Expertise", "displayName": "Human Service Expertise" });
    }
    else if (this.currentSlideName === 'Human Service Expertise') {
      this.openSlide({ "id": "Lamb Safeguard Protect", "displayName": "Lamb Safeguard Protect" });
    }
    else if (this.currentSlideName === 'Lamb Safeguard Protect') {
      this.openSlide({ "id": "Proposal Introduction", "displayName": "Proposal Introduction" });
    }
    else if (this.currentSlideName === 'Proposal Introduction') {
      this.openSlide({ "id": "Map of Lamb Client", "displayName": "Map of Lamb Client" });
    }
    else if (this.currentSlideName === 'Map of Lamb Client') {
      this.openSlide({ "id": "Total Premium Summary", "displayName": "Total Premium Summary" });
    }
    else if (this.currentSlideName === 'Total Premium Summary') {
      this.openSlide({ "id": "Coverages", "displayName": "Coverages" });
    }
    else if (this.currentSlideName === 'Coverages') {
      this.openSlide({ "id": "Marketing Summary", "displayName": "Marketing Summary" });
    }
    else if (this.currentSlideName === 'Marketing Summary') {
      this.openSlide({ "id": "Am Best Rating", "displayName": "Am Best Rating" });
    }
    else if (this.currentSlideName === 'Am Best Rating') {
      this.openSlide({ "id": "Risk Management Glossary", "displayName": "Risk Management Glossary" });
    }
    else if (this.currentSlideName === 'Risk Management Glossary') {
      this.openSlide({ "id": "Your Service Team", "displayName": "Your Service Team" });
    }
    else if (this.currentSlideName === 'Your Service Team') {
      this.openSlide({ "id": "Condition of Market Place", "displayName": "Condition of Market Place" });
    }
    else if (this.currentSlideName === 'Condition of Market Place') {
      this.openSlide({ "id": "Subjectivities and Billing", "displayName": "Subjectivities and Billing" });
    }
    else if (this.currentSlideName === 'Subjectivities and Billing') {
      this.openSlide({ "id": "Summary", "displayName": "Summary" });
    }
    else if (this.currentSlideName === 'Summary') {
      this.openSlide({ "id": "Disclaimer", "displayName": "Disclaimer" });
    }
    else if (this.currentSlideName === 'Disclaimer') {
      this.openSlide({ "id": "Philadelphia Insurance Companies", "displayName": "Philadelphia Insurance Companies" });
    }
    else if (this.currentSlideName === 'Philadelphia Insurance Companies') {
      this.openSlide({ "id": "Amtrust", "displayName": "Amtrust" });
    }
    else if (this.currentSlideName === 'Amtrust') {
      this.openSlide({ "id": "Church Mutual", "displayName": "Church Mutual" });
    }
    else if (this.currentSlideName === 'Church Mutual') {
      this.openSlide({ "id": "Guide One", "displayName": "Guide One" });
    }
    else if (this.currentSlideName === 'Guide One') {
      this.openSlide({ "id": "Non-Profits Insurance Alliance", "displayName": "Non-Profits Insurance Alliance" });
    }
    else if (this.currentSlideName === 'Non-Profits Insurance Alliance') {
      this.openSlide({ "id": "Berkley", "displayName": "Berkley" });
    }
    else if (this.currentSlideName === 'Berkley') {
      this.openSlide({ "id": "Hanover", "displayName": "Hanover" });
    }
    else if (this.currentSlideName === 'Hanover') {
      this.openSlide({ "id": "Key Risk", "displayName": "Key Risk" });
    }
  }

  getGroupName(coverage: any, index: number) {
    let currentIndexName = coverage.coverageParameters[index].parameterCategory;

    if (index === 0) return currentIndexName;
    else {
      let lastIndexName = coverage.coverageParameters[index - 1].parameterCategory;

      if (lastIndexName === currentIndexName) return '';
      else return currentIndexName;
    }
  }

  handleCoverageHighlight(param: any) {
    param.highlights = !param.highlights;
    this.handleCoverageCheckbox('highlights');
  }

  saveProposal(action: string) {
    if (action === 'download') {
      this.downloadProposalReport();
    }
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    if (firstInvalidControl)
      firstInvalidControl.focus(); //without smooth behavior
  }

  //to get all form controls
  get f() {
    return this.proposalForm.controls;
  }

  onIncludeChange(slideName: string, controlValue: any) {
    let object = this.MasterCoverageListNew.find((x: { displayName: string; }) => x.displayName === slideName);
    object.isInclude = !controlValue;
  }

  onCoverPageTitleChange() {
    if (this.proposalId === 0) {
      this.riskServiceLabel = this.riskServiceLabelOriginal.replace("@coverPageTitle", this.proposalForm.value.coverPageTitle);
    }
  }

  onCoverPageProposalChange() {
    if (this.proposalId === 0) {
      this.pi_description = this.pi_descriptionOriginal.replace("@UserName", this.userDetails.user.given_name);
      this.pi_description = this.pi_descriptionOriginal.replace("@ProspectName", this.proposalForm.value.coverPageProspectName);
      this.proposalForm.patchValue({ piDescription: this.pi_description });
    }
  }

  addServiceTeamFormGroup() {
    (this.proposalForm.get(['ystResouceList']) as UntypedFormArray).push(
      this.fb.group({
        resourceName: [''],
        resourceTitle: [''],
      })
    );
  }

  onCoverageHighlightAllSelect(event: any, cov: any, index: any) {
    let value = event.target.checked;
    if (value) {
      let _selectEleContact = (document.getElementById(cov.coverageName + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = true
      }

      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "highlights": true }));
    } else {
      let _selectEleContact = (document.getElementById(cov.coverageName + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = false
      }
      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "highlights": false }));
    }
  }

  onCoverageSelectAllSelect(event: any, cov: any, index: any) {
    let value = event.target.checked;
    if (value) {
      let _selectEleContact = (document.getElementById(cov.coverageName + 'SelectRow' + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = true
      }

      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "selectRow": true }));
    } else {
      let _selectEleContact = (document.getElementById(cov.coverageName + 'SelectRow' + index) as HTMLInputElement);
      if (_selectEleContact) {
        _selectEleContact.checked = false
      }
      cov.coverageParameters = cov.coverageParameters.map((obj: any) => ({ ...obj, "selectRow": false }));
    }
  }

  handleCoverageSelectRow(param: any) {
    param.selectRow = !param.selectRow;
    this.handleCoverageCheckbox('selectRow');
  }

  handleCoverageCheckbox(value: any) {
    this.totalSelectLength = 0;
    this.totalParameterSelectLength = 0;
    this.totalHighlightLength = 0;
    this.totalParameterHighlightLength = 0;
    setTimeout(() => {
      this.CoverageCheckList.forEach((cov: any, index: any) => {
        if (value === 'selectRow') {
          this.totalSelectLength = cov.coverageParameters.filter((x: { selectRow: boolean; }) => x.selectRow === true).length;
          this.totalParameterSelectLength = cov.coverageParameters.length;
          if (this.totalParameterSelectLength > 0 && this.totalSelectLength == this.totalParameterSelectLength) {
            let covName = cov.coverageName + 'SelectRow' + index.toString();
            let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleSelectRow) {
              _selectEleSelectRow.checked = true
            }
          }
          else {
            let covName = cov.coverageName + 'SelectRow' + index.toString();
            let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleSelectRow) {
              _selectEleSelectRow.checked = false
            }
          }
        } else if (value === 'highlights') {
          this.totalHighlightLength = cov.coverageParameters.filter((x: { highlights: boolean; }) => x.highlights === true).length;
          this.totalParameterHighlightLength = cov.coverageParameters.length;

          if (this.totalParameterHighlightLength > 0 && this.totalHighlightLength == this.totalParameterHighlightLength) {
            let covName = cov.coverageName + index.toString()
            let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleHighlight) {
              _selectEleHighlight.checked = true
            }
          }
          else {
            let covName = cov.coverageName + index.toString()
            let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleHighlight) {
              _selectEleHighlight.checked = false
            }
          }
        } else if (value === 'both') {
          // SelectRow
          this.totalSelectLength = cov.coverageParameters.filter((x: { selectRow: boolean; }) => x.selectRow === true).length;
          this.totalParameterSelectLength = cov.coverageParameters.length;
          if (this.totalParameterSelectLength > 0 && this.totalSelectLength == this.totalParameterSelectLength) {
            let covName = cov.coverageName + 'SelectRow' + index.toString();
            let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleSelectRow) {
              _selectEleSelectRow.checked = true
            }
          }
          else {
            let covName = cov.coverageName + 'SelectRow' + index.toString();
            let _selectEleSelectRow = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleSelectRow) {
              _selectEleSelectRow.checked = false
            }
          }

          // highlights

          this.totalHighlightLength = cov.coverageParameters.filter((x: { highlights: boolean; }) => x.highlights === true).length;
          this.totalParameterHighlightLength = cov.coverageParameters.length;

          if (this.totalParameterHighlightLength > 0 && this.totalHighlightLength == this.totalParameterHighlightLength) {
            let covName = cov.coverageName + index.toString()
            let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleHighlight) {
              _selectEleHighlight.checked = true
            }
          }
          else {
            let covName = cov.coverageName + index.toString()
            let _selectEleHighlight = (document.getElementById(covName.toString()) as HTMLInputElement);
            if (_selectEleHighlight) {
              _selectEleHighlight.checked = false
            }
          }
        }

      })
    })
  }

}
