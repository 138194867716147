import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-proposal-summary-notes',
  templateUrl: './proposal-summary-notes.component.html',
  styleUrls: ['./proposal-summary-notes.component.css']
})
export class ProposalSummaryNotesComponent implements OnInit {

  @Input() summaryData: any;
  notesForm: any;

  constructor(private activeModal: NgbActiveModal, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    console.log(this.summaryData);
    this.getFormControls();
    this.setFormValue();
  }

  cancel() {
    this.activeModal.close('');
  }

  getFormControls() {
    this.notesForm = this.fb.group({
      comments: ['']
    })
  }

  setFormValue() {
    let notes = this.summaryData.notes;
    // if (typeof (this.summaryData.notes) == 'object') notes = notes.join(". ")
    this.notesForm.patchValue({
      comments: notes
    })
  }

  saveNotes() {
    console.log(this.notesForm.value.comments);
    this.activeModal.close(this.notesForm.value.comments);
  }

}
