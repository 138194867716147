import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { environment } from 'src/environments/environment';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.css']
})
export class AuditReportComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter<any>();
  public _navigationPath: NavigationPath = new NavigationPath();
  isAssess: boolean = true;
  CoverageAssessmentData: any;
  isCoverage: boolean = false;
  _confirmBox: any;
  CoverageCheckList: any = [];
  request: any = [];
  coverageAssessmentIDList: any;
  WBADetailedData: any;
  groupResult: any[] = [];
  finalGroupResult: any[] = [];
  public wbaId: any = sessionStorage.getItem(environment.storageVariables.wbaId) || "0";
  SaveRequest: any;
  clickedMoreCoverageName: string = "";
  coverageCurrentId: any = 0;
  WBASaveAssessmentReq: any;
  missingCoverage: any[] = [];
  showHideParametersValidationPopUp: boolean = false;
  arrayForRequiredExpsoureType: any;
  convertArrayToStringDisplayName: any;
  showRequiredExposureTypePopUp: boolean = false;
  ratingBasisMessage: string = "";

  constructor(
    public _activeModal: NgbActiveModal,
    private _addWBAService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private _router: Router,
    private modalService: NgbModal,
    public _amountPipe: CustomeAmountPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe

  ) {
    this._confirmBox = new ConfirmBox();
    this.CoverageCheckList = sessionStorage.getItem(environment.storageVariables.CoverageCheckListStorage);
    this.CoverageCheckList = JSON.parse(this.CoverageCheckList);

  }
  getWBADetailsForTitleBar() {
    this._notifierService.getWBADetailsValue().subscribe((res: any) => {
      this.WBADetailedData = res;
      //adding currency format
      if (res.expiringPremium > 0)
        this.WBADetailedData.expiringPremium = this._amountPipe.transform(res.expiringPremium, 'USD', '', '1.0-0');
    })
  }
  getWBASaveAssessmentReq() {
    this._notifierService.getWBASaveAssessmentReq().subscribe((res: any) => {
      this.WBASaveAssessmentReq = res;
      res?.coverageAssessmentDetail
        .forEach((cove: any) => {
          if (cove?.coverageName == 'General Liability' || cove?.coverageName == 'Cyber') {
            this.CoverageCheckList.forEach((x: any) => {
              if (x?.displayName == 'GL Rating Basis' || x?.displayName == 'Cyber Rating Basis') {
                x?.ratingBasis.push(...cove?.ratingBasis)
              }
            })
          }
        })
    })
    //updating expringValue and recommendedValue in local storage to display on page
    //these are not save in assessment yet
    this.WBASaveAssessmentReq.coverageAssessmentDetail.forEach((apiResponse: any) => {
      let covFromLocal = this.CoverageCheckList.find((x: any) => x.displayName == apiResponse.coverageName)
      apiResponse.coverages.forEach((apiCovParam: any) => {
        let paramsFromLocal = covFromLocal.coverageParameters.find((x: any) => x.displayName == apiCovParam.parameterName)
        if (paramsFromLocal) {
          paramsFromLocal.expiringValue = apiCovParam.expiringValue
          paramsFromLocal.recommendedValue = apiCovParam.recommendedValue
          if (paramsFromLocal.displayName == 'Liability - Auto Symbol') {
            if (typeof paramsFromLocal.expiringValue === "string") {
              paramsFromLocal.expiringValue = paramsFromLocal.expiringValue.split(',')
            }

            if (typeof paramsFromLocal.expiringValue !== "string") {
              let paramsFromLocalCopy = JSON.parse(JSON.stringify(paramsFromLocal));
              let indexof10Object = paramsFromLocalCopy.expiringValue.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
              let indexof10Index = paramsFromLocalCopy.expiringValue.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
              if (indexof10Index > -1) {
                paramsFromLocalCopy.expiringValue.splice(indexof10Index, 1)
              }
              paramsFromLocalCopy.expiringValue = paramsFromLocalCopy.expiringValue.sort().join(',');
              if (indexof10Index > -1) {
                paramsFromLocalCopy.expiringValue = paramsFromLocalCopy.expiringValue + ',' + indexof10Object
              }
              paramsFromLocal.expiringValue = paramsFromLocalCopy.expiringValue
            }

            if (typeof paramsFromLocal.recommendedValue === "string") {
              paramsFromLocal.recommendedValue = paramsFromLocal.recommendedValue.split(',')
            }

            if (typeof paramsFromLocal.recommendedValue !== "string") {
              let paramsFromLocalCopy = JSON.parse(JSON.stringify(paramsFromLocal));
              let indexof10Object = paramsFromLocalCopy.recommendedValue.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
              let indexof10Index = paramsFromLocalCopy.recommendedValue.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
              if (indexof10Index > -1) {
                paramsFromLocalCopy.recommendedValue.splice(indexof10Index, 1)
              }
              paramsFromLocalCopy.recommendedValue = paramsFromLocalCopy.recommendedValue.sort().join(',');
              if (indexof10Index > -1) {
                paramsFromLocalCopy.recommendedValue = paramsFromLocalCopy.recommendedValue + ',' + indexof10Object
              }

              paramsFromLocal.recommendedValue = paramsFromLocalCopy.recommendedValue
            }

            if (paramsFromLocal.expiringValue.charAt(0) == ',') {
              paramsFromLocal.expiringValue = paramsFromLocal.expiringValue.substring(1)
            }
            if (paramsFromLocal.recommendedValue.charAt(0) == ',') {
              paramsFromLocal.recommendedValue = paramsFromLocal.recommendedValue.substring(1)
            }
            paramsFromLocal.expiringValue = paramsFromLocal.expiringValue.replace(/,/g, "</br>");
            paramsFromLocal.recommendedValue = paramsFromLocal.recommendedValue.replace(/,/g, "</br>");
          }
        }
      })
    })
  }

  setDefaultCoveragesExpiring() {
    this.CoverageCheckList.forEach((covg: any) => {
      if (covg.coverageParameters) {
        covg.coverageParameters.forEach((obj: any) => {
          if (obj.displayName == 'Carrier (Issuing Company)') {
            obj.expiringValue = this.WBADetailedData?.expiringName
          }
          if (obj.displayName == 'Premium') {
            var removeDollarPremium = this._removeAmountPipe.transform(obj.expiringValue);
            obj.expiringValue = this._amountPipe.transform(parseInt(removeDollarPremium), 'USD', '', '1.0-0');
          }
          if (obj.displayName == 'Named Insured') {
            obj.expiringValue = this.WBADetailedData?.companyName
          }
          if (obj.valueType === 'input-currency') {
            var removeDollarPremiumExp = this._removeAmountPipe.transform(obj.expiringValue);
            obj.expiringValue = this._amountPipe.transform(parseInt(removeDollarPremiumExp), 'USD', '', '1.0-0');
            var removeDollarPremiumRec = this._removeAmountPipe.transform(obj.recommendedValue);
            obj.recommendedValue = this._amountPipe.transform(parseInt(removeDollarPremiumRec), 'USD', '', '1.0-0')
          }
        });
      }
    });

    // local storage set again for above keys get updated
    sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList));

  }


  ngOnInit(): void {
    this.getCoveragedata();
    this.getWBADetailsForTitleBar();
    this.getWBASaveAssessmentReq();
    this.GetAssessmentIdByCoverageParameterIdAndValue();
    this.CoverageCheckList.forEach((item: any) => {
      item.isChecked = false;
    })
    this.CoverageCheckList[0].isChecked = true;
    //setting first coverage as opened
    this.clickedMoreCoverageName = this.CoverageCheckList[0].displayName;
    this.CoverageCheckList[0].showLessButton = true;
    this.setDefaultCoveragesExpiring()
  }

  //getting assessmentId by parameterId and value
  GetAssessmentIdByCoverageParameterIdAndValue() {
    let request: any[] = []
    //making api request
    this.CoverageCheckList.forEach((cov: any) => {
      cov.showLessButton = false;
      if (cov.coverageParameters) {
        cov.coverageParameters.forEach((element: any) => {
          let data = {
            parameterId: element.id,
            expiringValue: element.expiringValue,
            recommendedValue: element.recommendedValue
          }
          request.push(data);
        });
      }
    })

    //setting first coverage as opened
    this.clickedMoreCoverageName = this.CoverageCheckList[0].displayName;
    this.CoverageCheckList[0].showLessButton = true;
    this.coverageCurrentId = this.CoverageCheckList[0].id;

    this._addWBAService.GetAssessmentIdByCoverageParameterIdAndValue(request).subscribe((response: any) => {
      if (response) {
        this.coverageAssessmentIDList = response.coverageParameterAssessmentList;
        //updating assessment id in existing storage
        this.coverageAssessmentIDList.forEach((cov: any) => {
          this.CoverageCheckList.forEach((element: any) => {
            if (element.coverageParameters) {
              element.coverageParameters.forEach((param: any) => {
                if (param.id === cov.parameterId) {
                  param.expiringAssessmentId = cov.expiringAssessmentId;
                  param.expiringConditionDescription = cov.expiringDescription;
                  param.recommendedAssessmentId = cov.recommendedAssessmentId;
                  param.recommendedConditionDescription = cov.recommendedDescription;
                }
              });
            }
          });

        })
        sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList));

        this.CoverageCheckList.forEach((item: any) => {
          if (item.coverageParameters) {
            item.expringAssess0 = item.coverageParameters.filter((x: any) => x.expiringAssessmentId == 0).length;
            item.expringAssess1 = item.coverageParameters.filter((x: any) => x.expiringAssessmentId == 1).length;
            item.expringAssess2 = item.coverageParameters.filter((x: any) => x.expiringAssessmentId == 2).length;
            item.expringAssess3 = item.coverageParameters.filter((x: any) => x.expiringAssessmentId == 3).length;
            item.expringAssess4 = item.coverageParameters.filter((x: any) => x.expiringAssessmentId == 4).length;
            item.recommendedAssess0 = item.coverageParameters.filter((x: any) => x.recommendedAssessmentId == 0).length;
            item.recommendedAssess1 = item.coverageParameters.filter((x: any) => x.recommendedAssessmentId == 1).length;
            item.recommendedAssess2 = item.coverageParameters.filter((x: any) => x.recommendedAssessmentId == 2).length;
            item.recommendedAssess3 = item.coverageParameters.filter((x: any) => x.recommendedAssessmentId == 3).length;
            item.recommendedAssess4 = item.coverageParameters.filter((x: any) => x.recommendedAssessmentId == 4).length;
          }
        })

        this.CoverageCheckList.forEach((element: any) => {
          var groups = new Set(element.coverageParameters?.map((item: any) => item.parameterCategory));
          this.groupResult = [];
          groups.forEach(g =>
            this.groupResult.push({
              covName: element.displayName,
              groupName: g,
              groupParameters: element.coverageParameters.filter((i: any) => i.parameterCategory === g)
            }
            ))
          this.finalGroupResult.push(this.groupResult)
        });
      }
    })
  }


  getGroupName(coverage: any, index: number) {
    let currentIndexName = coverage.coverageParameters[index].parameterCategory;
    if (index === 0) return currentIndexName;
    else {
      let lastIndexName = coverage.coverageParameters[index - 1].parameterCategory;
      if (lastIndexName === currentIndexName) return '';
      else return currentIndexName;
    }
  }

  showM(val: any, item: any) {
    this.clickedMoreCoverageName = item.displayName;
    let currentValue = item.showLessButton

    this.CoverageCheckList.forEach((cov: any) => {
      if (cov.id === item.id) {
        cov.showLessButton = !cov.showLessButton
      }
      else {
        if (this.coverageCurrentId != 0 && this.coverageCurrentId !== item.id) {
          cov.showLessButton = currentValue
        }
      }
    })

    this.coverageCurrentId = item.id;
    this.isAssess = !val;
  }

  closeModal() {
    this._activeModal.close();
  }

  gotoSelectedCoverage(lob: any, i: any) {
    document.getElementById(lob)?.scrollIntoView({ behavior: "smooth" });
    this.CoverageCheckList.forEach((item: any) => {
      item.isChecked = false
    })
    this.CoverageCheckList[i].isChecked = true;

  }

  saveCoverageAssessmentOnAccept(action: string) {
    // if (this.checkRequiredExpiringCoverageHasValues()) {
    if (this.checkRequiredRecommendedCoverageHasValues()) {
      if (this.checkGLRatingBasisHasRequiredValues()) {
        //updating assessment id in existing storage
        this.coverageAssessmentIDList.forEach((cov: any) => {
          this.WBASaveAssessmentReq.coverageAssessmentDetail.forEach((element: any) => {
            element.coverages.forEach((param: any) => {
              if (param.coverageParameterId === cov.parameterId) {
                param.expiringAssessmentId = cov.expiringAssessmentId;
                param.expiringConditionDescription = cov.expiringDescription;
                param.recommendedAssessmentId = cov.recommendedAssessmentId;
                param.recommendedConditionDescription = cov.recommendedDescription;

                if (param.parameterName == 'Liability - Auto Symbol') {
                  if (typeof param.expiringValue === "string") {
                    param.expiringValue = param.expiringValue.split(',')
                  }

                  if (typeof param.expiringValue !== "string") {
                    let paramsFromLocalCopy = JSON.parse(JSON.stringify(param.expiringValue));
                    let indexof10Object = paramsFromLocalCopy.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                    let indexof10Index = paramsFromLocalCopy.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                    if (indexof10Index > -1) {
                      paramsFromLocalCopy.splice(indexof10Index, 1)
                    }
                    paramsFromLocalCopy = paramsFromLocalCopy.sort().join(',');

                    if (indexof10Index > -1) {
                      paramsFromLocalCopy = paramsFromLocalCopy + ',' + indexof10Object
                    }

                    param.expiringValue = paramsFromLocalCopy

                    if (typeof param.recommendedValue === "string") {
                      param.recommendedValue = param.recommendedValue.split(',')
                    }

                    if (typeof param.recommendedValue !== "string") {
                      let paramsFromLocalRecommended = JSON.parse(JSON.stringify(param.recommendedValue));
                      let indexof10ObjectRecommended = paramsFromLocalRecommended.find((x: any) => x == '10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                      let indexof10IndexRecommended = paramsFromLocalRecommended.indexOf('10 - Mobile Equipment Subject To Compulsory Or Financial Responsibility Or Other Motor Vehicle Insurance Law Only')
                      if (indexof10IndexRecommended > -1) {
                        paramsFromLocalRecommended.splice(indexof10IndexRecommended, 1)
                      }
                      paramsFromLocalRecommended = paramsFromLocalRecommended.sort().join(',');
                      if (indexof10IndexRecommended > -1) {
                        paramsFromLocalRecommended = paramsFromLocalRecommended + ',' + indexof10ObjectRecommended
                      }

                      param.recommendedValue = paramsFromLocalRecommended
                    }
                    if (param.recommendedValue.charAt(0) == ',') {
                      param.recommendedValue = param.recommendedValue.substring(1)
                    }
                    if (param.expiringValue.charAt(0) == ',') {
                      param.expiringValue = param.expiringValue.substring(1)
                    }
                  }
                }
              }
            });
          });
        })

        //approving audit run
        this.WBASaveAssessmentReq.isAuditRun = true;
        this._addWBAService.saveCoverageAssessmentForWBA(this.WBASaveAssessmentReq).subscribe((response: any) => {
          this.passEntry.emit(this.WBASaveAssessmentReq);
          this._notifierService._isAuditRun.next(true);
          //updating local storage            
          sessionStorage.setItem(environment.storageVariables.CoverageCheckListStorage, JSON.stringify(this.CoverageCheckList));
          if (action === 'Accept') {
            this._activeModal.close('Accept');
          }
          else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
        });
      }
    } else {
      this.showHideParametersValidationPopUp = true;
    }
    // }
    // else {
    //   this.showHideParametersValidationPopUp = true;
    // }
  }

  checkRequiredExpiringCoverageHasValues() {
    let result: boolean = true
    this.missingCoverage = []
    for (const element of this.CoverageCheckList) {
      if (element.coverageParameters) {
        let data = element.coverageParameters.find((cov: any) => cov.displayName === "Carrier (Issuing Company)");

        if (!data || data.expiringValue == "")
          return result;
        for (const param of element.coverageParameters) {
          if (param.displayName == "Carrier (Issuing Company)") {
            if (param.expiringValue == '')
              break;
          }
          if (param.isRequired && (param.expiringValue == "" || param.expiringValue == "undefined")) {
            this.missingCoverage.push(element.displayName)
            result = false
            break;
          }
        }
      }
    }
    return result;
  }

  checkRequiredRecommendedCoverageHasValues() {
    let result: boolean = true
    this.missingCoverage = []
    for (const element of this.CoverageCheckList) {
      if (element.coverageParameters) {
        for (const param of element.coverageParameters) {
          if (param.displayName != "Named Insured") {
            if (param.isRequired && (param.recommendedValue == "" || param.recommendedValue == "undefined")) {
              this.missingCoverage.push(element.displayName)
              result = false
              break;
            }
          }
        }
      }
    }
    return result;
  }

  // checkGLRatingBasisHasRequiredValues() {
  //   let result: boolean = true
  //   let finding_GL = this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis');

  //   if (finding_GL) {
  //     let countForExposureRequiredInRequest: number = 0;
  //     let count_requiredExposureType = finding_GL.coverageExposures.filter((x: any) => x.isRequired).length
  //     let finding_GL_fromRequest = this.request.coverageAssessmentDetail.filter((x: any) => x.coverageName == 'General Liability')
  //     finding_GL.coverageExposures.forEach((storageObj: any) => {
  //       finding_GL_fromRequest[0].ratingBasis.forEach((reqObject: any) => {
  //         if ((storageObj.id == reqObject.exposureId) && storageObj.isRequired) {
  //           countForExposureRequiredInRequest++;
  //         }
  //       });
  //     });
  //     if (count_requiredExposureType != countForExposureRequiredInRequest) {
  //       //getting required exposure types
  //       this.arrayForRequiredExpsoureType = finding_GL.coverageExposures.filter((x: any) => x.isRequired)
  //       let findDisplayNameFilter = this.arrayForRequiredExpsoureType.map((obj: any) => obj.displayName)//getting display names only
  //       this.convertArrayToStringDisplayName = findDisplayNameFilter.join(', ')
  //       this.showRequiredExposureTypePopUp = true;
  //       result = false
  //     }
  //   }
  //   return result;
  // }

  checkGLRatingBasisHasRequiredValues() {
    let result: boolean = true;
    let finding_GLOrCyber = this.CoverageCheckList.find((x: any) => x.displayName == 'GL Rating Basis' || x.displayName == 'Cyber Rating Basis');
    if (finding_GLOrCyber?.displayName == "GL Rating Basis" && finding_GLOrCyber?.ratingBasis?.length == 0) {
      this.ratingBasisMessage = "At least one GL Rating Basis is mandatory."
    }
    if (finding_GLOrCyber?.displayName == "Cyber Rating Basis" && finding_GLOrCyber?.ratingBasis?.length == 0) {
      this.ratingBasisMessage = "At least one Cyber Rating Basis is mandatory."
    }
    if (finding_GLOrCyber?.ratingBasis?.length == 0) {
      this.showRequiredExposureTypePopUp = true;
      result = false
    }
    return result;
  }

  getCoveragedata() {
    this._addWBAService.getCoverageAssessmentDetails(parseInt(this.wbaId)).subscribe((response: any) => {
      this.request = response;
    });
  }

  ngAfterViewInit(): void {
    this.replaceBrTagByComma()
  }

  replaceBrTagByComma() {
    this.CoverageCheckList.forEach((covg: any) => {
      if (covg.coverageParameters) {
        covg.coverageParameters.forEach((obj: any) => {
          if (obj.displayName == 'Liability - Auto Symbol') {
            obj.expiringValue = obj.expiringValue.split("</br>").join(",");
            obj.recommendedValue = obj.recommendedValue.split("</br>").join(",");
          }

        });
      }
    });
  }
}

