import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ErrorBox } from 'src/app/shared/class/error-box';

@Component({
  selector: 'app-upload-prospect-logo',
  templateUrl: './upload-prospect-logo.component.html',
  styleUrls: ['./upload-prospect-logo.component.css']
})
export class UploadProspectLogoComponent implements OnInit {
  @Input() prospectLogoObject: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  prospectDeatils: any;
  filesToUpload: any;
  fileName: any = "";
  fileSize: any;
  prospectLogoName: any;
  imageSrc: any;
  _confirmBox: any;
  _userMessages: any;
  showError: boolean = false;
  errorList: any[] = [];
  errorMessage: any;
  _errorBox: ErrorBox;
  imageChangedSrc: any;
  uploadUrl: any;


  constructor(
    public _activeModal: NgbActiveModal,
    private _AddprospectService: AddprospectService,
    private _wbaService: WhiteBoardActivityService,
  ) {
    this._confirmBox = new ConfirmBox()
    this._userMessages = new UserMessages();
    this._errorBox = new ErrorBox();
  }

  ngOnInit(): void {
    if (this.prospectLogoObject) {
      this.getProspectDetail(this.prospectLogoObject.prospectId)
      if (this.prospectLogoObject.prospectLogoPath)
        this.imageSrc = this.prospectLogoObject.prospectLogoPath + '?q=' + Math.random().toString(16);
    }
  }

  closeModal() {
    this._activeModal.close();
  }

  //To get prospect details based on selected prospect id.
  getProspectDetail(pId: any) {
    if (pId) {
      this._AddprospectService.getProspectById(pId).subscribe(
        (resp) => {
          if (resp && resp.prospectInfo) {
            this.prospectDeatils = resp.prospectInfo;
          }
        },
      );
    }
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.filesToUpload = file;

      for (var i = 0; i < event.target.files.length; i++) {
        var size = event.target.files[i].size;
        this.fileSize = Math.round(size / 1024)
      }
    }
  }
  onFileChanges(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const fileType = event.target.files[0].name.split('.')[1];
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        for (var i = 0; i < event.target.files.length; i++) {
          var size = event.target.files[i].size;
          this.fileSize = size / 1024 / 1024;
        }
        if (this.fileSize > 1) {
          this._errorBox.message = "Client/Prospect logo should not be larger than 1 MB.";
          this._errorBox.isVisible = true;
        } else {
          const [file] = event.target.files;
          const files = event.target.files[0];
          this.filesToUpload = files;
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.imageChangedSrc = reader.result as string;
          };
        }

      }
      else {
        this._errorBox.message = "Only jpg/jpeg and png files are allowed.";
        this._errorBox.isVisible = true;
      }
    }
  }

  uploadLogo() {
    const formData = new FormData();
    formData.append('Id', this.prospectLogoObject?.prospectLogoId ? this.prospectLogoObject?.prospectLogoId : 0);
    formData.append('prospectId', this.prospectLogoObject?.prospectId);
    formData.append('prospectLogo', this.filesToUpload ? this.filesToUpload : null);
    formData.append('wbaId', this.prospectLogoObject?.wbaId);

    this._wbaService.addUpdateProspectLogo(formData).subscribe((resp: any) => {
      if (resp) {
        if (this.imageChangedSrc) this.uploadUrl = this.imageSrc.replace(/^data:image\/[a-z]+;base64,/, "");
        this.passEntry.emit(this.uploadUrl);
        this.closeModal();
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else if (err.status === 412) {
            this.showError = true;
            this.errorMessage = err.error.detail;
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = err.message;
            this.errorList.push(this.errorMessage);
          }
        }
      })

  }

  //to cancel the process and back to view prospect screen
  cancel() {
    if (this.imageChangedSrc) {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    } else {
      this.closeModal();
    }

  }

  errorBoxCancel() {
    this._errorBox.isVisible = false;
  }

}
