<div class="mid-con" #mydiv>
  <div class="pro-detail">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">
          <div class="col-12 wbaHeadding">
            <div class="hd-width">
              <h2>Marketing Summary</h2>
              <div class="alert alert-danger alert-dismissible fade show alert-wba" role="alert"
                *ngIf="!ismarketingSummaryRequestcheck  && AcordPermissionResponse && wbaDetails?.transType == 'RN'">
                <ul class="view-error">
                  <li>Access denied, your permissions do not allow you to proceed.</li>
                </ul>
                <button type="button" class="btn-close" (click)="closeError1()" data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>
              <div id="vNote">
                <a [ngClass]="{ 'isDisabled': marketingSummaryList.length == 0 }" href="javascript:void(0)"
                  (click)="openMarketingNotesModal('')">View
                  All
                  Notes<img src="../../assets/images/eye.svg" alt="View All Notes" title="View All Notes" width="14px"
                    class="img-pos1 ms-2"></a>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12">

            <div class="card-text row addw">
              <div class="row frmrow add-wba-form mt-0">
                <div class="table-responsive">
                  <table class="table wba-tbl">
                    <thead class="table-dark">
                      <tr class="table-hd">
                        <th>Action</th>
                        <th>Coverage</th>
                        <th>Carrier</th>
                        <th>Wholesaler</th>
                        <th>Quote Status</th>
                        <th>Quote Date</th>
                        <th>Quoted Premium</th>
                        <th width="50px">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of marketingSummaryList;let index=index"
                        id="tr-marketing-edit-{{item.coverageId}}-{{item.carrierId}}-{{item.wholesalerId}}">
                        <td class="text-center">
                          <span *ngIf="isValidUser">
                            <a *ngIf="index!=editIndex" href="javascript:void(0)" title=""
                              class="tbl-act pointer list-action" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                              aria-expanded="false"><img src="assets/images/action.svg" alt="" /></a>
                            <ul class="dropdown-menu action-dd act-pos" aria-labelledby="dropdownMenuButton1">
                              <li><a *ngIf="index!=editIndex" class="dropdown-item" href="javascript:void(0)"
                                  (click)="handleAction('quoteDetails', item)"><img
                                    src="../../assets/images/quote-details.svg" alt="Quote Details"><span>Quote
                                    Details</span>
                                </a>
                              </li>
                              <li><a *ngIf="index!=editIndex" class="dropdown-item" href="javascript:void(0)"
                                  (click)="openMarketingNotesModal(item)"><img
                                    src="../../assets/images/quote-details.svg" alt="Notes"><span>Notes</span>
                                </a>
                              </li>
                            </ul>
                            <!-- <span *ngIf="index==editIndex">
                                                                <img *ngIf="editIndex!==-1 && editIndex===index"
                                                                     (click)="handelMarketingSummaryList('cross', false, index)"
                                                                     class="pointer me-2" width=" 13px"
                                                                     src="assets/images/cross.svg" alt="" />
                                                                <img *ngIf="editIndex!==-1 && editIndex===index"
                                                                     class="pointer" width=" 13px"
                                                                     (click)="createSummary('edit',item.id)"
                                                                     src="assets/images/save.svg" alt="" />
                                                                </span> -->
                          </span>
                        </td>
                        <td>
                          <span *ngIf="index===editIndex; else coverageString">
                            <select class="form-select" [(ngModel)]="editMarketSummayForm.coverageId"
                              [disabled]="item.quoteNotesFound" (change)="handleData()">
                              <option disabled value="">Select
                                Coverage</option>
                              <ng-container *ngFor="let cov of coverageList">
                                <ng-container *ngIf="cov.isActive">
                                  <option [value]="cov.id">
                                    {{cov.displayName}}
                                  </option>
                                </ng-container>
                              </ng-container>
                            </select>
                          </span>
                          <ng-template #coverageString>
                            {{item.coverageName}}
                          </ng-template>
                        </td>
                        <td>
                          <span *ngIf="index===editIndex; else CarrierString">
                            <div class="flex-div table-autocomp">
                              <p-autoComplete placeholder="Carrier" [(ngModel)]="editCarrier"
                                [disabled]="item.quoteNotesFound" [showEmptyMessage]="true"
                                [suggestions]="expiringCarrierlist" [minLength]="1"
                                (onSelect)="selectItem($event.value,'Carrier')"
                                (onClear)="lookUpDataFilterChanges('editCarrier')"
                                (completeMethod)="getCarriers('Carrier',$event,'TypeOne')"
                                (onDropdownClick)="getCarriers('Carrier',$event,'TypeOne')" field="value"
                                (change)="handleCarrierData($event,'')">
                                <ng-template let-carrier pTemplate="item">
                                  <div class="company-item">
                                    <div>{{
                                      carrier.value
                                      }}
                                    </div>
                                  </div>
                                </ng-template>
                              </p-autoComplete>
                              <img (click)="openLookupModal('Carrier','Carrier')" src="assets/images/search.svg" alt=""
                                class="ms-2" />
                            </div>
                          </span>
                          <ng-template #CarrierString>
                            {{item.carrierName}}</ng-template>
                        </td>
                        <td>
                          <span *ngIf="index===editIndex; else WholesalerString">
                            <div class="flex-div table-autocomp">
                              <p-autoComplete placeholder="Wholesaler" [(ngModel)]="editWholesaler"
                                [showEmptyMessage]="true" [suggestions]="expiringCarrierlist" [minLength]="1"
                                (onSelect)="selectItem($event.value,'Wholesaler')"
                                (onClear)="lookUpDataFilterChanges('editWholesaler')"
                                (completeMethod)="getCarriers('Wholesaler',$event,'TypeTwo')"
                                (onDropdownClick)="getCarriers('Wholesaler',$event,'TypeTwo')" field="value"
                                (change)="handleWholesalerData($event,'')">
                                <ng-template let-carrier pTemplate="item">
                                  <div class="company-item">
                                    <div>{{
                                      carrier.value
                                      }}
                                    </div>
                                  </div>
                                </ng-template>
                              </p-autoComplete>
                              <img (click)="openLookupModal('Wholesaler','Wholesaler')" src="assets/images/search.svg"
                                alt="" class="ms-2" />
                            </div>
                          </span>
                          <ng-template #WholesalerString>
                            {{item.wholesalerName}}
                          </ng-template>
                        </td>
                        <td>
                          <span *ngIf="index===editIndex; else quotedStatusString">
                            <select class="form-select" (ngModelChange)="getStatusValue($event,'')"
                              [(ngModel)]="editMarketSummayForm.quotedStatus">
                              <option value="">Select Status
                              </option>
                              <option value="In Process">In
                                Process
                              </option>
                              <option value="Cleared">Cleared
                              </option>
                              <option value="Need Additional Info">
                                Need Additional Info</option>
                              <option value="Blocked">Blocked
                              </option>
                              <option value="Quoted">Quoted
                              </option>
                              <option value="Declined">Declined
                              </option>
                              <option value="Not Bound">Not Bound
                              </option>
                              <option value="Bound">Bound
                              </option>
                              <option value="Bound, Currently in Renewal">
                                Bound,Currently in Renewal
                              </option>
                              <option value="Bound with Other">
                                Bound
                                with Other</option>
                              <option value="Expired">Expired
                              </option>
                              <option value="Closing with Carrier">
                                Closing with Carrier</option>
                            </select>
                          </span>
                          <ng-template #quotedStatusString>
                            {{item.quotedStatus}}
                          </ng-template>
                        </td>
                        <td>
                          <span *ngIf="index===editIndex; else quoteDateString">
                            <p-calendar class="policyDetail" [(ngModel)]="editMarketSummayForm.quoteDate"
                              placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy" inputId="quoteDate"
                              (onSelect)="handleData()">
                            </p-calendar>
                          </span>
                          <ng-template #quoteDateString>{{item?.quoteDate |
                            date:'MM/dd/yyyy'}}</ng-template>
                        </td>
                        <td>
                          <span *ngIf="index===editIndex; else QuotedPremium">
                            <div class="pos-re">
                              <span class="dollor-view" style="margin-top: 3px !important;">$</span>
                              <input class="form-control me-3 dollor-input-view"
                                [(ngModel)]="editMarketSummayForm.quotedPremium"
                                (focusout)="addNumberFormat(editMarketSummayForm)"
                                (keypress)="ValidateKey.integer($event, 16)"
                                (focus)="removeNumberFormat(editMarketSummayForm)"
                                (focusout)="removeSpaces(editMarketSummayForm)" (change)="handleData()" />
                            </div>

                          </span>
                          <ng-template #QuotedPremium>
                            {{item.quotedPremium | currency : 'USD'
                            :'$':'1.0-0'}}
                          </ng-template>
                        </td>
                        <!-- <td>
                                                           <span
                                                                *ngIf="index===editIndex; else clearanceDateString">
                                                                <p-calendar class="policyDetail"
                                                                     [(ngModel)]="editMarketSummayForm.clearanceDate"
                                                                     placeholder="MM/DD/YYYY"
                                                                     [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                                     inputId="clearanceDate"
                                                                     (onSelect)="handleData()"> </p-calendar>
                                                           </span>
                                                           <ng-template
                                                                #clearanceDateString>{{item?.clearanceDate |
                                                                date:'MM/dd/yyyy'}}</ng-template>
                                                        </td> -->
                        <td>
                          <span *ngIf="isValidUser">
                            <img *ngIf="editIndex===-1" class="pointer"
                              (click)="handelMarketingSummaryList('edit',editMarketSummayForm, index)"
                              src="assets/images/edit.svg" width="13px" alt="" class="me-2"
                              [ngClass]="{ 'isDisabled':  isHide || (!ismarketingSummaryRequestcheck && wbaDetails?.transType == 'RN')}" />

                            <!-- <ng-container *ngIf="!isEditSubmitted"> -->
                            <img *ngIf="editIndex!==-1 && editIndex===index"
                              (click)="handelMarketingSummaryList('cross', false, index)" class="pointer me-2"
                              width=" 13px" src="assets/images/cross.svg" alt=""
                              [ngClass]="{ 'isDisabled':  isHide || (!ismarketingSummaryRequestcheck && wbaDetails?.transType == 'RN')}" />
                            <img *ngIf="editIndex!==-1 && editIndex===index" class="pointer" width=" 13px"
                              (click)="handelMarketingSummaryList('save',editMarketSummayForm, index)"
                              src="assets/images/save.svg" alt=""
                              [ngClass]="{ 'isDisabled':  isHide || (!ismarketingSummaryRequestcheck && wbaDetails?.transType == 'RN')}" />
                            <!-- </ng-container> -->
                          </span>
                        </td>
                      </tr>
                      <tr *ngIf="editIndex===-1"
                        id="tr-marketing-add-{{marketSummayForm.coverageId}}-{{marketSummayForm.carrierId}}-{{marketSummayForm.wholesalerId}}">
                        <td></td>
                        <td>
                          <div class="flex-div table-autocomp">
                            <select class="form-select" [(ngModel)]="marketSummayForm.coverageId">
                              <option [selected]="" value="">
                                Select Coverage
                              </option>

                              <option *ngFor="let cov of coverageList" [value]="cov.id">
                                {{ cov.displayName }}
                              </option>
                            </select>
                          </div>
                        </td>

                        <td>
                          <div class="flex-div table-autocomp">
                            <p-autoComplete placeholder="Carrier" [(ngModel)]="Carrier" [showEmptyMessage]="true"
                              [suggestions]="expiringCarrierlist" [minLength]="1"
                              (onSelect)="selectItem($event.value,'Carrier')" (onClear)="lookUpDataFilterChanges('Carrier')"
                              (completeMethod)="getCarriers('Carrier',$event,'TypeOne')"
                              (onDropdownClick)="getCarriers('Carrier',$event,'TypeOne')" field="value"
                              (change)="handleCarrierData($event,'add')">
                              <ng-template let-carrier pTemplate="item">
                                <div class="company-item">
                                  <div>{{ carrier.value }}
                                  </div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                            <img src="assets/images/search.svg" (click)="
                                                                openLookupModal('Carrier','Carrier')" alt=""
                              class="ms-2" />
                          </div>
                        </td>
                        <td>
                          <div class="flex-div table-autocomp">
                            <p-autoComplete placeholder="Wholesaler" [(ngModel)]="Wholesaler" [showEmptyMessage]="true"
                              [suggestions]="expiringCarrierlist" [minLength]="1"
                              (onSelect)="selectItem($event.value,'Wholesaler')"
                              (onClear)="lookUpDataFilterChanges('Wholesaler')"
                              (completeMethod)="getCarriers('Wholesaler',$event,'TypeTwo')"
                              (onDropdownClick)="getCarriers('Wholesaler',$event,'TypeTwo')" field="value"
                              (change)="handleWholesalerData($event,'add')">
                              <ng-template let-carrier pTemplate="item">
                                <div class="company-item">
                                  <div>{{ carrier.value }}
                                  </div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                            <img src="assets/images/search.svg" (click)="
                                                                openLookupModal('Wholesaler','Wholesaler')" alt=""
                              class="ms-2" />
                          </div>
                        </td>
                        <td>

                          <select class="form-select" (ngModelChange)="getStatusValue($event,'add')"
                            [(ngModel)]="marketSummayForm.quotedStatus">
                            <option value="" [selected]="">Select
                              Status</option>
                            <option value="In Process">In Process
                            </option>
                            <option value="Cleared">Cleared
                            </option>
                            <option value="Need Additional Info">
                              Need
                              Additional Info</option>
                            <option value="Blocked">Blocked</option>
                            <option value="Quoted">Quoted</option>
                            <option value="Declined">Declined
                            </option>
                            <option value="Not Bound">Not Bound
                            </option>
                            <option value="Bound">Bound</option>
                            <option value="Bound, Currently in Renewal">
                              Bound,
                              Currently in Renewal</option>
                            <option value="Bound with Other">Bound
                              with
                              Other
                            </option>
                            <option value="Expired">Expired</option>
                            <option value="Closing with Carrier">
                              Closing
                              with
                              Carrier</option>
                          </select>


                        </td>
                        <td>
                          <p-calendar class="policyDetail" [(ngModel)]="marketSummayForm.quoteDate"
                            placeholder="MM/DD/YYYY" [maxDate]="maximumDate" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                            inputId="quoteDate" (onSelect)="handleData()">
                          </p-calendar>
                        </td>
                        <td>
                          <div class="pos-re">
                            <span class="dollor-view" style="margin-top: 3px !important;">$</span>
                            <input class="form-control me-3 dollor-input-view"
                              [(ngModel)]="marketSummayForm.quotedPremium"
                              (focusout)="addNumberFormat(marketSummayForm)"
                              (keypress)="ValidateKey.integer($event, 16)"
                              (focus)="removeNumberFormat(marketSummayForm)" (focusout)="removeSpaces(marketSummayForm)"
                              (change)="handleData()">
                          </div>
                        </td>
                        <!-- <td>
                                                           <p-calendar class="policyDetail"
                                                                [(ngModel)]="marketSummayForm.clearanceDate"
                                                                placeholder="MM/DD/YYYY"
                                                                [maxDate]="maximumDate" [showIcon]="true" [iconDisplay]="'input'"
                                                                dateFormat="mm/dd/yy" inputId="clearanceDate"
                                                                (onSelect)="handleData()"> </p-calendar>
                                                      </td> -->
                        <td>
                          <span *ngIf="isValidUser">
                            <img class="pointer me-2" width=" 13px"
                              (click)="handelMarketingSummaryList('cross', false, -1)" src="assets/images/cross.svg"
                              [ngClass]="{ 'isDisabled': isHide || (!ismarketingSummaryRequestcheck && wbaDetails?.transType == 'RN')}"
                              alt="" />
                            <img class="pointer" width=" 13px" src="assets/images/save.svg" alt=""
                              (click)="handelMarketingSummaryList('add',marketSummayForm, 0)"
                              [ngClass]="{ 'isDisabled': isHide || (!ismarketingSummaryRequestcheck && wbaDetails?.transType == 'RN')}" />
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="_confirmBox.isVisible"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>{{_confirmBox.message}}</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="_confirmBox.isVisible = false" label="OK" styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

      <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showHideBoundCarrierValidationPopUp"
        [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
        [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
        <p>This is restricted. Please check the progress or outcome.</p>
        <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
          <p-button (click)="showHideBoundCarrierValidationPopUp = false" label="OK"
            styleClass="btn btn-primary"></p-button>
        </ng-template>
      </p-dialog>

    </div>
  </div>
</div>
