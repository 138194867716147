<div class="mid-con">
    <div class="tab-content py-0">
        <div class="tab-pane fade show active">
            <div class="pro-right-con">
                <div class="row">
                  <div class="col-12 wbaHeadding">
                    <div class="hd-width">
                      <h2>Account Management Services</h2>
                      </div>
                      </div>
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="ams">
                        <h6>All Tiers</h6>
                        <ul>
                            <li>Ongoing advice and counsel regarding industry trends </li>
                            <li>Annual policy & placement reviews</li>
                            <li>Email and voicemail acknowledgement within 24 hours of receipt</li>
                            <li>On-demand Certificate of Insurance issuance with 24 hours of request.
                            </li>
                        </ul>
                        <h6>Tier I Only</h6>
                        <ul>
                            <li>Service Summary (Stewardship) Emails</li>
                            <li>Workers Compensation Classification Code Audit</li>
                            <li>Workers Compensation Audit Prep</li>
                            <li>General Liability Class Code Assignment Review</li>
                            <li>Special Premium Allocation (Escrow & Client Designated Allocation)</li>
                        </ul>
                        <h6>Tier I & II (greater than $50k in revenue)</h6>
                        <ul>
                            <li>Service Excellence Program</li>
                            <li>Premium Allocation as recommended by Lamb</li>
                        </ul>
                        <h6>Tiers I, II, & III</h6>
                        <ul>
                            <li>Contract Review</li>
                            <li>Statement of Values Review</li>
                            <li>Timely renewal premium indications</li>
                        </ul>

                        <ul class="note-list">
                            <li> Lamb's claims team provides advocacy and support for any claims where
                                potential issues might arise. We hold carriers accountable and ensure
                                your best interests are prioritized. </li>
                            <li>Lamb assigns a dedicated claims advocate to your organization,
                                responsible for-day to-day claims support, providing loss analyses, and
                                conducting claims reviews. </li>
                            <li>Lamb will provide a no-cost risk management platform, providing 24/7
                                claims submission capability, risk management tools, and loss analytics
                                on demand.</li>

                        </ul>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
