<div class="mid-con">
  <div class="tab-content py-0">
    <div class="tab-pane fade show active">
      <div class="pro-right-con">
        <div class="row">



          <div class="col-12 wbaHeadding">
            <div class="hd-width">
              <h2>CE Status</h2>


              <div class="alert alert-danger alert-dismissible fade show alert-wba" role="alert" *ngIf="showError">
                <ul class="view-error">
                  <li *ngFor="let err of errorList">{{err}}</li>
                </ul>
                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>


              <a class="email-btn me-4" (click)="openeEmailTemplateModal()"
                [ngClass]="{'isDisabled':wbaDetails?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || isAssesmentParamsHasMandatoryValues}">
                <img src="assets/images/email-s.png" alt="Submissions Email" />Submissions Email
              </a>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12">
            <div class="card-text  addw" id="tb-h">
              <div class=" frmrow add-wba-form my-2 accord-famous">
                <form [formGroup]="acordForm" *ngIf="acordForm">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">

                        <div class="col-12 col-sm-12 col-md-3">
                          <div class="frmrow add-wba-form">
                            <label class="form-label" for="Review">BOR</label>
                            <div class="inputControl">
                              <select class="form-select" #selectBor id="bor" (change)="setBor(selectBor.value)"
                                formControlName="bor">
                                <option value="none" selected disabled hidden>Select</option>
                                <option *ngFor="let item of bor" [value]="item.value">
                                  {{ item.label }}
                                </option>
                              </select>
                            </div>
                            <button class="btn btn-primary float-left mb-3"
                              [disabled]="wbaDetails?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won' || wbaDetails?.wbaStatusDescription =='Lost' || isAssesmentParamsHasMandatoryValues || acordForm.get('bor').value != 'Yes'"
                              (click)="openBorModal()">BOR Plan of Attack Form</button>
                            <!-- <div class="mb-2">
                              <a href="javascript:void(0)"
                                [ngClass]="{'isDisabled':wbaDetails?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || isAssesmentParamsHasMandatoryValues || acordForm.get('bor').value != 'Yes'}"
                                class="btn btn-outline-primary" (click)="openBorModal()">BOR Plan of Attack Form</a>
                            </div> -->

                            <!-- <button class="btn btn-primary ms-3"
                              [ngClass]="{'isDisabled':wbaDetails?.outcome == 'Lost' || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || isAssesmentParamsHasMandatoryValues || acordForm.get('bor').value != 'Yes'}"
                              class="btn btn-outline-primary" (click)="openBorModal()">BOR Plan of Attack Form</button> -->

                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3">
                          <div class="frmrow add-wba-form " *ngIf="hideReviewDropdown">
                            <label class="form-label" for="Review">Review</label>
                            <div class="inputControl">
                              <select class="form-select" #review id="Review" (change)="setReview(review.value)"
                                *ngIf="requestedOption" formControlName="review">
                                <option value="none" selected disabled hidden>Select</option>
                                <option *ngFor="let item of acords1" [value]="item.value">
                                  {{ item.label }}
                                </option>
                              </select>
                              <select class="form-select" #review id="Review" (change)="setReview(review.value)"
                                *ngIf="notNeededOption" formControlName="review">
                                <option value="none" selected disabled hidden>Select</option>
                                <option *ngFor="let item of acords2" [value]="item.value">
                                  {{ item.label }}
                                </option>
                              </select>
                            </div>
                            <button class="btn btn-primary" *ngIf="acordForm.get('review').value != 'Not Needed'"
                              [disabled]="wbaView?.outcome == 'Lost' || wbaView?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost' || isAssesmentParamsHasMandatoryValues"
                              (click)="createAccordReview()">Submit</button>

                          </div>
                        </div>
                      </div>




                    </div>

                    <ng-container formArrayName="notNeededDropdowns"
                      *ngIf="showNotNeededDropdowns && hideReviewDropdown">
                      <div class="col-12 col-sm-12 col-md-12 mt-2">
                        <div class="row">
                          <ng-container *ngFor="let item of notNeededDropdowns?.controls; let i = index">
                            <div class="col-12 col-sm-12 col-md-3" [formGroupName]="i">

                              <label class="form-label " [for]="item.get('fileType')?.value">
                                {{item.get('fileType')?.value}}<span
                                  *ngIf="(item.get('fileType')?.value !=='Financials' && item.get('fileType')?.value !=='BOR')"
                                  class='star-color'>*</span></label>

                              <div class="inputControl">

                                <select class="form-select" [class.errorInput]="acordForm.invalid && submitted"
                                  [id]="item.get('fileType')?.value" #wba (change)="setRsmAcord(wba)"
                                  formControlName="selectedOption">
                                  <option value="none" selected disabled hidden>Select</option>
                                  <option [value]="option"
                                    *ngFor="let option of item.get('options')?.value?.split(',')">
                                    {{ option }}
                                  </option>
                                </select>

                                <div class="display-error" *ngIf="!(item.get('selectedOption')?.value) && submitted">
                                  <div>
                                    <span>
                                      {{addWbaScreen.sdRsmDropdowns.required}}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>


                          <div class="col-12 col-sm-12 col-md-12">

                            <button class="btn btn-primary" (click)="createWBAFileReview('Review')"
                              [disabled]="wbaView?.outcome == 'Lost' || wbaView?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost'|| isAssesmentParamsHasMandatoryValues">Request
                              SD Approval</button>

                          </div>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                  <div class="mt-2">
                    <h6><label for="Review Status" style="color: #122B74;" class="fw-bold mb-3">Review
                        Status</label>
                    </h6>
                  </div>
                  <table class="table wba-tbl" id="response" *ngIf="acordWBA">
                    <thead class="table wba-tbl">
                      <tr>
                        <th>Acord/WBA</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>User</th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr *ngFor="let item of AcordResponse ">
                        <td>{{item.requestType}}</td>
                        <td>
                          <div class="flex-div">
                            <img *ngIf="item.requestStatus=='Requested'" src="assets/images/requested.svg"
                              alt="Requested" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Pending'" src="assets/images/pending.svg" alt="Pending"
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete Information'"
                              src="assets/images/incomplete-info.svg" alt="Incomplete Information" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete Contact'"
                              src="assets/images/incomplete-contact.svg" alt="Incomplete Contact" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Not Needed'" src="assets/images/not-needed.svg"
                              alt="Not Needed" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Completed'" src="assets/images/completed.svg"
                              alt="Completed" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Approved'" src="assets/images/approved.svg" alt="Approved"
                              class="me-2 " />
                            <img *ngIf="item.requestStatus=='Incomplete'" src="assets/images/incomplete.svg"
                              alt="Incomplete" class="me-2 " />
                            <img *ngIf="item.requestStatus=='Denied'" src="assets/images/denied.svg" alt="denied"
                              class="me-2 " />

                            {{item.requestStatus}}
                          </div>
                        </td>
                        <td>
                          <div class="flex-div"><img *ngIf="item.requestStatus!='Pending'"
                              src="assets/images/calendar.svg" class="me-2" />{{item.requestDate | date}}</div>
                        </td>
                        <td>
                          <div class="flex-div"><img *ngIf="item.requestStatus!='Pending'" src="assets/images/user.svg"
                              alt="" class="me-2" />{{item.createdByUserName}}</div>
                        </td>
                      </tr>
                      <tr class="pending" *ngIf="marketingReview">
                        <td>Marketing Review</td>
                        <td>
                          <div class="flex-div"><img src="assets/images/pending.svg" alt="" class="me-2" />
                            Pending
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr *ngIf="SDRSMapproval" [formGroup]="sdrsmForm">
                        <td colspan="4" class="expandRow">
                          <div class="expandRow-con " formArrayName="sdrsmDropdowns">
                            <div class="row">
                              <div class="col-12 col-sm-12 col-md-6"
                                *ngFor="let item of sdrsmDropdowns?.controls, let i = index">
                                <div class="form-group row align-items-center" [formGroupName]="i">

                                  <label class="form-label me-4 col-2"
                                    [for]="item.get('fileType')?.value">{{item.get('fileType')?.value}}<span
                                      class='star-color'>*</span></label>
                                  <div class="select-div col-4">
                                    <select class="form-select l-3" [class.errorInput]="acordForm.invalid && submitted2"
                                      [id]="item.get('fileType')?.value" #wba (change)="setRsmAcord(wba)"
                                      formControlName="selectedOption">
                                      <option value="none" selected disabled hidden>Select</option>
                                      <option [value]="option"
                                        *ngFor="let option of item.get('options')?.value?.split(',')">
                                        {{ option }}
                                      </option>
                                    </select>

                                    <div class="display-error"
                                      *ngIf="!(item.get('selectedOption')?.value) && submitted2">
                                      <div>
                                        <span>
                                          {{addWbaScreen.sdRsmDropdowns.required}}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="col-12 col-sm-12 col-md-12 text-right">

                                  <button class="btn btn-primary" *ngIf="sdRsmStatus !== 'Denied'"
                                    [disabled]="wbaView?.outcome == 'Lost'  || wbaView?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost' || isAssesmentParamsHasMandatoryValues"
                                    (click)="createWBAFileReview('SD Approval')">Request SD
                                    Approval</button>
                                  <button class="btn btn-primary" *ngIf="sdRsmStatus === 'Denied'"
                                    [disabled]="wbaView?.outcome == 'Lost'  || wbaView?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost'|| isAssesmentParamsHasMandatoryValues"
                                    (click)="createWBAFileReview('SD Approval')">Re-Request SD
                                    Approval</button>

                                </div> -->

                              </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 text-right">

                              <button class="btn btn-primary" *ngIf="sdRsmStatus !== 'Denied'"
                                [disabled]="wbaView?.outcome == 'Lost'  || wbaView?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost'|| isAssesmentParamsHasMandatoryValues"
                                (click)="createWBAFileReview('SD Approval')">Request SD
                                Approval</button>
                              <button class="btn btn-primary" *ngIf="sdRsmStatus === 'Denied'"
                                [disabled]="wbaView?.outcome == 'Lost'  || wbaView?.outcome =='Bound' || this.wbaDetails?.wbaStatusDescription == 'Won' || this.wbaDetails?.wbaStatusDescription == 'Lost'|| isAssesmentParamsHasMandatoryValues"
                                (click)="createWBAFileReview('SD Approval')">Re-Request SD
                                Approval</button>

                            </div>

                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- WBA Table -->
                  <table class="table wba-tbl" id="response" *ngIf="wbaReviewTable">
                    <thead class="table wba-tbl">
                      <tr>
                        <th>WBA Review Status</th>
                        <th>Reminder Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of wbaResponse ">
                        <td>{{item.wbaReviewStatus}}</td>
                        <td>{{item.reminderDays == 0 ? 'N/A' : item.reminderDays}}</td>
                      </tr>
                    </tbody>
                  </table>

                  <p-dialog [header]="_warningBox.header2" [modal]="true" [(visible)]="_warningBox.isVisible"
                    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false"
                    [resizable]="false" [styleClass]="_warningBox.borderColor" *ngIf="_warningBox">
                    <p>{{ _warningBox.message }}</p>
                    <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
                      <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
                    </ng-template>
                  </p-dialog>

                </form>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>