<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Notes</h2>
                </div>
                <div class="add-act1">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
        </div>

        <div class="add-content padding-bottom" [formGroup]="notesForm">
            <div class="pdg-con">

                <div class="add-title">
                    <h3 class="fw-bold">Notes</h3>
                </div>

                <div class="row">
                    <div class="mb-2">
                        <textarea rows="10" class="form-control mb-2" name="comments" placeholder="View/Edit Notes"
                            #comments formControlName="comments"></textarea>
                    </div>
                </div>

            </div>
        </div>

        <div class="blk-bt">
            <button class="btn btn-primary me-2" (click)="saveNotes()">Save</button>
            <button class="btn btn-primary" (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>