<div class="modal-content">
  <div class="modal-body">
    <div class="add-pro-outer">
      <div class="add-hdr">
        <div class="heading">
          <h2>eFile - Attach document</h2>
        </div>
        <div class="add-act">
          <a href="javascript:void(0)" title="" (click)="closeModal()" class="close" data-bs-dismiss="modal"
            aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
        </div>
      </div>
      <div class="add-content add-form">
        <div class="pdg-con">
          <div class="card">
            <!-- <div class="card-body " formGroupName="contact"> -->
            <div class="card-body ">
              <div class="card-text row addw attch">

                <embed width="1800" height="580" type="text/html" id="i_frame" [src]="urlSafes" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>