<div class="main-body-con">
  <div class="page-container pt-4">
    <div class="page-hdr-outer ps-4 pe-4">
      <div class="page-hdr">
        <h2>Whiteboard Info</h2>
        <button class="btn btn-border create" (click)="navigate()"><span><img src="assets/images/back.svg" alt=""
              class="plus-icon"></span><label>Back to List</label></button>
      </div>
      <div class="mid-con mt-2">
        <div class="full-detail px-4 py-3" id="fullpage">
          <div class="pro-right-header">
            <div class="pro-action">
              <div class="act-link">
                <a href="javascript:void(0)" [routerLink]="['../'+_navigationPath.getwhiteboardsApplicationUrl()]"
                  class="mx-2" title="Call" data-bs-toggle="modal"><img src="assets/images/edit.svg" alt=""
                    class="mx-2"><span>Edit</span></a>
                <a href="javascript:void(0)" class="mx-2" title="Call"><img src="assets/images/setting.svg" alt=""
                    class="mx-2"><span>Automate Acords</span></a>
              </div>
            </div>
          </div>


          <div class="row py-3">
            <div class="col-12 col-sm-12 col-md-6 mb-4">
              <div class="card">
                <h5 class="card-header">Basic Info</h5>
                <div class="card-body">

                  <div class="card-text">
                    <div class="con1 tbl-pro-detail">
                      <div class="row-detail mt-0">
                        <div class="lbl-title">Whiteboard Activiy Name</div>
                        <div class="des">{{WBA?.whiteBoardActivityName}}</div>

                      </div>
                      <div class="row-detail">
                        <div class="lbl-title">Marketing</div>
                        <div class="des">{{WBA?.userName}}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 mb-4">
              <div class="card">
                <h5 class="card-header">Covarage Requirements Info</h5>
                <div class="card-body">

                  <div class="card-text">
                    <div class="con1 tbl-pro-detail">
                      <div class="row-detail mt-0">
                        <div class="lbl-title">Line</div>
                        <div class="des">{{WBA?.businessLine}}</div>
                      </div>
                      <div class="row-detail">
                        <div class="lbl-title">Effective Date</div>
                        <div class="des">{{WBA?.effectiveDate | date:'MM/dd/yyyy'}}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div class="col-12 col-sm-12 col-md-6 mb-0">
              <div class="card">
                <h5 class="card-header">Company Info</h5>
                <div class="card-body">

                  <div class="card-text">
                    <div class="con1 tbl-pro-detail">
                      <div class="row-detail mt-0">
                        <div class="lbl-title">Company Name</div>
                        <div class="des">{{WBA?.companyName}}</div>
                      </div>
                      <div class="row py-0">
                        <div class="col-12 col-sm-12 col-md-7">
                          <div class="row-detail">
                            <div class="lbl-title">Business Status</div>
                            <div class="des">{{WBA?.businessStatus}}</div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-5">

                          <div class="row-detail">
                            <div class="lbl-title">EIN</div>
                            <div class="dis">{{WBA?.ein | mask:'00-0000000'}}</div>
                          </div>
                        </div>
                      </div>


                    </div>




                  </div>

                </div>
              </div>
            </div>



            <div class="col-12 col-sm-12 col-md-6 mb-0">
              <div class="card">
                <h5 class="card-header">Policy Info</h5>
                <div class="card-body">

                  <div class="card-text">
                    <div class="con1 tbl-pro-detail">
                      <div class="row-detail mt-0">
                        <div class="lbl-title">Bound Revenue</div>
                        <div class="des">{{WBA?.boundRevenue | currency : 'USD' : '' :'1.0-0'}}</div>
                      </div>
                    </div>




                  </div>

                </div>
              </div>
            </div>

          </div>


        </div>
      </div>

    </div>
  </div>






</div>