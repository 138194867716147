<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">
            <div class="add-hdr">
                <div class="heading">
                    <h2>Email</h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
            <div class="add-content add-form">
                <div class="pdg-con">
                    <form class="row g-3">
                        <div class="add-form">
                            <div class="add-fld ">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12">
                                        <div class="row">
                                            <div class="row frmrow" *ngIf="acmeEmailDetail">
                                                <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                    <label class="form-label">Subject : <span></span>
                                                        {{acmeEmailDetail.subject??"___"}}</label>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                    <label class="form-label">From : <span></span>
                                                        {{acmeEmailDetail.from??"___"}}</label>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                    <label class="form-label">To : <span></span>
                                                        {{acmeEmailDetail.to??"___"}}</label>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                    <label class="form-label">Date : <span></span>
                                                        {{acmeEmailDetail.date ?
                                                        (acmeEmailDetail.date| date:
                                                        'MM/dd/yyyy'):"___"}}</label>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                    <label class="form-label">Body :
                                                    </label>
                                                    <div class="borderLine email-template"
                                                        [innerHtml]="emailBody | safeHtml"></div>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-12 mb-1">
                                                    <label class="form-label">Attachment
                                                        :
                                                        <ng-container *ngFor="let item of acmeEmailDetail.attachment">
                                                            <p style="text-decoration:underline;">
                                                                <a [href]="item.attachmentURL" target="_blank"
                                                                    [attr.disabled]="item.attachmentURL"
                                                                    [download]="item.attachmentName">
                                                                    {{item.attachmentName}}
                                                                </a>
                                                            </p>
                                                        </ng-container>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>