import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AcmeProductService } from 'src/app/features/acme/service/acme-product.service';
import { environment } from 'src/environments/environment';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubmissionTemplateComponent } from '../submissionTemplate/submissionTemplate.component';
import { ValidatePolicyComponent } from '../validate-policy/validate-policy.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { DatacacheService } from '../../../services/datacache.service';

@Component({
  selector: 'app-rsm-approval',
  templateUrl: './rsm-approval.component.html',
  styleUrls: ['./rsm-approval.component.css']
})
export class RsmApprovalComponent implements OnInit {
  wba_approval: any = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
  ];

  wba_profitCenter: any = [
    { label: 'Lamb', value: 'Lamb' },
    { label: 'CREIS', value: 'CREIS' },
    { label: 'TruePartners', value: 'TruePartners' },
    { label: 'TruePartners - Wheels', value: 'TruePartners - Wheels' },
  ];


  @Input() public tabName: any;
  @Input() public userDetailsOutput: any;
  @Input() public selectedValue: any;
  @Input() public acordTab: any;
  @Input() public PrevtabName: any;
  @Input() isAssesmentParamsHasMandatoryValues: any;
  @Output() nextTab: any = new EventEmitter<string>();
  @Output() acordStatus: any = new EventEmitter<string>();
  @Output() setCreatedAcordId: any = new EventEmitter<string>();
  public _navigationPath: NavigationPath = new NavigationPath();
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  @Output() handleRsmIconStatus: any = new EventEmitter<any>();

  hideReviewDropdown: boolean = true;
  acordWBA: boolean = false;
  SDRSMapproval: boolean = false;
  marketingReview: boolean = true;
  accessReviewList: Array<any> = [];
  selectedReview: any;
  selectedReviewResponse: any;
  AcordResponse: Array<any> = [];
  requestStatus: any;
  rolesList: Array<any> = [];
  wbaFileResponse: Array<any> = [];
  marketingReviewStatus: any;
  roleId: any;
  roleName: string = '';
  selectedAcord: any;
  wbaId: any
  userId: any;
  roles: any;
  userRoleId: any;
  AcordArray: any;
  marketingReviewArray: any;
  wbaFileDropdownAccord: any
  wbaFileDropdownApplication: any;
  wbaFileDropdownModDocument: any;
  wbaFileDropdownLOSS: any;
  businessLineIdResp: Array<any> = [];
  BusinessLineId: any;
  marketingroleName: string = "";
  sdRsmStatus: any;
  wbaLossFileId: number = 0;
  wbaAccordFileId: number = 0;
  wbaModDocumentFileId: number = 0;
  wbaApplicationFileId: number = 0;
  selectWbaApproval: string = "";
  RsmArray: any;
  selectedComment: any;
  isDisabled: boolean = false;
  reasonResponse: any;
  BusinessLine: any;
  rsmRoleName: string = "";
  marketingCreatedByUserName: any;
  rsmCreatedByUserName: any;
  acordCreatedByUserName: any;
  loggedInUserName: any;
  wbaResponse: any;
  rsmApprovalDropdowns: boolean = true;
  wbaProgressChange: any;
  isDirty: boolean = false;
  wbaDropdown: boolean = false;
  wbaReviewTable: boolean = false;
  selectedWbaReviewStatus: any;
  selectedReasonForWbaReview: any;
  selectedReminderForWbaReview: any;
  wbaReminderDropdown: boolean = true;
  assessmentName: any;
  cvgName: any;
  workercompRating: any;
  assessmentGLRating: any;
  glRating: any;
  assessmentWCRating: any;
  epicUniquePolicyId: any;
  epicPolicyResponse: any;
  _warningBox: ConfirmBox;
  loggedInUserId: any;
  activeWbaId: any;
  rsmResponse: any;
  prospectId: any;
  wbaDetails: any;
  coverageAssessmentDetailData: any
  wbaTransType: any;
  showError: boolean = false;
  errorList: any[] = [];
  errorMessage: any;
  validatePolicyResponse: any;
  validationMessage: boolean = false;
  checkValidationPopup: boolean = true;
  SelectedvalueforProfitCenter: any;
  profitCenter: any;
  _warningBox1: ConfirmBox;
  profitCenterDisable: boolean = false;
  errorMessage1: SafeHtml = ''; // Initialize to an empty string
  isPolicyCreated: boolean = false;
  tabRefresh: any;
  takingDataFromApi: any;

  constructor(
    private wbaService: WhiteBoardActivityService,
    private _AcmeProductService: AcmeProductService,
    private _router: Router,
    private _loaderService: LoaderService,
    private _addWBAService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private _dataCacheService: DatacacheService
  ) {
    this._warningBox = new ConfirmBox();
    this._warningBox1 = new ConfirmBox()
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    // this.getBusinessLineList();
    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);
    this.loggedInUserName = userDetails.user.display_name;
    this.loggedInUserId = userDetails.user.userId;
    // this.getAccessReviewList();
    // this.getAccordReasonList();
    // this.getWhiteBoardDetails(this.activeWbaId);
    // this.getCoverageAssessmentDetails();
    this.intializeData();
    window.scroll(0, 0);
  }

  intializeData() {
    this.tabRefresh = this._dataCacheService.retrieveData('tabRefresh');
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    // this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLineIdResp');
    this.businessLineIdResp = this._dataCacheService.retrieveData('wba_businessLine');
    this.AcordResponse = this._dataCacheService.retrieveData('wba_AcordResponse');
    this.wbaResponse = this._dataCacheService.retrieveData('wba_wbaResponse');
    this.reasonResponse = this._dataCacheService.retrieveData('wba_reasonResponse');
    this.coverageAssessmentDetailData = this._dataCacheService.retrieveData('wba_CoverageAssessmentDetails');
    // this.takingDataFromApi = this.tabRefresh.find((X: any) => X.label == 'SD Approval');
    if (!this.wbaDetails || this.reasonResponse.length == 0 || this.coverageAssessmentDetailData.length == 0) this.getAllMaster();
    else this.maprsmApproval();
  }

  getAllMaster() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };
    let data = {
      acordReasonType: "rsm"
    }
    // var businessLineList = this.wbaService.getBusinessLineList();
    var accessReviewList = this.wbaService.getAccessReviewList(requestBody);
    var AccordReasonList = this.wbaService.getAccordReasonList(data);
    var wbaActivityList = this._addWBAService.getWBAById(this.activeWbaId);
    var CoverageAssessmentDetails = this.wbaService.getCoverageAssessmentDetails(this.activeWbaId);
    forkJoin([accessReviewList, AccordReasonList, wbaActivityList, CoverageAssessmentDetails
    ]).subscribe((resp) => {
      if (resp) {
        // this.businessLineIdResp = resp[0].businessLineList;
        this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', resp[0]);
        this.AcordResponse = resp[0].acordResponse;
        this.wbaResponse = resp[0].wbaReviews;
        this.reasonResponse = resp[1].acordReasonList;
        this.wbaDetails = resp[2].whiteBoardActivityInfo;
        this.coverageAssessmentDetailData = resp[3];
        // this.tabRefresh[8].refresh = false;
        // this._dataCacheService.compressAndCacheData('tabRefresh', this.tabRefresh);
        this.maprsmApproval();
        this.setDataInCache();
      }
    })
  }
  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    // this._dataCacheService.compressAndCacheData('wba_businessLineIdResp', this.businessLineIdResp);
    this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
    this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
    this._dataCacheService.compressAndCacheData('wba_reasonResponse', this.reasonResponse);
    this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', this.coverageAssessmentDetailData);
  }

  maprsmApproval() {
    if (this.businessLineIdResp) {
      this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
      this.BusinessLineId = this.BusinessLine?.businessLineId;
      this.wbaFileResponse = this._dataCacheService.retrieveData('wba_wbaFileResponse');
      if (!this.wbaFileResponse) this.getWBAFileById();
    }
    this.mapAccessReviewList();
    this.mapwbaActivityList();
    if (this.coverageAssessmentDetailData) {
      let response = this.coverageAssessmentDetailData?.coverageAssessmentDetail?.find((el: any) => el?.coverageName == "General Liability" || el?.coverageName == "Workers Compensation");
      if (response?.coverageName == "General Liability") {
        this.assessmentGLRating = response?.ratingBasis;
      } else if (response?.coverageName == "Workers Compensation") {
        this.assessmentWCRating = response?.workercompRating;
      }
    }
  }

  mapAccessReviewList() {
    this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
    this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
    this.marketingCreatedByUserName = this.marketingReviewArray?.createdByUserName;
    this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
    this.acordStatus = this.AcordArray?.requestStatus
    this.acordCreatedByUserName = this.AcordArray?.createdByUserName;
    this.RsmArray = this.AcordResponse?.find(e => e?.requestType == 'SD Approval');
    this.sdRsmStatus = this.RsmArray?.requestStatus;
    this.rsmCreatedByUserName = this.RsmArray?.createdByUserName;
    this.hideSDrsmFields();
    this.setRsmApprovalDropdowns();
    this.setwbaReviewTable();
    this.acordWBA = true;
    this.marketingReview = false;
  }
  mapwbaActivityList() {
    this.setUpdatedProgressId.emit(this.wbaDetails.progressId);
    this.wbaProgressChange = this.wbaDetails.progressName;
    this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
    // this.epicUniquePolicyId = resp?.whiteBoardActivityInfo?.priorPolicyId;
    this.prospectId = this.wbaDetails.prospectId
    this.epicUniquePolicyId = this.wbaDetails.epicUniquePolicyId;
    this.wbaTransType = this.wbaDetails.transType;
    this.SelectedvalueforProfitCenter = this.wbaDetails.profitCenter;
    if (this.wbaDetails?.transType == 'RN') {
      this.profitCenterDisable = true;
    }
    if (!this.epicUniquePolicyId) this.ValidatePolicyInEpic();
    this.isPolicyCreated = false;
  }
  getBusinessLineList() {
    this.wbaService.getBusinessLineList().subscribe(resp => {
      this.businessLineIdResp = resp?.businessLineList;
      this._dataCacheService.compressAndCacheData('wba_businessLineIdResp', this.businessLineIdResp);
      this.BusinessLine = this.businessLineIdResp?.find(el => el?.businessLineId == this.selectedValue)
      this.BusinessLineId = this.BusinessLine?.businessLineId;
      this.getWBAFileById();
    })
  }

  setWbaApproval(item: any) {
    this.isDirty = true;
    this.selectWbaApproval = item;
  }
  setComments(item: any) {
    this.isDirty = true;
    this.selectedComment = item;
    this.isDisabled = false;
  }

  createAccordReview() {

    let data = {
      "wbaId": this.activeWbaId,
      "acordStatus": this.selectedReview
    };
    this.wbaService.createAccordReview(data).subscribe(res => {

      this.selectedReviewResponse = res;
      this.setCreatedAcordId.emit(res);

      if (res) {
        this.getAccessReviewList();
      }
    });
  }

  getAccessReviewList() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };

    this.wbaService.getAccessReviewList(requestBody).subscribe(res => {
      this.AcordResponse = res?.acordResponse;
      this.wbaResponse = res?.wbaReviews;
      this._dataCacheService.compressAndCacheData('wba_AcordResponse_reviewList', res);
      this._dataCacheService.compressAndCacheData('wba_AcordResponse', this.AcordResponse);
      this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
      this.marketingReviewArray = this.AcordResponse?.find(e => e?.requestType == 'Marketing Review');
      this.marketingReviewStatus = this.marketingReviewArray?.requestStatus
      this.marketingCreatedByUserName = this.marketingReviewArray?.createdByUserName;
      this.AcordArray = this.AcordResponse?.find(a => a?.requestType == "Acord Review Status")
      this.acordStatus = this.AcordArray?.requestStatus
      this.acordCreatedByUserName = this.AcordArray?.createdByUserName;
      this.RsmArray = this.AcordResponse?.find(e => e?.requestType == 'SD Approval');
      this.sdRsmStatus = this.RsmArray?.requestStatus;
      this.rsmCreatedByUserName = this.RsmArray?.createdByUserName;
      this.hideSDrsmFields();
      this.setRsmApprovalDropdowns()
      // this.setwbaDropdown();
      this.setwbaReviewTable();
      this.handleIconData();
    });
    this.acordWBA = true;
    this.marketingReview = false;
  }

  hideSDrsmFields() {
    this.SDRSMapproval = this.sdRsmStatus == "Pending" ? true : false;
  }

  setRsmApprovalDropdowns() {
    if (this.acordStatus == "Not Needed" && this.sdRsmStatus == "Pending") {
      this.rsmApprovalDropdowns = false;
    }
  }

  setwbaReviewTable() {
    if (this.wbaResponse[0]?.wbaReviewStatus != null) {
      this.wbaReviewTable = true;
      this.SDRSMapproval = false;
      this.wbaDropdown = false;
    }
  }

  setwbaDropdown() {
    if ((this.acordStatus == "Not Needed" && this.sdRsmStatus == "Approved") && this.wbaResponse[0]?.wbaReviewStatus == null) {
      this.wbaDropdown = true;
    }
  }

  handleIconData() {
    if ((this.acordStatus == "Not Needed" && this.sdRsmStatus == "Approved")
      && this.wbaResponse[0]?.wbaReviewStatus != null) this.handleRsmIconStatus.emit({ label: 'SD Approval', value: false });
    else if (this.acordStatus != "Not Needed" && this.sdRsmStatus == "Approved")
      this.handleRsmIconStatus.emit({ label: 'SD Approval', value: false });
    else this.handleRsmIconStatus.emit({ label: 'SD Approval', value: true });
  }

  // acord review for SD/RSM Approval
  getWBAFileById() {
    let requestBody = {
      wbaId: this.activeWbaId,
      lineId: this.BusinessLineId
    }
    this.wbaService.getWBAFileById(requestBody).subscribe(res => {
      this.wbaFileResponse = res.wbaFiles;
      this._dataCacheService.compressAndCacheData('wba_wbaFileResponse', this.wbaFileResponse);
    })
    this._loaderService.hide();

  }

  createPolicyInEPIC() {
    if (this.assessmentWCRating?.length === 0) {
      this.showWarningBox1();
    } else if (this.assessmentGLRating?.length === 0) {
      this.showWarningBox5();
    } else if (this.epicUniquePolicyId) {
      this.showWarningBox2();
    } else if (this.validationMessage == true) {
      this.showWarningBox7();
    } else if (this.validatePolicyResponse && this.validatePolicyResponse.length && this.checkValidationPopup) {
      this.openValidatePolicyModal();
    } else {
      let requestBody = {
        wbaId: this.activeWbaId,
      };
      this.wbaService.createEpicInPolicy(requestBody).subscribe(res => {
        this.epicPolicyResponse = res;
        if (res.epicPolicyId) {
          this.policyCreatedMsg();
          if (this.acordStatus == "Not Needed" && this.selectWbaApproval == "Approved") {
            //this.createWbaReviewStatus();
            this.updateRsmReview();
          } else {
            this.getWhiteBoardDetails(this.activeWbaId);
          }
        }
        else {
          this.createPolicyErrMsg();
          this.isPolicyCreated = true;
          this.getWhiteBoardDetails(this.activeWbaId);
        }
      }, (err: any) => {
        this.showError = true;
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
    }
  }

  updateAccessReview() {
    if (!this.selectWbaApproval) {
      this.showWarningBox6();
    }
    else if (this.acordStatus == "Not Needed" && this.selectWbaApproval == "Approved") {
      if (!this.epicUniquePolicyId) {
        this.createPolicyInEPIC();
      } else {
        // this.createWbaReviewStatus();
        this.updateRsmReview();
        this.showWarningBox2();
      }
    }
    else {
      this.updateRsmReview();
      // this.getWhiteBoardDetails(this.activeWbaId);
    }
  }

  updateRsmReview() {
    let requestBody = {
      id: this.selectedReviewResponse,
      wbaId: this.activeWbaId,
      acordStatus: this.acordStatus,
      marketingStatus: this.marketingReviewStatus,
      marketingUser: null,
      marketingUserId: null,
      marketingComments: "",
      rsmStatus: this.selectWbaApproval,
      profitCenter: this.SelectedvalueforProfitCenter,
      rsmUser: this.loggedInUserName,
      rsmUserId: this.loggedInUserId,
      rsmComments: "",
      aeUserId: "",
      amUserId: "",
      amUser: "",
      amStatus: "",
      amComments: ""
    }
    this.wbaService.updateRSMReview(requestBody).subscribe(res => {
      if (res) {
        this.rsmResponse = res;
        if (res?.id === 0) {
          this.showWarningBox4();
        } else {
          this.isDirty = false;
          this._notifierService._isprofitCenterChange.next(this.SelectedvalueforProfitCenter);
          this.getAccessReviewList();
          this.getWhiteBoardDetails(this.activeWbaId);
          this.updateProfitCenterInEpic();
          if (this.acordStatus == "Not Needed" && this.selectWbaApproval == "Approved") this.createWbaReviewStatus();
        }
        // this._notifierService._acordSubmitted.next(true);
        // this._dataCacheService.__acordSubmitted.next(true);
        let data = {
          value: 'accord',
          wbaId: this.activeWbaId
        }
        this._dataCacheService.emitrefreshaccord(data);
      }
    });
  }

  createWbaReviewStatus() {
    let requestBody = {
      "wbaId": this.activeWbaId,
      "wbaReviewStatus": "Complete",
      "reminderdays": 0
    }
    this.wbaService.createWbaReviewStatus(requestBody).subscribe(res => {
      if (res) {
        this.getAccessReviewList();
      }
    });
  }

  closeError() {
    this.showError = false;
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this.wbaDetails = resp.whiteBoardActivityInfo;
      this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
      this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
      // this.epicUniquePolicyId = resp?.whiteBoardActivityInfo?.priorPolicyId;
      this.prospectId = resp.whiteBoardActivityInfo.prospectId
      this.epicUniquePolicyId = resp.whiteBoardActivityInfo?.epicUniquePolicyId;
      this.wbaTransType = resp.whiteBoardActivityInfo?.transType;
      this.SelectedvalueforProfitCenter = resp.whiteBoardActivityInfo?.profitCenter;
      if (this.wbaDetails?.transType == 'RN') {
        this.profitCenterDisable = true;
      }
      if (!this.epicUniquePolicyId) this.ValidatePolicyInEpic();
      if (this.epicUniquePolicyId && this.isPolicyCreated && this.acordStatus == "Not Needed" && this.selectWbaApproval == "Approved") {
        // this.createWbaReviewStatus();
        this.updateRsmReview();
      }
      this.isPolicyCreated = false;
    });
  }


  // API for COMMENTS
  getAccordReasonList() {
    let data = {
      acordReasonType: "rsm"
    }
    this.wbaService.getAccordReasonList(data).subscribe(res => {
      this.reasonResponse = res.acordReasonList;
      this._dataCacheService.compressAndCacheData('wba_reasonResponse', this.reasonResponse);

    })
  }

  showWarningBox1() {
    let message = `WC Rating Basis is not added on Coverage Assessment.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox3() {
    let message = `Policy is not created in EPIC.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox4() {
    let message = this.rsmResponse?.reason
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox5() {
    let message = `GL Rating Basis is not added on Coverage Assessment.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox6() {
    let message = `Please select WBA Approval.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox2() {
    let message = `Policy "${this.epicUniquePolicyId}" already created in EPIC.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  showWarningBox7() {
    let message = this.validatePolicyResponse?.message
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  policyCreatedMsg() {
    let message = `Policy "${this.epicPolicyResponse.epicPolicyId}" created in EPIC.`
    this._warningBox.message = message;
    this._warningBox.isVisible = true;
  }
  createPolicyErrMsg() {
    let message = this.epicPolicyResponse.errorMessage
    this.errorMessage1 = this.sanitizer.bypassSecurityTrustHtml(message);
    this._warningBox1.isVisible = true;
  }

  warningBoxCancel() {
    this._warningBox.isVisible = false;
  }

  openeEmailTemplateModal() {
    const modalRef = this.modalService.open(SubmissionTemplateComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    let data = {
      wbaId: this.activeWbaId,
      prospectId: this.prospectId,
      epicUniquePolicyId: this.epicUniquePolicyId,
      wbaTransType: this.wbaTransType,
      priorPolicyId: this.wbaDetails.priorPolicyId
    }
    modalRef.componentInstance.wbaIdData = data
  }

  getCoverageAssessmentDetails() {
    this.wbaService.getCoverageAssessmentDetails(this.activeWbaId).subscribe(res => {
      this._dataCacheService.compressAndCacheData('wba_CoverageAssessmentDetails', res);
      let response = res?.coverageAssessmentDetail?.find((el: any) => el?.coverageName == "General Liability" || el?.coverageName == "Workers Compensation");
      if (response?.coverageName == "General Liability") {
        this.assessmentGLRating = response?.ratingBasis;
      } else if (response?.coverageName == "Workers Compensation") {
        this.assessmentWCRating = response?.workercompRating;
      }
    })
  }

  ValidatePolicyInEpic() {
    this._addWBAService.validateEpicInPolicy(this.activeWbaId).subscribe(res => {
      this.validatePolicyResponse = res;
      res?.message ? this.validationMessage = true : this.validationMessage = false;
    });
  }

  openValidatePolicyModal() {
    const modalRef = this.modalService.open(ValidatePolicyComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });
    modalRef.closed.subscribe(resp => {
    })
    modalRef.componentInstance.epicPolicyResponse = this.validatePolicyResponse;
    modalRef.componentInstance.transType = 'New Business';
    modalRef.componentInstance.passEntry.subscribe((res: any) => {
      this.checkValidationPopup = false;
      if (res == 'Create Policy') {
        this.createPolicyInEPIC();
      } else {
        this.getWhiteBoardDetails(this.activeWbaId);
      }
    })
  }

  saveWBA(action: string) {
    if (action === 'next') {
      this.sdRsmStatus == "Approved" ? this.nextTab.emit(this.tabName) : this.nextTab.emit(this.acordTab)
    }
    else this._router.navigate([this._navigationPath.getProspectsWhiteboardsUrl()]);
  }

  typeOfEvent(event: any) {
    let value1 = event.target.value;
    if (value1 == 'Lamb') {
      this.SelectedvalueforProfitCenter = 'Lamb'
    } else if (value1 == 'CREIS') {
      this.SelectedvalueforProfitCenter = 'CREIS'
    } else if (value1 == 'TruePartners') {
      this.SelectedvalueforProfitCenter = 'TruePartners'
    }
    else if (value1 == 'TruePartners - Wheels') {
      this.SelectedvalueforProfitCenter = 'TruePartners - Wheels'
    }

  }
  updateProfitCenterInEpic() {
    let request = {
      "wbaId": this.activeWbaId
    }
    this.wbaService.updateProfitCenterInEpic(request).subscribe((res) => {
    })
  }
}