import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { ActivatedRoute } from '@angular/router';
//import { DatacacheService } from 'src/app/features/prospect/services/datacache.service';
import { DatacacheService } from '../../../services/datacache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupManagerComponent } from '../lookup-manager/lookup-manager.component';
import {stringEmpty} from "../../../../../shared/utils";

@Component({
  selector: 'app-epicpolicyd-details',
  templateUrl: './epicpolicyd-details.component.html',
  styleUrls: ['./epicpolicyd-details.component.css']
})
export class EpicpolicydDetailsComponent implements OnInit {

  @Input() wbaView: any;
  epicPolicyForm: any;
  wbaId: string = "0";
  id: number = 0;
  showError: boolean = false;
  errorList: any;
  errorMessage: any;
  activeWbaId: any;
  wbaEpicPolicyDetailList: any
  accountManagerlist: any;
  lookupData: any;
  lookupcomponent: any;
  userDetails: any;
  isValidUser: boolean = false;

  constructor(private formbuilder: UntypedFormBuilder,
    private activeRouter: ActivatedRoute,
    private _getWbaActivityService: WhiteBoardActivityService,
    private _dataCacheService: DatacacheService,
    private _dataCacheService1: DatacacheService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    this.epicPolicyControl();
    this.initializeData();
    window.scroll(0, 0);
    this._dataCacheService1.getproducer().subscribe((res: any) => {
      if (res) {
        this.getEpicPolicyDetailsApi();
      }
    })
    this.userInformation();
  }

  //method for choose cache data or get api call
  initializeData() {
    this.wbaEpicPolicyDetailList = this._dataCacheService.retrieveData('wba_epicPolicyDetails');
    if (!this.wbaEpicPolicyDetailList) this.getEpicPolicyDetailsApi();
    else this.setFormData();
  }

  //get epic policy detail api and update cache data
  getEpicPolicyDetailsApi() {
    if (this.activeWbaId) {
      this._getWbaActivityService.GetEpicPolicyDetails(this.activeWbaId).subscribe((resp: any) => {
        this.wbaEpicPolicyDetailList = resp.wbaEpicPolicyDetailList[0];
        this._dataCacheService.compressAndCacheData('wba_epicPolicyDetails', this.wbaEpicPolicyDetailList);
        this.setFormData();
      })
    }
  }

  userInformation(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

    if (this.userDetails) {
      let roleExist = this.userDetails.user.roles.find((role: any) => role.roleName == 'Admin' || role.roleName == 'Client Service Level 4');
      if (roleExist) {
        this.isValidUser = true;
      }
      else {
        this.isValidUser = false;
      }
    }
  }


  // set form data
  setFormData() {
    let epicPolicyList = this.wbaEpicPolicyDetailList
    this.id = this.wbaEpicPolicyDetailList.id
    this.epicPolicyForm.patchValue({
      "epicUniquePolicyId": epicPolicyList.epicUniquePolicyId,
      "previousEpicId": epicPolicyList.previousEpicId,
      "policyStatus": epicPolicyList.policyStatus,
      "efileParentNodeId": epicPolicyList.efileParentNodeId,
      "borTimer": epicPolicyList.borTimer,
      "eFileNodeId": epicPolicyList.eFileNodeId,
      "modifiedby": epicPolicyList.modifiedBy,
      "boundDate": epicPolicyList.boundDate,
      "originalProducer": { id: epicPolicyList.originalProducerId, value: epicPolicyList.originalProducer ?? stringEmpty },
      "originalSubProducer": { id: epicPolicyList.originalSubProducerId, value: epicPolicyList.originalSubProducer ?? stringEmpty },
    })
  }

  //get form control
  epicPolicyControl() {
    this.epicPolicyForm = this.formbuilder.group({
      epicUniquePolicyId: [''],
      previousEpicId: [''],
      policyStatus: [''],
      efileParentNodeId: [''],
      borTimer: [''],
      eFileNodeId: [''],
      originalProducer: [''],
      originalSubProducer: [''],
      modifiedby: [''],
      boundDate: ['']
    })
    this.epicPolicyForm.get('modifiedby').disable();
    this.epicPolicyForm.get('boundDate').disable();
  }

  //select original produser and subproduser
  selectItem(item: any, type: any) {
    if (type == 'accountManagerProducer')
      this.epicPolicyForm.controls['originalProducer'].setValue({
        id: item.userId,
        value: item.userName
      });
    if (type == 'accountManagerSubProducer')
      this.epicPolicyForm.controls['originalSubProducer'].setValue({
        id: item.userId,
        value: item.userName
      });
  }

  //bind selected data
  getCompanyList(fieldName: any, val: any) {
    if (fieldName && val.query) {
      let encode = val.query.replaceAll('&', '%26')
      this._getWbaActivityService.getTypeheadList(fieldName, encode).subscribe((resp: any) => {
        if (fieldName == 'accountManager')
          this.accountManagerlist = resp.userList;
      })
    }
  }

  //lookup model for produser and sub produser
  openLookupModal(value: any, callForm: string) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    this._getWbaActivityService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
      if (response) {
        this.lookupData = response;
        if (value === "AccountManager") {
          this.lookupcomponent = LookupManagerComponent;
        }
        const modalRef = this.modalService.open(this.lookupcomponent, {
          keyboard: false,
          backdrop: 'static',
          modalDialogClass: 'customeDialog1'
        });
        modalRef.closed.subscribe((resp: any) => {
        })

        modalRef.componentInstance.lookupData = this.lookupData;

        modalRef.componentInstance.messageEvent.subscribe((data: any) => {
          if (data) {
            if (callForm == 'Producer') {
              this.epicPolicyForm.controls['originalProducer'].patchValue({
                id: data.userId,
                value: data.userName
              });
            }
            if (callForm == 'SubProducer') {
              this.epicPolicyForm.controls['originalSubProducer'].patchValue({
                id: data.userId,
                value: data.userName
              });
            }
          }
        });

      }
    }, (err: any) => {
      if (err) {
        this.showError = true;
        this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
      }
    });
  }

  // post data and update functionality
  epicPolicyDetails() {
    let epicPolicyData = this.epicPolicyForm.value;
    if (!this.id) {
      let savedata = {
        "wbaId": this.activeWbaId,
        "epicUniquePolicyId": epicPolicyData.epicUniquePolicyId,
        "previousEpicId": epicPolicyData.previousEpicId,
        "policyStatus": epicPolicyData.policyStatus,
        "efileParentNodeId": epicPolicyData.efileParentNodeId,
        "borTimer": epicPolicyData.borTimer,
        "eFileNodeId": epicPolicyData.eFileNodeId,
        "originalProducerId": epicPolicyData.originalProducer.id,
        "originalSubProducerId": epicPolicyData.originalSubProducer.id

      }
      this._getWbaActivityService.saveEpicPolicyDetails(savedata).subscribe((resp: any) => {
        this.getEpicPolicyDetailsApi();
      });
    }
    else {
      let updatedData = {
        "wbaId": this.activeWbaId,
        "epicUniquePolicyId": epicPolicyData.epicUniquePolicyId,
        "previousEpicId": epicPolicyData.previousEpicId,
        "policyStatus": epicPolicyData.policyStatus,
        "efileParentNodeId": epicPolicyData.efileParentNodeId,
        "borTimer": epicPolicyData.borTimer,
        "eFileNodeId": epicPolicyData.eFileNodeId,
        "originalProducerId": epicPolicyData.originalProducer.id,
        "originalSubProducerId": epicPolicyData.originalSubProducer.id
      }
      this._getWbaActivityService.updateEpicPolicyDetails(updatedData, this.id, this.activeWbaId).subscribe((resp: any) => {
        this.getEpicPolicyDetailsApi();
      })
    }
    (err: any) => {
      this.showError = true;
      if (err.status !== 200) {
        if (err.status === 400) {
          this.showError = true;
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          this.errorList = [];
          this.errorMessage = arr[0].value[0];
          this.errorList.push(this.errorMessage);
        } else {
          this.showError = true;
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
          this.errorList.push(this.errorMessage);
        }
      }
    }
  }

}
