<!-- Dialog for confirmation starts-->
<p-dialog [header]="_confirmBoxForWba.header" [modal]="true" [(visible)]="_confirmBoxForWba.isVisible"
  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
  [styleClass]="_confirmBoxForWba.borderColor" *ngIf="_confirmBoxForWba">
  <p>{{ _confirmBoxForWba.message }}</p>
  <ng-template pTemplate="footer" *ngIf="_confirmBoxForWba.hasFooter">
    <p-button (click)="_confirmBoxForWba.isVisible = false" label="No" styleClass="btn btn-outline-primary">
    </p-button>
    <p-button *ngIf="closedModalType == 'closeModal'" (click)="confirmBoxModalClose()" label="Yes"
      styleClass="btn btn-primary"></p-button>
  </ng-template>
</p-dialog>
<!-- Dialog for confirmation ends-->
<div class="main-body-con">
  <div class="page-container">
    <div class="mid-con">
      <div class="wba-outer-block">
        <div class="wba-left-block">
          <div class="wba-contact-detail">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12">
                <div class="flex-item wbc wba-contact">
                  <img src="assets/images/user.svg" alt="">
                  <kendo-dropdownlist [data]="contactList" (valueChange)="getContactItem($event)"
                    [valuePrimitive]="true" textField="fullNames" valueField="id" [(ngModel)]="selectedContactId">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      {{ dataItem?.jobTitle ? dataItem?.fullName + ' | ' + dataItem?.jobTitle : dataItem?.fullName }}
                    </ng-template>
                  </kendo-dropdownlist>


                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-12">
                <div class="flex-item wbc">

                  <img src="assets/images/phone-pr.svg" alt="">
                  <label>
                    <p class="wba-phone"
                      (*kendoAutoCompleteGroupTemplate)="openAddDialModal(wbaView?.office1PhoneNumber)">
                      <span>{{wbaView?.office1PhoneNumber ? (wbaView?.office1PhoneNumber | mask: "(000) 000-0000") :
                        "NA" }}</span>
                      | <span class="ext"> Ext. <ng-container
                          *ngIf="wbaView?.office1Extension">{{wbaView?.office1Extension}}</ng-container></span>
                    </p>
                  </label>
                </div>

              </div>
              <div class="col-12 col-sm-12 col-md-12">
                <div class="flex-item wbc">
                  <img src="assets/images/email-pr.svg" alt="">
                  <label>
                    <a href="mailto:{{ wbaView?.office1Email}}">
                      <p class="wba-email" pTooltip="{{ wbaView?.office1Email }}" tooltipPosition="bottom"
                        tooltipStyleClass="tooltipstyle">
                        {{wbaView?.office1Email}}</p>
                    </a>
                  </label>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12">
                <div class="flex-item">
                  <button tooltipPosition="right" tooltipstyleclass="tooltipstyle" pTooltip="Edit Contact Detail"
                    [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Bound' || wbaView?.wbaStatusDescription =='Lost' || wbaView?.wbaStatusDescription =='Won' || wbaView?.contactId == 0}"
                    (click)="openEditContactModel()" class="editbutton ms-0">
                    <img src="../../assets/images/edit.svg" width="14px" />
                  </button>
                  <button class="btn  newContact" (click)="openAddContactModal()">
                    New Contact
                  </button>
                </div>

              </div>
            </div>



          </div>
          <div class="scrolltab">
            <ul class="nav nav-tabs" id="myTab-wh" role="tablist">
              <li *ngFor="let tabItem of whTabsList" class="nav-item" role="presentation">
                <button class="nav-link flex-div" (click)="tabChange(tabItem)" [disabled]="isWbaTabDisabled"
                  [ngClass]="!tabItem.isActive? '' : 'activateTab'" data-bs-toggle="tab" type="button" role="tab"
                  aria-selected="true">

                  <img class="me-2" src="{{tabItem.tabIcon}}" alt="">
                  {{ tabItem.tabName }}</button>

                <ng-container *ngIf="tabItem.tabName === 'WBA Details' && activeTab === whTabsList[1].tabName">
                  <ul class="nav nav-tabs wbsubtab" id="myTab-bot-left" role="tablist">
                    <li *ngFor="let item of tabs" class="nav-item " role="presentation">
                      <button class="nav-link flex-div wbutton" (click)="detailsTabChange(item)"
                        [ngClass]="!item.isActive? '' : 'activateTab'" id="{{ item.labelImg + '-tab' }}"
                        [attr.data-bs-toggle]="!isAssesmentPopUp && item.required ? '' : 'tab'" type="button"
                        attr.role="{{!isAssesmentPopUp && item.required ? '' : 'tab'}}" aria-selected="true">
                        <div class="flex-div space-between">
                          <div class="flex-div">
                            <img (click)="detailsTabChange(item)" class="me-2 ver-img" src="{{item.labelImg}}"
                              alt="{{item.label}}">
                            <span (click)="detailsTabChange(item)">{{ item.label }}</span>
                          </div>

                          <span class="errorImage" (click)="detailsTabChange(item)"><img
                              [src]="!item.showError ? 'assets/images/success.svg' : 'assets/images/error2.svg'"
                              alt="" /></span>
                        </div>
                      </button>

                    </li>
                  </ul>
                </ng-container>
              </li>



            </ul>
          </div>
          <div class="createdWBA datecol">
            <div>
              <p class="createdOn">Created On {{wbaView?.createdDate | date:'MM/dd/YYYY'}} </p>
              <p class="createdBy">By {{wbaView?.createdByName ? wbaView.createdByName:'NA'}}</p>
            </div>
            <div class="dt-icon">
              <img src="assets/images/dt.png" alt="" />
            </div>
          </div>

        </div>
        <div class="wba-right-block" #myDiv>
          <div class="pro-detail no-round-border" id="add-wba-view">
            <div id="scrollUp"></div>
            <form [formGroup]="whiteBoardForm" class="formClass">
              <div class="wh-view">
                <div class="pro-right-header">
                  <div class="pro-name-city flex-div">
                    <h2><a href="javascript:void(0)" title="" tooltipPosition="bottom" tooltipStyleClass="tooltipstyle"
                        [routerLink]="'/prospects/viewprospect'" pTooltip="{{wbaView?.companyName}}"
                        [queryParams]="{prospectId: wbaView?.prospectId,listId:wbaListId,initiated: 'whiteboard'}"
                        (click)="clickOnProspect()">{{wbaView?.companyName}}</a> <span><img
                          src="assets/images/content-copy.png" alt="" (click)="copyText(wbaView?.companyName)" /></span>
                    </h2>

                    <h2 class="wbaID"> WBA-{{WBAid}} <span><img src="assets/images/content-copy.png" alt=""
                          (click)="copyText(WBAid)" /></span></h2>
                  </div>

                  <div class="pro-action">
                    <div
                      *ngIf="wbaView?.isBorDefined !='0' && wbaView?.isBorDefined !='No' && wbaView?.isBorDefined !='NO'  && wbaView?.isBorDefined !='False' && wbaView?.isBorDefined !=''"
                      class="text-end flex-div">
                      <div>
                        <span class="btn-bor">Broker of
                          record
                        </span>
                      </div>
                    </div>

                    <div class="row-detail me-3 has-validation">

                      <!-- <div class="outcome-blk">
                      <label>WBA Status</label>
                      <div>
                        <ng-container>
                          <a aria-expanded="false"></a>
                          <ul>
                            <li class="pe-2"><span href="javascript:void(0)">{{wbaView?.wbaStatusDescription=='' ?
                                'Open':
                                wbaView?.wbaStatusDescription
                                }}</span>
                            </li>
                          </ul>
                        </ng-container>
                      </div>
                    </div> -->
                    </div>

                    <div class="me-3 has-validation">
                      <div class="outcome-blk">
                        <img
                          *ngIf="wbaView?.progressName == 'Quoted' || wbaView?.progressName =='Request to Bind' || this.progressStatusChange =='Quoted' "
                          src="assets/images/arrow-thick-right.png" alt="" class="open-w arrow-anim" />
                        <ng-container *ngIf="wbaView?.outcome=='Open' || wbaView?.outcome==''">
                          <img src="assets/images/openicon.png" alt="WBA Open" class="open-w" />
                        </ng-container>
                        <ng-container *ngIf="wbaView?.outcome == 'BOR'">
                          <img src="assets/images/bor.png" alt="BOR" class="open-w" />
                        </ng-container>
                        <ng-container *ngIf="wbaView?.outcome == 'Bind Request'">
                          <img src="assets/images/brIcon.png" alt="Bind Request" class="open-w" />
                        </ng-container>
                        <ng-container *ngIf="wbaView?.outcome == 'Bound'">
                          <img src="assets/images/bound.png" alt="Bound" class="open-w" />
                        </ng-container>
                        <ng-container *ngIf="wbaView?.outcome == 'Lost'">
                          <img src="assets/images/lost-wba.png" alt="Lost" class="open-w" />
                        </ng-container>

                        <div class="dropdown text-right">
                          <ng-container>
                            <a class="dropdown-toggle pe-2" type="button" id="dropdownMenuButton1"
                              data-bs-toggle="dropdown" aria-expanded="false">{{wbaView?.outcome=='Open' ||
                              wbaView?.outcome=='' ? 'Open':
                              wbaView?.outcome}}</a>
                            <ul class="dropdown-menu action-dd dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                              <li *ngFor="let bs of valuesForOutcome">

                                <ng-container *ngIf="wbaView?.transType == 'RN'">
                                  <ng-container *ngIf="wbaView?.outcome == 'WBA Open' || wbaView?.outcome == ''">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled': bs == 'BOR' || bs == 'Bind Request'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="wbaView?.transType == 'NB'">
                                  <ng-container *ngIf="wbaView?.outcome == 'WBA Open' || wbaView?.outcome == ''">
                                    <a [ngClass]="{ 'isDisabled': bs == 'BOR' && selectedBor == 'No'}"
                                      class="dropdown-item" href="javascript:void(0)"
                                      (click)="openOutcomeForms(bs)"><img src="../../assets/images/{{bs}}.svg"
                                        alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>

                                <ng-container *ngIf="wbaView?.transType == 'RN'">
                                  <ng-container *ngIf="wbaView?.outcome == 'BOR'">
                                    <a class="dropdown-item" href="javascript:void(0)"
                                      [ngClass]="{ 'isDisabled': bs == 'BOR' || bs == 'Bind Request'}"
                                      (click)="openOutcomeForms(bs)"><img src="../../assets/images/{{bs}}.svg"
                                        alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="wbaView?.transType == 'NB'">
                                  <ng-container *ngIf="wbaView?.outcome == 'BOR'">
                                    <a class="dropdown-item" href="javascript:void(0)"
                                      (click)="openOutcomeForms(bs)"><img src="../../assets/images/{{bs}}.svg"
                                        alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>

                                <ng-container *ngIf="wbaView?.transType == 'RN'">
                                  <ng-container *ngIf="wbaView?.outcome == 'Lost'">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Lost' && bs == 'Bind Request' || bs == 'Bound' || bs =='BOR'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="wbaView?.transType == 'NB'">
                                  <ng-container *ngIf="wbaView?.outcome == 'Lost'">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Lost' && bs == 'Bind Request' || bs == 'Bound'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>

                                <!-- [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Bind Request' &&  bs == 'Lost'}" -->
                                <ng-container *ngIf="wbaView?.transType == 'RN'">
                                  <ng-container *ngIf="wbaView?.outcome == 'Bind Request'">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled':bs == 'Bind Request' ||  bs == 'Lost' || bs =='BOR'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="wbaView?.transType == 'NB'">
                                  <ng-container *ngIf="wbaView?.outcome == 'Bind Request'">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Bind Request' &&  bs == 'Lost'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>

                                <ng-container *ngIf="wbaView?.transType == 'RN'">
                                  <ng-container *ngIf="wbaView?.outcome == 'Bound'">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Bound' &&  bs == 'Lost' || bs == 'Bind Request' || bs =='BOR'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="wbaView?.transType == 'NB'">
                                  <ng-container *ngIf="wbaView?.outcome == 'Bound'">
                                    <a class="dropdown-item"
                                      [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Bound' &&  bs == 'Lost' || bs == 'Bind Request'}"
                                      href="javascript:void(0)" (click)="openOutcomeForms(bs)"><img
                                        src="../../assets/images/{{bs}}.svg" alt="Lost">
                                      <span>{{ bs }}</span></a>
                                  </ng-container>
                                </ng-container>
                              </li>
                            </ul>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <div class="text-end flex-div">
                      <!-- <div class="es-link">
                      <a href="javascript:void(0)" class="btn btn-outline-primary"
                        (click)="executiveSummaryById()">Executive
                        Summary</a>
                    </div> -->
                      <!-- <div class="es-link"
                      *ngIf="(wbaView?.transType == 'NB' && (((wbaView?.isBorDefined=='No' || wbaView?.isBorDefined=='' || wbaView?.isBorDefined=='null')
                     && ((wbaView?.wbaStatusDescription== 'OPEN' || wbaView?.wbaStatusDescription== 'Lost' ) && (wbaView?.outcome=='' || wbaView?.outcome != 'Bound')))))">
                      <a href="javascript:void(0)" class="btn btn-outline-primary" (click)="openCloneBorModal(wbaView)">
                        Create a Clone
                      </a>
                    </div> -->
                      <div class="es-link"
                        *ngIf="(isBorRenuewshow && (wbaView?.transType == 'NB' && (wbaView?.isBorDefined=='Yes' && wbaView?.outcome == 'Bound')))">
                        <a href="javascript:void(0)" class="btn btn-outline-primary"
                          (click)="openCloneBorModal(wbaView)">
                          BOR Renewal
                        </a>
                      </div>
                      <!-- <button class="attach-btn me-3" (click)="openeFileModal()">
                      <img src="assets/images/efile.png" alt="" width="46" />
                    </button> -->
                      <div class="profit-center">
                        <img src="assets/images/chart-simple.png" alt="" class="profitCenter" />
                        <p>{{this.wbalistcopy?.profitCenter}}</p>
                      </div>
                      <div class="wbaStatus open">
                        <ng-container>
                          {{wbaView?.wbaStatusDescription=='' ? 'Open':
                          wbaView?.wbaStatusDescription
                          }}
                        </ng-container>
                      </div>
                      <div>
                        <a (click)="closeModal()" href="javascript:void(0)" title="Back to List" class="closeWBA"><img
                            src="assets/images/close-wba.png" alt="Back to List" /></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="wh-view-detail">
                  <div class="row row-cols-7">

                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"><img src="assets/images/wb-icon1.png" alt="" /></div>
                        <div class="icon-des"><span>Effective Date</span>
                          <p *ngIf="wbaView?.effectiveDate">{{ wbaView?.effectiveDate | date:'MM/dd/YYYY'}} </p>
                          <p *ngIf="!wbaView?.effectiveDate">NA </p>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"><img src="assets/images/wb-icon2.png" alt="" /></div>
                        <div class="icon-des"><span>Line of Business</span>
                          <div class="cr-wd textover" [ngClass]="{'txth':!isExpand ? '':isExpand}">
                            <p pTooltip="{{ wbaView?.masterBusinessLine}} {{policyTypeName ? ' - ' + policyTypeName : ''}}"
                              tooltipPosition="bottom" tooltipStyleClass="tooltipstyle">{{wbaView?.masterBusinessLine ?
                              wbaView?.masterBusinessLine:'NA'}}
                              <span class="txt-lhtblue" *ngIf="policyTypeName"> - {{policyTypeName }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"><img src="assets/images/wb-icon3.png" alt="" /></div>
                        <div class="icon-des"> <span>Business Status</span>
                          <div class="cr-wd textover" [ngClass]="{'txth':!isExpand ? '':isExpand}">
                            <p pTooltip="{{ businessStatusChange }}" tooltipPosition="bottom"
                              tooltipStyleClass="tooltipstyle">{{
                              businessStatusChange ? businessStatusChange : 'NA'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"><img src="assets/images/wb-icon4.png" alt="" /></div>
                        <div class="icon-des"><span>Progress</span>
                          <div class="cr-wd" [ngClass]="{'txth':!isExpand ? '':isExpand}">
                            <p pTooltip="{{ progressStatusChange }}" tooltipPosition="bottom"
                              tooltipStyleClass="tooltipstyle">{{progressStatusChange ? progressStatusChange : 'NA'}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"> <img src="assets/images/Client-Status (3).png" alt="" /></div>
                        <div class="icon-des"><span>Client Status</span>
                          <div class="cr-wd">
                            <p pTooltip="{{this.prospectDetail?.prospectInformation.status}}" tooltipPosition="bottom"
                              tooltipStyleClass="tooltipstyle">
                              {{prospectDetail?.prospectInformation.status ? prospectDetail?.prospectInformation.status
                              :'NA'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"> <img src="assets/images/wb-icon6.png" alt="" /></div>
                        <div class="icon-des"><span>Tier</span>
                          <div class="cr-wd">
                            <p pTooltip="{{this.tierName?.tierValue}}" tooltipPosition="bottom"
                              tooltipStyleClass="tooltipstyle">
                              {{tierName?.tierValue ? tierName?.tierValue:'NA'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="icon-block">
                        <div class="icon-image"> <img src="assets/images/wb-icon7.png" alt="" /></div>
                        <div class="icon-des"><span>Projected Revenue</span>
                          <div class="cr-wd">
                            <p pTooltip="{{wbaView?.expectedBoundRevenue | number:'1.0-0' }}" tooltipPosition="bottom"
                              tooltipStyleClass="tooltipstyle">$ {{wbaView?.expectedBoundRevenue ?
                              (wbaView?.expectedBoundRevenue | number:'1.0-0'):'0'}}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="col">
                    <div class="icon-block">
                      <div class="icon-image"><img src="assets/images/wb-icon6.png" alt="" /></div>
                      <div class="icon-des"><span>Account Manager</span>
                        <div class="cr-wd">
                          <p pTooltip="{{ wbaView?.accountManagerUserName }}" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipstyle">{{ wbaView?.accountManagerUserName ?
                            wbaView?.accountManagerUserName:'NA'}}</p>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  </div>
                </div>
                <div class="coreTeam">
                  <div class="core-title">
                    <img src="assets/images/coreteam.png" alt="Core Team" />
                  </div>
                  <div class="core-des">
                    <div class="team-row">
                      <div class="row align-items-center">
                        <div class="col-12 col-sm-12 col-md-4  coreMr">
                          <div class="flex-div">

                            <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                            <p>Owner:</p>
                            <div class="teamInput auto-comp-wd">

                              <p-autoComplete placeholder="NA" class="auto-comp-view" formControlName="ownerName"
                                [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || isBtnDisabled || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'}"
                                [showEmptyMessage]="true" [minLength]="1" (onSelect)="selectItem($event.value,'ownerName')"
                                (onClear)="lookUpDataFilterChanges('Owner')" [suggestions]="ownerlist"
                                (completeMethod)="getOwnerlist($event)" field="value">
                                <ng-template let-owner pTemplate="item">
                                  <div class="company-item">
                                    <div>{{owner.userName}}</div>
                                  </div>
                                </ng-template>
                              </p-autoComplete>
                              <img (click)="openLookupModal('Owner')"
                                [ngClass]="{'disabled-img':isBtnDisabled || wbaView?.outcome == 'Lost' || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'}"
                                src="assets/images/mode-edit.svg" alt="" class="ms-1">
                            </div>
                          </div>
                          <div class="display-error">
                            <div *ngIf="f.ownerName.invalid && isSubmitted">
                              <span *ngIf="f.ownerName.errors?.required">
                                {{
                                _AddWhiteBoardScreen.owner.required
                                }}
                              </span>
                            </div>
                          </div>
                          <div class="display-error">
                            <span *ngIf="invalidowner?.id == '' && !f.ownerName.errors?.required">
                              {{ _AddWhiteBoardScreen.owner.ownerInvalid }}
                            </span>
                          </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 coreMr">
                          <div class="flex-div">

                            <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                            <p>Marketing Manager:</p>
                            <div class="teamInput auto-comp-wd">


                              <p-autoComplete [ngClass]="{'red' : isMarketingManager, 'am_dis': !isMarketingManager}"
                                [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || isBtnDisabled || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'}"
                                placeholder="NA" class="auto-comp-view" [showEmptyMessage]="true"
                                [suggestions]="marketingManagerlist" field="value" [minLength]="1"
                                (onSelect)="selectItem($event.value,'marketingManager')" (onClear)="clearValue()"
                                (onClear)="lookUpDataFilterChanges('marketingManager')"
                                (onDropdownClick)="getCompanyList('marketingManager',$event)"
                                (completeMethod)="getCompanyList('marketingManager',$event)"
                                formControlName="marketingManager">
                                <ng-template let-account pTemplate="item">
                                  <div class="company-item">
                                    <div>{{ account.userName }}</div>
                                  </div>
                                </ng-template>
                              </p-autoComplete>
                              <img
                                [ngClass]="{'red' : isMarketingManager, 'mm_hid': !isMarketingManager, 'disabled-img':isBtnDisabled || wbaView?.outcome == 'Lost' || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'}"
                                src="assets/images/mode-edit.svg" (click)="openLookupModal('MarketingManager')" alt=""
                                class="ms-2" />


                            </div>
                          </div>
                          <div class="display-error">
                            <span *ngIf="invalidMarketingManager">
                              {{ _AddWhiteBoardScreen.marketingManager.marketingManagerInvalid }}
                            </span>
                          </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-4 coreMr">
                          <div class="flex-div">

                            <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                            <p>Account Manager:</p>
                            <div class="teamInput auto-comp-wd">


                              <p-autoComplete [ngClass]="{'red' : isAccountManager, 'am_dis': !isAccountManager}"
                                [ngClass]="{'isDisabled':wbaView?.outcome == Lost || isBtnDisabled || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'}"
                                placeholder="NA" class="auto-comp-view" [showEmptyMessage]="true"
                                [suggestions]="accountManagerlist" field="value" [minLength]="1"
                                (onSelect)="selectItem($event.value,'accountManagerUserId')"
                                (onClear)="clearValueForAccount()" (onClear)="lookUpDataFilterChanges('accountManager')"
                                (onDropdownClick)="getCompanyList('accountManager',$event)"
                                (completeMethod)="getCompanyList('accountManager',$event)"
                                formControlName="accountManagerUserId">
                                <ng-template let-account pTemplate="item">
                                  <div class="company-item">
                                    <div>{{ account.userName }}</div>
                                  </div>
                                </ng-template>
                              </p-autoComplete>
                              <img
                                [ngClass]="{'red' : isAccountManager, 'am_hid': !isAccountManager, 'disabled-img':isBtnDisabled || wbaView?.outcome == 'Lost' || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost' }"
                                src="assets/images/mode-edit.svg" (click)="openLookupModal('AccountManager')" alt=""
                                class="ms-2" />

                            </div>
                          </div>

                          <div class="display-error">
                            <span *ngIf="invalidAccountManager">
                              {{ _AddWhiteBoardScreen.accountManager.accountManagerInvalid }}
                            </span>
                          </div>
                        </div>

                        <!-- <div class="col-12 col-sm-3 coreMr">
                        <div class="flex-div">

                          <img src="assets/images/circle-user.png" alt="" class="onerimg" />
                          <p>Original Producer:</p>
                          <div class="teamInput auto-comp-wd">

                            <p-autoComplete placeholder="Owner" class="auto-comp-view" formControlName="ownerName"
                              [showEmptyMessage]="true" [minLength]="1" (onSelect)="selectItem($event,'ownerName')"
                              (onClear)="lookUpDataFilterChanges('Owner')" [suggestions]="ownerlist"
                              (completeMethod)="getOwnerlist($event)" field="value">
                              <ng-template let-owner pTemplate="item">
                                <div class="company-item">
                                  <div>{{owner.userName}}</div>
                                </div>
                              </ng-template>
                            </p-autoComplete>
                            <img (click)="openLookupModal('Owner')" src="assets/images/mode-edit.svg" alt=""
                              class="ms-1">
                          </div>
                        </div>
                        <div class="display-error">
                          <div *ngIf="f.ownerName.invalid && isSubmitted">
                            <span *ngIf="f.ownerName.errors?.required">
                              {{
                              _AddWhiteBoardScreen.owner.required
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="display-error">
                          <span *ngIf="invalidowner?.id == '' && !f.ownerName.errors?.required">
                            {{ _AddWhiteBoardScreen.owner.ownerInvalid }}
                          </span>
                        </div>
                      </div> -->

                      </div>



                    </div>
                  </div>

                </div>


                <!-- <div class="wh-view-detail">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">


                    <div class="row row-cols-7 ">
                      <div class="col-auto datecol">
                        <span>Created On</span>
                        <p>{{wbaView?.createdDate | date:'MM/dd/YYYY'}} By {{wbaView?.createdByName ?
                          wbaView.createdByName:'NA'}}</p>
                      </div>
                      <div class="col-auto datecol">
                        <span>Effective Date</span>
                        <p *ngIf="wbaView?.effectiveDate">{{ wbaView?.effectiveDate=='0001-01-01T00:00:00' ? "NA" :
                          wbaView?.effectiveDate | date:'MM/dd/YYYY'}} </p>
                        <p *ngIf="!wbaView?.effectiveDate">NA </p>
                      </div>
                      <div class="col">
                        <span>Business Status</span>
                        <div class="cr-wd">
                          <p pTooltip="{{ businessStatusChange }}" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipstyle">{{
                            businessStatusChange ? businessStatusChange : 'NA'}}</p>
                        </div>
                      </div>
                      <div class="col">
                        <span>Progress</span>
                        <p>{{progressStatusChange ? progressStatusChange : 'NA'}}</p>
                      </div>
                      <div class="col">
                        <span>Line of Business</span>
                        <div class="cr-wd">
                          <p pTooltip="{{ wbaView?.masterBusinessLine }}" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipstyle">{{wbaView?.masterBusinessLine ?
                            wbaView?.masterBusinessLine:'NA'}} {{this.policyTypeName}} </p>
                        </div>
                      </div>
                      <div class="col">
                        <span>Account Manager</span>
                        <div class="cr-wd">
                          <p pTooltip="{{ wbaView?.accountManagerUserName }}" tooltipPosition="bottom"
                            tooltipStyleClass="tooltipstyle">{{ wbaView?.accountManagerUserName ?
                            wbaView?.accountManagerUserName:'NA'}}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div> -->
              </div>
              <div class="row" *ngIf="showError">
                <div class="col-12">
                  <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                    <ul class="view-error">
                      <li *ngFor="let err of errorList">{{ err }}</li>
                    </ul>
                    <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                      aria-label="Close"></button>
                  </div>
                </div>
              </div>




              <div class="wh-tabs" [ngClass]="{'mxw': isMaxMin  ? '': !isMaxMin}">

                <div class="wh-tabs-detail"
                  [ngClass]="{'subMax': !isMaxMin && activeTab === 'Submission View'  ? '': isMaxMin && activeTab === 'Submission View'}">

                  <div class="pro-content py-0"
                    [ngClass]="{'subMin': isMaxMin && activeTab === 'Submission View'  ? '': !isMaxMin && activeTab === 'Submission View'}">
                    <div class="row mb-0" id="viewinfo">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12   col-xxl-12 ">
                        <div class="wh-detail" [ngClass]="activeTab === whTabsList[3].tabName ? 'notetb' : ''">
                          <!-- For WBA Insured details tab starts -->
                          <ng-container *ngIf="activeTab === whTabsList[0].tabName">

                            <div class="con1 tbl-pro-detail">
                              <div class="row">
                                <!-- <ng-template #template>
                                    <div class="eMsg">
                                      Saved

                                    </div>
                                  </ng-template> -->

                                <div class="col-12 col-sm-12 col-md-6  col-lg-4 col-xl-4  col-xxl-4 insured ">
                                  <h2>Policy Detail</h2>


                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label" for="effectiveDate">Effective
                                      Date <span>*</span></label>


                                    <div class="inputControl date-control">
                                      <p-calendar class="form-control" placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'"
                                        [class.isDisabled1]="wbaView?.transType == 'RN'" dateFormat="mm/dd/yy"
                                        inputId="effectiveDate"
                                        [ngClass]="{error:f.effectiveDate.invalid && isSubmitted}"
                                        formControlName="effectiveDate"></p-calendar>
                                    </div>
                                    <div class="display-error">
                                      <div *ngIf="f.effectiveDate.invalid && isSubmitted">
                                        <span> {{ _AddWhiteBoardScreen.effDate.required }} </span>
                                      </div>
                                    </div>

                                  </div>
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Line of Business <span>*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select" [(ngModel)]="selectedValue"
                                        [ngClass]="{error:f.businessLineId.invalid && isSubmitted}"
                                        formControlName="businessLineId" [attr.disabled]="true"
                                        (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value); onDropdownChange('businssLine')">
                                        <option disabled value="">
                                          Select Business Line
                                        </option>
                                        <option *ngFor="let line of businessLine" [value]="line.businessLineId"
                                          (click)=" selectItem($event, 'businessLineList' )">
                                          {{ line.businessLineName }}
                                        </option>
                                      </select>
                                      <div class="display-error"></div>
                                    </div>
                                  </div>
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Policy Type <span *ngIf="f.businessLineId.value == 5"
                                        class="star-color">*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select"
                                        [ngClass]="{error:f.policyTypeId.invalid && isSubmitted}" [attr.disabled]="true"
                                        formControlName="policyTypeId">
                                        <option disabled value="">
                                          Select Policy Type
                                        </option>
                                        <option *ngFor="let policyType of policyTypeList"
                                          [ngValue]="policyType.businessLineId"
                                          (click)="selectItem($event, 'policyType')">
                                          {{ policyType.businessLineName }}
                                        </option>
                                      </select>
                                      <div class="display-error">
                                        <div *ngIf="f.policyTypeId.invalid && isSubmitted">
                                          <span *ngIf="f.policyTypeId.errors?.required">
                                            {{ _AddWhiteBoardScreen.policyType.required }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="frmrow add-wba-form has-validation">
                                    <div class="flex-div justify-content-between">

                                      <label class="form-label flex-div justify-content-between">
                                        <div>
                                          Expiring Carrier
                                          <span *ngIf="f.expiringPremium.value !='0' && f.expiringPremium.value !=''"
                                            class="star-color">*</span>
                                        </div>
                                        <img tooltipPosition="bottom" width="14px" tooltipstyleclass="tooltipstyle"
                                          src="assets/images/info.png" alt="" pTooltip="{{_userMessages.lookUpInfo}}">
                                      </label>

                                    </div>
                                    <div class="inputContact">
                                      <div class="flex-div ">
                                        <p-autoComplete class="form-control" placeholder="Expiring Carrier"
                                          formControlName="expiringCarrierId"
                                          [class.isDisabled1]="wbaView?.transType == 'RN'"
                                          [ngClass]="{error:f.expiringCarrierId.invalid && isSubmitted}"
                                          [ngClass]="{'isDisabled':wbaView?.transType=='RN'}" [showEmptyMessage]="true"
                                          [suggestions]="expiringCarrierlist" [minLength]="1"
                                          (onSelect)="selectItem($event.value,'expiringCarrierId')"
                                          (onClear)="clearValueForAccount()"
                                          (onClear)="lookUpDataFilterChanges('carrier')"
                                          (completeMethod)="getCompanyList('carrier',$event)"
                                          (onDropdownClick)="getCompanyList('carrier',$event)"
                                          (onClear)="onExpiringCarrierClear()" field="value">
                                          <ng-template let-carrier pTemplate="item">
                                            <div class="company-item">
                                              <div>{{ carrier.value }}</div>
                                            </div>
                                          </ng-template>
                                        </p-autoComplete>
                                        <img
                                          [class.isDisabled1]="wbaView?.masterExpiringCarrierId>0 && wbaView?.transType=='RN'"
                                          (click)="openLookupModal('Carrier')" src="assets/images/search.svg" alt=""
                                          class="ms-2" />
                                      </div>
                                      <div class="display-error">
                                        <span *ngIf="isExpiringCarrierIdRequired">
                                          {{
                                          _AddWhiteBoardScreen.expiringCarrier.required
                                          }}
                                        </span>
                                      </div>
                                      <div class="display-error">
                                        <span *ngIf="invalidExpiringCarrier">
                                          {{ _AddWhiteBoardScreen.expiringCarrier.expringInvalid }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>






                                  <!-- <div class="frmrow add-wba-form has-validation">

                                  <label class="form-label">Marketing Manager<span></span></label>

                                  <div class="inputControl">
                                    <div class="flex-div">
                                      <p-autoComplete
                                        [ngClass]="{'red' : isMarketingManager, 'am_dis': !isMarketingManager}"
                                        placeholder="Marketing Manager" class="form-control" [showEmptyMessage]="true"
                                        [suggestions]="marketingManagerlist" field="value" [minLength]="1"
                                        (onSelect)="selectItem($event,'marketingManager')" (onClear)="clearValue()"
                                        (onClear)="lookUpDataFilterChanges('marketingManager')"
                                        (onDropdownClick)="getCompanyList('marketingManager',$event)"
                                        (completeMethod)="getCompanyList('marketingManager',$event)"
                                        formControlName="marketingManager">
                                        <ng-template let-account pTemplate="item">
                                          <div class="company-item">
                                            <div>{{ account.userName }}</div>
                                          </div>
                                        </ng-template>
                                      </p-autoComplete>
                                      <img [ngClass]="{'red' : isMarketingManager, 'mm_hid': !isMarketingManager}"
                                        src="assets/images/search.svg" (click)="openLookupModal('MarketingManager')"
                                        alt="" class="ms-2" />
                                    </div>
                                    <div class="display-error">
                                      <span *ngIf="invalidMarketingManager">
                                        {{ _AddWhiteBoardScreen.marketingManager.marketingManagerInvalid }}
                                      </span>
                                    </div>
                                  </div>
                                </div> -->

                                  <ng-container *ngIf="selectedValue == 1">
                                    <div class="frmrow add-wba-form has-validation">

                                      <label class="form-label">EIN <span>*</span></label>

                                      <div class="inputControl">
                                        <div class="flex-div">
                                          <input [ngClass]="{error:f.businessLineId.invalid && isSubmitted}"
                                            mask="00-0000000" class="form-control error" placeholder="##-#######"
                                            formControlName="ein" [attr.disabled]="true" />
                                        </div>
                                        <div class="display-error">
                                          <div *ngIf="f.ein.invalid && isSubmitted">
                                            <span *ngIf="f.ein.errors?.required">
                                              {{_AddWhiteBoardScreen.ein.required}}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4  col-xxl-4 insured">
                                  <div class="ps-4 pe-3">
                                    <h2>Premium & Revenue Details</h2>
                                    <div class="frmrow add-wba-form has-validation">

                                      <label class="form-label">Expiring Premium </label>

                                      <div class="inputControl">
                                        <div class="pos-re">
                                          <span class="dollor-view">$</span>
                                          <input class="form-control error dollor-input-view"
                                            [ngClass]="{error:f.expiringPremium.invalid && isSubmitted}"
                                            [class.isDisabled1]="wbaView?.transType == 'RN'"
                                            [ngClass]="{'isDisabled':wbaView?.transType=='RN'}"
                                            formControlName="expiringPremium"
                                            (focusout)="addCurrencyFormat($event,f.expiringPremium)"
                                            (keypress)="ValidateKey.integer($event, 15)"
                                            (focus)="removeCurrencyFormat($event,f.expiringPremium)"
                                            (focusout)="removeSpaces($event,f.expiringPremium)"
                                            (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value)" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="frmrow add-wba-form has-validation">

                                      <label class="form-label">Expiring Revenue</label>

                                      <div class="inputControl">
                                        <div class="pos-re">
                                          <span class="dollor-view">$</span>
                                          <input class="form-control error dollor-input-view"
                                            [ngClass]="{error:f.expiringRevenue.invalid && isSubmitted}"
                                            formControlName="expiringRevenue"
                                            (keypress)="ValidateKey.integer($event, 15)"
                                            (focusout)="addCurrencyFormat($event,f.expiringRevenue)" maxlength="21"
                                            (focus)="removeCurrencyFormat($event, f.expiringRevenue)"
                                            (focusout)="removeSpaces($event,f.expiringRevenue)"
                                            [attr.disabled]="true" />
                                        </div>
                                      </div>
                                    </div>

                                    <div class="frmrow add-wba-form has-validation">

                                      <label class="form-label">Target Premium <span>*</span></label>
                                      <div class="inputControl">
                                        <div class="pos-re">
                                          <span class="dollor-view">$</span>
                                          <input class="form-control error dollor-input-view"
                                            [ngClass]="{error:f.targetPremium.invalid && isSubmitted}"
                                            formControlName="targetPremium" (keypress)="ValidateKey.integer($event, 15)"
                                            (focusout)="addCurrencyFormat($event,f.targetPremium)"
                                            (focus)="removeCurrencyFormat($event,f.targetPremium)"
                                            (focusout)="removeSpaces($event,f.targetPremium)"
                                            (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value)" />
                                        </div>

                                        <div class="display-error">
                                          <div *ngIf="f.targetPremium.invalid && isSubmitted">
                                            <span *ngIf="f.targetPremium.errors?.required">
                                              {{
                                              _AddWhiteBoardScreen.targetPremium.required
                                              }}
                                            </span>
                                          </div>
                                          <span
                                            *ngIf="showNonZeroMessageForTargetPremium && f.targetPremium.value=='0'">
                                            {{ _AddWhiteBoardScreen.targetPremium.nonZero }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="frmrow add-wba-form has-validation">

                                      <label class="form-label">Projected Revenue <span>*</span></label>

                                      <div class="inputControl">
                                        <div class="pos-re">
                                          <span class="dollor-view">$</span>
                                          <input class="form-control error dollor-input-view"
                                            [ngClass]="{error:f.targetRevenue.invalid && isSubmitted}"
                                            formControlName="targetRevenue" (keypress)="ValidateKey.integer($event, 15)"
                                            (focusout)="addCurrencyFormat($event,f.targetRevenue)" maxlength="21"
                                            (focus)="removeCurrencyFormat($event, f.targetRevenue)"
                                            (focusout)="removeSpaces($event,f.targetRevenue)"
                                            [ngClass]="{'isDisabled':wbaView?.wbaStatusDescription == 'Won' || wbaView?.wbaStatusDescription == 'Lost' || wbaView?.outcome == 'Bound' }" />
                                        </div>
                                        <div class="display-error">
                                          <div *ngIf="f.targetRevenue.invalid && isSubmitted">
                                            <span *ngIf="f.targetRevenue.errors?.required">
                                              {{
                                              _AddWhiteBoardScreen.targetRevenue.required
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>









                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6  col-lg-4 col-xl-4 col-xxl-4 insured">
                                  <h2>Additional WBA Details</h2>
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Priority <span
                                        *ngIf="wbaView?.transType == 'NB'">*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select" formControlName="wbaPriorityId"
                                        (change)="selectItem($event,'wbaPriorityList'); handlePriority();">
                                        <!-- <option value="{{SelectedWbaPriorityName ?
                                          SelectedWbaPriorityName:'Select Priority'}}">
                                            {{SelectedWbaPriorityName ?
                                            SelectedWbaPriorityName:"Select Priority"}}
                                          </option> -->
                                        <option disabled value="">
                                          Select Priority
                                        </option>
                                        <option *ngFor="let bs of WBAPriority.wbaPriorityList" value="{{ bs.id }}">
                                          {{ bs.value }}
                                        </option>
                                      </select>
                                      <div class="display-error">
                                        <div *ngIf="f.wbaPriorityId.invalid && isSubmitted">
                                          <span *ngIf="f.wbaPriorityId.errors?.required">
                                            {{_AddWhiteBoardScreen.priority.required}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Business Status <span>*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select" formControlName="businessStatusId"
                                        [class.isDisabled1]="wbaView?.transType == 'RN'"
                                        [ngClass]="{error:f.businessStatusId.invalid && isSubmitted}">
                                        <option disabled value="">
                                          Select Business Status
                                        </option>
                                        <option *ngFor="let bs of businessStatus" value="{{ bs.businessStatusId }}"
                                          (click)="selectItem($event,'businessStatusList')">
                                          {{ bs.businessStatusName }}
                                        </option>
                                      </select>
                                      <div class="display-error"></div>
                                    </div>
                                  </div>

                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Tier <span>*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select" formControlName="tierId"
                                        [class.isDisabled1]="wbaView?.transType == 'RN' && wbaView?.prospectSizeId > 0">
                                        <option disabled value="">
                                          Select Tier
                                        </option>
                                        <option *ngFor="let tier of prospectTierList" value="{{ tier.id }}"
                                          (click)="selectItem($event, 'tier')">
                                          {{tier.tierValue }}
                                        </option>
                                      </select>
                                      <div class="display-error">
                                        <div *ngIf="f.tierId.invalid && isSubmitted">
                                          <span *ngIf="f.tierId.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen.size.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Industry <span>*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select"
                                        [ngClass]="{error:f.industryId.invalid && isSubmitted}"
                                        formControlName="industryId">
                                        <option disabled value="">
                                          Select Industry
                                        </option>
                                        <option *ngFor="let industry of prospectIndustryList" value="{{ industry.id }}"
                                          (click)="selectItem($event, 'industry')">
                                          {{ industry.prospectIndustryName }}
                                        </option>
                                      </select>
                                      <div class="display-error">
                                        <div *ngIf="f.industryId.invalid && isSubmitted">
                                          <span *ngIf="f.industryId.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen.industry.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div class="frmrow add-wba-form has-validation">
                                    <label class="form-label">Owner <span>*</span></label>
                                    <div class="inputControl">
                                      <div class="flex-div">
                                        =======
                                      </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6  col-lg-4 col-xl-4 col-xxl-4 ">
                                      <div class="row-detail has-validation">
                                        <div class="lbl-title edt">
                                          <label class="form-label">Expiring Revenue</label>
                                        </div>
                                        <div class="des">
                                          <div class="pos-re">
                                            <span class="dollor-view">$</span>
                                            <input class="edit-control error dollor-input-view"
                                              [ngClass]="{error:f.expiringRevenue.invalid && isSubmitted}"
                                              formControlName="expiringRevenue"
                                              (keypress)="ValidateKey.integer($event, 15)"
                                              (focusout)="addCurrencyFormat($event,f.expiringRevenue)" maxlength="21"
                                              (focus)="removeCurrencyFormat($event, f.expiringRevenue)"
                                              (focusout)="removeSpaces($event,f.expiringRevenue)"
                                              [attr.disabled]="true" />
                                          </div>
                                        </div>
                                      </div>
                                      (change)="onExpectedBoundRevuneChange()" -->
                                  <!-- <div class="row-detail has-validation">
                                    <div class="lbl-title edt">
                                      <label class="form-label">Proj Rev <span>*</span></label>
                                    </div>
                                    <div class="des">
                                      <div class="pos-re">
                                        <span class="dollor-view">$</span>
                                        <input class="edit-control error dollor-input-view"
                                          [ngClass]="{error:f.targetRevenue.invalid && isSubmitted}"
                                          formControlName="targetRevenue" (keypress)="ValidateKey.integer($event, 15)"
                                          (focusout)="addCurrencyFormat($event,f.targetRevenue)" maxlength="21"
                                          (focus)="removeCurrencyFormat($event, f.targetRevenue)"
                                          [ngClass]="{'isDisabled':wbaView?.wbaStatusDescription == 'Won' || wbaView?.wbaStatusDescription == 'Lost' || wbaView?.outcome == 'Bound' }"
                                          (focusout)="removeSpaces($event,f.targetRevenue)" />
                                      </div>
                                      <div class="display-error">
                                        <div *ngIf="f.targetRevenue.invalid && isSubmitted">
                                          <span *ngIf="f.targetRevenue.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen.targetRevenue.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-detail has-validation">
                                    <div class="lbl-title edt">
                                      <label class="form-label">Lead Generator <span
                                          *ngIf="wbaView?.transType == 'NB'">*</span></label>
                                    </div>
                                    <div class="des">
                                      <div class="flex-div auto-comp-wd">
                                        <p-autoComplete class="auto-comp-view" placeholder="Lead Generator"
                                          formControlName="leadGeneratorId"
                                          [ngClass]="{error:f.leadGeneratorId.invalid && isSubmitted}"
                                          [showEmptyMessage]="true" [suggestions]="leadGenratorlist" [minLength]="1"
                                          (onSelect)="
                                        selectItem($event, 'leadGenerator')
                                      " (onClear)="lookUpDataFilterChanges('LeadGenerator')" (completeMethod)="
                                        getCompanyList('leadGenerator', $event)
                                      " (onDropdownClick)="
                                        getCompanyList('leadGenerator', $event)
                                      " field="value">
                                          <ng-template let-generator pTemplate="item">
                                            <div class="company-item">
                                              <div>{{ generator.name }}</div>
                                            </div>
                                          </ng-template>
                                        </p-autoComplete>
                                        <img (click)="openLookupModal('LeadGenerator')" src="assets/images/search.svg"
                                          alt="" class="ms-2" />
                                      </div>
                                      <div class="display-error">
                                        <div *ngIf="f.leadGeneratorId.invalid && isSubmitted">
                                          <span *ngIf="f.leadGeneratorId.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen
                                            .leadGenerator.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="display-error">
                                        <span
                                          *ngIf="invalidLeadGerator?.id == '' && !f.leadGeneratorId.errors?.required && wbaView?.transType == 'NB'">
                                          {{ _AddWhiteBoardScreen.leadGenerator.leadgenratorInvalid }}
                                        </span>
                                        <span *ngIf="leadGeneratorNotBlankButInvalid">
                                          {{ _AddWhiteBoardScreen.leadGenerator.leadgenratorInvalid }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-detail has-validation">
                                    <div class="lbl-title edt">
                                      <label class="form-label">Expiring Premium </label>
                                    </div>
                                    <div class="des">
                                      <div class="pos-re">
                                        <span class="dollor-view">$</span>
                                        <input class="edit-control error dollor-input-view"
                                          [ngClass]="{error:f.expiringPremium.invalid && isSubmitted}"
                                          [ngClass]="{'isDisabled':wbaView?.transType=='RN'}"
                                          formControlName="expiringPremium"
                                          (focusout)="addCurrencyFormat($event,f.expiringPremium)"
                                          (keypress)="ValidateKey.integer($event, 15)"
                                          (focus)="removeCurrencyFormat($event,f.expiringPremium)"
                                          (focusout)="removeSpaces($event,f.expiringPremium)"
                                          (change)="getExpiringRevenueCalculation(selectedValue,f.expiringPremium.value,f.targetPremium.value)" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row-detail has-validation">
                                    <div class="lbl-title edt">
                                      <label class="form-label">Owner <span>*</span></label>
                                    </div>
                                    <div class="des">
                                      <div class="flex-div auto-comp-wd">
                                        <p-autoComplete class="form-control auto-comp" placeholder="Owner"
                                          formControlName="ownerName" [showEmptyMessage]="true" [minLength]="1"
                                          (onSelect)="selectItem($event,'ownerName')"
                                          (onClear)="lookUpDataFilterChanges('Owner')" [suggestions]="ownerlist"
                                          (completeMethod)="getOwnerlist($event)" field="value">
                                          <ng-template let-owner pTemplate="item">
                                            <div class="company-item">
                                              <div>{{owner.userName}}</div>
                                            </div>
                                          </ng-template>
                                        </p-autoComplete>
                                        <img (click)="openLookupModal('Owner')" src="assets/images/search.svg" alt=""
                                          class="ms-2">
                                      </div>
                                      <div class="display-error">
                                        <div *ngIf="f.ownerName.invalid && isSubmitted">
                                          <span *ngIf="f.ownerName.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen.owner.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="display-error">
                                        <span *ngIf="invalidowner?.id == '' && !f.ownerName.errors?.required">
                                          {{ _AddWhiteBoardScreen.owner.ownerInvalid }}
                                        </span>
                                      </div>
                                    </div>
                                  </div> -->



                                  <!--
                                <div class="frmrow add-wba-form has-validation">

                                  <label class="form-label">Account Manager<span></span></label>

                                  <div class="inputControl">
                                    <div class="flex-div">
                                      <p-autoComplete
                                        [ngClass]="{'red' : isAccountManager, 'am_dis': !isAccountManager}"
                                        placeholder="Account Manager" class="form-control auto-comp"
                                        [showEmptyMessage]="true" [suggestions]="accountManagerlist" field="value"
                                        [minLength]="1" (onSelect)="selectItem($event,'accountManagerUserId')"
                                        (onClear)="clearValueForAccount()"
                                        (onClear)="lookUpDataFilterChanges('accountManager')"
                                        (onDropdownClick)="getCompanyList('accountManager',$event)"
                                        (completeMethod)="getCompanyList('accountManager',$event)"
                                        formControlName="accountManagerUserId">
                                        <ng-template let-account pTemplate="item">
                                          <div class="company-item">
                                            <div>{{ account.userName }}</div>
                                          </div>
                                        </ng-template>
                                      </p-autoComplete>
                                      <img [ngClass]="{'red' : isAccountManager, 'am_hid': !isAccountManager}"
                                        src="assets/images/search.svg" (click)="openLookupModal('AccountManager')"
                                        alt="" class="ms-2" />
                                    </div>
                                    <div class="display-error">
                                      <span *ngIf="invalidAccountManager">
                                        {{ _AddWhiteBoardScreen.accountManager.accountManagerInvalid }}
                                      </span>
                                    </div>
                                  </div>
                                </div> -->








                                </div>
                                <div class="col-12 col-sm-12 insured">
                                  <h2>Additional Information</h2>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  col-xxl-3 "
                                  *ngIf="isRenewalStatusVisible=='RN'">
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Renewal Status <span>*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select"
                                        [ngClass]="{error:f.renewalStatusId.invalid && isSubmitted}"
                                        formControlName="renewalStatusId">
                                        <option value="">
                                          Select Renewal Status
                                        </option>
                                        <option *ngFor="let renewalStatus of renewalStatusList"
                                          value="{{ renewalStatus.id }}">
                                          {{ renewalStatus.displayName }}
                                        </option>
                                      </select>
                                      <div class="display-error">
                                        <div *ngIf="f.renewalStatusId.invalid && isSubmitted">
                                          <span *ngIf="f.renewalStatusId.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen.renewalStatus.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  col-xxl-3 ">
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Profit Center<span></span></label>

                                    <div class="inputControl">
                                      <select class="form-select" formControlName="profitCenter">
                                        <option disabled value="">
                                          Select
                                        </option>
                                        <option value="Lamb">Lamb</option>
                                        <option value="CREIS">CREIS</option>
                                        <option value="TruePartners">TruePartners</option>
                                        <option value="TruePartners - Wheels">TruePartners - Wheels</option>
                                      </select>
                                    </div>
                                  </div>

                                </div>
                                <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 ">
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Opportunity Approved</label>

                                    <div class="inputControl">
                                      <select class="form-select" formControlName="opportunityApproved">
                                        <option disabled value="">
                                          Select
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                      </select>
                                    </div>
                                  </div>
                                </div> -->
                                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  col-xxl-3 ">
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Lead Generator <span
                                        *ngIf="wbaView?.transType == 'NB'">*</span></label>

                                    <div class="inputControl">
                                      <div class="flex-div">
                                        <p-autoComplete class="form-control" placeholder="Lead Generator"
                                          formControlName="leadGeneratorId"
                                          [ngClass]="{error:f.leadGeneratorId.invalid && isSubmitted}"
                                          [showEmptyMessage]="true" [suggestions]="leadGenratorlist" [minLength]="1"
                                          (onSelect)="
                                            selectItem($event.value, 'leadGenerator')
                                          " (onClear)="lookUpDataFilterChanges('LeadGenerator')" (completeMethod)="
                                            getCompanyList('leadGenerator', $event)
                                          " (onDropdownClick)="
                                            getCompanyList('leadGenerator', $event)
                                          " field="value">
                                          <ng-template let-generator pTemplate="item">
                                            <div class="company-item">
                                              <div>{{ generator.name }}</div>
                                            </div>
                                          </ng-template>
                                        </p-autoComplete>
                                        <img (click)="openLookupModal('LeadGenerator')" src="assets/images/search.svg"
                                          alt="" class="ms-2" />
                                      </div>
                                      <div class="display-error">
                                        <div *ngIf="f.leadGeneratorId.invalid && isSubmitted">
                                          <span *ngIf="f.leadGeneratorId.errors?.required">
                                            {{
                                            _AddWhiteBoardScreen
                                            .leadGenerator.required
                                            }}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="display-error">
                                        <span
                                          *ngIf="invalidLeadGerator?.id == '' && !f.leadGeneratorId.errors?.required && wbaView?.transType == 'NB'">
                                          {{ _AddWhiteBoardScreen.leadGenerator.leadgenratorInvalid }}
                                        </span>
                                        <span *ngIf="leadGeneratorNotBlankButInvalid">
                                          {{ _AddWhiteBoardScreen.leadGenerator.leadgenratorInvalid }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  col-xxl-3 ">


                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Result<span></span></label>

                                    <div class="inputControl">
                                      <select class="form-select" formControlName="epicStatusId">
                                        <option disabled value="">
                                          Select Result
                                        </option>
                                        <!-- [selected]="bs.id == wbaSaveLostReqNotifire" -->
                                        <option *ngFor="let bs of epicStatus" [value]="bs.id">
                                          {{ bs.epicStatusName }}
                                        </option>
                                      </select>
                                      <div class="display-error"></div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3  col-xxl-3 ">
                                  <div class="frmrow add-wba-form has-validation">

                                    <label class="form-label">Progress <span class="star-color">*</span></label>

                                    <div class="inputControl">
                                      <select class="form-select" [class.isDisabled1]="wbaView?.transType == 'RN'"
                                        [ngClass]="{error:f.progressId.invalid && isSubmitted}"
                                        formControlName="progressId">
                                        <option disabled value="">
                                          Select progress
                                        </option>
                                        <option *ngFor="let bs of progress" value="{{ bs.id }}">
                                          {{ bs.progressName }}
                                        </option>
                                      </select>
                                      <div class="display-error">
                                        <div *ngIf="f.progressId.invalid && isSubmitted">
                                          <span *ngIf="f.progressId.errors?.required">
                                            {{ _AddWhiteBoardScreen.progressId.required }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4  col-xxl-4 ">
                                  <div class="frmrow add-wba-form" *ngIf="wbaView?.transType == 'NB'">
                                    <div class="flex-div align-items-center">
                                      <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                          formControlName="borInDiscussion" (change)="changeBorInDiscussion()"
                                          [checked]="wbaView?.borInDiscussion"
                                          [ngClass]="{'isDisabled':wbaView?.borInDiscussionEnable}"
                                          id="flexCheckDefault" />
                                      </div>
                                      <div class="Bor_Label">
                                        <label class="form-label mb-0" for="flexCheckDefault" [ngClass]="{'isDisabled':wbaView?.borInDiscussionEnable
                                        }">BOR in Discussion</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>



                                <div class="card-text">
                                  <div>
                                    <ng-container *ngIf="activeTab=='Insured Details'">
                                      <button class="btn btn-primary float-right mb-3"
                                        [disabled]="isBtnDisabled || wbaView?.outcome == 'Lost' || wbaView?.outcome == 'Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'"
                                        (click)="saveInsuredDetails()">
                                        Save </button>
                                      <!-- wbaView?.outcome == 'Bind Request' -->
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <!-- For WBA Insured details tab ends -->
                          <!-- For WBA Details tab starts -->
                          <ng-container *ngIf="activeTab === whTabsList[1].tabName">
                            <div class="wh-detail">

                              <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12  col-xxl-12   p-0">
                                <div class="default-window" [ngClass]="{'maxWindow': isMaxMin  ? '': !isMaxMin}">
                                  <div class="max-btn">

                                    <button class="mx-btn" (click)="maxWindow(isMaxMin)" *ngIf="!isMaxMin"><img
                                        src="assets/images/minimize.svg"></button>


                                    <button class="mx-btn min-btn" (click)="maxWindow(isMaxMin)" *ngIf="isMaxMin"><img
                                        src="assets/images/maximize.svg"></button>
                                  </div>
                                  <div class="flex-div win-con">


                                    <div class="note-outer-block" id="nt">

                                      <app-policydetail [ApplicationData]="whiteBoardForm.value"
                                        (policyDescriptionchangeValue)="handlePolicyDescriptionchangeValue($event)"
                                        (policyDescriptionValue)="handlePolicyDescriptionValue($event)"
                                        [businessLineData]="businessLine" [policyTypeListData]="policyTypeList"
                                        [transType]="wbaView?.transType" [businessLineIdfromApp]="emittedbusinessLineId"
                                        [selectedValue]="selectedValue"
                                        (isAssesmentPopUp)="getCoverageAssementInfo($event)"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[0].label"
                                        *ngIf="currentTab === tabs[0].label">
                                      </app-policydetail>

                                      <!-- <app-additional-information *ngIf="currentTab === tabs[1].label"
                                        [businessLineIdfromApp]="emittedlobIdForSpeciality"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[1].label">
                                      </app-additional-information> -->

                                      <app-coverage-checklist [ApplicationData]="whiteBoardForm.value"
                                        [tierIdfromApp]="selectedTierValue" [industryIdfromApp]="selectedIndustryValue"
                                        [masterCoverageListByLineTierAndIndustry]="masterCoverageListByLineTierAndIndustry"
                                        [CoverageAssessmentData]="CoverageAssessmentData"
                                        [businessLineIdfromApp]="emittedbusinessLineId"
                                        *ngIf="currentTab === tabs[1]?.label && wbaView?.transType == 'NB'"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[1]?.label"
                                        (isAssesmentPopUp)="setIsAssesmentPopup($event)"
                                        (coverageCheckListPayload)="getCoverageCheckListPayload($event)"
                                        (noMasterCovData)="getnoMasterCovData($event)"
                                        (handleCheckListIconStatus)="handleAcordIconStatus($event)">
                                      </app-coverage-checklist>

                                      <app-coverage-assessment [ApplicationData]="whiteBoardForm.value"
                                        [tierId]="tierId" [epicUniquePolicyId]="epicUniquePolicyId"
                                        [isRenewalStatusVisible]="isRenewalStatusVisible"
                                        [CoverageAssessmentData]="CoverageAssessmentData" [auditDone]="auditDone"
                                        *ngIf="currentTab === tabs[1]?.label && wbaView?.transType == 'RN'"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[1]?.label"
                                        (assessmentResponse)="assessmentResponse($event)"
                                        (assessmentPayload)="getAssessmentPayload($event)"
                                        (isAssesmentParamsHasMandatoryValues)="saveAndNextAssessment($event)"
                                        [masterCoverageListByLineTierAndIndustry]="masterCoverageListByLineTierAndIndustry"
                                        (handleCovAssessmentIconStatus)="handleAcordIconStatus($event)">
                                      </app-coverage-assessment>

                                      <app-coverage-assessment-nb [ApplicationData]="whiteBoardForm.value"
                                        [tierId]="tierId" [epicUniquePolicyId]="epicUniquePolicyId"
                                        [isRenewalStatusVisible]="isRenewalStatusVisible"
                                        [CoverageAssessmentData]="CoverageAssessmentData" [auditDone]="auditDone"
                                        *ngIf="currentTab === tabs[2]?.label && wbaView?.transType == 'NB'"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[2]?.label"
                                        (assessmentResponse)="assessmentResponse($event)"
                                        (assessmentPayload)="getAssessmentPayload($event)"
                                        (isAssesmentParamsHasMandatoryValues)="saveAndNextAssessment($event)"
                                        (handleAssessmentIconStatus)="handleAcordIconStatus($event)">
                                      </app-coverage-assessment-nb>

                                      <app-acord *ngIf="currentTab === tabs[3]?.label && wbaView?.transType == 'NB'"
                                        [tabName]="tabs[3]?.label" [sdrsmTab]="tabs[5]?.label"
                                        (nextTab)="handleEventFromChild($event)"
                                        (setCreatedAcordId)="setCreatedAcordId($event)" [selectedValue]="selectedValue"
                                        (setUpdatedProgressId)="setUpdatedProgressId($event)" [wbaView]="wbaView"
                                        [isAssesmentParamsHasMandatoryValues]="isAssesmentParamsHasMandatoryValues"
                                        (openBor)="openBorFromAcord($event)" (borValue)="enableBor($event)"
                                        (handleAcordIconStatus)="handleAcordIconStatus($event)">
                                      </app-acord>

                                      <app-account-executive *ngIf="currentTab === 'AE Status'"
                                        [tabName]="tabs[2]?.label" (nextTab)="handleEventFromChild($event)"
                                        (setCreatedAcordId)="setCreatedAcordId($event)" [selectedValue]="selectedValue"
                                        (setUpdatedProgressId)="setUpdatedProgressId($event)" [wbaView]="wbaView"
                                        [isAssesmentParamsHasMandatoryValues]="isAssesmentParamsHasMandatoryValues"
                                        (handleAEIconStatus)="handleAcordIconStatus($event)"
                                        [AcordPermissionResponse]="AcordPermissionResponse"></app-account-executive>

                                      <app-submit-to-marketing
                                        *ngIf="currentTab === tabs[4]?.label && wbaView?.transType == 'NB'"
                                        [tabName]="tabs[4].label" [PrevtabName]="tabs[3].label"
                                        (nextTab)="handleEventFromChild($event)" [acordId]="acordId"
                                        (setUpdatedProgressId)="setUpdatedProgressId($event)"
                                        [selectedValue]="selectedValue"
                                        [isAssesmentParamsHasMandatoryValues]="isAssesmentParamsHasMandatoryValues"
                                        (handleMarketingIconStatus)="handleAcordIconStatus($event)">
                                      </app-submit-to-marketing>


                                      <app-rsm-approval
                                        *ngIf="currentTab === tabs[5]?.label && wbaView?.transType == 'NB'"
                                        (nextTab)="handleEventFromChild($event)"
                                        (setUpdatedProgressId)="setUpdatedProgressId($event)" [tabName]="tabs[5].label"
                                        [selectedValue]="selectedValue" [acordTab]="tabs[3].label"
                                        [isAssesmentParamsHasMandatoryValues]="isAssesmentParamsHasMandatoryValues"
                                        (handleRsmIconStatus)="handleAcordIconStatus($event)">
                                      </app-rsm-approval>
                                      <!-- <app-account-manager
                                        *ngIf="currentTab === tabs[5].label && wbaView?.transType == 'RN'"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[5].label"
                                        [selectedValue]="selectedValue"
                                        (setUpdatedProgressId)="setUpdatedProgressId($event)" [acordTab]="tabs[3].label"
                                        [isAssesmentParamsHasMandatoryValues]="isAssesmentParamsHasMandatoryValues"
                                        (handleAMIconStatus)="handleAcordIconStatus($event)">
                                      </app-account-manager> -->

                                      <app-submission
                                        *ngIf="wbaView?.transType == 'NB' ? currentTab === tabs[6]?.label : currentTab === tabs[3]?.label"
                                        [wbaOutcome]="wbaView?.outcome"
                                        [wbaReviewStatus]="wbaReviewStatusArray?.wbaReviewStatus"
                                        [AcordPermissionResponse]="AcordPermissionResponse"
                                        (setUpdatedProgressId)="setUpdatedProgressId($event)"
                                        [wbaAcordProgress]="wbaAcordProgress"
                                        (emitSendToSelectedFlag)="hanldeSendToSelectedFlag($event)"
                                        [isAssesmentParamsHasMandatoryValues]="isAssesmentParamsHasMandatoryValues"
                                        (handleSubmIconStatus)="handleAcordIconStatus($event)">
                                      </app-submission>

                                      <!-- <app-account-management-services *ngIf="currentTab === tabs[7].label"
                                        (nextTab)="handleEventFromChild($event)" [tabName]="tabs[7].label">
                                      </app-account-management-services> -->

                                      <app-binding *ngIf="currentTab === tabs[8]?.label" [wbaView]="wbaView">
                                      </app-binding>
                                      <app-epicpolicyd-details *ngIf="currentTab === tabs[9]?.label">
                                      </app-epicpolicyd-details>


                                      <div class="max-window-btn"><button class="btn btn-primary "
                                          (click)="saveWBA('exit')"
                                          [disabled]="wbaView?.outcome == 'Lost' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'">
                                          Save </button></div>

                                    </div>
                                  </div>
                                  <div class="btn-blk-wh">
                                    <ng-container *ngIf="activeTab=='WBA Details'">
                                      <button class="btn btn-outline-primary me-3" (click)="saveWBA('exit')"
                                        [disabled]="wbaView?.outcome == 'Lost' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'"
                                        *ngIf="currentTab != 'Marketing Review' && currentTab !='CE Status' && currentTab != 'AE Status' && currentTab != 'Account Manager' && currentTab !='Coverage Assessment' && currentTab !='Coverage Assessment ' && currentTab !='SD Approval' && currentTab !='Submissions' && currentTab !='Coverage Checklist'  && currentTab !='Marketing Summary' && currentTab !='Proposals' && currentTab != 'Binding' && currentTab != 'EPIC Policy Details'">
                                        Save &
                                        Exit
                                      </button>
                                      <button
                                        *ngIf="((currentTab === 'Coverage Assessment' || currentTab === 'Coverage Assessment ') && wbaView.transType == 'NB')"
                                        class="btn btn-highlight me-3"
                                        [disabled]="wbaView?.outcome == 'Lost'|| wbaView?.outcome=='Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost' || noMasterCov"
                                        (click)="saveWBA('ShowAuditWizard')"> Show Audit Wizard </button>
                                      <button
                                        *ngIf="((currentTab === 'Coverage Assessment' || currentTab === 'Coverage Assessment ' ) && wbaView.transType == 'NB')"
                                        class="btn btn-highlight me-3"
                                        [disabled]="wbaView?.outcome == 'Lost' || wbaView?.outcome=='Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost' || noMasterCov"
                                        (click)="saveWBA('RunAuditWizard')"> Run Audit Wizard </button>
                                      <button
                                        *ngIf="((currentTab === 'Coverage Assessment' || currentTab === 'Coverage Assessment ') && wbaView.transType == 'NB')"
                                        class="btn btn-primary me-3"
                                        [disabled]="wbaView?.outcome == 'Lost'|| wbaView?.outcome=='Bound' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost' || noMasterCov"
                                        (click)="saveWBA('SaveOnly')"> Save </button>

                                      <button class="btn btn-primary " (click)="saveWBA('next')" [disabled]="(((currentTab=='Coverage Checklist' || currentTab=='Coverage Assessment') && this.masterCoverageListByLineTierAndIndustry.length ==0) && noMasterCov) || (wbaView?.outcome=='Lost' || wbaView?.outcome=='Bound' || wbaView?.wbaStatusDescription=='Won' ||
                                      wbaView?.wbaStatusDescription=='Lost')"
                                        *ngIf="currentTab != 'Marketing Review' && currentTab !='CE Status' && currentTab != 'AE Status' && currentTab != 'Account Manager' && currentTab !='SD Approval' && currentTab !='Submissions' && currentTab !='Marketing Summary' && currentTab !='Proposals' && currentTab != 'Binding' && currentTab != 'EPIC Policy Details' && currentTab != 'Account Management Services'">
                                        {{ wbaView.transType == 'RN' && currentTab === 'Coverage Assessment' ? 'Next'
                                        :'Save & Next'}}
                                      </button>

                                      <button class=" btn btn-primary ms-3"
                                        *ngIf="currentTab === 'Submissions' && isDisplaySubmissionSendButton"
                                        (click)="saveWBA('send')" [disabled]="wbaView?.outcome == 'Lost' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost' || (wbaReviewStatusArray?.wbaReviewStatus != 'Complete' && wbaAcordProgress == true && wbaView?.transType === 'NB') || (wbaView?.outcome === 'Bound' || wbaView?.outcome === 'Lost' || wbaView?.outcome === 'Bind Request')
                                        || (isAssesmentParamsHasMandatoryValues && wbaView?.transType == 'NB' ) ||
                                        (!issubmissionRequest && wbaView?.transType == 'RN')">Send
                                        to selected</button>

                                      <button class="btn btn-primary ms-3" *ngIf="currentTab === 'EPIC Policy Details'"
                                        [disabled]="wbaView?.outcome == 'Lost' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'"
                                        (click)="saveWBA('save')">Save</button>

                                      <!-- <button class="btn btn-primary ms-3"
                                        *ngIf="currentTab === 'Account Management Services'"
                                        [disabled]="wbaView?.outcome == 'Lost' || wbaView?.wbaStatusDescription == 'Won'|| wbaView?.wbaStatusDescription =='Lost'"
                                        (click)="saveWBA('next')">Next</button> -->
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <!-- For WBA Details tab ends -->
                          <!-- For Email Tab starts -->
                          <div *ngIf="activeTab === whTabsList[2].tabName">
                            <div class="row">
                              <div class="col-12 wbaHeadding">
                                <div class="hd-width">
                                  <h2>Email</h2>
                                  <div class="flex-div">
                                    <a href="javascript:void(0)" data-bs-toggle="collapse" role="button"
                                      aria-expanded="false" aria-controls="collapseExample">
                                      <label (click)="getWBAEmailListClick()">Refresh <span><img
                                            src="assets/images/refresh.svg" alt="" class="refPos" /></span></label>
                                    </a>
                                    <a class="float-right  ms-3" href="javascript:void(0)" data-bs-toggle="collapse"
                                      role="button"
                                      [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Won'}"
                                      aria-expanded="false" aria-controls="collapseExample">
                                      <label (click)="openSendEmailModal()">Send Email <span><img
                                            src="assets/images/send.svg" alt="" class="refPos1" /></span></label>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-12">
                                <kendo-grid [data]="gridData" [skip]="skip" [sortable]="true" [sort]="sort" [virtualColumns]="true"
                                  (sortChange)="sortChange($event)" [sortable]="{
                            allowUnsort: false,
                            mode: multiple ? 'multiple' : 'single'
                          }" [filterable]="true" [resizable]="true" [filter]="gridFilter"
                                  (filterChange)="filterwbaEmailList($event)"
                                  [pageable]="{ pageSizes: true, position: 'top' } " (pageChange)="pageChange($event)"
                                  [pageSize]="wbaEmailListSize" [skip]="skip">
                                  <ng-template kendoPagerTemplate let-totalPages="totalPages"
                                    let-currentPage="currentPage">
                                    <div class="flex-div justify-content-between grid-hd">
                                      <div class="flex-div">
                                        <kendo-textbox placeholder="Search" #searchText
                                          (input)="searchValue(searchText.value)"
                                          [style.width.px]="205"></kendo-textbox><img src="assets/images/search.svg"
                                          (click)="onFilter()" alt="" class="ms-2" />
                                      </div>

                                      <div class="flex-div">
                                        <div class="flex-div">
                                          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                                          <kendo-pager-numeric-buttons
                                            [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
                                          <kendo-pager-next-buttons></kendo-pager-next-buttons>

                                          <kendo-pager-page-sizes [pageSizes]="sizesEmailList"></kendo-pager-page-sizes>
                                          <kendo-grid-messages [pagerItemsPerPage]="'Items per page'">
                                          </kendo-grid-messages>
                                        </div>
                                        <kendo-pager-info class="bd-l"></kendo-pager-info>
                                      </div>
                                    </div>
                                    <!-- <button [ngClass]="{ actbtn: isFilter }" (click)="columnFilter(isFilter)"
                                class="filter-button">
                                <img src="assets/images/filter.svg" alt="" class="flt-icon" />
                              </button> -->
                                  </ng-template>
                                  <kendo-grid-column field="emailDate" title="Date" [minResizableWidth]="110"
                                    [maxResizableWidth]="150" [width]="120" filter="date">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                      {{
                                      dataItem.emailDate | date:'MM/dd/yyyy'
                                      }}
                                    </ng-template>
                                  </kendo-grid-column>
                                  <kendo-grid-column field="emailTime" title="Time" [minResizableWidth]="110"
                                    [maxResizableWidth]="150" [width]="120">
                                  </kendo-grid-column>
                                  <kendo-grid-column field="from" title="From Email" [filterable]="true"
                                    [minResizableWidth]="500" [maxResizableWidth]="800" [width]="180">
                                  </kendo-grid-column>

                                  <kendo-grid-column field="to" title="To Email" [filterable]="true"
                                    [minResizableWidth]="500" [maxResizableWidth]="800" [width]="230">

                                  </kendo-grid-column>
                                  <kendo-grid-column field="subject" title="Subject" [filterable]="true"
                                    [minResizableWidth]="180" [maxResizableWidth]="800" [width]="300">
                                  </kendo-grid-column>
                                  <kendo-grid-column field="isAttachment" title="Attachment" filter="boolean"
                                    [minResizableWidth]="500" [maxResizableWidth]="800" [width]="150">
                                    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
                                    </kendo-grid-messages>
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                      {{
                                      dataItem?.isAttachment === true
                                      ? "Yes"
                                      : dataItem?.isAttachment === false
                                      ? "No"
                                      : dataItem?.isAttachment
                                      }}
                                    </ng-template>
                                  </kendo-grid-column>
                                  <kendo-grid-column field="Actions" title="View" [minResizableWidth]="500"
                                    [maxResizableWidth]="800" [width]="80" [filterable]="false" [sortable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                      <div class="cr-wd">
                                        <a href="{{dataItem.blobURL}}" target="_blank"><label
                                            style="text-align:center">View<span><img src="../../assets/images/eye.svg"
                                                class="wba_img" alt="" /></span>
                                          </label></a>
                                      </div>
                                    </ng-template>
                                  </kendo-grid-column>
                                </kendo-grid>
                              </div>
                            </div>
                          </div>
                          <!-- For Email Tab ends -->
                          <!-- For Notes Tab starts -->
                          <ng-container *ngIf="activeTab === whTabsList[3].tabName">

                            <div class="row">

                              <div class="col-12 wbaHeadding">
                                <div class="hd-width">
                                  <h2>Internal Notes</h2>
                                  <div class="note-action">
                                    <a href="javascript:void(0)" (click)="openNoteModal()"
                                      [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Won'}">
                                      <span><img src="assets/images/plus.svg" alt="" /></span><label class="ps-2">Add
                                        Note</label></a>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-sm-12 col-md-12">
                                <ng-container *ngIf="notesList.length > 0">
                                  <ng-container *ngFor="let item of notesList let i=index;">

                                    <div class="noteBlock ">

                                      <div class="notesView">
                                        <div>
                                          <p class="bold">
                                            Topic : {{item.topic}}
                                          </p>
                                          <!-- <div class="py-1 pb-2">

                                            <p #textbox [class.show-less]="isExpand1 === false">{{item.description}}</p>

                                            <a (click)="toggle(i)"
                                              *ngIf="textbox.offsetHeight < textbox.scrollHeight || (isExpand1 === false && textbox.offsetHeight > 40)">{{
                                              anchor }}</a>
                                          </div> -->
                                          <div class="pt-2">
                                            <p
                                              [ngClass]="{'readMore' : item.isSelected , 'readMoreClose' : !item.isSelected }">
                                              {{item.description}}
                                            </p>
                                            <ng-container *ngIf="item.description.length > 465">
                                              <a *ngIf="!item.isSelected" class="readMore-a" href="javascrip:void(0)"
                                                (click)="readMore(i)"><u>More</u>
                                              </a>
                                              <a *ngIf="item.isSelected" class="readMore-a" href="javascrip:void(0)"
                                                (click)="readMore(i)">
                                                <u>Less</u></a>
                                            </ng-container>
                                          </div>
                                          <div class="flex-div">
                                            <div class="me-4">
                                              <span><img src="assets/images/dateIcon.svg" alt="" class="me-2">
                                                {{item.recordDate | date : 'MM/dd/YYYY'}} at {{ item.recordDate |
                                                date : 'hh:mm a' }}</span>
                                            </div>

                                            <div>
                                              <span><img src="assets/images/user.svg" alt="" width="11px"
                                                  class=" me-2">By
                                                {{item.createdBy}}
                                              </span>
                                            </div>

                                          </div>
                                        </div>
                                        <div class="flex-div justify-content-end not-icons notesDate">
                                          <p class="ms-3" (click)="editNoteModal(item)"><img title="Edit"
                                              [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Won'}"
                                              src="assets/images/edit.svg" alt="Edit" width="14">
                                          </p>

                                          <p class="ms-3" (click)="cancel(item.id)"><img title="Delete"
                                              [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Won'}"
                                              src="assets/images/trash.svg" alt="Delete" width="13"></p>

                                        </div>
                                      </div>

                                    </div>
                                  </ng-container>


                                </ng-container>

                                <ng-container *ngIf="notesList.length == 0">
                                  <p class="noRcd">No Records Found.</p>
                                </ng-container>

                                <!-- <ng-container *ngFor="let item of notesList">
                                      <div class="col-md-12 p-0 mb-3 notesView">
                                        <div class="flex-div p-2 border-bottom">
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-0">
                                            <span><img src="assets/images/calendar.svg" alt="" class="me-2">
                                              {{item.recordDate | date : 'MM/dd/YYYY'}}</span>
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-0">
                                            <span> <img src="assets/images/schedule.svg" alt="" class="me-2">
                                              {{ item.recordDate | date : 'hh:mm a' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-0">
                                            <span><img src="assets/images/user.svg" alt="" width="11px" class=" me-2">By
                                              {{item.createdBy}}
                                            </span>
                                          </div>
                                          <div class="col-8 col-sm-8 col-md-8 col-lg-8 p-0">
                                            <div class="flex-div justify-content-end not-icons">
                                              <p class="ms-3" (click)="editNoteModal(item)"><img title="Edit"
                                                  [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Won'}"
                                                  src="assets/images/edit.svg" alt="Edit" width="14">
                                              </p>

                                              <p class="ms-3" (click)="cancel(item.id)"><img title="Delete"
                                                  [ngClass]="{'isDisabled':wbaView?.outcome == 'Lost' || wbaView?.outcome =='Won'}"
                                                  src="assets/images/trash.svg" alt="Delete" width="13"></p>

                                            </div>
                                          </div>
                                        </div>
                                        <div class="pt-2">
                                          <p>
                                            Topic : {{item.topic}}
                                          </p>
                                        </div>
                                        <div class="pt-2">
                                          <p>
                                            {{item.description}}
                                          </p>
                                        </div>

                                      </div>
                                    </ng-container>


                                  <ng-container *ngIf="notesList.length == 0">
                                    <p class="noRcd">No Records Found.</p>
                                  </ng-container> -->

                              </div>

                            </div>


                          </ng-container>
                          <!-- For Notes Tab ends -->
                          <!-- For Marketing Summery starts -->
                          <ng-container *ngIf="activeTab == whTabsList[4].tabName">
                            <app-quote></app-quote>
                          </ng-container>
                          <!-- For Marketing Summery ends -->

                          <!-- For Binding starts -->
                          <ng-container *ngIf="isHideEpicBindingTab">
                            <ng-container *ngIf="activeTab === whTabsList[5].tabName">
                              <app-binding [wbaView]="wbaView">


                              </app-binding>

                            </ng-container>
                            <!-- For Binding End -->

                            <!-- For EPic Policy starts -->
                            <ng-container *ngIf="activeTab === whTabsList[6].tabName">
                              <app-epicpolicyd-details [wbaView]="wbaView"></app-epicpolicyd-details>
                            </ng-container>
                            <!-- For EPic Policy End -->
                          </ng-container>

                          <!-- For Binding starts -->
                          <!-- <ng-container *ngIf="isHideEpicBindingTab">
                            <ng-container *ngIf="activeTab === whTabsList[5].tabName">
                              <app-binding [wbaView]="wbaView">


                              </app-binding>

                            </ng-container>
                          </ng-container> -->
                          <!-- For Binding End -->

                          <!-- For Submission View starts -->
                          <ng-container *ngIf="activeTab === 'Submission View'">

                            <div class="wh-detail">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12  col-xxl-12   p-0">
                                <div class="default-window" [ngClass]="{'maxWindow': !isMaxMin  ? '': isMaxMin}">
                                  <div class="max-btn">

                                    <button class="mx-btn" (click)="maxWindow(isMaxMin)" *ngIf="!isMaxMin"><img
                                        src="assets/images/minimize.svg"></button>


                                    <button class="mx-btn min-btn" (click)="maxWindow(isMaxMin)" *ngIf="isMaxMin"><img
                                        src="assets/images/maximize.svg"></button>
                                  </div>
                                  <app-submission-view></app-submission-view>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <!-- For Submission View End -->
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="wba-rht-panel">


          <img src="assets/images/collapsed-icon.png" alt="" class="arwicon" (click)="expand(isExpand)"
            *ngIf="!isExpand" />


          <img src="assets/images/expand-icon.png" alt="" class="arwicon" (click)="expand(isExpand)" *ngIf="isExpand" />

          <div class="wba-rht-panel-content" [ngClass]="{'expRht': !isExpand  ? '': isExpand}">
            <form [formGroup]="whiteBoardForm">
              <ul class="rht-more">
                <li class="menuItems">
                  <!-- <button (click)="onProposalButtonClick()"
                    [disabled]="wbaView?.outcome == 'Lost'|| wbaView?.outcome =='Won'">
                    <img src="assets/images/rht-1.png" alt="" /></button> -->
                  <button>
                    <img src="assets/images/rht-1.png" alt="" /></button>
                  <p>Proposal</p>
                  <div class="sub-menu">
                    <ul>
                      <li><a href="javascript:void(0)" (click)="openViewProposalModal()"><img class="me-2"
                            src="assets/images/saved.svg" alt="" />Saved Proposals</a></li>
                      <li><a href="javascript:void(0)" title="" (click)="onProposalButtonClick()"><img class="me-2"
                            src="assets/images/generate.svg" alt="Generate Proposal">Generate Proposal</a></li>
                    </ul>
                    <div class="sub-arw"></div>
                  </div>
                </li>
                <li>
                  <button (click)="openeFileModal()">
                    <img src="assets/images/rht-2.png" alt="" /></button>
                  <p>eFile</p>
                </li>
                <li>
                  <ng-container
                    *ngIf="(wbaView?.transType == 'NB' && (wbaView?.isBorDefined=='No' || wbaView?.isBorDefined=='' || wbaView?.isBorDefined=='null'))">
                    <button (click)="openCloneBorModal(wbaView)">
                      <img src="assets/images/rht-3.png" alt="" /></button>
                    <p>Create a Clone</p>
                  </ng-container>
                </li>
                <!-- <li>
                  <button (click)="executiveSummaryById()">
                    <img src="assets/images/rht-4.png" alt="" /></button>
                  <p>Executive Summary</p>
                </li> -->
                <!-- [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Lost' && DisableLostFlag}"  -->
                <!-- <li>
                  <ng-container>
                    <button [ngClass]="{ 'isDisabled':wbaView?.outcome == 'Bound'}" (click)="openOutcomeForms('Lost')">
                      <img src="assets/images/rht-5.png" alt="" />
                    </button>
                    <p>WBA Lost</p>
                  </ng-container>
                </li> -->




              </ul>
            </form>
          </div>

        </div>
      </div>

    </div>
  </div>

  <!-- Dialog for confirmation starts -->
  <p-dialog [header]="isCallOnGoing != 'callOnGoing' ? _confirmBox.header : _confirmBox.header2" [modal]="true"
    [(visible)]="_confirmBox.isVisible" [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }"
    [draggable]="false" [resizable]="false" [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
    <p>{{ _confirmBox.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
      <ng-container *ngIf="isCallOnGoing != 'callOnGoing'">
        <p-button (click)="_confirmBox.isVisible = false" label="No" styleClass="btn btn-outline-primary"></p-button>
        <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
      </ng-container>
      <ng-container *ngIf="isCallOnGoing == 'callOnGoing'">
        <p-button (click)="_confirmBox.isVisible = false" label="OK" styleClass="btn btn-primary"></p-button>
      </ng-container>
    </ng-template>
  </p-dialog>
  <!-- Dialog for confirmation ends -->

  <p-dialog header="Alert" [modal]="true" [(visible)]="_warningBox.isVisible" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [styleClass]="_warningBox.borderColor"
    *ngIf="_warningBox">
    <p>{{ _warningBox.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_warningBox.hasFooter">
      <p-button (click)="warningBoxCancel()" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </p-dialog>
  <p-dialog header="Alert" [modal]="true" [(visible)]="_warningBox1.isVisible" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false" [styleClass]="_warningBox1.borderColor"
    *ngIf="_warningBox1">
    <p>{{ _warningBox1.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_warningBox1.hasFooter">
      <p-button (click)="this._warningBox1.isVisible = false;" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog [header]="_warningBoxTabChange.header2" [modal]="true" [(visible)]="_warningBoxTabChange.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_warningBoxTabChange.borderColor" *ngIf="_warningBoxTabChange">
    <p>{{ _warningBoxTabChange.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_warningBoxTabChange.hasFooter">
      <p-button (click)="warningBoxTabChangeCancel()" label="OK" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog [header]="_confirmBoxForApplication.header" [modal]="true" [(visible)]="_confirmBoxForApplication.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_confirmBoxForApplication.borderColor" *ngIf="_confirmBoxForApplication">
    <p>{{ _confirmBoxForApplication.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBoxForApplication.hasFooter">
      <p-button (click)="cancelTabChangePopup()" label="No" styleClass="btn btn-outline-primary">
      </p-button>
      <p-button (click)="navigateToRoute()" label="Yes" styleClass="btn btn-primary"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog header="Alert" [modal]="true" [(visible)]="isAssesmentParamsHasMandatoryValuesPopup"
    [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
    [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
    <p>You have not entered the expiring or recommended value for mandatory coverage(s).</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
      <p-button (click)="isAssesmentParamsHasMandatoryValuesPopup = false" label="OK"
        styleClass="btn btn-outline-primary"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog [header]="_confirmBoxForClone.header2" [modal]="true" [(visible)]="_confirmBoxForClone.isVisible"
    [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '40vw' }" [draggable]="false" [resizable]="false"
    [styleClass]="_confirmBoxForClone.borderColor" *ngIf="_confirmBoxForClone">
    <p>{{ _confirmBoxForClone.message }}</p>
    <ng-template pTemplate="footer" *ngIf="_confirmBoxForClone.hasFooter">
      <p-button (click)="this._confirmBoxForClone.isVisible = false" label="OK" styleClass="btn btn-outline-primary">
      </p-button>
    </ng-template>
  </p-dialog>
</div>

