import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WhiteBoardActivityService } from '../../../services/white-board-activity.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteDetailsComponent } from '../quoteDetails/quoteDetails.component'
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MarketingNotesComponent } from '../marketing-notes/marketing-notes.component';
import { LookupCarrierComponent } from '../lookup-carrier/lookup-carrier.component';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { CustomeNumberPipe } from 'src/app/shared/pipes/number.pipe';
import { CustomeRemoveNumberPipe } from 'src/app/shared/pipes/remove-number.pipe';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { WBANotifierService } from '../../../services/wba-notifier.service';
import { DatacacheService } from '../../../services/datacache.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {
  @Output() setUpdatedProgressId: any = new EventEmitter<string>();
  _confirmBox: any;
  _userMessages: any;
  wbaSubmissionCarriers: any = [];
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  wbaId: number = 0;
  activeWbaId: any;
  wbaDetails: any
  coverageList: any[] = [];
  expiringCarrierlist: any[] = [];
  public Carrier: any;
  public editCarrier: any;
  public Wholesaler: any;
  public editWholesaler: any;
  showHideBoundCarrierValidationPopUp: boolean = false;
  private defaultMarketetingSummary: any = {
    id: 0,
    wbaId: 0,
    carrierId: 0,
    wholesalerId: 0,
    coverageId: '',
    quoteDate: '',
    quotedPremium: 0,
    quotedStatus: '',
  };
  isAddSubmitted: boolean = false;
  isEditSubmitted: boolean = false;
  isValidCarrier: boolean = false;
  public marketSummayForm: any = { ...this.defaultMarketetingSummary };
  public editMarketSummayForm: any = { ...this.defaultMarketetingSummary };
  public editIndex: number = -1;
  lossDirty: boolean = false;
  private lookupData: any;
  maximumDate = new Date();
  marketingSummaryList: any[] = [];
  isHide: boolean = false;
  wbaProgressChange: any;
  wbaResponse: any;
  AcordStatus: any;
  carrierDataLookupFilterData: string = "";
  editablecarrierDataLookupFilterData: string = "";
  WholesalerDataLookupFilterData: string = "";
  editableWholesalerDataLookupFilterData: string = "";
  lookupDataEdit = {
    carrier: "",
    wholesaler: ""
  }
  userDetails: any;
  isValidUser: boolean = false;
  AcordPermissionResponse: any;
  ismarketingSummaryRequest: boolean = false;
  ismarketingSummaryRequestcheck: boolean = false;
  AccessReviewResp: any;


  constructor(private _wbaService: WhiteBoardActivityService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private activeRouter: ActivatedRoute,
    public ValidateKey: KeyboardValidation,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _numberPipe: CustomeNumberPipe,
    private _removeNumberPipe: CustomeRemoveNumberPipe,
    private _addWBAService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService,
    private _dataCacheService: DatacacheService
  ) {
    this._confirmBox = new ConfirmBox()
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this.activeRouter.queryParams
      .subscribe(params => {
        if (params)
          this.activeWbaId = params.wbaId
      });
    this.marketSummayForm.wbaId = this.activeWbaId;
    this.editMarketSummayForm.wbaId = this.activeWbaId;
    this.userInformation();
    this.initializeMasterData();
    this._notifierService._mrktngSmryRefresh.subscribe((resp: any) => {
      if (resp == 'refresh') {
        this.onLostwbadetails();
      }
    })
    window.scroll(0, 0);
  }

  onLostwbadetails() {
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    if (this.wbaDetails) this.getWhiteBoardDetailsFromApi(this.activeWbaId);
    if (!this.AcordPermissionResponse.length) this.getAccordPermissionByWbaId();
    else this.checkAccessPermission();
  }

  //to get wba carriers list
  getWbaSubmissionCarrierListById(wbaId: any) {
    this._wbaService.getWbaSubmissionCarrierListById(wbaId).subscribe(resp => {
      this.wbaSubmissionCarriers = resp.wbaSubmissionCarrierList;
    })
  }

  // to display quote details popup
  handleAction(actionName: string, item: any) {
    if (actionName === 'quoteDetails') {
      this.openQuoteDetailsPopup(item);
    }
  }

  // quote details popup
  openQuoteDetailsPopup(item: any) {
    const modalRef = this.modalService.open(QuoteDetailsComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1',
    });

    modalRef.componentInstance.quoteData = item;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      if (resp == 'updateWbaSubmissionCarriers') {
        this.getMarketingSummaryList(this.activeWbaId)
      }
    })

  }

  // marketing notes popup
  openMarketingNotesModal(item: any) {
    const modalRef = this.modalService.open(MarketingNotesComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog',
    });

    modalRef.componentInstance.currentCarrier = item;
    modalRef.componentInstance.marketingSummaryList = this.marketingSummaryList;
    modalRef.closed.subscribe(resp => {

    })
  }

  saveQuote(action: string) { }

  GetMasterCoverageListByBusinessLineTierAndIndustryWbaId(businesslineId: number, industryId: number, tierId: number, wbaId: any) {
    this._wbaService.getMasterCovList().subscribe((res: any) => {
      this.coverageList = res;
    })
  }

  getWhiteBoardDetails(wbaId: any) {
    this._addWBAService.getWBAById(wbaId).subscribe(resp => {
      this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', resp.whiteBoardActivityInfo);
      this.wbaProgressChange = resp.whiteBoardActivityInfo.progressName;
      this.AcordPermissionResponse = this._dataCacheService.retrieveData('wba_AcordPermissionResponse');
      if (!this.AcordPermissionResponse.length) this.getAccordPermissionByWbaId();
      else this.checkAccessPermission();
      this._notifierService._isProgressStatusChange.next(this.wbaProgressChange);
      this._notifierService._setUpdatedProgressId.next(resp.whiteBoardActivityInfo.progressId);
      this.setUpdatedProgressId.emit(resp.whiteBoardActivityInfo.progressId);
    });
  }

  initializeMasterData(): void {
    this.wbaDetails = this._dataCacheService.retrieveData('wba_wbaDetailsInfo');
    this.wbaResponse = this._dataCacheService.retrieveData('wba_wbaResponse');
    this.AccessReviewResp = this._dataCacheService.retrieveData('wba_AcordResponse_reviewList');
    this.wbaSubmissionCarriers = this._dataCacheService.retrieveData('wba_Mrktsmry_carrierList');
    this.marketingSummaryList = this._dataCacheService.retrieveData('wba_Mrktsmry_marketingSummary');
    this.coverageList = this._dataCacheService.retrieveData('wba_Mrktsmry_masterCovList');
    if (!this.wbaDetails) this.getAllMasters();
    else this.getAccessReviewList();
    this.AcordPermissionResponse = this._dataCacheService.retrieveData('wba_AcordPermissionResponse');
    if (!this.AcordPermissionResponse.length) this.getAccordPermissionByWbaId();
    else this.checkAccessPermission();
  }

  getAllMasters() {
    let requestBody = {
      wbaId: this.activeWbaId,
      isRenewal: this.wbaDetails?.transType == 'NB' ? false : true
    };
    var wbaActivityList = this._wbaService.getWBAById(this.activeWbaId); //to getBusinessStatusList
    var reviewList = this._addWBAService.getAccessReviewList(requestBody); //to getBusinessStatusList
    var carrierList = this._wbaService.getWbaSubmissionCarrierListById(this.activeWbaId); //to get state list
    var summaryList = this._wbaService.getMarketingSummaryList(this.activeWbaId); //to get status list
    var mastercvList = this._wbaService.getMasterCovList();
    forkJoin([wbaActivityList, reviewList, carrierList, summaryList,
      mastercvList
    ]).subscribe((resp) => {
      if (resp) {
        this.wbaDetails = resp[0].whiteBoardActivityInfo;
        this.wbaResponse = resp[1].wbaReviews;
        this.wbaSubmissionCarriers = resp[2].wbaSubmissionCarrierList;
        this.marketingSummaryList = resp[3].marketingSummary;
        this.coverageList = resp[4];
        this.getAccessReviewList()
        this.setDataInCache();
      }
    })
  }

  setDataInCache() {
    this._dataCacheService.compressAndCacheData('wba_wbaDetailsInfo', this.wbaDetails);
    this._dataCacheService.compressAndCacheData('wba_wbaResponse', this.wbaResponse);
    this._dataCacheService.compressAndCacheData('wba_Mrktsmry_carrierList', this.wbaSubmissionCarriers);
    this._dataCacheService.compressAndCacheData('wba_Mrktsmry_marketingSummary', this.marketingSummaryList);
    this._dataCacheService.compressAndCacheData('wba_Mrktsmry_masterCovList', this.coverageList);
  }

  getAccessReviewList() {
    // let requestBody = {
    //   wbaId: this.activeWbaId,
    // };
    // this._addWBAService.getAccessReviewList(requestBody).subscribe(res => {
    //   if (res) {
    // this.wbaResponse = res?.wbaReviews;
    if (this.AccessReviewResp.wbaResponse == null) {
      this.getWhiteBoardDetailsFromApi(this.activeWbaId)
    }
    if (this.wbaResponse?.length == 0) {
      this.getWhiteBoardDetailsFromApi(this.activeWbaId)
    }
    if (this.wbaResponse) {
      this.AcordStatus = this.wbaResponse[0]?.wbaReviewStatus;
      this.getWhiteBoardDetailsFromApi(this.activeWbaId)
    }
    // }
    // });
  }

  getWhiteBoardDetailsFromApi(Id: any) {
    // this._wbaService.getWBAById(Id).subscribe(resp => {
    //   if (resp && resp.whiteBoardActivityInfo) {
    //     this.wbaDetails = resp.whiteBoardActivityInfo;
    // this.GetMasterCoverageListByBusinessLineTierAndIndustryWbaId(this.wbaDetails.masterBusinessLineId, this.wbaDetails.prospectSizeId, this.wbaDetails.industryId, this.activeWbaId);
    if (this.wbaDetails.transType == 'RN') {
      this.showHideBoundCarrierValidationPopUp = false;
      this.isHide = false;
      return;
    }
    if (this.wbaDetails.transType == 'NB') {
      if (this.wbaDetails?.outcome == 'Lost' || this.wbaDetails?.wbaStatusDescription == 'Lost' || this.wbaDetails.progressName == 'Lost') {
        this.showHideBoundCarrierValidationPopUp = false;
        this.isHide = false;
      }
      else if ((this.AcordStatus != 'Complete' && this.AcordStatus != 'Completed') && (this.wbaDetails?.outcome != 'Lost' || this.wbaDetails?.wbaStatusDescription != 'Lost' || this.wbaDetails.progressName != 'Lost')) {
        this.showHideBoundCarrierValidationPopUp = true;
        this.isHide = true;
        return;
      }
      else if (this.wbaDetails.progressName == 'Marketed to Carrier' || this.wbaDetails.progressName == 'AM Marketed to Carrier'
        || this.wbaDetails.progressName == 'Quoted' || this.wbaDetails.progressName == 'BOR' || this.AcordStatus == 'Complete'
        || this.AcordStatus == 'Completed') {
        this.showHideBoundCarrierValidationPopUp = false;
        this.isHide = false;
      }
    }
    // if (this.wbaDetails.progressName == 'Marketed to Carrier' || this.wbaDetails.progressName == 'AM Marketed to Carrier'
    //   || this.wbaDetails.progressName == 'Quoted' || this.wbaDetails.progressName == 'BOR' || this.AcordStatus == 'Complete'
    //   || this.AcordStatus == 'Completed') {
    //   this.showHideBoundCarrierValidationPopUp = false;
    //   this.isHide = false;
    //   if (this.wbaDetails.progressName == 'Request to Bind' || this.wbaDetails.outcome == 'Bound' || this.wbaDetails.progressName == 'Client' || this.wbaDetails.outcome == 'Lost') {
    //     this.isHide = true;
    //   }
    // } else if (this.wbaDetails.progressName == 'Request to Bind' || this.wbaDetails.outcome == 'Bound' || this.wbaDetails.progressName == 'Client' || this.wbaDetails.outcome == 'Lost') {
    //   this.showHideBoundCarrierValidationPopUp = false;
    //   this.isHide = true;
    // }
    // else {
    //   this.showHideBoundCarrierValidationPopUp = true;
    //   this.isHide = true;
    //   return;
    // }


    //   }
    // })
  }

  getCarriers(fieldName: any, val: any, type: any) {

    if (this.editIndex === -1) {
      if (fieldName == 'Carrier') {
        this.carrierDataLookupFilterData = val.query;
      } else {
        this.WholesalerDataLookupFilterData = val.query;
      }
    }
    else {
      if (fieldName == 'Carrier') {
        this.editablecarrierDataLookupFilterData = val.query;
      } else {
        this.editableWholesalerDataLookupFilterData = val.query;
      }
    }

    if (fieldName && val.query) {
      this._wbaService.getTypeheadList(fieldName, val.query).subscribe(resp => {
        this.expiringCarrierlist = [];
        let tempList = resp.expiringCarrierList;
        if (type == 'TypeOne') {
          tempList.forEach((x: any) => {
            if (x.masterCarrierTypeId == 1) {
              this.expiringCarrierlist.push(x)
            };
          });
        } else {
          tempList.forEach((x: any) => {
            if (x.masterCarrierTypeId == 2) {
              this.expiringCarrierlist.push(x)
            };
          });
        }
      });
    }
  }

  selectItem(item: any, selectFrom: any) {
    this.isValidCarrier = false;
    if (this.editIndex === -1) {
      if (selectFrom == 'Carrier') {
        this.marketSummayForm.carrierId = item.id;
        this.Carrier = {
          id: item.id,
          value: item.value
        }
        this.carrierDataLookupFilterData = item.value;
      } else {
        this.marketSummayForm.wholesalerId = item.id;
        this.Wholesaler = {
          id: item.id,
          value: item.value
        }
        this.WholesalerDataLookupFilterData = item.value;
      }

    } else {
      if (selectFrom == 'Carrier') {
        this.editMarketSummayForm.carrierId = item.id;
        this.editCarrier = {
          id: item.id,
          value: item.value
        }
        this.editablecarrierDataLookupFilterData = item.value;
      } else {
        this.editMarketSummayForm.wholesalerId = item.id;
        this.editWholesaler = {
          id: item.id,
          value: item.value
        }
        this.editableWholesalerDataLookupFilterData = item.value;
      }
    }
  }

  handleData() {
    this.lossDirty = true;
  }

  handleWholesalerData(event: any, type: any) {
    this.lossDirty = true;
    if (type == 'add') {
      this.marketSummayForm.wholesalerId = event.target.value == '' ? 0 : this.marketSummayForm.wholesalerId
    } else {
      this.editMarketSummayForm.wholesalerId = event.target.value == '' ? 0 : this.editMarketSummayForm.wholesalerId
    }

  }

  handleCarrierData(event: any, type: any) {
    this.lossDirty = true;
    if (type == 'add') {
      this.marketSummayForm.carrierId = event.target.value == '' ? 0 : this.marketSummayForm.carrierId
    } else {
      this.editMarketSummayForm.carrierId = event.target.value == '' ? 0 : this.editMarketSummayForm.carrierId
    }

  }

  lookUpDataFilterChanges(val: any) {
    if (val == 'Carrier') {
      this.carrierDataLookupFilterData = "";
    }
    if (val == 'Wholesaler') {
      this.WholesalerDataLookupFilterData = "";
    }
    if (val == 'editCarrier') {
      this.editablecarrierDataLookupFilterData = "";
    }
    if (val == 'editWholesaler') {
      this.editableWholesalerDataLookupFilterData = "";
    }
  }

  openLookupModal(value: any, selectFrom: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });
    if (this.editIndex === -1) {
      if (selectFrom == 'Carrier') {
        modalRef.componentInstance.carrierLookupFilterData = this.carrierDataLookupFilterData;
      } else {
        modalRef.componentInstance.carrierLookupFilterData = this.WholesalerDataLookupFilterData;
      }
    }
    else {
      if (selectFrom == 'Carrier') {
        modalRef.componentInstance.carrierLookupFilterData = this.editablecarrierDataLookupFilterData;
      } else {
        modalRef.componentInstance.carrierLookupFilterData = this.editableWholesalerDataLookupFilterData;
      }
    }
    if (modalRef.componentInstance) {
      modalRef.componentInstance.searchType = value;
      modalRef.componentInstance.messageEvent.subscribe((data: any) => {
        this.selectItem(data, selectFrom);
      });
    }
    modalRef.closed.subscribe((resp: any) => {
    })
  }

  getMarketingSummaryList(wbaId: any) {
    if (wbaId) {
      this._wbaService.getMarketingSummaryList(wbaId).subscribe((res: any) => {
        if (res) {
          this.marketingSummaryList = res.marketingSummary;
          this._dataCacheService.compressAndCacheData('wba_Mrktsmry_marketingSummary', this.marketingSummaryList);
        }
      })
    }
  }

  addNumberFormat(variable: marketingSummaryDetails) {
    variable.quotedPremium = this._numberPipe.transform(variable.quotedPremium, 'USD', '1.0-0');
  }

  removeNumberFormat(variable: marketingSummaryDetails) {
    variable.quotedPremium = this._removeNumberPipe.transform(variable.quotedPremium);
  }

  removeSpaces(variable: marketingSummaryDetails) {
    variable.quotedPremium = this._removeWhiteSpacesPipe.transform(variable.quotedPremium, '');
  }

  handelMarketingSummaryList(action: string, marketingDetails: any, index: number) {
    this.lossDirty = true
    this.isValidCarrier = false;
    this.marketSummayForm.wbaId = this.activeWbaId;
    this.editMarketSummayForm.wbaId = this.activeWbaId;
    let isCarrieOrWholesaleExist: boolean = false;
    let { carrierId, wholesalerId, coverageId } = marketingDetails;
    if (action === 'add') {
      isCarrieOrWholesaleExist = (this.marketSummayForm.carrierId != 0 || this.marketSummayForm.wholesalerId != 0) ? false : true;
      if (isCarrieOrWholesaleExist || this.marketSummayForm.coverageId == 0) {
        this._confirmBox.message = this._userMessages.carrierOrCove;
        this._confirmBox.isVisible = true;
        return
      }
      let elementId = 'tr-marketing-edit-' + this.marketSummayForm.coverageId + '-' + this.marketSummayForm.carrierId + '-' + this.marketSummayForm.wholesalerId
      let element = document.getElementById(elementId)
      if (element) {
        this.isValidCarrier = true;
        this._confirmBox.message = this._userMessages.carrierExist;
        this._confirmBox.isVisible = true;
        return
      }
      // if (this.marketingSummaryList) {
      //   this.marketingSummaryList.forEach((x: any, index: any) => {
      //     if (x.coverageId == parseInt(this.marketSummayForm.coverageId)) {
      //       if (((x.carrierId == this.marketSummayForm.carrierId) && (x.carrierId != 0 && this.marketSummayForm.carrierId != 0)) || ((x.wholesalerId == this.marketSummayForm.wholesalerId) &&
      //         (x.wholesalerId != 0 && this.marketSummayForm.wholesalerId != 0))) {
      //         this.isValidCarrier = true;
      //         this._confirmBox.message = this._userMessages.carrierExist;
      //         this._confirmBox.isVisible = true;
      //         return
      //       }
      //     }
      //   })
      // }
      this.isAddSubmitted = true;

      if (coverageId && !this.isValidCarrier) {
        marketingDetails.quoteDate = this.datepipe.transform(marketingDetails.quoteDate, 'yyyy-MM-dd');
        marketingDetails.quotedPremium = parseInt(this._removeNumberPipe.transform(marketingDetails.quotedPremium));
        this.marketSummayForm.quotedPremium = parseInt(this.marketSummayForm.quotedPremium != null ? this.marketSummayForm.quotedPremium : 0);
        this.marketSummayForm.quotedStatus = this.marketSummayForm.quotedStatus != '' ? this.marketSummayForm.quotedStatus : 'In Process'
        if (Number.isNaN(this.marketSummayForm.quotedPremium)) {
          this.marketSummayForm.quotedPremium = 0
        }
        this._wbaService.createMarketingSummary(this.marketSummayForm).subscribe((res: any) => {
          this.isEditSubmitted == false;
          this.isValidCarrier = false;
          this.editIndex = -1;
          this.getMarketingSummaryList(this.activeWbaId)
          this.marketSummayForm = { ...this.defaultMarketetingSummary }
          this.marketSummayForm.wbaId = this.activeWbaId;
          // this.editMarketSummayForm.wbaId = this.activeWbaId;
          this.Wholesaler = '';
          this.Carrier = '';
          this.getWhiteBoardDetails(this.activeWbaId);

        })
        this.marketSummayForm = { ...this.defaultMarketetingSummary };
        this.isAddSubmitted = false;
      }
    } else if (action === "edit") {
      this.lossDirty = false;
      this.isEditSubmitted = true;
      //if (JSON.stringify(this.lossRunForm) === JSON.stringify(this.defaultLossRun)) {
      let record = this.marketingSummaryList.find((obj: any, index1: number) => index === index1);

      if (record.quoteDate)
        record.quoteDate = new Date(record.quoteDate).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      // record.clearanceDate = new Date(record.clearanceDate).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })
      //.toISOString().split('T')[0]
      let carrierDetails = {
        id: record.carrierId,
        value: record.carrierName
      }

      let WholesalerDetails = {
        id: record.wholesalerId,
        value: record.wholesalerName
      }

      this.editMarketSummayForm = { ...record };
      this.addNumberFormat(this.editMarketSummayForm);
      this.editIndex = index;
      this.Carrier = { id: 0, value: '' }
      this.Wholesaler = { id: 0, value: '' }
      this.selectItem(carrierDetails, 'Carrier');
      this.selectItem(WholesalerDetails, '');
      //} else {
      // this.showAlert();
      //}
    } else if (action === "cross") {
      this.editIndex = -1;
      this.isEditSubmitted = false;
      this.isAddSubmitted = false;

      this.editMarketSummayForm = { ...this.defaultMarketetingSummary };
      this.marketSummayForm = { ...this.defaultMarketetingSummary };

      let obj = { id: 0, value: '' };
      //this.Carrier = { id: 0, value: '' }
      this.editCarrier = { ...obj };
      this.Carrier = { ...obj };

    } else if (action === "save") {
      isCarrieOrWholesaleExist = (this.editMarketSummayForm.carrierId != 0 || this.editMarketSummayForm.wholesalerId != 0) ? false : true;
      if (isCarrieOrWholesaleExist || this.editMarketSummayForm.coverageId == 0) {
        this._confirmBox.message = this._userMessages.carrierOrCove;
        this._confirmBox.isVisible = true;
        return
      }
      let data = this.editMarketSummayForm
      this.marketingSummaryList
      let isRecordExist = this.marketingSummaryList.find((x) => x.coverageId == data.coverageId && x.carrierId == data.carrierId && x.wholesalerId == data.wholesalerId)
      let isRecordExistIndex = this.marketingSummaryList.findIndex((x: any) => x.coverageId == data.coverageId && x.carrierId == data.carrierId && x.wholesalerId == data.wholesalerId)

      if (isRecordExist && (this.editIndex !== isRecordExistIndex)) {
        this.isValidCarrier = true;
        this._confirmBox.message = this._userMessages.carrierExist;
        this._confirmBox.isVisible = true;
        return
      }
      // if (this.marketingSummaryList) {
      //   this.marketingSummaryList.forEach((x: any, index: any) => {
      //     if (x.coverageId == parseInt(this.editMarketSummayForm.carrierId) && index) {
      //       if (((x.carrierId == this.editMarketSummayForm.carrierId) && (x.carrierId != 0 && this.editMarketSummayForm.carrierId != 0)) || ((x.wholesalerId == this.editMarketSummayForm.wholesalerId) &&
      //         (x.wholesalerId != 0 && this.editMarketSummayForm.wholesalerId != 0))) {
      //         this.isValidCarrier = true;
      //         this._confirmBox.message = this._userMessages.carrierExist;
      //         this._confirmBox.isVisible = true;
      //         return
      //       }
      //     }
      //   })
      // }
      if (coverageId && !this.isValidCarrier) {
        marketingDetails.quoteDate = this.datepipe.transform(marketingDetails.quoteDate, 'yyyy-MM-dd');
        marketingDetails.quotedPremium = parseInt(this._removeNumberPipe.transform(marketingDetails.quotedPremium ? marketingDetails.quotedPremium : 0));
        this.editMarketSummayForm.clearanceDate = null
        if (Number.isNaN(this.editMarketSummayForm.quotedPremium)) {
          this.editMarketSummayForm.quotedPremium = 0
        }
        this._wbaService.createMarketingSummary(this.editMarketSummayForm).subscribe((res: any) => {
          this.isEditSubmitted == false;
          this.isValidCarrier = false;
          this.editIndex = -1;
          this.getMarketingSummaryList(this.activeWbaId)
          // this.editMarketSummayForm = { ...this.defaultMarketetingSummary }
          this.marketSummayForm.wbaId = this.activeWbaId;
          this.editMarketSummayForm.wbaId = this.activeWbaId;
          this.Wholesaler = '';
          this.Carrier = '';
          this.getWhiteBoardDetails(this.activeWbaId);
        }, ((err: any) => {
          let obj = err.error.errors;
          var arr = Object.keys(obj).map((key) => ({
            type: key,
            value: obj[key],
          }));
          if (arr) {
            this.handelMarketingSummaryList('cross', false, this.editIndex)
            this.isEditSubmitted = false;
            this._confirmBox.message = arr[0].value[0];
            this._confirmBox.isVisible = true;
          }
        }))
        this.editMarketSummayForm = { ...this.defaultMarketetingSummary };
        this.isAddSubmitted = false;
      }
    }
  }

  getStatusValue(value: any, callFrom: any) {
    if (callFrom == 'add') {
      this.marketSummayForm.quoteDate = value && value == 'Quoted' ? new Date() : ''
    } else {
      this.editMarketSummayForm.quoteDate = value && value == 'Quoted' ? new Date() : ''
    }

  }

  userInformation(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');

    if (this.userDetails) {

      // const requiredRoles = ['Account Manager', 'Admin', 'Sales Director', 'Marketing Manager'];
      // const requiredRoles = ['Client Executive'];
      //  this.isValidUser = this.userDetails.user.roles.some((role: any) => requiredRoles.includes(role.roleName));

      let roleExist = this.userDetails.user.roles.find((role: any) => role.roleName != 'Client Executive');
      if (roleExist) {
        this.isValidUser = true;
      }
      else {
        this.isValidUser = false;
      }
    }
  }

  checkAccessPermission() {

    let userDetails = JSON.parse(localStorage.getItem('userDetails')!);

    for (let index = 0; index < this.AcordPermissionResponse.length; index++) {
      let allRolePerms = this.AcordPermissionResponse[index];
      let actionAERequest = allRolePerms.actionName

      let findRoleName = userDetails.user.roles.find((y: any) => y.id === allRolePerms.approverRoleId);
      // if(findRoleName)
      // this.disabledSubmit = true
      if (actionAERequest == 'MarketingSummary' && (allRolePerms.approverUserId == userDetails?.user?.userId || findRoleName)) {
        console.log("AAM");
        this.ismarketingSummaryRequest = true;
      }
    }

    if (this.wbaDetails.transType == 'RN' && (this.wbaDetails.progressName == 'Marketed to Carrier' || this.wbaDetails.progressName == 'AM Marketed to Carrier'
      || this.wbaDetails.progressName == 'Quoted' || this.wbaDetails.progressName == 'BOR' || this.AcordStatus == 'Complete' || this.wbaDetails.progressName == 'Submitted to Marketing' || this.wbaDetails.progressName == 'AM Remarketing'
      || this.AcordStatus == 'Completed' || this.wbaDetails.progressName == 'Request to Bind' || this.wbaDetails.outcome == 'Bound' || this.wbaDetails.progressName == 'Client' || this.wbaDetails.outcome == 'Lost') && this.ismarketingSummaryRequest) {
      this.ismarketingSummaryRequestcheck = true;
      return;

    }
    //let actionAERequest = this.AcordPermissionResponse.filter((x: any) => x.actionName == 'AccountExecutiveRequest')
    //this.isAERequestSubmit = actionAERequest.some((item: any) => item.approverUserId === userDetails?.user?.userId);

  }


  getAccordPermissionByWbaId() {
    let requestBody = {
      wbaId: this.activeWbaId
    };

    this._wbaService.getAccordPermissionByWbaId(requestBody).subscribe((res: any) => {
      this.AcordPermissionResponse = res.acordPermissions;
      this._dataCacheService.compressAndCacheData('wba_AcordPermissionResponse', this.AcordPermissionResponse);//setting resp in cache
      this.checkAccessPermission();

    });
  }

  closeError() {
    this.ismarketingSummaryRequest = false;
  }

  closeError1() {
    this.ismarketingSummaryRequestcheck = false;
  }

}

export interface marketingSummaryDetails {
  businessLineId: number,
  carrierId: number,
  wholesalerId: number,
  coverageId: number,
  quoteDate: string,
  quotedPremium: number,
  quotedStatus: string,
  comments: string,
  clearanceDate: string
}
