<div class="modal-content">
    <div class="modal-body">
        <div class="add-pro-outer">


            <div class="add-hdr">
                <div class="heading">
                    <h2>WBA Outcome</h2>
                </div>
                <div class="add-act">
                    <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
                        aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
                </div>
            </div>
            <div class="row" *ngIf="showError">
                <div class="col-12">
                    <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
                        <ul class="view-error">
                            <li *ngFor="let err of errorList">{{err}}</li>
                        </ul>
                        <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!isBoundRequest && AcordPermissionResponse && wbaDetails?.transType == 'RN'">
                <div class="col-12">
                    <div class="alert alert-danger alert-dismissible fade show my-2" role="alert">
                        <ul class="view-error">
                            <li>Access denied, your permissions do not allow you to proceed.</li>
                        </ul>
                        <button type="button" class="btn-close" (click)="closeErrorBound()" data-bs-dismiss="alert"
                            aria-label="Close"></button>
                    </div>
                </div>
            </div>
            <div class="add-content add-form">
                <div class="pdg-con">
                    <form class="row g-3" [formGroup]="addBoundForm">
                        <div class="add-form ">
                            <div class="add-fld">
                                <div class="add-title ">
                                    <h3>Bound</h3>
                                </div>
                                <div class="row">
                                    <div class="row frmrow">

                                        <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                                            <label class="form-label">Outcome </label>
                                            <input formControlName="outcome" dataTextFocus
                                                [class.errorInput]="f.outcome.invalid && submitted" class="form-control"
                                                placeholder="Outcome" type="text"
                                                (focusout)="removeSpaces($event, f.outcome)" />

                                        </div>



                                        <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                                            <label class="form-label">Reason <span class='star-color'>*</span></label>

                                            <p-dropdown filterBy="label" [options]="reasonLostWbaList" [group]="true"
                                                [filter]="true" formControlName="reason">
                                                <ng-template let-group pTemplate="group">
                                                    <div class="flex align-items-center">
                                                        <span>{{ group.label }}</span>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                            <ng-template let-group pTemplate="group">
                                                <div class="flex align-items-center">
                                                    <span>{{ group.label }}</span>
                                                </div>
                                            </ng-template>
                                            <!-- </select> -->
                                            <div class="display-error" *ngIf="f.reason.invalid && submitted">
                                                <div *ngIf="f.reason.invalid && submitted">
                                                    <span *ngIf="f.reason.errors?.required">
                                                        {{_addWbaScreen.reason.required}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 mb-1"
                                            *ngIf="addBoundForm.value.reason == '29'">
                                            <label class="form-label">Description <span
                                                    class='star-color'>*</span></label>
                                            <textarea rows="4" class="form-control" name="description" #description
                                                formControlName="description"></textarea>

                                            <div class="display-error" *ngIf="f.description.invalid && submitted">
                                                <div *ngIf="f.description.invalid && submitted">
                                                    <span *ngIf="f.description.errors?.required">
                                                        {{_addWbaScreen.description.required}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                                            <label class="form-label">Bound Carrier <span
                                                    class='star-color'>*</span></label>
                                            <div class="row">
                                                <div class="col-12 col-sm-6 col-md-12">
                                                    <div class="flex-div">
                                                        <p-autoComplete class="form-control auto-comp"
                                                            placeholder="Bound Carrier" formControlName="boundCarrier"
                                                            [minLength]="1" [showEmptyMessage]="true"
                                                            [suggestions]="expiringCarrierlist"
                                                            (onSelect)="selectItem($event.value, 'expiringCarrierforBound')"
                                                            (completeMethod)="getCompanyList('Carrier', $event)"
                                                            (onDropdownClick)="getCompanyList('Carrier', $event)"
                                                            field="value">
                                                            <ng-template let-boundCarrier pTemplate="item">
                                                                <div class="company-item">
                                                                    <div>{{ boundCarrier.value }}</div>
                                                                </div>
                                                            </ng-template>
                                                        </p-autoComplete>
                                                        <img (click)="openLookupModalforBoundCarrier('Carrier')"
                                                            src="assets/images/search.svg" alt="" class="ms-2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="display-error" *ngIf="f.boundCarrier.invalid && submitted">
                                                <div *ngIf="f.boundCarrier.invalid && submitted">
                                                    <span *ngIf="f.boundCarrier.errors?.required">
                                                        {{_addWbaScreen.boundCarrier.required}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="display-error" *ngIf="invalidBoundCarrier && submitted">
                                                <span *ngIf="invalidBoundCarrier && submitted">
                                                    {{_addWbaScreen.boundCarrier.required}}
                                                </span>
                                            </div>
                                        </div>


                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Bound Premium <span
                                                    class='star-color'>*</span></label>
                                            <div class="pos-re">
                                                <span class="dollor">$</span>
                                                <input formControlName="boundPremium" class="form-control"
                                                    (keypress)="ValidateKey.integer($event,16)"
                                                    (focusout)="addCurrencyFormat($event,f.boundPremium)"
                                                    (focus)="removeCurrencyFormat($event,f.boundPremium)"
                                                    (focusout)="removeSpaces($event,f.boundPremium)" placeholder="" />
                                            </div>
                                            <div class="display-error" *ngIf="f.boundPremium.invalid && submitted">
                                                <div *ngIf="f.boundPremium.invalid && submitted">
                                                    <span *ngIf="f.boundPremium.errors?.required">
                                                        {{_addWbaScreen.boundPremium.required}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="display-error"
                                                *ngIf="f.boundPremium.valid && f.boundPremium.value == 0 && submitted">
                                                <span *ngIf="f.boundPremium.value == 0 && submitted">
                                                    {{_addWbaScreen.boundPremium.length}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Bound Revenue (to Lamb) <span
                                                    class='star-color'>*</span></label>
                                            <div class="pos-re">
                                                <span class="dollor">$</span>
                                                <input formControlName="boundRevenue"
                                                    (keypress)="ValidateKey.integer($event,16)"
                                                    (focusout)="addCurrencyFormat($event,f.boundRevenue)"
                                                    (focus)="removeCurrencyFormat($event,f.boundRevenue)"
                                                    (focusout)="removeSpaces($event,f.boundRevenue)"
                                                    class="form-control" placeholder="" />
                                            </div>
                                            <div class="display-error" *ngIf="f.boundRevenue.invalid && submitted">
                                                <div *ngIf="f.boundRevenue.invalid && submitted">
                                                    <span *ngIf="f.boundRevenue.errors?.required">
                                                        {{_addWbaScreen.boundRevenue.required}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="display-error"
                                                *ngIf="f.boundRevenue.valid && f.boundRevenue.value == 0 && submitted">
                                                <span *ngIf="f.boundRevenue.value == 0 && submitted">
                                                    {{_addWbaScreen.boundRevenue.length}}
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Carrier Commission % <span
                                                    class='star-color'>*</span></label>
                                            <div>
                                                <input formControlName="carrierCommission"
                                                    (keypress)="decimalFilter($event)" class="form-control"
                                                    placeholder="Carrier Commission %" />
                                            </div>
                                            <div class="display-error">
                                                <div *ngIf="f.carrierCommission.invalid && submitted">
                                                    <div *ngIf="f.carrierCommission.invalid && submitted">
                                                        <span *ngIf="f.carrierCommission.errors?.required">
                                                            {{_addWbaScreen.carrierCommission.required}}
                                                        </span>
                                                    </div>
                                                </div>
                                                <span *ngIf="showCarrierPercentageValidation">
                                                    {{ _addWbaScreen.carrierCommission.range }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Bound Policy Number <span
                                                    class='star-color'>*</span></label>
                                            <input formControlName="boundPolicyNumber" class="form-control"
                                                placeholder="Policy #" />
                                            <div class="display-error" *ngIf="f.boundPolicyNumber.invalid && submitted">
                                                <div *ngIf="f.boundPolicyNumber.invalid && submitted">
                                                    <span *ngIf="f.boundPolicyNumber.errors?.required">
                                                        {{_addWbaScreen.boundPolicyNumber.required}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 mb-2 date-control has-validation">
                                            <label class="form-label">Policy Expiration Date <span
                                                    class='star-color'>*</span></label>
                                            <p-calendar [readonlyInput]="true" class="form-control"
                                                placeholder="MM/DD/YYYY" [showIcon]="true" [iconDisplay]="'input'" dateFormat="mm/dd/yy"
                                                inputId="policyExpirationDate"
                                                formControlName="policyExpirationDate"></p-calendar>
                                            <div class="display-error"
                                                *ngIf="f.policyExpirationDate.invalid && submitted">
                                                <div *ngIf="f.policyExpirationDate.invalid && submitted">
                                                    <span *ngIf="f.policyExpirationDate.errors?.required">
                                                        {{_addWbaScreen.policyExpirationDate.required}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Cross Sale<span class='star-color'></span></label>
                                            <select class="select-cs form-select" placeholder="Cross Sale"
                                                [class.errorInput]="f.rfp.invalid && submitted"
                                                formControlName="crossSale">
                                                <option [value]="true">Yes</option>
                                                <option [value]="false" [selected]="isSelected">No</option>
                                            </select>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Sub Producer<span
                                                    class='star-color'></span></label>

                                            <div class="flex-div">
                                                <p-autoComplete placeholder="Sub Producer"
                                                    class="form-control auto-comp" [showEmptyMessage]="true"
                                                    [suggestions]="accountManagerlist" field="value" [minLength]="1"
                                                    (onSelect)="selectItem($event.value,'accountManager')"
                                                    (onDropdownClick)="getCompanyList('accountManager',$event)"
                                                    (completeMethod)="getCompanyList('accountManager',$event)"
                                                    formControlName="subProcedureName">
                                                    <ng-template let-account pTemplate="item">
                                                        <div class="company-item">
                                                            <div>{{ account.userName }}</div>
                                                        </div>
                                                    </ng-template>
                                                </p-autoComplete>
                                                <img src="assets/images/search.svg"
                                                    (click)="openLookupModal('AccountManager')" alt="" class="ms-2" />
                                            </div>

                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 mb-2  has-validation">
                                            <label class="form-label">Sub Producer % </label>
                                            <div>

                                                <input formControlName="subProducer" (change)="onChange($event)"
                                                    class="form-control per" (keypress)="decimalFilter($event)"
                                                    placeholder="Sub Producer %" />
                                            </div>
                                            <div class="display-error" *ngIf="showPercentageValidation">
                                                <span>
                                                    {{ _addWbaScreen.subProducer.range }}
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6  mb-2  has-validation">
                                            <label class="form-label">Was this an RFP? <span
                                                    class='star-color'>*</span></label>
                                            <select class="select-cs form-select" placeholder="Was this an RFP?"
                                                [class.errorInput]="f.rfp.invalid && submitted" formControlName="rfp">
                                                <option [value]="true">Yes</option>
                                                <option [value]="false" [selected]="isSelected">No</option>
                                            </select>
                                            <div class="display-error" *ngIf="f.rfp.invalid && submitted">
                                                <div *ngIf="f.rfp.invalid && submitted">
                                                    <span *ngIf="f.rfp.errors?.required">
                                                        {{_addWbaScreen.rfp.required}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="add-title ">
                                    <h3>Competitor</h3>
                                </div>
                                <div class="row">
                                    <div class="row frmrow">

                                        <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                                            <div class="row">
                                                <div class="col-12 col-sm-6 col-md-6 mb-2">
                                                    <label class="form-label">Broker Agent</label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-6 mb-2 text-right">
                                                    <input class="form-check-input" type="checkbox"
                                                        formControlName="competitorAgentKnown">
                                                    <label class="form-check-label lb-non" for="flexCheckDefault">
                                                        Competitor Agent Known
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-12 ">
                                                    <input class="form-control" placeholder="Broker Agent Name"
                                                        type="email" formControlName="brokerAgent" />
                                                </div>
                                            </div>


                                        </div>

                                        <div class="col-12 col-sm-12 col-md-12  mb-2  has-validation">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6  mb-2">
                                                    <label class="form-label">Carrier</label>


                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 mb-2 text-right ">
                                                    <input class="form-check-input" type="checkbox"
                                                        formControlName="competitorCarrierKnown">
                                                    <label class="form-check-label lb-non" for="flexCheckDefault">
                                                        Competitor Carrier Known
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 col-md-12">
                                                    <div class="flex-div">
                                                        <p-autoComplete class="form-control auto-comp"
                                                            placeholder="Carrier " formControlName="carrier"
                                                            [minLength]="1" [showEmptyMessage]="true"
                                                            [suggestions]="expiringCarrierlist"
                                                            (onSelect)="selectItem($event.value, 'expiringCarrier')"
                                                            (completeMethod)="getCompanyList('Carrier', $event)"
                                                            (onDropdownClick)="getCompanyList('Carrier', $event)"
                                                            field="value">
                                                            <ng-template let-carrier pTemplate="item">
                                                                <div class="company-item">
                                                                    <div>{{ carrier.value }}</div>
                                                                </div>
                                                            </ng-template>
                                                        </p-autoComplete>
                                                        <img (click)="openLookupModal('Carrier')"
                                                            src="assets/images/search.svg" alt="" class="ms-2" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="blk-bt">
                <button class="btn btn-outline-primary ms-2 me-3" (click)="cancel()">Cancel</button>
                <button class="btn btn-primary"
                    [disabled]="isSaveBtnDisable || wbaDetails?.wbaStatusDescription == 'Won'|| wbaDetails?.wbaStatusDescription =='Lost' || !wbaDetails?.accountManagerUserId || (!isBoundRequest && wbaDetails?.transType == 'RN')"
                    (click)="saveWbaBound()">Bound</button>
            </div>
        </div>
        <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showHideBoundCarrierValidationPopUp"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>There is no QUOTED status of any carrier in the Marketing Summary.</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="showHideBoundCarrierValidationPopUp = false" label="OK"
                    styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>

        <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showDialogBoxFOrBindRequestOrNot"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>Bind Request does not exist.</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="showDialogBoxFOrBindRequestOrNot = false" label="OK"
                    styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>

        <p-dialog [header]="_confirmBox.header2" [modal]="true" [(visible)]="showDialogBoxForNoAccountManager"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '43vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>Account Manager needs to be assigned before binding the policy.</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="showDialogBoxForNoAccountManager = false" label="OK"
                    styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>

        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
            [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
            [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
            <p>{{_confirmBox.message}}</p>
            <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
                <p-button (click)="_confirmBox.isVisible = false" label="No"
                    styleClass="btn btn-outline-primary"></p-button>
                <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
