import { Component, OnInit, ElementRef, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { EmailTemplateMessage } from 'src/app/features/white-board/error-message/emailTemplate-mesages'
import { Router } from '@angular/router';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { environment } from 'src/environments/environment';
import { WBANotifierService } from '../../../services/wba-notifier.service';

declare var $: any;
declare var kendo: any;
@Component({
  selector: 'app-submissionTemplate',
  templateUrl: './submissionTemplate.component.html',
  styleUrls: ['./submissionTemplate.component.css']
})
export class SubmissionTemplateComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() wbaIdData: any;
  _EmailTemplateMessage: EmailTemplateMessage;
  submitted: boolean = false;
  templateForm: any;
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  _navigationPath: NavigationPath;
  isDisable: boolean = false;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  filesToUpload: any;
  fileName: any;
  isProceed: boolean = false;
  fileSize: any;
  csvFileName: string = "";
  selectedDocumentList: any[] = [];
  emailTemplateId: number = 0;
  isUpdateTemplate: boolean = true;
  isShowRefreshButton: boolean = false;
  isMaxMin: boolean = true;
  MailData: any;
  refreshed: any = [];
  @ViewChild("questionEditor") questionEditor !: ElementRef;
  textAreaData: any;
  editor = `<textarea formControlName="mailTemplate" id="editor" style="height: 450px;"></textarea>`

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private _router: Router,
    public ValidateKey: KeyboardValidation,
    private _preventBack: PreventBackButtonService,
    private el: ElementRef,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _whiteBoardActivityService: WhiteBoardActivityService,
    private _notifierService: WBANotifierService
  ) {
    this._navigationPath = new NavigationPath();
    this._EmailTemplateMessage = new EmailTemplateMessage();
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    //Template form creation
    this.templateForm = this.fb.group({
      mailSubject: ['', Validators.required],
      mailTemplate: ['', Validators.required],
      mailAttachmentDocType: ['']
    })
  }

  ngOnInit(): void {
    this.templateForm.reset(); //method to reset Template form
    this._preventBack.preventBackButton(); //method to prevent browser back button
    this.getWbaEmailTemplateByWbaId(this.wbaIdData.wbaId);
    this.getEfileTaskLogByWbaId();
  }

  //to get wba email template by wbaId
  getWbaEmailTemplateByWbaId(wbaId: any) {
    this._whiteBoardActivityService.getWbaEmailTemplateByWbaId(wbaId).subscribe(resp => {
      if (resp && resp.wbaSubmissionTemplateList.length > 0) {
        let data = resp.wbaSubmissionTemplateList[0];
        if (data.isMasterTemplate) {
          this.emailTemplateId = 0;
          this._notifierService._isSubmissionsButtonsDisable.next(true);
        }
        else {
          this.emailTemplateId = data.id;
          this._notifierService._isSubmissionsButtonsDisable.next(false);
        }

        if (this.isUpdateTemplate) {
          this.templateForm.patchValue({
            mailTemplate: data.mailTemplate,
            mailSubject: data.mailSubject,
            mailAttachmentDocType: ""
          })

          $('#editor').kendoEditor({
            // resizable: {
            //   content: true,
            //   toolbar: true
            // },
            value: data.mailTemplate
          });

        }
      }
    })
  }

  setEditorValue(content: any) {
    // Get the Kendo Editor instance
    var editor = $('#editor').data('kendoEditor');

    // Check if the editor instance exists
    if (editor) {
      // Set the value of the editor
      editor.value(content);
    }
  }

  getMasterTemplate() {
    this.templateForm.reset(); //method to reset Template form
    this.getMasterEmailTemplateByWbaId(this.wbaIdData.wbaId)
  }

  //to get master email template by wbaId
  getMasterEmailTemplateByWbaId(wbaId: any) {
    this._whiteBoardActivityService.getMasterEmailTemplateByWbaId(wbaId).subscribe(resp => {
      if (resp && resp.wbaSubmissionTemplateList.length > 0) {
        let data = resp.wbaSubmissionTemplateList[0];
        // if (data.isMasterTemplate) {
        //   this.emailTemplateId = 0;
        //   this._notifierService._isSubmissionsButtonsDisable.next(true);
        // }
        // else {
        //   this.emailTemplateId = data.id;
        //   this._notifierService._isSubmissionsButtonsDisable.next(false);
        // }

        if (this.isUpdateTemplate) {
          this.templateForm.patchValue({
            mailTemplate: data.mailTemplate,
            mailSubject: data.mailSubject,
            mailAttachmentDocType: ""
          })
          if (this.refreshed.length > 1) {
            let i = 0;
            while (i < this.refreshed.length - 1) {
              const editorElement = $('#editor' + (this.refreshed.length - 2));
              editorElement.closest('.k-editor').remove();
              i++;
            }
          } else {
            const editorElement = $('#editor');
            editorElement.closest('.k-editor').remove();
          }
        }
        var editor = $(this.refreshed.length > 0 ? `#editor${this.refreshed.length - 1}` : '#editor');

        editor[0].style.display = 'block';
        editor.kendoEditor({
          value: data.mailTemplate
        });
      }
    })
  }

  //to save email template
  saveWbaEmailTemplate() {
    this.submitted = true;
    let data = this.templateForm?.value;
    if (this.templateForm.invalid) {
      this.templateForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
      this.isDisable = false;
      return;
    }
    else {

      var editor = $(this.refreshed.length > 0 ? `#editor${this.refreshed.length - 1}` : '#editor');
      var editorValue = ''
      if (editor.length > 0) {
        editorValue = editor[0].value.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&nbsp;/g, '')
      }

      // var editorValue = editor.value()
      this.isDisable = true;
      let saveData = {
        "wbaId": this.wbaIdData.wbaId,
        // "mailTemplate": data.mailTemplate,
        "mailTemplate": editorValue,
        "mailSubject": data.mailSubject
      }
      this._whiteBoardActivityService.addWbaEmailTemplate(saveData).subscribe(resp => {
        if (parseInt(resp) > 0) {
          this.isUpdateTemplate = true;
          this.passEntry.emit('Template-Saved');
          this.getWbaEmailTemplateByWbaId(this.wbaIdData.wbaId);
          this._router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl()],
            { queryParams: { wbaId: this.wbaIdData.wbaId, prospectId: this.wbaIdData.prospectId } });
          this.activeModal.close();
        } else {
          this.isDisable = false;
        }
      }, (err: any) => {
        this.errorList = [];
        this.showError = true;
        this.isDisable = false; //enabling save button for after changes
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          }
          if (err.status === 403) {
            this.showError = true;
            let message = `Access denied, your permissions do not allow you to proceed.`
            this.errorList.push(message);
          } else {
            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
    }
  }

  maxWindow(val: any) {
    this.isMaxMin = !val;
  }

  getEfileTaskLogByWbaId() {
    var data = {
      wbaId: this.wbaIdData.wbaId
    }
    this._whiteBoardActivityService.getEfileTaskLog(data).subscribe(resp => {
      if (resp?.message) {
        this.errorList = [];
        this.showError = true;
        this.errorList.push(resp.message);
      }
      else {
        this.errorList = [];
        this.showError = false;
        this.getWbaSubmissionAttachmentByWbaId(this.wbaIdData.wbaId);
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          }
          else if (err.status === 404) {
            this.showError = true;
            this.errorMessage = err.error.detail
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }


  //to get wba Submission Attachment by wbaId
  getWbaSubmissionAttachmentByWbaId(wbaId: any) {
    var data = {
      wbaId: wbaId,
      priorPolicyId: this.wbaIdData.priorPolicyId,
      wbaTransType: this.wbaIdData.wbaTransType
    }

    this._whiteBoardActivityService.getWbaSubmissionAttachmentByWbaId(data).subscribe(resp => {
      this.isShowRefreshButton = true;
      if (resp && resp.wbaSubmissionAttachmentList.length > 0) {
        resp.wbaSubmissionAttachmentList.forEach((data: any) => {
          if (data.documentType !== "Expiring" && data.documentType !== "Working Docs - Not Included in Submission") {
            this.addRemoveItemFromDocumentList(data, "add")
          }
        })
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          }
          else if (err.status === 404) {
            this.showError = true;
            this.errorMessage = err.error.detail
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }

  //to get wba Submission Attachment by wbaId
  deleteWbaSubmissionAttachmentById(id: number) {
    this._whiteBoardActivityService.deleteWbaSubmissionAttachmentyById(id).subscribe(resp => {
      if (resp && parseInt(resp) > 0) {
        this.selectedDocumentList = [];
        this.getWbaSubmissionAttachmentByWbaId(this.wbaIdData.wbaId);
      }
    })
  }

  preventDefault() {
    return false;
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }

  //to calcel the process and back to email template screen
  cancel() {
    if (!this.templateForm.dirty) {
      this.activeModal.close();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  //to get all form controls
  get f() {
    return this.templateForm.controls;
  }

  // Remove white spaces from every input fileds
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  closeError() {
    this.showError = false;
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.csvFileName = '';
      this.isProceed = true;
      const file = event.target.files[0];
      this.filesToUpload = file;
      this.csvFileName = file.name;

      for (var i = 0; i < event.target.files.length; i++) {
        var size = event.target.files[i].size;
        this.fileSize = Math.round(size / 1024)
      }

      if (this.filesToUpload.type) {
        this.isProceed = true
      }
    }
  }

  fileOpenInNewTab(fileName: string, fileData: any) {
    let html = '';

    html += '<html><head><title>' + fileName + '</title><custom-style><style>#title {Display:none !important}</style></custom-style> <script>';
    html += ' var cover = document.createElement("div");';
    html += ' let css = "position: fixed;pointer-events: none;top: 0;left: 0;width: 100vw;height: 100vh;background-color: #3aa757;mix-blend-mode: multiply;z-index: 1;"';
    html += 'cover.setAttribute("style", css);document.body.appendChild(cover);</script></head>';
    html += '<body style="margin:0!important">';
    html += '<div class="pdf"><embed width="100%" height="100%" src="' + fileData + '" type="application/pdf" /></div>';
    html += '</body>';
    html += '</html>';
    setTimeout(() => {
      let win: Window | null = null
      win = window.open("", "_blank");
      win?.document.write(html);
    }, 0);
  }

  //create userSubscription request on selection/removal
  addRemoveItemFromDocumentList(item: any, operation: string) {
    let data = {
      "wbaId": this.wbaIdData.wbaId,
      "id": item.id || 0,
      "documentType": item.documentType,
      "fileName": item.fileName,
      "originalFileName": item.originalFileName,
      "file": item.file,
      "filePath": item.filePath,
      "fileType": item.fileType,
      "wbaSubmissionTemplateId": item.wbaSubmissionTemplateId,
      "fileURL": item.filePath + item.fileName + item.fileType
    }

    if (operation === "add") {
      let dataExiist = this.selectedDocumentList.find(x => x.id == item.id);
      if (!dataExiist) {
        this.selectedDocumentList.push(data)
      }

      this.selectedDocumentList = this.selectedDocumentList.filter((item, index, self) => self.indexOf(item) === index);
    } else {
      this.selectedDocumentList = this.removeValueFromJson(this.selectedDocumentList, "id", "0")
    }
  }

  //this function is used to remove  value from array
  removeValueFromJson(array: any, _key: string, value: string) {
    let _array = array.filter(function (_ele: any) {
      return _ele[_key] != value;
    })
    return _array;
  }

  //to remove the item from list
  removeDocumentFromList(id: any) {
    this.deleteWbaSubmissionAttachmentById(id);
  }

  viewDocument(item: any, index: number) {
    this.fileOpenInNewTab(item.fileName, item.fileURL);
  }

  getWbaFilesFromEfileCabinet() {
    var data = {
      wbaId: this.wbaIdData.wbaId
    }
    this._whiteBoardActivityService.getEfileTaskLog(data).subscribe(resp => {
      if (resp?.message) {
        this.errorList = [];
        this.showError = true;
        this.errorList.push(resp.message);
      }
      else {
        this.errorList = [];
        this.showError = false;
        this.getDataFromEPIC()
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          }
          else if (err.status === 404) {
            this.showError = true;
            this.errorMessage = err.error.detail
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }

  getDataFromEPIC() {
    var data = {
      wbaId: this.wbaIdData.wbaId,
      priorPolicyId: this.wbaIdData.priorPolicyId
    }

    this._whiteBoardActivityService.getDataFromEPIC(data).subscribe(resp => {
      this.isShowRefreshButton = true;
      if (resp && resp.wbaSubmissionAttachmentList.length > 0) {
        this.selectedDocumentList = [];
        resp.wbaSubmissionAttachmentList.forEach((data: any) => {
          if (data.documentType !== "Expiring" && data.documentType !== "Working Docs - Not Included in Submission") {
            this.addRemoveItemFromDocumentList(data, "add")
          }
        })
      }
    },
      (err: any) => {
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          }
          else if (err.status === 404) {
            this.showError = true;
            this.errorMessage = err.error.detail
            this.errorList.push(this.errorMessage);
          }
          else {
            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });
  }

  //to delete wba Submission Attachment by wbaId
  deleteWbaSubmissionAttachmentByWbaId(wbaId: number) {
    this._whiteBoardActivityService.deleteWbaSubmissionAttachmentyByWbaId(wbaId).subscribe(resp => {
      if (resp && parseInt(resp) > 0) {
        this.selectedDocumentList = [];
        this.getWbaSubmissionAttachmentByWbaId(this.wbaIdData.wbaId);
      }
    })
  }

  uploadEpicFilesOnEfileCabinet() {
    let data = {
      policyId: this.wbaIdData.epicUniquePolicyId,
      wbaId: this.wbaIdData.wbaId
    }

    this._whiteBoardActivityService.uploadEpicFilesOnEfileCabinet(data).subscribe(resp => {
      if (resp) {
        console.log(resp);
      }
    },
      ((errorResponse: any) => {
        console.log(errorResponse);
      }));
  }



}
