<div class="default-window" [ngClass]="{'maxWindow': isMaxMin  ? '': !isMaxMin}">
  <div class="modal-content ">
    <div class="modal-body">
      <div class="add-pro-outer">
        <div class="add-hdr">
          <div class="heading">
            <h2>Submissions Email Template</h2>
          </div>


          <div class="add-act">

            <div class="max-btn me-2">
              <button class="mx-btn" (click)="maxWindow(isMaxMin)" *ngIf="!isMaxMin"><img
                  src="assets/images/minimize.svg"></button>
              <button class="mx-btn min-btn" (click)="maxWindow(isMaxMin)" *ngIf="isMaxMin"><img
                  src="assets/images/maximize.svg"></button>
            </div>

            <a href="javascript:void(0)" title="" (click)="cancel()" class="close" data-bs-dismiss="modal"
              aria-label="Close"><img src="assets/images/cross.svg" alt="" /></a>
          </div>
        </div>
        <div class="row" *ngIf="showError">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show my-2 " role="alert">
              <ul class="view-error">
                <li *ngFor="let err of errorList">{{err}}</li>
              </ul>
              <div>

                <button type="button" class="btn-close" (click)="closeError()" data-bs-dismiss="alert"
                  aria-label="Close"></button>
              </div>

            </div>
          </div>
        </div>

        <div class="add-content add-form">
          <div class="pdg-con">
            <form class="row g-3" novalidate [formGroup]='templateForm' id="prospectform"
              onkeydown="return event.key != 'Enter';">
              <div class="add-form">

                <div class="add-fld">
                  <div class="flex-div space-between my-2 hd-p add-title">
                    <h3>Email Template</h3>
                    <a (click)="getMasterTemplate();refreshed.push(0);">
                      <span><img src="assets/images/refresh.svg" alt="" class="syncImg" /></span>
                      <label style="padding-left: 5px;">Refresh</label>
                    </a>
                  </div>
                  <div class="row">
                    <div class="row frmrow">
                      <div class="col-12 col-sm-12 col-md-12  mb-1  has-validation">
                        <label class="form-label">Subject <span class='star-color'>*</span></label>
                        <input formControlName="mailSubject" [class.errorInput]="f.mailSubject.invalid && submitted"
                          class="form-control" placeholder="Email Subject" dataTextFocus
                          (focusout)="removeSpaces($event,f.mailSubject)" />
                        <div class="display-error" *ngIf="f.mailSubject.invalid && submitted">
                          <span
                            *ngIf="f.mailSubject.errors?.required">{{_EmailTemplateMessage.mailSubject.required}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row frmrow">

                      <div class="col-12 col-sm-12 col-md-12  phone-div">

                        <div class="phone">
                          <label class="form-label">Body <span class='star-color'>*</span></label>
                          <!-- <p-editor formControlName="mailTemplate" [style]="{'height':'320px'}">
                        </p-editor> -->
                          <!-- <textarea id="editor" #questionEditor></textarea> -->
                          <textarea formControlName="mailTemplate" id="editor" style="height: 420px;"></textarea>
                          <ng-container *ngFor="let txt of refreshed;let i=index;">
                            <textarea formControlName="mailTemplate" id="editor{{i}}"
                              style="height: 420px;display: none;"></textarea>
                          </ng-container>

                          <!-- <kendo-editor formControlName="mailTemplate" style="height: 450px;">
                            <kendo-toolbar>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                              <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                              <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                              <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                              <kendo-toolbar-colorpicker kendoEditorBackColor
                                view="gradient"></kendo-toolbar-colorpicker>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-button kendoEditorBlockquoteButton></kendo-toolbar-button>
                              <kendo-toolbar-button kendoEditorSelectAllButton></kendo-toolbar-button>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                              <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                              <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                              <kendo-toolbar-button kendoEditorCleanFormattingButton></kendo-toolbar-button>
                              <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                              </kendo-toolbar-buttongroup>
                              <kendo-toolbar-button kendoEditorPrintButton></kendo-toolbar-button>
                            </kendo-toolbar>
                          </kendo-editor> -->
                          <div class="display-error" *ngIf="f.mailTemplate.invalid && submitted">
                            <span
                              *ngIf="f.mailTemplate.errors?.required">{{_EmailTemplateMessage.mailTemplate.required}}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">

                    <div class="col-12 col-sm-12 col-md-12 mb-2">
                      <div class="flex-div space-between mt-2 mb-1 hd-p add-title">
                        <h3>eFile Attachments</h3>
                        <div class="flex-div">
                          <!-- <a *ngIf="wbaIdData.wbaTransType == 'RN' && isShowRefreshButton" href="javascript:void(0)"
                            data-bs-toggle="collapse" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            <label class="anchor-color" (click)="uploadEpicFilesOnEfileCabinet()">Copy files from EPIC
                              to eFile </label> </a>
                          <span *ngIf="wbaIdData.wbaTransType == 'RN' && isShowRefreshButton" class="mr-3 ml-3"> |
                          </span> -->
                          <a href="javascript:void(0)" data-bs-toggle="collapse" role="button" aria-expanded="false"
                            aria-controls="collapseExample">
                            <label class="anchor-color" (click)="getWbaFilesFromEfileCabinet()">Sync eFiles
                              <span>
                                <img src="assets/images/refresh.svg" alt="" class="syncImg" />
                              </span>
                            </label>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="row frmrow">
                      <div class="col-12 col-sm-12 col-md-12 mb-4">
                        <div class="notes-display">
                          <div class="table-responsive table-responsive-md list-prospect carrier-list" id="">
                            <table class="table">
                              <thead class="table-dark">
                                <tr class="table-hd">
                                  <th width="30%">eFile Folder</th>
                                  <th width="60%">File Name</th>
                                  <th width="10%">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container>
                                  <tr *ngFor="let item of selectedDocumentList; let i=index;">
                                    <td class="recall-blk high-pr">
                                      {{item.documentType}}
                                    </td>
                                    <td class="recall-blk high-pr">
                                      <div class="cr-wd text-wrap">
                                        <span pTooltip="{{ item.originalFileName }}" tooltipPosition="bottom"
                                          tooltipStyleClass="tooltipstyle"> {{item.originalFileName}}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div class="flex-div justify-content-end" style="float: left;">
                                        <p class="ms-2"><img (click)="viewDocument(item, i)" title="View"
                                            src="assets/images/eye.svg" alt="Delete" class="me-2 mt-1" width="13">
                                        </p>
                                        <p class="ms-2"><img (click)="removeDocumentFromList(item.id)" title="Delete"
                                            src="assets/images/trash.svg" alt="Delete" class="me-2 mt-1" width="13">
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </ng-container>
                                <ng-container *ngIf="selectedDocumentList && selectedDocumentList.length == 0">
                                  <tr>
                                    <td colspan="6" style="border-bottom: 1px solid #dee2e6;">No Records Found.</td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="blk-bt">
            <button class="btn btn-primary ms-2 me-3" (keydown.enter)="preventDefault()"
              (click)="saveWbaEmailTemplate()" [disabled]="isDisable">Save</button>
            <button class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
          </div>
        </div>

        <!-- Dialog for confirmation -->
        <p-dialog [header]="_confirmBox.header" [modal]="true" [(visible)]="_confirmBox.isVisible"
          [breakpoints]="{'960px': '75vw'}" [style]="{width: '40vw'}" [draggable]="false" [resizable]="false"
          [styleClass]="_confirmBox.borderColor" *ngIf="_confirmBox">
          <p>{{_confirmBox.message}}</p>
          <ng-template pTemplate="footer" *ngIf="_confirmBox.hasFooter">
            <p-button (click)="_confirmBox.isVisible = false" label="No"
              styleClass="btn btn-outline-primary"></p-button>
            <p-button (click)="confirmBoxCancel()" label="Yes" styleClass="btn btn-primary"></p-button>
          </ng-template>
        </p-dialog>

      </div>
    </div>
  </div>
</div>